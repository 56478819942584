import React from 'react'
import styled from 'styled-components'

interface Props extends React.ComponentProps<'div'> {
  height?: number
  width?: number
  src?: string
}

function ImageFrame(props: Props) {
  const wrapperRef = React.useRef<HTMLImageElement>(null)

  // React.useEffect(() => {
  //   if (wrapperRef.current) {
  //     if (props.height && props.width) {
  //       const ratio = props.height / props.width
  //       // wrapperRef.current.style.paddingBottom = `${ratio * props.height}px`
  //     } else {
  //       // Fallback if image is missing height and width props
  //       //wrapperRef.current.style.paddingBottom = '100%'
  //     }
  //   }
  // }, [props.height, props.width])

  return (
    <div ref={wrapperRef} className={props.className}>
      <img src={props.src} />
      {props.children}
    </div>
  )
}

export default styled(ImageFrame)`
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 800px;
  min-height: 150px;
  position: relative;

  img {
    max-height: 50vh;
    max-width: 100%;
    /* border-radius: 3px;
    display: block;
    max-height: 100%;
    left: 50%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%; */
  }
`
