import React from 'react'

import { useSuspendedLocationValidation } from '../hooks/useSuspendedLocationValidation'

import { Notice } from 'happitu/src/components/Fields'

const SuspendedLocationNotice = (props: { locationId?: ID | null }) => {
  const isSuspendedLocation = useSuspendedLocationValidation(props.locationId)
  return isSuspendedLocation ? (
    // TODO: provide customizable message.
    <Notice style={{ marginTop: '2em', marginRight: '3em' }}>
      This location is not supported. Please refer the caller to the front desk or an
      appropriate source for the approved technical contact information.
    </Notice>
  ) : null
}

export default SuspendedLocationNotice
