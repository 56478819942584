import styled from 'styled-components'

import { InputCSS, NextFieldWrapper } from '../FieldUtils'

import getStyle from 'happitu/src/getStyle'

export const StyledInput = styled.input`
  ${InputCSS};
  text-align: center;

  ${NextFieldWrapper} & {
    font-size: inherit;
    font-weight: inherit;
    text-align: inherit;
  }

  /* :not(:focus) {
    background: ${getStyle('button-base-default-background')};
    border-color: transparent;
  } */

  /* Remove the spinners */
  appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
  }
`
