import Cookies from 'js-cookie'

import relay, { RelayDispatch } from './relay'

import {
  ORGANIZATION_ALIAS_COOKIE,
  ORGANIZATION_ID_COOKIE,
} from 'happitu/src/constants/cookies'
import tracker from 'happitu/src/helpers/tracker'
import {
  updateUserRequest,
  getCurrentUserRequest,
} from 'happitu/src/services/happitu-api/usersService'
import userService from 'happitu/src/services/websocket/userService'

function hydrateCurrentUser(value: { [key: string]: any }, user: CurrentUserRecord) {
  Object.keys(value).forEach((key) => {
    if (typeof value[key] === 'object') {
      value[key] = hydrateCurrentUser(value[key], user)
    } else {
      value[key] = value[key] === 'currentUser' ? user.id : value[key]
    }
  })
  return value
}

export function initializeCurrentUser() {
  return async (dispatch: RelayDispatch<typeof getCurrentUserRequest>) => {
    const payload = await getCurrentUserRequest()
    const currentUser = payload.users[0]
    relay(dispatch)({
      ...payload,
      currentUser,
    })
    // @ts-ignore
    global.HAPPITU_AUTH_TOKEN = payload.authToken
    userService.connect(payload.websocketToken)
    Cookies.set(ORGANIZATION_ALIAS_COOKIE, payload.organizations[0].nameSlug, {
      expires: 2000,
    })
    Cookies.set(ORGANIZATION_ID_COOKIE, payload.organizations[0].id, {
      expires: 2000,
    })
    tracker.identify(currentUser.id)
    tracker.setProfile(currentUser)
    return payload
  }
}

export function updateUserOptions(key: string | Record<string, object>, value?: any) {
  return (dispatch: RelayDispatch<typeof updateUserRequest>, getState: GetReduxState) => {
    const { user } = getState()
    const { options } = user.profile
    let nextOptions = {} as { [key: string]: any }
    if (typeof key === 'string' && value) {
      nextOptions = {
        ...options,
        [key]: btoa(JSON.stringify(hydrateCurrentUser(value, user))),
      }
    } else if (typeof key !== 'string') {
      nextOptions = {
        ...options,
      }

      Object.keys(key).forEach((k) => {
        nextOptions[k] = btoa(JSON.stringify(key[k]))
      })
    }

    const payload = {
      options: nextOptions,
    }

    // NOTE: This is using the old redux store... and will eventually be factored out.
    // @ts-ignore 2322
    relay(dispatch)({ currentUser: payload })
    return updateUserRequest(user.id, payload)
  }
}
