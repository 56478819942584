import React, { useCallback, useState } from 'react'

import { RequiredCollections } from '../workflow-viewer.types'

import WorkflowActionListItem, { BackLink } from './workflow-action-list-item'
import { Wrapper } from './workflow-actions-list.styles'

import Icon from 'happitu/src/components/Icon'
import Box from 'happitu/src/components/box'
import Text from 'happitu/src/components/text'
import { getParams, WorkTicketRouteParams } from 'happitu/src/helpers/routeHelpers'

interface Props {
  actionIds: ID[]
  collections: RequiredCollections
  disabled?: boolean
  label?: string
  onAdvance: (action: WorkflowActionRecord) => Promise<void>
  onBack?: () => void
  selectedAction?: ID | null
}

const WorkflowActionList = (props: Props) => {
  const { stepIndex } = getParams<WorkTicketRouteParams>()
  const [loading, setLoading] = useState<ID | null>(null)

  const handleClick = useCallback(async (action: WorkflowActionRecord) => {
    try {
      setLoading(action.id)
      await props.onAdvance(action)
    } finally {
      setLoading(null)
    }
  }, [])

  return (
    <>
      <Wrapper>
        {props.actionIds.length > 0 && <Text color="sub">{props.label}</Text>}
        <Box>
          {props.actionIds?.map((actionId) => {
            const action = props.collections.workflowActions?.findById<WorkflowActionRecord>(
              actionId,
            )
            const selected = props.selectedAction === actionId
            return action ? (
              <WorkflowActionListItem
                action={action}
                broken={
                  !props.collections.workflowSteps?.findById(action.targetStepId) &&
                  !action.escalationWorkflowId
                }
                disabled={!!loading || props.disabled}
                key={action.id}
                loading={loading === action.id}
                onClick={handleClick}
                selected={selected}
              />
            ) : null
          })}
          {props.onBack && stepIndex !== '0' && (
            <BackLink onClick={props.onBack}>
              <Icon type="arrow-left" /> Back a Step
            </BackLink>
          )}
        </Box>
      </Wrapper>
    </>
  )
}

export default WorkflowActionList
