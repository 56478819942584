export const baseline = '14px'

export const gutter = '25px'
export const halfGutter = '12px'

export const borderRadius = '3px'
export const largeBorderRadius = '6px'

export const horizontalNavHeight = '4em'
export const navWidth = '240px'
export const ticketBinWidth = '400px'

export const navPadding = `.5em ${halfGutter}`
export const navMargin = `0 ${halfGutter}`

/*
  Ticketing
 */
export const ticketingGutterRight = '400px'
export const ticketingGutterLeftDesktop = '20%'
