import React from 'react'
import { Link as BaseLink, withRoute } from 'react-router5'
import styled from 'styled-components'

import { getParams } from 'happitu/src/helpers/routeHelpers'

export interface Props {
  style?: React.CSSProperties
  children?: React.ReactNode
  className?: string
  ghost?: boolean
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  options?: Record<string, unknown> // Pass things like state
  params?: Record<string, any>
  rel?: string
  tabIndex?: number
  title?: string
  to?: string // The name of the route
  href?: string
  target?: string
}

const Link = ({
  className = '',
  to,
  params,
  options,
  tabIndex = -1,
  style,
  href,
  ...props
}: Props) => {
  const elementConfig = !href
    ? {
        component: BaseLink,
        props: {
          routeName: to,
          routeOptions: options,
          routeParams: {
            organizationId: getParams().organizationId,
            ...(params || {}),
          },
        },
      }
    : {
        component: 'a',
        props: { href },
      }

  return React.createElement(
    elementConfig.component,
    {
      ...elementConfig.props,
      style: style,
      className: className,
      onClick: props.onClick,
      rel: props.rel,
      tabIndex: tabIndex,
      target: props.target,
      title: props.title,
    },
    props.children,
  )
}

export { Link as PureComponent }

export default styled(withRoute<Props>(Link))`
  outline: none;
  cursor: pointer;
`
