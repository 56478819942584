import * as React from 'react'
import styled from 'styled-components'

import BaseLoader from './BaseLoader'

const Wrapper = styled.div`
  height: 40px;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
`

interface Props {
  className?: string
  size?: 'small' | 'large'
}

const SubLoader = ({ className, size = 'large' }: Props) => {
  return (
    <Wrapper className={ className }>
      <BaseLoader size={ size } />
    </Wrapper>
  )
}

export default SubLoader
