import relay, { RelayDispatch } from './relay'

import {
  getContactRequest,
  updateContactRequest,
} from 'happitu/src/services/happitu-api/contactsService'

export const getContact = (contactId: ID) => (
  dispatch: RelayDispatch<typeof getContactRequest>,
) => getContactRequest(contactId).then(relay(dispatch))

export const updateContact = (contactId: ID, contact: Partial<ContactRecord>) => (
  dispatch: RelayDispatch<typeof updateContactRequest>,
) => updateContactRequest(contactId, contact).then(relay(dispatch))
