import { useMemo } from 'react'
import { Editor } from 'slate'
import { useSlate } from 'slate-react'
import styled from 'styled-components'

import Flex from '../../flex'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuListItem,
  MenuWrapper,
  MenuListSection,
} from '../../menu'
import Text from '../../text'

const Wrapper = styled.div`
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 2px 4px;
  margin-right: 0.5em;
`

const isBackgroundColor = (value?: string) => /-background/.test(`${value}`)

const TextIcon = (props: { value?: string }) => {
  const attribute = useMemo(
    () => (isBackgroundColor(props.value) ? 'background-color' : 'color'),
    [props.value],
  )
  return (
    <Wrapper style={props.value ? { [attribute]: textColors[props.value] } : {}}>
      <Text bold>A</Text>
    </Wrapper>
  )
}

export const textColors: Record<string, string> = {
  'gray': 'grey',
  'brown': 'saddlebrown',
  'orange': 'orangered',
  'yellow': 'gold',
  'green': 'rgb(34, 139, 34)',
  'blue': 'mediumblue',
  'purple': 'purple',
  'pink': 'deeppink',
  'red': 'crimson',
  'gray-background': 'lightgrey',
  'brown-background': 'antiquewhite',
  'orange-background': 'peachpuff',
  'yellow-background': 'rgba(255, 215, 0, .5)',
  'green-background': 'rgba(34, 139, 34, .5)',
  'blue-background': 'rgba(0, 0, 205, .5)',
  'purple-background': 'rgba(128, 0, 128, .5)',
  'pink-background': 'rgba(255, 20, 147, .5)',
  'red-background': 'rgba(220, 20, 60, .5)',
}

const textStyles = [
  {
    label: 'Color',
    children: [
      { label: 'Default', value: 'default' },
      { label: 'Gray', value: 'gray' },
      { label: 'Brown', value: 'brown' },
      { label: 'Orange', value: 'orange' },
      { label: 'Yellow', value: 'yellow' },
      { label: 'Green', value: 'green' },
      { label: 'Blue', value: 'blue' },
      { label: 'Purple', value: 'purple' },
      { label: 'Pink', value: 'pink' },
      { label: 'Red', value: 'red' },
    ],
  },
  {
    label: 'Background',
    children: [
      { label: 'Default', value: 'default' },
      { label: 'Gray background', value: 'gray-background' },
      { label: 'Brown background', value: 'brown-background' },
      { label: 'Orange background', value: 'orange-background' },
      { label: 'Yellow background', value: 'yellow-background' },
      { label: 'Green background', value: 'green-background' },
      { label: 'Blue background', value: 'blue-background' },
      { label: 'Purple background', value: 'purple-background' },
      { label: 'Pink background', value: 'pink-background' },
      { label: 'Red background', value: 'red-background' },
    ],
  },
]

const TextStyleButton = () => {
  const editor = useSlate()
  const marks = Editor.marks(editor) as null | Record<string, string> | string
  const highlightType = marks instanceof Object ? marks.highlight : undefined

  const select = (value: string) => {
    if (value === 'default') {
      Editor.removeMark(editor, 'highlight')
    } else {
      Editor.addMark(editor, 'highlight', value)
    }
  }

  return (
    <MenuWrapper>
      <MenuButton ghost onMouseDown={(e) => e.preventDefault()}>
        <TextIcon value={highlightType} />
      </MenuButton>
      <Menu>
        <MenuList>
          {textStyles.map((category, index) => {
            return (
              <MenuListSection key={index} label={category.label}>
                {category.children.map((style, index) => (
                  <MenuListItem
                    key={index}
                    active={highlightType === style.value}
                    onSelect={() => select(style.value)}
                    onMouseDown={(e: MouseEvent) => {
                      e.preventDefault()
                    }}
                  >
                    <Flex>
                      <TextIcon value={style.value} />
                      {style.label}
                    </Flex>
                  </MenuListItem>
                ))}
              </MenuListSection>
            )
          })}
        </MenuList>
      </Menu>
    </MenuWrapper>
  )
}

export default TextStyleButton
