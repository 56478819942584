import { Editor, Transforms, Range, Point, Node, Element } from 'slate'
import { v4 as uuid } from 'uuid'

import { ElementType, ParagraphElement } from 'happitu/src/types/slate-types'

const applyNewNodeIds = (editor: Editor, nodes: Node[]) => {
  // Iterate through the nodes, checking for nodes with a nodeId.
  nodes.forEach((node) => {
    // TODO: Figure out method to guarantee that nodeId exists, like checking if node has a type of the various elements with a nodeId.
    const nodeId: string | undefined = (node as any).nodeId
    if (Element.isElement(node) && !!nodeId) {
      // Update the first node with the same nodeId.
      Transforms.setNodes(
        editor,
        { nodeId: uuid() },
        { match: (n) => Element.isElement(n) && (n as any).nodeId === nodeId, at: [] },
      )
    }
    // Recursively update the children nodes.
    if (Element.isElement(node)) {
      applyNewNodeIds(editor, node.children)
    }
  })
}

const withVoidBehavior = (editor: Editor) => {
  const { insertBreak, deleteBackward, insertFragment } = editor

  editor.insertFragment = (fragment: Node[]) => {
    // Apply new nodeIds on paste to eliminate erratic behavior from duplicate identifiers.
    applyNewNodeIds(editor, fragment)
    insertFragment(fragment)
  }

  editor.insertBreak = () => {
    // When a void node is selected and the user presses enter, insert an empty paragraph below.
    const currentNode = Editor.above(editor, { match: (n) => Editor.isBlock(editor, n) })
    if (currentNode && Editor.isVoid(editor, currentNode[0])) {
      // const nodes = [getNodeDefault(ElementType.Paragraph)]
      const nodes: ParagraphElement[] = [
        {
          type: ElementType.Paragraph,
          indentLevel: 0,
          children: [{ text: '' }],
          nodeId: uuid(),
        },
      ]
      Transforms.insertNodes(editor, nodes, { mode: 'highest' })
      return
    }

    // Fall back on default behavior.
    insertBreak()
  }

  editor.deleteBackward = (...args) => {
    // Handle deleting the currently selected void node.
    const currentNode = Editor.above(editor, { match: (n) => Editor.isBlock(editor, n) })
    const prevNode = Editor.previous(editor, { match: (n) => Editor.isBlock(editor, n) })
    const { selection } = editor

    // Handle cases when the cursor is at the beginning of another node immediately after a void node.
    if (
      currentNode &&
      Element.isElement(currentNode[0]) &&
      prevNode &&
      Editor.isVoid(editor, prevNode[0]) &&
      selection &&
      Range.isCollapsed(selection) &&
      Point.equals(selection.anchor, Editor.start(editor, currentNode[1])) &&
      prevNode[0].type !== ElementType.SectionBreak // Bail out for section breaks. We might want to organize this logic differently.
    ) {
      if (Editor.isEmpty(editor, currentNode[0])) {
        // If the current node is empty, just delete the current node.
        Transforms.removeNodes(editor, { at: currentNode[1] })
      } else {
        // Else select the previous node.
        Transforms.select(editor, prevNode[1])
      }
      return
    }

    // Fall back on default behavior.
    deleteBackward(...args)
  }

  return editor
}

export default withVoidBehavior
