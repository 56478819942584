import { startCase } from 'lodash'

import SearchService from '../searchService'

import { processResponse } from './searchSuggester.context'
import { createFilterKey, convertToString } from './searchSuggester.helpers'
import {
  BaseSuggestion,
  DEFAULT_SUGGESTER_OPTIONS,
  SuggesterOptions,
} from './searchSuggester.types'

import {
  generatedMetadata,
  SearchServices,
  transformSolrFieldToMongo,
  isSolrField,
  GeneratedCollection,
} from 'happitu/src/helpers/generatedMetadata'
import { error, warn } from 'happitu/src/helpers/loggerHelper'
import { suggestRequest } from 'happitu/src/services/happitu-reporting-api/ticketLookupService'

interface CallbackFilter {
  key: string
  name: string
  value: string
  enabled: boolean
}

type FilterMapCallback = (filter: CallbackFilter, index: number) => any

export default class SearchSuggester {
  protected readonly collectionName: GeneratedCollection
  protected readonly searchService: SearchService
  protected searchFilters: Record<
    string,
    {
      name: string
      value: string
    }
  >
  protected enabledSearchFilters: Record<string, boolean>

  /**
   * Sort format: 'field direction' e.g. 'name desc' or 'firstName lastName'
   */
  protected sort: string | null

  constructor(
    collectionName: GeneratedCollection,
    defaultOptions?: Partial<{ sort: string | null }>,
  ) {
    if (generatedMetadata === undefined)
      throw Error(
        `generated metadata is not loaded, cannot create '${name}' search suggester`,
      )

    const searchServiceKey = Object.keys(generatedMetadata.searchServices).find(
      (key) => `${collectionName}.suggester` === key,
    )

    if (!searchServiceKey)
      throw new Error('EqualityRecordSuggester: missing search service')

    this.collectionName = collectionName
    this.searchService = new SearchService(searchServiceKey as SearchServices)
    this.searchFilters = {}
    this.enabledSearchFilters = {}
    this.sort = defaultOptions?.sort || null
  }

  public isFilterEnabled(filterKey: string): boolean {
    return !!this.enabledSearchFilters[filterKey]
  }

  public getSearchFilters() {
    return this.searchFilters
  }

  public mapSearchFilters(callback: FilterMapCallback) {
    return Object.keys(this.searchFilters).map((filterKey, index) => {
      return callback(
        {
          key: filterKey,
          ...this.searchFilters[filterKey],
          enabled: this.enabledSearchFilters[filterKey],
        },
        index,
      )
    })
  }

  resetFilters(): SearchSuggester {
    this.searchFilters = {}
    this.enabledSearchFilters = {}
    return this
  }

  suggestionFields() {
    return this.searchService.suggestionFields()
  }

  toggleSearchFilter(filterKey: string) {
    if (this.enabledSearchFilters[filterKey] !== undefined) {
      this.enabledSearchFilters[filterKey] = !this.enabledSearchFilters[filterKey]
    } else {
      warn(`SearchSuggester: trying to toggle undefined filter: ${filterKey}`)
    }

    return this
  }

  setSearchFilter(field: string, value: string): SearchSuggester {
    if (!this.suggestionFields().includes(field)) {
      error(`SearchSuggester: setting invalid filter field: ${field}`)
      return this
    }
    this.searchFilters[field] = {
      name: startCase(field),
      value: convertToString(value),
    }
    this.enabledSearchFilters[field] = true
    return this
  }

  removeSearchFilter(field: string) {
    delete this.searchFilters[field]
    delete this.enabledSearchFilters[field]
    return this
  }

  countActiveFilters() {
    return Object.keys(this.enabledSearchFilters).reduce((acc, filterKey) => {
      return this.enabledSearchFilters[filterKey] ? acc + 1 : acc
    }, 0)
  }

  setSearchFiltersFromRecord<Record extends Partial<StoreRecord>>(
    record?: Record,
    clearExistingFilters = true,
  ) {
    if (record) {
      const baseFilterSet = clearExistingFilters ? {} : this.searchFilters
      const collectionName = this.searchService.getCollectionName()
      this.searchFilters = this.suggestionFields().reduce((filters, field) => {
        if (isSolrField(collectionName, field)) {
          const attr = transformSolrFieldToMongo(collectionName, field)
          const value = convertToString(record[attr]).trim()
          if (value) {
            filters[field] = {
              name: startCase(field),
              value,
            }
            // Minor side-effect. Set as enabled search filter
            this.enabledSearchFilters[field] = true
          }
        }
        return filters
      }, baseFilterSet)
    }
    return this
  }

  setSort(sort: string): SearchSuggester {
    this.sort = sort
    return this
  }

  clearSort(): SearchSuggester {
    this.sort = null
    return this
  }

  getSort() {
    return this.sort
  }

  // TODO: refactor this class to contain
  async send(searchTerm = '', searchOptions?: Omit<SuggesterOptions, 'sort'>) {
    // Include enabled search filters
    const searchCriteria = this.buildSearchCriteria(searchOptions)
    const response = await suggestRequest<BaseSuggestion>(
      this.searchService.getCollectionName(),
      this.searchService
        .suggestionFields()
        // TODO: find a better way to conditionally use the Whole field
        .map((f) => f)
        .join(','),
      searchTerm === ''
        ? Object.keys(this.searchFilters)
            .map((filter) => this.searchFilters[filter].value)
            .join(',')
        : searchTerm.trim(),
      searchCriteria,
    )

    processResponse({
      nonRelayKeys: response.nonRelayKeys,
      [this.searchService.getCollectionName()]: searchOptions?.includeRecords
        ? response[this.searchService.getCollectionName()]
        : response.suggestions,
    })
    return response
  }

  private buildSearchCriteria(searchOptions: SuggesterOptions = {}) {
    return Object.keys(this.searchFilters).reduce<
      Record<string, string | number | null | undefined | boolean>
    >(
      (criteria, filterKey: keyof SuggesterOptions) => {
        if (this.enabledSearchFilters[filterKey]) {
          criteria[createFilterKey(filterKey)] = this.searchFilters[filterKey].value
        }
        return criteria
      },
      {
        ...DEFAULT_SUGGESTER_OPTIONS,
        ...searchOptions,
        sort: this.sort,
      },
    ) as SuggesterOptions
  }
}
