import styled from 'styled-components'
import { ifProp } from 'styled-tools'

import Input from './Input'

import { inputFocus } from 'happitu/src/themes/mixins/form'
import theme from 'theme'

export const DropdownInput = styled(Input)`
  align-items: center;
  background: ${ifProp(
    'focus',
    theme('dropdown.focus.background'),
    theme('dropdown.background'),
  )};
  border-color: transparent;
  cursor: pointer;
  display: flex;
  height: 38px;
  padding: 0;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    user-select: none;
  }

  ${ifProp('focus', inputFocus)}
`

export const DisplayValue = styled.div`
  background: transparent;
  border: 0;
  box-shadow: none;
  flex-grow: 1;
  padding: ${theme('input.padding')};
  user-select: none;

  &:focus {
    box-shadow: none;
  }
`
