import request from './request'
import { handleSuccess, handleReject } from './responseHandler'

import { LocationRecord } from 'happitu/src/models/location'

interface BaseLocationResponse {
  locations: LocationRecord[]
}

export const getLocationRequest = (locationId: ID) =>
  new Promise<BaseLocationResponse>((resolve, reject) =>
    request
      .get(`/locations/${locationId}`)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )

export const createLocationRequest = (partialLocation: Partial<LocationRecord>) =>
  new Promise<BaseLocationResponse>((resolve, reject) =>
    request
      .post('/locations')
      .send(partialLocation)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )

export const updateLocationRequest = (
  locationId: ID,
  partialLocation: Partial<LocationRecord>,
) =>
  new Promise<BaseLocationResponse>((resolve, reject) =>
    request
      .put(`/locations/${locationId}`)
      .send(partialLocation)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )

export const deleteLocationRequest = (locationId: ID) =>
  new Promise<BaseLocationResponse>((resolve, reject) =>
    request
      .delete(`/locations/${locationId}`)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )

// prettier-ignore
export const updateLocationHelpTopicsRequest = (
  locationId: ID,
  changes: { add: ID[], remove: ID[] },
) =>
  new Promise<BaseLocationResponse>((resolve, reject) =>
    request
      .put(`/locations/${locationId}/helpTopicImpression`)
      .send(changes)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )

// prettier-ignore
export const updateLocationWorkflowsRequest = (
  locationId: ID,
  changes: { add: ID[], remove: ID[] },
) =>
  new Promise<BaseLocationResponse>((resolve, reject) =>
    request
      .put(`/locations/${locationId}/workflowIds`)
      .send(changes)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )
