import { css } from 'styled-components'
import { ifProp, prop, switchProp } from 'styled-tools'

import { ButtonProps } from './button.types'

import getStyle from 'happitu/src/getStyle'
import defaultTheme from 'happitu/src/themes/defaultTheme'
import theme, { font, metric } from 'happitu/src/themes/theme'

const getThemeAttrs = (props: ButtonProps, type: string) => css`
  ${props.disabled
    ? css`
        background: ${getStyle(
          `button-${type}-inactive-background` as keyof typeof defaultTheme,
        )};
        color: ${getStyle(`button-${type}-inactive-color` as keyof typeof defaultTheme)};
      `
    : css`
        background: ${getStyle(
          `button-${type}-default-background` as keyof typeof defaultTheme,
        )};
        border-color: ${getStyle(
          `button-${type}-borderColor` as keyof typeof defaultTheme,
        )};
        color: ${getStyle(`button-${type}-default-color` as keyof typeof defaultTheme)};

        &:hover {
          background: ${getStyle(
            `button-${type}-hover-background` as keyof typeof defaultTheme,
          )};
          color: ${getStyle(`button-${type}-hover-color` as keyof typeof defaultTheme)};
        }

        &:active {
          background: ${getStyle(
            `button-${type}-active-background` as keyof typeof defaultTheme,
          )};
          color: ${getStyle(`button-${type}-active-color` as keyof typeof defaultTheme)};
        }
      `}
`

const buttonType = (props: ButtonProps) => {
  if (props.primary) {
    return getThemeAttrs(props, 'primary')
  }

  if (props.secondary) {
    return getThemeAttrs(props, 'secondary')
  }
  if (props.dark) {
    return getThemeAttrs(props, 'dark')
  }
  if (props.warn) {
    return getThemeAttrs(props, 'warn')
  }
  return getThemeAttrs(props, 'base')
}

const styles = css<ButtonProps>`
  border-radius: ${ifProp('rounded', '20px', metric('largeBorderRadius'))};
  border: ${theme('button.border')};
  color: ${theme('button.color')};
  cursor: pointer;
  display: inline-flex;
  flex-grow: ${prop('grow') || 0};
  font-size: ${theme('button.fontSize')};
  font-weight: ${theme('button.fontWeight')};
  line-height: 1em;
  outline: none;
  padding: ${theme('button.padding')};
  position: relative;
  text-align: center;
  justify-content: center;
  user-select: none;
  ${buttonType}

  ${ifProp(
    'dark',
    css`
      box-shadow: 0 0 0 1px ${getStyle('dark-border-color')};
    `,
  )}

  ${switchProp('size', {
    large: css`
      padding: 0.75em;
      font-size: ${font('size', 'medium')};
    `,
    medium: css`
      font-size: ${font('size', 'medium')};
    `,
    small: css`
      font-size: ${font('size', 'small')};
      padding: 4px 5px;
    `,
  })}
  ${ifProp(
    'ghost',
    css`
      background: transparent;
      border: 1px solid transparent;
      box-shadow: none;

      ${ifProp(
        'primary',
        css`
          color: ${getStyle('button-primary-default-background')};

          &:active {
            background: transparent;
            border-color: transparent;
            color: ${getStyle('button-primary-active-background')};
          }
        `,
      )}

      ${ifProp(
        'inactive',
        css`
          color: ${getStyle('button-base-inactive-color')};
        `,
      )}
    `,
  )}

  ${ifProp(
    'highlight',
    css`
      background: ${getStyle('button-base-highlight-background')};
      color: ${getStyle('button-primary-default-background')};
    `,
  )}



  ${(props) =>
    (props.disabled || props.loading) &&
    css`
      border-color: transparent;
      cursor: default;
      opacity: 0.5;
    `}

  ${(props) =>
    props.noWrap &&
    css`
      white-space: nowrap;
    `}


  ${ifProp(
    'rounded',
    css`
      padding-left: 1em;
      padding-right: 1em;
    `,
  )}


  i {
    opacity: 0.8;

    &:not(:last-child) {
      margin-right: 0.5em;
    }
  }
`

export default styles
