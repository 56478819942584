import { omit } from 'lodash'
import React from 'react'

import { FieldProps } from '../FinalFormFields.types'

import {
  ErrorFigure,
  FieldFigure,
  Input,
  InputWrapper,
  NextFieldWrapper,
  InsetLabelContent,
} from 'happitu/src/components/Fields/FieldUtils'

const TextField = (props: FieldProps<string>) => {
  const {
    label,
    footnote,
    meta,
    className,
    input,
    style,
    type = 'text',
    ...otherProps
  } = props

  return (
    <NextFieldWrapper className={className} style={style}>
      <InputWrapper>
        <Input
          noLabel={!label}
          type={type}
          {...input}
          {...omit(otherProps, ['selectOnClick'])}
        />
        {!!label && (
          <InsetLabelContent required={otherProps.required}>{label}</InsetLabelContent>
        )}
      </InputWrapper>
      {meta.touched && meta.error ? (
        <ErrorFigure>{meta.error}</ErrorFigure>
      ) : (
        <FieldFigure>{footnote}</FieldFigure>
      )}
    </NextFieldWrapper>
  )
}

export default TextField
