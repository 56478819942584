import { createContext as reactCreateContext } from 'react'

import { RelayAction } from 'happitu/src/actions/relay'

type PartialReduxState = Partial<
  ReduxState & {
    currentUser: CurrentUserRecord
    route: Router5Route
  }
>

interface MappedReduxHandlers {
  _reduxHandlers?: {
    [key: string]: Function
  }
}

interface MappedReduxState {
  _reduxState?: PartialReduxState
}

export interface ContextProviderProps extends MappedReduxHandlers, MappedReduxState {
  children: React.ReactNode
}

type GlobalStoreKeys =
  | 'organization'
  | 'currentUser'
  | 'route'
  | 'users'
  | 'workflows'
  | 'teams'

// Map global objects to the local context.
export function mapGlobalStateToProps(stores: GlobalStoreKeys[]) {
  return (state: ReduxState): MappedReduxState => {
    const reduxState: PartialReduxState = {}

    stores.forEach((store) => {
      switch (store) {
        case 'users':
          return (reduxState[store] = state.users)
        case 'currentUser':
          return (reduxState[store] = state.user)
        case 'workflows':
          return (reduxState[store] = state.workflows)
        case 'teams':
          return (reduxState[store] = state.teams)
        case 'route':
          return (reduxState[store] = state.router.route)
        case 'organization':
          return (reduxState[store] = state.organization)
        default:
          return
      }
    })

    return {
      _reduxState: reduxState,
    }
  }
}

export interface BaseContext<S> {
  handlers: {
    [key: string]: Promise<RelayAction<any>> | Function
  }
  state: Partial<S>
}

export default function createContext<S>() {
  return reactCreateContext<BaseContext<S>>({
    handlers: {},
    state: {},
  })
}
