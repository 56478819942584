import React from 'react'
import styled from 'styled-components'

import RecordSelect from './RecordSelect'
import { BaseSelectProps } from './Select.types'

import { IconProps } from 'happitu/src/components/Icons/FieldIcon'
import { Anchor } from 'happitu/src/components/menus/Menu.types'
import { GeneratedCollection } from 'happitu/src/helpers/generatedMetadata'
import { getRandomTagColor } from 'happitu/src/helpers/tagHelper'
import { processResponse } from 'happitu/src/models/searchSuggester'
import { createTagRequest } from 'happitu/src/services/happitu-api/tagsService'

interface Props extends BaseSelectProps {
  createPlaceholder?: string
  icon?: IconProps
  menuAnchor?: Partial<Anchor>
  onCreate?: (tag: TagRecord) => void
  onSelect: (value: ID[] | ID) => void
  value?: ID | null
}

const TagSelect = (props: Props) => {
  const handleCreate = async (value: string) => {
    const response = await createTagRequest({ name: value, color: getRandomTagColor() })
    processResponse(response)
    if (typeof props.onCreate === 'function') props.onCreate(response.tags[0])
  }

  return (
    <RecordSelect
      {...props}
      collectionName={GeneratedCollection.Tags}
      onCreate={handleCreate}
    />
  )
}

export default styled(TagSelect)``
