import React, { ReactNode } from 'react'
import styled from 'styled-components'

import Loader from './loader'

import Icon from 'happitu/src/components/Icon'

interface LoadingLabelProps {
  invert?: boolean
  loadingValue: string
  icon?: string
}

interface ButtonLabelProps extends LoadingLabelProps {
  loading?: boolean
  value: ReactNode
}

const LoadingLabel = (props: LoadingLabelProps) => {
  return (
    <>
      <Loader invert={props.invert} />
      <span style={{ marginLeft: 30 }}>{props.loadingValue}</span>
    </>
  )
}

const Value = styled.span`
  align-items: center;
  display: inline-flex;
`

const ButtonLabel = ({
  loading,
  value,
  icon,
  ...loadingLabelProps
}: ButtonLabelProps) => {
  return loading ? (
    <LoadingLabel {...loadingLabelProps} />
  ) : (
    <>
      {icon && <Icon type={icon} />}
      {!!value && <Value>{value}</Value>}
    </>
  )
}

export default ButtonLabel
