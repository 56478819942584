import memoize from 'memoize-one'

export const fadeTransition = memoize((duration = 0.15) => ({
  animate: { opacity: 1 },
  initial: { opacity: 0 },
  exit: { opacity: 0 },
  transition: { duration: duration },
}))

export const SLIDE_UP = {
  animate: { y: 0 },
  initial: { y: 25 },
  exit: { y: 15 },
  transition: { duration: 0.15 },
}

export const SLIDE_DOWN = {
  animate: { y: 0 },
  initial: { y: -25 },
  exit: { y: -15 },
  transition: { duration: 0.15 },
}

export const SLIDE_LEFT = {
  animate: { x: 0 },
  initial: { x: 25 },
  exit: { x: 15 },
  transition: { duration: 0.15 },
}

export const SLIDE_RIGHT = {
  animate: { x: 0 },
  initial: { x: -25 },
  exit: { x: -15 },
  transition: { duration: 0.15 },
}
