import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import { Button } from 'happitu/src/components/button'
import getStyle from 'happitu/src/getStyle'
import { font, metric } from 'theme'

export const ApplyButton = styled(Button)`
  height: auto;
  padding: 0.4em 0.5em;
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translateY(-50%);
`

export const Wrapper = styled(motion.div)<{ isBelow: boolean }>`
  left: 50%;
  position: absolute;
  user-select: none;
  z-index: 3;

  ${ifProp(
    'isBelow',
    css`
      bottom: -3em;
    `,
    css`
      top: -3em;
    `,
  )}
`

const sharedStyles = css`
  border-radius: ${metric('borderRadius')};
  border: 1px solid ${getStyle('border-color')};
  box-shadow: ${getStyle('boxShadow')};
  font-size: ${font('size', 'medium')};
  font-weight: ${font('weight', 'medium')};
  line-height: 1.4;
  width: 25rem;
`

export const ViewContainer = styled.div`
  ${sharedStyles}

  align-items: center;
  background: ${getStyle('app-background')};
  display: flex;
  padding-left: 0.75em;
  padding-right: 0.5em;
  white-space: nowrap;
`

export const StyledInput = styled.input`
  ${sharedStyles}

  color: ${getStyle('text-default-color')};
  padding: 0.5em 0.75em;
  padding-right: 4.5em;

  &:focus {
    border-color: transparent;
    box-shadow: 0px 0px 0px 2px ${getStyle('border-active-color')};
  }
`

export const LinkContainer = styled.span`
  color: ${getStyle('text-active-color')};
  overflow: hidden;
  padding: 0.5em 0;
  text-overflow: ellipsis;
`

export const Spacer = styled.span`
  margin: 0 auto;
`
