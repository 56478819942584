import Store from './createStore'

import { RelayAction } from 'happitu/src/actions/relay'

interface Handlers<T extends StoreRecord> {
  [key: string]: (state: Store<T>, action: RelayAction<any>) => Store<T>
}

/**
 * Minimize reducer boilerplate by instantiating reducer handlers
 * with this method.
 */
export default function createReducer<T extends StoreRecord>(
  initialState: Store<T>,
  handlers: Handlers<T>,
): Function {
  return function reducer(state: Store<T> = initialState, action: RelayAction<any>) {
    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action)
    } else {
      return state
    }
  }
}
