import { AnimatePresence, motion, Transition, Variants } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'

import { IconButton } from 'happitu/src/components/Buttons'
import getStyle from 'happitu/src/getStyle'
import { font } from 'theme'

interface Props {
  titles: React.ReactNode[]
  onBack?: React.MouseEventHandler
  onClose?: () => void
  showCloseButton?: boolean
  className?: string
  exit?: string
  custom?: number
  animate?: string
  initial?: string
  transition?: Transition
  variants?: Variants
}

function MultiStepMenuHeader(props: Props) {
  const headerRef = React.useRef(null)
  const currentTitle = props.titles[props.titles.length - 1]
  return (
    <header className={props.className} ref={headerRef}>
      {props.titles.length > 1 && (
        <IconButton
          className="back-button"
          icon="caret-left"
          ghost
          onClick={props.onBack}
        />
      )}
      <AnimatePresence initial={false} custom={props.custom}>
        <motion.h2
          animate={props.animate}
          custom={props.custom}
          exit={props.exit}
          initial={props.initial}
          key={props.titles.length}
          style={{ top: 0 }}
          transition={props.transition}
          variants={props.variants}
        >
          {currentTitle}
        </motion.h2>
      </AnimatePresence>

      {props.showCloseButton && (
        <IconButton className="close-button" icon="close" ghost onClick={props.onClose} />
      )}
    </header>
  )
}

export default styled(MultiStepMenuHeader)`
  align-items: center;
  border-bottom: 1px solid ${getStyle('border-color')};
  display: flex;
  margin: 0;
  overflow: hidden;
  padding: 1em;
  position: relative;

  h2 {
    align-items: center;
    color: ${getStyle('text-default-color')};
    display: flex;
    font-size: ${font('size', 'medium')};
    font-weight: ${font('weight', 'medium')};
    height: 100%;
    justify-content: start;
    padding-left: 0.5em;
    pointer-events: none;
    position: absolute;
    user-select: none;
    white-space: nowrap;
  }

  ${IconButton} {
    opacity: 0.8;
    transition: opacity 150ms;
    z-index: 1;
  }

  .back-button {
    opacity: ${(props: Props) => (props.titles.length > 1 ? 0.8 : 0)};
  }

  .close-button {
    font-size: 0.9em;
    position: absolute;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);
  }
`
