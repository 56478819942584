import autobind from 'autobind-decorator'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import styles from './DropdownOption.scss'

import { MenuListItemAction } from 'happitu/src/components/_DEPRECATED/_DEPRECATED_Menu'

export default class DropdownOption extends PureComponent {
  static propTypes = {
    isSelected: PropTypes.bool,
    label: PropTypes.any,
    subLabel: PropTypes.string,
    isHovering: PropTypes.bool,
    onClick: PropTypes.func,
    onHover: PropTypes.func,
    multiple: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  @autobind
  handleClick() {
    this.props.onClick(this.props.value)
  }

  @autobind
  handleHover() {
    this.props.onHover(this.props.value)
  }

  render() {
    const classes = classNames(
      {
        [styles.active]: this.props.isSelected,
        [styles.multiple]: this.props.multiple,
      },
      styles.option,
    )

    return (
      <MenuListItemAction
        onClick={this.handleClick}
        onMouseEnter={this.handleHover}
        active={this.props.isSelected}
        hover={this.props.isHovering}
        className={classes}
        multiple={this.props.multiple}
        title={this.props.label}
      >
        <i className={styles.box} />
        <div>
          <span className={styles.label}>{this.props.label}</span>
          {!!this.props.subLabel && (
            <span className={styles.subLabel}>{this.props.subLabel}</span>
          )}
        </div>
      </MenuListItemAction>
    )
  }
}
