import isHotkey from 'is-hotkey'
import { useCallback, useEffect, useRef } from 'react'

export enum Hotkey {
  // Common shortcuts
  Close = 'Escape',
  Delete = 'Delete',
  Backspace = 'Backspace',
  HardDelete = 'Mod+Delete', // TODO: Currently broken.
  Save = 'Mod+s',
  SaveAs = 'Mod+Shift+s',
  New = 'Mod+Alt+n', // TODO: Currently broken.
  Refresh = 'Mod+Alt+r',
  Open = 'Mod+o',
  Download = 'Mod+Alt+d', // TODO: Currently broken.
  Favorite = 'Mod+Alt+y',

  // Ticketing
  FocusNotes = 'Mod+Shift+k',
  ToggleHelpTopics = 'Alt+h',

  // Select
  Enter = 'Enter',
  Down = 'ArrowDown',
  Up = 'ArrowUp',
  Tab = 'Tab',
  Select = ' ',
  Beginning = 'Home',
  End = 'End',

  ZoomIn = 'mod+=',
  ZoomOut = 'mod+-',
  ZoomDefault = 'mod+0',

  // MultiValue Field
  Comma = ',',
  Space = ' ',
  Pipe = '|',

  CopyHelpTopic = 'Alt+C',

  // Audio Player
  TogglePlayback = 'Space',
  VolumeUp = 'ArrowUp',
  VolumeDown = 'ArrowDown',
  SkipForward5 = 'ArrowRight',
  SkipBackward5 = 'ArrowLeft',
  Mute = 'm',
  DecreasePlaybackRate = ',', // '<' key
  IncreasePlaybackRate = '.', // '>' key
}

export default function useHotkey(
  hotkey: Hotkey,
  onKeyDown: Function,
  activeBinding = true,
  deps: any[] = [],
) {
  const handler = useRef<Function>()

  useEffect(() => {
    handler.current = onKeyDown
  }, [onKeyDown])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (activeBinding && isHotkey(hotkey)(event) && handler.current) {
        event.preventDefault()
        handler.current(event)
      }
    },
    [activeBinding, hotkey, ...deps],
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [hotkey, activeBinding, ...deps])
}
