import styled from 'styled-components'

import getStyle from 'happitu/src/getStyle'
import { font } from 'theme'

export default styled.h2.attrs({
  'data-selectable': 'false',
})`
  color: ${getStyle('text-sub-color')};
  font-size: ${font('size', 'small')};
  font-weight: ${font('weight', 'medium')};
  padding: 1rem 0.75rem;
  padding-bottom: 0;
  margin-bottom: 1rem;
`
