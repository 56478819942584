import { Editor, Transforms, Range, Point, NodeEntry, Node, Element } from 'slate'
import { v4 as uuid } from 'uuid'

import {
  removeAllMarks,
  setBlockType,
  unindentLine,
  unindentSelection,
} from '../../formattingHelper'

import withBlockMarkdown from './withBlockMarkdown'
import withInlineMarkdown from './withInlineMarkdown'
import withListItems from './withListItems'
import withSectionBreaks from './withSectionBreaks'

import { MULTILINE_BLOCK_TYPES } from 'happitu/src/types/models/richTextEditor'
import { ElementType, ParagraphElement } from 'happitu/src/types/slate-types'

const withMarkdown = (editor: Editor) => {
  const { deleteBackward, insertBreak } = editor

  // eslint-disable-next-line complexity
  editor.insertBreak = () => {
    const currentNode = Editor.above(editor, {
      match: (n) => Editor.isBlock(editor, n),
    })
    const block = currentNode ? currentNode[0] : undefined
    const path = currentNode ? currentNode[1] : undefined
    const { selection } = editor

    if (
      Element.isElement(block) &&
      block?.type === ElementType.Paragraph &&
      Node.string(block) === '' &&
      block.indentLevel > 0 &&
      currentNode
    ) {
      // Unindent empty paragraphs on enter.
      unindentLine(editor, currentNode as NodeEntry<typeof block>)
      return
    } else if (
      block &&
      Element.isElement(block) &&
      MULTILINE_BLOCK_TYPES.includes(block.type)
    ) {
      // Expected behavior is that users can enter a new line between paragraphs, but clear escape out of it after the second new line.
      const prevNode = Editor.previous(editor, {
        match: (n) => Editor.isBlock(editor, n),
      })
      if (
        path &&
        prevNode &&
        Element.isElement(prevNode[0]) &&
        Element.isElement(block) &&
        prevNode[0].type === block.type &&
        Editor.string(editor, prevNode[1]) === '' &&
        Editor.string(editor, path) === ''
      ) {
        setBlockType(editor, ElementType.Paragraph)
        Transforms.removeNodes(editor, { at: prevNode[1] })
      } else {
        insertBreak()
        removeAllMarks(editor)
      }
      return
    } else if (
      block &&
      path &&
      selection &&
      Range.isCollapsed(selection) &&
      Point.equals(selection.anchor, Editor.start(editor, path))
    ) {
      // If the cursor is at the start, just insert a plain paragraph above.
      if (Editor.string(editor, path) !== '') {
        // const nodes = [getNodeDefault(ElementType.Paragraph)]
        const nodes: ParagraphElement[] = [
          {
            type: ElementType.Paragraph,
            indentLevel: 0,
            children: [{ text: '' }],
            nodeId: uuid(),
          },
        ]
        Transforms.insertNodes(editor, nodes, { match: (n) => Editor.isBlock(editor, n) })
        // Then move the selection to the start of the next node.
        const nextNode = Editor.next(editor, { match: (n) => Editor.isBlock(editor, n) })
        if (nextNode) {
          const newSelection = {
            anchor: Editor.start(editor, nextNode[1]),
            focus: Editor.start(editor, nextNode[1]),
          }
          Transforms.setSelection(editor, newSelection)
        }
      } else {
        setBlockType(editor, ElementType.Paragraph)
        insertBreak()
      }
      return
    }

    // Default behavior is to insert a plain paragraph node next.
    // TODO: Just insert a new node instead of converting one.
    // TODO: Maybe handle this as a true default within useEditor.
    insertBreak()
    if (block && Element.isElement(block) && block.type !== ElementType.Paragraph) {
      setBlockType(editor, ElementType.Paragraph)
    }
    removeAllMarks(editor)
  }

  // Unindent paragraphs on backspace if cursor is at the beginning of the line.
  editor.deleteBackward = (...args) => {
    const { selection } = editor

    // Check that the selection is not a range.
    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        match: (n) => Editor.isBlock(editor, n),
      })

      if (match) {
        // Get the block and path of the current selection.
        const [block, path] = match
        // Get the starting point of the selection.
        const start = Editor.start(editor, path)

        if (
          Element.isElement(block) &&
          block.type === ElementType.Paragraph &&
          Point.equals(selection.anchor, start) &&
          block.indentLevel > 0
        ) {
          // Unindent paragraphs on backspace.
          unindentSelection(editor)
          return
        }
      }

      // Fallback on default behavior.
      deleteBackward(...args)
    }
  }

  return editor
}

const markdownPlugins = [
  withMarkdown,
  withInlineMarkdown,
  withBlockMarkdown,
  withSectionBreaks,
  withListItems,
]

const composedPlugins = (editor: Editor) =>
  markdownPlugins.reduce((acc, cur) => {
    return cur(acc)
  }, editor)

export default composedPlugins
