import { startCase } from 'lodash'

import {
  SearchDocumentField,
  SearchFieldPresets,
  GeneratedCollection,
  GeneratedFieldType,
  SearchDynamic,
} from 'happitu/src/helpers/generatedMetadata'
import {
  SearchDynamicIdentifier,
  SearchQueryField,
  SearchOutputField,
} from 'happitu/src/types/searchQueryTypes'

function getRelationPrefix(relation?: string) {
  return relation !== undefined && relation !== '' ? `${relation}.` : ''
}

export const makeSearchFieldKey = (
  fieldName: string,
  relation?: string,
  dynamicIdentifier?: SearchDynamicIdentifier,
): string => {
  return (
    `${getRelationPrefix(relation)}${fieldName}` +
    (dynamicIdentifier
      ? `>${dynamicIdentifier.identifier}|${dynamicIdentifier.type}`
      : '')
  )
}

export default class SearchField implements SearchDocumentField {
  public readonly collectionName: GeneratedCollection
  public readonly fieldName: string
  public readonly alias: string
  public readonly relation?: string
  public readonly relationAlias?: string
  public readonly references?: string
  public readonly type: GeneratedFieldType
  public readonly hasSuggester: boolean
  public readonly isInternal: boolean
  public readonly isIndexed: boolean
  public readonly isNGram: boolean
  public readonly presets: SearchFieldPresets
  public readonly dynamicIdentifiers?: SearchDynamicIdentifier[]
  public readonly dynamic?: SearchDynamic

  constructor(
    searchDocumentField: SearchDocumentField,
    collectionName: GeneratedCollection,
    fieldName: string,
    relation?: string,
    relationAlias?: string,
  ) {
    this.dynamic = searchDocumentField.dynamic
    this.collectionName = collectionName
    this.fieldName = fieldName
    this.references = searchDocumentField.references
    this.relation = relation
    this.type = searchDocumentField.type
    this.presets = searchDocumentField.presets
    this.hasSuggester = searchDocumentField.hasSuggester
    this.isInternal = searchDocumentField.isInternal
    this.isNGram = searchDocumentField.isNGram
    this.alias = searchDocumentField.alias
    this.relationAlias = relationAlias || startCase(relation) || startCase(collectionName)
    this.dynamicIdentifiers = searchDocumentField.dynamicIdentifiers
  }

  static fromQueryField(searchQueryField: SearchQueryField): SearchField {
    return new SearchField(
      { dynamicIdentifiers: searchQueryField.dynamicIdentifiers } as SearchDocumentField,
      searchQueryField.model as GeneratedCollection,
      searchQueryField.field,
      searchQueryField.relation,
    )
  }

  public key(dynamicIdentifiers = this.dynamicIdentifiers): string {
    return makeSearchFieldKey(
      this.fieldName,
      this.relation,
      dynamicIdentifiers ? dynamicIdentifiers[0] : undefined,
    )
  }

  public getName(): string {
    return this.alias || this.fieldName
  }

  public getRelation(): string {
    return this.relation !== undefined ? this.relation : this.collectionName
  }

  public getDisplayName(alias?: string): string {
    return alias || this.alias || startCase(this.fieldName)
  }

  public getRelationName() {
    return this.relationAlias
  }

  public isDynamic(): boolean {
    return typeof this.dynamic === 'object'
  }

  public getOutputFormat(
    alias?: string,
    dynamicIdentifiers?: SearchDynamicIdentifier[],
  ): SearchOutputField {
    return {
      alias: this.getDisplayName(alias),
      dynamicIdentifiers: dynamicIdentifiers,
      field: this.fieldName,
      model: this.collectionName,
      relation: this.getRelation(),
    }
  }

  public isExactMatch(): boolean {
    return /Whole$/.test(this.fieldName)
  }

  // public suggester (): SearchSuggesterQuery {
  //   return new SearchSuggesterQuery(this.collectionName)
  // }
}
