import { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import { boolableProp } from 'happitu/src/themes/helpers'

export interface GrowProps {
  grow?: number | string | boolean
}

export const growMixin = css<GrowProps>`
  ${ifProp(
    'grow',
    css`
      flex-grow: ${boolableProp('grow')};
    `,
  )}
`
