import { createGlobalStyle } from 'styled-components'

import { input, inputFocus } from './mixins/form'

import getStyle from 'happitu/src/getStyle'
import { font } from 'theme'

export default createGlobalStyle`
  html {
    -moz-osx-font-smoothing: grayscale;
  }

  html, body {
    color: ${getStyle('text-default-color')};
    font-size: ${font('size')};
    height: 100%;
    margin: 0;
    padding: 0;
  }

  body {
    background: ${getStyle('app-background')};
    /* font-family: ${font('family')}; */

    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-feature-settings: "liga", "kern";
    font-size: 15px;

    line-height: 1.143em;
    -webkit-font-smoothing: antialiased;
  }

  strong {
    font-weight: ${font('weight', 'medium')};
  }

  #root {
    height: 100%;
  }

  * {
    box-sizing: border-box;
  }

  h1 {
    margin-bottom: 40px;
  }

  h3 {
    line-height: 1.33em;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: ${font('weight', 'normal')};
    margin: 0;
  }

  sub {
    color: ${getStyle('text-light-color')};
  }

  p {
    margin: 0;
    margin-bottom: 1em;
  }

  button,
  input,
  textarea {
    outline: none;
    font-family: ${font('family')};
    font-weight: ${font('weight')};
    font-size: 1em;
    line-height: 22px;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  input,
  textarea {
    ${input}

    &:focus {
      ${inputFocus};
    }
  }

  button:not(:disabled) {
    cursor: pointer;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
    color: ${getStyle('link-default-color')};
    font-weight: ${font('weight', 'medium')};
    overflow-wrap: break-word;


    &:hover {
      color: ${getStyle('link-hover-color')};
    }
  }

  ::selection {
    background: ${getStyle('textSelection-background')};
    color: ${getStyle('textSelection-color')};
  }
`
