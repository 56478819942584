import { StripeElementChangeEvent } from '@stripe/stripe-js'

import * as regex from './regex'

type ValidationMessage = string | undefined

export const isJSONString = (input: string): boolean => {
  try {
    JSON.parse(input)
  } catch (e) {
    return false
  }
  return true
}

export const validateJSON = (
  message = 'Please enter a valid JSON object',
  optional = true,
) => (value: string): ValidationMessage => {
  return (value === '' && optional) || (value && isJSONString(value))
    ? undefined
    : message
}

export const requiredValidator = (message = 'This field is required') => (
  value: string,
) => (value ? undefined : message)

export const numberValidator = (message = 'Please enter a number') => (value: string) =>
  isNaN(parseInt(value)) ? message : undefined

export const numFieldValidator = (message = 'Please enter a number') => (
  value?: number | null,
) => (typeof value !== 'number' || isNaN(value) ? message : undefined)

const maybe = (predicate: boolean, message: string) => (predicate ? message : undefined)
const length = (value: string | number): number =>
  typeof value === 'string' ? value.length : value

export const min = (minLength: number) => (value: string | number) =>
  maybe(length(value) <= minLength, `Please enter more than ${minLength} characters`)

export const max = (maxLength: number) => (value: string | number) =>
  maybe(length(value) >= maxLength, `Please enter less than ${maxLength} characters`)

export const requireEmail = requiredValidator()
export const requireFirstName = requiredValidator('Please provide your first name')
export const requireLastName = requiredValidator('Please provide your last name')
export const requirePassword = requiredValidator('Please enter a password')
export const requireTag = requiredValidator('Tag name is required')

export const fieldValidator = (message?: string) => (value: string) =>
  value.length > 0 ? undefined : message || 'Please select a file to upload'

export const urlValidator = (message?: string) => (value: string) =>
  regex.URL.test(value) ? undefined : message || 'Please enter a valid URL'

export const hostValidator = (message?: string) => (value: string) =>
  regex.HOST.test(value) ? undefined : message || 'Please enter a valid host name'

export const emailValidator = (message?: string) => (value: string) =>
  regex.EMAIL.test(value) ? undefined : message || 'Please enter a valid email address'

export const requireValidEmail = emailValidator()

export const emailMultiValidator = (message?: string) => (value: string[]) => {
  const errors = {} as Record<number, string>
  value.map((val, index) => {
    if (!regex.EMAIL.test(val) && !!val) {
      errors[index] = message || 'Please enter a valid email address'
    }
  })
  return Object.keys(errors).length ? errors : undefined
}

export const telephoneValidator = (message?: string) => (value: string) => {
  const testValue = value.replace(/\W+/g, '')
  return regex.TELEPHONE.test(testValue)
    ? undefined
    : message || 'Please enter a valid telephone number'
}

export const dateValidator = (message?: string) => (value: string | string[]) => {
  const isValid = Array.isArray(value)
    ? !value.map((val) => regex.DATE.test(val)).includes(false)
    : regex.DATE.test(value)
  return isValid ? undefined : message || 'Please enter a valid date'
}

export const hexColorValidator = (message?: string) => (value: string) =>
  regex.HEX_COLOR.test(value) ? undefined : message || 'Invalid hex color'

export const requireValidHex = hexColorValidator()

export const matchFieldValidator = (matchKey: string, message?: string) => (
  value: string,
  formValues: Record<string, string>,
) => {
  return formValues[matchKey] === value
    ? undefined
    : message || 'This field does not match'
}

export const requiredOptionValidator = (message = 'Please select one option') => (
  values: string[],
) => {
  return values.filter((v) => v !== '').length === 0 ? message : undefined
}

const requiredAddressFieldIndexes = [0, 3, 4, 5]
const requiredPostalFieldIndexes = [3, 4, 5]

export const requireAddress = (message = 'Please enter a valid address') => (
  values: string[],
) => (element: WorkflowElementRecord) => {
  const requiredIndexes = element.attributes.hasStreetAddress
    ? requiredAddressFieldIndexes
    : requiredPostalFieldIndexes
  return requiredIndexes.find((index) => !values[index]) !== undefined
    ? message
    : undefined
}

export interface PasswordParam {
  key: string
  label: string
  message: string
  test: RegExp
}

export const passwordParams = [
  {
    key: 'lowercase',
    label: 'One lowercase character',
    message: 'Make sure your password has at least one lowercase character',
    test: regex.ONE_LOWER_CASE_LETTER,
  },
  {
    key: 'uppercase',
    label: 'One uppercase character',
    message: 'Make sure your password has at least one uppercase character',
    test: regex.ONE_UPPER_CASE_LETTER,
  },
  {
    key: 'number',
    label: 'One number',
    message: 'Make sure your password has at least one number',
    test: regex.ONE_NUMBER,
  },
  {
    key: 'special',
    label: 'One special character',
    message: 'Make sure your password has at least one special character',
    test: regex.ONE_SPECIAL_CHARACTER,
  },
  {
    key: 'length',
    label: '8 characters minimum',
    message: 'Your password must be at least 8 characters long',
    test: regex.MINIMUM_EIGHT_CHARACTERS,
  },
] as Array<PasswordParam>

export const validPassword = (value: string): string | void => {
  for (let p = 0; p < passwordParams.length; p++) {
    if (!passwordParams[p].test.test(value)) {
      return passwordParams[p].message
    }
  }
}
export const goodPasswordHelper = (value = ''): Record<string, boolean> => {
  const warnings = {} as Record<string, boolean>
  passwordParams.forEach((prop) => {
    const propTest = prop.test.test(value)
    warnings[prop.key] = propTest
  })
  return warnings
}

export const composeValidators = (...validators: Function[]) => (
  value: string | string[],
  allValues: Record<string, string | string[]>,
) =>
  validators.reduce((error, validator) => error || validator(value, allValues), undefined)

export const validCreditCard = (event: StripeElementChangeEvent) => event?.error?.message
