import React from 'react'
import styled from 'styled-components'

import getStyle from 'happitu/src/getStyle'

const Inner = styled.div<{ progress: number }>`
  background: ${getStyle('progressBarInner-default-background')};
  border-radius: 0.5em;
  height: 0.5em;
  min-width: 0.75em;
  width: ${(props) => `${props.progress}%`};
  transition: width 150ms ease-in-out;
`

interface Props {
  className?: string
  progress: number
}

const ProgressBar = ({ className, progress = 0 }: Props) => {
  return (
    <div className={className}>
      <Inner progress={progress} />
    </div>
  )
}

export default styled(ProgressBar)`
  backdrop-filter: blur(2px);
  background: ${getStyle('progressBarOuter-default-background')};
  border-radius: 0.5em;
  border: 1px solid ${getStyle('progressBar-default-borderColor')};
  box-shadow: ${getStyle('boxShadow')};
  left: 50%;
  min-width: 1.5em;
  padding: 0.25em 0.325em;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 60%;
`
