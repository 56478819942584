import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import styles from './DropdownValueMarker.scss'

export default class DropdownValueMarker extends PureComponent {
  static propTypes = {
    index: PropTypes.number,
    label: PropTypes.string,
    onRef: PropTypes.func.isRequired,
  }

  handleRef = r => this.props.onRef(r, this.props.index)

  render () {
    return (
      <span className={ styles.marker } ref={ this.handleRef }>
        { this.props.label }
      </span>
    )
  }
}
