import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import {
  AlignProps,
  BackgroundProps,
  BorderProps,
  FlexProps,
  GridProps,
  PaddingProps,
  RoundedProps,
  ScrollProps,
  ShadowProps,
  alignMixin,
  backgroundMixin,
  borderMixin,
  flexMixin,
  gridMixin,
  paddingMixin,
  roundedMixin,
  scrollMixin,
  shadowMixin,
} from './helpers'

export type BoxProps = AlignProps &
  BackgroundProps &
  BorderProps &
  FlexProps &
  GridProps &
  RoundedProps &
  ScrollProps &
  ShadowProps &
  PaddingProps

const Box = styled.div<BoxProps>`
  ${backgroundMixin}
  ${paddingMixin}
  ${roundedMixin}
  ${borderMixin}
  ${scrollMixin}
  ${shadowMixin}
  ${flexMixin}

  ${(props: BoxProps) => {
    if (props.gap) {
      return css`
        ${gridMixin}
        ${alignMixin}
      `
    } else if (props.align || props.direction || props.justify) {
      {
        // eslint-disable-next-line no-console
        console.warn('Deprecated use of <Box />. Use <Flex />')
      }
      return css`
        display: flex;
        flex-direction: ${ifProp({ direction: 'ltr' }, 'row', 'column')};
        ${alignMixin}
      `
    }
    return
  }}
`

export default Box

export const Container = styled.div`
  max-width: 800px;
  padding: 1em;
  margin: 0 auto;
`
