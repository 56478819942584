import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

import { SelectWrapper, Label } from '../Select.styles'
import { SelectProps } from '../Select.types'

import AvailableFields from './AvailableFields'
import ConstantFieldForm from './ConstantFieldForm'

import { Menu } from 'happitu/src/components/menus'
import MenuNav, {
  MenuNavItem,
} from 'happitu/src/components/menus/MenuLayoutComponents/MenuNav'
import Tree, {
  ConstantNumber,
  isConstantValue,
  RecordFieldReference,
  TreeValue,
} from 'happitu/src/models/expressionTree'
import {
  CompiledFieldList,
  findField,
} from 'happitu/src/modules/Insights/components/MetricInspector/MetricInspector.helpers'

interface Props
  extends Omit<
    SelectProps<RecordFieldReference | TreeValue | ConstantNumber | Tree>,
    'onSelect'
  > {
  allowStatic?: boolean
  availableFields: CompiledFieldList
  onCreate?: () => void
  onSelect: (value: RecordFieldReference | TreeValue | ConstantNumber) => void
  placeholder?: string
  searchPlaceholder?: string
  value?: RecordFieldReference | TreeValue | ConstantNumber | Tree
}

const FieldSelect = ({
  className,
  searchPlaceholder = 'Search fields',
  ...props
}: Props) => {
  const [isOpen, setMenuVisibility] = useState(false)
  const [view, setView] = useState(isConstantValue(props.value) ? 'static' : 'fields')
  const valueStr = JSON.stringify(props.value)

  const handleConstantNumber = (values: { number: string }) => {
    props.onSelect(new ConstantNumber(parseInt(values.number)))
  }

  const handleFieldSelect = (value: string) => {
    props.onSelect(JSON.parse(value))
    setMenuVisibility(false)
  }

  const handleCreate = () => {
    if (!props.onCreate)
      throw new Error('fired handleCreate without passing onCreate callback')
    props.onCreate()
    setMenuVisibility(false)
  }

  const currentField = useMemo((): string | null => {
    if (isConstantValue(props.value)) return `${props.value.constant}`
    const field = findField(props.availableFields, props.value)
    return field ? field.label : null
  }, [valueStr])
  const currentValue = currentField || props.placeholder

  return (
    <SelectWrapper
      aria-pressed={isOpen}
      className={className}
      disabled={props.disabled}
      onClick={() => setMenuVisibility(true)}
      size={props.type === 'button' ? 'small' : props.size}
      type={props.type}
    >
      <input
        id="basic-select-id"
        role="select"
        readOnly
        value={currentValue}
        type="text"
        disabled={props.disabled}
        onBlur={blur}
        onFocus={props.onFocus}
      />

      <Label>{currentValue}</Label>

      <Menu
        aria-controls="basic-select-id"
        isVisible={isOpen}
        onClose={() => setMenuVisibility(false)}
        style={{ width: 280 }}
      >
        {props.allowStatic && (
          <MenuNav onNavigate={setView} activeTab={view}>
            <MenuNavItem value="fields">Fields</MenuNavItem>
            <MenuNavItem value="static">Static #</MenuNavItem>
          </MenuNav>
        )}

        {view === 'fields' ? (
          <AvailableFields
            fields={props.availableFields}
            onSelect={handleFieldSelect}
            searchPlaceholder={searchPlaceholder}
            value={valueStr}
            onCreate={props.onCreate && handleCreate}
          />
        ) : (
          <ConstantFieldForm onSubmit={handleConstantNumber} initialValue={props.value} />
        )}
      </Menu>
    </SelectWrapper>
  )
}

export default styled(FieldSelect)``
