import {
  Children,
  ComponentProps,
  ReactNode,
  useMemo,
  cloneElement,
  ReactElement,
  useEffect,
  useContext,
} from 'react'
import styled from 'styled-components'

import { MenuContext, MenuListContext } from './context'
import { getName, MenuComponent } from './utils'

import useSelectableList from 'happitu/src/hooks/use-selectable-list'
import { Hotkey } from 'happitu/src/hooks/useHotkey'

const List = styled.ul`
  margin: 0.5em 0;
`

const ACTIVE_KEYS = [Hotkey.Space, Hotkey.Enter]
const isActiveKey = (key: string) => ACTIVE_KEYS.includes(key as Hotkey)

type Handlers = Record<string, () => void>

const isReactElement = (child: any): child is ReactElement =>
  !!child && typeof child === 'object' && 'props' in child

const cloneChildren = (
  children: ReactNode,
  handlers = {},
  index = 0,
): [number, Handlers, ReactNode] => {
  const items = Children.map(children, (child) => {
    if (isReactElement(child)) {
      switch (getName(child)) {
        case MenuComponent.MenuListSection:
          // eslint-disable-next-line no-case-declarations
          const [newIndex, newHandlers, clonedChildren] = cloneChildren(
            child.props.children,
            handlers,
            index,
          )
          index = newIndex
          handlers = { ...handlers, ...newHandlers }
          return cloneElement(child, {}, clonedChildren)
        case MenuComponent.MenuListItem:
          index++
          handlers = { ...handlers, [index]: child.props.onSelect }
          return cloneElement(child, { index })
        default:
          return child
      }
    }
    return child
  })
  return [index, handlers, items]
}

interface Props extends Omit<ComponentProps<'ul'>, 'ref'> {
  closeOnSelect?: boolean
}

const MenuList = ({ children, closeOnSelect = true, ...props }: Props) => {
  const { setVisibility } = useContext(MenuContext)
  const [listLength, handlers, menuItems] = useMemo(() => cloneChildren(children), [
    children,
  ])
  const [hoverIndex, setHover] = useSelectableList({ listLength })

  const onKeyDown = (e: KeyboardEvent) => {
    const handler = handlers[hoverIndex]
    if (isActiveKey(e.key) && typeof handler === 'function') {
      handler()
      if (closeOnSelect) setVisibility(false)
    }
  }

  const onClick = () => {
    if (closeOnSelect) setVisibility(false)
  }

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    return () => document.removeEventListener('keydown', onKeyDown)
  }, [handlers, hoverIndex])

  return (
    <MenuListContext.Provider value={{ hoverIndex, setHover }}>
      <List {...props} onClick={onClick}>
        {menuItems}
      </List>
    </MenuListContext.Provider>
  )
}

export default MenuList
