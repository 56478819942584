import { motion, AnimatePresence } from 'framer-motion'
import React, { useRef, useEffect, useMemo } from 'react'
import styled from 'styled-components'

import {
  getStyleFromAnchor,
  getOptimalAnchor,
  getSlideTransition,
  setMenuVisibility,
} from './Menu.helpers'
import { MenuProps, Anchor } from './Menu.types'

import SectionHeading from 'happitu/src/components/Section/SectionHeading'
import getStyle from 'happitu/src/getStyle'
import useHotkey, { Hotkey } from 'happitu/src/hooks/useHotkey'
import { metric, zIndex, font } from 'theme'

const defaultAnchor: Anchor = {
  position: 'bottom',
  alignment: 'start',
  forced: false,
}

const Menu = ({ isVisible, onClose, ...props }: MenuProps) => {
  const preferredAnchor = useMemo(() => ({ ...defaultAnchor, ...props.anchor }), [
    props.anchor,
  ])
  const [anchor, setAnchor] = React.useState(preferredAnchor)
  const menuRef = useRef<HTMLDivElement>(null)
  const transition = useMemo(() => getSlideTransition(anchor), [anchor])

  const defaultStyles = useMemo(() => {
    const menuWidth = props.menuWidth ? { width: props.menuWidth } : {}
    return {
      ...props.style,
      ...getStyleFromAnchor(anchor),
      ...menuWidth,
    }
  }, [anchor, props.menuWidth])

  useEffect(() => {
    const mouseDown = (event: MouseEvent) => {
      if (
        !divElement?.contains(event.target as Node) &&
        !divElement?.parentElement?.contains(event.target as Node) &&
        onClose
      ) {
        onClose()
      }
    }
    const divElement = menuRef?.current
    if (isVisible && divElement) {
      document.addEventListener('mousedown', mouseDown)
    }

    // Recording menu visibility so other components can be aware of its existence.
    // Currently used to block a modal from closing if a menu is open
    setMenuVisibility(isVisible)

    return () => {
      document.removeEventListener('mousedown', mouseDown)
    }
  }, [isVisible])

  // Check if the menu will display outside of the viewport, and pick the optimal anchor if so.
  React.useLayoutEffect(() => {
    if (isVisible && menuRef.current) {
      const newAnchor = anchor.forced
        ? preferredAnchor
        : getOptimalAnchor(preferredAnchor, menuRef.current)
      setAnchor(newAnchor)
    }
  }, [isVisible])

  if (typeof onClose === 'function') {
    useHotkey(Hotkey.Close, onClose, isVisible, [])
  }

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          aria-expanded={isVisible}
          className={props.className}
          key="menu"
          style={defaultStyles}
          ref={menuRef}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
          {...transition}
        >
          {props.children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default styled(Menu)`
  background: ${getStyle('menu-background')};
  border-radius: ${metric('largeBorderRadius')};
  border: 1px solid ${getStyle('menu-border-color')};
  box-shadow: 0 8px 16px -2px rgba(0, 0, 0, 0.05), 0 1px 1px 0 rgba(0, 0, 0, 0.05),
    0 2px 3px -1px rgba(0, 0, 0, 0.075);
  position: absolute;
  z-index: ${zIndex('menu')};
  min-width: 200px;
  text-align: left;
  font-weight: ${font('weight', 'normal')};

  ${SectionHeading} {
    padding: 0.5rem 1rem;
  }

  &:after {
    bottom: -2em;
    content: '';
    left: -2em;
    position: absolute;
    right: -2em;
    top: -2em;
    pointer-events: none;
  }
`
