import request, { SuperAgentRequest } from 'superagent'

import { getAuthToken, hasSessionCookie } from './currentUserHelpers'

import userService from 'happitu/src/services/websocket/userService'

export function happituHeaders() {
  return (req: SuperAgentRequest) => {
    const socketId = userService.getSocketId()
    req.set('Happitu-Client-Version', process.env.ASSETS_VERSION || 'unknown')

    const organizationParam = window.location.pathname.match(/\/org\/([\w\d]+)/i)
    const organizationId: string = organizationParam ? organizationParam[1] : ''
    req.set('Happitu-Organization-ID', organizationId)

    if (!hasSessionCookie()) {
      const authToken = getAuthToken('localStorage')
      if (authToken) req.set('Authorization', `Bearer ${getAuthToken('localStorage')}`)
    }

    if (socketId) {
      req.set('Happitu-Client-Socket-ID', socketId)
    }
    return req
  }
}

export const isErrorResponse = (error: Error): error is request.ResponseError =>
  'response' in error
