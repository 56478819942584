// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/$$virtual/css-loader-virtual-ef416ba337/0/cache/css-loader-npm-5.2.4-285d2e57c2-3698399558.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1KYTgj0_ga5NMUdkVGB7z-{border-bottom:1px solid #e9e7ea;margin-bottom:0;margin-top:16px;margin:8px 20px;padding-bottom:14px;position:relative}._2frUg50rtz7iEsWPlP4b7R{flex-grow:1;max-height:240px;overflow-y:auto;padding-bottom:10px;padding-top:10px;margin:0}.HsErk5KRXBn7E7Mc76u2C h4{color:#a6a1ab;font-size:11px;font-weight:300;text-transform:uppercase}._2ph8FKF8FyowkDNEf_Kt2F{align-items:center;display:flex;padding:10px 20px}._2ph8FKF8FyowkDNEf_Kt2F ._1_hLDbnY2-66y5gQYSlOJY{border-radius:3px;border:1px solid #e9e7ea;color:#807987;font-weight:bold;height:20px;line-height:1;margin-right:15px;padding:2px 5px;text-align:center;width:20px}._2ph8FKF8FyowkDNEf_Kt2F .HsErk5KRXBn7E7Mc76u2C{line-height:1;margin-bottom:5px}._3x3u1I6h7HIhGsCxBYazES{display:flex}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchWrapper": "_1KYTgj0_ga5NMUdkVGB7z-",
	"options": "_2frUg50rtz7iEsWPlP4b7R",
	"createLabel": "HsErk5KRXBn7E7Mc76u2C",
	"createOption": "_2ph8FKF8FyowkDNEf_Kt2F",
	"indicator": "_1_hLDbnY2-66y5gQYSlOJY",
	"label": "_3x3u1I6h7HIhGsCxBYazES"
};
export default ___CSS_LOADER_EXPORT___;
