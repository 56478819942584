import React from 'react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { useSelected, useSlate, ReactEditor, RenderElementProps } from 'slate-react'

import FullscreenImagePreview from './FullscreenImagePreview'
import { Container, Wrapper, Img } from './Image.styles'
import ElementDragHandle from './element-drag-handle'

import ProgressBar from 'happitu/src/components/Loaders/ProgressBar'
import { getFileUrl } from 'happitu/src/helpers/fileUploadHelpers'
import { ImageElement } from 'happitu/src/types/slate-types'

interface Props extends RenderElementProps {
  dragHandleProps?: DraggableProvidedDragHandleProps
  element: ImageElement
  isDragging?: boolean
}

// TODO: Properly handle image dragging.
const EditableImage = ({ attributes, isDragging = false, ...props }: Props) => {
  const element = props.element
  const children = props.children
  const isPreview = !element.fileId
  const url = isPreview
    ? element.previewUrl
    : element.fileId
    ? getFileUrl(element.fileId)
    : ''
  const selected = useSelected()
  const [showFullscreen, setShowFullscreen] = React.useState(false)
  const editor = useSlate()
  const fileIds = editor.fileIds as ID[]
  const index = fileIds && element.fileId ? fileIds.indexOf(element.fileId) : 0

  const handleImageClick = () => {
    if (selected && !isPreview) {
      setShowFullscreen(true)
    }
  }

  const closePreview = () => {
    setShowFullscreen(false)
  }

  React.useEffect(() => {
    if (showFullscreen) {
      ReactEditor.blur(editor)
    } else {
      ReactEditor.focus(editor)
    }
  }, [showFullscreen])

  return (
    <Wrapper {...attributes}>
      <div contentEditable={false} style={{ userSelect: 'none' }}>
        <Container>
          <ElementDragHandle dragHandleProps={props.dragHandleProps} />
          <Img
            draggable={false}
            isDragging={isDragging}
            isPreview={isPreview}
            isSelected={selected}
            onClick={handleImageClick}
            src={url}
          />
          {isPreview && <ProgressBar progress={element.uploadProgress ?? 0} />}
        </Container>
        {showFullscreen && fileIds && (
          <FullscreenImagePreview
            closePreview={closePreview}
            fileIds={fileIds}
            defaultIndex={index}
          />
        )}
      </div>
      {children}
    </Wrapper>
  )
}

export default EditableImage
