// eslint-disable-next-line import/no-named-as-default
import createRouter, { Plugin } from 'router5'
import browserPlugin from 'router5-plugin-browser'

import { error } from 'happitu/src/helpers/loggerHelper'
import tracker from 'happitu/src/helpers/tracker'

function mixPanelPlugin(): Plugin {
  return {
    onTransitionSuccess: (toState) => {
      if (toState) {
        tracker.track('Client Page Transition', {
          Name: toState.name,
          Path: toState.path,
          ...toState.params,
        })
      }
    },
  }
}

function logFailedTransitions(): Plugin {
  return {
    onTransitionError: (_, fromState, err) => {
      if (err.code === 'ROUTE_NOT_FOUND' && fromState) {
        error('route not found:', fromState)
      }
    },
  }
}

function configureRouter(
  routes: Router5DefinedRoute[],
  plugins = [browserPlugin(), mixPanelPlugin, logFailedTransitions],
) {
  const router = createRouter(routes, {
    defaultRoute: 'organizationSelect',
  })
  // Plugins
  plugins.forEach((plugin) => router.usePlugin(plugin))

  return router
}

export default configureRouter
