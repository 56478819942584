import createReducer from './createReducer'
import Store from './createStore'

import * as types from 'happitu/src/constants/ActionTypes'

const initialState = new Store()

export default createReducer(initialState, {
  [types.RELAY_DISPATCH]: (state, { payload }) =>
    state.setMany(payload.workflowEmailAddresses),
})
