import { error } from 'happitu/src/helpers/loggerHelper'
import defaultTheme from 'happitu/src/themes/defaultTheme'

export default function getStyle(themeKey: keyof typeof defaultTheme) {
  return ({ theme }: ContextThemeProps) => {
    if (!theme.currentTheme[themeKey]) {
      error('Unknown key', themeKey)
      return ''
    }
    return theme.currentTheme[themeKey]
  }
}
