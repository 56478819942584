import autobind from 'autobind-decorator'
import { omit } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Button, Input, Wrapper } from './RadioButton.styles'

export default class RadioButton extends Component {
  static propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
    item: PropTypes.object,
    onChange: PropTypes.func,
    tabIndex: PropTypes.number,
    type: PropTypes.string,
    value: PropTypes.any,
  }

  static defaultProps = {
    checked: false,
    tabIndex: 0,
    type: 'radio',
  }

  @autobind
  handleChange(e) {
    if (this.props.onChange) this.props.onChange(e)
  }

  render() {
    const { className, type, checked, ...props } = this.props
    return (
      <Wrapper>
        <Input
          {...omit(props, ['item'])}
          checked={checked}
          onChange={this.handleChange}
          type="radio"
        />
        <Button checked={checked} className={className} type={type} />
      </Wrapper>
    )
  }
}
