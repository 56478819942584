import { ChangeEventHandler } from 'react'

import { BaseRelayResponse } from 'happitu/src/actions/relay'

export interface BaseSuggestion {
  id: ID
  impressionId?: ID
  [key: string]: any
}

export interface SuggesterResults<SuggestionRecord> {
  suggestions: SuggestionRecord[]
  pagination: PaginationObject
  highConfidenceMatchId?: ID
  highlighting?: Record<string, Record<string, string[]>>
}

export interface SuggestResponse<S>
  extends BaseRelayResponse<'pagination' | 'suggestions'> {
  pagination: PaginationObject
  suggestions: S[] | null
  highConfidenceMatchId?: ID
  highlighting?: Record<string, Record<string, string[]>>
}

export interface SuggesterOptions {
  'groupBy'?: string
  'fields'?: string
  'filter[active]'?: boolean
  'filter[component]'?: string
  'filter[workflowId]'?: ID
  'filter[pendingWorkflowIds]'?: ID
  'filter[activeWorkflowIds]'?: ID
  'filter[isInternal]'?: boolean
  'limit'?: number
  'sort'?: string
  'cursor'?: string | null
  'skip'?: number
  'includeRecords'?: boolean
  'includeUpdaters'?: boolean
}

export const DEFAULT_PAGINATION_OBJECT = {
  collectionName: null,
  offsetIndex: 0,
  totalRecords: 0,
  cursor: null,
}

export const DEFAULT_SUGGESTER_OPTIONS = {
  cursor: '*',
  skip: 0,
  limit: 10,
}

export interface SuggesterInputProps {
  onChange: ChangeEventHandler
  value: string
}
