import { useContext } from 'react'

import { SuggesterContext } from '../searchSuggester.context'

import { GeneratedCollection } from 'happitu/src/helpers/generatedMetadata'

const isStore = (collection: any): collection is StoreInterface<any> =>
  typeof collection?.findById === 'function'

const useSuggesterReferenceStore = () => {
  const state = useContext(SuggesterContext)
  const findById = <S extends StoreRecord>(
    collectionName: GeneratedCollection,
    id: ID | null,
  ): S | null | undefined => {
    const collection = state[collectionName]
    return isStore(collection) ? collection.findById<S>(id) : null
  }

  const findByImpression = <S extends StoreRecord>(
    collectionName: GeneratedCollection,
    impressionId: ID | null,
  ): S | null | undefined => {
    const collection = state[collectionName]
    return isStore(collection) ? collection.findByImpression<S>(impressionId) : null
  }

  return { state, findById, findByImpression }
}

export default useSuggesterReferenceStore
