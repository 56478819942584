import { startOfDay, differenceInDays } from 'date-fns'

// A help topic is considered expired on the day it hits expiresAt.
export const checkIsExpired = (expiresAt: string | null): boolean => {
  if (expiresAt === null) return false

  const date = new Date(expiresAt)
  const today = new Date()
  const diff = differenceInDays(startOfDay(date), startOfDay(today))

  return diff <= 0
}

// Help topics are considered to be expiring soon if expiresAt falls within the next week.
export const checkExpiresSoon = (expiresAt: string | null): boolean => {
  if (expiresAt === null) return false

  const date = new Date(expiresAt)
  const today = new Date()
  const diff = differenceInDays(startOfDay(date), startOfDay(today))

  return diff < 7
}
