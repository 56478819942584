import autobind from 'autobind-decorator'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import MenuArrow from './_DEPRECATED_Menu/MenuArrow'
import MenuBody from './_DEPRECATED_Menu/MenuBody'
import MenuFooter from './_DEPRECATED_Menu/MenuFooter'
import MenuHeader from './_DEPRECATED_Menu/MenuHeader'
import MenuList from './_DEPRECATED_Menu/MenuList'
import MenuListItem from './_DEPRECATED_Menu/MenuListItem'
import MenuListItemAction from './_DEPRECATED_Menu/MenuListItemAction'
import MenuListItemLink from './_DEPRECATED_Menu/MenuListItemLink'
import MenuListSection from './_DEPRECATED_Menu/MenuListSection'
import MenuTransition from './_DEPRECATED_Menu/MenuTransition'
import MenuWrapper from './_DEPRECATED_Menu/MenuWrapper'

class Menu extends Component {
  static propTypes = {
    anchor: PropTypes.oneOf([
      'top',
      'top-left',
      'top-right',
      'bottom',
      'bottom-left',
      'bottom-right',
    ]),
    anchorRef: PropTypes.object,
    className: PropTypes.string,
    children: PropTypes.any,
    closeOnClick: PropTypes.bool,
    defaultAnchor: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    width: PropTypes.number,
    horizontalOffset: PropTypes.number,
    verticalOffset: PropTypes.number,
  }

  static defaultProps = {
    defaultAnchor: 'top-left',
  }

  state = {
    anchor: this.props.anchor || this.props.defaultAnchor,
  }

  componentDidUpdate() {
    if (this.props.isOpen) {
      document.addEventListener('keydown', this.handleKeyDown)
    }
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  @autobind
  handleKeyDown(e) {
    if (e.which === 27) {
      e.preventDefault()
      this.handleClose(e)
    }
  }

  @autobind
  handleClose(e) {
    const { onClose } = this.props
    if (typeof onClose === 'function' && this.props.isOpen) {
      onClose(e)
    }
  }

  renderMenu() {
    const {
      children,
      closeOnClick,
      className,
      isOpen,
      width,
      verticalOffset,
      horizontalOffset,
    } = this.props
    const { anchor } = this.state
    if (isOpen) {
      return (
        <MenuWrapper
          {...{
            anchor,
            className,
            closeOnClick,
            width,
            verticalOffset,
            horizontalOffset,
          }}
          anchorRef={this.props.anchorRef}
          onClose={this.handleClose}
        >
          {children}
        </MenuWrapper>
      )
    }
    return null
  }

  render() {
    return this.renderMenu()
  }
}

export default Menu

export {
  MenuArrow,
  MenuBody,
  MenuFooter,
  MenuHeader,
  MenuList,
  MenuListItem,
  MenuListItemAction,
  MenuListItemLink,
  MenuListSection,
  MenuTransition,
}
