import relay, { RelayDispatch } from './relay'

import {
  deleteHelpTopicPageRequest,
  newHelpTopicPageRequest,
  updateHelpTopicPageRequest,
} from 'happitu/src/services/happitu-api/helpTopicPageService'
import { HelpTopicPageRecord } from 'happitu/src/types/models/helpTopics'

export function updateHelpTopicPage(id: ID, helpTopicPage: Partial<HelpTopicPageRecord>) {
  return async (dispatch: RelayDispatch<typeof updateHelpTopicPageRequest>) => {
    const response = await updateHelpTopicPageRequest(id, helpTopicPage)
    relay(dispatch)(response)
    return response
  }
}

export function deleteHelpTopicPage(id: ID) {
  return async (dispatch: RelayDispatch<typeof deleteHelpTopicPageRequest>) => {
    const response = await deleteHelpTopicPageRequest(id)
    relay(dispatch)(response)
    return response
  }
}

export function newHelpTopicPage(helpTopicPage: Partial<HelpTopicPageRecord>) {
  return async (dispatch: RelayDispatch<typeof newHelpTopicPageRequest>) => {
    const response = await newHelpTopicPageRequest(helpTopicPage)
    relay(dispatch)(response)
    return response
  }
}
