import React, { InputHTMLAttributes } from 'react'
import { FieldMetaState, FieldInputProps } from 'react-final-form'

import Dropdown from './Dropdown'

import {
  ErrorMessage,
  FieldFigure,
  FieldWrapper,
  Label,
} from 'happitu/src/components/Fields/FieldUtils'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  footnote?: string
  inline?: boolean
  input: FieldInputProps<string[] | string, HTMLInputElement>
  label?: string
  meta?: FieldMetaState<string[] | string>
  multiple?: boolean
  options?: DropdownOption
}

export default function DropdownField(props: Props) {
  const {
    className,
    inline,
    required,
    label,
    footnote,
    meta = {},
    multiple,
    ...dropdownProps
  } = props

  // Allow the field to be used with Redux Form.
  let inputProps = {}
  if (dropdownProps.input) inputProps = dropdownProps.input

  return (
    <FieldWrapper
      className={className}
      inline={inline}
      invalid={meta.touched && meta.error}
    >
      <Label required={required} value={label} />
      <Dropdown
        {...dropdownProps}
        {...inputProps}
        multiple={
          dropdownProps.input && Array.isArray(dropdownProps.input.value)
            ? true
            : multiple
        }
        required={required}
      />
      {meta && meta.touched && meta.error ? (
        <ErrorMessage {...meta} />
      ) : (
        <FieldFigure>{footnote}</FieldFigure>
      )}
    </FieldWrapper>
  )
}
