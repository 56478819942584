import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import getStyle from 'happitu/src/getStyle'
import { font } from 'theme'

export const Wrapper = styled(motion.div)<{ disabled?: boolean }>`
  align-items: center;
  background: ${getStyle('button-base-default-background')};
  border-radius: 6px;
  display: inline-grid;
  grid-template-columns: 1fr auto auto;
  list-style: none;
  margin-bottom: 0.5em;
  margin-right: 0.5em;
  padding: 0.75em 1em;
  position: relative;
  width: 100%;
  min-width: 200px;

  ${ifProp(
    'disabled',
    css`
      cursor: not-allowed;
      opacity: 0.5;
      user-select: none;
    `,
  )}
`

export const Heading = styled.h4`
  color: ${getStyle('text-default-color')};
  font-size: 1em;
  font-weight: ${font('weight', 'medium')};
`

export const Label = styled.header`
  margin: 0;
`

export const FileSize = styled.span`
  color: ${getStyle('text-light-color')};
  font-size: ${font('size', 'small')};
`
