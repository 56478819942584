import relay, { RelayDispatch } from './relay'

import * as service from 'happitu/src/services/happitu-api/helpTopicService'
import { attachHelpTopicRequest } from 'happitu/src/services/happitu-api/workflowStepService'
import { HelpTopicRecord } from 'happitu/src/types/models/helpTopics'

interface SearchActionConfig extends service.SearchServiceConfig {
  resetStore: boolean
  workflowIds: ID[]
  limit: number
  responseScope: 'ids'
}

const defaultSearchConfig = {
  resetStore: true,
} as SearchActionConfig

// prettier-ignore
export function searchHelpTopics(
  query = '',
  sort: Array<{ key: string, direction: string }> = [],
  conf = {},
) {
  return async (dispatch?: RelayDispatch<typeof service.searchHelpTopicsRequest>) => {
    const { resetStore, ...config } = { ...defaultSearchConfig, ...conf }
    const response = await service.searchHelpTopicsRequest(query, sort, config)
    if (!Array.isArray(response) && dispatch) {
      relay(dispatch)(response, {
        reset: {
          helpTopics: resetStore,
        },
      })
    }

    return response
  }
}

interface FilterParams {
  workflowId: ID
  workflowVersion: number
  working?: boolean
  excludeExpired?: boolean
}

export function getWorkflowHelpTopics({
  workflowId,
  workflowVersion,
  working = false,
  excludeExpired = false,
}: FilterParams) {
  return (dispatch: RelayDispatch<typeof service.getHelpTopicsRequest>) => {
    return service
      .getHelpTopicsRequest({
        workflowId,
        workflowVersion,
        [working ? 'working' : 'current']: true,
        excludeExpired,
      })
      .then(relay(dispatch))
  }
}

export function getHelpTopic(id: ID) {
  return async (dispatch: RelayDispatch<typeof service.getHelpTopicRequest>) => {
    const response = await service.getHelpTopicRequest(id)
    relay(dispatch)(response)
    return response
  }
}

export function getWorkingHelpTopicByImpression(impressionID: ID) {
  return async (dispatch: RelayDispatch<typeof service.getWorkingHelpTopicRequest>) => {
    const response = await service.getWorkingHelpTopicRequest(impressionID)
    relay(dispatch)(response)
    return response
  }
}

export function newHelpTopic(helpTopic: Partial<HelpTopicRecord>) {
  return async (dispatch: RelayDispatch<typeof service.newHelpTopicRequest>) => {
    const response = await service.newHelpTopicRequest(helpTopic)
    relay(dispatch)(response)
    return response
  }
}

export function updateHelpTopic(id: ID, helpTopic: Partial<HelpTopicRecord>) {
  return async (dispatch: RelayDispatch<typeof service.updateHelpTopicRequest>) => {
    const response = await service.updateHelpTopicRequest(id, helpTopic)
    relay(dispatch)(response)
    return response
  }
}

export function publishHelpTopic(id: ID) {
  return async (dispatch: RelayDispatch<typeof service.publishHelpTopicRequest>) => {
    const response = await service.publishHelpTopicRequest(id)
    relay(dispatch)(response)
    return response
  }
}

export function deleteHelpTopic(id: ID) {
  return async (dispatch: RelayDispatch<typeof service.deleteHelpTopicRequest>) => {
    const response = await service.deleteHelpTopicRequest(id)
    relay(dispatch)(response)
    return response
  }
}

export function attachHelpTopic(
  stepId: ID,
  operation: 'add' | 'remove' = 'add',
  impressionId: ID,
) {
  return (dispatch: RelayDispatch<typeof attachHelpTopicRequest>) => {
    return attachHelpTopicRequest(stepId, operation, impressionId).then(relay(dispatch))
  }
}
