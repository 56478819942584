import React, { ComponentProps } from 'react'

import { TicketingToolbar, TicketingHeading } from '../Scaffolding'

import { getContactName } from 'happitu/src/helpers/contactHelper.new'

interface Props extends ComponentProps<'header'> {
  contact: Partial<ContactRecord>
}

const CurrentContactHeader = ({ contact }: Props) => {
  return (
    <TicketingToolbar>
      <TicketingHeading>{getContactName(contact)}</TicketingHeading>
    </TicketingToolbar>
  )
}

export default CurrentContactHeader
