import React from 'react'
import { createEditor, Editor, Transforms } from 'slate'
import { withHistory } from 'slate-history'
import { withReact } from 'slate-react'
import { v4 as uuid } from 'uuid'

import withLinks from 'happitu/src/helpers/editor/plugins/withLinks'
import withMarkdown from 'happitu/src/helpers/editor/plugins/withMarkdown'
import withTrailingNewLine from 'happitu/src/helpers/editor/plugins/withTrailingNewLine'
import withVoidBehavior from 'happitu/src/helpers/editor/plugins/withVoidBehavior'

// import { ElementType } from 'happitu/src/types/slate-types'

type Plugin = (editor: Editor) => Editor

// Nice to haves, considered applicable to all editors.
const defaultPlugins: Plugin[] = [
  withHistory,
  withLinks,
  withMarkdown,
  withVoidBehavior,
  withTrailingNewLine,
]

const useEditor = (newPlugins: Plugin[] = []) => {
  // The bare minimum needed to run Slate in React.
  const defaultEditor = withReact(createEditor())
  defaultEditor.insertBreak = () => {
    Transforms.splitNodes(defaultEditor, { always: true })
    Transforms.setNodes(defaultEditor, { nodeId: uuid() })
    // ALT: Slate's default behavior is the split on current block on line break. We could insert a new paragraph block instead.
    // Transforms.insertNodes(defaultEditor, [
    //   {
    //     type: ElementType.Paragraph,
    //     nodeId: uuid(),
    //     indentLevel: 0,
    //     children: [{ text: '' }],
    //   },
    // ])
  }

  const plugins = [...defaultPlugins].concat(newPlugins)

  const editor = React.useMemo(() => {
    return plugins.reduce((acc, cur) => {
      return cur(acc)
    }, defaultEditor)
  }, [])

  return editor
}

export default useEditor
