import { getParams, WorkTicketRouteParams } from 'happitu/src/helpers/routeHelpers'

export function getTicketFromRoute(tickets?: TicketStore) {
  if (!tickets) {
    throw new Error("Tickets haven't been initialized. What's up?")
  }
  const { ticketId } = getParams<WorkTicketRouteParams>()
  const ticket = tickets.findById<TicketRecord>(ticketId)
  if (!ticket) {
    throw new Error(`Missing ticket: ${ticketId}`)
  }
  return ticket
}

export function getInteractionFromRoute(ticketInteractions?: TicketInteractionStore) {
  if (!ticketInteractions) {
    throw new Error("Ticket interactions haven't been initialized. What's up?")
  }
  const { ticketInteractionId } = getParams<WorkTicketRouteParams>()
  const interaction = ticketInteractions.findById<TicketInteractionRecord>(
    ticketInteractionId,
  )
  if (!interaction) {
    throw new Error(`Missing interaction: ${ticketInteractionId}`)
  }
  return interaction
}

export function getCurrentStepValues(
  workflowSteps: InteractionStep[],
  stepIndex = getParams<WorkTicketRouteParams>().stepIndex,
) {
  return typeof stepIndex !== 'undefined'
    ? workflowSteps[parseInt(stepIndex)]
    : workflowSteps.slice(-1)[0]
}

export function getCurrentStepIndex(workflowSteps: InteractionStep[]) {
  const { stepIndex } = getParams<WorkTicketRouteParams>()
  return typeof stepIndex !== 'undefined' ? parseInt(stepIndex) : workflowSteps.length - 1
}

export function getCurrentTicket<R extends WorkTicketRouteParams>(tickets?: TicketStore) {
  if (!tickets) {
    throw new Error("Tickets haven't been initialized. What's up?")
  }
  return tickets.findById<TicketRecord>(getParams<R>().ticketId)
}

export function isLastStep<R extends WorkTicketRouteParams>(
  workflowSteps: InteractionStep[],
) {
  const stepIndex = getParams<R>().stepIndex || '0'
  return workflowSteps.length === 0 || workflowSteps.length === parseInt(stepIndex) + 1
}
