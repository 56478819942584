import Dropzone from 'react-dropzone'
import styled from 'styled-components'

import { FlexCol } from 'happitu/src/components/_DEPRECATED/Flex'
import { color, font } from 'theme'

interface Props {
  active: boolean
}

export const Wrapper = styled(FlexCol)<Props>`
  background: ${(props) => (props.active ? color('alpha', 10) : 'transparent')};
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &:active {
    background: ${color('alpha', 10)};
  }

  &:before {
    border-radius: 6px;
    border: 2px dashed ${color('alpha', 20)};
    bottom: 1em;
    content: '';
    left: 1em;
    opacity: ${(props) => (props.active ? 1 : 0)};
    position: absolute;
    right: 1em;
    top: 1em;
    transform: ${(props) => (props.active ? 'scale(1)' : 'scale(0.95)')};
    transition: transform 200ms, opacity 200ms;
    display: block;
  }
`

export const DropZone = styled(Dropzone)`
  border: 0;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: background 200ms;
  height: 100%;
`

export const EmptyCallout = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${color('grey', 'dark')};

  h4 {
    font-weight: ${font('weight', 'medium')};
    color: ${color('grey', 'dark')};
    background: ${color('grey', 'offWhite')};
    padding: 0.25em 0.5em;
    border-radius: 3px;
  }
`
