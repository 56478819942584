import { actions } from 'redux-router5'

import routes from '../routes'

import { ReportingControl } from 'happitu/src/modules/Insights/Insights.types'
import { HelpTopicTypes } from 'happitu/src/types/helpTopicTypes'

interface BaseParams {
  organizationId: ID
}

export interface EditLocationRouteParams extends BaseParams {
  locationId: ID
}

export interface EditNotificationHookRouteParams extends BaseParams {
  notificationHookId: ID
}

export interface WorkTicketRouteParams extends BaseParams {
  ticketId: ID
  ticketInteractionId: ID
  stepIndex: string
}

export interface ViewTicketRouteParams extends BaseParams {
  ticketId: ID
  redirectTo?:
    | string
    | {
        to: string
        params: {
          stepId: ID
          workflowId: ID
        }
      }
}

export interface NewTicketRouteParams extends BaseParams {
  authHash?: string
  autoCloseAfter?: string
  channel?: string
  nextStepImpressionId?: string
  testTicket?: boolean | string
  redirectTo?: {
    to: string
    params: {
      stepId: ID
      workflowId: ID
    }
  }
  workflowId: ID
}

export interface ContinueTicketRouteParams extends BaseParams {
  ticketId: ID
  workflowId: ID
}

export interface ExplainHookParams extends BaseParams {
  ticketId: ID
  ticketInteractionId: ID
}

export interface NewHelpTopicParams extends BaseParams {
  backTo?: string
  type?: HelpTopicTypes
  workflowIds?: ID[]
}

export interface HelpTopicParams extends NewHelpTopicParams {
  helpTopicImpressionId: ID
  helpTopicPageImpressionId: ID
}

export interface WorkflowDesignerParams extends BaseParams {
  workflowId: ID
}

export interface WorkflowSetupParams extends WorkflowDesignerParams {
  name?: string
  teamIds?: ID[]
  presetId?: ID
}

export interface LookupRouteParams extends BaseParams {
  savedQueryId?: ID
}

export interface InsightsRouteParams extends BaseParams {
  reportId?: ID
  hash?: string
  reportingControl?: ReportingControl | null
  options?: Record<string, string> | null
}

export interface InvitationRouteParams extends BaseParams {
  invitationConflicts?: Router5Params['invitationConflicts']
}

export interface NotificationHookParams extends BaseParams {
  notificationHookId: ID
}

export interface HelpTopicPopOutParams extends BaseParams {
  workflowId: ID
  helpTopicImpressionId?: ID
}

export interface ViewHelpTopicPopOutParams extends HelpTopicPopOutParams {
  helpTopicImpressionId: ID
}

export interface SelfServiceWorkParams extends BaseParams {
  workflowId: ID
  ticketInteractionId: ID
  ticketId: ID
  helpTopicImpressionId?: ID
  helpTopicPageId?: ID
  stepIndex: ID
}

export function getParams<R extends BaseParams>() {
  return window.store.getState().router.route.params as R
}

export function getRouteName() {
  return window.store.getState().router.route.name
}

export function navigateTo(
  route: string,
  params?: Router5Params,
  options?: Record<string, any>,
) {
  return window.store.dispatch(
    actions.navigateTo(
      route,
      {
        organizationId: getParams().organizationId,
        ...params,
      },
      options,
    ),
  )
}

export function getRoute<R extends BaseParams>(): {
  name: string
  params: R
  path: string
} {
  const { route } = window.store.getState().router
  return {
    name: route.name,
    path: route.path,
    params: route.params as R,
  }
}

const optionalParamRegex = /\?:(\w+)/g
const paramRegex = /:(\w+)/g
function hydrateParams(path: string, params?: Router5Params): string {
  // Fill in or remove the optional parameters first
  return path
    .replace(optionalParamRegex, (_, paramKey: keyof typeof params) =>
      !!params && !!params[paramKey] ? params[paramKey] : '',
    )
    .replace(paramRegex, (_, paramKey: keyof typeof params) => {
      if (!params || !params[paramKey]) {
        throw new Error(`missing param: ${paramKey}`)
      }
      return params[paramKey]
    })
}

function buildRoutePath(routes: Router5DefinedRoute[], nodeNames: string[]): string {
  const node = nodeNames.shift()
  const routePart = routes.find(({ name }) => node === name)

  if (!routePart) {
    throw new Error(`missing route part: ${node}`)
  }

  return !!nodeNames.length && routePart.children
    ? // Append the current path and continue the dig
      routePart.path + buildRoutePath(routePart.children, nodeNames)
    : // No more children! We're done
      routePart.path
}

function formatGetParams(getParams?: Record<string, any>): string {
  if (!getParams) return ''
  return Object.keys(getParams).reduce((prev, cur) => {
    return `${prev}${prev !== '' ? '&' : '?'}${cur}=${getParams[cur]}`
  }, '')
}

export function getUrlFromRoute(
  route: string,
  params?: Router5Params,
  getParams?: Record<string, any>,
): string {
  const splitRoute = route.split('.')
  const path = buildRoutePath(routes, splitRoute)
  return hydrateParams(path, params) + formatGetParams(getParams)
}
