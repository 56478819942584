import { format } from 'date-fns'
import memoizeOne from 'memoize-one'
import { createElement } from 'react'

import Monospaced from 'happitu/src/components/Layout/Monospaced'

type WordParam =
  | string
  | {
      plural: string
      singular: string
    }

/**
 * Pluralize most words.
 */
export function pluralize(word: WordParam, length: number): string {
  if (word === 'is') {
    return length !== 1 ? 'are' : 'is'
  }
  if (word === 'was') {
    return length !== 1 ? 'were' : 'was'
  }
  if (typeof word === 'string') {
    word = { singular: word, plural: word }
  }

  return length !== 1
    ? /s$/.test(word.plural)
      ? `${word.plural}es`
      : `${word.plural}s`
    : word.singular
}

export function renderRecipientList(list?: string[], parser?: 'url') {
  if (!list) return null
  return list.map((item, index) =>
    createElement(
      Monospaced,
      { key: index },
      parser === 'url' ? extractHostname(item) : item,
    ),
  )
}

/**
 * Singularize some words.
 */
export const singularize = (word: string, length = 1): string =>
  length === 1 ? word.replace(/s$/, '') : word

/**
 * Returns the name of the period of the day: 'morning', 'afternoon', 'evening'.
 */
export const getGreetingTime = (dateTime = new Date(), capitalized = false): string => {
  let g = ''

  const splitAfternoon = 12 // 24hr time to split the afternoon
  const splitEvening = 17 // 24hr time to split the evening
  const currentHour = parseFloat(format(dateTime, 'H'))

  if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
    g = 'afternoon'
  } else if (currentHour >= splitEvening) {
    g = 'evening'
  } else {
    g = 'morning'
  }
  if (capitalized) {
    g = capitalize(g)
  }
  return g
}

/**
 * Takes a string and capitalizes each word in the string, then returns the string.
 */
export const titleize = (str: string): string =>
  str
    .split(' ')
    .map((s) => capitalize(s))
    .join(' ')

/**
 * Takes a string and capitalizes the first character, then returns the string.
 */
export const capitalize = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1)

/**
 * Zero pads a number and returns the resulting string.
 */
export const zeroPad = (num: number, size = 2): string => {
  let str = num + ''
  while (str.length < size) {
    str = `0${str}`
  }
  return str
}

/**
 * Generate a readable list from an array
 */
export const arrayToList = (items: string[], conjunction = 'and', includeAnd = true) => {
  const filteredItems = items.filter((i) => i)
  const strConjunction = includeAnd ? ` ${conjunction} ` : ' '
  switch (filteredItems.length) {
    case 0:
      return ''
    case 1:
      return filteredItems[0]
    case 2:
      return filteredItems.join(strConjunction)
    default:
      return (
        filteredItems.slice(0, filteredItems.length - 1).join(', ') +
        `,${strConjunction}` +
        filteredItems[filteredItems.length - 1]
      )
  }
}

/**
 * Returns a human readable version of a camel cased string.
 */
export const humanizeCamelCase = (camelCaseString: string): string => {
  const returnString = camelCaseString.replace(/([A-Z])/g, ' $1').replace(/(Id)/g, 'ID')
  return returnString.charAt(0).toUpperCase() + returnString.slice(1)
}

/**
 * Escapes special characters from a string in preparation for
 * it becoming a regular expression.
 */
export const sanitizeStringForRegex = (str: string): string => {
  return str.replace(/([\\[\]().$^+*])/g, '\\$&')
}

export const parseLineBreaks = (value: string) => ({
  dangerouslySetInnerHTML: {
    __html: value.replace(/\n/g, '<br />'),
  },
})

export function sanitizeString(str: string, multiline = false) {
  return str.trim().replace(multiline ? /\t/g : /\t\n/g, '')
}

export const extractHostname = memoizeOne((url: string) => {
  let hostname

  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2]
  } else {
    hostname = url.split('/')[0]
  }

  hostname = hostname.split(':')[0]
  hostname = hostname.split('?')[0]
  return hostname
})

export function isString(str: any): str is string {
  return typeof str === 'string'
}
