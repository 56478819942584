import * as React from 'react'

import { IconButton } from 'happitu/src/components/Buttons'
import { ButtonProps } from 'happitu/src/components/button'
import { copyToClipboard } from 'happitu/src/helpers/domHelper'

interface Props
  extends Omit<React.ComponentPropsWithoutRef<'button'>, 'type'>,
    ButtonProps {
  text: string
}

export default function CopyButton({ text, ...props }: Props) {
  const handleCopy = React.useCallback(() => {
    copyToClipboard(text)
  }, [text])

  return <IconButton icon="clipboard" onClick={handleCopy} {...props} />
}
