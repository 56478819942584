import { Descendant } from 'slate'

import { CustomElement, ElementType } from 'happitu/src/types/slate-types'

const initialNodes = (value = ''): CustomElement[] => [
  {
    type: ElementType.Paragraph,
    indentLevel: 0,
    children: [{ text: value }],
    nodeId: 'c44f16e9-81d1-4797-b9f1-6be53deddc08',
  },
]

export const getInitialValue = (contents?: Descendant[], fallback?: string) =>
  !!contents && !!contents.length ? contents : initialNodes(fallback)
