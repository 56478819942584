import styled from 'styled-components'

interface Props {
  className?: string
  size?: string
  type: string
}

const getSize = ({ size }: Props): string => {
  switch (size) {
    case 'small':
      return '10px'
    case 'medium':
      return '20px'
    case 'large':
      return '2em'
    default:
      return '1em'
  }
}

export default styled.i.attrs<Props>((props) => ({
  className: [`icon-${props.type}`, props.className].join(' '),
}))`
  font-size: ${getSize};
`
