import * as React from 'react'

import MenuListItem from './MenuListItem'

import Link, { Props } from 'happitu/src/components/link'

export default function MenuListItemLink({ children, ...props }: Omit<Props, 'onClick'>) {
  return (
    <MenuListItem as={Link} {...props}>
      {children}
    </MenuListItem>
  )
}
