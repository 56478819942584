import { getBrandName } from './localizeHelper'

import * as regex from 'happitu/src/constants/regex'

export const refresh = () => location.reload()
export const back = () => window.history.back()
export const reload = () => (window.location.href = '/')

interface HTMLInput extends HTMLInputElement {
  selectionEnd: number
  selectionStart: number
  createTextRange: () => {
    compareEndPoints: Function
    moveEnd: Function
    moveToBookmark: Function
    setEndPoint: Function
  }
}

/**
 * Returns the position of the cursor in given input.
 */
export const getCursorPos = (input: HTMLInput) => {
  if ('selectionStart' in input && document.activeElement === input) {
    return {
      end: input.selectionEnd,
      start: input.selectionStart,
    }
  } else if (input.createTextRange) {
    // @ts-ignore: 2339
    const sel = document.selection.createRange()
    if (sel.parentElement() === input) {
      const rng = input.createTextRange()
      rng.moveToBookmark(sel.getBookmark())
      let len
      let pos
      for (
        len = 0;
        rng.compareEndPoints('EndToStart', rng) > 0;
        rng.moveEnd('character', -1)
      ) {
        len++
      }
      rng.setEndPoint('StartToStart', input.createTextRange())
      for (
        pos = { start: 0, end: len };
        rng.compareEndPoints('EndToStart', rng) > 0;
        rng.moveEnd('character', -1)
      ) {
        pos.start++
        pos.end++
      }
      return pos
    }
  }
  return -1
}

export const isSyntheticEvent = (e: any): e is React.MouseEvent =>
  !!(e as React.MouseEvent).nativeEvent

/**
 * Calculates the width of the OS's scrollbar.
 */
export const getScrollBarWidth = () => {
  const inner = document.createElement('p')
  inner.style.width = '100%'
  inner.style.height = '200px'

  const outer = document.createElement('div')
  outer.style.position = 'absolute'
  outer.style.top = '0px'
  outer.style.left = '0px'
  outer.style.visibility = 'hidden'
  outer.style.width = '200px'
  outer.style.height = '150px'
  outer.style.overflow = 'hidden'
  outer.appendChild(inner)

  document.body.appendChild(outer)
  const w1 = inner.offsetWidth
  outer.style.overflow = 'scroll'
  let w2 = inner.offsetWidth
  if (w1 === w2) w2 = outer.clientWidth

  document.body.removeChild(outer)

  return w1 - w2
}

/**
 * Sets the title of the page.
 */
export function setPageTitle(subtitle = '') {
  if (subtitle !== '') {
    document.title = `${subtitle} | ${getBrandName()}`
  } else {
    document.title = getBrandName()
  }
}

/**
 * Get a url parameter by name.
 */
export function getParam(name: string) {
  name = name.replace(/[[]]/g, '\\$&')

  const url = window.location.href
  const regexp = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regexp.exec(url)

  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export function getTokenFromURL() {
  const url = window.location.href
  const parts = url.split('/')
  const token = parts[parts.length - 1]
  return token
}

/**
 * Creates an metric object with key, value
 */
const metric = (key: string, value: number) => ({ key, value })

/**
 * Calculates the vertical metrics for the floating menu.
 */
export const getVerticalMetrics = (
  anchorMetrics: ClientRect,
  menuMetrics: ClientRect,
  windowLimit: number,
) => {
  if (
    anchorMetrics.bottom + menuMetrics.height > windowLimit &&
    anchorMetrics.top - menuMetrics.height > 30
  ) {
    return metric('bottom', windowLimit - anchorMetrics.bottom + anchorMetrics.height)
  }
  return metric('top', anchorMetrics.bottom)
}

/**
 * Calculates the horizontal metrics for the floating menu.
 */
export const getHorizontalMetrics = (
  anchorMetrics: ClientRect,
  menuMetrics: ClientRect,
  windowLimit: number,
) => {
  if (anchorMetrics.left + menuMetrics.width > windowLimit) {
    return metric('right', windowLimit - anchorMetrics.right)
  }
  return metric('left', anchorMetrics.left)
}

/**
 * Takes a given background hex value and returns the color to use for the text
 * of the element to ensure that the text is visible.
 *
 * bgColor can be either a short or a full hex value.
 *   #567
 *   #eb83a9
 */
export const fontColorByBackground = (
  bgColor?: string,
  light = 'white',
  dark = 'black',
) => {
  if (!bgColor || !regex.HEX_COLOR.test(bgColor)) {
    return dark
  }

  const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor
  let r: number, g: number, b: number

  // The hex code has been shortened. Adjust each value to be correct.
  if (color.length === 3) {
    const rgb = color.split('')
    r = parseInt(rgb[0] + rgb[0])
    g = parseInt(rgb[1] + rgb[1])
    b = parseInt(rgb[2] + rgb[2])
  } else if (color.length < 6) {
    // Color is invalid.
    return 'black'
  } else {
    // Full hex code, extract each piece.
    r = parseInt(color.substring(0, 2), 16) // hexToR
    g = parseInt(color.substring(2, 4), 16) // hexToG
    b = parseInt(color.substring(4, 6), 16) // hexToB
  }

  const uiColors = [r / 255, g / 255, b / 255]
  const c = uiColors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92
    }
    return Math.pow((col + 0.055) / 1.055, 2.4)
  })
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]
  return L > 0.6 ? dark : light
}

export const IS_MAC = navigator.appVersion.indexOf('Mac') !== -1

export const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea')
  el.value = str
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}
