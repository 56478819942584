export const bump = 1
export const resourceWrapper = 1
export const fixedResources = resourceWrapper + 1
export const reportHeader = 3
export const contextualMenu = 2
export const scrollShadow = 4
export const elementControls = scrollShadow
export const resourceControls = elementControls
export const fuzzySelect = 5
export const menu = 10

export const stickyFooter = 6
export const stepLinting = 6
export const ticketingFooter = 6
export const contactDetailsMenu = ticketingFooter + 1
export const navigation = 9
export const focusedItems = navigation + 1
export const modal = focusedItems + 1
export const modalControls = modal + 1
export const loginOverlay = modal + 1
export const appLoader = loginOverlay + 1
export const errorMessage = loginOverlay + 1

export const ticketingHeader = 6 - 1
export const ticketingDrawer = 6 + 1
