import styled, { css } from 'styled-components'
import { prop, ifProp } from 'styled-tools'

import Icon from 'happitu/src/components/Icon'
import getStyle from 'happitu/src/getStyle'
import { font } from 'theme'

const sharedStyles = css`
  border-radius: 6px;
  border: 1px solid transparent;
  color: inherit;
  display: block;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  max-width: inherit;
  min-width: inherit;
  outline: none;
  overflow: hidden;
  padding: 0.25em 0.5em;
  text-overflow: ellipsis;
`

// Should this potentially be a button or a label?
export const DisplayValue = styled.span<{ placeholder?: string }>`
  ${sharedStyles};
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  :focus {
    box-shadow: 0 0 0 2px ${getStyle('border-color')};
    outline: none;
  }

  :hover {
    background: ${getStyle('text-hover-background')};
  }

  :hover:focus {
    background: rgba(255, 255, 255, 0.6);
  }

  > :first-child:empty:before {
    color: ${getStyle('text-light-color')};
    content: '${prop('placeholder', '')}';
    display: inline-block;
  }
`

// This span is used purely to calculate the width of the input.
export const GhostValue = styled(DisplayValue)`
  left: -1000px;
  opacity: 0;
  position: fixed;
  top: -1000px;
  visibility: none;
`

export const EditableValue = styled.input<{ width: number }>`
  ${sharedStyles};
  color: ${getStyle('text-default-color')};
  background: ${getStyle('input-default-background')};
  width: ${(props) => `calc(${props.width}px + 3em)`};

  :focus {
    box-shadow: 0 0 0 2px ${getStyle('input-focus-borderColor')};
  }

  ::placeholder {
    color: ${getStyle('text-light-color')};
  }
`

export const StyledIcon = styled(Icon)<{ isActive: boolean }>`
  color: ${ifProp('isActive', 'inherit', getStyle('text-light-color'))};
  font-weight: ${font('weight', 'medium')};
  margin-left: 0.5em;
`
