import { debounce } from 'lodash'
import { useState, useMemo, ChangeEvent, useRef, useReducer, useEffect } from 'react'

import SearchSuggester from '../searchSuggester'
import { isImpressionable } from '../searchSuggester.helpers'
import { SuggesterOptions } from '../searchSuggester.types'

import useSuggesterResponse from './useSuggesterResponse'

import { GeneratedCollection } from 'happitu/src/helpers/generatedMetadata'

type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never
interface Options extends SuggesterOptions {
  initialSearchValue: string
}

const UPDATE_SUGGESTER = 'UPDATE_SUGGESTER'

interface Action {
  type: typeof UPDATE_SUGGESTER
  payload: SearchSuggester
}

const suggesterReducer = (prevState: SearchSuggester, action: Action) => {
  switch (action.type) {
    case UPDATE_SUGGESTER:
      return action.payload
    default:
      return prevState
  }
}

const useSuggester = (
  collectionName: GeneratedCollection,
  opts: Partial<Options> = {},
) => {
  const { initialSearchValue = '', limit = 50, sort, ...suggestionOptions } = opts
  const initialSuggester = useMemo(() => new SearchSuggester(collectionName, { sort }), [
    collectionName,
  ])
  const [suggester, dispatch] = useReducer(suggesterReducer, initialSuggester)
  const isLoading = useRef(false)
  const [results, setResults] = useSuggesterResponse(collectionName)
  const [searchValue, setSearchValue] = useState(initialSearchValue)
  const [loadedAllRecords, setLoadedAllRecords] = useState(false)

  const dispatchAction = <M extends keyof typeof suggester>(methodName: M) => (
    ...args: ArgumentTypes<typeof suggester[M]>
  ) => {
    // I'm not sure how to fix the typing error that '...args' creates. But this is set up to properly compose the
    // below handlers with the correct interfaces.
    // @ts-ignore
    const nextSuggester = suggester[methodName](...args) as SearchSuggester
    dispatch({
      type: UPDATE_SUGGESTER,
      payload: nextSuggester,
    })
    nextSuggester.send(searchValue, { limit, ...suggestionOptions }).then(setResults)
    return nextSuggester
  }

  useEffect(() => {
    search('')
  }, [])

  /* Search handlers ================================ */
  const search = async (value: string) => {
    const response = await suggester.send(value, {
      limit,
      groupBy: isImpressionable(collectionName) ? 'impressionId' : undefined,
      ...suggestionOptions,
    })
    setResults(response)
  }

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const nextValue = e.target.value
    setSearchValue(nextValue)
    debounce(() => search(nextValue), 250)()
  }

  /* Filter handlers */

  // Set an individual filter
  const setFilter = dispatchAction('setSearchFilter')
  // Set a bunch of filters from a record
  const setFiltersFromRecord = dispatchAction('setSearchFiltersFromRecord')
  // Remove a filter
  const removeFilter = dispatchAction('removeSearchFilter')
  // Keep filter available, just don't query using it.
  const toggleFilter = dispatchAction('toggleSearchFilter')

  const mapSearchFilters = (
    ...args: ArgumentTypes<typeof suggester['mapSearchFilters']>
  ) => suggester.mapSearchFilters(...args)

  /* Sort handlers */

  // Set order of suggestions
  const setSort = dispatchAction('setSort')
  // Remove suggestion result order
  const clearSort = dispatchAction('clearSort')

  /* Pagination handlers =========================== */

  const loadMoreItems = () =>
    suggester
      .send(searchValue, {
        ...suggestionOptions,
        limit,
        groupBy: isImpressionable(collectionName) ? 'impressionId' : undefined,
        cursor: results.pagination.cursor,
      })
      .then((newResults) => {
        if (newResults.suggestions === null) setLoadedAllRecords(true)
        else setResults(newResults, false)
      })

  // Use this handler if you want to implement infinite scrolling
  const onScroll = async (event: UIEvent | React.UIEvent<HTMLElement>) => {
    const element = event.currentTarget as HTMLElement
    if (!element) return
    const triggerPos = element.scrollTop + element.clientHeight + 300
    if (triggerPos > element.scrollHeight && !isLoading.current && !loadedAllRecords) {
      isLoading.current = true
      await loadMoreItems()
      isLoading.current = false
    }
  }

  return {
    ...results,
    inputProps: {
      onChange: changeHandler,
      value: searchValue,
    },
    suggester,

    loadMoreItems,
    loadedAllRecords,
    mapSearchFilters,
    onScroll,
    setFilter,
    setFiltersFromRecord,
    removeFilter,
    toggleFilter,
    setSort,
    clearSort,
  }
}

export default useSuggester
