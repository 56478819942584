import React from 'react'
import { RenderElementProps } from 'slate-react'

import FullscreenImagePreview from './FullscreenImagePreview'
import { Wrapper, DisplayImg } from './Image.styles'

import { getFileUrl } from 'happitu/src/helpers/fileUploadHelpers'
import { ImageElement } from 'happitu/src/types/slate-types'

interface Props extends RenderElementProps {
  element: ImageElement
  fileIds?: ID[]
}

const DisplayImage = ({ children, ...props }: Props) => {
  const element = props.element
  if (!element.fileId) return null
  const url = getFileUrl(element.fileId)
  const [showFullscreen, setShowFullscreen] = React.useState(false)
  const fileIds = props.fileIds?.length ? props.fileIds : [element.fileId]
  const index = fileIds ? fileIds.indexOf(element.fileId) : 0

  const handleImageClick = () => {
    setShowFullscreen(true)
  }

  const closePreview = () => {
    setShowFullscreen(false)
  }

  return (
    <Wrapper {...props.attributes}>
      <DisplayImg draggable={false} src={url} onClick={handleImageClick} />
      {children}
      {showFullscreen && fileIds && (
        <FullscreenImagePreview
          closePreview={closePreview}
          fileIds={fileIds}
          defaultIndex={index}
        />
      )}
    </Wrapper>
  )
}

export default DisplayImage
