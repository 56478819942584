import { Children, ReactNode } from 'react'

import { isReactComponent } from '../List.types'
import { OptionProps } from '../ListItems'

import { error } from 'happitu/src/helpers/loggerHelper'

interface Opts {
  searchProps: string[]
  search?: boolean
  createOption?: ReactNode
  create?: boolean
}

const useSearchableList = (
  searchTerm = '',
  { search = true, searchProps, createOption, create }: Opts,
) => {
  const term = new RegExp(searchTerm, 'i')
  return (children: ReactNode) => {
    if (!Array.isArray(children))
      return error('no children were passed to useSearchableList')
    let isUniqueTerm = true
    const composedChildren = !search
      ? children
      : Children.toArray(children).filter((option: ReactNode) => {
          if (isReactComponent(option)) {
            return searchProps.find((searchProp) => {
              const searchableProp = option.props[searchProp as keyof OptionProps]
              // check to see if search term is unique amongst the children while we're filtering
              if (
                searchableProp &&
                searchableProp.toLowerCase() === searchTerm.toLowerCase()
              )
                isUniqueTerm = false
              // filterDisplayOption are used for controls that you want to display in a
              // searchable list.
              // e.g. a create new button
              return term.test(searchableProp)
            })
          } else {
            return false
          }
        })
    return create && createOption && isUniqueTerm && searchTerm.trim() !== ''
      ? [createOption, ...composedChildren]
      : composedChildren
  }
}

export default useSearchableList
