import styled from 'styled-components'
import { prop, ifProp } from 'styled-tools'

import getStyle from 'happitu/src/getStyle'

interface HrProps {
  size?: string | number
  dark?: boolean
}

export const Hr = styled.hr<HrProps>`
  border: 0;
  border-top: ${prop('size', 1)}px solid
    ${ifProp('dark', getStyle('dark-border-color'), getStyle('border-color'))};
  margin: 1em;
`
