import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Wrapper } from './AppLauncher.styles'

import { Button } from 'happitu/src/components/button'
import { VARIABLE_REGEXP } from 'happitu/src/helpers/variableHelper'

export default class AppLauncher extends Component {
  static propTypes = {
    attributes: PropTypes.object,
  }

  bindWrapperRef = (ref) => {
    this.wrapper = ref
  }

  handleClick = () => {
    const { link, openInNewTab } = this.props.attributes
    const { variables } = this.props

    const href = link.replace(VARIABLE_REGEXP, (_, $1) => {
      const matchRegExp = new RegExp(`^${$1}$`, 'i')
      const key = Object.keys(variables).find((v) => matchRegExp.test(v))
      return variables[key] && variables[key] !== ''
        ? encodeURIComponent(variables[key])
        : ``
    })
    if (openInNewTab === false) {
      window.location.href = href
    } else {
      window.open(href, '_blank')
    }
  }

  render() {
    const { attributes } = this.props

    return (
      <Wrapper ref={this.bindWrapperRef}>
        <Button
          onClick={this.handleClick}
          value={attributes.label}
          style={{ flexGrow: 1 }}
        />
      </Wrapper>
    )
  }
}
