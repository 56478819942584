import {
  generatedMetadata,
  SearchDocument,
  SearchServices,
  GeneratedCollection,
} from 'happitu/src/helpers/generatedMetadata'
import SearchField from 'happitu/src/models/searchField'
import {
  handleSuccess,
  handleReject,
} from 'happitu/src/services/happitu-api/responseHandler'
import { reportingRequest } from 'happitu/src/services/happitu-reporting-api'
import { JSONResponse } from 'happitu/src/services/happitu-reporting-api/ticketLookupService'
import { WebsocketResponse } from 'happitu/src/services/websocket/fileService'

export default class SearchService {
  protected name: string
  protected searchDocument: SearchDocument
  protected searchDocumentName: string

  protected readonly _fieldByKey: string[] = []
  protected readonly _fields: Record<string, SearchField> = {}

  constructor(name: SearchServices) {
    if (generatedMetadata === undefined) {
      throw Error(
        `generated metadata is not loaded, cannot create '${name}' search service`,
      )
    }
    const service = generatedMetadata.searchServices[name]
    if (service === undefined) {
      throw Error(`generated metadata does not contain a search service named '${name}'`)
    }

    this.name = name
    this.searchDocument = generatedMetadata.searchDocuments[service.searchDocument]
    this.searchDocumentName = service.searchDocument

    Object.keys(this.searchDocument.fields).forEach((fieldName) => {
      const field = new SearchField(
        this.searchDocument.fields[fieldName],
        this.searchDocument.collectionName,
        fieldName,
      )

      this._fields[field.key()] = field
      this._fieldByKey.push(field.key())
    })

    Object.keys(this.searchDocument.embeddedDocuments).forEach((embeddedDocumentName) => {
      const embeddedDocument = this.searchDocument.embeddedDocuments[embeddedDocumentName]
      Object.keys(
        this.searchDocument.embeddedDocuments[embeddedDocumentName].fields,
      ).forEach((fieldName) => {
        const field = new SearchField(
          embeddedDocument.fields[fieldName],
          embeddedDocument.collectionName,
          fieldName,
          embeddedDocumentName,
          embeddedDocument.title,
        )
        this._fields[field.key()] = field
        this._fieldByKey.push(field.key())
      })
    })
  }

  public collectionName(): string {
    return this.searchDocument.collectionName
  }

  public getFieldsByKey(keys: string[]): SearchField[] {
    return keys.map((key) => this.getFieldByKey(key))
  }

  public getFieldByKey(key: string): SearchField {
    const [baseKey] = key.split('>')
    return this._fields[baseKey]
  }

  public availableFields(): SearchField[] {
    return this._fieldByKey.map((key) => this._fields[key])
  }

  public suggestionFields(): string[] {
    return this.availableFields()
      .filter((field) => field.isNGram && !field.dynamic && !field.isExactMatch())
      .map((field) => field.fieldName)
  }

  public send(query: object) {
    return new Promise<JSONResponse | WebsocketResponse>((resolve, reject) => {
      return reportingRequest('/ticket-lookup')
        .send(query)
        .then((r) => resolve(handleSuccess(r)))
        .catch((r) => reject(handleReject(r)))
    })
  }

  public getCollectionName(): GeneratedCollection {
    return this.searchDocument.collectionName
  }

  public getSearchDocumentName(): string {
    return this.searchDocumentName
  }
}
