import React, { createElement, useCallback } from 'react'
import { RenderElementProps } from 'slate-react'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import Icon from 'happitu/src/components/Icon'
import Box from 'happitu/src/components/box'
import getStyle from 'happitu/src/getStyle'
import {
  iconizeFileType,
  humanizeFileType,
} from 'happitu/src/helpers/editor/attachmentHelpers'
import { getFileUrl } from 'happitu/src/helpers/fileUploadHelpers'
import { AttachmentElement } from 'happitu/src/types/slate-types'

interface WrapperProps {
  disabled?: boolean
  selected?: boolean
}

const Wrapper = styled(Box)<WrapperProps>`
  grid-template-columns: min-content 1fr min-content;
  position: relative;
  user-select: none;

  ${ifProp(
    'selected',
    css`
      box-shadow: 0 0 0 2px ${getStyle('input-focus-borderColor')};
      z-index: 1;
    `,
  )}

  ${ifProp(
    'disabled',
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `,
    css`
      cursor: pointer;
    `,
  )}
`

interface Props extends RenderElementProps {
  element: AttachmentElement
  readOnly?: boolean
  selected?: boolean
}

const DisplayAttachment = (props: Props) => {
  const element = props.element
  const clickEventType = props.readOnly === false ? 'onDoubleClick' : 'onClick'

  const handleClick = useCallback(() => {
    element.fileId && window.open(getFileUrl(element.fileId))
  }, [element.fileId])

  return (
    <div {...props.attributes} style={{ padding: '0.5em 0' }}>
      {createElement(
        Wrapper,
        {
          align: 'center',
          bg: 'alt',
          contentEditable: false,
          disabled: !element.fileId,
          gap: 1,
          padding: 1,
          rounded: true,
          selected: !!props.selected,
          [clickEventType]: handleClick,
        },
        [
          <Icon
            key="icon"
            type={iconizeFileType(element.fileType)}
            style={{ fontSize: '1.5em' }}
          />,
          <Box key="name" gap="0" style={{ lineHeight: 1.25 }}>
            <strong>{element.name}</strong>
            <sub>{humanizeFileType(element.fileType)}</sub>
          </Box>,
          <Icon key="download" type="download" onClick={handleClick} />,
        ],
      )}
      {props.children}
    </div>
  )
}

export default DisplayAttachment
