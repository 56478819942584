import styled, { keyframes } from 'styled-components'
import { ifProp } from 'styled-tools'

import theme, { anim } from 'happitu/src/themes/theme'

export const loaderSize = 10
const loaderBorderWidth = 2

const grow = keyframes`
  0% {
    transform: translateY(-50%) scale(1);
    opacity: 1;
  }

  50% {
    transform: translateY(-50%) scale(1.8);
    opacity: 0.5;
  }

  100% {
    transform: translateY(-50%) scale(1);
    opacity: 1;
  }
`

const Loader = styled.i<{ invert?: boolean }>`
  animation: ${grow} ${anim('speed', 'reallySlow')} infinite;
  animation-delay: ${anim('speed', 'normal')};
  display: block;
  height: ${loaderSize}px;
  left: ${loaderSize}px;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: ${loaderSize}px;
  margin-right: 10px;

  &:before {
    border-radius: ${loaderSize}px;
    border: ${loaderBorderWidth}px solid
      ${ifProp('invert', theme('button.primary.color'), theme('button.default.color'))};
    content: '';
    display: block;
    height: ${loaderSize - loaderBorderWidth * 2}px;
    width: ${loaderSize - loaderBorderWidth * 2}px;
  }
`

export default Loader
