// Each color palette is based off of a single hue.
// TODO: Add more natural depth by changing the adjusting the hue on top of the saturation and lightness.
// Maybe try programmatically converting the hex values to HSL and adjusting the hue for each value.

const colors = {
  grey: {
    100: '#F9F9FE',
    200: '#eeeeef',
    300: '#E3E3ED',
    400: '#CFD2E3',
    500: '#A6AAC5',
    600: '#777C9B',
    700: '#4E526B',
    800: '#383846',
    900: '#1C1C2C',
    950: '#151523',
  },
  purple: {
    100: '#FAF8FF',
    200: '#E6DEFF',
    300: '#CEBDFF',
    400: '#B499FF',
    500: '#9B78FF',
    600: '#8762F0',
    700: '#5B36C4',
    800: '#4D28B6',
    900: '#29068C',
  },
  indigo: {
    100: '#F8F9FF',
    200: '#DEE2FF',
    300: '#BDC6FF',
    400: '#5c72ff',
    500: '#5E1EE2',
    600: '#584CD6',
    700: '#293EC5',
    800: '#1225A3',
    900: '#00117E',
  },
  blue: {
    100: '#F5FAFE',
    200: '#D3E8FA',
    300: '#A9D2F7',
    400: '#3385FF',
    500: '#0064FF',
    600: '#358DDE',
    700: '#2078C9',
    800: '#0A62B3',
    900: '#00498D',
  },
  teal: {
    100: '#E5FEFE',
    200: '#B2F3F3',
    300: '#82E4E4',
    400: '#52CFCF',
    500: '#3BB0B0',
    600: '#349696',
    700: '#2D7979',
    800: '#285F5F',
    900: '#224F4F',
  },
  mint: {
    100: '#EEFEF9',
    200: '#BAF9E6',
    300: '#8CD7C0',
    400: '#4BC9A3',
    500: '#3FB490',
    600: '#37997B',
    700: '#307C64',
    800: '#2A6150',
    900: '#245143',
  },
  green: {
    100: '#EFFFF1',
    200: '#BEFDC8',
    300: '#82F795',
    400: '#5DDA72',
    500: '#00A882',
    600: '#3D9F4D',
    700: '#348040',
    800: '#2D6436',
    900: '#26532E',
  },
  lime: {
    100: '#F6FCEC',
    200: '#DAF2B3',
    300: '#B7E36E',
    400: '#C1FD60',
    500: '#9BEC19',
    600: '#87CC18',
    700: '#589830',
    800: '#3B8010',
    900: '#3B6000',
  },
  yellow: {
    100: '#FCF9EC',
    200: '#FEF3CB',
    300: '#FFEAA0',
    400: '#FFDD62',
    500: '#F6C71F',
    600: '#CEA71A',
    700: '#A5861A',
    800: '#82690C',
    900: '#454503',
  },
  orange: {
    100: '#FFF9F6',
    200: '#FCE9DA',
    300: '#FACEAE',
    400: '#F6A972',
    500: '#ED8A42',
    600: '#D67128',
    700: '#B55F21',
    800: '#8F4C1B',
    900: '#6F3C17',
  },
  red: {
    100: '#FFF5F5',
    200: '#FDD7D7',
    300: '#FDB3B3',
    400: '#FB8383',
    500: '#F46868',
    600: '#E44444',
    700: '#C43434',
    800: '#9A2F2F',
    900: '#732B2B',
  },
  pink: {
    100: '#FFF5F9',
    200: '#FBD5E5',
    300: '#FAB0CF',
    400: '#F57DB0',
    500: '#EE629C',
    600: '#DD3D80',
    700: '#BD2D6A',
    800: '#952A57',
    900: '#702846',
  },
  magenta: {
    100: '#FEF4FD',
    200: '#F9D3F5',
    300: '#F6ACEE',
    400: '#EB7FE0',
    500: '#E65AD8',
    600: '#D13CC2',
    700: '#B626A7',
    800: '#8F2485',
    900: '#6C2465',
  },
  violet: {
    100: '#FCF5FF',
    200: '#F0D6FC',
    300: '#E4B3FD',
    400: '#D089F5',
    500: '#C467F3',
    600: '#AC49DE',
    700: '#9233C3',
    800: '#752E99',
    900: '#5A2A72',
  },
}

export default colors
