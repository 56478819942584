import { WebsocketResponse } from '../websocket/fileService'

import { GeneratedCollection } from 'happitu/src/helpers/generatedMetadata'
import { warn } from 'happitu/src/helpers/loggerHelper'
import SearchService from 'happitu/src/models/searchService'
import { SuggestResponse, SuggesterOptions } from 'happitu/src/models/searchSuggester'
import {
  handleReject,
  handleSuccess,
} from 'happitu/src/services/happitu-api/responseHandler'
import { reportingRequest } from 'happitu/src/services/happitu-reporting-api'

let TicketLookupService = {} as SearchService

try {
  TicketLookupService = new SearchService('TicketLookup')
} catch (e) {
  warn('Missing generated metadata', e)
}

export default TicketLookupService

export interface JSONResponse {
  rows: object[]
  pagination: PaginationObject
}

export const findTicketsRequest = (query: object) => {
  return new Promise<JSONResponse | WebsocketResponse>((resolve, reject) => {
    return reportingRequest('/ticket-lookup')
      .send(query)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r)))
  })
}

const getCollectionRoute = (collectionName: GeneratedCollection) => {
  switch (collectionName) {
    case GeneratedCollection.Contacts:
      return 'suggest-contacts'
    default:
      return `suggest/${collectionName}`
  }
}

export const suggestRequest = <S>(
  collectionName: GeneratedCollection,
  fieldName: string,
  query: string,
  options: SuggesterOptions = {},
) => {
  return new Promise<SuggestResponse<S>>((resolve, reject) => {
    return reportingRequest(`/${getCollectionRoute(collectionName)}/${fieldName}`, 'get')
      .query({ query, ...options })
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r)))
  })
}
