import { FC } from 'react'

import { connectTicketContext, TicketComponentProps } from '../../ticketing/ticketing'
import { useCurrentInteraction, useKConversationHook } from '../common/kustomerHooks'

import { EditableWorkflowElement } from 'happitu/src/types/workflowElements'

const KustomerSetStatus: FC<
  EditableWorkflowElement & TicketComponentProps<'ticketInteractions' | 'tags'>
> = (props) => {
  const ticketInteraction = useCurrentInteraction()

  useKConversationHook({
    tags: ticketInteraction?.tags.map((interactionTag) => {
      const tag = props.tags.findById<TagRecord>(interactionTag.tagId)
      return tag?.name || 'unknown tag'
    }),
  })

  return null
}

export default connectTicketContext(['ticketInteractions', 'tags?'])(KustomerSetStatus)
