import apiRequest from './request'
import { handleReject, handleSuccess } from './responseHandler'

export const createStepRequest = (step: Partial<WorkflowStepRecord>) =>
  new Promise((resolve, reject) => {
    apiRequest
      .post('/workflow_steps')
      .send(step)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r)))
  })

export const updateStepRequest = (stepId: ID, updates: Partial<WorkflowStepRecord>) =>
  new Promise((resolve, reject) => {
    apiRequest
      .put(`/workflow_steps/${stepId}`)
      .send(updates)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r)))
  })

export const attachHelpTopicRequest = (
  stepId: ID,
  operation: 'add' | 'remove' = 'add',
  helpTopicImpressionId: ID,
) =>
  new Promise((resolve, reject) => {
    apiRequest
      .put(`/workflow-step-help-topic/${stepId}`)
      .send({
        helpTopicImpressionId: {
          [operation]: helpTopicImpressionId,
        },
      })
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r)))
  })

export const deleteStepRequest = (stepId: ID, version: number) =>
  new Promise((resolve, reject) =>
    apiRequest
      .delete(`/workflow_steps/${stepId}`)
      .send({ version })
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )

export const duplicateStepRequest = (stepId: ID) =>
  new Promise((resolve, reject) =>
    apiRequest
      .post(`/workflow_steps/${stepId}/duplicate`)
      .send({})
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )
