import { FC } from 'react'

import { useKCustomerHook } from '../common/kustomerHooks'

import { KustomerSetAttributeDisplayProps } from 'happitu/src/types/workflowElements'

const unpackAttribute = (
  strKey?: string,
  value?: string,
  variables: Record<string, string> = {},
): Record<string, string | Record<string, any>> => {
  if (!strKey || !value) return {}

  const hydrateVariable = (initialValue: string): string => {
    return variables[value] || initialValue
  }

  const [key, ...remaining] = strKey.split('.')
  return {
    [key]:
      remaining && remaining.length > 0
        ? unpackAttribute(remaining.join('.'), value, variables)
        : hydrateVariable(value),
  }
}

const KustomerSetAttribute: FC<KustomerSetAttributeDisplayProps> = (props) => {
  useKCustomerHook(
    unpackAttribute(props.attributes.attribute, props.attributes.value, props.variables),
  )

  return null
}

export default KustomerSetAttribute
