import { darken } from 'polished'

import { alpha, border, grey, red, secondary } from './colors'
import * as font from './font'

/*
 * Form Colors
 * --------------------------------------
 */

/* Radio */
export const radio = {
  size: '18px',

  active: {
    background: secondary.base,
    borderColor: secondary.base,
    color: darken(.08, secondary.base),
  },
}

/* Dropdown */
export const dropdown = {
  background: darken(0.025, grey.offWhite),

  focus: {
    background: darken(0.05, grey.offWhite),
  },
}

/* Input */
export const input = {
  border: `1px solid ${ border.input }`,
  borderRadius: '4px',
  padding: '8px 10px',
  searchBorder: `1px solid ${ dropdown.background }`,

  focus: {
    border: `1px solid ${ grey.lighter }`,
    boxShadow: `0 0 0 2px ${ alpha['20'] }`,
    insetBoxShadow: `inset 0 0 0 2px ${ alpha['20'] }, 0 0 0 1px ${ grey.lighter }`,
  },
}

/*
 * Button Colors
 * --------------------------------------
 */

/* Base */
export const button = {
  border: '1px solid',
  borderRadius: input.borderRadius,

  fontSize: font.size.normal,
  fontWeight: font.weight.medium,

  padding: '8px 12px',

  focus: {
    border: input.focus.border,
    boxShadow: input.focus.boxShadow,
  },

  /* Default */
  default: {
    background: grey.nearWhite,
    borderColor: grey.nearWhite,
    color: grey.base,

    active: {
      background: grey.offWhite,
      borderColor: grey.offWhite,
      color: grey.base,
    },
  },

  /* Warn */
  warn: {
    background: 'white',
    borderColor: grey.lightest,
    color: red.base,

    active: {
      background: grey.lighter,
      borderColor: grey.lighter,
      color: red.base,
    },
  },

  /* Primary */
  primary: {
    background: secondary.base,
    borderBottonColor: secondary.base,
    borderColor: secondary.base,
    color: 'white',

    active: {
      background: secondary.dark,
      borderColor: secondary.dark,
      color: 'white',
    },
  },
}
