import { useState, useEffect, useCallback, useRef } from 'react'

import { Hotkey } from 'happitu/src/hooks/useHotkey'

const useDateSelect = () => {
  const ref = useRef<HTMLLabelElement>(null)
  const [isVisible, setVisibility] = useState(false)
  const handleOpen = () => setVisibility(true)
  const handleClose = () => setVisibility(false)

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (
        !isVisible &&
        [Hotkey.Select, Hotkey.Enter, Hotkey.Down].includes(e.key as Hotkey)
      ) {
        e.preventDefault()
        handleOpen()
      }
    },
    [isVisible],
  )

  useEffect(() => {
    ref.current?.addEventListener('keydown', handleKeyDown)
    return () => ref.current?.removeEventListener('keydown', handleKeyDown)
  }, [isVisible, ref])

  return {
    ref,
    onOpen: handleOpen,
    onClose: handleClose,
    isVisible,
  }
}

export default useDateSelect
