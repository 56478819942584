import React, { ReactNode, Children, ReactElement, MouseEventHandler } from 'react'
import styled from 'styled-components'

import getStyle from 'happitu/src/getStyle'
import { font } from 'theme'

interface NavProps {
  className?: string
  children: ReactElement<NavItemProps>[]
  onNavigate: (value: string) => void
  activeTab?: string
}

interface NavItemProps {
  children: ReactNode
  value: string
  active?: boolean
  onClick?: MouseEventHandler
}

const MenuNav = (props: NavProps) => {
  return (
    <nav className={props.className}>
      {Children.map(props.children, (child) =>
        React.createElement(child.type, {
          ...child.props,
          active: child.props.value === props.activeTab,
          onClick: () => props.onNavigate(child.props.value),
        }),
      )}
    </nav>
  )
}

export const MenuNavItem = (props: NavItemProps) => {
  return (
    <button onClick={props.onClick} className={props.active ? 'active' : undefined}>
      {props.children}
    </button>
  )
}

export default styled(MenuNav)`
  border-bottom: 1px solid ${getStyle('border-color')};
  display: grid;
  grid-auto-flow: column;

  :not(:first-child) {
    border-top: 1px solid ${getStyle('border-color')};
  }

  > button {
    background: transparent;
    border: 0;
    color: ${getStyle('text-light-color')};
    font-weight: ${font('weight', 'medium')};
    padding: 1em 2em;

    &.active {
      color: ${getStyle('text-active-color')};
    }

    &:not(:last-child) {
      border-right: 1px solid ${getStyle('border-color')};
    }
  }
`
