import { darken, lighten, rgba } from 'polished'

export const black = 'rgb(0, 0, 0)'
export const baseGrey = '#6a777a'
export const white = 'rgb(255, 255, 255)'

export const purple = '#820263'
 // Alternate teals: '#00c08c' //'#46e6b4' // '#47e4ae' //'rgb(71, 228, 174)'
export const teal = '#08A569' // '#42d3a3'
export const darkestGrey = darken(.5, baseGrey)
export const darkerGrey = darken(.3, baseGrey)
export const darkGrey = darken(.15, baseGrey)
export const lightGrey = lighten(.15, baseGrey)
export const lighterGrey = lighten(.3, baseGrey)
export const lightestGrey = lighten(.41, baseGrey)

export const borderColor = lightestGrey

export const grey = {
  nearWhite: 'rgb(245, 245, 249)',
  offWhite: '#ebebf0',
  white: 'rgb(255, 255, 255)',

  light: '#728084',
  lighter: '#AEB4B5',
  lightest: '#DBDBDB',

  base: baseGrey,

  dark: '#435154',
  darker: '#2E373A',
  darkest: '#121B1F',
}

export const alpha = {
  5: rgba(grey.darkest, .05),
  10: rgba(grey.darkest, .10),
  20: rgba(grey.darkest, .20),
  25: rgba(grey.darkest, .25),
  30: rgba(grey.darkest, .30),
  40: rgba(grey.darkest, .40),
  50: rgba(grey.darkest, .50),
  60: rgba(grey.darkest, .60),
  70: rgba(grey.darkest, .70),
  75: rgba(grey.darkest, .75),
  80: rgba(grey.darkest, .80),
  90: rgba(grey.darkest, .90),
  100: rgba(grey.darkest, .100),
}

export const primary = {
  base: purple,

  dark: darken(.15, purple),
  light: lighten(.15, purple),
}

export const secondary = {
  light: '#78e0c2',

  base: teal,
  baseText: teal,

  dark: '#00c08c',
  darker: '#00ab6a',
}

export const border = {
  base: grey.lightest,

  dark: grey.lighter,
  darker: lighten(.1, grey.light),

  focus: `rgba(${ grey.lighter }, .8)`,
  input: grey.lighter,
}

export const buttonBg = {
  base: darken(.03, grey.offWhite),
}

export const green = {
  base: teal,

  light: teal,
  lightest: 'rgb(232, 246, 240)',
}

export const orange = {
  base: '#FFA500',

  dark: '#F15B2B',
}

export const red = {
  lightest: 'rgb(252, 222, 228)',

  lighter: '#F8A6B8',

  light: '#F34970',

  base: '#ED214D',

  dark: '#C31A40',
}

export const yellow = {
  lightest: 'rgb(255, 245, 218)',

  light: '#FFCF33',

  base: 'rgb(255, 207, 51)',
}

export const blue = {
  light: '#37C1EC',

  base: '#248fc6',

  dark: '#4498AF',
  darker: '#1264a3',
}

export const pink = {
  base: '#CC77B1',
}

export const tag = {
  base: '#424143',

  blue: blue.base,
  grey: '#424143',
  orange: orange.dark,
  pink: pink.base,
  purple: primary.base,
  red: red.dark,
  teal: blue.dark,
}

export const ticket = {
  agentNotes: yellow.lightest,
  documentation: yellow.lightest,
}
