import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'

export const getOrganizationId = (): string | null => {
  // This is only populated when someone hits an organization sub domain. e.g., customer-direct.happitu.com
  const organizationParam = window.location.pathname.match(/org\/([a-z,0-9]+)(\/.+)?/i)
  return organizationParam ? organizationParam[1] : null
}

interface Token {
  oui: Record<ID, ID>
  orgOpts: Record<ID, { limited?: boolean }>
}

const getToken = (auth: string) => {
  try {
    return jwtDecode<Token>(auth)
  } catch {
    return
  }
}

export const isAuthenticated = (auth = Cookies.get('auth')) => {
  const organizationId = getOrganizationId()
  if (!auth || !organizationId) return false
  const token = getToken(auth)
  if (!token) return false
  if (
    token.orgOpts &&
    token.orgOpts[organizationId] &&
    token.orgOpts[organizationId].limited
  )
    return false
  if (token.oui && token.oui[organizationId]) return true
  return false
}
