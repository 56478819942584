import { useState } from 'react'
import { Text } from 'slate'

import { Content, FloatingCopyButton, Wrapper } from '../common/script-blurb.styles'
import { getInitialValue } from '../slate'

import { humaizeVariableValue } from 'happitu/src/components/RichTextEditor/Element/editable-workflow-variable'
import RenderSlateValue from 'happitu/src/components/RichTextEditor/render-slate-value'
import { ElementType, CustomElement, BlockChild } from 'happitu/src/types/slate-types'

export async function copyText(value: CustomElement, variables: Record<string, string>) {
  const html = serialize(value, variables, 'html')
    .replace(/\n+$/g, '')
    .replace(/\n/g, '<br />')
  const plain = serialize(value, variables, 'plain').replace(/\n+$/g, '')

  const typeRich = 'text/html'
  const blobRich = new Blob([`<span style="font-family: inherit">${html}</span>`], {
    type: typeRich,
  })

  const typePlain = 'text/plain'
  // optionally, replace/remove html tags with plain text equivalents (e.g., newlines instead of <br>)
  // const plainText = replaceHtmlTags(value);
  const blobPlain = new Blob([plain], { type: typePlain })

  // Copy both types so it can be pasted into either a Rich Text editor with formatting,
  // or a plain text editor with basic formatting (e.g., new lines but not HTML tags)
  // @ts-ignore
  const data = [new ClipboardItem({ [typeRich]: blobRich, [typePlain]: blobPlain })]
  // @ts-ignore
  await navigator.clipboard.write(data)
}

const serialize = (
  node: CustomElement | BlockChild,
  variables: Record<string, string>,
  format: 'html' | 'plain',
): string => {
  if (Text.isText(node)) {
    return node.text
  }

  const children = node.children
    .map((n: CustomElement | BlockChild) => serialize(n, variables, format))
    .join('')

  switch (node.type) {
    case ElementType.BlockQuote:
      return `> ${children}\n`
    case ElementType.OrderedList:
      return `1. ${children}\n`
    case ElementType.UnorderedList:
      return `* ${children}\n`
    case ElementType.Link:
      return node.children.some((c) => !!c.text)
        ? format === 'plain'
          ? `${node.url}`
          : `<a href="${node.url}">${node.children.map((c) => c.text).join(' ')}</a>`
        : ''
    case ElementType.SectionBreak:
      return `\n\n---\n\n`
    case ElementType.WorkflowVariable:
      return humaizeVariableValue(node.variable, node.placeholder, variables)
    default:
      return `${children}\n`
  }
}

const ScriptBlurb = ({ attributes, onClick, variables }: WorkflowDisplayElement) => {
  const [isHovering, setIsHovering] = useState(false)
  const [copied, setCopied] = useState(false)

  const handleCopy = () => {
    copyText({ children: attributes.richValue } as CustomElement, variables)

    setCopied(true)
    setTimeout(() => setCopied(false), 1500)
  }

  return (
    <Wrapper onClick={onClick} isHovering={isHovering}>
      <div className="mask" />
      <Content>
        {!!attributes.richValue || !!attributes.value ? (
          <RenderSlateValue
            value={getInitialValue(attributes.richValue, attributes.value)}
            variables={variables}
          />
        ) : (
          attributes.placeholder
        )}
      </Content>
      <FloatingCopyButton
        icon={copied ? undefined : 'clipboard'}
        copied={copied}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={handleCopy}
      >
        {copied ? 'Copied!' : ''}
      </FloatingCopyButton>
    </Wrapper>
  )
}

export default ScriptBlurb
