import * as React from 'react'
import styled from 'styled-components'

import {
  ClearIcon,
  Input,
  LeftPlaceholder,
  Loader,
  ResultCount,
  RightPlaceholder,
  SearchIcon,
  Wrapper,
} from 'happitu/src/components/Fields/SearchInput.styles'
import HotkeyFigure from 'happitu/src/components/Layout/HotkeyFigure'
import { pluralize } from 'happitu/src/helpers/stringHelper'

interface Props extends React.ComponentProps<'input'> {
  autoFocus?: boolean
  className?: string
  focus?: boolean
  hotKeyLabel?: string
  inverse?: boolean
  loading?: boolean
  onBlur?: React.FocusEventHandler
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onClear?: React.MouseEventHandler
  onFocus?: React.FocusEventHandler
  placeholder?: string
  resultCount?: number
  value?: string
}

function useFocus(onFocus?: React.FocusEventHandler, onBlur?: React.FocusEventHandler) {
  const [focus, setFocus] = React.useState(false)

  function handleFocus(event: React.FocusEvent<HTMLInputElement>) {
    setFocus(true)
    event.currentTarget.select()
    if (onFocus) {
      onFocus(event)
    }
  }

  function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    setFocus(false)
    if (onBlur) {
      onBlur(event)
    }
  }

  return {
    focus,
    setBlur: handleBlur,
    setFocus: handleFocus,
  }
}

function SearchInput(props: Props, ref: React.Ref<HTMLInputElement>) {
  const {
    className,
    hotKeyLabel,
    loading,
    onBlur,
    onClear,
    onFocus,
    resultCount,
    inverse,
    ...otherProps
  } = props
  const { focus, setFocus, setBlur } = useFocus(onFocus, onBlur)
  return (
    <Wrapper focus={focus} inverse={inverse} className={className}>
      <LeftPlaceholder>
        {loading ? <Loader size="small" /> : <SearchIcon type="search" />}
      </LeftPlaceholder>
      <Input
        {...otherProps}
        onBlur={setBlur}
        onFocus={setFocus}
        ref={ref}
        data-slate-editor={true}
        data-search-input={true} // Used to manage focus with help topic actions.
        contentEditable={true}
      />
      {resultCount && (
        <RightPlaceholder>
          <ResultCount>
            {resultCount} {pluralize('result', resultCount)}
          </ResultCount>
        </RightPlaceholder>
      )}
      {!resultCount && hotKeyLabel && (
        <RightPlaceholder>
          <HotkeyFigure hotkey={hotKeyLabel} />
        </RightPlaceholder>
      )}
      {resultCount && onClear && (
        <RightPlaceholder>
          <ClearIcon type="close" onClick={onClear} />
        </RightPlaceholder>
      )}
    </Wrapper>
  )
}

export default styled(React.forwardRef(SearchInput))``
