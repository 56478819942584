import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { Element, Transforms } from 'slate'
import { useSlate } from 'slate-react'

import {
  StyledInput,
  ApplyButton,
  ViewContainer,
  Wrapper,
  LinkContainer,
  Spacer,
} from './LinkTooltip.styles'

import { IconButton } from 'happitu/src/components/Buttons'
import CopyButton from 'happitu/src/components/Buttons/CopyButton'
import { FlexRow } from 'happitu/src/components/_DEPRECATED/Flex'
import useHotkey, { Hotkey } from 'happitu/src/hooks/useHotkey'
import { ElementType } from 'happitu/src/types/slate-types'

interface Props {
  defaultValue?: string
  defaultIsEditing?: boolean
  direction: 'above' | 'below'
  isVisible: boolean
  nodeId: ID
  onClose: () => void
  onSubmit: (value: string) => void
}

const LinkTooltip = ({
  defaultValue = '',
  direction,
  isVisible,
  nodeId,
  onClose,
  onSubmit,
}: Props) => {
  const [isEditing, setIsEditing] = React.useState(!defaultValue) // Start in edit mode when creating a new link.
  const [value, setValue] = React.useState(defaultValue)
  const ref = React.useRef<HTMLDivElement>(null)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const editor = useSlate()

  const initial =
    direction === 'below'
      ? { opacity: 0, transform: 'translate3d(-50%, -10%, 0)' }
      : { opacity: 0, transform: 'translate3d(-50%, 10%, 0)' }
  const animate = { opacity: 1, transform: 'translate3d(-50%, 0%, 0)' }

  // Handle auto focus.
  React.useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isEditing])

  // Close the input if you click outside.
  // NOTE: Currently using this listener instead of onBlur to make sure the behavior is consistent even if there's an issue with focus or Slate.
  React.useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
        if (defaultValue === '') {
          Transforms.unwrapNodes(editor, {
            at: [],
            match: (n) =>
              Element.isElement(n) && n.type === ElementType.Link && n.nodeId === nodeId,
          })
        }
        onClose()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setValue(e.target.value)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      e.preventDefault()
      if (defaultValue === '') {
        Transforms.unwrapNodes(editor, {
          at: [],
          match: (n) =>
            Element.isElement(n) && n.type === ElementType.Link && n.nodeId === nodeId,
        })
      }
      setValue(defaultValue)
      setIsEditing(false)
    } else if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault()
      if (!!value) {
        onSubmit(value)
        setIsEditing(false)
      }
    }
  }

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    Transforms.unwrapNodes(editor, {
      at: [],
      match: (n) =>
        Element.isElement(n) && n.type === ElementType.Link && n.nodeId === nodeId,
    })
  }

  const handleEdit = () => setIsEditing(true)

  // NOTE: The tooltip closes on apply as of now. This is an unintentional effect of onSubmit.
  // TODO: Handle onSubmit differently if we don't want to close the tooltip.
  const handleApply = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    onSubmit(value)
    setIsEditing(false)
  }

  // Close the tooltip if you press escape while not editing.
  useHotkey(Hotkey.Close, onClose, !isEditing, [isVisible, isEditing])

  return (
    <AnimatePresence>
      {isVisible && (
        <Wrapper
          animate={animate}
          contentEditable={false}
          transition={{
            damping: 50,
            mass: 1,
            stiffness: 500,
            // bounce: 0, // Overridden if damping, mass, or stiffness are set.
            type: 'spring',
            velocity: 1000,
          }}
          // exit={initial}
          initial={initial}
          isBelow={direction === 'below'}
          ref={ref}
        >
          {isEditing ? (
            <FlexRow>
              <StyledInput
                contentEditable
                data-slate-editor={true}
                data-slate-hover-input={true}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder="https://example.com"
                ref={inputRef}
                size={30}
                type="url"
                value={value}
              />
              <ApplyButton
                disabled={!value}
                onClick={handleApply}
                primary
                title="Apply link"
              >
                Apply
              </ApplyButton>
            </FlexRow>
          ) : (
            <ViewContainer>
              <LinkContainer>
                <a
                  href={value}
                  rel="noreferrer nofollow noopener"
                  target="_blank"
                  title={value}
                >
                  {value}
                </a>
              </LinkContainer>
              <Spacer />
              <CopyButton ghost rounded text={value} title="Copy link" />
              <IconButton
                ghost
                icon="edit"
                onClick={handleEdit}
                rounded
                title="Edit link"
              />
              <IconButton
                ghost
                icon="delete"
                onClick={handleDelete}
                rounded
                title="Remove link"
              />
            </ViewContainer>
          )}
        </Wrapper>
      )}
    </AnimatePresence>
  )
}

export default LinkTooltip
