import styled from 'styled-components'

import { Option } from 'happitu/src/components/Lists'
import getStyle from 'happitu/src/getStyle'
import { icomoon } from 'happitu/src/themes/style-utils'

export default styled(Option)`
  position: relative;

  :after {
    ${icomoon};
    content: '\\e916';
    position: absolute;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);
    color: ${getStyle('text-light-color')};
  }
`
