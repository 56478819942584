import * as React from 'react'
import styled from 'styled-components'

import Icon from 'happitu/src/components/Icon'
import { icomoon } from 'happitu/src/themes/style-utils'
import theme, { color } from 'theme'

interface Props {
  value?: string
  placeholder: string
}

export const Arrow = styled(Icon).attrs(() => ({
  type: 'bi-direction-arrow',
}))`
  color: ${color('grey', 'light')};
  margin-left: 10px;
  &:after {
    ${icomoon};
    background: white;
    content: '\\e913';
    position: absolute;
    right: 0.5em;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
`

export const Wrapper = styled.div<{ empty: boolean }>`
  align-items: center;
  border-radius: 3px;
  border: ${theme('input.border')};
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  line-height: 22px;
  padding: 8px 10px;

  &:hover {
    border-color: ${theme('input.focus.border')};
    color: ${color('grey', 'dark')};

    ${Arrow} {
      color: ${color('grey', 'dark')};
    }
  }
`

export const Label = styled.div`
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  i {
    color: ${color('grey', 'light')};
  }
`

export default function CitySelectValue({ value, placeholder }: Props) {
  return (
    <Wrapper empty={!value}>
      <Label>{value || <i>{placeholder}</i>}</Label>
      <Arrow />
    </Wrapper>
  )
}
