import PropTypes from 'prop-types'
// import PropTypes from 'prop-types'

export const UserShape = PropTypes.shape({
  createdAt: PropTypes.string,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  organizationId: PropTypes.string,
  password: PropTypes.string,
  updatedAt: PropTypes.string,
})

export const WorkflowVersionShape = PropTypes.shape({
  groups: PropTypes.arrayOf(PropTypes.string),
  version: PropTypes.number,
})

export const WorkflowShape = PropTypes.shape({
  createdAt: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  publishedAt: PropTypes.string,
  publishedBy: PropTypes.object,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.oneOfType([PropTypes.object,PropTypes.string]),
  resources: PropTypes.arrayOf(PropTypes.string),
  versions: PropTypes.arrayOf(WorkflowVersionShape),
  currentVersion: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
})

export const WorkflowGroupShape = PropTypes.shape({
  createdAt: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.object),
  updatedAt: PropTypes.string,
})

export const WorkflowStepShape = PropTypes.shape({
  actions: PropTypes.arrayOf(PropTypes.object),
  createdAt: PropTypes.string,
  elements: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  updatedAt: PropTypes.string,
})

export const WorkflowElementShape = PropTypes.shape({
  attributes: PropTypes.object,
  component: PropTypes.string,
  createdAt: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
})

export const WorkflowActionShape = PropTypes.shape({
  documentation: PropTypes.string,
  email: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string.isRequired,
  link: PropTypes.string,
  name: PropTypes.string,
  tag: PropTypes.string,
})

export const WorkflowTagShape = PropTypes.shape({})

export const OrganizationShape = PropTypes.shape({})

export const ResourceShape = PropTypes.shape({
  contentType: PropTypes.string,
  createdAt: PropTypes.string,
  description: PropTypes.string,
  expireAt: PropTypes.string,
  fileName: PropTypes.string,
  id: PropTypes.string,
  importance: PropTypes.number,
  organizationId: PropTypes.string,
  s3FileName: PropTypes.string,
  name: PropTypes.string,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string,
})

export const DraggableShape = {
  item: PropTypes.shape({
    name: PropTypes.string
  })
}

export const FuzzySelectOptions = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })),
    value: PropTypes.string.isRequired,
  })
)


export const ActionShape = PropTypes.shape({
  documentation: PropTypes.string,
  email: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
  link: PropTypes.string,
  name: PropTypes.string,
  tag: PropTypes.string,
})

export const TagShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
})
