import { RenderElementProps } from 'slate-react'

import { Hr, HrContainer } from './Element.styles'

import { SectionBreakElement } from 'happitu/src/types/slate-types'

interface Props extends RenderElementProps {
  element: SectionBreakElement
}

const SectionBreak = (props: Props) => {
  // Note: Overflow is applied to prevent margin collapse between adjacent section breaks.
  return (
    <div {...props.attributes} contentEditable={false} style={{ overflow: 'auto' }}>
      <HrContainer>
        <Hr />
      </HrContainer>
      {props.children}
    </div>
  )
}

export default SectionBreak
