import styled from 'styled-components'

import TextField from '../final-form-fields/fields/TextField'

import { InsetLabelContent } from './FieldUtils'

import { font } from 'theme'

const LargeTextField = styled(TextField)`
  input {
    font-size: ${font('size', 'large')};
    line-height: 1.5em;
  }

  input[value='']:not(:focus)
    + ${InsetLabelContent},
    input:not([type]):empty:not(:focus)
    + ${InsetLabelContent} {
    transform: translateY(9px);
  }
`

export default LargeTextField
