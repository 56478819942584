import styled from 'styled-components'

import getStyle from 'happitu/src/getStyle'
import { font } from 'theme'

const SectionHeadingLabel = styled.h3`
  align-items: center;
  color: ${getStyle('text-default-color')};
  display: flex;
  font-size: 1em;
  font-weight: ${font('weight', 'medium')};
  line-height: 1;
  transition: color 200ms;

  i {
    margin-right: 0.5em;
    opacity: 0.75;
  }
`

export default SectionHeadingLabel
