import React, { useRef, UIEventHandler } from 'react'
import styled from 'styled-components'

import { CREATE_ACTION } from '../List.types'
import { CreateOption } from '../ListItems'
import SelectableListProvider from '../SelectableListProvider'
import { useSearchableList } from '../hooks'

import Scrollable from 'happitu/src/components/Layout/Scrollable'
import {
  SelectProps,
  SelectOptionChild,
  AcceptedSelectValue,
  SelectHandler,
} from 'happitu/src/components/Select'
interface Props<SelectValue> extends Omit<SelectProps<SelectValue>, 'onSelect'> {
  autoFocus?: boolean
  onScroll?: UIEventHandler<HTMLDivElement>
  onSelect?: SelectHandler<SelectValue>
}

function SelectableList<SelectValue extends AcceptedSelectValue>({
  autoFocus,
  children,
  className,
  multiple,
  onSelect,
  onCreate,
  onScroll,
  createPlaceholder = 'Create "%s"',
  searchTerm,
  value,
  search,
}: Props<SelectValue> & { searchTerm?: string }) {
  const listRef = useRef<HTMLDivElement>(null)
  const connectListFilter = useSearchableList(searchTerm, {
    search,
    searchProps: ['label'],
    createOption: (
      <CreateOption
        label={createPlaceholder.replace(/%s/g, searchTerm || '')}
        value={CREATE_ACTION}
      />
    ),
    create: !!onCreate,
  })

  const handleCreate = () => searchTerm && onCreate && onCreate(searchTerm)

  return (
    <Scrollable
      role="list"
      className={className}
      innerRef={listRef}
      onScroll={onScroll}
      tabIndex={0}
    >
      <SelectableListProvider
        ref={listRef}
        autoFocus={autoFocus}
        multiple={multiple}
        onCreate={handleCreate}
        onSelect={onSelect}
        value={value}
      >
        {connectListFilter(children) as SelectOptionChild[]}
      </SelectableListProvider>
    </Scrollable>
  )
}

export default styled(SelectableList)`
  outline: none;
`
