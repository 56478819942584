import cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'

import { UserType } from './userHelpers'

interface OrgOpts {
  limited: boolean
  selfOnly: boolean
  wfIds: ID[]
}
interface Session {
  // [Organization ID]: UserID
  oui: Record<ID, ID>
  orgOpts?: Record<ID, OrgOpts>
}

const StorageKey = 'auth'

export const storeSessionToken = (token: string) =>
  window.localStorage.setItem(StorageKey, token)

export const getAuthToken = (
  location?: 'cookie' | 'localStorage',
): string | null | undefined =>
  location === 'localStorage'
    ? window.localStorage.getItem(StorageKey)
    : cookies.get(StorageKey)

export const hasSessionCookie = (): boolean => !!getAuthToken()

export function getSession(): Session | void {
  const auth = getAuthToken()
  if (auth) {
    return jwtDecode<Session>(auth)
  }
}

export function getUserType() {
  return getCurrentUser().userType
}

export function isAdminUser() {
  return getUserType() === UserType.Admin
}

export function isLimitedAgent() {
  return getUserType() === UserType.AgentLimited
}

export function getCurrentUser() {
  return window.store.getState().user
}

// This is used in parts of the app where redux isn't used. e.g., /login, /register
export function _safelyGetCurrentUser() {
  if (window.store && typeof window.store.getState === 'function') {
    return window.store.getState().user
  }
  return null
}

export function getOrganization() {
  return window.store.getState().organization
}

export function getOrganizationProfile() {
  return window.store.getState().organizationProfile
}

export const grantAccess = (type: UserType, currentUser: CurrentUserRecord) => {
  if (!type) {
    return true
  }

  switch (currentUser.userType) {
    case 'Admin':
      return true
    case 'Manager':
      return type !== 'Admin'
  }

  if (type === 'Editor' && currentUser.permissions.workflowManage) {
    return currentUser.permissions.workflowManage.write.length > 0
  }

  return false
}
