import React, { ComponentProps, MutableRefObject } from 'react'
import { FieldInputProps, FieldMetaState } from 'react-final-form'

import * as Styled from './FieldUtils'

interface Props extends ComponentProps<'textarea'> {
  label?: string
  value?: string
  footnote?: string
  input?: FieldInputProps<string, HTMLTextAreaElement>
  meta?: FieldMetaState<string>
  innerRef?:
    | ((instance: HTMLTextAreaElement | null) => void)
    | MutableRefObject<HTMLTextAreaElement | null>
    | null
}

const Textarea = (props: Props) => {
  const {
    className,
    style,
    meta,
    footnote,
    label,
    input,
    innerRef,
    ...otherProps
  } = props
  const textareaProps = input ? input : { ...otherProps }

  return (
    <Styled.NextFieldWrapper className={className} style={style}>
      <Styled.InputWrapper>
        <Styled.Textarea {...textareaProps} ref={innerRef} />
        <Styled.InsetLabelContent>{label}</Styled.InsetLabelContent>
      </Styled.InputWrapper>

      {meta && meta.touched && meta.error ? (
        <Styled.ErrorFigure>{meta.error}</Styled.ErrorFigure>
      ) : (
        <Styled.FieldFigure>{footnote}</Styled.FieldFigure>
      )}
    </Styled.NextFieldWrapper>
  )
}

export default Textarea
