import { AnimatePresence, motion } from 'framer-motion'
import React, { useState, useCallback } from 'react'
import styled from 'styled-components'

import { SelectWrapper, Label } from './Select.styles'
import { SelectProps, AcceptedSelectValue } from './Select.types'
import useSelect from './hooks/useSelect'

import SearchInput from 'happitu/src/components/Fields/SearchInput'
import Icon from 'happitu/src/components/Icon'
import SelectableList from 'happitu/src/components/Lists/ListContainers/SelectableList'
import Menu from 'happitu/src/components/menus/Menu'

interface Props<SelectValue> extends Omit<SelectProps<SelectValue>, 'onCreate'> {
  search?: boolean
  searchPlaceholder?: string
  disabled?: boolean
  onCreate?: (value: string) => void
}

function Select<SelectValue extends AcceptedSelectValue>({
  className,
  searchPlaceholder = 'Filter options',
  createPlaceholder,
  onCreate,
  ...props
}: Props<SelectValue>) {
  const [searchTerm, setSearchTerm] = useState('')
  const {
    blur,
    closeMenu,
    displayValue,
    invalidValue,
    isOpen,
    onKeyDown,
    openMenu,
    onSelect,
    selectRef,
  } = useSelect(props)

  const handleCreate = useCallback(() => {
    onCreate && onCreate(searchTerm)
    !props.multiple && closeMenu()
  }, [onCreate, searchTerm, props.multiple])

  return (
    <SelectWrapper
      insistent={props.insistent || !!invalidValue}
      aria-pressed={isOpen}
      className={className}
      disabled={props.disabled}
      onClick={openMenu}
      size={props.type === 'button' ? 'small' : props.size}
      type={props.type}
    >
      <input
        id="basic-select-id"
        ref={selectRef}
        role="select"
        readOnly
        value={displayValue}
        type="text"
        disabled={props.disabled}
        onKeyDown={onKeyDown}
        onBlur={blur}
        onFocus={props.onFocus}
        style={{ opacity: 0 }}
      />

      <Label>
        <AnimatePresence>
          {props.insistent && (
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              style={{
                position: 'absolute',
                left: '.5em',
                transformOrigin: 'center',
              }}
            >
              <Icon type="warning" />
            </motion.div>
          )}
        </AnimatePresence>
        {displayValue || invalidValue}
      </Label>

      <Menu
        aria-controls="basic-select-id"
        isVisible={isOpen}
        onClose={closeMenu}
        anchor={{
          position: 'bottom',
          alignment: 'start',
          forced: true,
        }}
      >
        {props.search && (
          <header>
            <SearchInput
              autoFocus
              placeholder={searchPlaceholder}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.currentTarget.value)}
            />
          </header>
        )}

        <SelectableList
          createPlaceholder={createPlaceholder}
          multiple={props.multiple}
          onCreate={onCreate ? handleCreate : undefined}
          onSelect={onSelect}
          search={props.search}
          searchTerm={searchTerm}
          value={props.value}
        >
          {props.children}
        </SelectableList>
      </Menu>
    </SelectWrapper>
  )
}

export default styled(Select)``
