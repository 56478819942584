import { differenceInDays, differenceInSeconds, format, formatDistance } from 'date-fns'
import memoizeOne from 'memoize-one'

import { NullableDateSelectRange } from '../components/date-select'

// Alt: 'M/d/y' - 'Jan 1, 500' formats as '1/1/500' (update validator in workflowElementsReducer if we use this)
export const SHORT_HAND_DATE_FORMAT = 'MM/dd/yyyy' // 'Jan 1, 500' formats as '01/01/0500'
export const SHORT_HAND_DATE_WITH_TIME_FORMAT = 'MMM d, yyyy hh:mm aa'
export const relativeDate = memoizeOne(
  (date?: string | Date, dateFormat = 'MMM d, yyyy', threshold = 6) => {
    if (!date) return null
    const d = new Date(date)
    const today = new Date()
    return differenceInDays(today, d) > threshold
      ? format(d, dateFormat)
      : `${formatDistance(d, today, { addSuffix: true })}`
  },
)

export const getPlace = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor(seconds / 60) % 60

  if (hours >= 10) {
    return 0
  } else if (hours) {
    return 1
  } else if (minutes >= 10) {
    return 3
  } else {
    return 4
  }
}

export const formatDuration = memoizeOne((seconds: number, place = getPlace(seconds)) => {
  const timestamp = new Date((seconds ?? 0) * 1000).toISOString().substr(11, 8) // 00:00:00
  return timestamp.slice(place)
})

export const isDate = (value: any): value is Date | string => {
  try {
    const date = Date.parse(value)
    return !isNaN(date)
  } catch (e) {
    return false
  }
}

export const formatDate = memoizeOne(
  (date?: string | Date, dateFormat = 'eeee MMM d, yyyy') => {
    if (!isDate(date)) return null
    const d = new Date(date)
    return format(d, dateFormat)
  },
)

// eslint-disable-next-line complexity
export const humanizeRange = memoizeOne((value: NullableDateSelectRange) => {
  if (!!value.start && !!value.end) {
    const diff = differenceInDays(value.end, value.start)
    if (diff === 0) {
      return `${format(value.start, 'MMM d, yyyy')}`
    }
    return `${format(value.start, 'MMM d, yyyy')} – ${format(value.end, 'MMM d, yyyy')}`
  } else if (!value.start && !!value.end) {
    return `Up to ${format(value.end, 'MMM d, yyyy')}`
  } else if (!value.end && !!value.start) {
    return `${format(value.start, 'MMM d, yyyy')} onwards`
  }
  return null
})

export const timeDifference = (left: string | Date, right: string) => {
  const dateLeft = new Date(left)
  const dateRight = new Date(right)
  const secs = differenceInSeconds(dateLeft, dateRight)
  return `${(secs / 60).toFixed(2)} min`
}
