import styled from 'styled-components'
import { ifProp } from 'styled-tools'

import getStyle from 'happitu/src/getStyle'
import * as theme from 'happitu/src/themes/default/menu'

export const Wrapper = styled.div`
  background: ${getStyle('menu-background')};
  border-radius: 6px;
  border: 1px solid ${getStyle('menu-border-color')};
  box-shadow: ${theme.boxShadow};
  list-style: none;
  margin: 0;
  max-width: ${ifProp('width', 'auto', theme.maxWidth)};
  min-width: ${ifProp('width', 'auto', theme.minWidth)};
  position: fixed;
  text-align: left;
  user-select: none;
`

export const Mask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
`
