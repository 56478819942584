import { last, sortBy } from 'lodash'

const TAG_COLORS = [
  '#46e6b4',
  '#49f7d2',
  '#29b0cf',
  '#25cefe',
  '#6a4199',
  '#9561ba',
  '#fa656e',
  '#fe6f93',
  '#feb92b',
  '#ffd130',
]

/**
 * Gets a random color for the pre defined set of colors.
 */
const randomColor = () => TAG_COLORS[Math.floor(Math.random() * TAG_COLORS.length)]

/**
 * Gets the next tag color in the set of colors.
 * This looks at the last tag created to figure out what color is next.
 */
export const getNextTagColor = (
  tagCollection: StoreInterface<TagRecord> | TagRecord[],
) => {
  // Establish a random color to use.
  const color = randomColor()
  const tags = Array.isArray(tagCollection)
    ? tagCollection
    : tagCollection.toArray<TagRecord>()

  let lastTag
  // If tags were passed base next color on last tag created.
  if (tags) lastTag = last(sortBy(tags, ['createdAt']))
  // No tags are found, return a random color.
  if (!lastTag) return color
  // Get the index of the last color.
  const index = TAG_COLORS.indexOf(lastTag.color)

  if (index + 1 === TAG_COLORS.length) {
    return TAG_COLORS[0]
  }

  return index > -1 ? TAG_COLORS[index + 1] : color
}

/**
 * Gets a random color from the pre defined set of colors.
 * This function will ensure that the same color passed is not returned.
 */
export const getRandomTagColor = () => {
  return randomColor()
}

/**
 * Returns a new tag record.
 */
export const getNewTagRecord = (name: string, tags: StoreInterface<TagRecord>) => ({
  color: getNextTagColor(tags),
  name,
})
