import { Descendant } from 'slate'
import { RenderElementProps } from 'slate-react'

import Node from './node'

import { SlateRecordRefs } from 'happitu/src/types/models/richTextEditor'
import { ElementType } from 'happitu/src/types/slate-types'

interface Props {
  className?: string
  collections?: SlateRecordRefs
  fileIds?: ID[]
  value?: Descendant[]
  variables?: Record<string, string>
}

const RenderSlateValue = ({ className, collections, fileIds, value }: Props) => {
  if (!value) return null

  return (
    <div className={className}>
      {value.map((node, index) => (
        <Node
          attributes={attributes}
          collections={collections}
          element={element}
          fileIds={fileIds}
          key={index}
          node={node}
          {...{ children: null }}
        />
      ))}
    </div>
  )
}

const attributes: RenderElementProps['attributes'] = {
  'data-slate-node': 'element',
  'ref': null,
}

const element: RenderElementProps['element'] = {
  type: ElementType.Paragraph,
  indentLevel: 0,
  children: [{ text: '' }],
  nodeId: '',
}

export default RenderSlateValue
