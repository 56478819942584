import styled from 'styled-components'

import { SelectableList } from 'happitu/src/components/Lists'
import { Button } from 'happitu/src/components/button'

export const MultiStepMenuListWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  max-height: 50vh;

  ${SelectableList} {
    padding: 1em;
  }

  > ${Button} {
    margin: 1em;
    margin-top: 0;
  }
`
