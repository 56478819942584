import { startCase } from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { SelectWrapper } from './Select.styles'
import DisplayReferenceValue from './components/DisplayReferenceValue'
import useSelect from './hooks/useSelect'

import { RecordSelector, RecordSelectorProps } from 'happitu/src/components/Lists'
import { Menu } from 'happitu/src/components/menus'
import getStyle from 'happitu/src/getStyle'
import { singularize, capitalize } from 'happitu/src/helpers/stringHelper'

const RecordSelect = ({ className, ...props }: RecordSelectorProps) => {
  const placeholder = useMemo(
    () =>
      props.placeholder ||
      `Select a ${capitalize(singularize(startCase(props.collectionName), 1))}`,
    [props.placeholder, props.collectionName],
  )
  const {
    blur,
    closeMenu,
    displayValue,
    isOpen,
    onKeyDown,
    openMenu,
    // onSelect,
    selectRef,
  } = useSelect<ID | ID[]>(props)

  const handleCreate = (value: string) => {
    closeMenu()
    props.onCreate && props.onCreate(value)
  }

  return (
    <SelectWrapper
      aria-pressed={isOpen}
      className={className}
      disabled={props.disabled}
      onClick={openMenu}
    >
      <input
        disabled={props.disabled}
        id="record-select-id"
        name={props.name}
        onBlur={blur}
        onKeyDown={onKeyDown}
        readOnly
        ref={selectRef}
        role="select"
        type="text"
        placeholder={props.placeholder}
        value={displayValue}
      />

      <DisplayReferenceValue
        collectionName={props.collectionName}
        conjunction={props.conjunction}
        placeholder={placeholder}
        value={props.value}
      />

      <Menu
        anchor={props.menuAnchor}
        aria-controls="record-select-id"
        aria-expanded={isOpen}
        isVisible={isOpen}
        onClose={closeMenu}
      >
        <RecordSelector
          autoFocus
          collectionName={props.collectionName}
          filters={props.filters}
          icon={props.icon}
          multiple={props.multiple}
          onCreate={typeof props.onCreate === 'function' ? handleCreate : undefined}
          onSelect={props.onSelect}
          value={props.value}
        />
      </Menu>
    </SelectWrapper>
  )
}

export default styled(RecordSelect)`
  color: ${getStyle('text-default-color')};
`
