import autobind from 'autobind-decorator'
import { last } from 'lodash'
import memoize from 'memoize-one'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {
  FieldFigure,
  Input,
  InputWrapper,
  NextFieldWrapper,
  InsetLabelContent,
} from 'happitu/src/components/Fields/FieldUtils'
import { FlexRow } from 'happitu/src/components/_DEPRECATED/Flex'

class TextField extends Component {
  static propTypes = {
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
    attributes: PropTypes.object.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    forceErrors: PropTypes.bool,
    id: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.string,
    value: PropTypes.any,
  }

  static defaultProps = {
    as: 'input',
    type: 'text',
    value: '',
  }

  constructor(props) {
    super(props)
    this.inputWrapper = React.createRef()
  }

  previousBlurValue = this.props.value

  state = {
    focus: false,
    touched: false,
    value: this.props.value,
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      const { top, height } = this.inputWrapper.current.getBoundingClientRect()
      const windowHeight = window.innerHeight
      if (top + height < windowHeight) {
        this.inputWrapper.current.querySelector('input, textarea').focus()
      }
    }
  }

  UNSAFE_componentWillReceiveProps({ value }) {
    if (value !== this.previousBlurValue) {
      this.setState({ value })
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.validationAttempts !== prevProps.validationAttempts &&
      this.props.autoFocus &&
      this.props.errors.length > 0
    ) {
      if (this.inputWrapper.current) {
        // check if component is in view AND there are no errors, then apply focus
        this.inputWrapper.current.querySelector('input, textarea').focus()
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this._timeout)
  }

  formatValue(type, value) {
    switch (type) {
      case 'number':
        return value.replace(/\D/i, '')
      default:
        return value
    }
  }

  @autobind
  handleChange(e) {
    const value = this.formatValue(this.props.type, e.target.value)
    this.setState({ value })
    this.onChange([value])
  }

  @autobind
  handleBlur() {
    let touched = this.state.value !== '' || this.state.touched
    this.setState({ touched }, this.handleTimeout)
    if (this.state.value !== this.props.value) {
      this.previousBlurValue = this.state.value
      this.props.onBlur([this.state.value], this.props.id)
    }
  }

  @autobind
  handleTimeout() {
    this._timeout = setTimeout(() => this.setState({ focus: false }), 500)
  }

  @autobind
  handleFocus() {
    this.setState({ focus: true })
  }

  onChange(val) {
    this.props.onChange(val, this.props.id)
  }

  getMaxWidth = memoize((type, as) => {
    if (as !== 'input') {
      return '100%'
    }
    switch (type) {
      case 'email':
        return 400
      case 'tel':
        return 280
      case 'number':
        return 180
      default:
        return 320
    }
  })

  render() {
    const { attributes, type } = this.props
    const showError =
      (this.state.touched || this.props.forceErrors) && this.props.errors.length > 0
    return (
      <NextFieldWrapper invalid={!!showError}>
        <FlexRow>
          <InputWrapper
            style={{ maxWidth: this.getMaxWidth(type, this.props.as), width: '100%' }}
            ref={this.inputWrapper}
          >
            <Input
              as={this.props.as}
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              placeholder={attributes.placeholder}
              type={
                this.props.as === 'input'
                  ? type === 'number'
                    ? 'text'
                    : type
                  : undefined
              }
              value={this.state.value}
            />
            <InsetLabelContent required={attributes.required}>
              {attributes.label}
            </InsetLabelContent>
          </InputWrapper>
        </FlexRow>

        {showError && <FieldFigure value={last(this.props.errors)} />}
      </NextFieldWrapper>
    )
  }
}

export default TextField
