import { format, addDays, subDays, isValid } from 'date-fns'
import React, { Suspense, useEffect, useState } from 'react'

import 'react-date-range/dist/styles.css'

import { BaldInput, ClearButton } from './DateSelect.styles'
import DateSelectWrapper from './DateSelectWrapper'
import useDateSelect from './useDateSelect'

import ErrorBoundary from 'happitu/src/components/ErrorBoundary'
import { Menu } from 'happitu/src/components/menus'
import { BEGINNING_OF_TIME, TODAY } from 'happitu/src/helpers/dateSelectHelpers'
import { Hotkey } from 'happitu/src/hooks/useHotkey'

const Calendar = React.lazy(() => import('./Calendar'))

interface Props {
  id?: string
  autoFocus?: boolean
  disableClear?: boolean
  label?: string
  maxDate?: Date | null
  minDate?: Date | null
  months?: number
  onSelect: (date: Date | string | null) => void
  placeholder?: string
  required?: boolean
  style?: React.CSSProperties
  value: Date | string | null
}

const processDateAlias = (date: string) => {
  return /today|now/i.test(date) ? new Date() : new Date(date)
}

export const formatDateSafely = (
  value: string | Date | null,
  dateFormat = 'MMM d, yyyy',
): string => {
  return value
    ? isValid(new Date(value))
      ? format(new Date(value), dateFormat)
      : `${value}`
    : ''
}

const DateSelect = ({
  maxDate = TODAY,
  minDate = BEGINNING_OF_TIME,
  months = 2,
  style,
  ...props
}: Props) => {
  const valueDate = !!props.value ? new Date(props.value) : null
  const { ref, onOpen, onClose, isVisible } = useDateSelect()
  const [value, setValue] = useState(() => formatDateSafely(valueDate))

  useEffect(() => {
    setValue(formatDateSafely(props.value))
  }, [props.value])

  const parseValue = (value: string) => {
    const dateMatch = value.match(
      /(\d{1,4}[-/.]){1,}\d{2,4}|(\w|\d)+\s(\w|\d)+,?\s?(\w|\d)+|today|now/i,
    )
    if (dateMatch) {
      const dateStr = dateMatch[0]
      const valueParts = value.replace(new RegExp(dateStr), '')
      const operatorTerms = valueParts.match(/([-+]\s?\d+)\d?/gi)
      const newDate = operatorTerms
        ? operatorTerms.reduce((date, operatorTerm) => {
            const matches = operatorTerm.match(/([-+])\s?(\d+)d?/i)
            if (!matches) return date
            const [_, operator, term] = matches
            switch (operator) {
              case '+':
                return addDays(date, parseInt(term))
              case '-':
                return subDays(date, parseInt(term))
              default:
                return date
            }
          }, processDateAlias(dateStr))
        : processDateAlias(dateStr)

      props.onSelect(newDate)
    } else if (value === '') {
      props.onSelect(null)
    } else {
      props.onSelect(value)
    }
  }

  const handleSelect = (date: Date) => {
    props.onSelect(date)
  }

  const handleClear = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    props.onSelect(null)
  }

  return (
    <DateSelectWrapper
      {...{ ref, onOpen, onClose, style }}
      label={props.label}
      required={props.required}
      hasValue={!!value || !!props.placeholder}
      isPlaceholder={!value}
    >
      <span style={{ marginRight: 'auto', display: 'flex', flexShrink: 1, flexGrow: 1 }}>
        <BaldInput
          id={props.id}
          autoFocus={props.autoFocus}
          type="text"
          value={value}
          onChange={(e) => {
            setValue(e.currentTarget.value)
          }}
          onKeyPress={(e) => {
            if (e.key === Hotkey.Enter) parseValue(value)
          }}
          onBlur={() => parseValue(value)}
          placeholder={props.placeholder}
        />
      </span>
      {!!value && !props.disableClear && (
        <ClearButton
          onClick={handleClear}
          size="small"
          title="Clear the selected date"
          type="close"
        />
      )}
      <Menu isVisible={isVisible} onClose={onClose}>
        <ErrorBoundary>
          <Suspense fallback={<div />}>
            <Calendar
              date={isValid(valueDate) ? valueDate : null}
              direction="horizontal"
              maxDate={maxDate || undefined}
              minDate={minDate || undefined}
              months={months}
              onChange={handleSelect}
            />
          </Suspense>
        </ErrorBoundary>
      </Menu>
    </DateSelectWrapper>
  )
}

export default DateSelect
