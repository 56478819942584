import update from 'immutability-helper'

import createReducer from './createReducer'
import Store from './createStore'

import * as types from 'happitu/src/constants/ActionTypes'

const initialState = new Store<TeamRecord>()

const addWorkflow = (state: Store<TeamRecord>, payload: any) => {
  if (payload.teams) {
    payload.teams.forEach((id: ID) => {
      const team = state.findById<TeamRecord>(id)
      if (team) {
        state = state.update(id, {
          workflows: [...team.workflows, payload.workflowId],
        })
      }
    })
  }
  return state
}

const removeWorkflow = (state: Store<TeamRecord>, payload: { workflowId: ID }) => {
  let nextState = state
  state.forEach((team: TeamRecord) => {
    if (!payload.workflowId) return
    const index = team.workflows.indexOf(payload.workflowId)
    if (index > -1) {
      nextState = nextState.update(team.id, {
        workflows: update(team.workflows, { $splice: [[index, 1]] }),
      })
    }
  })

  return nextState
}

const addTicketToTeam = (
  state: Store<TeamRecord>,
  { ticketId, teamId }: { ticketId: ID, teamId: ID },
) => {
  const team = state.findById<TeamRecord>(teamId)
  if (team && ticketId) {
    const index = team.tickets.indexOf(ticketId)

    if (index === -1) {
      return state.update(teamId, {
        tickets: [...team.tickets, ticketId],
      })
    }
  }
  return state
}

export default createReducer(initialState, {
  [types.RELAY_DISPATCH]: (state, { payload }) => state.setMany(payload.teams),

  [types.UPDATE_TEAM_FAILED]: (state, { payload }) =>
    state.updateWithErrors(payload.id, payload.errors as object[]),

  // Handle workflow association
  [types.CREATE_WORKFLOW]: (state, { payload }) => addWorkflow(state, payload),
  [types.DELETE_WORKFLOW]: (state, { payload }) => removeWorkflow(state, payload),
  [types.ADD_TICKET_TO_TEAM]: (state, { payload }) => addTicketToTeam(state, payload),

  // Websocket
  [types.WS_CREATE_TEAM]: (state, { payload }) => state.set(payload.id, payload),
  [types.WS_DELETE_TEAM]: (state, { payload }) => state.remove(payload.id),
  [types.WS_UPDATE_TEAM]: (state, { payload }) => state.update(payload.id, payload),
})
