import { get } from 'lodash'

import { RelayAction } from 'happitu/src/actions/relay'
import * as types from 'happitu/src/constants/ActionTypes'

type PartialRecord<T extends StoreRecord> = {
  [P in keyof T]?: T[P]
}

export const initialState: PartialRecord<CurrentUserRecord> = {}

const defaultSettings = {
  lastWorkflowId: null,
}

interface CurrentUserPayload {
  users: UserRecord[]
  currentUser: UserRecord
}

/**
 * Stores the active user to be referenced by the application.
 */
function initializeUser(
  state: PartialRecord<CurrentUserRecord>,
  currentUser: UserRecord,
) {
  const { id, settings, ...profile } = currentUser
  return {
    ...state,
    ...profile,
    id: id || state.id,
    // NOTE: these are old keys that are still used... please don't use them.
    profile: {
      ...state.profile,
      settings: {
        ...defaultSettings,
        ...get(state, 'profile.settings', {}),
        ...settings,
      },
      ...profile,
      online: true,
    },
  }
}

function updateProfile(
  state: PartialRecord<CurrentUserRecord>,
  payload: Partial<CurrentUserPayload>,
) {
  if (payload.currentUser) {
    return initializeUser(state, payload.currentUser)
  } else if (payload.users) {
    const index = payload.users.findIndex((u) => u.id === state.id)
    if (index > -1) return initializeUser(state, payload.users[index])
  }
  return state
}

export default function user(
  state = initialState,
  { type, payload }: RelayAction<Partial<CurrentUserPayload>>,
) {
  switch (type) {
    default:
      return state
    case types.RELAY_DISPATCH:
      return updateProfile(state, payload)
  }
}
