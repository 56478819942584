export enum HelpTopicType {
  Document = 'document',
  Link = 'link',
}

export const HELP_TOPIC_TYPES = [
  {
    name: 'Document',
    value: HelpTopicType.Document,
    description:
      'Create a single-page or guided help topic that is fully searchable within your workflow.',
  },
  {
    name: 'External Reference',
    value: HelpTopicType.Link,
    description:
      'Include a resource that is managed in another system and make it easy to access in your workflows.',
  },
]
