import React from 'react'

import TextField from './TextField'

const NumberField = (props) => <TextField {...props} />

NumberField.defaultProps = {
  type: 'number',
}

export default NumberField
