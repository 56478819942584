import styled, { css } from 'styled-components'

import { color } from 'theme'

interface Props {
  display: boolean
  show: boolean
}

export default styled.figure<Props>`
  border: 0;
  border-top: 1px solid
    ${(props) =>
      props.show || props.display ? color('grey', 'lightest') : 'transparent'};
  color: ${color('grey', 'light')};
  display: flex;
  margin: 0;
  margin-top: 0.5em;
  max-height: ${(props) => (props.show || props.display ? '200px' : '0')};
  overflow: hidden;
  transition: max-height 200ms;
  width: 100%;

  > label {
    flex-grow: 1;
  }

  ${(props) =>
    props.display &&
    css`
      padding: 17px 20px;
    `}

  textarea {
    display: block;
    flex-grow: 1;
    margin: 2px;
    padding: 17px 16px;

    &:not(:focus) {
      box-shadow: none;
      border-color: transparent;
      color: ${color('grey')};
    }
  }
`
