import PropTypes from 'prop-types'
import React  from 'react'

import ErrorPage from './ErrorPage'

const InternalError = ({ message, stackTrace, title }) =>
  <ErrorPage code="500"
             title={ title || 'Internal Server Error' }
             { ...message }
             { ...stackTrace }
  />

InternalError.propTypes = {
  message: PropTypes.string,
  stackTrace: PropTypes.array,
  title: PropTypes.string,
}
export default InternalError
