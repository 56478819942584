import { createContext } from 'react'

import { VOID_FUNC } from 'happitu/src/helpers/codeHelpers'

export enum HelpTopicScreenType {
  Home = 'home',
  HelpTopic = 'helpTopic',
}

export interface Screen {
  screen: HelpTopicScreenType
  context: Partial<{
    helpTopicImpressionId: ID
    helpTopicIds: ID[]
    helpTopicPageId: ID
  }>
}

export interface TicketingHelpTopicsContextProps {
  advanceScreen: (helpTopicImpressionId: ID, helpTopicPageId: ID) => void
  screenHistory: readonly Screen[]
  setScreenHistory: (screenHistory: readonly Screen[]) => void
  setStep: (step: number) => void
  step: number
}

// TODO: See about not using a dummy default value here. The default context should be guaranteed from the navigation hook.
export const defaultContext: TicketingHelpTopicsContextProps = {
  advanceScreen: VOID_FUNC,
  screenHistory: [],
  setScreenHistory: VOID_FUNC,
  setStep: VOID_FUNC,
  step: 0,
}

const TicketingHelpTopicsContext = createContext<TicketingHelpTopicsContextProps>(
  defaultContext,
)

export default TicketingHelpTopicsContext
