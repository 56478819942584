import React, { ComponentProps, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import SectionHeadingLabel from './SectionHeadingLabel'

import { IconButton } from 'happitu/src/components/Buttons'
import Icon from 'happitu/src/components/Icon'

interface Props extends Omit<ComponentProps<'header'>, 'title'> {
  title: ReactNode
  titleIcon?: string
  isVisible?: boolean
}

const SectionHeading = ({ title, titleIcon, isVisible, onClick, ...props }: Props) => {
  return (
    <header {...props}>
      <SectionHeadingLabel>
        <span onClick={onClick}>
          {titleIcon && <Icon type={titleIcon} />} {title}
        </span>
      </SectionHeadingLabel>
      {typeof onClick === 'function' && (
        <IconButton
          onClick={onClick}
          icon={isVisible ? 'caret-down' : 'caret-left'}
          ghost
        />
      )}
    </header>
  )
}

export default styled(SectionHeading)`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 0.5em 0;
  user-select: none;

  ${ifProp(
    'onClick',
    css`
      ${SectionHeadingLabel} > *,
      ${IconButton} {
        cursor: pointer;
      }
    `,
  )}

  ${IconButton} {
    font-size: 0.95em;
  }
`
