import createReducer from './createReducer'
import Store from './createStore'

import { RelayAction } from 'happitu/src/actions/relay'
import * as types from 'happitu/src/constants/ActionTypes'

const initialState = new Store()

const setTag = (state: Store<TagRecord>, { payload }: RelayAction<TagRecord>) =>
  state.set(payload.id, payload)

const createTag = (
  state: Store<TagRecord>,
  { payload }: RelayAction<{ tag: TagRecord }>,
) => {
  if (!payload.tag) return state
  return state.set(payload.tag.id, payload.tag)
}

const updateTag = (state: Store<TagRecord>, { payload }: RelayAction<TagRecord>) =>
  state.set(payload.id, payload)

export default createReducer(initialState, {
  [types.RELAY_DISPATCH]: (state, { payload }) => state.setMany(payload.tags),

  [types.GET_TAGS]: (state, { payload }) => state.setMany(payload.tags),

  [types.WS_CREATE_TAG]: setTag,
  [types.FETCH_TAG]: setTag,
  [types.SET_TAG]: setTag,

  [types.CREATE_TAG]: createTag,

  [types.UPDATE_TAG]: updateTag,
  [types.WS_UPDATE_TAG]: updateTag,
  [types.WS_DELETE_TAG]: updateTag,

  [types.DELETE_TAG]: (state, { payload }) => state.remove(payload.id),
})
