import { HelpTopicPageStore, HelpTopicStore } from './models/helpTopics'

import { APIError } from 'happitu/src/createErrorHandler'
import { SuggestedHelpTopics } from 'happitu/src/helpers/ticket/buildSuggestedHelpTopics'
import { LocationStore } from 'happitu/src/models/location'

interface TicketReduxState {
  currentUser?: CurrentUserRecord
  route: Router5Route
}

export type TicketValueHistory = Record<string, Record<string, string[]>>

export interface TicketContextState {
  addresses: AddressStore
  contactDetailTypes: ContactDetailTypeStore
  contacts: ContactStore
  currentContact: Partial<ContactRecord>
  emails: StoreInterface<InteractionEmail>
  errors: APIError<{ ticketInteractions: TicketInteractionRecord[] }>[]
  fileUploads: FileUploadStore
  finalizeInfo: FinalizeInfo
  helpTopicPages: HelpTopicPageStore
  helpTopics: HelpTopicStore
  interfaceOptions: InterfaceOptions
  locations: LocationStore
  tags: TagStore
  ticketInteractions: TicketInteractionStore
  tickets: TicketStore
  users: UserStore
  valueHistory: Record<string, Record<string, string[]>>
  workflowActions: WorkflowActionStore
  workflowElements: WorkflowElementStore
  workflowStepGroupSteps: WorkflowStepStore
  workflowStepGroups: WorkflowStepGroupStore
  workflows: WorkflowStore
}

export interface OptionalTicketContextState {
  'addresses?': AddressStore
  'contactDetailTypes?'?: ContactDetailTypeStore
  'contacts?'?: ContactStore
  'currentContact?'?: Partial<ContactRecord>
  'currentStep?'?: WorkflowStepRecord
  'emails?'?: StoreInterface<InteractionEmail>
  'fileUploads?'?: FileUploadStore
  'finalizeInfo?'?: FinalizeInfo
  'helpTopicPages?'?: WorkflowStepRecord
  'locations?': LocationStore
  'tags?'?: TagStore
  'ticketInteractions?'?: TicketInteractionStore
  'tickets?'?: TicketStore
  'users?'?: UserStore
  'workflowActions?': WorkflowActionStore
  'workflowElements?'?: WorkflowElementStore
  'workflowStepGroupSteps?'?: WorkflowStepStore
  'workflows?'?: WorkflowStore
  'helpTopics?'?: HelpTopicStore
  'variables?'?: Record<string, string>
}

export interface TicketPostProcessStores {
  interfaceOptions: InterfaceOptions
  suggestedHelpTopics: SuggestedHelpTopics
  variables: Record<string, string>
}

export interface InterfaceOptions {
  isUploadingFile?: boolean
}

export enum TicketContextActionTypes {
  UpdateInterfaceOptions = 'UPDATE_INTERFACE_OPTIONS',
}

interface Aliases {
  currentStep: WorkflowStepRecord
  currentStepValues: InteractionStep
}

export type TicketState = TicketReduxState &
  TicketContextState &
  TicketPostProcessStores &
  Aliases
