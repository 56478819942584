import { useState } from 'react'

import {
  BaseSuggestion,
  SuggesterResults,
  SuggestResponse,
  DEFAULT_PAGINATION_OBJECT,
} from '../searchSuggester.types'

import { GeneratedCollection } from 'happitu/src/helpers/generatedMetadata'

const useSuggesterResponse = <SuggestionRecord extends BaseSuggestion>(
  collection: GeneratedCollection,
) => {
  const [results, setResults] = useState<SuggesterResults<SuggestionRecord>>({
    suggestions: [],
    pagination: DEFAULT_PAGINATION_OBJECT,
  })

  /**
   * Update suggestion results.
   * @param response Response from /suggest
   * @param resetList Settable flag that will wipe existing suggestions in state. Pagination is a
   * case where we want append to the existing list of suggestion.
   */
  const dispatch = (response: SuggestResponse<SuggestionRecord>, resetList = true) => {
    const nextSuggestions = (response[collection] ||
      response.suggestions ||
      []) as SuggestionRecord[]
    setResults({
      suggestions: !resetList
        ? [...results.suggestions, ...nextSuggestions]
        : nextSuggestions,
      pagination: response.pagination,
      highlighting: response.highlighting,
      highConfidenceMatchId: response.highConfidenceMatchId,
    })
  }

  return [results, dispatch] as [typeof results, typeof dispatch]
}

export default useSuggesterResponse
