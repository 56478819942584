import { transparentize } from 'polished'

import colors from './colors'

// TODO: Enforce constraint that key values match those of defaultValue.
export default {
  'app-background': colors.grey[900],
  'app-background-translucent': transparentize(0.05, colors.grey[900]),
  'app-alt-background': colors.grey[950],
  'app-alt-background-translucent': transparentize(0.05, colors.grey[950]),

  'modalMask-background': transparentize(0.2, colors.grey[900]),
  'modal-background': colors.grey[900],

  'border-color': colors.grey[700],
  'dark-border-color': colors.grey[600],

  'text-default-color': colors.grey[200],
  'text-sub-color': colors.grey[500],
  'text-light-color': colors.grey[600],
  'text-hover-color': colors.indigo[500],
  'text-active-color': colors.indigo[400],
  'text-inactive-color': colors.grey[700],
  'text-error-color': colors.red[700],
  'text-warning-color': colors.red[600],
  'text-success-color': colors.green[600],
  'text-modified-color': colors.yellow[500],

  'textSelection-background': colors.indigo[600],
  'textSelection-color': colors.grey[200],

  'mainNav-background': colors.indigo[600],

  'mainNavButton-default-color': colors.purple[100],

  'button-base-active-background': colors.grey[700],
  'button-base-active-color': 'white',
  'button-base-default-background': colors.grey[800],
  'button-base-default-color': colors.grey[200],
  'button-base-hover-background': colors.grey[700],
  'button-base-hover-color': 'white',
  'button-base-inactive-background': colors.grey[700],
  'button-base-inactive-color': colors.grey[500],
  'button-base-borderColor': 'transparent',
  'button-base-highlight-background': colors.grey[800],
  'button-base-highlight-color': colors.indigo[400],

  'button-primary-inactive-background': colors.grey[700],
  'button-primary-inactive-color': colors.grey[500],

  'button-warn-active-background': colors.red[700],
  'button-warn-active-color': colors.grey[100],
  'button-warn-default-background': colors.red[600],
  'button-warn-default-color': 'white',
  'button-warn-hover-background': colors.red[700],
  'button-warn-hover-color': 'white',
  'button-warn-inactive-background': colors.red[300],
  'button-warn-inactive-color': colors.grey[100],
  'button-warn-borderColor': 'transparent',

  'button-dark-active-background': colors.grey[900],
  'button-dark-active-color': colors.grey[200],
  'button-dark-default-background': colors.grey[950],
  'button-dark-default-color': colors.grey[200],
  'button-dark-hover-background': colors.grey[900],
  'button-dark-hover-color': colors.grey[200],
  'button-dark-inactive-background': colors.grey[600],
  'button-dark-inactive-color': colors.grey[500],
  'button-dark-borderColor': 'transparent',

  'input-default-background': colors.grey[900],
  'input-default-borderColor': colors.grey[500],
  'input-default-placeholderColor': colors.grey[400],
  'input-disabled-background': colors.grey[700],
  'input-disabled-color': colors.grey[500],
  'input-focus-borderColor': colors.indigo[500],
  'input-focus-outline': colors.indigo[400],

  'menu-background': colors.grey[900],
  'menu-border-color': colors.grey[600],

  'toggle-active-background': colors.mint[600],
  'toggle-default-background': colors.grey[800],
  'toggle-handle-background': colors.grey[300],

  'pill-error-background': colors.red[500],
  'pill-error-color': 'white',
  'pill-default-background': colors.grey[800],
  'pill-default-color': colors.grey[400],
  'pill-success-background': colors.mint[600],
  'pill-success-color': 'white',
  'pill-warning-background': colors.yellow[400],
  'pill-warning-color': colors.yellow[800],
  'pill-inactive-background': colors.grey[400],
  'pill-inactive-color': 'white',

  'searchInput-default-placeholderColor': colors.grey[400],

  'searchInput-default-color': 'white',
  'searchInput-default-background': colors.grey[800],
  'searchInput-default-borderColor': colors.grey[700],
  'searchInput-active-color': colors.grey[200],
  'searchInput-active-background': colors.grey[900],
  'searchInput-active-borderColor': colors.grey[600],

  // Select
  'select-default-background': colors.grey[800],
  'select-default-borderColor': colors.grey[800],
  'select-default-placeholderColor': colors.grey[400],
  'select-focus-background': colors.grey[800],
  'select-disabled-background': colors.grey[700],

  // Select Option
  'selectOption-disabled-background': colors.grey[200],
  'selectOption-disabled-color': colors.grey[600],
  'selectOption-focused-background': 'rgba(255, 255, 255, .1)',
  'selectOption-selected-background': colors.indigo[500],
  'selectOption-selected-color': colors.grey[100],

  'selectOption-highlight-color': colors.yellow[800],
  'selectOption-highlight-background': colors.yellow[100],

  'selectOptionCheckbox-disabled-borderColor': colors.grey[400],
  'selectOptionCheckbox-focused-borderColor': colors.grey[400],
  'selectOptionCheckbox-selected-borderColor': colors.grey[400],

  'notice-info-color': 'white',
  'notice-info-background': colors.blue[900],

  'notice-error-background': colors.yellow[900],
  'notice-error-iconColor': colors.yellow[800],

  // Badges
  'badge-default-background': colors.blue[800],
  'badge-default-borderColor': colors.blue[800],

  'badge-warn-background': colors.red[900],
  'badge-warn-borderColor': colors.red[500],

  'agent-notes-default-color': colors.magenta[500],

  // Scrollbar
  'scrollbar-background-track': 'rgba(255, 255, 255, 0.05)',
  'scrollbar-background-thumb': 'rgba(255, 255, 255, 0.2)',
  'scrollbar-background-thumb-hover': 'rgba(255, 255, 255, 0.3)',
}
