import { OptionValue } from './ListItems/Option.types'

import { AcceptedSelectValue } from 'happitu/src/components/Select'

export const isSelectedOption = (
  value: AcceptedSelectValue | null | undefined,
  optionValue: OptionValue,
) => {
  return (
    value !== null &&
    typeof value !== 'undefined' &&
    (Array.isArray(value) ? value.includes(optionValue) : value === optionValue)
  )
}
