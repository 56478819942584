// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/$$virtual/css-loader-virtual-ef416ba337/0/cache/css-loader-npm-5.2.4-285d2e57c2-3698399558.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cFQnoU1QZi9ccAs0aTX1r,button.cFQnoU1QZi9ccAs0aTX1r{background:#fff;border-radius:20px;border:1px solid #e9e7ea;box-shadow:0 2px 8px -1px rgba(0,0,0,0.1),0 2px 2px rgba(0,0,0,0.1);color:#a6a1ab;display:inline-block;flex-shrink:0;height:34px;margin:5px;min-width:34px;padding:5px 0;position:relative}.cFQnoU1QZi9ccAs0aTX1r:hover,button.cFQnoU1QZi9ccAs0aTX1r:hover{background:#f5f5f6}.cFQnoU1QZi9ccAs0aTX1r>i,button.cFQnoU1QZi9ccAs0aTX1r>i{left:50%;position:absolute;top:50%;transform:translate(-50%, -55%)}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "cFQnoU1QZi9ccAs0aTX1r"
};
export default ___CSS_LOADER_EXPORT___;
