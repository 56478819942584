import { darken } from 'polished'

import { darkGrey, grey, lighterGrey, secondary, white } from './colors'
import { weight } from './font'
import { gutter } from './grid'
import { menu } from './zIndexes'

// Menu
export const backgroundColor =  white
export const border =           `1px solid ${ lighterGrey }`
export const borderRadius =     '3px'

export const boxShadow =
  '0 3px 10px -2px rgba(0, 0, 0, .05), 0 1px 1px 1px rgba(0, 0, 0, .05), 0 2px 3px -1px rgba(0, 0, 0, .075)'

export const width =            '100%'
export const minWidth =         '210px'
export const maxWidth =         '600px'

export const arrowSize =        '20px'

export const zIndex =           menu

// Menu Item
export const paddingX =         gutter

export const color =            darkGrey

export const hoverBackground =  grey.offWhite

export const activeColor =      white
export const activeFontWeight = weight.medium
export const activeBackground = secondary.base
export const activeHoverBackground = darken(.05, secondary.base)
