import * as types from 'happitu/src/constants/ActionTypes'

const initialState = {
  name: '',
  active: true,
}

const getOrganization = (state, payload) => {
  return payload ? payload : state
}

export default function organizationReducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.RELAY_DISPATCH:
      return payload.organizations ? payload.organizations[0] : state
    case types.GET_ORGANIZATION:
      return getOrganization(state, payload)
    case types.WS_UPDATE_ORGANIZATION:
      return payload
    default:
      return state
  }
}
