import relay, { RelayDispatch } from './relay'

import { fetchWorkflowRequest } from 'happitu/src/services/happitu-api/workflowsService'

export function getWorkflowStepGroups(workflowId: ID, versionKey: number) {
  return async (dispatch: RelayDispatch<typeof fetchWorkflowRequest>) => {
    const response = await fetchWorkflowRequest(workflowId, versionKey)
    relay(dispatch)(response)
    return response
  }
}
