import { startCase } from 'lodash'
import React from 'react'

// import Tag from 'happitu/src/components/Tag'
import { GeneratedCollection } from 'happitu/src/helpers/generatedMetadata'
import { error } from 'happitu/src/helpers/loggerHelper'
import { arrayToList } from 'happitu/src/helpers/stringHelper'
import { isRecordWithName, isUserOrContactRecord } from 'happitu/src/helpers/userHelpers'
import {
  isImpressionable,
  useSuggesterReferenceStore,
} from 'happitu/src/models/searchSuggester'

interface Props {
  collectionName: GeneratedCollection
  conjunction?: string
  placeholder?: string
  value?: ID | ID[] | null
}

// const isTagRecord = (record?: StoreRecord | null): record is TagRecord =>
//   !!record && 'color' in record

const getDisplayName = (
  record: StoreRecord,
  collectionName: GeneratedCollection,
): string => {
  const unknownLabel = startCase(collectionName)
  if (isUserOrContactRecord(record)) return `${record?.firstName} ${record?.lastName}`
  // if (isTagRecord(record)) return <Tag tag={record} />
  if (isRecordWithName(record)) return record?.name ?? unknownLabel
  return unknownLabel
}

const DisplayReferenceValue = ({ conjunction = 'and', ...props }: Props) => {
  if (!props.value || (Array.isArray(props.value) && !props.value.length))
    return <div>{props.placeholder}</div>

  const { findById, findByImpression } = useSuggesterReferenceStore()
  const values = Array.isArray(props.value) ? props.value : [props.value]
  const label = arrayToList(
    values.map((value) => {
      const record = isImpressionable(props.collectionName)
        ? findByImpression(props.collectionName, value)
        : findById(props.collectionName, value)
      if (!record) {
        error(`Missing ${startCase(props.collectionName)}: ${value}`)
        return 'NOT FOUND'
      }
      return getDisplayName(record, props.collectionName)
    }),
    conjunction,
  )

  return <div title={label}>{label}</div>
}

export default DisplayReferenceValue
