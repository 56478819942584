import autobind from 'autobind-decorator'
import classNames from 'classnames'
import { isArray } from 'lodash'
import memoize from 'memoize-one'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import scrollIntoView from 'scroll-into-view-if-needed'
import shallowEqual from 'shallowequal'
import styled from 'styled-components'

import FuzzySearchInput from './FuzzySearchInput'
import styles from './FuzzySelectMenu.scss'
import FuzzySelectOption from './FuzzySelectOption'
import NoMatches from './NoMatches'

import Menu, {
  MenuList,
  MenuListItemAction,
  MenuListSection,
} from 'happitu/src/components/_DEPRECATED/_DEPRECATED_Menu'
import { FuzzySelectOptions } from 'happitu/src/constants/shapes'
import { isSelected } from 'happitu/src/helpers/fuzzySelectHelpers'
import { sanitizeStringForRegex } from 'happitu/src/helpers/stringHelper'

const FuzzyMenu = styled(Menu)`
  display: flex;
  flex-direction: column;
  min-width: 260px;
`

const NEW_OPTION = 'NEW_OPTION'
const UP = 1
const DOWN = 0

const filterOptionsRecursively = (term, options, callback) => {
  const nextOptions = [...options]
  return nextOptions
    .map((option) => {
      if (isArray(option.options)) {
        return {
          ...option,
          options: filterOptionsRecursively(term, option.options, callback),
        }
      }
      return option
    })
    .filter((option) => {
      return (
        (!isArray(option.options) && term.test(option.name)) ||
        (isArray(option.options) && option.options.length) ||
        (callback && callback(term, option))
      )
    })
}

const firstOption = (options) => {
  let option, result
  for (let o = 0; o < options.length; o++) {
    option = options[o]
    if (isArray(option.options)) {
      result = firstOption(option.options, false)
      if (result) {
        return result
      }
    } else {
      return option.value
    }
  }
}

const lastOption = (options, offset = 1) => {
  let option = options[options.length - offset]
  if (Array.isArray(option.options) && option.options.length === 0) {
    return lastOption(options, offset + 1)
  }
  return option.options ? lastOption(option.options, offset) : option.value
}

const previousOption = (options, current, __next = false) => {
  let option, result
  for (let o = options.length - 1; o >= 0; o--) {
    option = options[o]
    if (isArray(option.options)) {
      result = previousOption(option.options, current, __next)
      __next = result.__next
      if (result.value) {
        return result
      }
    } else {
      // Return the next option
      if (__next) {
        return { __next, value: option.value }
      }
      if (option.value === current) {
        __next = true
      }
    }
  }
  return { __next }
}

const nextOption = (options, current, __next = false) => {
  let option, result
  for (let o = 0; o < options.length; o++) {
    option = options[o]
    if (isArray(option.options)) {
      result = nextOption(option.options, current, __next)
      __next = result.__next
      if (result.value) {
        return result
      }
    } else {
      // Return the next option
      if (__next) {
        return { __next, value: option.value }
      }
      if (option.value === current) {
        __next = true
      }
    }
  }
  return { __next }
}

const findSelectionName = (options, active) => {
  for (const option of options) {
    if (active === option.value) {
      return option.name
    }
    if (option.options) {
      const childName = findSelectionName(option.options, active)
      if (childName) {
        return childName
      }
    }
  }
  return 'Unknown'
}

export default class FuzzySelectMenu extends Component {
  static propTypes = {
    allowDeselect: PropTypes.bool,
    anchorRef: PropTypes.any,
    className: PropTypes.string,
    createLabel: PropTypes.string,
    defaultActive: PropTypes.string,
    isOpen: PropTypes.bool,
    menuWidth: PropTypes.number,
    multiple: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onCreate: PropTypes.func,
    onSearch: PropTypes.func,
    onSelect: PropTypes.func,
    options: FuzzySelectOptions,
    placeholder: PropTypes.string,
    preventedOptions: PropTypes.array,
    searchFilter: PropTypes.func,
    searchOptions: PropTypes.array,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  }

  static defaultProps = {
    options: [],
    preventedOptions: [],
    createLabel: 'create',
    placeholder: 'Find or create...',
  }

  state = {
    active: this.props.defaultActive || firstOption(this.props.options),
    anchor: 'top-left',
    filteredOptions: this.props.options,
    preventedOptions: this.props.preventedOptions,
    query: '',
    searchValue: '',
  }

  static getDerivedStateFromProps(props) {
    if (typeof props.onSearch === 'function') {
      return { filteredOptions: props.options }
    }
    return null
  }

  componentDidMount() {
    if (this.props.isOpen) {
      this._boundEvents = true
      this.bindEvents()
    }
  }

  componentDidUpdate() {
    if (this.props.isOpen && !this._boundEvents) {
      this._boundEvents = true
      this.bindEvents()

      if (!shallowEqual(this.props.options, this.state.filteredOptions)) {
        this.setState({ filteredOptions: this.props.options })
      }
    } else if (!this.props.isOpen && this._boundEvents) {
      this._boundEvents = false
      this.unbindEvents()
      this.revertPointerEvent()
    }
  }

  componentWillUnmount() {
    this.unbindEvents()
    this.revertPointerEvent()
  }

  bindEvents() {
    document.addEventListener('keydown', this.handleKeyDown)
    document.addEventListener('mousemove', this.revertPointerEvent)
  }

  unbindEvents() {
    document.removeEventListener('keydown', this.handleKeyDown)
    document.removeEventListener('mousemove', this.revertPointerEvent)
  }

  _optionRefs = {}

  @autobind
  handleClose() {
    this.props.onClose()
  }

  @autobind
  handleFilter(e) {
    const value = e.target.value.trim()

    if (this.props.onSearch) {
      e.persist()
      if (this._searchTimeout) {
        clearTimeout(this._searchTimeout)
      }
      this._searchTimeout = setTimeout(() => this.props.onSearch(e), 250)

      this.setState({
        query: value,
        searchValue: e.target.value,
      })
      return
    }

    const term = new RegExp(sanitizeStringForRegex(value), 'i')

    let nextOptions = this.props.options
    let preventedOptions = []

    if (value.length > 0) {
      const options =
        this.state.query.length > value.length || this.state.query === ''
          ? this.props.searchOptions || this.props.options
          : this.state.filteredOptions

      nextOptions = filterOptionsRecursively(term, options, this.props.searchFilter)
      preventedOptions = filterOptionsRecursively(
        term,
        this.props.preventedOptions,
        this.props.searchFilter,
      )
    }

    if (nextOptions.findIndex((opt) => opt.value === this.state.active) === -1) {
      this.setState({
        active: this._optionRefs[NEW_OPTION] ? NEW_OPTION : firstOption(nextOptions),
      })
    }
    const lowerValue = value.toLowerCase().trim()
    const exactMatch = [...nextOptions, ...preventedOptions].find((opt) => {
      if (opt.options) {
        return opt.options.find((o) => o.name.toLowerCase() === lowerValue)
      } else {
        return opt.name.toLowerCase() === lowerValue
      }
    })

    this.setState({
      query: value,
      searchValue: e.target.value,
      filteredOptions: nextOptions,
      preventedOptions,
      exactMatch,
    })
  }

  @autobind
  handleHover(optionId) {
    this.setState({ active: optionId })
  }

  @autobind
  handleSelect(optionId, optionName) {
    if (this.props.onSelect)
      this.props.onSelect(optionId === this.props.selected ? null : optionId, optionName)
    if (!this.props.multiple) this.handleClose()
  }

  @autobind
  handleCreate() {
    if (this.props.onCreate) {
      this.props.onCreate(this.state.query)
      this.handleClose()
      // this.setState({ query: '' })
    }
  }

  @autobind
  handleEnter() {
    const { active, exactMatch, query } = this.state
    const { allowDeselect, selected } = this.props
    if (active === NEW_OPTION) {
      if (!exactMatch) {
        this.handleCreate(query)
      }
    } else {
      const selectionName = findSelectionName(this.props.options, active)
      if (active === selected && allowDeselect) {
        this.handleSelect(active, selectionName)
      } else if (active !== selected) {
        this.handleSelect(active, selectionName)
      }
    }
  }

  @autobind
  handleFilterKeyDown(e) {
    e.stopPropagation()
  }

  @autobind
  handleArrowKey(dir, e) {
    const { filteredOptions, active } = this.state
    const clickableOptions = Object.keys(this._optionRefs).length

    document.body.style.pointerEvents = 'none'
    if (clickableOptions === 0 || filteredOptions.length === 0) return

    const direction = dir === UP ? lastOption : firstOption
    const optionDirection = dir === UP ? previousOption : nextOption
    const bookendOption =
      this._optionRefs[NEW_OPTION] && active !== NEW_OPTION
        ? NEW_OPTION
        : direction(filteredOptions)
    const activeOption = optionDirection(filteredOptions, active).value || bookendOption
    e.preventDefault()
    this.setState({ active: activeOption })

    this._options.style.pointerEvent = 'none'

    scrollIntoView(this._optionRefs[activeOption], {
      scrollMode: 'if-needed',
      block: 'nearest',
      inline: 'nearest',
    })
  }

  @autobind
  handleKeyDown(e) {
    switch (e.key) {
      case 'ArrowDown':
        this.handleArrowKey(DOWN, e)
        break
      case 'ArrowUp':
        this.handleArrowKey(UP, e)
        break
      case 'Enter':
        e.preventDefault()
        this.handleEnter()
        break
      case 'Tab':
      case 'Escape':
        e.stopPropagation()
        this.handleClose()
        break
    }
  }

  @autobind
  revertPointerEvent() {
    if (document.body.style.pointerEvents === 'none') {
      document.body.style.pointerEvents = 'auto'
    }
  }

  @autobind
  handleCreateHover() {
    this.handleHover(NEW_OPTION)
  }

  @autobind
  handleCreateClick() {
    this.handleCreate(this.state.query)
  }

  @autobind
  handleOptionsRef(r) {
    this._options = r
  }

  @autobind
  scrollUpIntoView(option) {
    const optionsMetrics = this._options.getBoundingClientRect()
    const optionMetrics = option.getBoundingClientRect()
    if (
      optionMetrics.top > optionsMetrics.bottom ||
      optionMetrics.top < optionsMetrics.top
    ) {
      option.scrollIntoView(false)
    }
  }

  renderOption(option) {
    const hasChildren = Array.isArray(option.options)

    if (hasChildren) {
      return option.options.length > 0 ? (
        <MenuListSection key={option.value} title={option.name}>
          {this.renderOptions(option.options)}
        </MenuListSection>
      ) : null
    }

    return (
      <FuzzySelectOption
        {...option}
        innerRef={this.handleOptionRef(option.value)}
        deselectable={this.props.allowDeselect}
        hovering={this.state.active === option.value}
        key={option.value}
        onClick={this.handleSelect}
        onMouseEnter={this.handleHover}
        selected={isSelected(option.value, this.props.selected)}
      />
    )
  }

  handleNewRef = (ref) => (this._optionRefs[NEW_OPTION] = ref)
  handleOptionRef = memoize((optionId) => (ref) => (this._optionRefs[optionId] = ref))

  renderOptions(options) {
    return options.map((option) => this.renderOption(option))
  }

  renderCreateButton() {
    if (typeof this.props.onCreate === 'function') {
      return (
        <MenuListItemAction
          hover={this.state.active === 'NEW_OPTION'}
          ref={this.handleNewRef}
          key="newOpt"
          onClick={this.handleCreateClick}
          className={classNames(styles.option, styles.createOption, styles.active)}
          onMouseEnter={this.handleCreateHover}
        >
          <div className={styles.indicator}>+</div>
          <div className={styles.createLabel}>
            <h4>{this.props.createLabel}:</h4>
            <h3>{this.state.query}</h3>
          </div>
        </MenuListItemAction>
      )
    }
  }

  renderSelectedOption() {
    return (
      <MenuListItemAction key="selOpt">
        Unavailable Option: {this.state.exactMatch.name}
      </MenuListItemAction>
    )
  }

  renderMenuContents() {
    const { filteredOptions, exactMatch, query } = this.state
    const contents = []

    if (!exactMatch && query !== '') {
      contents.push(this.renderCreateButton())
    }

    if (filteredOptions.length > 0) {
      contents.push(this.renderOptions(filteredOptions))
    } else if (exactMatch) {
      contents.push(this.renderSelectedOption())
    }

    return contents
  }

  render() {
    const { className, isOpen } = this.props

    return (
      <FuzzyMenu
        anchorRef={this.props.anchorRef}
        className={className}
        isOpen={isOpen}
        width={this.props.menuWidth}
        onClose={this.props.onClose}
        horizontalOffset={0}
      >
        <FuzzySearchInput
          type="text"
          autoFocus
          className={styles.search}
          onChange={this.handleFilter}
          onKeyDown={this.handleFilterKeyDown}
          placeholder={this.props.placeholder}
          value={this.state.searchValue}
        />
        {!!this.props.onCreate || !!this.state.filteredOptions.length ? (
          <MenuList className={styles.options} ref={this.handleOptionsRef}>
            {this.renderMenuContents()}
          </MenuList>
        ) : (
          <NoMatches>No matching results</NoMatches>
        )}
      </FuzzyMenu>
    )
  }
}
