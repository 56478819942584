import update from 'immutability-helper'
import { get } from 'lodash'
import { createElement, useContext } from 'react'

import { mergeWorkflow } from './reducers/workflowsReducer'

import { RelayAction } from 'happitu/src/actions/relay'
import { GeneratedCollection } from 'happitu/src/helpers/generatedMetadata'
import { error, logState } from 'happitu/src/helpers/loggerHelper'
import Store from 'happitu/src/reducers/createStore'

export const RELAY_DISPATCH = 'RELAY_DISPATCH'
export const initialState = {}

interface Aliases {
  [alias: string]: (state: any) => any
}

type PostProcessMap<S, PS, A> = {
  [key in keyof PS]: (state: S & PS, action: A) => any
}
type StoreState<S> = { [key in keyof S]: any | StoreInterface<any> }
type Parameters<T> = T extends (...args: infer P) => any ? P : never

export type UnboundRelayAction = (
  ...args: any[]
) => (dispatch: React.Dispatch<any>, getState: Function) => Promise<any> | void
export type BoundActionType<A extends UnboundRelayAction> = (
  ...params: Parameters<A>
) => ReturnType<ReturnType<A>>
export type BoundActionTypes<IActions extends RelayActions> = {
  [A in keyof IActions]: BoundActionType<IActions[A]>
}

export interface BoundRelayActions {
  [key: string]: (...args: any[]) => Promise<any> | void
}

export interface RelayActions {
  [key: string]: UnboundRelayAction
}

interface RelayStoreConfig {
  relations?: Record<string, StoreRelation[]>
}

// Remove the required props that are handled by the context.
//
// Example
// -----------
// Child: ({ tickets: TicketStore, isActive: boolean }) => React.ComponentType<any>
// Parent: () => <Child isActive={ false } />
//                     ^--- Note that the tickets props is not required
type NonContextNames<T, S, A> = {
  [K in keyof T]: K extends keyof S ? never : K extends keyof A ? never : K
}[keyof T]
type NonContextProps<T, S, A> = Pick<T, NonContextNames<T, S, A>>

// Why is there a constructor check? Well, I'm happy you asked...
// this is for immutable class instances like SearchQuery.
function isClass(payload?: any) {
  return !Array.isArray(payload) && payload && payload.constructor
}

function updatePlain(
  payload: object | object[] | null,
  nextState: any,
  resetStore = false,
) {
  if (payload === null) return null
  return Array.isArray(payload) || resetStore || isClass(payload)
    ? payload
    : { ...(nextState || {}), ...payload }
}

const setInstructionPresets: Partial<
  Record<GeneratedCollection, () => Record<string, any>>
> = {
  [GeneratedCollection.Workflows]: mergeWorkflow,
}

function updateStore<GC extends GeneratedCollection>(
  collectionName: GC,
  payload: object[],
  nextState: any,
  resetStore = false,
  config?: Partial<StoreConfig>,
) {
  if (resetStore) return new Store(config).setMany(payload)
  return ((nextState || new Store(config)) as StoreInterface<any>).setMany(
    payload,
    setInstructionPresets[collectionName],
  )
}

function updateState<S>(
  nextState: S,
  key: keyof S,
  payload: any,
  nonRelayKeys: Array<keyof S> = [],
  resetStore?: boolean,
  config?: Partial<StoreConfig>,
) {
  // Allow null values to be set
  // This is required to determine if there are any linting errors during workflow rollout.
  if (payload === undefined) return nextState
  const change =
    key !== 'pagination' && Array.isArray(payload) && !nonRelayKeys.includes(key)
      ? updateStore(
          key as GeneratedCollection,
          payload,
          nextState[key],
          resetStore,
          config,
        )
      : updatePlain(payload, nextState[key], resetStore)
  return update(nextState, { [key]: { $set: change } })
}

function processNextState<S extends StoreState<S>>(
  state: S,
  action: RelayAction<S>,
  config?: RelayStoreConfig,
) {
  let nextState = { ...state }
  const { nonRelayKeys, ...payload } = action.payload
  const { reset } = action.options

  const actionPayload = payload as { [Key in keyof S]: any }
  for (const key in actionPayload) {
    if (Object.prototype.hasOwnProperty.call(actionPayload, key)) {
      const storeConfig =
        config && config.relations && config.relations[key]
          ? { relations: config.relations[key] }
          : {}
      const resetStore = reset ? reset[key] : false
      nextState = updateState(
        nextState,
        key,
        actionPayload[key],
        nonRelayKeys,
        resetStore,
        storeConfig,
      )
    }
  }
  return nextState
}

function isNotPreset(prop: any) {
  return (
    !prop ||
    (Object.keys(prop).length === 0 && !prop.initialized && typeof prop !== 'function')
  )
}

// tslint:disable-next-line:cyclomatic-complexity
function mapKeysToProps<S>(
  state: S,
  keys?: Array<keyof S | string> | null,
  warnMissing = false,
  aliases: Aliases = {},
) {
  const props = {} as { [key: string]: any }

  if (!keys) return props

  for (const rawKey of keys) {
    const keyParts = (rawKey as string).split(/\?$/)
    const isOptional = keyParts.length > 1
    const key = keyParts[0]

    if (aliases[key]) {
      const alias = aliases[key](state)
      if (!isOptional && !alias) return false
      props[key] = aliases[key](state)
      continue
    } else if (!isOptional && isNotPreset(state[key as keyof S])) {
      if (warnMissing) {
        // eslint-disable-next-line no-console
        console.warn(
          `It looks like you're trying to get '${key}'. This doesn't exist in this context. Please consider removing it.`,
        )
      }
      return false
    }

    props[key] = state[key as keyof S]
  }
  return props
}

function debugState(message: string) {
  error(message)
}

// Use this to safely bind to the context.
export function createContextProvider<S, O, A>(
  Context: React.Context<any>,
  options?: { aliases: Aliases },
) {
  return function connect<P>(
    stateKeys: Array<keyof (S & O)> | null,
    handlerKeys?: Array<keyof A>,
  ) {
    return (
      DecoratedComponent: React.ComponentType<NonContextProps<P, S, A>>,
      PlaceholderComponent?: React.ComponentType<any>,
    ) => {
      return (otherProps: NonContextProps<P, S, A>) => {
        const { state, handlers } = useContext(Context)

        const stateProps = mapKeysToProps<S & O>(
          state,
          stateKeys,
          false,
          get(options, 'aliases'),
        )
        const handlerProps = mapKeysToProps<A>(handlers, handlerKeys, true)

        return !stateProps
          ? PlaceholderComponent
            ? createElement(PlaceholderComponent, { debugState })
            : null
          : createElement(DecoratedComponent, {
              ...stateProps,
              ...handlerProps,
              ...otherProps,
            } as P)
      }
    }
  }
}

export default function createReducer<S extends StoreState<S>, PS = {}>(
  postProcesses: PostProcessMap<S, PS, RelayAction<S & PS>> = {} as PostProcessMap<
    S,
    PS,
    RelayAction<S & PS>
  >,
  config?: RelayStoreConfig,
) {
  let scopedState = {}

  function getState() {
    return scopedState
  }

  function useActions(actions: RelayActions) {
    return (dispatch: React.Dispatch<any>) => {
      const boundActions = {} as BoundRelayActions
      Object.keys(actions).forEach((key) => {
        boundActions[key] = (...args: any[]) => actions[key](...args)(dispatch, getState)
      })
      return boundActions
    }
  }

  function processRelayReducers(state: S, action: RelayAction<S>) {
    switch (action.type) {
      case RELAY_DISPATCH:
        return processNextState(state, action, config)
      default:
        return state
    }
  }

  function reducer(state: S, action: RelayAction<S>) {
    const nextState = processRelayReducers(state, action)
    const postState = {} as { [K in keyof PS]: any }
    Object.keys(postProcesses).forEach((k) => {
      const key = k as keyof PS
      postState[key] = postProcesses[key](nextState, action)
    })
    scopedState = {
      ...nextState,
      ...postState,
    }
    logState(scopedState)

    return scopedState
  }

  return {
    reducer,
    useActions,
  }
}
