import { escapeRegExp } from 'lodash'
import { Editor, Range, Transforms, Path, Text, Element, Node, Point } from 'slate'

import { error } from 'happitu/src/helpers/loggerHelper'
import { MARK_TYPES } from 'happitu/src/types/models/richTextEditor'

interface InlineShortcuts {
  [key: string]: MARK_TYPES
}

const INLINE_SHORTCUT_CHARS = ['*', '_', '`', '~']

const INLINE_SHORTCUTS: InlineShortcuts = {
  '**': 'bold',
  '*': 'italic',
  '_': 'underline',
  '`': 'code',
  '~': 'strikethrough',
}

const withInlineMarkdown = (editor: Editor) => {
  const { insertText } = editor

  // eslint-disable-next-line complexity
  editor.insertText = (text) => {
    const { selection } = editor

    // Handle inline markdown (i.e. bolding text).
    if (
      INLINE_SHORTCUT_CHARS.includes(text) &&
      selection &&
      Range.isCollapsed(selection)
    ) {
      // Get the current block. Only parse inline shortcuts within a given block.
      const currentBlock = Editor.above(editor, {
        match: (n) => Editor.isBlock(editor, n),
      })
      if (currentBlock) {
        // We could technically run into a bug where <em>*</em>* isn't parsed because they exist on separate nodes. Maybe use the flattened text for the block.
        // const blockText = Editor.string(editor, currentBlock[1])

        // Get the anchor for the selection.
        const { anchor } = selection

        // Get the previous character
        const prevChar = Editor.string(editor, {
          anchor,
          focus: { ...anchor, offset: anchor.offset - 1 },
        })
        if (prevChar === ' ') return insertText(text)
        // Get the next character
        const nextChar = Editor.string(editor, {
          anchor,
          focus: { ...anchor, offset: anchor.offset + 1 },
        })

        const shortcutKey =
          text === '*' && (prevChar === '*' || nextChar === '*') ? '**' : text

        // Don't apply markdown if there's a space before a double asterisk ' **'.
        if (
          shortcutKey === '**' &&
          Editor.string(editor, {
            anchor,
            focus: { ...anchor, offset: anchor.offset - 2 },
          })[0] === ' '
        )
          return insertText(text)

        const shortcut = INLINE_SHORTCUTS[shortcutKey]

        // Regex to get for matches for the shortcut key.
        const shortcutKeyRegex =
          shortcutKey === '*'
            ? /[^*]\*(?![*\s])(?![\s\S]*\*\*)|^\*(?![*\s])(?![\s\S]*\*\*)/
            : shortcutKey === '**'
            ? /\*\*(?![*\s])/
            : new RegExp(
                escapeRegExp(shortcutKey) + `(?![` + escapeRegExp(shortcutKey) + `\\s])`,
              )

        const fragmentsBeforeSelection = Editor.fragment(editor, {
          anchor: Editor.start(editor, currentBlock[1]),
          focus: anchor,
        })[0]
        if (!Element.isElement(fragmentsBeforeSelection)) {
          error(
            `Inline markdown matched non-element node: ${JSON.stringify(
              fragmentsBeforeSelection,
            )}`,
          )
          return
        }
        // Get the nodes before the selection.
        const nodesBeforeSelection = [...fragmentsBeforeSelection.children]

        // Get the index of the closest match.
        const matchIndex = nodesBeforeSelection
          .reverse()
          .findIndex((n: Node) => Text.isText(n) && shortcutKeyRegex.test(n.text))

        if (matchIndex > -1) {
          // Get the path of that match. We're subtracting the match index from the array length because the array is reversed. We could handle this differently.
          const path = [...currentBlock[1], nodesBeforeSelection.length - matchIndex - 1]
          // Get the node text for that match. If it's the same node as the selection, get the fragment before the selection.
          const prevFragment = Editor.fragment(editor, {
            anchor,
            focus: { path, offset: 0 },
          })[0]
          const textNode = Editor.node(editor, path)[0]
          if (!Text.isText(textNode)) {
            error(`Selected node isn't a text node: ${JSON.stringify(textNode)}`)
          }
          const nodeText: string =
            Path.isCommon(path, anchor.path) && Element.isElement(prevFragment)
              ? Text.isText(prevFragment.children[0])
                ? prevFragment.children[0].text
                : ''
              : Text.isText(textNode)
              ? textNode.text
              : ''

          // NOTE: The single '*' regex is checking for matches that don't include multiple asterisks and that aren't proceeded late by '**'.
          // Ex: 'When *entering a shortcut in the **middle of this example, the single asterisk*[*] would otherwise be parsed first.'
          // Regex to get the text before the last match in the node.
          const beforeTextRegex =
            shortcutKey === '*'
              ? /[\s\S]*[^*](?=\*(?![*\s])(?![\s\S]*\*\*))|^(?=\*(?![*\s])(?![\s\S]*\*\*))/
              : shortcutKey === '**'
              ? /[\s\S]*(?=\*\*(?![\s*]))/
              : new RegExp(
                  `[\\s\\S]*(?=` +
                    escapeRegExp(shortcutKey) +
                    `(?![\\s` +
                    escapeRegExp(shortcutKey) +
                    `]))`,
                )

          // Get the last match in the node.
          const match = beforeTextRegex.exec(nodeText)
          // Get the length of the string before the shortcut text.
          const openKeyEndOffset = match ? match[0].length + shortcutKey.length : 0

          // Determine the point where the opening shortcut key ends.
          const openKeyEnd = { path, offset: openKeyEndOffset }
          const openKeyStart = { path, offset: openKeyEndOffset - shortcutKey.length }
          // Determine the point where the closing shortcut key starts.
          const closeKeyStartOffset =
            text === '*' && prevChar === '*' ? anchor.offset - 1 : anchor.offset
          const closeKeyStart = { ...anchor, offset: closeKeyStartOffset }
          const closeKeyEnd = {
            ...anchor,
            offset: closeKeyStartOffset + shortcutKey.length,
          }

          // Check if the point is before the user's cursor.
          if (Point.isBefore(openKeyEnd, closeKeyStart)) {
            // Set the selection from the cursor to the match.
            insertText(text)

            // Remove the shortcut keys.
            Transforms.delete(editor, {
              at: { anchor: closeKeyStart, focus: closeKeyEnd },
            })
            Transforms.delete(editor, { at: { anchor: openKeyStart, focus: openKeyEnd } })

            // Handle if the match is in the same node as the anchor.
            const offsetMultiplier = Path.isCommon(path, anchor.path) ? 2 : 1
            // Get the next text node.
            const nextNode = Editor.next(editor, {
              at: openKeyStart.path,
              match: (n) => Text.isText(n),
            })
            // NOTE: If the point is at the end of a node, get the start of the next node. Slate doesn't handle a selection point at the end of a node with addMark.
            const selectionAnchor =
              !!nextNode &&
              Point.equals(openKeyStart, Editor.end(editor, openKeyStart.path))
                ? Editor.start(editor, nextNode[1])
                : openKeyStart

            // Get the new selection range.
            const selectionRange = {
              anchor: selectionAnchor,
              focus: {
                ...anchor,
                offset: anchor.offset - offsetMultiplier * shortcutKey.length + 1,
              },
            }
            Transforms.setSelection(editor, selectionRange)
            // Add the mark.
            Editor.addMark(editor, shortcut, true)
            // Collapse the selection.
            Transforms.collapse(editor, { edge: 'focus' })

            return
          }
        }
      }
    }

    // Fallback on other insertText behavior.
    insertText(text)
  }

  return editor
}

export default withInlineMarkdown
