import React from 'react'

import Transition from 'happitu/src/components/Animations/Transition'
import { SLIDE_DOWN, SLIDE_UP } from 'happitu/src/helpers/animations'

const getStyles = (anchor: string) => (/top/.test(anchor) ? SLIDE_DOWN : SLIDE_UP)

export default function MenuTransition({ anchor, children }: Props) {
  return (
    <Transition isVisible={!!children} transition={getStyles(anchor)}>
      {children}
    </Transition>
  )
}

interface Props {
  anchor: string
  children: React.ReactNode
}
