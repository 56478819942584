import React, { FC } from 'react'

import Notice from 'happitu/src/components/Fields/Notice'
import Box from 'happitu/src/components/box'
import { Button } from 'happitu/src/components/button'
import { error } from 'happitu/src/helpers/loggerHelper'
import request from 'happitu/src/services/happitu-api/request'
import { CallbackButtonDisplayProps } from 'happitu/src/types/workflowElements'

const CallbackButton: FC<CallbackButtonDisplayProps> = (props) => {
  const [loading, setLoading] = React.useState(false)
  const [notice, setNotice] = React.useState<{
    label: string
    type: 'error' | 'success'
  } | null>(null)

  const handleClick = async () => {
    setLoading(true)
    try {
      if (!props.attributes.callbackOptions) {
        throw new Error('missing callback options')
      }
      const hydrateVariables = (str: string) => {
        return str.replace(/:([.\S]+):/i, (_, $1) => {
          return props.variables[$1]
        })
      }
      await request.get('/integration/incontact/schedule-callback').query({
        screenPopSrc: 'Custom',
        ...props.attributes.callbackOptions,
        screenPopUrl: hydrateVariables(props.attributes.callbackOptions.screenPopUrl),
        phoneNumber: hydrateVariables(props.attributes.callbackOptions.phoneNumber),
      })
      setNotice({ label: 'Callback scheduled successfully!', type: 'success' })
    } catch (e) {
      setNotice({
        label: 'There was a problem scheduling your callback. Please try again later.',
        type: 'error',
      })
      error('CallbackButton', e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box paddingY="1">
      {notice && <Notice type={notice.type}>{notice.label}</Notice>}
      <Button
        onClick={handleClick}
        loading={loading}
        disabled={!!notice}
        loadingLabel="Scheduling callback..."
        value={props.attributes.label}
        style={{ flexGrow: 1 }}
      />
    </Box>
  )
}

export default CallbackButton
