import styled, { css } from 'styled-components'
import { ifNotProp } from 'styled-tools'

import Icon from 'happitu/src/components/Icon'
import { Button } from 'happitu/src/components/button'
import getStyle from 'happitu/src/getStyle'
import { font } from 'theme'

// prettier-ignore
export const Wrapper = styled.div<{isFirst: boolean, isLast: boolean}>`
  padding: 0.5em;
  position: relative;

  border: 1px solid ${getStyle('border-color')};

  ${props => {
      const {isFirst, isLast} = props
      if (isFirst && isLast) {
        return css`
          border-radius: 6px;
          margin: 1em 0;
        `
      } else if (isFirst) {
        return css`
          margin-top: 1em;
          border-bottom: none;
          border-radius: 6px 6px 0 0;
        `
      } else if (isLast) {
        return css`
          margin-bottom: 1em;
          border-radius: 0 0 6px 6px;
          border-top: none;
          padding-top: calc(0.5em + 1px);
        `
      } else {
        return css`
          border-top: none;
          border-bottom: none;
          padding-top: calc(0.5em + 1px);
        `
      }
    }}

    ${ifNotProp('isLast'), css`
      &:after {
        background: ${getStyle('border-color')};
        content: '';
        height: 1px;
        left: 50%;
        position: absolute;
        top: 100%;
        transform: translateX(-50%);
        width: calc(100% - 1.5em);
      }
    `}

  /* &:after {
    background: ${getStyle('border-color')};
    content: '';
    height: 1px;
    left: 50%;
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
    width: calc(100% - 1em);
  }

  &:before {
    background: ${getStyle('border-color')};
    bottom: 100%;
    content: '';
    height: 1px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: calc(100% - 1em);
  } */
`

export const StyledButton = styled(Button)`
  color: ${getStyle('text-active-color')};
  justify-content: space-between;
  width: 100%;
  white-space: normal;

  :hover,
  :active {
    color: ${getStyle('text-active-color')};
  }
`

export const StyledIcon = styled(Icon)`
  font-weight: ${font('weight', 'medium')};
  margin-left: 0.5em;
`
