import { motion } from 'framer-motion'
import React, { useRef, useEffect } from 'react'
import useMeasure from 'use-measure'

interface Props {
  children: React.ReactNode
  className?: string
  dimension: 'height' | 'width'
  isVisible: boolean
  // TODO: Scale is currently being omitted to resolve a typing issue
  style: Omit<React.CSSProperties, 'scale'>
}

export default function Autogrow({
  isVisible,
  children,
  className,
  style,
  dimension,
}: Props) {
  const motionRef = useRef<HTMLDivElement>(null)
  const nodeRef = useRef<HTMLDivElement>(null)
  const measure = useMeasure(nodeRef)

  const onAnimationStart = () => {
    if (motionRef.current) motionRef.current.style.overflow = 'hidden'
  }
  const onAnimationComplete = () => {
    if (motionRef.current && isVisible && style?.overflow !== 'hidden')
      motionRef.current.style.overflow = ''
  }

  useEffect(() => {
    if (motionRef.current && !isVisible) motionRef.current.style.overflow = 'hidden'
  }, [])

  return (
    <motion.div
      initial={false}
      transition={{ duration: 0.3, ease: 'easeOut' }}
      animate={{
        [dimension]: isVisible ? (nodeRef.current ? measure[dimension] : 'auto') : 0,
        opacity: isVisible ? 1 : 0,
        pointerEvents: isVisible ? 'auto' : 'none',
      }}
      style={style}
      className={className}
      onAnimationStart={onAnimationStart}
      onAnimationComplete={onAnimationComplete}
      ref={motionRef}
    >
      <div ref={nodeRef}>{children}</div>
    </motion.div>
  )
}

Autogrow.defaultProps = {
  style: {},
  dimension: 'height',
}
