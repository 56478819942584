import { Editor, NodeEntry, Node, Element, Text, Transforms } from 'slate'
import { v4 as uuid } from 'uuid'

import { error } from 'happitu/src/helpers/loggerHelper'
import { ElementType, ParagraphElement } from 'happitu/src/types/slate-types'

// Guarantees that the editor ends with an empty paragraph.
const withTrailingNewLine = (editor: Editor) => {
  const { normalizeNode } = editor

  editor.normalizeNode = (entry: NodeEntry<Node>) => {
    const [node] = entry

    // Normalize the editor itself.
    if (Editor.isEditor(node)) {
      // This will be a text node.
      const lastNodeEntry = Editor.last(editor, [])
      if (!Text.isText(lastNodeEntry[0])) {
        error(`lastNodeEntry isn't a text node: ${JSON.stringify(lastNodeEntry)}`)
        return
      }
      // Get the block node above it.
      const lastBlockEntry = Editor.above(editor, {
        at: lastNodeEntry[1],
        match: (n) => Editor.isBlock(editor, n),
      })
      // If the last block node isn't an empty paragraph, insert one.
      if (
        lastNodeEntry[0].text !== '' ||
        !lastBlockEntry ||
        (Element.isElement(lastBlockEntry[0]) &&
          lastBlockEntry[0].type !== ElementType.Paragraph)
      ) {
        const index = lastBlockEntry ? lastBlockEntry[1][0] : 0
        // const nodes = [getNodeDefault(ElementType.Paragraph)]
        const nodes: ParagraphElement[] = [
          {
            type: ElementType.Paragraph,
            indentLevel: 0,
            children: [{ text: '' }],
            nodeId: uuid(),
          },
        ]
        Transforms.insertNodes(editor, nodes, { at: [index + 1] })
      }
    }

    normalizeNode(entry)
  }

  return editor
}

export default withTrailingNewLine
