import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import getStyle from 'happitu/src/getStyle'
import { anim } from 'theme'

interface Props {
  type: 'checkbox' | 'checked' | 'radio'
}

const styles = {
  checkbox: css`
    border-radius: 3px;

    &:after {
      background: transparent;
      border-bottom: 2px solid white;
      border-right: 2px solid white;
      height: 6px;
      width: 3px;
      ${ifProp('checked', 'transform: rotate(43deg) translate(-100%, -30%) scale(1)')};
    }
  `,
  checked: css`
    background: ${getStyle('radio-active-background')};
    border-color: ${getStyle('radio-active-background')};
  `,
  radio: css`
    border-radius: 8px;

    &:after {
      background: white;
      border-radius: 3px;
      height: 6px;
      width: 6px;

      ${ifProp('checked', 'transform: translate(-50%, -50%) scale(1)')};
    }
  `,
}

const getButtonStyle = (props: Props) => styles[props.type]

export const Button = styled.span<Props>`
  background: ${getStyle('app-background')};
  border: 1px solid ${getStyle('dark-border-color')};
  display: inline-block;
  height: 16px;
  margin-right: 0.75em;
  position: relative;
  transition: all ${anim('speed', 'fast')};
  width: 16px;

  &:after {
    content: '';
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: ${anim('speed', 'fast')};
  }

  ${getButtonStyle};
  ${ifProp('checked', styles.checked)};
`

export const Input = styled.input`
  opacity: 0;
  position: absolute;
  visibility: none;
`

export const Wrapper = styled.label`
  cursor: pointer;
  display: inline-block;
  position: relative;

  &:focus-within {
    ${Button} {
      border: 1px solid ${getStyle('radio-active-background')};
      box-shadow: 0 0 0 1px ${getStyle('radio-active-background')};
    }
  }
`
