import { rgba as prgba } from 'polished'

import { error } from 'happitu/src/helpers/loggerHelper'

export const color = (type: ThemeColors, shade: ThemeShades = 'base') => ({
  theme,
}: ContextThemeProps): string => {
  try {
    return theme.colors[type][shade]
  } catch (e) {
    error('Unknown color', type, shade)
    return 'black'
  }
}

export const rgba = (
  type: ThemeColors,
  shade: ThemeShades | number = 'base',
  alpha = 1,
) => {
  if (typeof shade === 'number') {
    alpha = shade
    shade = 'base'
  }

  return ({ theme }: ContextThemeProps): string => prgba(theme.colors[type][shade], alpha)
}

export const metric = (key: string) => ({ theme }: ContextThemeProps) =>
  theme.metrics[key]

export const font = (prop: string, value: string | Function = 'base') => ({
  theme,
  ...props
}: ContextThemeProps): string => {
  const key = typeof value === 'function' ? value(props) : value
  return theme.font[prop][key]
}

export const zIndex = (index: string) => ({ theme }: ContextThemeProps): string =>
  theme.zIndex[index]

export const anim = (type: string, attr: string) => ({ theme }: ContextThemeProps) => {
  if (process.env.NODE_ENV === 'test') {
    return
  }
  return theme.animation[type][attr]
}

export default function (keyStr: string) {
  const keys = keyStr.split('.')
  return ({ theme }: ContextThemeProps): string => {
    if (process.env.NODE_ENV === 'test') {
      return ''
    }

    keys.forEach((k) => {
      theme = theme[k]
    })

    return theme ? theme.toString() : ''
  }
}
