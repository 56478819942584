import { Editor, Transforms, Element } from 'slate'
import { v4 as uuid } from 'uuid'

import uploadFile from './uploadFile'

import { error } from 'happitu/src/helpers/loggerHelper'
import { AttachmentElement, ElementType } from 'happitu/src/types/slate-types'

export const acceptedFileTypes = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/pdf',
]

const wordPreset = {
  icon: 'file-word',
  name: 'Document',
}

const stylesheetPreset = {
  icon: 'file-spreadsheet',
  name: 'Spreadsheet',
}

const powerPointPreset = {
  icon: 'file-powerpoint-o',
  name: 'Slideshow',
}

const pdfPreset = {
  icon: 'file-pdf',
  name: 'PDF Document',
}

const fileTypeProperties = {
  'application/msword': wordPreset,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': wordPreset,
  'application/vnd.ms-excel': stylesheetPreset,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': stylesheetPreset,
  'application/vnd.ms-powerpoint': powerPointPreset,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': powerPointPreset,
  'application/pdf': pdfPreset,
} as Record<
  string,
  {
    icon: string
    name: string
  }
>

export const iconizeFileType = (type: string): string =>
  fileTypeProperties[type]?.icon || 'document'

export const humanizeFileType = (type: string): string =>
  fileTypeProperties[type]?.name || 'Unknown Document Type'

export const getDocumentsFromFiles = (files: File[]) =>
  files.filter((file) => acceptedFileTypes.includes(file.type))

export const insertDocumentsFromFiles = (
  editor: Editor,
  imageFiles: File[],
  collection: string,
  id: ID,
) => {
  imageFiles.forEach((file) => {
    // Get the encoded url.
    const currentBlock = Editor.above(editor, {
      match: (n) => Editor.isBlock(editor, n),
    })
    // Get a unique ID for each new attachment node.
    const nodeId = uuid()
    const lastNode = Editor.last(editor, [])
    const newAt = !editor.selection ? lastNode[1] : undefined // Insert just before last node if editor isn't focused.

    // Optimistically add attachment
    const attachment: AttachmentElement = {
      type: ElementType.Attachment,
      name: file.name.replace(/\..{3,4}$/, ''),
      fileType: file.type,
      nodeId,
      children: [{ text: '' }],
    }
    Transforms.insertNodes(editor, attachment, { at: newAt })

    // // Only replace the node if it's an empty paragraph. Otherwise, just insert the attachment.
    if (
      currentBlock &&
      Element.isElement(currentBlock[0]) &&
      currentBlock[0].type === ElementType.Paragraph &&
      Editor.isEmpty(editor, currentBlock[0])
    ) {
      Transforms.removeNodes(editor, { at: currentBlock[1] })
    }

    // // Update the upload progress.
    const handleUploadProgress = (uploadProgress: number) => {
      Transforms.setNodes(
        editor,
        { uploadProgress },
        {
          at: [],
          match: (n) =>
            Element.isElement(n) &&
            n.type === ElementType.Attachment &&
            n.nodeId === nodeId,
        },
      )
    }

    // Get the uploaded file.
    uploadFile(collection, id, [file], handleUploadProgress)
      .then((fileIds) => {
        const fileId = fileIds[0]
        // Update the image node to include the fileId.
        Transforms.setNodes(
          editor,
          { fileId },
          {
            at: [],
            match: (n) =>
              Element.isElement(n) &&
              n.type === ElementType.Attachment &&
              n.nodeId === nodeId,
          },
        )
      })
      .catch(error)
      .finally(() => {
        // Clean up previewUrl on image upload.
        Transforms.unsetNodes(editor, ['uploadProgress'], {
          at: [],
          match: (n) =>
            Element.isElement(n) &&
            n.type === ElementType.Attachment &&
            n.nodeId === nodeId,
        })
      })
  })
}
