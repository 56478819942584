import React from 'react'

import {
  Arrow,
  OptionLabel,
  OptionWrapper,
  Wrapper,
  GhostedOptionWrapper,
} from './Dropdown.styles'

import useConcatValue from 'happitu/src/hooks/useConcatValue'

interface Option {
  name: string
  value: string
}

interface Props {
  invalid: boolean
  onSelect: (value: string[]) => void
  options: Option[]
  value: string[]
  type: 'checkbox' | 'radio'
}

interface OptionSelectProps {
  placeholder: string
  value: string | null
  innerRef: React.RefObject<HTMLDivElement>
}

const OptionSelectValue = (props: OptionSelectProps) => (
  <OptionWrapper ref={props.innerRef}>
    <OptionLabel>{props.value || <i>{props.placeholder}</i>}</OptionLabel>
    <Arrow />
  </OptionWrapper>
)

// TODO: Eventually refactor the dropdown so that the display bar becomes the search bar
export default function Dropdown({ invalid, onSelect, options, type, value }: Props) {
  const [selectedOptions, setSelectedOptions] = React.useState<string[]>(value)
  const { displayVal, ref, ghostRef } = useConcatValue<HTMLDivElement>(selectedOptions)

  const handleSelect = (value: string) => {
    const newOptions = [...selectedOptions]
    const index = newOptions.indexOf(value)
    if (index === -1) {
      if (type === 'checkbox') {
        newOptions.push(value)
      } else {
        newOptions.splice(0, 1, value)
        newOptions.splice(1)
      }
    } else {
      newOptions.splice(index, 1)
    }
    setSelectedOptions(newOptions)
    onSelect(newOptions)
  }

  return (
    <>
      <GhostedOptionWrapper ref={ghostRef} style={{ paddingRight: '2em' }} />
      <Wrapper
        allowDeselect
        invalid={invalid}
        multiple={type === 'checkbox'}
        onSelect={handleSelect}
        horizontalOffset={0}
        options={options}
        searchPlaceholder="Search"
        value={selectedOptions}
        valueTemplate={
          <OptionSelectValue
            {...{ placeholder: 'Select an option', value: displayVal, innerRef: ref }}
          />
        }
      />
    </>
  )
}
