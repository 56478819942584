import * as React from 'react'

import { Loader, RetryLink, Wrapper } from './OfflinePrompt.styles'

import Icon from 'happitu/src/components/Icon'
import Box from 'happitu/src/components/box'

const OFFLINE_MESSAGES = [
  `CQ. CQ. We're having problems connecting to the mothership.`,
  `We're still unable to connect, Huston. Try again?`,
]

function AttemptReconnectPrompt({ message }: { message: string }) {
  return (
    <Box direction="ltr" style={{ width: 360 }}>
      <Icon type="radio-tower" />
      <div>
        {message}&nbsp;
        <RetryLink>Attempt reconnect</RetryLink>
      </div>
    </Box>
  )
}

function ReconnectingPrompt() {
  return (
    <div>
      <Loader />
      <span style={{ paddingLeft: 35 }}>Connecting...</span>
    </div>
  )
}

interface Props {
  onRetry: Function
  messages?: string[]
}

export default function OfflinePrompt({ onRetry, messages = OFFLINE_MESSAGES }: Props) {
  const [messageIndex, setMessageIndex] = React.useState(0)
  const [connecting, setConnecting] = React.useState(false)

  function handleReconnect() {
    setConnecting(true)
    onRetry().catch(() => {
      setConnecting(false)
      setMessageIndex(
        messages.length !== messageIndex + 1 ? messageIndex + 1 : messageIndex,
      )
    })
  }

  return (
    <Wrapper onClick={handleReconnect} connecting={connecting}>
      {connecting ? (
        <ReconnectingPrompt />
      ) : (
        <AttemptReconnectPrompt message={messages[messageIndex]} />
      )}
    </Wrapper>
  )
}
