import autobind from 'autobind-decorator'
import { last } from 'lodash'
import { LockSimple } from 'phosphor-react'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {
  FieldFigure,
  Input,
  InputWrapper,
  NextFieldWrapper,
  InsetLabelContent,
} from 'happitu/src/components/Fields/FieldUtils'
import { FlexRow } from 'happitu/src/components/_DEPRECATED/Flex'

class SSNField extends Component {
  static propTypes = {
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
    attributes: PropTypes.object.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    forceErrors: PropTypes.bool,
    id: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.string,
    value: PropTypes.any,
  }

  static defaultProps = {
    as: 'input',
    type: 'text',
    value: '',
  }

  constructor(props) {
    super(props)
    this.inputWrapper = React.createRef()
  }

  previousBlurValue = this.props.value

  state = {
    focus: false,
    touched: false,
    value: this.props.value,
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      const { top, height } = this.inputWrapper.current.getBoundingClientRect()
      const windowHeight = window.innerHeight
      if (top + height < windowHeight) {
        this.inputWrapper.current.querySelector('input, textarea').focus()
      }
    }
  }

  UNSAFE_componentWillReceiveProps({ value }) {
    if (value !== this.previousBlurValue) {
      this.setState({ value })
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.validationAttempts !== prevProps.validationAttempts &&
      this.props.autoFocus &&
      this.props.errors.length > 0
    ) {
      if (this.inputWrapper.current) {
        // check if component is in view AND there are no errors, then apply focus
        this.inputWrapper.current.querySelector('input, textarea').focus()
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this._timeout)
  }

  @autobind
  handleChange(e) {
    let val = e.target.value.replace(/\D/g, '')
    if (val.length >= 4) {
      val = val.replace(/^(\d{3})/, '$1-')
      if (val.length >= 7) {
        val = val.replace(/-(\d{2})/, '-$1-')
        val = val.replace(/(\d)-(\d{4}).*/, '$1-$2')
      }
    }
    this.setState({
      value: val,
    })
    this.onChange([val])
  }

  @autobind
  handleBlur() {
    let touched = this.state.value !== '' || this.state.touched
    this.setState({ touched, focus: false }, this.handleTimeout)
    if (this.state.value !== this.props.value) {
      this.previousBlurValue = this.state.value
      this.props.onBlur([this.state.value], this.props.id)
    }
  }

  @autobind
  handleTimeout() {
    this._timeout = setTimeout(() => this.setState({ focus: false }), 500)
  }

  @autobind
  handleFocus() {
    this.setState({ focus: true })
  }

  onChange(val) {
    this.props.onChange(val, this.props.id)
  }

  render() {
    const { attributes } = this.props
    const showError =
      (this.state.touched || this.props.forceErrors) && this.props.errors.length > 0

    return (
      <NextFieldWrapper invalid={!!showError}>
        <FlexRow>
          <InputWrapper style={{ maxWidth: 280, width: '100%' }} ref={this.inputWrapper}>
            <Input
              as={this.props.as}
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              placeholder={attributes.placeholder}
              type="text"
              value={
                this.state.focus ? this.state.value : this.state.value.replace(/\d/g, '•')
              }
            />
            <InsetLabelContent required={attributes.required}>
              {attributes.label} <LockSimple weight="fill" />
            </InsetLabelContent>
          </InputWrapper>
        </FlexRow>

        {showError && <FieldFigure value={last(this.props.errors)} />}
      </NextFieldWrapper>
    )
  }
}

export default SSNField
