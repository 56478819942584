import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import getStyle from 'happitu/src/getStyle'

export const toolbarButtonStyles = css`
  align-items: center;
  border-radius: 3px;
  color: ${getStyle('text-light-color')};
  cursor: pointer;
  display: flex;
  height: 2em;
  justify-content: center;
  line-height: 1;
  margin-right: 0.5em;
  width: 2em;
`

// prettier-ignore
export const ToolbarButton = styled.span<{ active?: boolean, disabled?: boolean }>`
  ${toolbarButtonStyles}

  ${ifProp(
    'active',
    css`
      background: ${getStyle('app-background')};
      color: ${getStyle('text-default-color')};
    `,
  )}

  ${ifProp(
    'disabled',
    css`
      cursor: not-allowed;
      color: ${getStyle('text-inactive-color')};
      transition: opacity 150ms ease-in-out;
    `,
  )}
`

export const Divider = styled.div`
  border-left: 1px solid ${getStyle('border-color')};
  height: 2rem;
  margin-right: 0.75em;
`
