import styled, { css } from 'styled-components'
import { ifNotProp } from 'styled-tools'

import { Hr as BaseHr } from 'happitu/src/components/Layout/Dividers'
import getStyle from 'happitu/src/getStyle'
import { icomoon } from 'happitu/src/themes/style-utils'
import { font } from 'theme'

const SHARED_HEIGHT = '1.75em'

export const Heading = styled.h1`
  color: ${getStyle('text-default-color')};
  font-size: 32px;
  font-weight: ${font('weight', 'bold')};
  line-height: ${SHARED_HEIGHT};
`

export const Subheading = styled.h2`
  color: ${getStyle('text-default-color')};
  font-size: 24px;
  font-weight: ${font('weight', 'medium')};
  line-height: ${SHARED_HEIGHT};
`

export const BlockQuote = styled.blockquote<{ hideIcon?: boolean }>`
  border-left: 2px solid ${getStyle('blockquoteBorder-color')};
  color: ${getStyle('text-default-color')};
  font-size: 18px;
  font-weight: ${font('weight', 'medium')};
  line-height: ${SHARED_HEIGHT};
  margin: 0;
  min-height: ${SHARED_HEIGHT};
  padding-left: 1em;
  position: relative;

  ${ifNotProp(
    'hideIcon',
    css`
      :before {
        ${icomoon};
        color: ${getStyle('blockquoteIcon-color')};
        content: '\\e94d';
        font-size: 14px;
        left: -1.625em;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    `,
  )}

  /* Don't show script blurb icon if the previous node is also a blockquote. */
  + blockquote {
    :before {
      content: '';
    }
  }
`

const listStyles = css`
  color: ${getStyle('text-default-color')};
  line-height: ${SHARED_HEIGHT};
  margin: 0;
`

export const ListItem = styled.li`
  line-height: ${SHARED_HEIGHT};
`

export const indentModifier = 1.25

const getIndentPadding = (indentLevel: number, baseLeftPadding = '0em') => {
  return `padding-left: calc(${indentLevel * indentModifier}em + ${baseLeftPadding});`
}

const olStyles = ['decimal', 'lower-latin', 'lower-roman']
const ulStyles = ['disc', 'circle', 'square']
const getListStyleType = (type: 'ol' | 'ul', indentLevel: number) => {
  if (type === 'ul') {
    return `list-style-type: ${ulStyles[indentLevel % ulStyles.length]};`
  } else if (type === 'ol') {
    return `list-style-type: ${olStyles[indentLevel % olStyles.length]};`
  }
  return
}

export const OrderedList = styled.ol<{ indentLevel: number }>`
  ${listStyles}
  ${(props) => getListStyleType('ol', props.indentLevel)}
  ${(props) => getIndentPadding(props.indentLevel, '0.92em')}

  ${ListItem} {
    padding-left: 0.33em;
  }
`

export const UnorderedList = styled.ul<{ indentLevel: number }>`
  ${listStyles}
  ${(props) => getListStyleType('ul', props.indentLevel)}
  ${(props) => getIndentPadding(props.indentLevel, '1.00em')}

  ${ListItem} {
    padding-left: 0.25em;
  }
`

// Note: This is set as a div for now because paragraphs often contain child div elements.
export const Paragraph = styled.div<{ indentLevel: number }>`
  color: ${getStyle('text-default-color')};
  line-height: ${SHARED_HEIGHT};
  margin: 0;
  min-height: ${SHARED_HEIGHT};

  ${(props) => getIndentPadding(props.indentLevel)}
`

// Calculate the padding to get an hr with the same height as paragraphs.
export const HrContainer = styled.div`
  padding: calc((${SHARED_HEIGHT} / 2) - 0.5px) 0;
`

export const Hr = styled(BaseHr)`
  margin: 0;
`
