import update from 'immutability-helper'

import createReducer from './createReducer'

import * as types from 'happitu/src/constants/ActionTypes'

const initialState = {
  data: {},
  filteredBy: 'SHOW_ALL',
  loaded: [],
}

const addStep = (state, { payload, stepGroupId }) => {
  const { id } = payload
  const group = state.data[stepGroupId]
  return {
    ...state,
    data: {
      ...state.data,
      [group.id]: {
        ...group,
        steps: group.steps.concat(id),
      },
    },
  }
}

const removeStep = (state, { stepGroupId, stepId }) => {
  const group = state.data[stepGroupId]

  return {
    ...state,
    data: {
      ...state.data,
      [group.id]: {
        ...group,
        steps: group.steps.filter((step) => step !== stepId),
      },
    },
  }
}

const updateFromRelay = (state, groups) => {
  if (!groups) return state
  const nextGroups = {}
  let workflowId = null
  groups.forEach((group) => {
    const existingStep = state.data[group.id] || {}
    nextGroups[group.id] = { ...existingStep, ...group }
    workflowId = group.workflowId
  })
  return update(state, {
    data: { $merge: nextGroups },
    loaded: { $push: [workflowId] },
  })
}

export default createReducer(initialState, {
  [types.RELAY_DISPATCH]: (state, { payload }) =>
    updateFromRelay(state, payload.workflowStepGroups),

  [types.REMOVE_WORKFLOW_STEP_FROM_GROUP]: (state, action) => removeStep(state, action),
  [types.CREATE_WORKFLOW_STEP]: (state, action) => addStep(state, action),
  [types.DELETE_WORKFLOW_STEP]: (state, action) => removeStep(state, action),

  [types.UPDATE_WORKFLOW_ACTION_WITH_STEP_FAILED]: (state, { payload }) =>
    removeStep(state, { stepGroupId: payload.step.stepGroupId, stepId: payload.step.id }),
})
