import * as React from 'react'

import useTicketCreate from '../hooks/useTicketCreate'

import VoidCallout from 'happitu/src/components/Layout/VoidCallout'
import { setPageTitle } from 'happitu/src/helpers/domHelper'
import {
  getParams,
  navigateTo,
  NewTicketRouteParams,
} from 'happitu/src/helpers/routeHelpers'
import { connectTicketContext } from 'happitu/src/modules/ticketing'

function NewTicket(props: { createTicket: AsyncAction }) {
  React.useEffect(() => {
    setPageTitle('New ticket')
  })

  useTicketCreate({
    onCreate: props.createTicket,
    onSuccess: ({ ticketId, ticketInteractionId }) => {
      const routeParams = getParams<NewTicketRouteParams>()
      navigateTo(
        'app.tickets.work',
        {
          redirectTo: routeParams.redirectTo,
          stepIndex: '0',
          ticketId,
          ticketInteractionId,
        },
        { replace: true },
      )
    },
    onError: () => navigateTo('home', {}, { replace: true }),
  })

  return <VoidCallout>Creating ticket...</VoidCallout>
}

export default connectTicketContext(null, ['createTicket'])(NewTicket)
