import { AnimatePresence, motion } from 'framer-motion'
import React, { ReactNode, useContext, CSSProperties } from 'react'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import { MenuContext } from './context'
import styles from './styles'
import { usePosition } from './utils'

interface Props {
  children: ReactNode
  style?: CSSProperties
  scrollable?: boolean
}

const AnimatedMenu = styled(motion.div)<{ scrollable?: boolean }>`
  ${styles}
  ${ifProp(
    'scrollable',
    css`
      max-height: calc(100vh - 100px);
      overflow-y: auto;
    `,
  )}
`

const Menu = ({ children, scrollable = true, ...props }: Props) => {
  const { isVisible } = useContext(MenuContext)
  const { ref, position } = usePosition(isVisible, scrollable)

  return (
    <AnimatePresence>
      {isVisible && (
        <AnimatedMenu
          ref={ref}
          style={props.style}
          aria-expanded={isVisible}
          onAnimationStart={position}
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.8, opacity: 0 }}
          scrollable={scrollable}
          transition={{ duration: 0.125 }}
          onClick={(e) => e.nativeEvent.stopImmediatePropagation()}
          key="menu"
          {...props}
        >
          {children}
        </AnimatedMenu>
      )}
    </AnimatePresence>
  )
}

export default Menu
