import { css } from 'styled-components'

import getStyle from 'happitu/src/getStyle'

const styles = css`
  background: ${getStyle('menu-background')};
  border-radius: ${getStyle('menu-border-radius')};
  border: 1px solid ${getStyle('menu-border-color')};
  box-shadow: ${getStyle('menu-box-shadow')};
  position: absolute;
  z-index: ${getStyle('menu-zIndex')};
  min-width: 200px;
  text-align: left;
  font-weight: ${getStyle('text-weight-normal')};
`

export default styles
