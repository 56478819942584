import { get } from 'lodash'

import { getParams, WorkTicketRouteParams } from 'happitu/src/helpers/routeHelpers'
import { buildSuggestedHelpTopics } from 'happitu/src/helpers/ticket/buildSuggestedHelpTopics'
import {
  getCurrentStepValues,
  getInteractionFromRoute,
} from 'happitu/src/helpers/ticket/ticketAttributeFinders'
import { TicketState } from 'happitu/src/types/ticketContext'

export default function suggestedHelpTopicsReducer(state: TicketState) {
  const { ticketInteractionId } = getParams<WorkTicketRouteParams>()
  if (state.ticketInteractions && ticketInteractionId && state.workflowStepGroupSteps) {
    const interaction = getInteractionFromRoute(state.ticketInteractions)
    const currentStepValues = getCurrentStepValues(interaction.workflowSteps)
    return buildSuggestedHelpTopics(
      interaction.workflowSteps,
      get(currentStepValues, 'stepId'),
    )(state)
  }
  return state.suggestedHelpTopics || { alerts: [], helpTopics: [] }
}
