import styled from 'styled-components'

import getStyle from 'happitu/src/getStyle'
import { font } from 'theme'

export const MenuHeading = styled.h1`
  align-items: center;
  color: ${getStyle('text-default-color')};
  display: grid;
  font-size: 1.5em;
  font-weight: ${font('weight', 'bold')};
  grid-template-columns: 1fr auto;
  padding: 1.5rem 1rem;
`

export const MenuSubHeading = styled.h2`
  font-size: 1em;
  font-weight: ${font('weight', 'medium')};
  margin-bottom: 1rem;
`
