import React from 'react'

import TextField from './TextField'

const TelephoneField = (props) =>  (
  <TextField { ...props } />
)

TelephoneField.defaultProps = {
  type: 'tel'
}

export default TelephoneField
