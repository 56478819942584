import { GeneratedCollection } from 'happitu/src/helpers/generatedMetadata'

export const isContactMethod = (value: any): value is ContactMethod =>
  typeof value === 'object' && 'value' in value

export const createFilterKey = (field: string) => `filter[${field}]`

export const convertToString = (value: any): string => {
  if (Array.isArray(value)) return convertToString(value[0])
  if (isContactMethod(value)) return value.value
  return `${value || ''}`
}

const hasImpression = [
  GeneratedCollection.WorkflowElements,
  GeneratedCollection.WorkflowActions,
  GeneratedCollection.WorkflowSteps,
  GeneratedCollection.HelpTopics,
]

export const isImpressionable = (collectionName: GeneratedCollection) =>
  hasImpression.includes(collectionName)
