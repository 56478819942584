import React from 'react'
import ReactDOM from 'react-dom'

import CloseButton from './CloseButton'
import {
  ImgContainer,
  PreviewImg,
  Toolbar,
  Wrapper,
  PaginationValues,
} from './FullscreenImagePreview.styles'
import NextImgButton from './NextImgButton'
import PrevImgButton from './PrevImgButton'
import ZoomButton from './ZoomButton'

import { getFileUrl } from 'happitu/src/helpers/fileUploadHelpers'

const portal = document.createElement('div')
portal.setAttribute('id', 'image-preview-portal')
document.body.appendChild(portal)

interface Props {
  closePreview: () => void
  defaultIndex: number
  fileIds: ID[]
}

const FullscreenImagePreview = ({ closePreview, fileIds, defaultIndex = 0 }: Props) => {
  const [index, setIndex] = React.useState(defaultIndex)
  const [isZoomed, setZoomed] = React.useState(false)
  const fileId = fileIds[index]
  const url = getFileUrl(fileId)
  const totalCount = fileIds.length

  const toggleZoom = () => setZoomed(!isZoomed)
  const showPrevImg = () => {
    setIndex(index === 0 ? totalCount - 1 : index - 1)
    setZoomed(false)
  }
  const showNextImg = () => {
    setIndex((index + 1) % totalCount)
    setZoomed(false)
  }
  const handleKeyboardNav = (e: KeyboardEvent) => {
    e.preventDefault() // Prevent anything from happening outside this component.
    if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
      showPrevImg()
    } else if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
      showNextImg()
    } else if (e.key === 'Escape') {
      closePreview()
    }
  }

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyboardNav)
    return () => window.removeEventListener('keydown', handleKeyboardNav)
  }, [index])

  return ReactDOM.createPortal(
    <>
      <Wrapper onClick={closePreview}>
        <CloseButton onClick={closePreview} />
        <ImgContainer>
          <PreviewImg src={url} isZoomed={isZoomed} />
        </ImgContainer>
      </Wrapper>
      <Toolbar onClick={(e) => e.stopPropagation()}>
        <PrevImgButton isDisabled={totalCount <= 1} onClick={showPrevImg} />
        <PaginationValues>
          {index + 1} / {totalCount}
        </PaginationValues>
        <NextImgButton isDisabled={totalCount <= 1} onClick={showNextImg} />
        <ZoomButton toggleZoom={toggleZoom} isZoomed={isZoomed} />
      </Toolbar>
    </>,
    portal,
  )
}

export default FullscreenImagePreview
