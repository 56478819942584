import * as React from 'react'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import { color, font } from 'theme'

interface Props {
  className?: string
  index: number
}

function Hotkey ({ className, index }: Props) {
  return (
    <div className={ className }>
      { index + 1 }.
    </div>
  )
}

function isEqual (prevProps: Props, nextProps: Props) {
  return prevProps.index === nextProps.index
}

type StyledProps = Props & {
  ghosted?: boolean
}

export default styled(React.memo(Hotkey, isEqual))<StyledProps>`
  border-radius: 2px;
  display: inline-block;
  font-size: 12px;
  font-weight: ${ font('weight', 'medium') };
  margin-right: 1em;
  min-width: 20px;
  padding: 2px;
  text-align: center;

  ${ ifProp('ghosted', css`
    border: 1px solid ${ color('border') };
    color: ${ color('grey', 'lighter') };
  `, css`

    color: ${ color('secondary') };
  `) }
`
