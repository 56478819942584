import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import { IconButton } from 'happitu/src/components/Buttons'
import InlineEdit from 'happitu/src/components/Fields/InlineEdit'
import {
  DisplayValue,
  EditableValue,
} from 'happitu/src/components/Fields/InlineEdit.styles'
import Icon from 'happitu/src/components/Icon'
import theme, { color, font } from 'theme'

export const LoadingLabel = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  text-align: center;
  top: 100%;
  transform: translateY(100%);
  transition: transform 500ms;
  width: 100%;
  z-index: 1;
  white-space: nowrap;
`

export const LoadingBar = styled.div`
  background: ${color('blue', 'darker')};
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: width 100ms;
  width: 0;
`

export const UploadLabel = styled(LoadingLabel)`
  align-items: center;
  display: flex;
  position: relative;
  transform: translateY(0);
`

export const UploadButton = styled.label<{ isLoading?: boolean }>`
  align-items: center;
  background: ${color('blue')} !important;
  border-radius: ${theme('input.borderRadius')};
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1);
  color: white;
  cursor: pointer;
  display: inline-flex;
  font-weight: ${font('weight', 'medium')};
  overflow: hidden;
  padding: 0.75em 1em;
  position: relative;
  user-select: none;

  input[type='file'] {
    left: -1000px;
    position: fixed;
    top: -1000px;
  }

  ${ifProp(
    `isLoading`,
    css`
      min-width: 180px;

      ${LoadingLabel} {
        top: 50%;
        transform: translateY(-50%);
      }

      ${LoadingBar} {
        display: block;
      }

      ${UploadLabel} {
        transform: translateY(-200%);
      }
    `,
  )}

  ${Icon} {
    font-size: ${font('size', 'medium')};
    margin-right: 1rem;
  }
`

export const CancelButton = styled(IconButton)`
  color: white;

  :hover,
  :active {
    background: unset;
    color: white;
  }
`

export const EditableUploadButton = styled(UploadButton)`
  padding: 0.5em 0.5em 0.5em 1em;

  ${Icon} {
    margin-right: 0.5rem;
  }

  ${InlineEdit} {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;

    ${DisplayValue} {
      :hover,
      :hover:focus {
        background: rgba(255, 255, 255, 0.2);
      }
      :focus {
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.3);
      }
    }

    ${EditableValue} {
      :focus {
        box-shadow: none;
      }
    }
  }
`
