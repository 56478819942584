export const WORKFLOW_CHAT_CHANNEL = 1
export const WORKFLOW_EMAIL_CHANNEL = 2
export const WORKFLOW_SELF_SERVICE_CHANNEL = 3
export const WORKFLOW_PHONE_CHANNEL = 4

export const WORKFLOW_CHANNELS = [
  {
    name: 'Phone',
    value: WORKFLOW_PHONE_CHANNEL,
    description:
      'Link phone conversations to this workflow. Structure your live conversations so your team can effectively handle your customers.',
    //description: 'Guide and Track your customer service journeys through live phone conversations.',
  },
  {
    name: 'Email',
    value: WORKFLOW_EMAIL_CHANNEL,
    description:
      'Receive and send emails with this workflow. Give your team contextual responses and resources to efficiently respond.',
  },
  {
    name: 'Chat',
    value: WORKFLOW_CHAT_CHANNEL,
    description: 'Connect your chat conversations with your ticket documentation.',
  },
  {
    name: 'Self-Service',
    value: WORKFLOW_SELF_SERVICE_CHANNEL,
    description:
      'Share troubleshooting techniques directly with your customers through an online knowledge base.',
  },
]

export const WORKFLOW_TEMPLATES = [
  {
    name: 'Custom',
    value: '',
  },
  {
    name: 'Customer Care',
    value: 1,
  },
  {
    name: 'Technical Support',
    value: 2,
  },
  {
    name: 'Reservations',
    value: 3,
  },
]

export const ADDRESS_DEFAULT_VALUE = ['', '', '', '', '', '', '', '']
