import update from 'immutability-helper'
import { get, unionBy } from 'lodash'

import createReducer from './createReducer'
import Store from './createStore'

import * as types from 'happitu/src/constants/ActionTypes'
import { getWorkingVersion } from 'happitu/src/helpers/workflowHelper'

export const initialState = new Store()

const timestampWorkflow = (state, { payload }) => {
  const { updatedAt, workflowId } = payload
  return state.update(workflowId, { updatedAt })
}

const createTag = (state, { payload }) => {
  if (!payload.workflowId) return state
  const workflow = state.findById(payload.workflowId)
  const version = getWorkingVersion({ workflows: state }, payload.workflowId)
  const tagIds = version.tagIds || []
  // Does the tag already exist?
  if (tagIds && tagIds.findIndex((tag) => tag === payload.tag.id) > -1) return state
  // Create the next version

  const nextVersion = {
    ...version,
    tagIds: [...tagIds, payload.tag.id],
  }
  // Create the new versions array.
  const nextVersions = [...workflow.versions]
  // Splice the new version.
  nextVersions.splice(workflow.workingVersion, 1, nextVersion)
  // Ready the next state
  const nextState = {
    ...workflow,
    versions: nextVersions,
  }
  // Set the nextState
  return state.set(payload.workflowId, nextState)
}

const removePristineFlag = (state, { workflowId }) => {
  const workflow = state.findById(workflowId)

  return state.update(
    workflowId,
    update(workflow, {
      versions: {
        [workflow.workingVersion]: {
          pristine: {
            $set: false,
          },
        },
      },
    }),
  )
}

const updateWorkflowLocations = (state, payload) => {
  const workflow = state.findById(payload.workflowId)

  return state.update(
    payload.workflowId,
    update(workflow, {
      versions: {
        [workflow.workingVersion]: {
          locationIds: {
            $set: payload.locationIds,
          },
          pristine: {
            $set: false,
          },
        },
      },
    }),
  )
}

function mergeVersions(newWorkflow, oldWorkflow) {
  return unionBy(
    get(newWorkflow, 'versions', []),
    get(oldWorkflow, 'versions', []),
    'version',
  )
}

export function mergeWorkflow(newWorkflow, oldWorkflow) {
  const versions = mergeVersions(newWorkflow, oldWorkflow)
  return Object.assign({}, oldWorkflow, newWorkflow, { versions })
}

export default createReducer(initialState, {
  [types.RELAY_DISPATCH]: (state, { payload }) =>
    state.setMany(payload.workflows, mergeWorkflow),

  [types.GET_WORKFLOWS]: (state, { payload }) => state.setMany(payload, mergeWorkflow),

  [types.CREATE_WORKFLOW]: (state, { payload }) =>
    state.set(payload.workflow.id, payload.workflow),

  [types.DELETE_WORKFLOW]: (state, { payload }) =>
    state.update(payload.workflowId, { active: false }),

  [types.UPDATE_WORKFLOW]: (state, { payload }) =>
    state.update(payload.id, payload.workflow),

  [types.UPDATE_WORKFLOW_LOCATIONS]: (state, { payload }) =>
    updateWorkflowLocations(state, payload),

  [types.PUBLISH_WORKFLOW]: (state, { payload }) => state.update(payload.id, payload),

  [types.CREATE_WORKFLOW_STEP]: removePristineFlag,
  [types.DELETE_WORKFLOW_STEP]: removePristineFlag,

  [types.ADD_WORKFLOW_ELEMENT]: (state, { response }) =>
    removePristineFlag(state, response),
  [types.UPDATE_WORKFLOW_ELEMENT]: (state, { payload }) =>
    removePristineFlag(state, payload),
  [types.REMOVE_WORKFLOW_ELEMENT]: (state, { payload }) =>
    removePristineFlag(state, payload),

  [types.CREATE_TAG]: (state, action) => createTag(state, action),

  [types.TIMESTAMP_WORKFLOW]: (state, action) => timestampWorkflow(state, action),
  [types.CREATE_SMTP_SETTING]: (state, action) =>
    state.update(action.workflowId, {
      smtpSettingId: action.payload.id,
    }),

  [types.LOCATION_UPLOAD]: (state, action) =>
    get(action.payload, 'workflow.id')
      ? state.set(action.payload.workflow.id, action.payload.workflow)
      : state,
})
