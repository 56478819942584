import { Editor, Element } from 'slate'
import { ReactEditor, RenderElementProps, useSlate } from 'slate-react'
import styled from 'styled-components'

import { BlockQuote } from './Element.styles'

import { BlockQuoteElement, ElementType } from 'happitu/src/types/slate-types'

interface Props extends RenderElementProps {
  className?: string
  element: BlockQuoteElement
  isDragging: boolean
}

const EditableBlockQuote = (props: Props) => {
  const editor = useSlate()

  const path = ReactEditor.findPath(editor, props.element)

  const prevEntry = Editor.previous(editor, {
    match: (n) => Editor.isBlock(editor, n),
    at: path,
  })

  const prevBlock = !!prevEntry ? prevEntry[0] : null

  const isFirst =
    !prevBlock ||
    (Element.isElement(prevBlock) && prevBlock.type !== ElementType.BlockQuote)

  // Hide the icon if it's not the first line in the block or if the node is being dragged.
  const hideIcon = !isFirst || props.isDragging
  return (
    <BlockQuote {...props.attributes} className={props.className} hideIcon={hideIcon}>
      {props.children}
    </BlockQuote>
  )
}

export default styled(EditableBlockQuote)`
  /* Hide the icon on hover. */
  /* &:hover {
    :before {
      content: '';
    }
  } */
`
