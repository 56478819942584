import styled from 'styled-components'

import getStyle from 'happitu/src/getStyle'
import { metric, font } from 'happitu/src/themes/theme'

export default styled.span`
  background: ${getStyle('button-base-default-background')};
  border-radius: ${metric('borderRadius')};
  display: inline-block;
  font-family: Menlo, Consolas, DejaVu Sans Mono, monospace;
  font-size: 0.9em;
  font-weight: ${font('weight', 'normal')};
  margin-right: 3px;
  padding: 0 2px;
  text-transform: lowercase;
  word-break: break-all;
`
