import { getWorkflowVersion } from 'happitu/src/helpers/workflowHelper'

export const findGroup = (state, groupId) => {
  const groups = state.workflowGroups
  return groups.data && groups.data[groupId] ? groups.data[groupId] : null
}

/**
 * @namespace Step
 * @prop {Object[]} actions
 * @prop {String}   createdAt
 * @prop {Object[]} elements
 * @prop {String}   groupId
 * @prop {String}   id
 * @prop {String}   name
 * @prop {String}   nextWorkflowId
 * @prop {String}   organizationId
 * @prop {String[]} resourceIds
 * @prop {String}   stepGroupId
 * @prop {String}   updatedAt
 */

/**
 * Fetches a step by ID.
 * @param {Object} state  The redux state.
 * @param {String} stepId The Step ID to fetch.
 * @returns {Step|null}
 */
export const findStep = (state, stepId) => {
  const steps = state.workflowSteps
  return steps.data && steps.data[stepId] ? steps.data[stepId] : null
}

/**
 * Returns the first step of the workflow.
 * @param {object}          state             The redux state.
 * @param {string}          workflowId        The ID of the workflow.
 * @param {string | number} workflowVersion   The version of the workflow.
 * @returns {Step}
 */
export const firstStep = (state, workflowId, workflowVersion = 'currentVersion') => {
  const workflow = state.workflows.findById(workflowId)
  let versionIndex = workflowVersion
  if (typeof workflowVersion === 'string') {
    versionIndex = workflow[workflowVersion]
  }
  const version = getWorkflowVersion(state, workflowId, versionIndex)
  for (const groupId of version.groups) {
    const group = findGroup(state, groupId)
    if (group.steps.length) return findStep(state, group.steps[0])
  }
}

/**
 * Returns the first group in the workflow
 * @param {object}          state             The redux state.
 * @param {string}          workflowId        The ID of the workflow.
 * @param {string | number} workflowVersion   The version of the workflow.
 */
export const firstGroup = (state, workflowId, workflowVersion = 'currentVersion') => {
  const workflow = state.workflows.findById(workflowId)
  let versionIndex = workflowVersion
  if (typeof workflowVersion === 'string') {
    versionIndex = workflow[workflowVersion]
  }
  const version = getWorkflowVersion(state, workflowId, versionIndex)
  return findGroup(state, version.groups[0])
}

export const findUserSetting = (state, key) => {
  const { user } = state
  return user.profile.settings[key]
}

export const findCustomer = (state, key) => {
  let ID = key
  if (!key) {
    const { ticketId } = state.router.route.params
    const { customerId, temporaryCustomerId } = state.tickets.findById(ticketId)
    ID = customerId || temporaryCustomerId
  }
  return state.customers.findById(ID)
}
