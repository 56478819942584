import { motion, AnimatePresence, Variants } from 'framer-motion'
import React, { Component } from 'react'
import styled from 'styled-components'

import {
  MultiStepMenuProps,
  StepKey,
  MenuStep,
  _DEPRECATED_MenuStep,
} from '../MultiStepMenu.types'

import MultiStepMenuHeader from './MultiStepMenuHeader'

type Props = Omit<MultiStepMenuProps, 'isVisible' | 'onClose'> & { onClose?: () => void }

interface State {
  stepKeys: Array<keyof MultiStepMenuProps['steps']>
  context: Record<StepKey, Record<string, any> | void>
  direction: number
  isAnimating: boolean
}

const Inner = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 1fr;

  > * {
    height: 100%;
  }
`

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
    position: 'relative',
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
      position: 'absolute',
    }
  },
} as Variants

const isNewMenuStep = (
  step: MenuStep<any> | _DEPRECATED_MenuStep<any>,
): step is MenuStep<any> => 'props' in step

const initializeStepKey = (
  steps: MultiStepMenuProps['steps'],
  defaultStep: MultiStepMenuProps['defaultStep'],
) => {
  if (Array.isArray(defaultStep)) return defaultStep
  return [defaultStep || Object.keys(steps)[0]]
}

class MultiStepMenuBody extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      context: props.defaultContext || {},
      direction: 1,
      isAnimating: false,
      stepKeys: initializeStepKey(props.steps, props.defaultStep),
    }

    this.appendStep = this.appendStep.bind(this)
    this.popStep = this.popStep.bind(this)
    this.onExitComplete = this.onExitComplete.bind(this)
  }

  appendStep(stepKey: StepKey, screenContext?: Record<string, any>) {
    this.setState({
      context: { ...this.state.context, [stepKey]: screenContext || {} },
      direction: 1,
      isAnimating: true,
      stepKeys: [...this.state.stepKeys, stepKey],
    })
  }

  popStep() {
    this.setState({
      direction: -1,
      isAnimating: true,
      stepKeys: this.state.stepKeys.slice(0, this.state.stepKeys.length - 1),
    })
  }

  private getTitles(): React.ReactNode[] {
    return this.state.stepKeys.map((key) => {
      const title = this.props.steps[key].title
      return typeof title === 'function'
        ? React.createElement(title, this.getStepProps(key))
        : title
    })
  }

  private getStepProps(key: StepKey) {
    const step = this.props.steps[key]
    const stepProps = isNewMenuStep(step) ? step.props : {}
    return {
      ...stepProps,
      ...(this.state.context[key] || {}),
      appendStep: this.appendStep,
      context: this.state.context[key] || {},
      key,
      popStep: this.popStep,
      isAnimating: this.state.isAnimating,
    }
  }

  onExitComplete() {
    this.setState({ isAnimating: false })
  }

  render() {
    const { stepKeys } = this.state
    const screen = stepKeys[stepKeys.length - 1]

    const step = this.props.steps[screen]
    const transition = {
      type: 'tween',
      ease: 'anticipate',
      duration: 0.5,
    }

    return (
      <div className={this.props.className}>
        <MultiStepMenuHeader
          animate="center"
          custom={this.state.direction}
          exit="exit"
          initial="enter"
          onBack={this.popStep}
          onClose={this.props.onClose}
          showCloseButton={this.props.showCloseHeaderButton}
          titles={this.getTitles()}
          transition={transition}
          variants={variants}
        />
        <Inner>
          <AnimatePresence
            onExitComplete={this.onExitComplete}
            custom={this.state.direction}
            initial={false}
          >
            <motion.div
              animate="center"
              custom={this.state.direction}
              exit="exit"
              initial="enter"
              key={screen}
              style={{ top: 0 }}
              transition={transition}
              variants={variants}
            >
              {React.createElement(
                isNewMenuStep(step) ? step.component : step.children,
                this.getStepProps(screen),
              )}
            </motion.div>
          </AnimatePresence>
        </Inner>
      </div>
    )
  }
}

export default styled(MultiStepMenuBody)`
  display: grid;
  grid-template-rows: 4em auto;
  height: 100%;
  width: 400px;
  overflow: hidden;
`
