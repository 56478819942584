import 'regenerator-runtime/runtime'
import 'react-hot-loader/patch'

import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router5'

import router from './app.router'

import configureStore from 'happitu/src/configureStore'
import { SuggesterProvider } from 'happitu/src/models/searchSuggester'
import App from 'happitu/src/modules/App'
import HappituThemeProvider from 'happitu/src/themes/HappituThemeProvider'
import BaseStyles from 'happitu/src/themes/base.styles'

const store = configureStore(router)

const renderApp = () => {
  router.start(() => {
    ReactDOM.render(
      <SuggesterProvider>
        <Provider store={store}>
          <RouterProvider router={router}>
            <HappituThemeProvider>
              <>
                <BaseStyles />
                <App />
              </>
            </HappituThemeProvider>
          </RouterProvider>
        </Provider>
      </SuggesterProvider>,
      document.getElementById('root'),
    )
  })
}

if (module.hot) {
  module.hot.accept('happitu/src/modules/App', renderApp)
}

renderApp()
