import isHotkey from 'is-hotkey'
import React, { useCallback } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { Transforms } from 'slate'
import { Editable, useSlate } from 'slate-react'
import styled from 'styled-components'

import Element from './Element'
import Leaf from './Leaf'
import { defaultHotkeys, Hotkeys } from './hotkeys'

import Toolbar from 'happitu/src/components/RichTextEditor/Toolbar/Toolbar'
import getStyle from 'happitu/src/getStyle'
import { isSafari } from 'happitu/src/helpers/browser-helpers'
import {
  getDocumentsFromFiles,
  insertDocumentsFromFiles,
} from 'happitu/src/helpers/editor/attachmentHelpers'
import {
  getImagesFromFiles,
  insertImagesFromFiles,
} from 'happitu/src/helpers/editor/imageHelper'
import { getDataTransferItems } from 'happitu/src/helpers/fileHelper'

const EditorContainer = styled.div`
  background: ${getStyle('app-background')};
  height: 100%;
  line-height: 1.8;
  min-height: 8em;
  position: relative;
`

// const nodeDefaults: { [key: string]: SlateElement } = {
//   'paragraph': {
//     type: 'paragraph',
//     children: [{ text: '' }],
//     indentLevel: 0,
//   },
//   'section-break': {
//     type: 'section-break',
//     children: [{ text: '' }],
//   },
// }

// /**
//  *
//  * WARNING: Do not use until we sort out Slate's duplicate key issue.
//  */
// export const getNodeDefault = (type: ELEMENT_TYPES) => {
//   return nodeDefaults[type]
// }

interface Props {
  collection?: string // Collection is only passed to handle file uploads.
  id?: ID
  hotkeys?: Hotkeys
  placeholder?: string
  disableToolbar?: boolean
}

/**
 * Usage: Wrap the editor and anything that should touch the editor context with <Slate> and pass it the editor from useEditor()
 */
const RichTextEditor = ({
  placeholder = 'Say something here...',
  collection,
  id,
  ...props
}: Props) => {
  const editor = useSlate()
  const hotkeys: Hotkeys = { ...defaultHotkeys, ...props.hotkeys }

  const renderElement = React.useCallback((props) => <Element {...props} />, [])
  const renderLeaf = React.useCallback(
    (props) => (
      <span {...props.attributes}>
        <Leaf leaf={props.leaf}>{props.children}</Leaf>
      </span>
    ),
    [],
  )

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    for (const hotkey in hotkeys) {
      if (isHotkey(hotkey, e.nativeEvent)) {
        e.preventDefault()
        const action = hotkeys[hotkey]
        action(editor)
      }
    }
  }

  // NOTE: We're handling Safari image drops separately, we'll eventually use Slate's insertData handler in withImages. See related Slate issue: https://github.com/ianstormtaylor/slate/issues/3664.
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    if (editor.allowMedia && isSafari() && !!collection && !!id) {
      e.preventDefault()
      e.stopPropagation()
      const files = getDataTransferItems(e)
      const imageFiles = getImagesFromFiles(files)
      const documentFiles = getDocumentsFromFiles(files)
      if (imageFiles.length > 0 || documentFiles.length > 0) {
        setTimeout(() => {
          insertImagesFromFiles(editor, imageFiles, collection, id)
          insertDocumentsFromFiles(editor, documentFiles, collection, id)
        }, 50)
      } else {
        e.preventDefault()
      }
    }
  }

  // Only allow files to be dropped if there are one or more image files.
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      const { destination, source } = result
      // Bail if the node was dropped outside of the editor.
      if (!destination) {
        return
      }
      // Bail if the node is dropped in its original position.
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return
      }
      // Move the node.
      Transforms.moveNodes(editor, {
        at: [source.index],
        to: [destination.index],
      })
    },
    [editor],
  )

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="text-editor" direction="vertical">
        {(provided) => (
          <EditorContainer {...provided.droppableProps} ref={provided.innerRef}>
            <Editable
              autoFocus
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onKeyDown={handleKeyDown}
              placeholder={placeholder}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              spellCheck
            />
            {!props.disableToolbar && <Toolbar collection={collection} id={id} />}
            <div>{provided.placeholder}</div>
          </EditorContainer>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default RichTextEditor
