import { css } from 'styled-components'
import { ifProp, prop } from 'styled-tools'

export interface GridProps {
  gap?: number | string
  inline?: boolean
  direction?: 'ltr' | 'ttb'
}

export const gridMixin = css<GridProps>`
  display: ${ifProp('inline', 'inline-grid', 'grid')};
  grid-gap: ${prop('gap', '0')}em;

  ${ifProp(
    'direction',
    css`
      grid-auto-flow: ${ifProp({ direction: 'ltr' }, 'column', 'row')};
    `,
  )}
`
