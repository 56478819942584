import { startCase } from 'lodash'
import React, { useRef, useMemo, useEffect } from 'react'
import styled from 'styled-components'

import { CREATE_ACTION } from '../List.types'
import { Option, CreateOption } from '../ListItems'
import SelectableListProvider from '../SelectableListProvider'

import { RecordSelectorProps, SuggesterFilters } from './RecordSelector.types'

import SearchInput from 'happitu/src/components/Fields/SearchInput'
import FieldIcon from 'happitu/src/components/Icons/FieldIcon'
import Scrollable from 'happitu/src/components/Layout/Scrollable'
import VoidCallout from 'happitu/src/components/Layout/VoidCallout'
import { SelectOptionChild } from 'happitu/src/components/Select'
import { isUserOrContactRecord, isRecordWithName } from 'happitu/src/helpers/userHelpers'
import { useSuggester, BaseSuggestion } from 'happitu/src/models/searchSuggester'
import { isImpressionable } from 'happitu/src/models/searchSuggester/searchSuggester.helpers'

type UnknownSuggestion = BaseSuggestion | null | StoreRecord

const normalizeValue = (value?: string | string[] | null) => {
  if (typeof value === 'string') return [value]
  if (Array.isArray(value)) return value
  return []
}

const getLabel = (suggestion?: UnknownSuggestion): string | null => {
  if (!suggestion) return null
  if (isUserOrContactRecord(suggestion))
    return `${suggestion.firstName} ${suggestion.lastName}`
  if (isRecordWithName(suggestion)) return `${suggestion.name || ''}`
  return null
}

const RecordSelector = ({
  createPlaceholder = 'Create "%s"',
  filters = {},
  ...props
}: RecordSelectorProps) => {
  const searchPlaceholder = useMemo(
    () => props.searchPlaceholder || `Find ${startCase(props.collectionName)}`,
    [props.collectionName, props.searchPlaceholder],
  )
  const value = normalizeValue(props.value)
  const listRef = useRef(null)
  const searchRef = useRef<HTMLInputElement>(null)
  const useImpression = isImpressionable(props.collectionName)

  let isUniqueTerm = true

  const filterOpts: { [key: string]: ID[] | ID | undefined | boolean } = {}

  Object.keys(filters).map((filter: keyof SuggesterFilters) => {
    const newKey = `filter[${filter}]`
    filterOpts[newKey] = filters[filter]
  })

  const { inputProps, suggestions, onScroll } = useSuggester(props.collectionName, {
    includeRecords: true,
    ...filterOpts,
  })

  const getValue = (record: BaseSuggestion) =>
    `${useImpression ? record.impressionId : record.id}`

  const displayCreateOption = () =>
    typeof props.onCreate === 'function' && isUniqueTerm && !!inputProps.value.trim()

  const Children = useMemo((): SelectOptionChild[] => {
    const options: SelectOptionChild[] = []
    for (const suggestion of suggestions) {
      if (typeof filters.active === 'boolean' && suggestion.active === false) continue
      const label = getLabel(suggestion)
      const value = getValue(suggestion)
      if (label && label.toLowerCase() === inputProps.value.toLowerCase())
        isUniqueTerm = false

      options.push(
        <Option
          checkbox={props.multiple}
          label={label}
          highlight={
            props.highlightOptions ? props.highlightOptions.includes(value) : false
          }
          value={value}
          key={getValue(suggestion)}
        >
          {props.icon && <FieldIcon {...props.icon} />}
          {label}
        </Option>,
      )
    }
    return options
  }, [suggestions.length, inputProps.value])

  useEffect(() => {
    if (props.autoFocus) searchRef.current?.focus()
  }, [props.autoFocus, searchRef])

  return (
    <div className={props.className}>
      <SearchInput
        ref={searchRef}
        className="search"
        placeholder={searchPlaceholder}
        {...inputProps}
      />
      <Scrollable
        innerRef={listRef}
        preventParentScrolling
        className="list"
        onScroll={onScroll}
        tabIndex={0}
      >
        <SelectableListProvider
          ref={listRef}
          onSelect={props.onSelect}
          onCreate={
            props.onCreate
              ? () => {
                  props.onCreate && props.onCreate(inputProps.value)
                }
              : undefined
          }
          onHover={props.onHover}
          value={value}
          multiple={props.multiple}
          autoFocus={false}
        >
          {displayCreateOption() && (
            <CreateOption
              label={createPlaceholder.replace(/%s/g, inputProps.value || '')}
              value={CREATE_ACTION}
            />
          )}
          {Children.length ? (
            Children
          ) : (
            <VoidCallout key="callout">No Results</VoidCallout>
          )}
        </SelectableListProvider>
      </Scrollable>
    </div>
  )
}

export default styled(RecordSelector)`
  display: grid;
  grid-template-rows: min-content 1fr;
  max-height: 50vh;
  min-height: 100px;
  min-width: 300px;

  ${VoidCallout} {
    height: 100px;
  }

  > .search {
    margin: 1em;
    margin-bottom: 0;
    position: relative;
    z-index: 1;
  }

  .list {
    padding: 1em;

    :focus {
      outline: none;
    }
  }

  .loading {
    align-items: center;
    display: flex;
    height: 100px;
    justify-content: center;
    position: relative;
  }
`
