import { Editor } from 'slate'

import { isUrl, setLink } from '../formattingHelper'

import { ElementType } from 'happitu/src/types/slate-types'

const withLinks = (editor: Editor) => {
  const { insertData, isInline } = editor

  editor.isInline = (element) => {
    return element.type === ElementType.Link ? true : isInline(element)
  }

  // Handle pasting links.
  editor.insertData = (data: DataTransfer) => {
    const text = data.getData('text/plain')
    if (text && isUrl(text)) {
      setLink(editor, text)
    } else {
      // Fallback on default behavior
      insertData(data)
    }
  }

  return editor
}

export default withLinks
