import styled from 'styled-components'

import { color, font } from 'theme'

export default styled.h3`
  border-bottom: 1px solid ${ color('border') };
  font-size: ${ font('size', 'small') };
  font-weight: ${ font('weight', 'medium') };
  padding: .5em 0;
  text-align: center;
  display: block;
  color: ${ color('grey', 'light') };
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-transform: uppercase;
`
