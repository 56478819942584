import autobind from 'autobind-decorator'
import classNames from 'classnames'
import update from 'immutability-helper'
import { isArray } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import shallowEqual from 'shallowequal'

import styles from './Dropdown.scss'
import { DropdownInput, DisplayValue } from './Dropdown.styles'
import DropdownOption from './Dropdown/DropdownOption'
import ValueMarker from './Dropdown/DropdownValueMarker'

import Icon from 'happitu/src/components/Icon'
import Menu, { MenuList } from 'happitu/src/components/_DEPRECATED/_DEPRECATED_Menu'

const LABEL = 1
const VALUE = 0
export const BLANK_VALUE = [['', '']]
const HOT_KEYS = [' ', 'ArrowUp', 'ArrowDown', 'Enter']

export const getSelection = (props, value) => {
  let predicate

  if (isArray(value) && value.length > 0) {
    predicate = (option) => value.indexOf(option[VALUE]) > -1
  }

  if (typeof value === 'string' || value === 'number') {
    predicate = (option) => value === option[VALUE]
  }

  if (predicate) {
    const result = props.options.filter(predicate)
    return result.length === 0 ? BLANK_VALUE : result
  }

  return BLANK_VALUE
}

const getHover = (props, value) =>
  value ? getSelection(props, value)[0][VALUE] : props.options[0][VALUE]

export default class Dropdown extends Component {
  static propTypes = {
    canReset: PropTypes.bool,
    className: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    queryTimeout: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    menuWidth: PropTypes.number,
  }

  static defaultProps = {
    canReset: false,
    onChange: () => {},
    queryTimeout: 200,
  }

  /* eslint-disable react/sort-comp */

  getCurrentValue = () => this.props.value || this.props.defaultValue

  /* eslint-enable */

  state = {
    displayValues: [],
    hiddenValues: '',
    hover: getHover(this.props, this.getCurrentValue()),
    isFocused: false,
    isOpen: false,
    selection: getSelection(this.props, this.getCurrentValue()),
  }

  _values = []
  _optionQuery = ''

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.selection[VALUE]) {
      const nextValue = props.value || props.defaultValue
      const selection = getSelection(props, nextValue)
      const hover = state.hover ? state.hover : getHover(props, selection)
      return {
        selection,
        hover,
      }
    }

    return null
  }

  componentDidMount() {
    this.setDisplayValue()
  }

  componentWillUnmount() {
    this.unbindEventListeners()
    clearTimeout(this._queryTimeout)
  }

  componentDidUpdate(prevProps) {
    if (!shallowEqual(prevProps.value, this.props.value)) {
      this.setDisplayValue()
    }
  }

  setDisplayValue() {
    const wrapperWidth = this._wrapper.offsetWidth
    const values = this._values.filter((value) => value !== null)

    let valuesWidth = this._indicator.offsetWidth
    let displayValues = []
    let hiddenValues = []

    // Always display the first value. Even if it is wider than the wrapper width.
    if (values.length === 1) {
      displayValues.push(this.state.selection[0][LABEL])
    } else {
      values.forEach((value, i) => {
        if (valuesWidth + value.offsetWidth > wrapperWidth) {
          hiddenValues.push(this.state.selection[i][LABEL])
        } else {
          valuesWidth += value.offsetWidth
          displayValues.push(this.state.selection[i][LABEL])
        }
      })
    }

    this.setState({
      displayValues: displayValues,
      hiddenValues: hiddenValues.join(', '),
    })
  }

  @autobind
  unbindEventListeners() {
    document.removeEventListener('keyup', this.handleKeyUp)
    document.removeEventListener('keydown', this.handleKeyDown)
    document.removeEventListener('click', this.handleDocBlur)
  }

  @autobind
  handleChange(selectedValue) {
    const { defaultValue, multiple, onChange, value } = this.props

    if (multiple) {
      const currentValue = value || defaultValue || []
      const index = currentValue.indexOf(selectedValue)
      return index > -1
        ? // Remove selected value if it has been selected already.
          onChange(update(currentValue, { $splice: [[index, 1]] }))
        : // Append value if it hasn't been.
          onChange([...currentValue, selectedValue])
    } else if (selectedValue !== value) {
      onChange(selectedValue)
      this.handleBlur(true)
      this._input.focus()
    } else {
      this._input.focus()
    }
  }

  @autobind
  handleFocus(e) {
    document.addEventListener('keyup', this.handleKeyUp)
    document.addEventListener('keydown', this.handleKeyDown)
    document.addEventListener('click', this.handleDocBlur)
    this.setState({ isFocused: true })
    if (this.props.onFocus) this.props.onFocus(e)
  }

  @autobind
  handleHover(value) {
    this.setState({ hover: value })
  }

  @autobind
  handleReturn() {
    if (this.state.isOpen) {
      if (this.state.hover !== this.props.value) {
        this.handleChange(this.state.hover)
      } else if (!this.props.multiple) {
        this.handleBlur(true)
      }
    } else {
      this.handleMenuOpen()
    }
  }

  @autobind
  handleKeyUp(e) {
    if (e.which === 27 && this.state.isOpen) {
      e.preventDefault()
      e.stopPropagation()
      this.handleBlur(true)
    }
  }

  @autobind
  handleKeyDown(e) {
    if (HOT_KEYS.indexOf(e.key) > -1) {
      e.preventDefault()
      e.stopPropagation()
    }
    switch (e.key) {
      case 'Tab':
        return this.handleBlur()

      case 'Enter':
      case ' ':
        return this.handleReturn()

      case 'ArrowUp':
        this.handleMenuOpen()
        return this.selectPrevious()

      case 'ArrowDown':
        this.handleMenuOpen()
        return this.selectNext()
    }

    if (e.key.length === 1) {
      this.findOptionByLabel(e.key)
    }
  }

  @autobind
  handleMenuClose() {
    this.handleBlur()
  }

  @autobind
  handleDocBlur() {
    this.setState({
      isFocused: false,
    })
    this.unbindEventListeners()
  }

  @autobind
  handleBlur(keepFocus = false) {
    this.setState({
      isOpen: false,
      isFocused: keepFocus,
    })

    if (this.props.onBlur) this.props.onBlur()

    if (!keepFocus) {
      this.unbindEventListeners()
    }
  }

  @autobind
  handleMenuOpen(e) {
    if (this.state.isOpen) {
      return
    }
    if (e) {
      e.stopPropagation()
    }

    this._input.focus()
    this.setState({ isOpen: true })
  }

  handleInputRef = (r) => (this._input = r)
  handleValueRef = (r, index) => (this._values[index] = r)
  handleWrapperRef = (r) => (this._wrapper = r)
  handleIndicatorRef = (r) => (this._indicator = r)

  @autobind
  findOptionByLabel(key) {
    clearTimeout(this._queryTimeout)
    this._optionQuery += key
    const option = this.props.options.find((o) =>
      new RegExp('^' + this._optionQuery, 'i').test(o[LABEL]),
    )
    if (option) {
      this.handleHover(option[VALUE])
    }

    this._queryTimeout = setTimeout(() => {
      this._optionQuery = ''
    }, this.props.queryTimeout)
  }

  @autobind
  selectNext() {
    const { options } = this.props
    const index = options.findIndex((o) => o[VALUE] === this.state.hover)

    return index + 1 === options.length
      ? this.handleHover(options[0][VALUE])
      : this.handleHover(options[index + 1][VALUE])
  }

  @autobind
  selectPrevious() {
    const { options } = this.props
    const index = options.findIndex((o) => o[VALUE] === this.state.hover)

    return index === 0
      ? this.handleHover(options[options.length - 1][VALUE])
      : this.handleHover(options[index - 1][VALUE])
  }

  isActive(optionValue) {
    const value = this.props.value || this.props.defaultValue
    return isArray(value) ? value.indexOf(optionValue) > -1 : optionValue === value
  }

  renderEmpty() {
    if (this.props.canReset && (this.props.value || this.props.defaultValue)) {
      return (
        <DropdownOption
          isHovering={this.state.hover === ''}
          label={
            <i>
              <Icon type="close" /> Clear value
            </i>
          }
          onClick={this.handleChange}
          onHover={this.handleHover}
          value=""
        />
      )
    }
  }

  renderMenu() {
    const { menuWidth, options, multiple } = this.props
    return (
      <Menu
        width={menuWidth}
        isOpen={this.state.isOpen}
        onClose={this.handleMenuClose}
        anchorRef={this._wrapper}
      >
        <MenuList maxHeight="300">
          {this.renderEmpty()}
          {options.map((o, k) => (
            <DropdownOption
              isHovering={this.state.hover === o[VALUE]}
              isSelected={this.isActive(o[VALUE])}
              key={k}
              label={o[LABEL]}
              subLabel={o[2]}
              multiple={multiple}
              onClick={this.handleChange}
              onHover={this.handleHover}
              value={o[VALUE]}
            />
          ))}
        </MenuList>
      </Menu>
    )
  }

  renderGhostedValue(selection) {
    return (
      <div style={{ whiteSpace: 'nowrap' }}>
        {selection.map((v, index) => (
          <ValueMarker
            index={index}
            key={index}
            label={index + 1 === selection.length ? v[LABEL] : v[LABEL] + ', '}
            onRef={this.handleValueRef}
          />
        ))}
      </div>
    )
  }

  renderValue() {
    return this.state.displayValues.join(', ')
  }

  renderIndicator() {
    const { selection, displayValues, hiddenValues } = this.state

    if (selection.length > 1 && selection.length !== displayValues.length) {
      return (
        <span title={hiddenValues}>
          {' '}
          & {selection.length - displayValues.length} more
        </span>
      )
    }
  }

  render() {
    const { selection } = this.state

    const inputClasses = classNames(styles.input, this.props.className)

    return (
      <div className={inputClasses}>
        {this.renderGhostedValue(selection)}
        <DropdownInput
          as="div"
          focus={this.state.isFocused || this.state.isOpen}
          onClick={this.handleMenuOpen}
          role="dropdown"
          ref={this.handleWrapperRef}
        >
          <input
            onFocus={this.handleFocus}
            placeholder={this.props.placeholder}
            readOnly
            ref={this.handleInputRef}
            type="text"
            value={this.renderValue(selection)}
          />
          <DisplayValue>{this.renderValue(selection)}</DisplayValue>
          <span ref={this.handleIndicatorRef}>{this.renderIndicator()}</span>
          <div className={styles.arrow} role="arrow" />
        </DropdownInput>
        {this.renderMenu()}
      </div>
    )
  }
}
