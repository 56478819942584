import { SuperAgentRequest } from 'superagent'

import request from './request'
import { handleReject, handleSuccess } from './responseHandler'

interface GetTicketsResponse {
  contacts: ContactRecord[]
  pagination: PaginationObject
  tickets: TicketRecord[]
  users: UserRecord[]
}

let getTicketsRequestHandler: SuperAgentRequest | null = null
export const getTicketsRequest = (
  ticketQuery: {
    limit: number
  } & Partial<PaginationObject>,
) => {
  return new Promise<GetTicketsResponse>((resolve, reject) => {
    if (getTicketsRequestHandler) {
      getTicketsRequestHandler.abort()
    }
    getTicketsRequestHandler = request.post('/ticket-search').send(ticketQuery)
    getTicketsRequestHandler
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(r))
      .finally(() => (getTicketsRequestHandler = null))
  })
}

interface GetTicketResponse {
  tickets: TicketRecord[]
  ticketInteractions: TicketInteractionRecord[]
  contacts?: ContactRecord[]
  websocketToken?: string
  roomId?: string
}

export const getTicketRequest = (id: ID) => {
  return new Promise<GetTicketResponse>((resolve, reject) => {
    return request
      .get(`/tickets/${id}`)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r)))
  })
}

export const createTicketRequest = (ticket: Partial<TicketRecord>) => {
  return new Promise<GetTicketResponse>((resolve, reject) => {
    return request
      .post('/tickets')
      .send(ticket)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r)))
  })
}

export const updateTicketRequest = (ticketId: ID, ticket: Partial<TicketRecord>) => {
  return new Promise<{ tickets: TicketRecord[] }>((resolve, reject) => {
    return request
      .put(`/tickets/${ticketId}`)
      .send(ticket)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r)))
  })
}
