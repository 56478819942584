import isHotkey from 'is-hotkey'
import React, { useState } from 'react'
import { Descendant, Range, Editor, Transforms, Location } from 'slate'
import { Editable, Slate } from 'slate-react'

import HoveringToolbar from '../slate/hover-toolbar'
import VariableSuggester from '../slate/variable-suggester'

import ErrorBoundary from 'happitu/src/components/ErrorBoundary'
import { defaultHotkeys, Element, Leaf } from 'happitu/src/components/RichTextEditor'
import withHtml from 'happitu/src/helpers/editor/plugins/with-html'
import withWorkflowVariables, {
  insertVariable,
} from 'happitu/src/helpers/editor/plugins/withWorkflowVariables'
import useEditor from 'happitu/src/hooks/useEditor'

interface Props {
  value: Descendant[]
  onChange: (value: Descendant[]) => void
  placeholder?: string
}

const InlineEditor = (props: Props) => {
  const editor = useEditor([withWorkflowVariables, withHtml])
  const [target, setTarget] = useState<Range | null>()
  const [index, setIndex] = useState(0)
  const [search, setSearch] = useState('')

  const renderElement = React.useCallback(
    (props) => <Element {...props} draggable={false} />,
    [],
  )
  const renderLeaf = React.useCallback(
    (props) => (
      <span {...props.attributes}>
        <Leaf leaf={props.leaf}>{props.children}</Leaf>
      </span>
    ),
    [],
  )

  const change = (value: Descendant[]) => {
    props.onChange(value)
    const { selection } = editor
    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection)
      const wordBefore = Editor.before(editor, start, { unit: 'word' })
      const before = wordBefore && Editor.before(editor, wordBefore)
      const beforeRange = before && Editor.range(editor, before, start)
      const beforeText = beforeRange && Editor.string(editor, beforeRange)
      const beforeMatch = beforeText && beforeText.match(/^:(\w+)$/)
      const after = Editor.after(editor, start)
      const afterRange = Editor.range(editor, start, after)
      const afterText = Editor.string(editor, afterRange)
      const afterMatch = afterText.match(/^(\s|$)/)
      if (/:(\w+)?$/.test(beforeText || '') && afterMatch) {
        setTarget(beforeRange)
        setSearch(beforeMatch ? beforeMatch[1] : '')
        setIndex(0)
        return
      }
      setTarget(null)
    }
  }

  const keydown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    for (const hotkey in defaultHotkeys) {
      if (isHotkey(hotkey, e.nativeEvent)) {
        e.preventDefault()
        const action = defaultHotkeys[hotkey]
        action(editor)
      }
    }
  }

  return (
    <Slate editor={editor} value={props.value} onChange={change}>
      <ErrorBoundary>
        <Editable
          placeholder={props.placeholder || 'Click here to give some direction...'}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={keydown}
          onBlur={() => {
            setSearch('')
            setTarget(null)
          }}
          spellCheck
        />
        <HoveringToolbar />
        <VariableSuggester
          editor={editor}
          target={target}
          activeIndex={index}
          search={search}
          onSelect={(value) => {
            Transforms.select(editor, target as Location)
            insertVariable(editor, value)
            setTarget(null)
          }}
        />
      </ErrorBoundary>
    </Slate>
  )
}

export default InlineEditor
