import { format } from 'date-fns'
import { last } from 'lodash'
import React from 'react'

import DateRangeSelect, { DateRange } from 'happitu/src/components/Fields/DateRangeSelect'
import { FieldFigure, FieldWrapper } from 'happitu/src/components/Fields/FieldUtils'
import { FlexRow } from 'happitu/src/components/_DEPRECATED/Flex'
import { SHORT_HAND_DATE_FORMAT } from 'happitu/src/helpers/dateHelpers'
import { DateRangePickerDisplayProps } from 'happitu/src/types/workflowElements'

const DateRangePicker = (props: DateRangePickerDisplayProps) => {
  const { value, attributes, onBlur, onChange, id, errors, autoFocus } = props
  const lastError = last(errors)

  // NOTE: The values for the date range are stored as [startDate, endDate], and are stored as human-readable values (i.e. '1/1/2020'). DateRange accepts nulls, so attributes.defaultValue is coerced from empty strings.
  const startDate = !!value[0] ? new Date(value[0]) : null
  const endDate = !!value[1] ? new Date(value[1]) : null

  const handleSelect = (dateRange: DateRange | null) => {
    if (!dateRange) {
      const newValue = ['', '']

      onBlur(newValue, id)
      onChange(newValue, id)
      return
    }

    const { startDate, endDate } = dateRange
    const newValue = [
      !!startDate ? format(startDate, SHORT_HAND_DATE_FORMAT) : startDate,
      !!endDate ? format(endDate, SHORT_HAND_DATE_FORMAT) : endDate,
    ]

    onBlur(newValue, id)
    onChange(newValue, id)
  }

  // TODO: Handle item-level validation in the validator.
  // const getErrorMessage = () => {
  //   if (lastError === 'Please enter a valid date range.') {
  //     if (!!startDate) {
  //       return 'Please enter a valid end date.'
  //     }
  //     if (!!endDate) {
  //       return 'Please enter a valid start date.'
  //     }
  //   }
  //   return lastError
  // }

  return (
    <FieldWrapper invalid={!!lastError}>
      <FlexRow>
        <FlexRow>
          <DateRangeSelect
            autoFocus={autoFocus}
            label={attributes.label}
            maxDate={null}
            minDate={null}
            months={1}
            onSelect={handleSelect}
            required={attributes.required}
            staticRanges={null}
            style={{ paddingTop: '0.75em', paddingBottom: '0.75em' }}
            value={{ startDate, endDate }}
          />
        </FlexRow>
      </FlexRow>

      {!!lastError && <FieldFigure value={lastError} />}
    </FieldWrapper>
  )
}

export default DateRangePicker
