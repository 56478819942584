import apiRequest from './request'
import { handleReject, handleSuccess } from './responseHandler'

/*
  Query example

  {
    "query: "locke",
    "fieldParams: {
      "firstName: {
        "useSuggester: true
      },
      "lastName: {
        "useSuggester: true
      },
      "fullName": {},
      "detail.ahimaId": {"boost": "2.0"},
      "*": {
        "useHighlighter": true
      }
    },
    "filters": {
      "verified": "false"
    }
  }
*/

export const getContactRequest = (contactId: ID) =>
  new Promise<{ contacts: ContactRecord[] }>((resolve, reject) =>
    apiRequest
      .get(`/contacts/${contactId}`)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )

export const createContactRequest = (contact: Partial<ContactRecord>) =>
  new Promise<{ contacts: ContactRecord[] }>((resolve, reject) =>
    apiRequest
      .post('/contacts')
      .send(contact)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )

export const updateContactRequest = (id: ID, contact: Partial<ContactRecord>) =>
  new Promise<{ contacts: ContactRecord[] }>((resolve, reject) =>
    apiRequest
      .put(`/contacts/${id}`)
      .send(contact)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )

export const findContactRequest = (field: string, query: string) =>
  new Promise((resolve, reject) =>
    apiRequest
      .post('/contact-search')
      .send({
        fieldParams: {
          [`detail.${field}`]: { boost: '2.0' },
          '*': {
            useHighlighter: true,
          },
        },
        query,
      })
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )
