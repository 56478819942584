import { merge, last } from 'lodash'
import React from 'react'
import shallowEqual from 'shallowequal'

import { ProTip } from './EmailField.styles'
import EmailFieldItem from './EmailFieldItem'

import { NextFieldWrapper, FieldFigure } from 'happitu/src/components/Fields/FieldUtils'
import { delimitedListRegExp, getRenderValue } from 'happitu/src/helpers/emailHelper'
import useTicketFieldAutofocus from 'happitu/src/hooks/useTicketFieldAutoFocus'
import { EmailFieldDisplayProps } from 'happitu/src/types/workflowElements'

interface FieldError {
  [key: number]: string
}

export const multiEmailTip = (
  <ProTip>
    <strong>Pro tip: </strong>Enter multiple emails at once by separating them with commas
    or semi-colons
  </ProTip>
)

export default function EmailField({
  attributes,
  autoFocus,
  errors,
  forceErrors,
  id,
  onBlur,
  validationAttempts,
  ...props
}: EmailFieldDisplayProps) {
  const [value, setValue] = React.useState<string[]>(props.value)
  const inputWrapper = useTicketFieldAutofocus<HTMLLabelElement>(
    autoFocus,
    validationAttempts,
    errors,
  )
  const [focusIndex, setFocusIndex] = React.useState<number | null>(null)
  const ref = React.useRef<HTMLDivElement>(null)

  // Assume string errors are component level, and object errors are field level
  const lastComponentError = last(
    errors.filter((error) => typeof error === 'string'),
  ) as string
  const fieldErrors = errors.reduce((acc, err) => {
    if (typeof err === 'object') {
      merge(acc, err)
    }
    return acc
  }, {}) as FieldError
  const showError = forceErrors && !!lastComponentError

  React.useEffect(() => {
    if (!!Object.keys(fieldErrors).length && autoFocus) {
      setFocusIndex(parseInt(Object.keys(fieldErrors)[0]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationAttempts])

  React.useEffect(() => {
    if (focusIndex !== null) {
      if (ref.current !== null) {
        const inputs = ref.current.querySelectorAll<HTMLInputElement>('input')
        if (inputs[focusIndex]) {
          inputs[focusIndex].focus()
          setFocusIndex(null)
        }
      }
    }
  }, [value, focusIndex])

  React.useEffect(() => {
    if (!shallowEqual(props.value, value)) {
      setValue(props.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value])

  const handleChange = (val: string, index: number) => {
    const newValue = [...value]
    newValue.splice(index, 1, val)
    setValue(newValue)
  }

  const handleBlur = (val: string, index: number) => {
    const newValue = [...value]
    if (attributes.allowMultiEmail) {
      if (newValue.length > 1 && index < newValue.length - 1 && val === '') {
        newValue.splice(index, 1)
      } else if (delimitedListRegExp.test(val)) {
        // Allow delimited emails
        const newVal = val
          .trim()
          .split(delimitedListRegExp)
          .filter((val) => val)
          .map((val) => val.replace(/.+<(.+)>/, (_, email) => email))
        newValue.splice(index, 1, ...newVal)

        // When entering multiple emails at once (comma/semicolon-delimited), set focus on the last email created
        const newFocusIndex = newValue.length - value.length + index
        setFocusIndex(newFocusIndex)
      }
    }
    setValue(newValue)
    onBlur(newValue, id)
  }

  const handleDelete = (index: number) => {
    const newValue = [...value]
    if (index === 0 && newValue.length === 1) {
      newValue.splice(index, 1, '')
    } else {
      newValue.splice(index, 1)
    }
    onBlur(newValue, id)
  }

  const renderValue = getRenderValue(value, attributes.allowMultiEmail)

  React.useEffect(() => {
    if (attributes.allowMultiEmail && value.length === 1 && autoFocus) {
      // This is needed because we have a ghost field that would otherwise succumb to a life of focus thievery
      setFocusIndex(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <NextFieldWrapper invalid={showError} ref={ref}>
      {attributes.allowMultiEmail && multiEmailTip}
      {renderValue.map((val, index) => {
        return (
          <EmailFieldItem
            allowMultiEmail={attributes.allowMultiEmail}
            disabled={renderValue[0] === '' && index === 1}
            emailValue={val}
            fieldError={fieldErrors[index]}
            forceErrors={forceErrors}
            index={index}
            key={index}
            label={attributes.label}
            onBlur={handleBlur}
            onChange={handleChange}
            onDelete={handleDelete}
            placeholder={attributes.placeholder}
            ref={inputWrapper}
            required={attributes.required}
            totalIndex={renderValue.length}
          />
        )
      })}
      {showError && <FieldFigure value={lastComponentError} />}
    </NextFieldWrapper>
  )
}
