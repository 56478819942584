import React, { FocusEvent, useState } from 'react'
import styled from 'styled-components'

import { StyledInput } from './num-input.styles'
import { NumInputProps } from './num-input.types'

import { error } from 'happitu/src/helpers/loggerHelper'

interface Value {
  current: number | null
  previous: number
}

// TODO: Check that the initial value isn't beyond the provided min/max range.

// NOTE: This component controls its own state so it can handle the logic for 'allowBlank'. We save the value with onCommit.
// We could factor out the 'allowBlank' logic into a separate hook if needed.
const NumInput = ({
  allowBlank = true,
  initialValue = null,
  placeholder = 'Enter a value',
  ...props
}: NumInputProps) => {
  // Verify that the max value isn't less than the min value.
  if (
    typeof props.min !== 'undefined' &&
    typeof props.max !== 'undefined' &&
    props.max < props.min
  ) {
    error(
      `A number input was passed a min of ${props.min} and a max of ${props.max}. The max value cannot be less than the min value.`,
    )
  }

  const [value, setValue] = useState<Value>({
    current: allowBlank ? initialValue : initialValue ?? 0,
    previous: initialValue ?? 0,
  })

  const ref = React.useRef<HTMLInputElement>(null)

  // Pass the new current value, and the previous value is updated with the old value.
  const updateValue = (newValue: number | null) => {
    setValue({
      previous: value.current ?? initialValue ?? 0,
      current: newValue,
    })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Make sure the new value isn't an empty string.
    if (!!e.target.value) {
      const newValue = +e.target.value
      if (typeof props.min !== 'undefined' && newValue < props.min) {
        e.preventDefault()
      } else if (typeof props.max !== 'undefined' && newValue > props.max) {
        // Prevent user input outside valid dates.
        e.preventDefault()
      } else {
        updateValue(newValue)
        typeof props.onChange === 'function' && props.onChange(newValue)
      }
    } else {
      updateValue(null)
      typeof props.onChange === 'function' && props.onChange(null)
    }
  }

  // Maybe fall back on the initial value instead?
  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (!allowBlank && value.current === null) {
      // If we want to prevent empty values, reset the value to the previous value.
      updateValue(value.previous)
    }
    // Save the value.
    const newValue = allowBlank ? value.current : value.current ?? value.previous
    props.onCommit(newValue)

    typeof props.onBlur === 'function' && props.onBlur(e)
  }

  const handleNumInputKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Prevent decimals from being entered.
    if (['.', 'e', '+'].includes(e.key)) {
      e.preventDefault()
    }

    // Prevent negative numbers from being entered if min isn't negative.
    if (typeof props.min !== 'undefined' && props.min >= 0 && e.key === '-') {
      e.preventDefault()
    }
  }

  const blockWheel = (e: WheelEvent) => {
    e.preventDefault()
  }

  // Prevent the number inputs from incrementing and decrementing on wheel.
  React.useEffect(() => {
    const refInstance = ref.current
    refInstance?.addEventListener('wheel', blockWheel)
    return () => {
      refInstance?.removeEventListener('wheel', blockWheel)
    }
  }, [])

  // Update the input with changes to the initial value.
  React.useEffect(() => {
    setValue({
      current: allowBlank ? initialValue : initialValue ?? 0,
      previous: initialValue ?? 0,
    })
  }, [initialValue])

  return (
    <StyledInput
      className={props.className}
      disabled={props.disabled}
      inputMode="numeric"
      max={props.max}
      min={props.min}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyDown={handleNumInputKeydown}
      placeholder={placeholder}
      ref={ref}
      type="number"
      value={value.current?.toString() || ''} // Convert to string to remove leading zeros.
    />
  )
}

export default styled(NumInput)``
