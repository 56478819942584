import update from 'immutability-helper'
import { get, merge, orderBy, uniq } from 'lodash'

const defaultConfig: StoreConfig = { key: 'id', relations: [] }

export default class Store<T extends StoreRecord> implements StoreInterface<T> {
  public readonly initialized = false
  public readonly version = 0
  public readonly length = 0

  /* tslint:disable:variable-name */
  public readonly _root: StoreRoot
  public readonly _byId: ReadonlyArray<ID> = []
  public readonly _byImpression: { [id: string]: ID }
  public readonly _filteredIds: ReadonlyArray<ID> = []
  public readonly _removedIds: ReadonlyArray<ID> = []
  public readonly _relationTree: RelationTree = {}

  public readonly _config: StoreConfig
  /* tslint:enable:variable-name */

  constructor(config: Partial<StoreConfig> = {}) {
    this._relationTree = initializeRelationTree(config)
    return makeStore(
      0,
      [],
      {},
      {},
      [],
      [],
      this._relationTree,
      0,
      { ...defaultConfig, ...config },
      false,
    )
  }

  public set(key?: string, value?: Record<string, any>): this {
    if (!key || !value) {
      throw new Error(`set: Invalid key: ${key}`)
    }
    return updateRecord(this, key, value)
  }

  public setMany(values?: Record<string, any>[], callback?: Function): this {
    if (values) {
      return updateRecords(this, values, callback)
    }
    return this
  }

  public update(id?: string, next?: Record<string, any>) {
    if (!id || !next) return this
    const record = this.findById(id)
    if (!record) {
      throw new Error(`update: Could not find record: ${id}`)
    }
    return this.set(id, { ...next, _errors: [] })
  }

  public updateDeep(id: string, next: StoreRecord): this {
    const record = this.findById(id)
    if (!record) {
      throw new Error(`updateDeep: Could not find record: ${id}`)
    }
    return updateDeepRecord(this, id, { ...next, _errors: [] })
  }

  public updateWithErrors(id?: string, keys?: Record<string, any>[] | string[]) {
    if (!id || !keys) return this
    const record = this.findById(id)
    if (!record) {
      throw new Error(`updateWithErrors: Could not find record: ${id}`)
    }
    return this.set(id, {
      _errors: [...(record._errors || []), ...keys],
    })
  }

  public remove(id?: string): this {
    if (id) return removeRecord(this, id)
    return this
  }

  public markForRemoval(id: string) {
    return this.update(id, { removing: 1 })
  }

  public rollbackRemoval(id?: string) {
    return this.update(id, { removing: 2 })
  }

  public toArray<R extends StoreRecord>(useFiltered = false): R[] {
    return (useFiltered ? this._filteredIds : this._byId).map((id: string) =>
      this._findByIdSafe(id),
    )
  }

  public map<U>(callback: (value: T, index: number) => U, useFiltered = false): U[] {
    return (useFiltered ? this._filteredIds : this._byId).map(
      (id: string, index: number) => {
        return callback(this._findByIdSafe<T>(id), index)
      },
    )
  }

  public reduce<U>(
    callback: (previousValue: U, record: T, index: number) => U,
    initialValue: U,
  ) {
    return this._byId.reduce((previousValue, id, index) => {
      return callback(previousValue, this._findByIdSafe(id), index)
    }, initialValue)
  }

  public forEach<R extends StoreRecord>(
    callback: StoreForEachCallback<R>,
    useFiltered = false,
  ) {
    return (useFiltered
      ? this._filteredIds
      : this._byId
    ).forEach((id: string, index: number) => callback(this._findByIdSafe(id), index))
  }

  public find<R extends StoreRecord>(
    key: string,
    value: FilterStoreCallback<R>,
    useFiltered = false,
  ) {
    return (useFiltered ? this._filteredIds : this._byId).find(
      makeCallback<R>(this._root, key, value),
    )
  }

  public findById<R extends StoreRecord>(id?: ID | null): R | undefined {
    if (!id) return
    if (this._root[id]) return this._root[id]
    return
  }

  public findByImpression<R extends StoreRecord>(
    impressionId?: ID | null,
  ): R | undefined {
    if (!impressionId) return
    const id = this._byImpression[impressionId]
    if (this._root[id]) return this._root[id]
    return
  }

  public findAllByRelation<R extends StoreRecord>(
    relation: string,
    relationId: ID,
  ): Array<R | undefined> {
    const relationCollection = this._relationTree[relation]
    if (relationCollection && relationCollection[relationId]) {
      return relationCollection[relationId].map((id) => this._root[id])
    }
    return []
  }

  public setFilter(key: string, value: string): this {
    const results: StoreRecord[] = []
    const condition = makeCallback(this._root, key, value)
    this._byId.forEach((id: string, index: number) => {
      if (condition(id, index)) {
        results.push(this._findByIdSafe(id))
      }
    })
    return filterRecords(this, results)
  }

  public filter<R extends StoreRecord>(key: FilterStoreCallback<R>, value?: string): R[] {
    const results: R[] = []
    const condition = makeCallback<R>(this._root, key, value)
    this._byId.forEach((id: string, index: number) => {
      if (condition(id, index)) {
        results.push(this._findByIdSafe(id))
      }
    })
    return results
  }

  public orderBy(keys: string[], direction: 'asc' | 'desc' = 'asc') {
    return orderBy(this._root, keys, direction)
  }

  public at(index: number) {
    const key = this._byId[index]
    return this._root[key] || null
  }

  public getIds() {
    return this._byId
  }

  public empty() {
    return makeStore(0, [], {}, {}, [], [], this._relationTree, 0, this._config, false)
  }

  public hydrateArray<R extends StoreRecord>(
    ids?: string[] | null,
    hydrateMapCallback: Function = (r: R) => r,
  ): HydratedArray<R> {
    return ids ? ids.map((id) => hydrateMapCallback(this.findById(id))) : []
  }

  public hydrateByImpression<R extends StoreRecord>(
    impressionIds: string[],
    hydrateMapCallback: Function = (r: R) => r,
  ): R[] {
    return impressionIds
      ? impressionIds.map((impresionId) => {
          const id = this._byImpression[impresionId]
          return hydrateMapCallback(this.findById(id))
        })
      : []
  }

  protected _findByIdSafe<R extends StoreRecord>(id: ID): R {
    return this._root[id]
  }
}

const StorePrototype = Store.prototype

/**
 * Returns a function to be ran on objects in the store.
 */
function makeCallback<R extends StoreRecord>(
  root: StoreRoot,
  key: FilterStoreCallback<R>,
  value: any,
) {
  return (id: string, index: number): boolean => {
    return typeof key === 'function' ? key(root[id], index) : get(root[id], key) === value
  }
}

/**
 * 'Removes' a record from the store, this does not actually remove the record
 * from _root, it instead moves the id from _byId to _removedIds.
 */
function removeRecord<T extends StoreRecord>(map: Store<T>, id: ID) {
  const index = map._byId.indexOf(id)
  const newById = index > -1 ? update(map._byId, { $splice: [[index, 1]] }) : map._byId
  const newByImpression = update(map._byImpression, {
    $unset: [map._root[id].impressionId],
  })
  const newRoot = Object.assign({}, map._root)
  const newLength = newById.length
  // Remove from filteredIds
  const filteredIndex = map._filteredIds.indexOf(id)
  const newFilteredIds =
    filteredIndex > -1
      ? update(map._filteredIds, {
          $splice: [[map._filteredIds.indexOf(id), 1]],
        })
      : map._filteredIds
  const newRemovedIds = update(map._removedIds, { $push: [id] })

  return makeStore(
    newLength,
    newById,
    newByImpression,
    newRoot,
    newFilteredIds,
    newRemovedIds,
    map._relationTree,
    map.version,
    map._config,
  )
}

function initializeRelationTree(config: Partial<StoreConfig>) {
  if (Array.isArray(config.relations)) {
    return config.relations.reduce<RelationTree>((acc, relation) => {
      acc[relation.belongsTo] = {}
      return acc
    }, {})
  }
  return {}
}

/**
 * Initializes a record for the store.
 * This adds a key of '_errors' to the record.
 */
function initializeRecord(v: { _errors?: Record<string, any>[] }, oldV: StoreRecord) {
  return Object.assign(
    {},
    oldV,
    v._errors
      ? v
      : Object.assign({}, v, {
          _errors: [],
        }),
  )
}

/**
 * Updates a record in the store.
 */
function updateRecord<T extends StoreRecord>(
  map: Store<T>,
  k: string,
  v: Record<string, any>,
) {
  const newRemovedIds = map._removedIds
  let newByImperssion
  let newById
  const newFilteredIds = map._filteredIds

  if (map._byId.indexOf(k) === -1) {
    newById = [...map._byId, k]
    newByImperssion = { ...map._byImpression, [get(v, 'impressionId')]: k }
  } else {
    newById = map._byId
    newByImperssion = map._byImpression
  }

  const newRelationTree = makeRelationFromRecord(
    v,
    map._relationTree,
    map._config.relations,
  )
  const newRoot = Object.assign({}, map._root, {
    [k]: initializeRecord(v, map._root[k]),
  })
  const newLength = newById.length

  return makeStore(
    newLength,
    newById,
    newByImperssion,
    newRoot,
    newFilteredIds,
    newRemovedIds,
    newRelationTree,
    map.version,
    map._config,
  )
}

function makeRelationFromRecord(
  record: Record<string, any>,
  relations?: RelationTree,
  relationsConfig?: StoreRelation[],
): RelationTree {
  if (!relations || !relationsConfig) return {}
  return relationsConfig.reduce((acc, relation) => {
    const id = record[relation.key]
    const relationRecord = acc[relation.belongsTo][id]
    const value = record.id
    if (value) {
      if (!relationRecord) {
        acc[relation.belongsTo][id] = [value]
      } else if (relationRecord.indexOf(value) === -1) {
        acc[relation.belongsTo][id] = [...(relationRecord || []), value]
      }
    }
    return acc
  }, relations)
}

/**
 * Updates the store with new records. Has an optional callback that each record is passed to.
 */
function updateRecords<T extends StoreRecord>(
  map: Store<T>,
  records: Record<string, any>[],
  callback: Function = initializeRecord,
) {
  const newRemovedIds = map._removedIds
  let newById = map._byId
  const newByImpression = map._byImpression
  const newRoot = map._root
  const newFilteredIds = map._filteredIds
  const key: string = map._config.key
  let newRelationTree = map._relationTree

  records.forEach((r: { [key: string]: ID }) => {
    newRoot[r[key]] = callback(r, newRoot[r[key]])
    newRelationTree = makeRelationFromRecord(r, newRelationTree, map._config.relations)
    newById = update(newById, { $push: [r[key]] })
    newByImpression[r.impressionId] = r[key]
  })

  newById = uniq(newById)
  const newLength = newById.length

  return makeStore(
    newLength,
    newById,
    newByImpression,
    newRoot,
    newFilteredIds,
    newRemovedIds,
    newRelationTree,
    map.version,
    map._config,
  )
}

/**
 * Deeply updates a record in the store.
 */
function updateDeepRecord<T extends StoreRecord>(
  map: Store<T>,
  k: string,
  v: StoreRecord,
) {
  const newRemovedIds = map._removedIds
  let newById = map._byId
  let newRoot = map._root
  const newFilteredIds = map._filteredIds
  const newV = merge(
    {},
    map._root[k],
    v._errors ? v : Object.assign({}, v, { _errors: [] }),
  )
  const newRelationTree = makeRelationFromRecord(
    v,
    map._relationTree,
    map._config.relations,
  )

  if (map._byId.indexOf(k) === -1) {
    newById = [...map._byId, k]
  } else {
    newById = map._byId
  }

  newRoot = Object.assign({}, map._root, { [k]: newV })

  const newLength = newById.length
  return makeStore(
    newLength,
    newById,
    map._byImpression,
    newRoot,
    newFilteredIds,
    newRemovedIds,
    newRelationTree,
    map.version,
    map._config,
  )
}

/**
 * Creates a new store Record<string, any> with _filteredIds populated from the records param.
 */
function filterRecords<T extends StoreRecord>(map: Store<T>, records: StoreRecord[]) {
  const newById = map._byId
  const newRoot = map._root
  const newLength = map.length
  const newRemovedIds = map._removedIds
  const key: string = map._config.key || 'id'
  const newFilteredIds: string[] = records.map(
    (r: { [index: string]: any }): ID => r[key],
  )
  return makeStore(
    newLength,
    newById,
    map._byImpression,
    newRoot,
    newFilteredIds,
    newRemovedIds,
    map._relationTree,
    map.version,
    map._config,
  )
}

/**
 * Creates a new Store Record<string, any>.
 */
function makeStore(
  length: number,
  byId: ReadonlyArray<ID>,
  byImpression: Record<string, any>,
  root: StoreRoot,
  filteredIds: ReadonlyArray<ID>,
  removedIds: ReadonlyArray<ID>,
  relationTree: RelationTree,
  version = 0,
  config: StoreConfig,
  initialized = true,
) {
  const store = Object.create(StorePrototype)
  store._byId = byId
  store._byImpression = byImpression
  store._root = root
  store._filteredIds = filteredIds
  store.initialized = initialized
  store.length = length
  store.version = version + 1
  store._relationTree = relationTree
  store._removedIds = removedIds
  store._config = config
  return store
}
