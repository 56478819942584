import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import getStyle from 'happitu/src/getStyle'
import { metric } from 'theme'

const MenuListItem = styled.li<{ disabled?: boolean }>`
  align-items: center;
  background: none;
  border-radius: ${metric('largeBorderRadius')};
  color: ${getStyle('text-default-color')};
  cursor: pointer;
  display: flex;
  margin: 0 0.5rem;
  padding: 0.75rem;
  padding-right: 1em;

  ${ifProp(
    'disabled',
    css`
      background: ${getStyle('selectOption-disabled-background')};
      color: ${getStyle('selectOption-disabled-color')};
      cursor: not-allowed;
      pointer-events: none;
    `,
  )}

  &:hover, &:active {
    background: ${getStyle('selectOption-focused-background')};
    color: ${getStyle('text-default-color')};
  }
`

export default MenuListItem
