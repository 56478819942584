import * as React from 'react'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import MenuListItem from './MenuListItem'

import getStyle from 'happitu/src/getStyle'
import useHotkey, { Hotkey } from 'happitu/src/hooks/useHotkey'
import * as theme from 'happitu/src/themes/default/menu'
import { color } from 'theme'

interface Props {
  active?: boolean
  children: React.ReactNode
  disabled?: boolean
  hotkey?: Hotkey
  hover?: boolean
  multiple?: boolean
  noHover?: boolean
  noWrap?: boolean
  onClick?: React.MouseEventHandler
  onMouseEnter?: React.MouseEventHandler
  type?: 'warn'
}

const hoverStyles = (props: Props) => {
  if (props.noHover) {
    return
  }

  // Parent component controls hover state
  if (typeof props.hover !== 'undefined') {
    return (
      props.hover &&
      css`
        ${ifProp(
          { type: 'warn' },
          css`
            background: ${color('red')};
            color: white;

            > * {
              color: white;
            }
          `,
          css`
            background: ${props.active
              ? getStyle('text-active-background')
              : getStyle('selectOption-focused-background')};
          `,
        )}
      `
    )
  }

  if (props.active || props.disabled) {
    return
  }

  return css`
    &:hover {
      ${ifProp(
        { type: 'warn' },
        css`
          background: ${color('red')};
          color: white;

          > * {
            color: white;
          }
        `,
        css`
          background: ${getStyle('selectOption-focused-background')};
        `,
      )}
    }
  `
}

const Wrapper = styled(MenuListItem)<Props>`
  cursor: pointer;
  user-select: none;
  opacity: ${ifProp('disabled', 0.5, 1)};

  ${ifProp(
    { type: 'warn' },
    css`
      color: ${color('red')};
    `,
  )}

  ${(props) =>
    props.noWrap &&
    `
    white-space: nowrap;
  `}

  ${(props) =>
    props.active &&
    css`
      background: ${getStyle('text-active-background')};
      color: ${getStyle('text-active-color')};
      cursor: ${props.multiple ? 'pointer' : 'default'};
      font-weight: ${theme.activeFontWeight};

      * {
        color: ${getStyle('text-active-color')} !important;
      }
    `}

  ${hoverStyles}

  [role="deselect"] {
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }

  &:active {
    background: ${getStyle('selectOption-focused-background')};
    color: ${getStyle('text-default-color')};

    [data-icon] {
      color: ${getStyle('text-default-color')};
    }

    [role='deselect'] {
      color: ${theme.activeColor};
    }
  }
`

export type Ref = HTMLLIElement

const MenuListItemAction = (
  { hotkey, onClick, ...props }: Props,
  ref: React.RefObject<HTMLLIElement>,
) => {
  if (hotkey && onClick) {
    useHotkey(hotkey, onClick, props.disabled !== true)
  }
  const handleClick = (e: React.MouseEvent<HTMLLIElement>) => {
    if (onClick && props.disabled !== true) onClick(e)
  }
  return <Wrapper {...props} onClick={handleClick} ref={ref} />
}

export default React.forwardRef<Ref, Props>(MenuListItemAction)
