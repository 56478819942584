import PropTypes from 'prop-types'
import React  from 'react'

import {
  Header,
  StackTrace,
  SubHeader,
  Wrapper,
} from './ErrorPage.styles'

const ErrorPage = ({ code, title, message, children, stackTrace }) => {
  return (
    <Wrapper>
      <Header>{ code }</Header>
      <SubHeader>{ title }</SubHeader>
      <p>{ message || '>.<' }</p>
      { stackTrace &&
      <StackTrace>
          { stackTrace.map((trace, key) => <span key={ key }>{trace}</span>) }
      </StackTrace>
      }
      { children && <div>{ children }</div> }
    </Wrapper>
  )
}

ErrorPage.propTypes = {
  code: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.object,
  stackTrace: PropTypes.array,
}

export default ErrorPage
