import { SearchDynamicIdentifier } from '../types/searchQueryTypes'

declare global {
  interface Window {
    GeneratedMetadata: GeneratedMetadata
  }
}

export type SearchFieldPresets = Record<string, number> | null

export enum GeneratedFieldType {
  NullableBool = 'bool?',
  Bool = 'bool',
  Id = 'id',
  IdArray = '[]id',
  NA = 'n/a',
  Number = 'number',
  Duration = 'duration',
  NumberArray = '[]number',
  String = 'string',
  StringArray = '[]string',
  Time = 'time',
  TimeArray = '[]time',
  PartialNumber = 'partialNumber',
}

export enum GeneratedCollection {
  Analytics = 'analytics',
  Contacts = 'contacts',
  HelpTopics = 'helpTopics',
  InteractionStats = 'interactionStats',
  Locations = 'locations',
  NotificationHooks = 'notificationHooks',
  Tags = 'tags',
  Teams = 'teams',
  TicketInteractions = 'ticketInteractions',
  Tickets = 'tickets',
  Users = 'users',
  WorkflowActions = 'workflowActions',
  WorkflowElements = 'workflowElements',
  Workflows = 'workflows',
  WorkflowSteps = 'workflowStepGroupSteps',
}

export type SearchServices =
  | 'TicketLookup'
  | 'HelpTopicSearch'
  | 'tags.suggester'
  | 'team.suggester'
  | 'user.suggester'
  | 'workflow.suggester'

export interface SearchDynamic {
  presets?: Record<string, SearchDynamicPreset>
}

export interface SearchDynamicPreset {
  alias: string
  key: string
  type: GeneratedFieldType
}

export interface SearchDocumentField {
  type: GeneratedFieldType
  references?: string
  hasSuggester: boolean
  isInternal: boolean
  isIndexed: boolean
  isNGram: boolean
  presets: SearchFieldPresets
  alias: string
  dynamicIdentifiers?: SearchDynamicIdentifier[]
  dynamic?: SearchDynamic
  defaultBoost?: string
}

export interface SearchDocument {
  collectionName: GeneratedCollection
  title: string
  fields: {
    [key: string]: SearchDocumentField
  }
  embeddedDocuments: {
    [key: string]: SearchDocument
  }
}

interface CollectionConfiguration {
  hasManySearchDocuments: boolean
  hasOneSearchDocument: boolean
  isMany: boolean
  isOptional: boolean
  requiresSearchDocument: boolean
}

export interface GeneratedEvent {
  description: string
  configurations: Array<{
    name: string
    key: string
    description: string
    collectionConfigurations: Record<string, CollectionConfiguration>
  }>
}

export interface GeneratedModelInfoField {
  alias?: string
  defaultBoost: string
  hasSuggester: boolean
  isIndexed: boolean
  isInternal: boolean
  isNGram: boolean
  presets: Record<string, number> | null
  type: GeneratedFieldType
  references?: GeneratedCollection
}

export interface GeneratedModelInfoFieldWithPresets extends GeneratedModelInfoField {
  presets: Record<string, number>
}

export interface GeneratedModelInfoFieldWithReferences extends GeneratedModelInfoField {
  references: GeneratedCollection
}

export interface GeneratedModelInfo {
  collectionName: GeneratedCollection
  embeddedDocuments: string | null
  fields: Record<string, GeneratedModelInfoField>
  multiValued: boolean
}

interface GeneratedMetadata {
  searchServices: Record<
    SearchServices,
    {
      searchDocument: string
    }
  >
  searchDocuments: {
    [key: string]: SearchDocument
  }
  suggesters: {
    [key: string]: SearchDocument
  }
  events: Record<string, GeneratedEvent>
  modelInfo: Record<string, GeneratedModelInfo>
}

export const generatedMetadata = window.GeneratedMetadata

const SOLR_MONGO_FIELD_DICTIONARY = {
  [GeneratedCollection.Contacts]: {
    phone: 'phones',
    email: 'emails',
    firstName: 'firstName',
    lastName: 'lastName',
  },
  [GeneratedCollection.Analytics]: { name: 'name' },
  [GeneratedCollection.HelpTopics]: { name: 'name' },
  [GeneratedCollection.Tags]: { test: 'hello' },
  [GeneratedCollection.Teams]: { test: 'hello' },
  [GeneratedCollection.Users]: { test: 'hello' },
  [GeneratedCollection.Workflows]: { test: 'hello' },
  [GeneratedCollection.TicketInteractions]: { test: 'hello' },
  [GeneratedCollection.WorkflowElements]: { test: 'hello' },
  [GeneratedCollection.WorkflowActions]: { test: 'hello' },
  [GeneratedCollection.Tickets]: { test: 'hello' },
  [GeneratedCollection.WorkflowSteps]: { test: 'hello' },
  [GeneratedCollection.InteractionStats]: { test: 'hello' },
  [GeneratedCollection.Locations]: { name: 'name' },
  [GeneratedCollection.NotificationHooks]: { name: 'name' },
}

export const isSolrField = <C extends keyof typeof SOLR_MONGO_FIELD_DICTIONARY>(
  collectionName: C,
  fieldName: string | number | symbol,
): fieldName is keyof typeof SOLR_MONGO_FIELD_DICTIONARY[C] =>
  fieldName in SOLR_MONGO_FIELD_DICTIONARY[collectionName]

export const transformSolrFieldToMongo = <
  C extends keyof typeof SOLR_MONGO_FIELD_DICTIONARY,
  F extends keyof typeof SOLR_MONGO_FIELD_DICTIONARY[C]
>(
  collectionName: C,
  solrFieldName: F,
) => SOLR_MONGO_FIELD_DICTIONARY[collectionName][solrFieldName]

export const getModelInfoField = (
  collectionName: GeneratedCollection,
  fieldKey: string,
) => generatedMetadata.modelInfo[collectionName].fields[fieldKey]

export const hasPresets = (
  field?: GeneratedModelInfoField,
): field is GeneratedModelInfoFieldWithPresets => field?.presets != null

export const hasReference = (
  field?: GeneratedModelInfoField,
): field is GeneratedModelInfoFieldWithReferences => field?.references != null

export const getFieldPresetValue = (
  collectionName: GeneratedCollection,
  fieldKey: string,
  value: string,
) => {
  const field = getModelInfoField(collectionName, fieldKey)
  return hasPresets(field)
    ? Object.keys(field.presets).find((key) => field.presets[key] === parseInt(value))
    : 'Unknown Group Name'
}
