import { Editor, Path, Transforms, Range, Point, Span } from 'slate'

// TODO: Maybe attach these to the editor itself.

// TODO: See if using the current offset feels more natural for the selection handlers.
export const selectNextBlockNode = (editor: Editor, at?: Range | Path | Point) => {
  const nextBlockNode = getNextBlockNode(editor, at)
  if (!nextBlockNode) return
  const newSelection = {
    anchor: Editor.start(editor, nextBlockNode[1]),
    focus: Editor.start(editor, nextBlockNode[1]),
  }
  Transforms.setSelection(editor, newSelection)
}

export const selectPreviousBlockNode = (editor: Editor, at?: Range | Path | Point) => {
  const previousBlockNode = getPreviousBlockNode(editor, at)
  if (!previousBlockNode) return
  const newSelection = {
    anchor: Editor.start(editor, previousBlockNode[1]),
    focus: Editor.start(editor, previousBlockNode[1]),
  }
  Transforms.setSelection(editor, newSelection)
}

// NOTE: This will return an array of block node entries. If the selection is collapsed, only the first is necessary.
export const getCurrentBlockNode = (editor: Editor, at?: Range | Path | Point | Span) => {
  const newAt = at || editor.selection // Default to the editor selection.
  return newAt
    ? Editor.nodes(editor, {
        at: newAt,
        match: (n) => Editor.isBlock(editor, n),
      })
    : null
}

export const getNextBlockNode = (editor: Editor, at?: Range | Path | Point) => {
  const newAt = at || editor.selection // Default to the editor selection.
  return newAt
    ? Editor.next(editor, {
        at: newAt,
        match: (n) => Editor.isBlock(editor, n),
      })
    : null
}

export const getPreviousBlockNode = (editor: Editor, at?: Range | Path | Point) => {
  const newAt = at || editor.selection // Default to the editor selection.
  return newAt
    ? Editor.previous(editor, {
        at: newAt,
        match: (n) => Editor.isBlock(editor, n),
      })
    : null
}
