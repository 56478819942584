import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns'
import { createStaticRanges } from 'react-date-range'

export const TODAY = new Date()
export const BEGINNING_OF_TIME = new Date(2016, 4, 31)

export const defineds = {
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  endOfMonth: endOfMonth(new Date()),
  endOfToday: endOfDay(new Date()),
  endOfWeek: endOfWeek(new Date()),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  startOfMonth: startOfMonth(new Date()),
  startOfToday: startOfDay(new Date()),
  startOfWeek: startOfWeek(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
}

export const defaultStaticRanges = createStaticRanges([
  {
    label: 'Today',
    range: () => ({
      endDate: defineds.endOfToday,
      startDate: defineds.startOfToday,
    }),
  },
  {
    label: 'Yesterday',
    range: () => ({
      endDate: defineds.endOfYesterday,
      startDate: defineds.startOfYesterday,
    }),
  },

  {
    label: 'This Week',
    range: () => ({
      endDate: defineds.endOfToday,
      startDate: defineds.startOfWeek,
    }),
  },
  {
    label: 'Last Week',
    range: () => ({
      endDate: defineds.endOfLastWeek,
      startDate: defineds.startOfLastWeek,
    }),
  },
  {
    label: 'This Month',
    range: () => ({
      endDate: defineds.endOfToday,
      startDate: defineds.startOfMonth,
    }),
  },
  {
    label: 'Last Month',
    range: () => ({
      endDate: defineds.endOfLastMonth,
      startDate: defineds.startOfLastMonth,
    }),
  },
])
