/* eslint-disable no-console */
import React, { useCallback } from 'react'

import { WorkflowElementProps } from '../workflow-viewer.types'

import WorkflowElement from './workflow-element'

import { FORM_FIELDS } from 'happitu/src/components/WorkflowElements/DisplayElements'
import Box from 'happitu/src/components/box'
import {
  getElementValue,
  getFieldError,
} from 'happitu/src/helpers/ticket/interactionHelper'

interface Props extends WorkflowElementProps {
  elementIds: ID[]
  interactionFields: InteractionStepField[]
}

export const shouldAutoFocus = (
  component: WorkflowElementTypes,
  value: string[],
  errors: string[],
  firstEmpty: boolean,
): boolean => {
  const hasEmptyValue = Array.isArray(value)
    ? !!value.filter((val) => !val).length
    : !value
  console.log(component, value, firstEmpty, hasEmptyValue)
  return (
    !!(hasEmptyValue || errors.length) &&
    firstEmpty &&
    FORM_FIELDS.indexOf(component) > -1
  )
}

const WorkflowElements = (props: Props) => {
  let firstEmptyField = true

  const placeholderFunction = useCallback(() => {
    return
  }, [])

  return (
    <Box>
      {props.elementIds.map((elementId: ID) => {
        const element = props.collections.workflowElements?.findById<WorkflowElementRecord>(
          elementId,
        )
        if (!element || element.attributes.invisible) {
          return
        }

        const elemIndex = props.interactionFields.findIndex(
          (f: InteractionStepField) => f.elementId === elementId,
        )
        const field = props.interactionFields[elemIndex]
        const errors = getFieldError(field)
        const hasErrors = errors.length > 0
        const value = getElementValue(element.component, field)
        const autoFocus = shouldAutoFocus(
          element.component,
          value,
          errors,
          firstEmptyField,
        )

        if (autoFocus) {
          firstEmptyField = false
        }

        return (
          <WorkflowElement
            {...element}
            autoFocus={autoFocus}
            errors={errors}
            fileIds={field?.fileIds}
            fileUploads={props.collections.fileUploads}
            forceErrors={hasErrors}
            indexId={elemIndex}
            key={elementId}
            onBlur={props.onValueChange}
            onChange={placeholderFunction}
            onChangeFileIds={props.onUpdateFileIds}
            onUpload={props.onUpload}
            validationAttempts={props.validationAttempts}
            value={value}
            variables={props.variables}
          />
        )
      })}
    </Box>
  )
}

export default WorkflowElements
