import React from 'react'
import { connect } from 'react-redux'

import * as contactActions from 'happitu/src/actions/contactActions'
import { getContactDetailTypes } from 'happitu/src/actions/contactDetailTypeActions'
import { clearError } from 'happitu/src/actions/errorActions'
import {
  getHelpTopic,
  getWorkflowHelpTopics,
  getWorkingHelpTopicByImpression,
} from 'happitu/src/actions/helpTopicActions'
import { getLocation } from 'happitu/src/actions/locationActions'
import { RelayAction, RelayDispatch } from 'happitu/src/actions/relay'
import * as ticketActions from 'happitu/src/actions/ticketActions'
import * as ticketInteractionActions from 'happitu/src/actions/ticketInteractionActions'
import * as ticketInteractionFinalizeInfoActions from 'happitu/src/actions/ticketInteractionsFinalizeInfoActions'
import { getWorkflowStepGroups } from 'happitu/src/actions/workflowStepGroupActions'
import createContext, {
  ContextProviderProps,
  mapGlobalStateToProps,
} from 'happitu/src/createRelayContext'
import createReducer, {
  BoundActionTypes,
  createContextProvider,
  initialState,
} from 'happitu/src/createRelayReducer'
import { getCurrentUser } from 'happitu/src/helpers/currentUserHelpers'
import { warn } from 'happitu/src/helpers/loggerHelper'
import {
  getCurrentStepValues,
  getInteractionFromRoute,
  getTicketFromRoute,
} from 'happitu/src/helpers/ticket/ticketAttributeFinders'
import { buildVariableList } from 'happitu/src/helpers/variableHelper'
import suggestedHelpTopicsReducer from 'happitu/src/reducers/suggestedHelpTopicsReducer'
import {
  InterfaceOptions,
  OptionalTicketContextState,
  TicketContextActionTypes,
  TicketContextState,
  TicketPostProcessStores,
  TicketState,
} from 'happitu/src/types/ticketContext'

const updateInterfaceOptions = (interfaceOptions: Partial<InterfaceOptions>) => (
  dispatch: RelayDispatch<Record<string, Partial<InterfaceOptions>>>,
) =>
  dispatch({
    type: TicketContextActionTypes.UpdateInterfaceOptions,
    payload: {
      interfaceOptions,
    },
    options: {},
  })

const actions = {
  ...ticketInteractionActions,
  ...ticketInteractionFinalizeInfoActions,
  ...ticketActions,
  ...contactActions,
  clearError,
  getHelpTopic,
  getLocation,
  getWorkingHelpTopicByImpression,
  getWorkflowHelpTopics,
  getWorkflowStepGroups,
  updateInterfaceOptions,
  getContactDetailTypes,
}

type ContextProps = keyof (TicketState & typeof actions)
type AvailableContextProps = TicketState & BoundActionTypes<typeof actions>
export type TicketComponentProps<S extends ContextProps> = Pick<AvailableContextProps, S>

function variableReducer(state: TicketContextState): Record<string, any> | void {
  if (state.ticketInteractions && state.workflows) {
    const interaction = getInteractionFromRoute(state.ticketInteractions)
    const ticket = getTicketFromRoute(state.tickets)
    const interactions = ticket.interactionIds.map((id) =>
      state.ticketInteractions.findById<TicketInteractionRecord>(id),
    )

    return buildVariableList(
      {
        contact: state.currentContact,
        overwrites: { ...ticket.variableOverwrites, ...interaction.variableOverwrites },
        steps: interactions.reduce((acc, interaction) => {
          if (interaction) return [...acc, ...interaction.workflowSteps]
          else return acc
        }, []),
        currentInteraction: interaction,
        ticket: ticket,
        user: getCurrentUser(),
        workflow: state.workflows.findById<WorkflowRecord>(interaction.workflowId),
      },
      state,
    )
  }
}

const defaultInterfaceOptions: InterfaceOptions = {
  isUploadingFile: false,
}

const interfaceOptionsReducer = (
  state: TicketContextState & TicketPostProcessStores,
  action: RelayAction<TicketContextState & TicketPostProcessStores>,
): InterfaceOptions => {
  if (action.type === TicketContextActionTypes.UpdateInterfaceOptions) {
    return {
      ...state.interfaceOptions,
      ...action.payload.interfaceOptions,
    }
  }
  return state.interfaceOptions ?? defaultInterfaceOptions
}

const { reducer, useActions } = createReducer<
  TicketContextState,
  TicketPostProcessStores
>({
  interfaceOptions: interfaceOptionsReducer,
  suggestedHelpTopics: suggestedHelpTopicsReducer,
  variables: variableReducer,
})

const boundHandlers = useActions(actions)

export const TicketContext = createContext<TicketContextState>()

function TicketContextProvider(props: ContextProviderProps) {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const handlers = boundHandlers(dispatch)
  return (
    <TicketContext.Provider
      value={{
        handlers: {
          ...props._reduxHandlers,
          ...handlers,
        },
        state: {
          ...props._reduxState,
          ...state,
        },
      }}
    >
      {props.children}
    </TicketContext.Provider>
  )
}

/**
 * Use this to safely bind to the context.
 */
export const connectTicketContext = createContextProvider<
  TicketState,
  OptionalTicketContextState,
  BoundActionTypes<typeof actions>
>(TicketContext, {
  aliases: {
    currentStep: (state: TicketState) => {
      try {
        const interaction = getInteractionFromRoute(state.ticketInteractions)
        if (state.workflowStepGroupSteps) {
          const interactionStep = getCurrentStepValues(interaction.workflowSteps)
          return interactionStep
            ? state.workflowStepGroupSteps.findById(interactionStep.stepId)
            : null
        }
        return null
      } catch (e) {
        warn('currentStep', e)
        return null
      }
    },
    currentStepValues: (state: TicketState) => {
      try {
        const interaction = getInteractionFromRoute(state.ticketInteractions)
        return getCurrentStepValues(interaction.workflowSteps)
      } catch (e) {
        // getInteractionFromRoute will throw an error if it can't find the interaction in state. Bad design, I know.
        return null
      }
    },
  },
})

export const TicketProvider = connect(mapGlobalStateToProps(['currentUser', 'route']))(
  TicketContextProvider,
)
