import ObjectId from 'bson-objectid'
import { get } from 'lodash'
import memoizeOne from 'memoize-one'

import { getWorkflowRoom } from './websocketHelper'

export const getWorkflowManagementRoute = memoizeOne(() => {
  return {
    to: 'app.manage.workflows',
  }
})

/**
 * Get the previous workflow version.
 */
export const previousPublishedVersion = (
  currentVersion: number,
  versions: WorkflowVersion[],
) => {
  for (let v = currentVersion - 1; v >= 0; v--) {
    if (versions[v].publishedAt !== null) {
      return { index: v, ...versions[v] }
    }
  }
  return
}

/**
 * Get published version.
 */
export const getPublishedVersion = (
  currentVersion: number,
  versions: WorkflowVersion[],
): WorkflowVersion | void =>
  versions.find((v, index) => v.publishedAt !== null && currentVersion === index)

/**
 * Get published workflow version.
 */
export const getPublishedVersionFromState = (
  state: ReduxState,
  workflowId: ID,
): WorkflowVersion | undefined => {
  const workflow = state.workflows.findById<WorkflowRecord>(workflowId)
  if (!workflow) {
    return
  }
  return workflow.versions[workflow.currentVersion]
}

/**
 * Get workflow version by index.
 */
export const getWorkflowVersion = <S extends { workflows?: WorkflowStore }>(
  state: S,
  workflowId: string,
  workflowVersion: number,
): WorkflowVersion => {
  if (!state.workflows) throw new Error('Workflows is not preset in local state.')
  const workflow = state.workflows.findById<WorkflowRecord>(workflowId)
  return get(workflow, `versions[${workflowVersion}]`)
}

/**
 * Get working workflow version.
 */
export const getWorkingVersion = (
  state: Partial<ReduxState>,
  workflowId?: string,
): WorkflowVersion | void => {
  if (!state.workflows) return
  const workflow = state.workflows.findById<WorkflowRecord>(workflowId)
  if (!workflow) return
  return workflow.versions[workflow.workingVersion]
}

/**
 * Determine if the workflow is published.
 */
export const isPublished = (workflow: WorkflowRecord): boolean => {
  if (workflow === null || typeof workflow !== 'object') {
    return false
  }
  return workflow.versions.length > 1
}

/**
 * Determine if the workflow has pending changes.
 */
export const isModified = (workflow: WorkflowRecord): boolean => {
  return !workflow.versions[workflow.workingVersion].pristine
}

/**
 * Returns a hydrated array of users that are currently editing a workflow.
 */
export const getActiveUsers = (state: ReduxState) => (
  workflowId: string,
): Array<void | UserRecord> => {
  const room = state.websocketRooms.findById<WebsocketRecord>(getWorkflowRoom(workflowId))
  return room
    ? room.ids
        .filter((id: ID) => id !== state.user.id)
        .map((id: ID) => state.users.findById<UserRecord>(id))
    : []
}

export const getWorkingVersionFromRoute = (state: ReduxState): WorkflowVersion | void =>
  getWorkingVersion(state, state.router.route.params.workflowId)

export const stringifyWorkflowAddress = ([
  line1,
  line2,
  line3,
  city,
  state,
  postalCode,
  country,
]: string[]): string => {
  const separator = postalCode || country ? ',' : ''
  const regionalPart = postalCode ? `${city}, ${state} ${postalCode}` : ''
  const streetPart = `${line1} ${line2} ${line3}`
  return `${streetPart}${separator} ${regionalPart} ${country}`.replace(/\s{2,}/g, ' ')
}

export function newActionRecord(workflowId: ID, stepId: string, actionIds: string[]) {
  return {
    id: new ObjectId().toHexString(),
    name: `Action ${actionIds.length}`,
    order: actionIds.length,
    stepId,
    workflowId,
  }
}

export function newStepRecord(workflowId: ID, stepGroupId: ID, name: string) {
  return {
    actionIds: [],
    id: new ObjectId().toHexString(),
    name,
    stepGroupId,
    workflowId,
  }
}

export function newTagRecord(name: string, color: string) {
  return {
    color,
    id: new ObjectId().toHexString(),
    name,
  }
}
