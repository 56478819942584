import relay, { RelayDispatch } from './relay'

import { error } from 'happitu/src/helpers/loggerHelper'
import {
  getFinalizeInfoRequest,
  Params,
} from 'happitu/src/services/happitu-api/ticketInteractionFinalizeInfoService'

export function getInteractionFinalizeInfo(interactionId: ID, params: Params = {}) {
  return (dispatch: RelayDispatch<typeof getFinalizeInfoRequest>) => {
    return getFinalizeInfoRequest(interactionId, params)
      .then(relay(dispatch))
      .catch((r) => error('unable to get finalize info', r))
  }
}
