import { css } from 'styled-components'
import { switchProp } from 'styled-tools'

export interface ScrollProps {
  scroll?: 'x' | 'y' | 'both'
}

export const scrollMixin = css<ScrollProps>`
  ${switchProp('scroll', {
    x: 'overflow-x: auto;',
    y: 'overflow-y: auto;',
    both: 'overflow: auto;',
  })}

  @media print {
    overflow: visible !important;
  }
`
