import styled from 'styled-components'
import { prop } from 'styled-tools'

import { FlexRow } from 'happitu/src/components/_DEPRECATED/Flex'
import getStyle from 'happitu/src/getStyle'
import { zIndex } from 'theme'

export const Footer = styled(FlexRow)`
  display: grid;
  grid-area: footer;
  grid-gap: 1em;
  grid-template-columns: auto auto auto;
  padding: 1em;
  white-space: nowrap;
  z-index: ${zIndex('bump')};
  background: ${getStyle('app-alt-background')};
  border-top: 1px solid ${getStyle('border-color')};
`

export const Col = styled(FlexRow)<{ justify?: string }>`
  flex-grow: 1;
  justify-content: ${prop('justify', 'center')};
`

export const TicketButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`
