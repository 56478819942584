import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components'

import {
  ImageFigure,
  ImageFrame,
} from 'happitu/src/components/WorkflowElements/ImageCommons'
import { getFileUrl } from 'happitu/src/helpers/fileUploadHelpers'

const Wrapper = styled.div`
  margin-bottom: 1em;
`

export default class Image extends Component {
  static propTypes = {
    attributes: PropTypes.object.isRequired,
  }

  state = {
    src: null,
  }

  componentDidMount() {
    const { fileId } = this.props.attributes

    if (fileId) {
      this.setState({
        src: getFileUrl(fileId),
      })
    }
  }

  renderFigure() {
    if (this.props.attributes.figure) {
      return <ImageFigure display>{this.props.attributes.figure}</ImageFigure>
    }
  }

  render() {
    return (
      <Wrapper>
        <ImageFrame
          width={this.props.attributes.imageWidth}
          height={this.props.attributes.imageHeight}
          src={this.state.src}
        />
        {this.renderFigure()}
      </Wrapper>
    )
  }
}
