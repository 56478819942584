import { css } from 'styled-components'
import { switchProp } from 'styled-tools'

import getStyle from 'happitu/src/getStyle'

export interface BackgroundProps extends ContextThemeProps {
  bg?: 'default' | 'alt' | 'primary'
}

export const backgroundMixin = css<BackgroundProps>`
  background: ${switchProp(
    'bg',
    {
      alt: getStyle('app-alt-background'),
      default: getStyle('app-background'),
      primary: getStyle('button-primary-default-background'),
    },
    'transparent',
  )};
`
