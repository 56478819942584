import createReducer from './createReducer'
import Store from './createStore'

import * as types from 'happitu/src/constants/ActionTypes'

export default createReducer(new Store(), {
  [types.RELAY_DISPATCH]: (state, { payload }) => state.setMany(payload.invitations),
  [types.RESEND_INVITATION]: (state, { payload }) => state.set(payload.id, payload),
  [types.DELETE_INVITATION]: (state, { payload }) => state.remove(payload.id),
  [types.DELETE_INVITATION_FAILED]: (state, { payload }) =>
    state.rollbackRemoval(payload.id),
  [types.UPDATE_INVITATION]: (state, { payload }) => state.set(payload.id, payload),
})
