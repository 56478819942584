import { getInitialValue } from '../slate'

import RenderSlateValue from 'happitu/src/components/RichTextEditor/render-slate-value'
import Box from 'happitu/src/components/box'

const DisplayText = ({ attributes, variables }: WorkflowDisplayElement) => {
  return (
    <Box>
      {!!attributes.richValue || !!attributes.value ? (
        <RenderSlateValue
          value={getInitialValue(attributes.richValue, attributes.text)}
          variables={variables}
        />
      ) : (
        attributes.placeholder
      )}
    </Box>
  )
}

export default DisplayText
