import { differenceInDays, format } from 'date-fns'
import { get } from 'lodash'

import { getUserOption } from './user'

import { TicketStatus } from 'happitu/src/constants/ticketConstants'

export const DEFAULT_BINS = [
  'openTickets',
  'myTickets',
  'highPriority',
  'closedTickets',
  'requestedChanges',
  'needsReview',
  'requestManager',
  'scheduledCallbacks',
]

export const openTicketsBin: TicketBinPreset = {
  criteria: {
    status: [1],
  },
  key: 'openTickets',
  name: 'Open Tickets',
}

export const scheduledCallbackBin: TicketBinPreset = {
  criteria: {
    status: [10],
  },
  key: 'scheduledCallbacks',
  name: 'Scheduled Callbacks',
}

export const myTicketsBin: TicketBinPreset = {
  criteria: {
    assignedUserId: 'currentUser',
    status: [1, 8],
  },
  key: 'myTickets',
  name: 'My Tickets',
}

export const smartBins: TicketBinPreset[] = [
  {
    criteria: {
      priority: 1,
      status: [TicketStatus.Open],
    },
    icon: 'gear',
    key: 'highPriority',
    name: 'High Priority',
  },
  {
    criteria: {
      status: [TicketStatus.Closed],
    },
    icon: 'gear',
    key: 'closedTickets',
    name: 'Closed Tickets',
  },
  {
    criteria: {
      status: [TicketStatus.RequestManager],
    },
    icon: 'gear',
    key: 'requestManager',
    name: 'Request Manager',
  },
  {
    criteria: {
      status: [TicketStatus.ReadyForReview],
    },
    icon: 'gear',
    key: 'needsReview',
    name: 'Needs Review',
  },
  {
    criteria: {
      status: [TicketStatus.ChangesRequested],
    },
    icon: 'gear',
    key: 'requestedChanges',
    name: 'Changes Requested',
  },
]

export function formatTicketNumber(
  ticket: TicketRecord | number | string,
  showInteractionSuffix = false,
  interactionSuffix?: number | string,
) {
  if (typeof ticket === 'object') {
    return `#${ticket.ticketNumber}${
      showInteractionSuffix ? `.${interactionSuffix || ticket.interactionIds.length}` : ''
    }`
  }
  return `#${ticket}`
}

export function createWorkflowBinShape(workflow: WorkflowRecord): TicketBinPreset {
  const criteriaHistory = getUserOption('binCriteriaHistory') || {}
  return {
    criteria: {
      nextWorkflowId: workflow.id,
      status: criteriaHistory[workflow.id] || [0, 1, 7, 8],
    },
    key: workflow.id,
    name: workflow.name,
  }
}

export function getRelativeName(date: Date) {
  switch (differenceInDays(date, new Date())) {
    case 0:
      return 'Today'
    case -1:
      return 'Yesterday'
    case -2:
    case -3:
    case -4:
    case -5:
      return format(date, 'EEEE')
    default:
      return format(date, 'MMM d, yyyy')
  }
}

function getTicketAttribute(criteriaKey: string, ticket: TicketRecord) {
  switch (criteriaKey) {
    case 'unassigned':
      return ticket.assignedUserId
    default:
      return get(ticket, criteriaKey)
  }
}

function matchesCriteria(criteriaKey: string, criteria: any, ticketAttributeValue: any) {
  switch (criteriaKey) {
    case 'status':
      return criteria.indexOf(ticketAttributeValue) > -1
    case 'unassigned':
      return ticketAttributeValue === null
    default:
      return criteria === ticketAttributeValue
  }
}

function matchBinCriteria(activeBin: TicketBinPreset, currentTicket: TicketRecord) {
  return Object.keys(activeBin.criteria).filter((key: 'status' | 'nextWorkflowId') => {
    const criteria = activeBin.criteria[key]
    const ticketAttribute = getTicketAttribute(key, currentTicket)
    if (typeof ticketAttribute !== 'undefined') {
      return matchesCriteria(key, criteria, ticketAttribute)
    }
    return false
  })
}

function compileModifications(
  activeBin: TicketBinPreset,
  binTicketIds: ID[],
  tickets: TicketStore,
) {
  const activeCriteraLength = Object.keys(activeBin.criteria).length
  return tickets.reduce(
    (acc: [ID[], ID[]], ticket: TicketRecord) => {
      const matches = matchBinCriteria(activeBin, ticket).length

      if (activeCriteraLength !== matches && binTicketIds.indexOf(ticket.id) > -1) {
        acc[0].push(ticket.id)
      } else if (
        activeCriteraLength === matches &&
        binTicketIds.indexOf(ticket.id) === -1
      ) {
        acc[1].push(ticket.id)
      }
      return acc
    },
    [[], []],
  )
}

export function updateTicketsFromBin(
  activeBin: TicketBinPreset,
  state: StoreInterface<Bin>,
  tickets?: TicketStore,
  bin?: Bin,
) {
  if (!bin || !tickets) return state

  const [removedTickets, addedTickets] = compileModifications(
    activeBin,
    bin.ticketIds,
    tickets,
  )
  return removedTickets.length || addedTickets.length
    ? state.update(activeBin.key, {
        pagination: {
          ...bin.pagination,
          totalRecords:
            bin.pagination.totalRecords - removedTickets.length + addedTickets.length,
        },
        ticketIds: [
          ...addedTickets,
          ...bin.ticketIds.filter((ticketId: ID) => {
            return removedTickets.indexOf(ticketId) === -1
          }),
        ],
      })
    : state
}

export function getBin() {
  // TODO: use a dynamic default value once we implement custom ticket bins
  return getUserOption('activeBin', openTicketsBin) as TicketBinPreset
}
