import { size } from 'polished'
import styled from 'styled-components'

import * as theme from 'happitu/src/themes/default/menu'

const getArrowPos = (props) => {
  switch (props.anchor) {
    case 'top':
      return `
        left: 50%;
        top: 100%;
        margin-left: -5px;
      `
    case 'top-left':
      return `
        left: 10px;
        top: 100%;
      `

    case 'top-right':
      return `
        right: 10px;
        top: 100%;
      `

    case 'bottom':
      return `
        left: 50%;
        top: 0;
        transform: translateY(-100%);
      `
    case 'bottom-right':
      return `
        right: 10px;
        top: 0;
        transform: translateY(-100%);
      `
    default:
      return `
        left: 10px;
        top: 0;
        transform: translateY(-100%);
      `
  }
}

const getOffset = (props) =>
  /top/.test(props.anchor) ? 'margin-top: -16px;' : 'margin-top: 5px;'

const MenuArrow = styled.div`
  ${size('10px', theme.arrowSize)}
  position: absolute;
  overflow: hidden;

  ${getArrowPos}

  &:before {
    ${size(theme.arrowSize)}
    background: ${theme.backgroundColor};
    transform: rotate(45deg);
    content: '';
    display: block;
    border: ${theme.border};

    ${getOffset}
  }
`

export default MenuArrow
