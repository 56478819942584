import { useCallback, useState, useRef, KeyboardEvent, useEffect, useMemo } from 'react'

import { SelectProps, SelectOptionChild, AcceptedSelectValue } from '../Select.types'

import { isSelectedOption } from 'happitu/src/components/Lists/List.helpers'
import { buildOptionIndex } from 'happitu/src/components/Lists/hooks/useListHotkeys'
import { Hotkey } from 'happitu/src/hooks/useHotkey'

const useSelect = <SelectValue extends AcceptedSelectValue>({
  children,
  disabled,
  multiple = false,
  placeholder = '',
  value,
  ...props
}: SelectProps<SelectValue>) => {
  const [menuVisibility, setMenuVisibility] = useState(false)
  const selectRef = useRef<HTMLInputElement>(null)
  const ignoreBlur = useRef(false)
  const previousValue = useRef('')

  const displayValue = useMemo((): string => {
    const displayValue = buildOptionIndex(children)
      .filter((child: SelectOptionChild) => isSelectedOption(value, child.props.value))
      .map((child: SelectOptionChild) => child.props.label)
    if (displayValue.length === 0) return placeholder || ''
    return displayValue.join(', ')
  }, [value, placeholder, children])

  const onSelect = (newValue: SelectValue) => {
    props.onSelect(newValue)
    !multiple && closeMenu()
  }

  useEffect(() => {
    if (displayValue !== '') previousValue.current = displayValue
  }, [displayValue])

  const blur = useCallback(() => {
    if (!ignoreBlur) {
      closeMenu()
    }
    if (!menuVisibility && typeof props.onBlur === 'function') {
      props.onBlur()
    }
  }, [ignoreBlur, selectRef])

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      e.stopPropagation()
      if ([Hotkey.Select, Hotkey.Enter, Hotkey.Down].includes(e.key as Hotkey)) {
        e.preventDefault()
        openMenu()
      }
    },
    [disabled, selectRef, ignoreBlur],
  )

  const openMenu = useCallback(() => {
    if (!disabled) {
      props.onFocus && props.onFocus()
      //selectRef.current?.focus()
      ignoreBlur.current = true
      setMenuVisibility(true)
    }
  }, [disabled, selectRef, ignoreBlur])

  const closeMenu = useCallback(() => {
    setMenuVisibility(false)
    selectRef.current?.focus()
  }, [selectRef])

  return {
    blur,
    closeMenu,
    invalidValue:
      previousValue.current !== '' && displayValue === '' ? previousValue.current : null,
    displayValue: displayValue,
    isOpen: menuVisibility,
    onKeyDown,
    openMenu,
    onSelect,
    selectRef,
  }
}

export default useSelect
