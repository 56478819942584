import { get } from 'lodash'
import memoizeOne from 'memoize-one'

import { formatDate, SHORT_HAND_DATE_FORMAT } from '../helpers/dateHelpers'

import { getInitialAddressFieldValues } from 'happitu/src/helpers/addressHelper'
import { generatedMetadata } from 'happitu/src/helpers/generatedMetadata'
import { error } from 'happitu/src/helpers/loggerHelper'
import { elementPresets } from 'happitu/src/reducers/workflowElementsReducer'

export enum TicketStatus {
  Closed = 0,
  Open = 1,
  Pending = 2,
  Escalate = 3,
  IncompleteSelfService = 5,
  HangUpWrongNumber = 6,
  ReadyForReview = 7,
  ChangesRequested = 8,
  RequestManager = 9,
  ScheduleCallback = 10,
}

export enum TicketStatusString {
  Closed = 'closed',
  Open = 'open',
  Pending = 'pending',
  Escalate = 'escalate',
  Training = 'training',
  HangUpWrongNumber = 'hangupWrongNumber',
  ReadyForReview = 'needsReview',
  ChangesRequested = 'changesRequested',
}

export const ticketStatusStringIndex = {
  [TicketStatusString.Closed]: TicketStatus.Closed,
  [TicketStatusString.Open]: TicketStatus.Open,
  [TicketStatusString.Pending]: TicketStatus.Pending,
  [TicketStatusString.Escalate]: TicketStatus.Escalate,
  [TicketStatusString.HangUpWrongNumber]: TicketStatus.HangUpWrongNumber,
  [TicketStatusString.ChangesRequested]: TicketStatus.ChangesRequested,
  [TicketStatusString.ReadyForReview]: TicketStatus.ReadyForReview,
}

export const OpenStatuses = [
  TicketStatus.Open,
  TicketStatus.Pending,
  TicketStatus.ReadyForReview,
  TicketStatus.ChangesRequested,
]

export const InReviewStatuses = [
  TicketStatus.ReadyForReview,
  TicketStatus.ChangesRequested,
]

export const getChannelName = memoizeOne((channelValue: number) => {
  try {
    const {
      presets: channelPresets,
    } = generatedMetadata.modelInfo.ticketInteractions.fields.Channels
    if (channelPresets) {
      const channelLabel = Object.keys(channelPresets).find((key) => {
        return channelPresets[key] === channelValue
      })
      return channelLabel
    }
  } catch (e) {
    error(`getChannelName: unable to find channel '${channelValue}'`, e)
  }
  return `Unknown channel: ${channelValue}`
})

// Don't forget to update TicketStatus in records.d.ts too
export const STATUSES = [
  'Closed',
  'Open',
  'Pending',
  'Escalate',
  'Training',
  'Incomplete - Self Service',
  'Hang Up/Wrong Number',
  'Ready for Review',
  'Changes Requested',
  'Request Manager',
  'Scheduled Callback',
]

export const STATUS_COLORS = [
  'red',
  'green',
  'yellow',
  'grey',
  'grey',
  'grey',
  'yellow',
  'orange',
  'orange',
  'orange',
  'blue',
]

export const PRIORITIES = [
  ['High', 1],
  ['Normal', 0],
]

export const TICKETING_PADDING = {
  default: '3em',
  handheld: '2em',
}

const getContactValue = (contact: Partial<ContactRecord>, attribute: string) => {
  if (/^detail\./.test(attribute)) {
    const [, detailName] = attribute.split('.')
    return get(contact, `details[${detailName}]`, [''])
  } else if (/^phones\./.test(attribute)) {
    return [get(contact, 'phones[0].value', '')]
  } else if (/^emails\./.test(attribute)) {
    return [get(contact, 'emails[0].value', '')]
  } else {
    return [get(contact, attribute, '')]
  }
}

/**
 * Returns an object representing a field for ticketing.
 */
export function newTicketingField(
  contact: Partial<ContactRecord>,
  valueHistory?: Record<string, string[]>,
  variables: Record<string, string> = {},
) {
  // eslint-disable-next-line complexity
  return ({
    attributes,
    id,
    impressionId,
    groupId,
    component,
  }: WorkflowElementRecord) => {
    let values = attributes.value || attributes.defaultValue
    const variableName = attributes.label
      ? attributes.label.replace(/\s/g, '_').toLowerCase()
      : null

    if (typeof values === 'string') {
      if (variableName && variables[variableName] && attributes.autoPopulate) {
        values = [variables[variableName]]
      } else if (attributes.contactAttribute) {
        const contactValue = getContactValue(contact, attributes.contactAttribute)
        if (Array.isArray(contactValue) && contactValue[0] !== '') {
          values = contactValue
        } else {
          values = [values]
        }
      } else {
        values = [values]
      }
    }

    if (component === 'Documentation' && Array.isArray(values)) {
      Object.entries(variables).forEach(([key, value]) => {
        if (Array.isArray(values) && typeof values[0] === 'string') {
          values = [values[0].replace(new RegExp(`:${key}:`, 'ig'), value)]
        }
      })
    }

    // Remove the address lines if address field is hiding them
    if (component === 'AddressField') {
      values =
        variableName && variables[variableName]
          ? getInitialAddressFieldValues(
              variables[variableName].split('|'),
              attributes.hasStreetAddress,
            )
          : getInitialAddressFieldValues(values as string[], attributes.hasStreetAddress)
    }

    if (component === 'EmailField' && !attributes.allowMultiEmail) {
      if (attributes.contactAttribute) {
        values = getContactValue(contact, attributes.contactAttribute)
      } else {
        values = [
          (attributes.defaultValue as string[])[0] || variables[variableName || ''] || '',
        ]
      }
    }

    if (
      ['RadioButtonArray', 'CheckboxArray'].includes(component) &&
      attributes.customerAttribute
    ) {
      values = getContactValue(contact, attributes.customerAttribute)
    }

    if (component === 'DatePicker') {
      values = [
        (attributes.defaultValue as string[])[0] || variables[variableName || '']
          ? formatDate(new Date(variables[variableName || '']), SHORT_HAND_DATE_FORMAT)
          : '',
      ]
    }

    if (
      valueHistory &&
      valueHistory[id] &&
      valueHistory[id].filter((value) => value).length > 0
    ) {
      values = valueHistory[id]
    }

    return {
      component,
      elementId: id,
      elementImpressionId: impressionId,
      elementGroupId: groupId,
      name: attributes.label,
      values,
    }
  }
}

/**
 * Returns an object representing a step ƒor ticketing.
 */
export function newTicketingStep(
  step: WorkflowStepRecord,
  elements: WorkflowElementStore,
  contact: Partial<ContactRecord> = {},
  valueHistory?: Record<string, string[]>,
  variables?: Record<string, string>,
) {
  return {
    action: null,
    fields: step.elementIds
      .map((id) => elements.findById<WorkflowElementRecord>(id))
      .filter((element, index) => {
        if (!element) {
          error(
            `Missing element record when creating ticketing ticketing step (elementIndex: ${index}, workflowStep: ${step.id}`,
          )
          return
        }
        return elementPresets[element.component].formInput
      })
      .map(newTicketingField(contact, valueHistory, variables)),
    stepId: step.id,
    emails: [],
    notes: '',
  }
}
