import React, { ComponentProps } from 'react'
import { FieldInputProps, FieldMetaState } from 'react-final-form'
import styled from 'styled-components'

import getStyle from 'happitu/src/getStyle'
import { metric, font } from 'theme'

const ToggleItem = styled.label`
  position: relative;

  &:first-child span {
    border-top-left-radius: ${metric('largeBorderRadius')};
    border-bottom-left-radius: ${metric('largeBorderRadius')};
  }

  &:last-child span {
    border-top-right-radius: ${metric('largeBorderRadius')};
    border-bottom-right-radius: ${metric('largeBorderRadius')};
  }

  input {
    appearance: none;
    border: 0;
    height: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 0;
  }

  span {
    display: block;
    padding: 0.5em 1em;
    font-weight: ${font('weight', 'medium')};
    background: ${getStyle('button-base-default-background')};
    color: ${getStyle('button-base-active-color')};
    text-align: center;
  }

  input:checked + span {
    background: ${getStyle('button-primary-default-background')};
    color: ${getStyle('button-primary-default-color')};
  }
`

interface Props extends ComponentProps<'div'> {
  input: FieldInputProps<string, HTMLInputElement>
  meta: FieldMetaState<string>
  options: {
    label: string
    value: string
  }[]
}

const ToggleList = ({ options, input, ...props }: Props) => {
  return (
    <div {...props}>
      {options.map((option, index) => {
        return (
          <ToggleItem key={index}>
            <input {...input} value={option.value} type="radio" />
            <span>{option.label}</span>
          </ToggleItem>
        )
      })}
    </div>
  )
}

export default styled(ToggleList)`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 2px;
`
