import { format } from 'date-fns'
import { AnyAction } from 'redux'

import { sendLog, LoggedMessage } from 'happitu/src/services/logger'

/**
 * Stores the colors we will use in the JS Console.
 */
const CONSOLE_COLORS = {
  // These colors are what `redux-logger` is using.
  action: 'grey',
  dispatch: '#03A9F4',
  error: '#F20404',
  nextState: '#4CAF50',
  prevState: '#9E9E9E',
}

// These are meant for different packages that could dispatch actions.
const MODULE_COLOR: { [key: string]: string } = {
  '@@happitu': '#42D3A3',
  '@@router5': '#E40454',
}

/**
 * Applies color to the JS Console.
 */
function applyColor(
  type: 'action' | 'dispatch' | 'error' | 'nextState' | 'prevState',
  msg: any,
) {
  return [`%c ${type}`, `color: ${CONSOLE_COLORS[type]}; font-weight: bold;`, msg]
}

/**
 * Generates the Console.Group title for our redux-logger.
 */
export function generateTitle(actionType: string) {
  let module
  let type

  if (actionType && actionType.indexOf('@@') > -1) {
    module = actionType.split('/')[0]
    type = actionType.split('/')[1]
  } else {
    module = '@@happitu'
    type = actionType
  }

  return [
    `%caction %c${`${module}%c/${type}`} %c${timestamp()}`,
    'color: grey; font-weight: bold;',
    `color: ${MODULE_COLOR[module]};`,
    'color: inherit; font-weight: inherit;',
    'color: grey; font-weight: bold;',
  ]
}

/**
 * Generates a timestamp.
 * @returns {string}
 */
function timestamp() {
  return `@ ${format(new Date(), 'HH:mm:ss:SSS')}`
}

/**
 * Our custom formatting for error logging
 */
export function formatErrorLog(data: object) {
  const state = window.store.getState()
  const { path } = state.router.route
  const userId = state.user.id

  return {
    ...data,
    path,
    userId,
  }
}

/* eslint-disable no-console */

/**
 * Deprecation warning that will be logged.
 */
export function deprecation(
  methodName: string,
  resolution = 'This should be not be used.',
) {
  // tslint:disable-next-line:no-console
  console.warn(`Deprecation Warning: ${methodName} was called.\n${resolution}`)
}

/**
 * Reports an error to the console.
 */
export function error(...errs: LoggedMessage) {
  sendLog('error', ...errs)
  console.error(errs)
}

/**
 * Reports a warning to the console.
 */
export function warn(...wrn: LoggedMessage) {
  console.warn(...wrn)
}

export function info(...inf: LoggedMessage) {
  sendLog('info', ...inf)
  console.info(...inf)
}

/**
 * Our custom redux-logger.
 */
export function reduxLogger({ getState }: { getState: Function }) {
  return function wrapDispatchToAddLogging(next: Function) {
    return function dispatchAndLog(action: AnyAction) {
      const prevState = getState()
      let result

      try {
        result = next(action)
      } catch (error) {
        // tslint:disable-next-line:no-console
        console.error(error)
      }

      if (process.env.NODE_ENV !== 'production') {
        // tslint:disable:no-console
        console.groupCollapsed(...generateTitle(action.type))
        console.log(...applyColor('prevState', prevState))
        console.log(...applyColor('dispatch', action))
        console.log(...applyColor('nextState', getState()))
        console.groupEnd()
        // tslint:enable:no-console
      }
      return result
    }
  }
}

// tslint:disable:no-console
export function logState(state: object) {
  if (process.env.NODE_ENV !== 'production') {
    console.groupCollapsed(...generateTitle('RELAY STATE CHANGE'))
    console.log({ ...state })
    console.groupEnd()
  }
}
// tslint:enable:no-console
