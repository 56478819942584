import React from 'react'
import styled, { keyframes } from 'styled-components'

export interface BaseLoaderProps {
  size?: string
}

const sizeMetrics = ({ size }: BaseLoaderProps) => {
  switch (size) {
    default:
      return `
        height: 22px;
        width: 22px;
      `
    case 'small':
      return `
        height: 15px;
        width: 15px;
      `
    case 'large':
      return `
        height: 40px;
        width: 40px;
      `
  }
}

const spin = keyframes`
  0% {
    transform: rotate(0deg);
    opacity: .8;
  }

  20% {
    opacity: .3;
  }

  80% {
    opacity: .3;
  }

  100% {
    transform: rotate(360deg);
    opacity: .8;
  }
`

export default React.memo(styled.div`
  animation: ${spin} 500ms infinite linear;
  background: url(https://assets.happitu.com/public/loader.svg) no-repeat center;
  position: absolute;
  ${sizeMetrics}
`)
