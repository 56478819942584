import { createContext, ReactNode } from 'react'

interface Props {
  variables?: Record<string, string>
  children?: ReactNode
}

export const VariableContext = createContext<Record<string, string>>({})

export const VariableProvider = (props: Props) => {
  return (
    <VariableContext.Provider value={props.variables ?? {}}>
      {props.children}
    </VariableContext.Provider>
  )
}
