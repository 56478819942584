import { AnimatePresence } from 'framer-motion'
import { last } from 'lodash'
import * as React from 'react'

import {
  CancelButton,
  LoadingBar,
  LoadingLabel,
  UploadButton,
  UploadLabel,
} from '../AttachFileField.styles'

import Autogrow from 'happitu/src/components/Animations/Autogrow'
import {
  FieldFigure,
  Label,
  NextFieldWrapper,
} from 'happitu/src/components/Fields/FieldUtils'
import Icon from 'happitu/src/components/Icon'
import AttachmentItem from 'happitu/src/components/Layout/AttachmentItem'
import { getDataTransferItems } from 'happitu/src/helpers/fileHelper'
import useIsMounted from 'happitu/src/hooks/use-is-mounted'
import {
  connectTicketContext,
  TicketComponentProps,
} from 'happitu/src/modules/ticketing/ticketing'
import { AttachFileFieldDisplayProps } from 'happitu/src/types/workflowElements'

interface FilesProps {
  fileIds?: ID[]
  fileUploads: FileUploadStore
  label?: string
  onRemove: (id: ID) => void
  required?: boolean
}

function AttachedFiles(props: FilesProps) {
  return (
    <Autogrow isVisible={!!props.fileIds && props.fileIds.length > 0}>
      <div style={{ paddingBottom: '.5em' }}>
        <Label required={props.required} value={props.label} />
        <AnimatePresence>
          {props.fileIds?.map((fileId) => {
            const file = props.fileUploads.findById<FileUploadRecord>(fileId)
            if (!file) return
            return <AttachmentItem file={file} key={fileId} onRemove={props.onRemove} />
          })}
        </AnimatePresence>
      </div>
    </Autogrow>
  )
}

interface Props
  extends TicketComponentProps<'updateInterfaceOptions'>,
    AttachFileFieldDisplayProps {}

function AttachFileField(props: Props) {
  const showError = props.forceErrors && props.errors.length > 0
  const [progress, setProgress] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const lastError = last(props.errors) as string
  const isMounted = useIsMounted()
  const cancelledRef = React.useRef(false)
  const cancelled = React.useCallback(() => cancelledRef.current, [])

  // Used to disable the leave ticket button.
  const startUploading = () => props.updateInterfaceOptions({ isUploadingFile: true })

  // Used to re-enable the leave ticket button.
  const finishUploading = () => props.updateInterfaceOptions({ isUploadingFile: false })

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      cancelledRef.current = false // Reset cancelled status on new upload request.
      startUploading()
      const files = getDataTransferItems(e)
      setLoading(true)
      props
        .onUpload(
          props.id,
          files,
          (p: number) => {
            if (!isMounted()) return
            if (cancelled()) {
              finishUploading()
              return
            }
            setProgress(Math.round(p))
          },
          cancelled,
        )
        .finally(() => {
          finishUploading()
          if (!isMounted()) return
          cancelledRef.current = false // Reset cancelled status on upload completion.
          setLoading(false)
          setProgress(0)
        })
    },
    [props.id],
  )

  const handleRemove = React.useCallback(
    (id) => {
      props.onChangeFileIds(
        props.id,
        props.fileIds.filter((v: ID) => v !== id),
      )
    },
    [props.id, props.fileIds],
  )

  const handleCancel = () => (cancelledRef.current = true)

  return (
    <NextFieldWrapper invalid={!!showError}>
      <AttachedFiles
        fileIds={props.fileIds}
        fileUploads={props.fileUploads}
        label={props.attributes.label}
        onRemove={handleRemove}
        required={props.attributes.required}
      />

      <UploadButton isLoading={loading}>
        <UploadLabel>
          {!loading && (
            <input type="file" disabled={loading} onChange={handleChange} multiple />
          )}
          <Icon type="upload-cloud" />
          <span>{props.attributes.buttonLabel}</span>
        </UploadLabel>
        <LoadingLabel>
          <span>
            {cancelledRef.current ? 'Cancelling' : 'Uploading (' + progress + '%)'}
          </span>
          {!cancelledRef.current && (
            <CancelButton
              ghost
              icon="close"
              onClick={handleCancel}
              size="small"
              title="Cancel upload"
            />
          )}
        </LoadingLabel>
        <LoadingBar style={{ width: cancelledRef.current ? '100%' : progress + '%' }} />
      </UploadButton>

      {showError && <FieldFigure value={lastError} />}
    </NextFieldWrapper>
  )
}

export default connectTicketContext<Props>(null, ['updateInterfaceOptions'])(
  AttachFileField,
)
