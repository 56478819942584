import * as React from 'react'
import styled from 'styled-components'

import styles from './button.styles'
import { ButtonProps } from './button.types'

import Icon from 'happitu/src/components/Icon'
import Link, { Props as LinkProps } from 'happitu/src/components/link'

interface Props extends LinkProps {
  disabled?: boolean
  icon?: string
}

function ButtonTo({ disabled, icon, children, ...props }: Props & ButtonProps) {
  const handleClick = React.useCallback(
    (e) => {
      e.preventDefault()
      return false
    },
    [disabled],
  )

  return (
    <Link {...props} onClick={disabled ? handleClick : props.onClick}>
      {icon && <Icon type={icon} />}
      {children}
    </Link>
  )
}

export default styled(ButtonTo)`
  ${styles}
`
