import { CardElement } from '@stripe/react-stripe-js'
import React, { InputHTMLAttributes, useMemo } from 'react'
import { FieldInputProps, FieldMetaState } from 'react-final-form'
import styled from 'styled-components'

import {
  NextFieldWrapper,
  ErrorFigure,
  FieldFigure,
  InputCSS,
} from 'happitu/src/components/Fields/FieldUtils'
import getStyle from 'happitu/src/getStyle'
import getTheme from 'happitu/src/getTheme'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  footnote?: string
  label?: string
  input: FieldInputProps<string, HTMLInputElement>
  meta: FieldMetaState<string>
}

const StyledCardElement = styled(CardElement)`
  &.StripeElement {
    ${InputCSS}
    padding: 14px 12px;
  }

  &.StripeElement--focus :not(.StripeElement--invalid) {
    box-shadow: 0 0 0 1px ${getStyle('input-focus-borderColor')};
    border-color: ${getStyle('input-focus-borderColor')};
  }

  &.StripeElement--invalid {
    border-color: ${getStyle('text-error-color')};
  }

  &.StripeElement--webkit-autofill {
    background-color: ${getStyle('notice-info-background')} !important;
  }
`

const CreditCardField = ({ meta, footnote, input }: Props) => {
  const theme = getTheme()

  const cardElementOptions = useMemo(
    () => ({
      hidePostalCode: true,
      style: {
        base: {
          'color': theme.currentTheme['text-default-color'],
          '::placeholder': {
            color: theme.currentTheme['text-light-color'],
          },
        },
        // empty: {},
        // complete: {},
        invalid: {
          color: theme.currentTheme['text-warning-color'],
        },
      },
    }),
    [],
  )

  const handleChange = (e: any) => {
    input.onChange(e)
  }

  return (
    <NextFieldWrapper>
      <StyledCardElement
        onChange={handleChange}
        onBlur={input.onBlur}
        options={cardElementOptions}
      />
      {meta.touched && meta.error ? (
        <ErrorFigure>{meta.error}</ErrorFigure>
      ) : (
        <FieldFigure>{footnote}</FieldFigure>
      )}
    </NextFieldWrapper>
  )
}

export default CreditCardField
