import React, { CSSProperties } from 'react'
import styled from 'styled-components'

import IconButton from './IconButton'

import { ButtonProps } from 'happitu/src/components/button'

interface Props extends ButtonProps {
  className?: string
  style?: CSSProperties
}

const DragHandle = (props: Props) => {
  return <IconButton as="span" {...props} role="button" icon="drag-indicator" ghost />
}

export default styled(DragHandle)`
  &:not(:disabled) {
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
`
