import styled from 'styled-components'

import Icon from 'happitu/src/components/Icon'
import MenuListItemAction from 'happitu/src/components/_DEPRECATED/_DEPRECATED_Menu/MenuListItemAction'

export const Wrapper = styled(MenuListItemAction)`
  ${Icon} {
    cursor: pointer;
    font-size: 12px;
  }
`

export const Label = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`

export const DeselectButton = styled(Icon)`
  margin-left: auto;
`
