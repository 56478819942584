import io from 'socket.io-client'

import { webSocketHost } from 'happitu/src/services'

type RoomMemberCallback = (namespace: string, roomId: string, userIds: string[]) => void
type MessageCallback = (message: {
  progress: number
  done?: boolean
  url: string
}) => void

export default class Socket {
  protected namespace: string
  protected messageCallback: MessageCallback
  protected roomMembersCallback: RoomMemberCallback
  protected rooms: { [key: string]: string }
  protected callbacks: { [key: string]: any }
  protected socket: ReturnType<typeof io>

  constructor(namespace: string) {
    this.namespace = namespace
    this.messageCallback = () => Object
    this.roomMembersCallback = () => Object
    this.rooms = {}
    this.callbacks = {}
  }

  public connect(token: string) {
    if (this.socket && this.socket.connected) {
      return
    }
    this.socket = io(`${webSocketHost()}/${this.namespace}?token=${token}`, {
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 100,
      transports: ['websocket'],
    })
    this.socket
      .on('message', (message: any) => {
        if (typeof message === 'object') {
          if (message.error === 'Invalid Token') {
            // eslint-disable-next-line no-console
            console.warn('socketService', 'connect', 'Invalid websocket token.')
          }
          this.messageCallback(message)
        } else {
          this.messageCallback(JSON.parse(message))
        }
      })
      .on('connect', () => {
        Object.keys(this.rooms).forEach((roomId: string) => {
          this.socket.emit('subscribeToRoom', roomId, this.rooms[roomId])
        })

        Object.keys(this.callbacks).map((key: string) =>
          this.socket.emit('registerCallback', event, this.callbacks[key]),
        )
      })
      .on('roomMembers', (namespace: string, roomId: string, userIds: string[]) => {
        this.roomMembersCallback(namespace, roomId, userIds)
      })
      .on('disconnect', () => {
        this.socket.io.connect()
      })
    return this
  }

  public registerCallback(event: string, action: any) {
    this.callbacks[event] = action

    if (this.socket) {
      this.socket.emit('registerCallback', event, action)
    }

    return this
  }

  public deregisterCallback(event: string) {
    delete this.callbacks[event]

    if (this.socket.connected) {
      this.socket.emit('deregisterCallback', event)
    }

    return this
  }

  public subscribe(callback: MessageCallback) {
    if (!this.socket) {
      throw new Error('You must call connect before subscribe!')
    }

    this.messageCallback = callback

    return this
  }

  public roomUpdates(callback: RoomMemberCallback) {
    if (!this.socket) {
      throw new Error('You must call connect before roomUpdates!')
    }

    this.roomMembersCallback = callback

    return this
  }

  public subscribeToRoom(id: string, token: string) {
    if (this.rooms[id]) {
      if (this.socket) {
        this.socket.emit('subscribeToRoom', id, token)
      }
    }
    this.rooms[id] = token

    return this
  }

  public unsubscribeToRoom(id: string) {
    delete this.rooms[id]
    if (this.socket) {
      this.socket.emit('unsubscribeToRoom', id)
    }

    return this
  }

  public getSocketId(): string | null {
    if (this.socket && this.socket.id) {
      return this.socket.id.split('#')[1]
    } else {
      return null
    }
  }
}
