export const size = {
  base: '14px',

  normal: '14px',
  small: '12px',
  medium: '1.1428571428571428rem',
  large: '1.7142857142857142rem',
}

export const weight = {
  base: 400,

  bold: 700,
  medium: 600,
  normal: 400,
}

export const family = {
  base: '\'Open Sans\', sans-serif',
}
