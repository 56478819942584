import { FC } from 'react'

import { useKConversationHook } from '../common/kustomerHooks'

import { EditableWorkflowElement } from 'happitu/src/types/workflowElements'

const KustomerSetStatus: FC<EditableWorkflowElement> = (props) => {
  useKConversationHook({
    status: props.attributes.value,
  })
  return null
}

export default KustomerSetStatus
