import styled from 'styled-components'

import getStyle from 'happitu/src/getStyle'

export const MenuSection = styled.section`
  padding: 1em;

  &:not(:first-child) {
    border-top: 1px solid ${getStyle('border-color')};
  }
`
