import { Editor, Transforms, NodeEntry, Node, Range, Element } from 'slate'
import { v4 as uuid } from 'uuid'

import {
  ALL_MARKS,
  MARK_TYPES,
  LIST_TYPES,
} from 'happitu/src/types/models/richTextEditor'
import {
  ElementType,
  LinkElement,
  OrderedListElement,
  ParagraphElement,
  UnorderedListElement,
} from 'happitu/src/types/slate-types'

// Check if the passed type is the active block type, and set the type to either the new type or revert to a paragraph node.
export const toggleBlock = (editor: Editor, newType: ElementType) => {
  if (isBlockActive(editor, newType)) {
    setBlockType(editor, ElementType.Paragraph)
  } else {
    setBlockType(editor, newType)
  }
}

// Config to determine what properties each type of node accepts, as well as the default value for each.
// TODO: Exclude voids.
const whitelistConfig: Record<ElementType, Record<string, any>> = {
  [ElementType.Paragraph]: {
    indentLevel: 0,
    nodeId: '',
  },
  [ElementType.OrderedList]: {
    indentLevel: 0,
    startsAt: 1,
    nodeId: '',
  },
  [ElementType.UnorderedList]: {
    indentLevel: 0,
    nodeId: '',
  },
  [ElementType.HeadingOne]: {
    nodeId: '',
  },
  [ElementType.HeadingTwo]: {
    nodeId: '',
  },
  [ElementType.BlockQuote]: {
    nodeId: '',
  },

  // Voids. Likely shouldn't be coerced into a new type, and should instead be removed and replaced.
  [ElementType.Image]: {},
  [ElementType.HelpTopicAction]: {},
  [ElementType.Link]: {},
  [ElementType.WorkflowVariable]: {},
  [ElementType.SectionBreak]: {},
  [ElementType.Attachment]: {},
}

// Coerce blocks into new types. This is where we should set, transfer, or wipe custom attributes between coercions as needed.
// eslint-disable-next-line complexity
// TODO: Exclude voids.
// NOTE: A lot of the logic for ordered lists is handled during normalization (check the withShortcuts file). This is because a given ordered list item needs to be aware of its position in list, so surrounding list items are often updated concurrently.
// This also saves us from handling the same logic for other multiple types of actions, such as converting a node to or from an ordered list item, deleting a list item, or inserting a new one. The logic is consolidated, and theoretically prevents the possibility of list items getting out of order.
export const setBlockType = (editor: Editor, newType: ElementType) => {
  const currentNode = Editor.above(editor, {
    match: (n) => Editor.isBlock(editor, n),
  })
  Transforms.setNodes(editor, { type: newType })

  if (currentNode) {
    // Get the keys of the props of the previous node type.
    const prevProps = Object.keys(currentNode[0]).filter(
      (p) => p !== 'type' && p !== 'children',
    )
    // Determine which ones to keep, remove, and what defaults are missing.
    const sharedProps = prevProps.filter((p) =>
      Object.keys(whitelistConfig[newType]).includes(p),
    )
    const propsToRemove = prevProps.filter(
      (p) => !Object.keys(whitelistConfig[newType]).includes(p),
    )
    const missingDefaultProps = Object.keys(whitelistConfig[newType]).filter(
      (p) => !sharedProps.includes(p),
    )

    // Wipe irrelevant properties (shared props can just remain).
    Transforms.unsetNodes(editor, propsToRemove)

    // Add any missing properties.
    const newProps: { [key: string]: any } = {}
    missingDefaultProps.forEach((p) => {
      newProps[p] = whitelistConfig[newType][p]
    })
    Transforms.setNodes(editor, newProps)
  }
}

export const removeAllMarks = (editor: Editor) => {
  ALL_MARKS.forEach((mark) => Editor.removeMark(editor, mark))
}

export const toggleMark = (editor: Editor, mark: MARK_TYPES, value: any = true) => {
  const isActive = isMarkActive(editor, mark)

  if (isActive) {
    Editor.removeMark(editor, mark)
  } else {
    Editor.addMark(editor, mark, value)
  }
}

export const isBlockActive = (editor: Editor, block: ElementType) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => Element.isElement(n) && n.type === block,
  })

  return !!match
}

export const isMarkActive = (editor: Editor, mark: MARK_TYPES) => {
  const marks = Editor.marks(editor)
  return marks ? marks[mark] === true : false
}

export const indentLine = (
  editor: Editor,
  entry: NodeEntry<ParagraphElement | OrderedListElement | UnorderedListElement>,
) => {
  const [node, path] = entry
  // TODO: Maybe use config for this check.
  // Only indent paragraphs and list items.
  if ([...LIST_TYPES, ElementType.Paragraph].includes(node.type)) {
    const prevIndentLevel = node.indentLevel
    const indentLevel = prevIndentLevel < 5 ? prevIndentLevel + 1 : prevIndentLevel
    Transforms.setNodes(editor, { indentLevel }, { at: path })
  }
}

export const indentSelection = (editor: Editor) => {
  const blockNodes = Editor.nodes<
    ParagraphElement | OrderedListElement | UnorderedListElement
  >(editor, { match: (n) => Editor.isBlock(editor, n) })
  for (const blockNode of blockNodes) {
    indentLine(editor, blockNode)
  }
}

export const unindentLine = (
  editor: Editor,
  entry: NodeEntry<ParagraphElement | OrderedListElement | UnorderedListElement>,
) => {
  const [node, path] = entry
  // TODO: Maybe use config for this check.
  // Only unindent paragraphs and list items.
  if ([...LIST_TYPES, ElementType.Paragraph].includes(node.type)) {
    const prevIndentLevel = node.indentLevel
    const indentLevel = prevIndentLevel > 0 ? prevIndentLevel - 1 : prevIndentLevel
    Transforms.setNodes(editor, { indentLevel }, { at: path })
  }
}

export const unindentSelection = (editor: Editor) => {
  const blockNodes = Editor.nodes<
    ParagraphElement | OrderedListElement | UnorderedListElement
  >(editor, { match: (n) => Editor.isBlock(editor, n) })
  for (const blockNode of blockNodes) {
    unindentLine(editor, blockNode)
  }
}

// Check if the current ordered list item has a sibling located after the children
export const getNextOLSibling = (
  editor: Editor,
  currentNode: NodeEntry<OrderedListElement>,
  indentLevel: number,
): NodeEntry<OrderedListElement> | null => {
  const nextNode = Editor.next<OrderedListElement>(editor, {
    at: currentNode[1],
    match: (n) => Editor.isBlock(editor, n),
  })
  if (!nextNode) return null
  if (nextNode[0].type !== ElementType.OrderedList) return null
  if (nextNode[0].indentLevel === indentLevel) return nextNode
  return getNextOLSibling(editor, nextNode, indentLevel)
}

export const updateNextOrderedList = (
  editor: Editor,
  nextNode: NodeEntry<OrderedListElement>,
  indentLevel: number,
  newStartsAt: number,
) => {
  // Update the next node if it's at the same indentation level.
  if (
    nextNode[0].indentLevel === indentLevel &&
    nextNode[0].startsAt !== newStartsAt + 1
  ) {
    Transforms.setNodes(
      editor,
      { startsAt: newStartsAt + 1 },
      {
        at: nextNode[1],
        match: (n) => Element.isElement(n) && n.type === ElementType.OrderedList,
      },
    )
  } else if (nextNode[0].indentLevel < indentLevel) {
    const currentNode = Editor.previous(editor, {
      at: nextNode[1],
      match: (n) => Editor.isBlock(editor, n),
    })
    if (currentNode) {
      const startsAt = getOLStartsAt(editor, currentNode, nextNode[0].indentLevel)
      Transforms.setNodes(
        editor,
        { startsAt },
        {
          at: nextNode[1],
          match: (n) => Element.isElement(n) && n.type === ElementType.OrderedList,
        },
      )
    }
  } else if (nextNode[0].indentLevel > indentLevel && nextNode[0].startsAt !== 1) {
    Transforms.setNodes(
      editor,
      { startsAt: 1 },
      {
        at: nextNode[1],
        match: (n) => Element.isElement(n) && n.type === ElementType.OrderedList,
      },
    )
  }
}

// Get the startsAt for an ordered list item. Recursively check the previous nodes until you hit either a sibling, a parent node, or the beginning of the ordered list.
export const getOLStartsAt = (
  editor: Editor,
  prevNode: NodeEntry<Node>,
  indentLevel: number,
): number => {
  if (!Element.isElement(prevNode[0])) return 1
  // If you hit the start of the entire ordered list without finding a sibling, return 1
  if (prevNode[0].type !== ElementType.OrderedList) return 1
  // If you hit the parent list without finding a sibling, return 1
  if (prevNode[0].indentLevel < indentLevel) return 1
  // If you find a sibling, increment the startsAt by 1
  if (prevNode[0].indentLevel === indentLevel) return prevNode[0].startsAt + 1
  const newPrevNode = Editor.previous(editor, {
    at: prevNode[1],
    match: (n) => Editor.isBlock(editor, n),
  })
  if (!newPrevNode) return 1
  // Else check the next previous node
  return getOLStartsAt(editor, newPrevNode, indentLevel)
}

export const isLinkActive = (editor: Editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) => Element.isElement(n) && n.type === ElementType.Link,
  })
  return !!link
}

const urlRegex = /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))/i

export const isUrl = (value: string) => urlRegex.test(value)

export const setLink = (editor: Editor, value: string, nodeId?: ID) => {
  if (isLinkActive(editor)) {
    Transforms.unwrapNodes(editor, {
      at: [],
      match: (n) =>
        Element.isElement(n) && n.type === ElementType.Link && n.nodeId === nodeId,
    })
  }

  // TODO: Don't do anything when the selection is collapsed.
  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)

  const createLinkElement = (url: string, value: string): LinkElement => {
    return {
      type: ElementType.Link,
      url: url,
      children: isCollapsed ? [{ text: value }] : [],
      nodeId: uuid(),
    }
  }

  if (isCollapsed) {
    const values = value.split(urlRegex).reduce((acc, v) => {
      if (!v) return acc
      if (isUrl(v)) {
        return [...acc, createLinkElement(v, v)]
      }
      return [
        ...acc,
        {
          text: v,
        },
      ]
    }, [])

    if (values.length === 1) {
      Transforms.insertNodes(editor, createLinkElement(value, value))
    } else {
      const p: ParagraphElement = {
        type: ElementType.Paragraph,
        indentLevel: 0,
        children: values,
        nodeId: uuid(),
      }
      Transforms.insertNodes(editor, p)
    }
  } else {
    // Check if the user already entered a valid url, else update the value. Leave empty string as-is to handle other behaviors.
    const url = value === '' || isUrl(value) ? value : 'http://' + value
    Transforms.wrapNodes(editor, createLinkElement(url, value), { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

export const isImageUrl = (value: string) =>
  isUrl(value) && /\.(jpeg|jpg|gif|png)$/.test(value)

// TODO: Refactor to use a helper to check ancestor nodes for a type match.
export const isVariable = (editor: Editor) => {
  const [variable] = Editor.nodes(editor, {
    match: (n) => Element.isElement(n) && n.type === ElementType.WorkflowVariable,
  })
  return !!variable
}
