import * as React from 'react'

import VoidCallout from 'happitu/src/components/Layout/VoidCallout'

export default function NotFound(props: {
  resource?: string
  children?: React.ReactNode
}) {
  return (
    <VoidCallout>
      <h2>{`Hmm. That's odd.`}</h2>
      {props.resource
        ? `The ${props.resource.toLowerCase()} you're looking
        for appears to be missing.`
        : props.children}
    </VoidCallout>
  )
}
