import React from 'react'
import { useSlate } from 'slate-react'
import styled from 'styled-components'

import BlockButton from './BlockButton'
import InsertHelpTopicActionButton from './InsertHelpTopicActionButton'
import InsertImageButton from './InsertImageButton'
import InsertLinkButton from './InsertLinkButton'
import MarkButton from './MarkButton'
import { Divider } from './Toolbar.styles'
import TextStyleButton from './text-style-button'

import getStyle from 'happitu/src/getStyle'
import { error } from 'happitu/src/helpers/loggerHelper'
import { ElementType } from 'happitu/src/types/slate-types'

interface Props extends React.ComponentProps<'div'> {
  collection?: string
  id?: ID
}

const Toolbar = (props: Props) => {
  const editor = useSlate()

  if (editor.allowMedia) {
    if (!props.collection) {
      error('Missing collection name necessary for image uploads.')
    }
    if (!props.id) {
      error('Missing record id necessary for image uploads.')
    }
  }

  return (
    <div className={props.className}>
      <TextStyleButton />
      <Divider />
      <MarkButton mark="bold" icon="bold" />
      <MarkButton mark="italic" icon="italic" />
      <MarkButton mark="underline" icon="underline" />
      <MarkButton mark="strikethrough" icon="strikethrough" />
      <InsertLinkButton />

      <Divider />
      <BlockButton block={ElementType.HeadingOne} icon="heading-one" />
      <BlockButton block={ElementType.HeadingTwo} icon="heading-two" />
      <BlockButton block={ElementType.OrderedList} icon="numbered-list" />
      <BlockButton block={ElementType.UnorderedList} icon="bulleted-list" />
      <BlockButton block={ElementType.BlockQuote} icon="script-blurb" />
      <Divider />
      {editor.allowMedia && !!props.collection && !!props.id && (
        <InsertImageButton collection={props.collection} id={props.id} />
      )}
      {editor.allowGuidedHelpTopics && <InsertHelpTopicActionButton />}
    </div>
  )
}

export default styled(Toolbar)`
  align-items: center;
  border-radius: 6px;
  border: 1px solid ${getStyle('border-color')};
  display: flex;
  padding: 0.75em 1em;
  z-index: 1;

  > :last-child {
    margin-right: 0;
  }
`
