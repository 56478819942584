import * as React from 'react'
import styled, { css } from 'styled-components'
import { ifProp, switchProp } from 'styled-tools'

import getStyle from 'happitu/src/getStyle'
import { zIndex, font } from 'theme'

type Types = 'error' | 'info'
interface Props {
  className?: string
  children: React.ReactNode
  fixed?: boolean
  style?: React.CSSProperties
  type?: Types
}

const Wrapper = styled.div<{ fixed?: boolean }>`
  height: 100%;
  width: 100%;
  min-height: 100px;

  ${ifProp(
    'fixed',
    css`
      background: rgba(255, 255, 255, 0.7);
      position: fixed;
      top: 0;
      left: 0;
      z-index: ${zIndex('modal')};
    `,
    css`
      position: relative;
    `,
  )};
`

const Callout = styled.div<{ type?: Types }>`
  color: ${switchProp(
    'type',
    {
      error: getStyle('voidCallout-error-color'),
    },
    getStyle('voidCallout-info-color'),
  )};
  font-weight: ${font('weight', 'medium')};
  left: 50%;
  line-height: 1.5em;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  user-select: none;

  h2 {
    margin-bottom: 2em;
    font-size: 1em;
    font-weight: ${font('weight', 'medium')};
  }
`

function VoidCallout({ children, fixed, className, ...props }: Props) {
  return (
    <Wrapper fixed={fixed} className={className}>
      <Callout {...props}>{children}</Callout>
    </Wrapper>
  )
}

export default styled(VoidCallout)``
