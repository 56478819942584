import autobind from 'autobind-decorator'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import memoize from 'memoize-one'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components'

import styles from './FuzzySelect.scss'
import FuzzySearchMenu from './FuzzySelect/FuzzySelectMenu'

import { FuzzySelectOptions } from 'happitu/src/constants/shapes'

function getLabel(value, options) {
  let option, result
  for (let i = 0; i < options.length; i++) {
    option = options[i]
    if (option.options) {
      result = getLabel(value, option.options)
      if (result) {
        return result
      }
    } else {
      if (option.value === value) {
        return option.name
      }
    }
  }
}

class FuzzySelect extends Component {
  static propTypes = {
    // Field props
    allowDeselect: PropTypes.bool,
    className: PropTypes.string,
    createLabel: PropTypes.string,
    defaultActive: PropTypes.string,
    disabled: PropTypes.bool,
    menuWidth: PropTypes.number,
    multiple: PropTypes.bool,
    openOnMount: PropTypes.bool,
    options: FuzzySelectOptions.isRequired,
    placeholder: PropTypes.string,
    preventedOptions: FuzzySelectOptions,
    searchOptions: PropTypes.array,
    searchPlaceholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

    // Callback hooks
    onCreate: PropTypes.func,
    onRemove: PropTypes.func,
    onSelect: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onSearch: PropTypes.func,

    // Templates
    functionTemplate: PropTypes.any,
    valueTemplate: PropTypes.any,
  }

  constructor(props) {
    super(props)
    this.refocusInput = React.createRef()
    this.focusInput = React.createRef()
  }

  state = {
    menu: false,
  }

  componentDidMount() {
    if (this.props.openOnMount) {
      this.setState({ menu: true })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.menu && prevState.menu) {
      this.refocusInput.current.removeAttribute('disabled')
      this.focusInput.current.setAttribute('disabled', 'true')
      this.refocusInput.current.focus()
      setTimeout(() => {
        this.focusInput.current.removeAttribute('disabled', 'true')
      }, 1)
    }
  }

  closeMenu = () => {
    this.setState({ menu: false })

    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  openMenu = () => {
    if (!this.props.disabled) {
      this.setState({ menu: true })

      if (this.props.onOpen) {
        this.props.onOpen()
      }
    }
  }

  @autobind
  handleRef(ref) {
    this._ref = ref
  }

  label = memoize((value, options) => getLabel(value, options))

  // Disables refocusInput input on blur for the next tab carousel
  disableRefocus = (e) => e.target.setAttribute('disabled', 'true')

  renderMenu() {
    return (
      <FuzzySearchMenu
        allowDeselect={this.props.allowDeselect}
        anchorRef={this._ref}
        createLabel={this.props.createLabel}
        defaultActive={this.props.defaultActive}
        hasOptionGroups
        isOpen={this.state.menu}
        menuWidth={this.props.menuWidth}
        multiple={this.props.multiple}
        onClose={this.closeMenu}
        onCreate={this.props.onCreate}
        onSelect={this.props.onSelect}
        onSearch={this.props.onSearch}
        options={this.props.options}
        placeholder={this.props.searchPlaceholder}
        preventedOptions={this.props.preventedOptions}
        searchOptions={this.props.searchOptions}
        selected={this.props.value}
      />
    )
  }

  renderValue() {
    const { valueTemplate } = this.props
    const { menu } = this.state
    const label = this.label(this.props.value, this.props.options)

    // Opt for value template if one is provided
    if (valueTemplate) {
      return <valueTemplate.type label={label} isOpen={menu} {...valueTemplate.props} />
    }

    return (
      <div className={styles.select}>
        {label || <i className={styles.placeholder}>{this.props.placeholder}</i>}
      </div>
    )
  }

  renderFunctionTemplate() {
    const { functionTemplate, onSelect, onCreate, onRemove, value } = this.props
    if (functionTemplate && !isEmpty(value)) {
      return (
        <functionTemplate.type
          {...functionTemplate.props}
          {...{ onSelect, onCreate, onRemove, value, onClick: this.openMenu }}
          label={this.label(this.props.value, this.props.options)}
        />
      )
    }
  }

  render() {
    const classes = classNames(styles.select, this.props.className, {
      [styles.disabled]: this.props.disabled,
    })

    return (
      <div className={styles.wrapper} ref={this.handleRef}>
        <input
          className={styles.input}
          disabled
          onBlur={this.disableRefocus}
          ref={this.refocusInput}
          type="text"
        />
        <input
          className={styles.input}
          disabled={this.state.menu}
          onFocus={this.openMenu}
          ref={this.focusInput}
          type="text"
        />
        <div onClick={this.openMenu} className={classes}>
          {this.renderValue()}
        </div>
        {this.renderFunctionTemplate()}
        {this.renderMenu()}
      </div>
    )
  }
}

export default styled(FuzzySelect)``
