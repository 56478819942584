import request from './request'

import {
  handleReject,
  handleSuccess,
} from 'happitu/src/services/happitu-api/responseHandler'

export interface Params {
  nextWorkflowId?: ID
}

export const getFinalizeInfoRequest = (interactionId: ID, params: Params = {}) => {
  return new Promise((resolve, reject) => {
    return request
      .get(`/ticket-interaction-finalize-info/${interactionId}`)
      .query(params)
      .send()
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r)))
  })
}
