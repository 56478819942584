import { getOrganizationProfile } from './helpers/currentUserHelpers'
import { getTheme as getStaticTheme } from './helpers/localizeHelper'

import { getRouteName } from 'happitu/src/helpers/routeHelpers'
import { getUserOption } from 'happitu/src/helpers/user'
import darkTheme from 'happitu/src/themes/darkTheme'
import * as theme from 'happitu/src/themes/default'
import defaultTheme from 'happitu/src/themes/defaultTheme'
import logixxTheme from 'happitu/src/themes/logixxTheme'

const themeConfig: Record<string, Partial<Record<keyof typeof defaultTheme, string>>> = {
  default: defaultTheme,
  dark: darkTheme,
  logixx: logixxTheme,
}

const getTheme = () => {
  if (!window.store) {
    return { ...theme, currentTheme: getStaticTheme() }
  }

  const routeName = getRouteName()
  const orgProfile = getOrganizationProfile()
  // Only show the light theme for the workflow designer.
  if (/app\.workflow/.test(routeName))
    return {
      ...theme,
      currentTheme: orgProfile?.themeSettings?.themeName
        ? themeConfig[orgProfile.themeSettings.themeName]
        : defaultTheme,
    }

  const interfaceOptions = getUserOption('interfaceOptions')
  const themeKey =
    orgProfile?.themeSettings?.themeName || interfaceOptions?.theme || 'default'

  // Fall back on the default theme if anything is missing in the current theme.
  const currentTheme =
    themeKey === 'default' ? defaultTheme : { ...defaultTheme, ...themeConfig[themeKey] }

  return { ...theme, currentTheme }
}

export default getTheme
