import { Editor, Transforms } from 'slate'
import { jsx } from 'slate-hyperscript'

import { createVariableNode } from './withWorkflowVariables'

import { textColors } from 'happitu/src/components/RichTextEditor/Toolbar/text-style-button'
import { ElementType } from 'happitu/src/types/slate-types'

type NodeMap = Record<string, (el: HTMLElement) => {}>

const ELEMENT_TAGS: NodeMap = {
  A: (el) => ({ type: ElementType.Link, url: el.getAttribute('href') }),
  H1: () => ({ type: ElementType.HeadingOne }),
  H2: () => ({ type: ElementType.HeadingTwo }),
  IMG: (el) => ({ type: ElementType.Image, url: el.getAttribute('src') }),
  LI: () => ({ type: ElementType.UnorderedList }),
  OL: () => ({ type: ElementType.OrderedList }),
  P: () => ({ type: ElementType.Paragraph }),
}

const TEXT_TAGS: NodeMap = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  B: () => ({ bold: true }),
  U: () => ({ underline: true }),
}

const getParentElement = (el: HTMLElement) => {
  const { nodeName } = el
  return nodeName === 'PRE' && el.childNodes[0] && el.childNodes[0].nodeName === 'CODE'
    ? el.childNodes[0]
    : el
}

// eslint-disable-next-line complexity
export const deserialize = (el: HTMLElement): any => {
  if (el.nodeType === 3) {
    return el.textContent
  } else if (el.nodeType !== 1) {
    return null
  } else if (el.nodeName === 'BR') {
    return '\n'
  }

  const { nodeName } = el
  const parent = getParentElement(el)
  const children = Array.from(parent.childNodes).map(deserialize).flat()

  if (el.nodeName === 'BODY') {
    return jsx('fragment', {}, children)
  }

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el)
    return jsx('element', attrs, children)
  }

  if (nodeName === 'SPAN' && (el.style.color || el.style.backgroundColor)) {
    const color = Object.keys(textColors).find(
      (key) =>
        textColors[key] === el.style.color ||
        textColors[key] === el.style.backgroundColor,
    )
    if (color) {
      return children.map((child) => jsx('text', { highlight: color }, child))
    }
  }

  if (el.dataset.variable) {
    return jsx('element', createVariableNode(el.dataset.variable), children)
  }

  if (el.dataset.slateZeroWidth === 'n') {
    return jsx('element', ELEMENT_TAGS.P(el), children)
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el)
    return children.map((child) => jsx('text', attrs, child))
  }

  return children
}

const withHtml = (editor: Editor) => {
  const { insertData, isInline, isVoid } = editor

  editor.isInline = (element) => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.isVoid = (element) => {
    return element.type === 'image' ? true : isVoid(element)
  }

  editor.insertData = (data) => {
    const html = data.getData('text/html')

    if (html) {
      const parsed = new DOMParser().parseFromString(html, 'text/html')
      if (parsed.doctype !== null) {
        const fragment = deserialize(parsed.body)
        Transforms.insertFragment(editor, fragment)
        return
      }
    }

    insertData(data)
  }

  return editor
}

export default withHtml
