import PropTypes from 'prop-types'
import React  from 'react'

import { Checkbox, Label, Wrapper } from './CheckboxField.styles'
import { FieldWrapper } from './FieldUtils'

const CheckboxField = ({ label, checked, style }) => (
  <FieldWrapper style={ style }>
    <Wrapper>
      <Checkbox>
        <input type="checkbox" checked={ checked }/>
      </Checkbox>
      <Label value={ label }/>
    </Wrapper>
  </FieldWrapper>
)

CheckboxField.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  style: PropTypes.object,
}

export default CheckboxField
