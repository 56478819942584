import { Ref, useRef, useLayoutEffect } from 'react'

const setPixelWidth = <T extends HTMLElement>(
  ref: T,
  width?: number | null,
  delay = 1,
) => {
  setTimeout(() => (ref.style.width = !width ? '' : `${width}px`), delay)
  return delay + 10
}

const useShadowSize = <T extends HTMLElement>(
  enable?: boolean,
  speed = 250,
): [Ref<T>] => {
  const ref = useRef<T>(null)
  const previousWidth = useRef<number>()
  useLayoutEffect(() => {
    if (ref.current) {
      const nextWidth = ref.current.getBoundingClientRect().width
      let offset = 0
      if (
        previousWidth.current &&
        // if the loading state is wider than the current state
        ((previousWidth.current > nextWidth && !enable) ||
          (previousWidth.current < nextWidth && enable))
      ) {
        offset = setPixelWidth(ref.current, previousWidth.current, offset)
        offset = setPixelWidth(ref.current, nextWidth, offset)
        setPixelWidth(ref.current, null, offset + speed)
      }
      previousWidth.current = nextWidth
    }
  }, [enable])
  return [ref]
}

export default useShadowSize
