import { HelpTopicPageStore } from './helpTopics'

import { ElementType } from 'happitu/src/types/slate-types'

export type MARK_TYPES =
  | 'bold'
  | 'italic'
  | 'underline'
  | 'strikethrough'
  | 'code'
  | 'highlight'

export const ALL_MARKS: MARK_TYPES[] = [
  'bold',
  'code',
  'italic',
  'strikethrough',
  'underline',
  'highlight',
]
export type BLOCK_TYPES =
  | ElementType.Attachment
  | ElementType.BlockQuote
  | ElementType.HeadingOne
  | ElementType.HeadingTwo
  | ElementType.HelpTopicAction
  | ElementType.Image
  | ElementType.OrderedList
  | ElementType.Paragraph
  | ElementType.SectionBreak
  | ElementType.UnorderedList

export const LIST_TYPES: ElementType[] = [
  ElementType.OrderedList,
  ElementType.UnorderedList,
]
export const MULTILINE_BLOCK_TYPES: ElementType[] = [ElementType.BlockQuote]
export type INLINE_BLOCK_TYPES = ElementType.Link | ElementType.WorkflowVariable
export type ELEMENT_TYPES = BLOCK_TYPES | INLINE_BLOCK_TYPES

export interface SlateRecordRefs {
  helpTopicPages?: HelpTopicPageStore
}
