import { OVERWRITE_PREFIX } from 'happitu/src/helpers/variableHelper'

const paramExcludeList = [
  'redirectTo',
  'organizationId',
  'stepIndex',
  'workflowId',
  'authHash',
]

type RouteParams = Record<string, any>

export const parseParamData = (otherParams: RouteParams) => {
  return Object.keys(otherParams).reduce<{ [key: string]: any }>(
    (acc, param: keyof RouteParams) => {
      if (!paramExcludeList.includes(param)) {
        const paramKey = decodeURIComponent(param)
        const metaKey = OVERWRITE_PREFIX.test(paramKey)
          ? 'variableOverwrites'
          : 'externalData'

        if (metaKey === 'externalData') {
          acc[metaKey][paramKey.replace(OVERWRITE_PREFIX, '')] = otherParams[param]
        } else {
          acc[metaKey][
            paramKey.replace(OVERWRITE_PREFIX, '').replace(/\s/g, '_').toLowerCase()
          ] = otherParams[param]
        }
      }
      return acc
    },
    {
      externalData: {},
      variableOverwrites: {},
    },
  )
}
