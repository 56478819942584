import React from 'react'

import { ButtonWrapper } from './FullscreenImagePreview.styles'

import Icon from 'happitu/src/components/Icon'

interface Props {
  isZoomed: boolean
  toggleZoom: () => void
}

const ZoomButton = ({ isZoomed, toggleZoom }: Props) => {
  const type = isZoomed ? 'shrink' : 'enlarge'
  const title = isZoomed ? 'Zoom to fit' : 'Zoom to actual size'

  const handleClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation()
    toggleZoom()
  }

  return (
    <ButtonWrapper onClick={handleClick} title={title}>
      <Icon type={type} size="medium" />
    </ButtonWrapper>
  )
}

export default ZoomButton
