import { useMemo, useContext } from 'react'
import styled from 'styled-components'

import { FieldWrapper } from 'happitu/src/components/Fields/FieldUtils'
import Icon from 'happitu/src/components/Icon'
import Box from 'happitu/src/components/box'
import Text from 'happitu/src/components/text'
import { HelpTopicType } from 'happitu/src/constants/helpTopicConstants'
import getStyle from 'happitu/src/getStyle'
import { error } from 'happitu/src/helpers/loggerHelper'
import {
  connectTicketContext,
  TicketComponentProps,
} from 'happitu/src/modules/ticketing/ticketing'
import TicketingHelpTopicsContext from 'happitu/src/modules/ticketing/ticketing-help-topics.context'
import { HelpTopicPageRecord, HelpTopicRecord } from 'happitu/src/types/models/helpTopics'
import { HelpTopicDisplayProps } from 'happitu/src/types/workflowElements'

const HelpTopic = ({
  attributes: { helpTopicImpressionId },
  ...props
}: HelpTopicDisplayProps & TicketComponentProps<'helpTopics' | 'helpTopicPages'>) => {
  if (!helpTopicImpressionId) return null

  const helpTopic = props.helpTopics.findByImpression<HelpTopicRecord>(
    helpTopicImpressionId,
  )
  const { advanceScreen } = useContext(TicketingHelpTopicsContext)

  if (!helpTopic) {
    error(`Missing help topic: ${helpTopicImpressionId}`)
    return null
  }

  const previewLength = 80
  const preview = useMemo(() => {
    if (!props.helpTopicPages) return ''
    const firstPage = props.helpTopicPages.findById<HelpTopicPageRecord>(
      helpTopic.pageIds[0],
    )
    const subText = firstPage?.searchText.substr(0, previewLength)
    return `${subText}${subText && subText.length === previewLength ? '...' : ''}`
  }, [helpTopic])

  const handleClick = () => {
    if (helpTopic.type === HelpTopicType.Link && props.helpTopicPages) {
      const firstPage = props.helpTopicPages.findById<HelpTopicPageRecord>(
        helpTopic.pageIds[0],
      )
      if (firstPage) {
        window.open(firstPage.contents[0].url)
      } else {
        error(
          `HelpTopic.tsx: unable to load help topic link. helpTopicID: ${helpTopic.id}`,
        )
      }
    } else {
      advanceScreen(helpTopicImpressionId, helpTopic.pageIds[0])
    }
  }

  return (
    <FieldWrapper>
      <Container
        as="button"
        direction="ltr"
        onClick={handleClick}
        padding="1"
        rounded
        bg="alt"
      >
        <StyledIcon type="graduation-cap" size="medium" />
        <Box paddingLeft="1">
          <Text bold color="default">
            {helpTopic.name}
          </Text>
          <Text color="sub">{preview ?? ''}</Text>
        </Box>
      </Container>
    </FieldWrapper>
  )
}

const StyledIcon = styled(Icon)`
  color: ${getStyle('text-sub-color')};
  opacity: 0.85;
`

const Container = styled(Box)`
  border: 0;

  &:active {
    background: ${getStyle('button-active-background')};
  }
`

export default connectTicketContext<HelpTopicDisplayProps>([
  'helpTopics',
  'helpTopicPages',
])(HelpTopic)
