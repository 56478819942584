import React from 'react'

import WorkflowStepViewer from './components/workflow-step-viewer'
import { connectTicketContext, TicketComponentProps } from './ticketing'

import { RefreshLink } from 'happitu/src/components/Buttons'
import VoidCallout from 'happitu/src/components/Layout/VoidCallout'
import BaseLoader from 'happitu/src/components/Loaders/BaseLoader'
import Box from 'happitu/src/components/box'
import Text from 'happitu/src/components/text'
import { VariableProvider } from 'happitu/src/modules/workflow-variables'

interface PublicProps {
  onBack?: () => void
  onAdvanceSuccess: (interaction: TicketInteractionRecord) => void
  preventAdvancement?: boolean
}

let longTicketLoad: number

const StepLoader = (props: any) => {
  const [reloadOffer, setReloadOffer] = React.useState(false)
  React.useEffect(() => {
    longTicketLoad = window.setTimeout(() => {
      props.debugState('Long ticket load time!')
      setReloadOffer(true)
    }, 5000)
    return () => {
      clearTimeout(longTicketLoad)
    }
  })

  return (
    <Box style={{ minHeight: '50vh', justifyContent: 'center' }}>
      <BaseLoader size="large" />
      {reloadOffer && (
        <VoidCallout style={{ marginTop: 90 }}>
          <Text>This ticket is taking a long time to load. Want to try reloading?</Text>
          <RefreshLink>Reload Ticket</RefreshLink>
        </VoidCallout>
      )}
    </Box>
  )
}

const WorkflowViewer = (
  props: PublicProps &
    TicketComponentProps<
      | 'contacts'
      | 'currentContact'
      | 'currentStep'
      | 'currentStepValues'
      | 'tickets'
      | 'workflows'
      | 'workflowStepGroupSteps'
      | 'workflowActions'
      | 'fileUploads'
      | 'workflowElements'
      | 'advanceInteraction'
      | 'updateValues'
      | 'updateFileIds'
      | 'uploadFile'
      | 'variables'
    >,
) => {
  const handleStepAdvance = async (action: WorkflowActionRecord) => {
    const { validatedInteraction } = await props.advanceInteraction(action)
    if (validatedInteraction) props.onAdvanceSuccess(validatedInteraction)
  }

  return (
    <VariableProvider variables={props.variables}>
      <WorkflowStepViewer
        workflowStep={props.currentStep}
        interactionStep={props.currentStepValues}
        onAdvance={handleStepAdvance}
        onBack={props.onBack}
        onUpdateFileIds={props.updateFileIds}
        onUpload={props.uploadFile}
        onValueChange={props.updateValues}
        preventAdvancement={props.preventAdvancement}
        variables={props.variables}
        collections={{
          workflowElements: props.workflowElements,
          workflowActions: props.workflowActions,
          workflowSteps: props.workflowStepGroupSteps,
          fileUploads: props.fileUploads,
        }}
      />
    </VariableProvider>
  )
}

export default connectTicketContext<PublicProps>(
  [
    'contacts?',
    'currentContact?',
    'currentStep',
    'currentStepValues',
    'tickets?',
    'workflows?',
    'workflowStepGroupSteps?',
    'workflowActions?',
    'fileUploads?',
    'workflowElements?',
    'variables',
  ],
  ['advanceInteraction', 'updateValues', 'updateFileIds', 'uploadFile'],
)(WorkflowViewer, StepLoader)
