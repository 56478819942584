import * as React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { switchProp, ifProp } from 'styled-tools'

import getStyle from 'happitu/src/getStyle'
import { font } from 'theme'

interface Props {
  children: React.ReactNode
  className?: string
  type?: 'error' | 'warning' | 'success' | 'info' | 'blue'
  noIcon?: boolean
}

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const Notice = styled.div<Props>`
  border-radius: ${getStyle('modal-border-radius')};
  color: ${getStyle('text-default-color')};
  margin-bottom: 1em;
  padding: 1.5em;
  text-align: left;
  position: relative;
  animation: ${fadeIn} 180ms;
  padding-left: ${ifProp('noIcon', '1.5em', '4em')};

  a {
    cursor: pointer;
    text-decoration: underline;
  }

  ::before {
    font-family: 'icomoon';
    font-size: ${font('size', 'medium')};
    position: absolute;
    top: 1.5em;
    left: 1.5em;
    display: ${ifProp('noIcon', 'none', 'block')};
  }

  ${switchProp('type', {
    info: css`
      background: ${getStyle('notice-info-background')};
      color: ${getStyle('notice-info-color')};

      ::before {
        color: ${getStyle('notice-info-iconColor')};
        content: '👋';
        top: 1.25em;
      }
    `,
    blue: css`
      background: #248fc6;
      color: ${getStyle('notice-blue-color')};
      font-weight: 500;

      ::before {
        color: ${getStyle('notice-blue-iconColor')};
        content: '⏱';
        top: 1.25em;
      }
    `,
    success: css`
      background: ${getStyle('notice-success-background')};
      ::before {
        color: ${getStyle('notice-success-iconColor')};
        content: '🎉';
      }

      button {
        background: ${getStyle('noticeButton-success-background')};

        &:hover {
          background: ${getStyle('noticeButton-success-hover-background')};
        }
      }
    `,
    error: css`
      background: ${getStyle('notice-error-background')};
      ::before {
        color: ${getStyle('notice-error-iconColor')};
        content: '\\e919';
      }
    `,
  })}
`

Notice.defaultProps = {
  type: 'error',
}

export default Notice
