import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

import Box from 'happitu/src/components/box'
import getStyle from 'happitu/src/getStyle'
import { WebviewEditableProps } from 'happitu/src/types/workflowElements'

const Placeholder = styled(Box)`
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  margin-bottom: 2rem;
  min-height: 400px;
  border: 1px solid ${getStyle('border-color')};
  overflow: hidden;

  iframe {
    height: 100%;
    width: 100%;
    border: 0;
  }
`

const Webview = (props: WebviewEditableProps) => {
  const frameRef = useRef<HTMLIFrameElement>(null)
  const gotResizeMessage = (event: MessageEvent) => {
    if (frameRef.current && event.data.type === 'RESIZE') {
      frameRef.current.style.height = `${event.data.payload.height}px`
    }
  }

  useEffect(() => {
    window.addEventListener('message', gotResizeMessage, false)
    return () => {
      window.removeEventListener('message', gotResizeMessage)
    }
  }, [])

  return (
    <div>
      <Placeholder bg="alt" rounded>
        <iframe ref={frameRef} src={props.attributes.value} />
      </Placeholder>
    </div>
  )
}

export default Webview
