import { v1 as uuid } from 'uuid'

import createReducer from './createReducer'
import Store from './createStore'

import * as types from 'happitu/src/constants/ActionTypes'
import * as validators from 'happitu/src/constants/validators'
import { ADDRESS_DEFAULT_VALUE } from 'happitu/src/constants/workflowConstants'
import { WorkflowElementPreset } from 'happitu/src/types/workflowElements'

const BASE_ATTRIBUTES = {
  defaultValue: '',
  label: 'Sample Element Label',
  name: 'Sample Element Name',
  required: false,
}

export const elementPresets: {
  [key: string]: WorkflowElementPreset
} = {
  AddressField: {
    attributes: {
      ...BASE_ATTRIBUTES,
      defaultValue: ADDRESS_DEFAULT_VALUE,
      hasStreetAddress: true,
      label: 'Address Field',
    },
    component: 'AddressField',
    description: 'Capture physical address fields.',
    formInput: true,
    icon: {
      base: 'https://assets.happitu.com/public/workflow-elements/address-field.png',
      retina: 'https://assets.happitu.com/public/workflow-elements/address-field@2x.png',
    },
    id: '',
    inspectorFields: ['streetAddressOption', 'required'],
    name: 'Address Field',
    validators: {
      required: validators.requireAddress(),
    },
  },

  AppLauncher: {
    attributes: {
      label: 'Launch App',
      link: '',
    },
    component: 'AppLauncher',
    description: 'Prompt to open a third party application.',
    formInput: false,
    icon: {
      base: 'https://assets.happitu.com/public/workflow-elements/app-launcher.png',
      retina: 'https://assets.happitu.com/public/workflow-elements/app-launcher@2x.png',
    },
    id: 'element16',
    inspectorFields: [],
    name: 'App Launcher',
    validators: {},
  },

  AttachFileField: {
    attributes: {
      ...BASE_ATTRIBUTES,
      buttonLabel: 'Upload File',
      defaultValue: '',
      label: 'Attached Files',
    },
    component: 'AttachFileField',
    description: 'Attach files to a ticket.',
    formInput: true,
    icon: {
      base: 'https://assets.happitu.com/public/workflow-elements/attach-file-field.png',
      retina:
        'https://assets.happitu.com/public/workflow-elements/attach-file-field@2x.png',
    },
    id: 'element11',
    inspectorFields: ['name', 'label', 'defaultValue', 'value', 'required'],
    name: 'Attach File',
    validators: {
      required: validators.fieldValidator(),
    },
  },

  CallbackButton: {
    attributes: {
      ...BASE_ATTRIBUTES,
      label: 'Schedule Callback',
      callbackOptions: {
        skill: '',
        phoneNumber: '',
        screenPopUrl: ':ticket_url:',
      },
    },
    component: 'CallbackButton',
    description: 'Allow a user to trigger a callback using NICE InContact.',
    formInput: true,
    icon: {
      base: 'https://assets.happitu.com/public/workflow-elements/app-launcher.png',
      retina: 'https://assets.happitu.com/public/workflow-elements/app-launcher@2x.png',
    },
    id: 'element5',
    inspectorFields: ['name', 'label', 'skillId', 'options', 'required'],
    name: 'Schedule Callback',
    validators: {
      required: validators.requiredOptionValidator(),
    },
  },

  CallbackScheduler: {
    attributes: {
      ...BASE_ATTRIBUTES,
      label: 'Callback Scheduler',
      variableReference: '',
    },
    component: 'CallbackScheduler',
    description:
      'Allow an agent to schedule a callback. Note this requires an integration with a compatible telephony platform.',
    formInput: true,
    icon: {
      base: 'https://assets.happitu.com/public/workflow-elements/app-launcher.png',
      retina: 'https://assets.happitu.com/public/workflow-elements/app-launcher@2x.png',
    },
    id: 'element50',
    inspectorFields: ['name', 'label', 'skillId', 'options', 'required'],
    name: 'Scheduled Callback Time',
    validators: {
      required: validators.requiredOptionValidator(),
    },
  },

  CheckboxArray: {
    attributes: {
      ...BASE_ATTRIBUTES,
      defaultValue: [],
      options: [{ id: uuid(), label: 'New checkbox 1' }],
    },
    component: 'CheckboxArray',
    description: 'Select multiple choices from a group.',
    formInput: true,
    icon: {
      base: 'https://assets.happitu.com/public/workflow-elements/checkbox.png',
      retina: 'https://assets.happitu.com/public/workflow-elements/checkbox@2x.png',
    },
    id: 'element5',
    inspectorFields: ['name', 'label', 'defaultValue', 'options', 'required'],
    name: 'Multi-Select',
    validators: {
      required: validators.requiredOptionValidator(),
    },
  },

  DatePicker: {
    attributes: {
      ...BASE_ATTRIBUTES,
      label: 'Date',
    },
    component: 'DatePicker',
    description: 'Select a date.',
    formInput: true,
    icon: {
      base: 'https://assets.happitu.com/public/workflow-elements/date-picker.png',
      retina: 'https://assets.happitu.com/public/workflow-elements/date-picker@2x.png',
    },
    id: 'element13',
    inspectorFields: ['name', 'label', 'defaultValue', 'required'],
    name: 'Date Picker',
    validators: {
      date: validators.dateValidator('Please enter a valid date.'),
      required: validators.requiredValidator(),
    },
  },

  DateRangePicker: {
    attributes: {
      ...BASE_ATTRIBUTES,
      defaultValue: ['', ''],
      label: 'Date Range',
    },
    component: 'DateRangePicker',
    description: 'Select a range of dates.',
    formInput: true,
    icon: {
      base: 'https://assets.happitu.com/public/workflow-elements/date-range-picker.png',
      retina:
        'https://assets.happitu.com/public/workflow-elements/date-range-picker@2x.png',
    },
    id: 'element15',
    inspectorFields: ['name', 'label', 'defaultValue', 'rangeEnd', 'required'],
    name: 'Date Range Picker',
    validators: {
      date: validators.dateValidator('Please enter a valid date range.'),
      required: validators.requiredValidator(),
    },
  },

  DisplayText: {
    attributes: {
      placeholder: 'This is informational text for the agent ...',
      text: '',
    },
    component: 'DisplayText',
    description: 'Write interlaced text in a script step.',
    formInput: false,
    icon: {
      base: 'https://assets.happitu.com/public/workflow-elements/display-text.png',
      retina: 'https://assets.happitu.com/public/workflow-elements/display-text@2x.png',
    },
    id: 'element3',
    inspectorFields: ['name', 'label', 'text'],
    name: 'Direction',
    validators: {},
  },

  Documentation: {
    attributes: {
      defaultValue: '',
      invisible: true,
      placeholder: '',
    },
    component: 'Documentation',
    description: 'Append documentation to a ticket.',
    formInput: true,
    icon: {
      base: 'https://assets.happitu.com/public/workflow-elements/documentation.png',
      retina: 'https://assets.happitu.com/public/workflow-elements/documentation@2x.png',
    },
    id: 'element12',
    inspectorFields: [''],
    name: 'Auto-Document',
  },

  EmailField: {
    attributes: {
      ...BASE_ATTRIBUTES,
      autoPopulate: true,
      allowMultiEmail: false,
      defaultValue: [''],
      label: 'Email',
      placeholder: 'example@email.com',
    },
    component: 'EmailField',
    description: 'Capture and validate email addresses.',
    formInput: true,
    icon: {
      base: 'https://assets.happitu.com/public/workflow-elements/email-field.png',
      retina: 'https://assets.happitu.com/public/workflow-elements/email-field@2x.png',
    },
    id: 'element12',
    inspectorFields: [
      'name',
      'defaultValue',
      'label',
      'placeholder',
      'required',
      'autoPopulate',
    ],
    name: 'Email Field',
    validators: {
      email: validators.emailMultiValidator(),
      required: validators.requiredOptionValidator('This field is required'),
    },
  },

  SSNField: {
    attributes: {
      ...BASE_ATTRIBUTES,
      autoPopulate: true,
      allowMultiEmail: false,
      defaultValue: [''],
      label: 'Social Security Number',
      placeholder: 'xxx-xx-xxxx',
    },
    component: 'SSNField',
    description: 'Safely capture and social security numbers.',
    formInput: true,
    icon: {
      base: 'https://assets.happitu.com/public/workflow-elements/telephone-field.png',
      retina:
        'https://assets.happitu.com/public/workflow-elements/telephone-field@2x.png',
    },
    id: 'element12',
    inspectorFields: [
      'name',
      'defaultValue',
      'label',
      'placeholder',
      'required',
      'autoPopulate',
    ],
    name: 'Social Security Field',
    validators: {
      email: validators.emailMultiValidator(),
      required: validators.requiredOptionValidator('This field is required'),
    },
  },

  HelpTopic: {
    attributes: {
      helpTopicImpressionId: '',
    },
    component: 'HelpTopic',
    description: 'Link to a Help Topic in your workflow.',
    formInput: false,
    icon: {
      base: 'https://assets.happitu.com/public/workflow-elements/help-topic.png',
      retina: 'https://assets.happitu.com/public/workflow-elements/help-topic@2x.png',
    },
    id: '',
    inspectorFields: [],
    name: 'Help Topic',
  },

  Image: {
    attributes: {
      url: '',
    },
    component: 'Image',
    description: 'Display images in your workflow.',
    formInput: false,
    icon: {
      base: 'https://assets.happitu.com/public/workflow-elements/image.png',
      retina: 'https://assets.happitu.com/public/workflow-elements/image@2x.png',
    },
    id: '',
    inspectorFields: [],
    name: 'Image',
  },

  LocationSelect: {
    attributes: {
      ...BASE_ATTRIBUTES,
      label: 'Location Picker',
      defaultValue: '',
      document: false,
    },
    component: 'LocationSelect',
    description: 'Associate a ticket with a location.',
    formInput: true,
    icon: {
      base: 'https://assets.happitu.com/public/workflow-elements/location-picker.png',
      retina:
        'https://assets.happitu.com/public/workflow-elements/location-picker@2x.png',
    },
    id: '',
    inspectorFields: ['name', 'label', 'defaultValue', 'required'],
    name: 'Location Picker',
    validators: {
      required: validators.requiredValidator(),
    },
  },

  MultiLineTextField: {
    attributes: {
      ...BASE_ATTRIBUTES,
      autoPopulate: true,
      defaultValue: '',
      placeholder: '',
    },
    component: 'MultiLineTextField',
    description: 'Display editable text.',
    formInput: true,
    icon: {
      base:
        'https://assets.happitu.com/public/workflow-elements/multi-line-text-field.png',
      retina:
        'https://assets.happitu.com/public/workflow-elements/multi-line-text-field@2x.png',
    },
    id: 'element8',
    inspectorFields: [
      'name',
      'defaultValue',
      'label',
      'placeholder',
      'required',
      'autoPopulate',
    ],
    name: 'Multi-Line Text Field',
    validators: {
      required: validators.requiredValidator(),
    },
  },

  ScriptBlurb: {
    attributes: {
      placeholder: 'This is what an agent will say...',
      value: '',
    },
    component: 'ScriptBlurb',
    description: 'Displays what an agent should say.',
    formInput: false,
    icon: {
      base: 'https://assets.happitu.com/public/workflow-elements/script-blurb.png',
      retina: 'https://assets.happitu.com/public/workflow-elements/script-blurb@2x.png',
    },
    id: 'element2',
    inspectorFields: ['name', 'text'],
    name: 'Script Blurb',
    validators: {},
  },

  RadioButtonArray: {
    attributes: {
      ...BASE_ATTRIBUTES,
      defaultValue: [],
      options: [{ id: uuid(), label: 'New radio 1' }],
    },
    component: 'RadioButtonArray',
    description: 'Select a single choice from a group.',
    formInput: true,
    icon: {
      base: 'https://assets.happitu.com/public/workflow-elements/radio-button.png',
      retina: 'https://assets.happitu.com/public/workflow-elements/radio-button@2x.png',
    },
    id: 'element4',

    inspectorFields: ['name', 'label', 'defaultValue', 'options', 'required'],
    name: 'Single-Select',
    validators: {
      required: validators.requiredOptionValidator(),
    },
  },

  TextField: {
    attributes: {
      ...BASE_ATTRIBUTES,
      autoPopulate: true,
      defaultValue: '',
      placeholder: '',
      allowMultiValue: false,
    },
    component: 'TextField',
    description: 'Display editable text.',
    formInput: true,
    icon: {
      base: 'https://assets.happitu.com/public/workflow-elements/text-field.png',
      retina: 'https://assets.happitu.com/public/workflow-elements/text-field@2x.png',
    },
    id: 'element8',
    inspectorFields: [
      'name',
      'defaultValue',
      'label',
      'placeholder',
      'required',
      'autoPopulate',
      'allowMultiValue',
    ],
    name: 'Text Field',
    validators: {
      required: validators.requiredOptionValidator('This field is required'),
    },
  },

  NumberField: {
    attributes: {
      ...BASE_ATTRIBUTES,
      autoPopulate: true,
      defaultValue: '',
      placeholder: '',
      label: 'Number Field',
    },
    component: 'NumberField',
    description: 'Captures number values.',
    formInput: true,
    icon: {
      base: 'https://assets.happitu.com/public/workflow-elements/number-field.png',
      retina: 'https://assets.happitu.com/public/workflow-elements/number-field@2x.png',
    },
    id: 'element8',
    inspectorFields: [
      'name',
      'defaultValue',
      'label',
      'placeholder',
      'required',
      'autoPopulate',
    ],
    name: 'Number Field',
    validators: {
      required: validators.requiredValidator(),
    },
  },

  TelephoneField: {
    attributes: {
      ...BASE_ATTRIBUTES,
      autoPopulate: true,
      country: 'US',
      defaultValue: '',
      label: 'Telephone Number',
      placeholder: 'xxx-xxx-xxxx',
    },
    component: 'TelephoneField',
    description: 'Capture and validate telephone numbers.',
    formInput: true,
    icon: {
      base: 'https://assets.happitu.com/public/workflow-elements/telephone-field.png',
      retina:
        'https://assets.happitu.com/public/workflow-elements/telephone-field@2x.png',
    },
    id: 'element11',
    inspectorFields: [
      'name',
      'label',
      'defaultValue',
      'value',
      'required',
      'autoPopulate',
    ],
    name: 'Telephone Field',
    validators: {
      required: validators.requiredValidator(),
      telephone: validators.telephoneValidator(),
    },
  },

  KustomerSetStatus: {
    attributes: {
      ...BASE_ATTRIBUTES,
    },
    component: 'KustomerSetStatus',
    description: 'Set the status of the current conversation',
    formInput: true,
    icon: {
      base: 'https://assets.happitu.com/public/workflow-elements/telephone-field.png',
      retina:
        'https://assets.happitu.com/public/workflow-elements/telephone-field@2x.png',
    },
    id: 'element11',
    inspectorFields: [],
    name: 'Kustomer: Set Conversation Status',
    validators: {},
  },

  KustomerSetAttribute: {
    attributes: {
      ...BASE_ATTRIBUTES,
      attribute: '',
      value: '',
    },
    component: 'KustomerSetAttribute',
    description: 'Set an attribute on the current customer.',
    formInput: true,
    icon: {
      base: 'https://assets.happitu.com/public/workflow-elements/telephone-field.png',
      retina:
        'https://assets.happitu.com/public/workflow-elements/telephone-field@2x.png',
    },
    id: 'element11',
    inspectorFields: [],
    name: 'Kustomer: Set Customer Attribute',
    validators: {},
  },

  KustomerApplyTags: {
    attributes: {
      ...BASE_ATTRIBUTES,
      attribute: '',
      value: '',
    },
    component: 'KustomerApplyTags',
    description: 'Updates current conversation with tags gathered in workflow.',
    formInput: true,
    icon: {
      base: 'https://assets.happitu.com/public/workflow-elements/telephone-field.png',
      retina:
        'https://assets.happitu.com/public/workflow-elements/telephone-field@2x.png',
    },
    id: 'element11',
    inspectorFields: [],
    name: 'Kustomer: Apply Tags to Conversation',
    validators: {},
  },

  Webview: {
    attributes: {
      value: '',
    },
    component: 'Webview',
    description: 'Load a third party app in a workflow.',
    formInput: false,
    icon: {
      base: 'https://assets.happitu.com/public/workflow-elements/webview.png',
      retina: 'https://assets.happitu.com/public/workflow-elements/webview@2x.png',
    },
    id: 'element12',
    inspectorFields: [],
    name: 'Webview',
    validators: {},
  },
}

export function workflowElementPresets(state = elementPresets) {
  return state
}

const initialState = new Store()

export default createReducer(initialState, {
  [types.RELAY_DISPATCH]: (state, { payload }) => state.setMany(payload.workflowElements),
})
