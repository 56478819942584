import React, { FocusEvent, ReactNode, forwardRef, MutableRefObject } from 'react'
import styled, { css } from 'styled-components'
import { ifNotProp, ifProp } from 'styled-tools'

import { InputCSS, InsetLabelContent } from '../FieldUtils'

import getStyle from 'happitu/src/getStyle'
import DateSelectStyles from 'happitu/src/themes/dateSelect.styles'
import { icomoon } from 'happitu/src/themes/style-utils'
import { font } from 'theme'

interface Props {
  children: ReactNode
  className?: string
  hasValue: boolean
  isPlaceholder?: boolean
  label?: string
  required?: boolean
  onClose: () => void
  onOpen: () => void
}

const DateSelectWrapper = forwardRef(
  (props: Props, ref: MutableRefObject<HTMLLabelElement>) => {
    const handleBlur = (e: FocusEvent<HTMLLabelElement>) => {
      if (!e.relatedTarget || !e.currentTarget.contains(e.relatedTarget as Node)) {
        props.onClose()
      }
    }

    const handleFocus = () => {
      props.onOpen()
    }

    return (
      <label
        className={props.className}
        role="date-selector"
        ref={ref}
        onBlur={handleBlur}
        onFocus={handleFocus}
        tabIndex={0}
      >
        <input type="text" />
        <DateSelectStyles />
        {props.label && (
          <InsetLabelContent required={props.required}>{props.label}</InsetLabelContent>
        )}
        <DatePickerValue>{props.children}</DatePickerValue>
      </label>
    )
  },
)

const DatePickerValue = styled.div`
  align-items: center;
  display: flex;
`

const isFocused = css`
  border-color: transparent;
  box-shadow: 0px 0px 0px 2px ${getStyle('border-active-color')};
  outline: none;
`

export default styled(DateSelectWrapper)`
  ${InputCSS}
  font-size: 1em;
  min-height: 52px;
  min-width: 200px;
  outline: none;
  position: relative;
  transition: padding-right 150ms;
  display: flex;

  input {
    flex-shrink: 1;
  }

  > input[type='text'] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  &:before {
    ${icomoon};
    color: ${getStyle('button-primary-default-background')};
    content: '\\e957';
    font-size: 1rem;
    left: 0.5em;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &:focus {
    ${isFocused}
  }

  &:focus-within {
    ${isFocused}
  }

  ${ifNotProp(
    'hasValue',
    css`
      &:not(:focus-within) {
        ${InsetLabelContent} {
          transform: translateY(0);
        }
      }
    `,
  )}

  ${ifNotProp(
    'label',
    css`
      color: ${getStyle('text-default-color')};
      font-weight: ${font('weight', 'medium')};
      padding: 0.5em;
      min-height: auto;
      min-width: auto;

      ${DatePickerValue} {
        margin-left: 2em;
      }
    `,
    css`
      padding-right: 0.5em;

      ${DatePickerValue} {
        margin-left: 2.25em;
        margin-top: 2px;
      }

      ${InsetLabelContent} {
        margin-left: 1.5em;
      }
    `,
  )}

  ${ifProp(
    'isPlaceholder',
    css`
      color: ${getStyle('input-default-placeholderColor')};
    `,
  )}
`
