import * as React from 'react'
import { FieldInputProps } from 'react-final-form'

import {
  Handle,
  Inner,
  Input,
  Label,
  On,
  Switch,
  Wrapper,
  SwitchLoader,
} from './Toggle.styles'

export interface ToggleProps {
  className?: string
  checked?: boolean
  label?: string
  labelPos?: 'left' | 'right'
  loading?: boolean
  name?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  input?: FieldInputProps<boolean, HTMLInputElement>
  style?: React.CSSProperties
  title?: string
}

function Toggle({
  className,
  label,
  labelPos,
  checked,
  loading = false,
  input,
  onChange,
  title,
  style,
  ...props
}: ToggleProps) {
  const displayChecked = input?.checked ?? checked
  return (
    <Wrapper className={className} style={style} title={title}>
      {label && labelPos === 'left' && <Label>{label}</Label>}
      <Input
        checked={displayChecked}
        tabIndex={-1}
        name={props.name}
        onChange={input?.onChange ?? onChange}
        onBlur={input?.onBlur}
        type="checkbox"
      />
      <Switch isLoading={loading}>
        <Inner>
          <On />
        </Inner>
        <Handle />
        {loading && <SwitchLoader checked={displayChecked} />}
      </Switch>
      {label && labelPos !== 'left' && <Label>{label}</Label>}
    </Wrapper>
  )
}

function isEqual(prevProps: ToggleProps, nextProps: ToggleProps) {
  return (
    prevProps.checked === nextProps.checked &&
    prevProps.loading === nextProps.loading &&
    prevProps.input?.checked === nextProps.input?.checked
  )
}

export default React.memo(Toggle, isEqual)
