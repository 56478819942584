import { css } from 'styled-components'
import { ifProp, prop } from 'styled-tools'

export interface FlexProps {
  grow?: number | string
  shrink?: number | string
}

export const flexMixin = css`
  ${ifProp(
    'grow',
    css`
      flex-grow: ${prop('grow')};
    `,
  )}

  ${ifProp(
    'shrink',
    css`
      flex-shrink: ${prop('shrink')};
    `,
  )}
`
