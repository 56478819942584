import styled from 'styled-components'

import { FlexCol } from 'happitu/src/components/_DEPRECATED/Flex'
import { TICKETING_PADDING } from 'happitu/src/constants/ticketConstants'
import getStyle from 'happitu/src/getStyle'

export const Wrapper = styled(FlexCol)`
  background: ${getStyle('app-background')};
  flex-grow: 1;
  margin: auto;
  max-width: 600px;
  width: 100%;
  padding: 0 2em;

  @media screen and (max-width: 500px) {
    padding: 0;
  }
`

export const Footer = styled.div`
  margin-top: 1em;
  display: flex;
  flex-direction: column;
`
