import { rgba } from 'polished'

import * as colors from './colors'

export const warning = {
  background: rgba(colors.orange.base, .3),
  color: colors.orange.base,
}

export const success = {
  background: colors.green.light,
  color: 'white'
}

export const normal = {
  background: colors.grey.offWhite,
  color: colors.grey.dark
}
