import { startCase } from 'lodash'
import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { Editor, Range } from 'slate'
import { ReactEditor } from 'slate-react'
import styled from 'styled-components'

import { menuStyle } from 'happitu/src/components/menu'
import Portal from 'happitu/src/components/portal'
import { VariableContext } from 'happitu/src/modules/workflow-variables'
import SelectableList from 'happitu/src/pages/self-service/components/selectable-list'

interface Props {
  search: string
  target?: Range | null
  activeIndex: number
  editor: Editor
  onSelect: (value: string) => void
}

const Menu = styled.div`
  ${menuStyle}
  max-height: 230px;
  min-width: 300px;
  padding: 0.5em;
  overflow-y: auto;
`

const VariableSuggester = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const variableMap = useContext(VariableContext)
  const variables = useMemo(() => {
    return Object.keys(variableMap)
      .filter((key) => new RegExp(props.search, 'i').test(key))
      .map((option) => ({
        label: startCase(option),
        value: option,
        description: `:${option}:`,
      }))
      .sort((a, b) => {
        if (a.label < b.label) return -1
        if (a.label > b.label) return 1
        return 0
      })
  }, [props.search])

  useEffect(() => {
    if (props.target && variables.length > 0) {
      const el = ref.current
      if (!el) return
      const domRange = ReactEditor.toDOMRange(props.editor, props.target)
      const rect = domRange.getBoundingClientRect()
      el.style.top = `${rect.top + window.pageYOffset + 24}px`
      el.style.left = `${rect.left + window.pageXOffset}px`
    }
  }, [variables.length, props.editor, props.search, props.target])

  return props.target && variables.length > 0 ? (
    <Portal>
      <Menu ref={ref}>
        <SelectableList options={variables} onSelect={props.onSelect} />
      </Menu>
    </Portal>
  ) : null
}

export default VariableSuggester
