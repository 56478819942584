import { ReactNode, useCallback, useEffect } from 'react'

import { TicketComponentProps, connectTicketContext } from './ticketing'

import { error } from 'happitu/src/helpers/loggerHelper'
import {
  getParams,
  navigateTo,
  WorkTicketRouteParams,
} from 'happitu/src/helpers/routeHelpers'
import useWebSocket from 'happitu/src/hooks/use-websocket'

interface Props {
  // onSuccess: (params: WorkTicketRouteParams) => void
  onError: (e: any) => void
  children: ReactNode
}

const InitializeTicket = (
  props: Props &
    TicketComponentProps<
      | 'getContactDetailTypes'
      | 'getTicket'
      | 'initializeInteraction'
      | 'getWorkflowHelpTopics'
    >,
) => {
  const [_, setWebsocketRoom] = useWebSocket()
  const {
    ticketId,
    ticketInteractionId,
    stepIndex,
    ...otherParams
  } = getParams<WorkTicketRouteParams>()

  const fetchData = useCallback(async () => {
    try {
      await props.getContactDetailTypes()

      const response = await props.getTicket(ticketId)
      if (response.websocketToken && response.roomId) {
        setWebsocketRoom({
          websocketToken: response.websocketToken,
          roomId: response.roomId,
        })
      }

      const currentInteraction = response.ticketInteractions.find(
        (ti) => ti.id === ticketInteractionId,
      )
      if (!currentInteraction) {
        throw new Error(`Unable to load ticket!
          Trying to load interaction (${ticketInteractionId}) in ticket (${ticketId}) `)
      }
      const ticket = response.tickets.find((t) => t.id === ticketId)
      const currentContact = response.contacts
        ? response.contacts.find((c) => ticket && ticket.contactId == c.id)
        : null

      // Check for invalid stepIndex
      if (parseInt(stepIndex) > currentInteraction.workflowSteps.length - 1) {
        navigateTo('app.tickets.work', {
          ...getParams(),
          stepIndex: `${currentInteraction.workflowSteps.length - 1}`,
        })
      }

      props.initializeInteraction(currentInteraction, currentContact, otherParams)
      props.getWorkflowHelpTopics({
        workflowId: currentInteraction.workflowId,
        workflowVersion: currentInteraction.workflowVersion,
        excludeExpired: true,
      })
    } catch (e) {
      error(e)
      props.onError(e)
    }
  }, [ticketId, ticketInteractionId])

  useEffect(() => {
    fetchData().then()
  }, [ticketId, ticketInteractionId])

  return <>{props.children}</>
}

export default connectTicketContext<Props>(null, [
  'getContactDetailTypes',
  'getTicket',
  'initializeInteraction',
  'getWorkflowHelpTopics',
])(InitializeTicket)
