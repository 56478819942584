import React from 'react'

import { StyledInput, StyledInputWrapper, DeleteButton } from './EmailField.styles'

import {
  FieldFigure,
  InsetLabelContent,
  NextFieldWrapper,
} from 'happitu/src/components/Fields/FieldUtils'
import { FlexRow } from 'happitu/src/components/_DEPRECATED/Flex'

interface Props {
  allowMultiEmail?: boolean
  disabled?: boolean
  emailValue: string
  fieldError?: string
  forceErrors: boolean
  index: number
  label?: string
  onBlur: (val: string, index: number, paste?: boolean) => void
  onChange: (val: string, index: number) => void
  onDelete: (index: number) => void
  placeholder?: string
  required?: boolean
  totalIndex: number
  style?: React.CSSProperties
}

function EmailFieldItem(props: Props, ref: React.Ref<HTMLLabelElement>) {
  const {
    allowMultiEmail,
    disabled,
    emailValue,
    fieldError,
    forceErrors,
    index,
    label,
    onBlur,
    onChange,
    onDelete,
    placeholder,
    required,
    totalIndex,
    style,
  } = props
  const showError = forceErrors && !!fieldError
  const isDeleting = React.useRef<boolean>(false)
  const isHovered = React.useRef<boolean>(false)

  React.useEffect(() => {
    isDeleting.current = false
  }, [emailValue])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value, index)
  }

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) =>
    onBlur(e.target.value, index)

  const handleMouseDown = () => (isDeleting.current = true)
  const handleMouseUp = () => {
    if (isDeleting.current && isHovered.current) {
      onDelete(index)
    }
    isDeleting.current = false
  }
  const handleMouseEnter = () => (isHovered.current = true)
  const handleMouseLeave = () => (isHovered.current = false)

  const showDeleteButton =
    allowMultiEmail && index !== totalIndex - 1 && !(index === 0 && !emailValue)

  return (
    <NextFieldWrapper invalid={showError} style={{ marginBottom: 4 }}>
      <FlexRow>
        <StyledInputWrapper
          ref={ref}
          style={{ maxWidth: 400, width: '100%', ...style }}
          lastItem={index > 0 && index === totalIndex - 1}
          disabled={disabled}
        >
          <StyledInput
            disabled={disabled}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={placeholder}
            // We're using type of 'text' instead of 'email' because of the side effects introduced by this spec:
            // https://html.spec.whatwg.org/multipage/input.html#e-mail-state-(type%3Demail)
            type="text"
            value={emailValue}
          />
          <InsetLabelContent required={required && index === 0}>
            {label}
            {totalIndex > 1 && ` ${index + 1}`}
          </InsetLabelContent>
          {showDeleteButton && (
            <DeleteButton
              ghost
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              rounded
              tabIndex={-1}
            >
              &times;
            </DeleteButton>
          )}
        </StyledInputWrapper>
      </FlexRow>
      {showError && <FieldFigure value={fieldError} />}
    </NextFieldWrapper>
  )
}

export default React.forwardRef(EmailFieldItem)
