import relay, { RelayDispatch } from './relay'

import { LocationRecord } from 'happitu/src/models/location'
import * as request from 'happitu/src/services/happitu-api/locationService'

export const getLocation = (locationId: ID) => (
  dispatch: RelayDispatch<typeof request.createLocationRequest>,
) => {
  return request.getLocationRequest(locationId).then(relay(dispatch))
}

export const createLocation = (location: Partial<LocationRecord>) => (
  dispatch: RelayDispatch<typeof request.createLocationRequest>,
) => {
  return request.createLocationRequest(location).then(relay(dispatch))
}

export const updateLocation = (
  locationId: ID,
  partialLocation: Partial<LocationRecord>,
) => (dispatch: RelayDispatch<typeof request.updateLocationRequest>) => {
  relay(dispatch)({ locations: [{ id: locationId, ...partialLocation }] })
  return request.updateLocationRequest(locationId, partialLocation).then(relay(dispatch))
}

export const deleteLocation = (locationId: ID) => (
  dispatch: RelayDispatch<typeof request.deleteLocationRequest>,
) => {
  relay(dispatch)({ locations: [{ id: locationId, active: false }] })
  return request
    .deleteLocationRequest(locationId)
    .then(relay(dispatch))
    .catch(() => relay(dispatch)({ locations: [{ id: locationId, active: true }] }))
}

// prettier-ignore
export const updateLocationHelpTopics = (
  locationId: ID,
  changes: { add: ID[], remove: ID[] }, // helpTopicImpressionId[]
) => (dispatch: RelayDispatch<typeof request.updateLocationHelpTopicsRequest>) => {
  return request
    .updateLocationHelpTopicsRequest(locationId, changes)
    .then(relay(dispatch))
}

// prettier-ignore
export const updateLocationWorkflows = (
  locationId: ID,
  changes: { add: ID[], remove: ID[] }, // workflowId[]
) => (dispatch: RelayDispatch<typeof request.updateLocationWorkflowsRequest>) => {
  return request
    .updateLocationWorkflowsRequest(locationId, changes)
    .then(relay(dispatch))
}
