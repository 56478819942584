import { BaseEditor } from 'slate'
import { HistoryEditor } from 'slate-history'
import { ReactEditor } from 'slate-react'

type ExtendedProperties = {
  allowGuidedHelpTopics?: boolean
  allowMedia?: boolean
  fileIds?: ID[]
}

export type CustomEditor = BaseEditor & ReactEditor & HistoryEditor & ExtendedProperties

export enum ElementType {
  Attachment = 'attachment',
  BlockQuote = 'block-quote',
  HeadingOne = 'heading-one',
  HeadingTwo = 'heading-two',
  HelpTopicAction = 'help-topic-action',
  Image = 'image',
  Link = 'link',
  OrderedList = 'ordered-list',
  Paragraph = 'paragraph',
  SectionBreak = 'section-break',
  UnorderedList = 'unordered-list',
  WorkflowVariable = 'workflow-variable',
}

// Block (top-level) types /////////////////////

export type ParagraphElement = {
  type: ElementType.Paragraph
  children: BlockChild[]
  indentLevel: number
  nodeId: ID
}

// export type HeadingElement = {
//   type: 'heading'
//   level: number
//   children: BlockChild[]
// }

export type HeadingOneElement = {
  type: ElementType.HeadingOne
  children: BlockChild[]
  nodeId: ID
}

export type HeadingTwoElement = {
  type: ElementType.HeadingTwo
  children: BlockChild[]
  nodeId: ID
}

export type BlockQuoteElement = {
  type: ElementType.BlockQuote
  children: BlockChild[]
  nodeId: ID
}

export type SectionBreakElement = {
  type: ElementType.SectionBreak
  children: BlockChild[]
  nodeId: ID
}

export type OrderedListElement = {
  type: ElementType.OrderedList
  children: BlockChild[]
  indentLevel: number
  startsAt: number
  nodeId: ID
}

export type UnorderedListElement = {
  type: ElementType.UnorderedList
  children: BlockChild[]
  indentLevel: number
  nodeId: ID
}

// Void block types /////////////////////
// Voids should only have empty text nodes as their children (as required by Slate).

export type ImageElement = {
  type: ElementType.Image
  children: CustomText[]
  fileId: ID | null
  nodeId: ID
  previewUrl?: string
  uploadProgress?: number
}

export type HelpTopicActionElement = {
  type: ElementType.HelpTopicAction
  children: CustomText[]
  label?: string
  nextPageId: ID | null
  nodeId: ID
}

export type AttachmentElement = {
  type: ElementType.Attachment
  children: CustomText[]
  fileId?: string
  fileType: string
  name: string
  nodeId: ID
}

// Inline types /////////////////////

export type LinkElement = {
  type: ElementType.Link
  children: CustomText[]
  nodeId: ID
  url?: string // Should probably be changed to required and nullable.
}

export type WorkflowVariableElement = {
  type: ElementType.WorkflowVariable
  children: CustomText[]
  nodeId: ID
  variable: string
  placeholder: string
}

// Text (leaf) node type /////////////////////

// prettier-ignore
export type CustomText = {
  bold?: boolean
  code?: boolean
  italic?: boolean
  strikethrough?: boolean
  text: string
  underline?: boolean
  highlight?: string
}

/////////////////////////////////////////

export type CustomElement =
  | AttachmentElement
  | BlockQuoteElement
  | HeadingOneElement
  | HeadingTwoElement
  | HelpTopicActionElement
  | ImageElement
  | LinkElement
  | OrderedListElement
  | ParagraphElement
  | SectionBreakElement
  | UnorderedListElement
  | WorkflowVariableElement

// A (non-void) block element (i.e. Paragraph) can have a text node (leaf node) or an inline block (i.e. Link) as the child. Inline blocks should only have text nodes as children.
export type BlockChild = CustomText | WorkflowVariableElement | LinkElement
