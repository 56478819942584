import * as React from 'react'
import styled from 'styled-components'

import getStyle from 'happitu/src/getStyle'

const Wrapper = styled.li`
  position: relative;
`

const List = styled.ul`
  border-top: 1px solid ${getStyle('border-color')};
  padding: 0.5em 0;
`

const Title = styled.h4`
  position: absolute;
  background: ${getStyle('menu-background')};
  top: 0;
  left: 1em;
  transform: translateY(-50%);
  line-height: 1;
  color: ${getStyle('text-light-color')};
  padding: 0 0.5em;
  margin: 0;
  font-size: 12px;
  font-weight: 600;
`

interface Props {
  children: React.ReactNode
  className?: string
  title?: string
}

export default function MenuListSection({ children, className, title }: Props) {
  return (
    <Wrapper className={className}>
      {!!title && <Title>{title}</Title>}
      <List>{children}</List>
    </Wrapper>
  )
}
