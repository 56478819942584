import { last } from 'lodash'
import * as React from 'react'
import styled from 'styled-components'

import Icon from 'happitu/src/components/Icon'
import {
  SectionCellList,
  SectionCellListItem,
  SectionCellListLabel,
  SectionCellListValue,
} from 'happitu/src/components/Section'
import Spacer from 'happitu/src/components/spacer'
import getStyle from 'happitu/src/getStyle'
import { setPageTitle } from 'happitu/src/helpers/domHelper'
import useDataFetch from 'happitu/src/hooks/useDataFetcher'
import { connectTicketContext, TicketComponentProps } from 'happitu/src/modules/ticketing'

const Wrapper = styled.h3`
  color: ${getStyle('text-success-color')};
  font-weight: 500;

  ${Icon} {
    font-size: 2em;
    margin-bottom: 2rem;
  }
`

const searchParams = new URLSearchParams(window.location.search)
const ticketId = searchParams.get('ticketId')

function TicketSubmitted(
  props: TicketComponentProps<'tickets' | 'ticketInteractions' | 'getTicket'>,
) {
  React.useEffect(() => {
    setPageTitle('Ticket Successfully Submitted')
  })

  useDataFetch(async () => {
    if (ticketId) {
      props.getTicket(ticketId)
    }
  })

  const ticket = props.tickets?.findById<TicketRecord>(ticketId)
  const interaction = props.ticketInteractions?.findById<TicketInteractionRecord>(
    last(ticket?.interactionIds || []),
  )

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{ minHeight: 400 }}>
        <Wrapper
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div>
            <Icon type="success" />
          </div>
          <br />
          <div>Ticket Successfully Submitted</div>
        </Wrapper>
        {interaction && (
          <>
            <Spacer y="2" />
            <SectionCellList>
              <SectionCellListItem>
                <SectionCellListLabel>Ticket Number</SectionCellListLabel>
                <SectionCellListValue>#{ticket?.ticketNumber}</SectionCellListValue>
              </SectionCellListItem>

              {interaction?.variableOverwrites.contact_first_name && (
                <SectionCellListItem>
                  <SectionCellListLabel>First Name</SectionCellListLabel>
                  <SectionCellListValue>
                    {interaction?.variableOverwrites.contact_first_name}
                  </SectionCellListValue>
                </SectionCellListItem>
              )}

              {interaction?.variableOverwrites.contact_last_name && (
                <SectionCellListItem>
                  <SectionCellListLabel>Last Name</SectionCellListLabel>
                  <SectionCellListValue>
                    {interaction?.variableOverwrites.contact_last_name}
                  </SectionCellListValue>
                </SectionCellListItem>
              )}
            </SectionCellList>
          </>
        )}
      </div>
    </div>
  )
}

export default connectTicketContext(
  ['tickets?', 'ticketInteractions?'],
  ['getTicket'],
)(TicketSubmitted)
