import { checkIsExpired } from 'happitu/src/helpers/helpTopicHelpers'
import { HelpTopicTypes } from 'happitu/src/types/helpTopicTypes'
import { HelpTopicRecord } from 'happitu/src/types/models/helpTopics'
import { TicketState } from 'happitu/src/types/ticketContext'

export interface SuggestedHelpTopic {
  current: boolean
  helpTopicId: ID
  helpTopicImpressionId: ID
}

export interface SuggestedHelpTopics {
  alerts: SuggestedHelpTopic[]
  helpTopics: SuggestedHelpTopic[]
}

export function buildSuggestedHelpTopics(
  interactionSteps: InteractionStep[],
  currentStepId?: ID,
) {
  function createResource(
    stepId: ID,
    helpTopicId: ID,
    helpTopicImpressionId: ID,
  ): SuggestedHelpTopic {
    return {
      current: stepId === currentStepId,
      helpTopicId,
      helpTopicImpressionId,
    }
  }

  function isNotPresent(collection: SuggestedHelpTopic[], impressionId: ID) {
    return (
      collection.findIndex(
        ({ helpTopicImpressionId }) => helpTopicImpressionId === impressionId,
      ) === -1
    )
  }

  return (state: TicketState) => {
    const alerts: SuggestedHelpTopic[] = []
    const helpTopics: SuggestedHelpTopic[] = []

    interactionSteps.forEach(({ stepId }) => {
      const step = state.workflowStepGroupSteps.findById<WorkflowStepRecord>(stepId)
      if (step && state.helpTopics && step.helpTopicImpressionIds) {
        step.helpTopicImpressionIds.forEach((impressionId: ID) => {
          const helpTopic = state.helpTopics.findByImpression<HelpTopicRecord>(
            impressionId,
          )

          if (helpTopic) {
            if (
              helpTopic.type === HelpTopicTypes.Alert &&
              isNotPresent(alerts, impressionId)
            ) {
              alerts.push(createResource(stepId, helpTopic.id, helpTopic.impressionId))
            } else if (
              !checkIsExpired(helpTopic.expiresAt) &&
              isNotPresent(helpTopics, impressionId)
            ) {
              helpTopics.push(
                createResource(stepId, helpTopic.id, helpTopic.impressionId),
              )
            }
          }
        })
      }
    })

    return {
      alerts: alerts.reverse(),
      helpTopics: helpTopics.reverse(),
    }
  }
}
