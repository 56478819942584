import React from 'react'

import GenericErrorMessage from 'happitu/src/error-boundaries/GenericErrorMessage'
import { error } from 'happitu/src/helpers/loggerHelper'
import { getRouteName } from 'happitu/src/helpers/routeHelpers'

interface Props {
  children: React.ReactNode
  errorComponent?: React.ComponentType
}

interface State {
  hasError?: boolean
  route?: string
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (state.route !== getRouteName()) {
      return { route: null, hasError: false }
    } else {
      return {}
    }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, route: getRouteName() }
  }

  componentDidCatch(err: Record<string, any>, errorInfo: Record<string, any>) {
    // You can also log the error to an error reporting service
    error(err, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return React.createElement(this.props.errorComponent || GenericErrorMessage)
    }

    return this.props.children
  }
}
