import styled from 'styled-components'

import getStyle from 'happitu/src/getStyle'

export const Wrapper = styled.div`
  align-items: center;
  background: ${getStyle('app-background')};
  border-bottom: 1px dotted ${getStyle('border-color')};
  border-top: 1px dotted ${getStyle('border-color')};
  display: flex;
  margin-bottom: 20px;
  padding: 10px 0;
  padding: 10px;
  z-index: 2;
`
