import { useEffect, useRef, MouseEvent } from 'react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { Element, Transforms, Editor, NodeEntry, Node } from 'slate'
import { useSlate, useSelected, ReactEditor, RenderElementProps } from 'slate-react'
import { v4 as uuid } from 'uuid'

import ElementDragHandle from '../../Element/element-drag-handle'

import {
  PageSelect,
  ActionButton,
  Container,
  Wrapper,
  LabelWrapper,
  InsertActionButton,
  InsertAboveContainer,
  InsertBelowContainer,
  ControlsRow,
} from './EditableHelpTopicAction.styles'
import LabelTextArea from './LabelTextArea'

import { Option, OptionProps } from 'happitu/src/components/Lists'
import {
  getNextBlockNode,
  getPreviousBlockNode,
  selectNextBlockNode,
} from 'happitu/src/components/RichTextEditor/editorHelpers'
import { error } from 'happitu/src/helpers/loggerHelper'
import { navigateTo, getParams, HelpTopicParams } from 'happitu/src/helpers/routeHelpers'
import {
  useHelpTopicsContext,
  ComponentProps,
} from 'happitu/src/modules/HelpTopics.context'
import { HelpTopicPageRecord, HelpTopicRecord } from 'happitu/src/types/models/helpTopics'
import { ElementType, HelpTopicActionElement } from 'happitu/src/types/slate-types'

interface Props
  extends RenderElementProps,
    ComponentProps<'helpTopicPages' | 'helpTopics' | 'newHelpTopicPage'> {
  dragHandleProps?: DraggableProvidedDragHandleProps
  element: HelpTopicActionElement
  isDragging?: boolean
}

const EditableHelpTopicAction = ({
  attributes,
  children,
  element,
  helpTopicPages,
  helpTopics,
  newHelpTopicPage,
  isDragging = false,
  ...props
}: Props) => {
  const { label, nextPageId, nodeId } = element
  if (!nodeId) {
    error('Help Topic Action is missing nodeId')
  }
  const editor = useSlate()
  const selected = useSelected()
  const {
    helpTopicPageImpressionId,
    helpTopicImpressionId,
  } = getParams<HelpTopicParams>()
  const pageSelectRef = useRef<HTMLDivElement>(null)
  const insertActionTitle = 'Insert a new action'

  const helpTopic = helpTopics.findByImpression<HelpTopicRecord>(helpTopicImpressionId)
  if (!helpTopic) {
    error(`Missing help topic ${helpTopicImpressionId}`)
    return null
  }

  const helpTopicPage = helpTopicPages.findByImpression<HelpTopicPageRecord>(
    helpTopicPageImpressionId,
  )

  const linkedPage = helpTopicPages.findById<HelpTopicPageRecord>(nextPageId)

  if (!helpTopicPage) {
    error(`Missing help topic page ${helpTopicPageImpressionId}`)
    return null
  }

  const currentHelpTopicPages = helpTopic.pageIds.map((id) =>
    helpTopicPages.findById<HelpTopicPageRecord>(id),
  ) as HelpTopicPageRecord[]

  // prettier-ignore
  const options: OptionProps[] = currentHelpTopicPages.map((page: HelpTopicPageRecord) => {
    return {
      label: page.name,
      isDisabled: page.id === helpTopicPage.id,
      value: page.id,
    }
  })

  // The current help topic action.
  const [node] = Editor.nodes(editor, {
    at: [],
    match: (n) =>
      Element.isElement(n) &&
      n.type === ElementType.HelpTopicAction &&
      n.nodeId === nodeId,
  })
  // The next top-level node in the editor.
  const nextEntry = getNextBlockNode(editor, node[1])
  // The previous top-level node in the editor.
  const prevEntry = getPreviousBlockNode(editor, node[1])

  const isFirst = !isPrevEntryAnAction(prevEntry)
  const isLast = !isNextEntryAnAction(nextEntry)

  const handleSelect = (pageId: ID) => {
    // Update the page that the help topic action links to.
    const newPageId = pageId === nextPageId ? null : pageId
    Transforms.setNodes(
      editor,
      {
        nextPageId: newPageId,
      },
      {
        at: [],
        match: (n) =>
          Element.isElement(n) &&
          n.type === ElementType.HelpTopicAction &&
          n.nodeId === nodeId,
      },
    )
  }

  const handleActionDelete = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    // Remove the help topic action.
    if (node) {
      Transforms.removeNodes(editor, { at: node[1] })
    } else {
      error(`Can't locate help topic action ${nodeId} for deletion`)
    }
  }

  // This is necessary to prevent the node from being selected when we open the dropdown, so that InlineEdit isn't focused.
  const handleSelectClick = (e: MouseEvent<HTMLDivElement>) => e.stopPropagation()

  const handleCreate = async (name: string) => {
    try {
      const response = await newHelpTopicPage({
        name,
        helpTopicId: helpTopic.id,
      })
      // Select the newly created page.
      handleSelect(response.helpTopicPages[0].id)
      return
    } catch (e) {
      return error(e)
    }
  }

  const handleInsertActionAbove = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()

    const newNode: HelpTopicActionElement = {
      type: ElementType.HelpTopicAction,
      label: '',
      nextPageId: null,
      nodeId: uuid(),
      children: [{ text: '' }],
    }

    // Insert new action above the current action.
    Transforms.insertNodes(editor, [newNode], { at: node[1] })
    // Select the new action.
    Transforms.select(editor, node[1])
  }

  const handleInsertActionBelow = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()

    const newNode: HelpTopicActionElement = {
      type: ElementType.HelpTopicAction,
      label: '',
      nextPageId: null,
      nodeId: uuid(),
      children: [{ text: '' }],
    }

    if (!!nextEntry) {
      // Insert new action below the current action.
      Transforms.insertNodes(editor, [newNode], { at: nextEntry[1] })
      // Select the new action.
      Transforms.select(editor, nextEntry[1])
    }
  }

  const handlePageSelectKeydown = (e: KeyboardEvent) => {
    if (e.key === 'Tab' && isLast && !!nextEntry) {
      // Select the beginning of the next block node when tabbing out of the last action.
      e.preventDefault()
      e.stopPropagation()
      selectNextBlockNode(editor)
      ReactEditor.focus(editor)
    }
  }

  useEffect(() => {
    pageSelectRef.current?.addEventListener('keydown', handlePageSelectKeydown)
    return () =>
      pageSelectRef.current?.removeEventListener('keydown', handlePageSelectKeydown)
  }, [isLast, nextEntry])

  // Clean up links to deleted pages.
  useEffect(() => {
    if (!!nextPageId) {
      const nextHelpTopicPage = helpTopicPages.findById<HelpTopicPageRecord>(nextPageId)
      if (nextHelpTopicPage && !nextHelpTopicPage.active) {
        Transforms.setNodes<HelpTopicActionElement>(
          editor,
          { nextPageId: null },
          {
            at: [],
            match: (n) =>
              Element.isElement(n) &&
              n.type === ElementType.HelpTopicAction &&
              n.nodeId === nodeId,
          },
        )
      }
    }
  }, [nextPageId])

  return (
    <Wrapper {...attributes}>
      <Container
        contentEditable={false}
        isDragging={isDragging}
        isFirst={isFirst}
        isLast={isLast}
        isSelected={selected}
      >
        <ControlsRow>
          <ElementDragHandle dragHandleProps={props.dragHandleProps} />
          <LabelWrapper isActive={!!nextPageId}>
            {!isDragging && isFirst && (
              <InsertAboveContainer>
                <InsertActionButton
                  icon="plus"
                  onClick={handleInsertActionAbove}
                  rounded
                  size="small"
                  tabIndex={-1}
                  title={insertActionTitle}
                />
              </InsertAboveContainer>
            )}
            <LabelTextArea
              defaultValue={label}
              nodeId={nodeId}
              placeholder="Set an action label"
            />
            {!isDragging && (
              <InsertBelowContainer>
                <InsertActionButton
                  icon="plus"
                  onClick={handleInsertActionBelow}
                  rounded
                  size="small"
                  tabIndex={-1}
                  title={insertActionTitle}
                />
              </InsertBelowContainer>
            )}
          </LabelWrapper>
          <div onClick={handleSelectClick} ref={pageSelectRef}>
            <PageSelect
              createPlaceholder="Create page '%s'"
              onCreate={handleCreate}
              onSelect={handleSelect}
              placeholder="Link to a page"
              search
              searchPlaceholder="Search for a page"
              value={nextPageId}
            >
              {options.map((option: any, index: number) => (
                <Option key={index} {...option} />
              ))}
            </PageSelect>
          </div>
          <ActionButton
            icon="arrow-right"
            title={`Navigate to ${linkedPage?.name ?? 'page'}`}
            disabled={!nextPageId}
            onClick={() =>
              navigateTo('app.manage.helpTopics.edit.page', {
                helpTopicImpressionId: helpTopic.impressionId,
                helpTopicPageImpressionId: linkedPage?.impressionId,
              })
            }
          />
          <ActionButton icon="delete" onClick={handleActionDelete} tabIndex={-1} />
        </ControlsRow>
      </Container>
      {children}
    </Wrapper>
  )
}

const isPrevEntryAnAction = (prevEntry?: NodeEntry<Node> | null) => {
  if (!prevEntry) return false
  return (
    Element.isElement(prevEntry[0]) && prevEntry[0].type === ElementType.HelpTopicAction
  )
}

const isNextEntryAnAction = (nextEntry?: NodeEntry<Node> | null) => {
  if (!nextEntry) return false
  return (
    Element.isElement(nextEntry[0]) && nextEntry[0].type === ElementType.HelpTopicAction
  )
}

export default useHelpTopicsContext<Props>(
  ['helpTopicPages?', 'helpTopics'],
  ['newHelpTopicPage'],
)(EditableHelpTopicAction)
