export const addressKeyToIndex = {
  city: 3,
  country: 6,
  line1: 0,
  line2: 1,
  line3: 2,
  postalCode: 5,
  state: 4,
  type: 7,
}

export const getAddressIndexFromKey = (key: keyof typeof addressKeyToIndex) => {
  return addressKeyToIndex[key]
}

export function getInitialAddressFieldValues(
  value: string[],
  hasStreetAddress?: boolean,
) {
  return hasStreetAddress ? value : value.map((v, index) => (index < 3 ? '' : v))
}

export const isLineThreeVisible = (values: readonly string[]) => {
  return (
    !!values[getAddressIndexFromKey('line2')] || values[getAddressIndexFromKey('line3')]
  )
}
