import { lighten, transparentize } from 'polished'

import colors from './colors'

// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

export default {
  'logo': 'https://assets.happitu.com/public/logo/2021/happitu-purple-500.svg',
  'termsUrl': '//happitu.com/legal/terms/',
  'privacyUrl': '//happitu.com/legal/privacy/',
  'homeUrl': '//happitu.com',

  // app
  'app-background': 'white',
  'app-background-translucent': 'rgba(255, 255, 255, .95)',
  'app-alt-background': colors.grey[200],
  'app-alt-background-translucent': transparentize(0.05, colors.grey[200]),

  'visitor-background': colors.grey[100],

  'link-color-hover': '',

  'border-color-primary': colors.grey[200],

  'text-color-primary': colors.grey[500],
  'text-color-primary-hover': colors.grey[500],
  'text-color-primary-disabled': colors.grey[200],
  'text-color-secondary': colors.grey[300],
  'text-color-inverse': 'white',
  'text-color-warn': 'red',
  'text-weight-normal': '400',
  'text-weight-medium': '600',

  'boxShadow': `0 3px 10px -2px rgba(0, 0, 0, 0.05), 0 1px 1px 1px rgba(0, 0, 0, 0.05), 0 2px 3px -1px rgba(0, 0, 0, 0.075)`,

  'modalMask-background': transparentize(0.2, colors.grey[700]),
  'modal-background': 'white',
  'modal-border-radius': '12px',
  'modal-boxShadow': '0 5px 30px rgba(0, 0, 0, 0.2)',

  'border-color': colors.grey[300],
  'dark-border-color': colors.grey[400],
  'border-active-color': colors.indigo[500],

  'header-background': 'white',

  'appMask-background': transparentize(0.6, colors.grey[700]),
  'appMask-dark-background': transparentize(0.1, colors.grey[900]),
  'appMask-light-background': transparentize(0.2, colors.grey[200]),

  'menu-background': 'white',
  'menu-border-color': colors.grey[400],
  'menu-border-radius': '10px',
  'menu-zIndex': '10',
  'menu-box-shadow':
    '0 8px 16px -2px rgba(0, 0, 0, 0.05), 0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 2px 3px -1px rgba(0, 0, 0, 0.075)',

  'toggle-active-background': colors.mint[500],
  'toggle-default-background': colors.grey[200],
  'toggle-handle-background': 'white',

  'pill-error-background': colors.red[500],
  'pill-error-color': 'white',
  'pill-default-background': colors.grey[200],
  'pill-default-color': colors.grey[600],
  'pill-success-background': colors.mint[500],
  'pill-success-color': 'white',
  'pill-warning-background': colors.yellow[300],
  'pill-warning-color': colors.yellow[700],
  'pill-inactive-background': colors.grey[400],
  'pill-inactive-color': 'white',

  'info-flag-background': colors.grey[200],
  'info-flag-color': colors.grey[600],

  'text-active-background': colors.indigo[200],
  'text-default-color': colors.grey[800],
  'text-sub-color': colors.grey[700],
  'text-light-color': colors.grey[500],
  'text-hover-background': colors.grey[200],
  'text-hover-color': colors.indigo[700],
  'text-active-color': colors.indigo[600],
  'text-inactive-color': colors.grey[500],
  'text-error-color': colors.red[600],
  'text-warning-color': colors.red[500],
  'text-success-color': colors.green[500],
  'text-modified-color': colors.yellow[500],
  'text-variable-highlight-background': colors.green[100],
  'text-variable-color': colors.green[500],

  'textSelection-background': 'auto',
  'textSelection-color': 'auto',

  'banner-primary-background': colors.indigo[500],
  'banner-primary-color': 'white',

  // Deprecate this block - use standard button styling instead of overrides.
  'button-active-background': colors.grey[300],
  'button-active-color': '',
  'button-default-background': colors.grey[300],
  'button-default-color': colors.grey[800],
  'button-hover-background': '',
  'button-hover-color': '',
  'button-inactive-background': '',
  'button-inactive-color': '',

  // Used in button.styles
  'button-base-active-background': lighten(0.02, colors.grey[300]),
  'button-base-active-color': colors.grey[900],
  'button-base-default-background': colors.grey[200],
  'button-base-default-color': colors.grey[800],
  'button-base-hover-background': lighten(0.01, colors.grey[200]),
  'button-base-hover-color': colors.grey[800],
  'button-base-inactive-background': colors.grey[200],
  'button-base-inactive-color': colors.grey[500],
  'button-base-borderColor': 'transparent',
  'button-base-highlight-background': colors.grey[200],
  'button-base-highlight-color': colors.indigo[600],

  'button-primary-activeGhost-background': colors.indigo[200],
  'button-primary-active-background': colors.indigo[600],
  'button-primary-active-color': 'white',
  'button-primary-default-background': colors.indigo[500],
  'button-primary-default-color': 'white',
  'button-primary-hover-background': lighten(0.03, colors.indigo[500]),
  'button-primary-hover-color': 'white',
  'button-primary-inactive-background': colors.grey[200],
  'button-primary-inactive-color': colors.grey[700],
  'button-primary-borderColor': 'transparent',

  'button-secondary-active-background': colors.indigo[300],
  'button-secondary-active-color': colors.indigo[500],
  'button-secondary-default-background': lighten(0.05, colors.indigo[200]),
  'button-secondary-default-color': colors.indigo[500],
  'button-secondary-hover-background': colors.indigo[200],
  'button-secondary-hover-color': colors.indigo[500],
  'button-secondary-inactive-background': colors.grey[200],
  'button-secondary-inactive-color': colors.grey[700],
  'button-secondary-borderColor': 'transparent',

  'button-warn-active-background': colors.red[600],
  'button-warn-active-color': colors.grey[100],
  'button-warn-default-background': colors.red[500],
  'button-warn-default-color': 'white',
  'button-warn-hover-background': colors.red[400],
  'button-warn-hover-color': 'white',
  'button-warn-inactive-background': colors.red[300],
  'button-warn-inactive-color': colors.grey[100],
  'button-warn-borderColor': 'transparent',

  'button-dark-active-background': lighten(0.05, colors.grey[800]),
  'button-dark-active-color': colors.grey[200],
  'button-dark-default-background': colors.grey[800],
  'button-dark-default-color': colors.grey[200],
  'button-dark-hover-background': lighten(0.01, colors.grey[800]),
  'button-dark-hover-color': colors.grey[300],
  'button-dark-inactive-background': colors.grey[600],
  'button-dark-inactive-color': colors.grey[100],
  'button-dark-borderColor': 'transparent',

  'ghostButton-active-background': transparentize(0.5, colors.indigo[200]),
  'ghostButton-active-color': colors.indigo[500],

  'input-default-background': 'white',
  'input-default-borderColor': colors.grey[500],
  'input-default-placeholderColor': colors.grey[500],
  'input-disabled-background': colors.grey[200],
  'input-disabled-color': colors.grey[600],
  'input-focus-borderColor': colors.indigo[500],
  'input-focus-outline': transparentize(0.8, colors.indigo[500]),

  'radio-active-background': colors.indigo[500],

  'searchInput-inverse-default-borderColor': transparentize(0.8, 'white'),
  'searchInput-inverse-default-color': transparentize(0.2, 'white'),
  'searchInput-inverse-default-placeholderColor': transparentize(0.5, 'white'),
  'searchInput-inverse-default-background': transparentize(0.9, colors.grey[900]),

  'searchInput-default-placeholderColor': colors.grey[700],

  'searchInput-default-color': colors.grey[800],
  // 'searchInput-default-background': transparentize(.9, colors.grey[900]),
  'searchInput-default-background': colors.grey[200],
  'searchInput-default-borderColor': colors.grey[300],
  'searchInput-active-color': colors.grey[800],
  'searchInput-active-background': 'white',
  'searchInput-active-borderColor': colors.grey[600],

  'graph-default-background': colors.grey[500],
  'graph-ghosted-background': transparentize(0.65, colors.grey[500]),

  'ticketStatus-open-background': colors.red[200],
  'ticketStatus-open-color': colors.red[700],
  'ticketStatus-closed-background': colors.green[200],
  'ticketStatus-closed-color': colors.green[700],
  'ticketStatus-invalid-background': colors.grey[200],
  'ticketStatus-invalid-color': colors.grey[700],
  'ticketStatus-other-background': colors.yellow[200],
  'ticketStatus-other-color': colors.yellow[700],

  'tableCol-default-borderColor': colors.indigo[500],
  'tableCol-active-background': transparentize(0.95, colors.indigo[500]),
  'tableRow-default-background': 'white',
  'tableRow-alt-background': colors.grey[100],
  'tableCell-default-borderColor': colors.grey[300],

  'tableHeader-sort-color': colors.indigo[500],
  'tableHeader-default-color': colors.grey[700],
  'tableHeader-default-background': 'white',
  'tableHeader-active-background': 'white',
  'tableHeader-active-color': colors.indigo[500],
  'tableHeader-base-drag-control': colors.grey[500],

  'link-active-color': '',
  'link-default-color': colors.indigo[500],
  'link-hover-color': colors.indigo[700],

  'tooltip-background': colors.grey[800],
  'tooltip-color': 'white',
  'tooltip-onboard-background': colors.indigo[500],
  'tooltip-onboard-color': 'white',

  'notice-error-background': 'rgb(255, 242, 198)',
  'notice-error-iconColor': colors.yellow[800],
  'notice-info-background': colors.grey[200],
  'notice-info-iconColor': colors.grey[600],
  'notice-info-color': colors.grey[700],
  'notice-success-background': transparentize(0.8, colors.green[400]),
  'notice-success-iconColor': colors.green[600],
  'notice-warn-background': colors.yellow[100],
  'notice-warn-iconColor': colors.yellow[600],
  'notice-warn-color': colors.yellow[900],

  'notice-blue-background': colors.indigo[500],
  'notice-blue-iconColor': 'white',
  'notice-blue-color': 'white',

  'noticeButton-success-background': 'rgba(128, 128, 128, .15)',
  'noticeButton-success-hover-background': 'rgba(128, 128, 128, .25)',

  'noticeButton-warn-background': colors.yellow[100],
  'noticeButton-warn-color': colors.yellow[800],
  'noticeButton-warn-hover-background': transparentize(0.9, colors.yellow[600]),
  'noticeButton-warn-active-background': transparentize(0.8, colors.yellow[600]),

  'notification-callout-background': colors.indigo[100],
  'notification-callout-borderColor': colors.indigo[400],
  'notification-callout-color': colors.indigo[500],

  'notification-background': colors.grey[200],
  'notification-default-color': 'white',
  'notification-default-background': colors.grey[500],
  'notification-pending-color': colors.yellow[500],
  'notification-pending-background': colors.yellow[500],
  'notification-success-background': colors.green[500],
  'notification-success-color': 'white',
  'notification-failed-color': 'white',
  'notification-failed-background': colors.red[500],

  'voidCallout-info-color': colors.grey[500],
  'voidCallout-error-color': colors.red[500],

  'error-page-color': colors.grey[600],

  // pane
  'pane-background': 'white',

  'paneHeader-background': colors.indigo[700],

  'paneText-default-color': colors.grey[700],
  'paneSubText-default-color': colors.grey[600],
  'paneText-hover-background': colors.grey[200],
  'paneText-active-background': colors.grey[300],

  'paneControlButton-active-background': transparentize(0.3, colors.grey[300]),
  'paneControlButton-active-color': colors.grey[900],
  'paneControlButton-default-background': colors.grey[200],
  'paneControlButton-default-color': colors.grey[800],
  'paneControlButton-hover-background': lighten(0.01, colors.grey[200]),
  'paneControlButton-hover-color': colors.grey[900],
  'paneControlButton-inactive-background': '',
  'paneControlButton-inactive-color': colors.grey[500],

  'iconMint-background': colors.mint[400],
  'iconBlue-background': colors.blue[600],
  'iconRed-background': colors.red[500],
  'iconRed-color': 'white',
  'iconPurple-background': colors.purple[500],
  'iconPurple-color': 'white',
  'iconDefault-background': colors.grey[500],
  'iconDefault-color': 'white',
  'iconGreen-background': colors.green[500],
  'iconGreen-color': 'white',

  'iconSuccess-color': colors.green[400],
  'iconError-color': colors.red[500],
  'iconWarn-color': colors.yellow[500],

  // main nav
  'mainNav-background': colors.indigo[500],
  'mainNav-alt-background': `rgb(105, 100, 228)`,
  'mainNav-divider': `rgba(255, 255, 255, .7)`,
  'mainNav-mask-background': `linear-gradient(to left, rgba(105, 100, 228, 0.8), rgba(105, 100, 228, 0))`,

  'mainNavButton-default-color': colors.purple[200],

  'mainNavButton-active-background': transparentize(0.75, colors.purple[900]),
  'mainNavButton-active-color': 'white',

  'mainNavButton-hover-background': 'transparent',
  'mainNavButton-hover-color': 'white',

  'avatar-default-background': colors.grey[600],
  'avatar-inverse-background': transparentize(0.8, colors.indigo[100]),

  'subNav-default-background': colors.indigo[500],

  // Help Topics
  'blockquoteBorder-color': transparentize(0.5, colors.mint[500]),
  'blockquoteIcon-color': colors.mint[500],

  // FullscreenImagePreview
  'toolbar-dark-background': transparentize(0.05, colors.grey[900]),
  'toolbar-dark-color': colors.grey[200],

  // Select
  'select-default-background': 'white',
  'select-default-borderColor': colors.grey[500],
  'select-default-placeholderColor': colors.grey[500],
  'select-focus-background': 'white',
  'select-disabled-background': colors.grey[200],

  // Select Option
  'selectOption-disabled-background': colors.grey[200],
  'selectOption-disabled-color': '#eee',
  'selectOption-focused-background': 'rgba(0, 0, 0, 0.035)',
  'selectOption-selected-background': colors.indigo[500],
  'selectOption-selected-color': colors.grey[100],

  'selectOption-highlight-color': colors.yellow[800],
  'selectOption-highlight-background': colors.yellow[100],

  'selectOptionCheckbox-disabled-borderColor': colors.grey[400],
  'selectOptionCheckbox-focused-borderColor': colors.grey[400],
  'selectOptionCheckbox-selected-borderColor': colors.grey[400],

  'progressBar-default-borderColor': transparentize(0.8, colors.indigo[500]),
  'progressBarInner-default-background': colors.indigo[500],
  'progressBarOuter-default-background': transparentize(0.3, 'white'),

  // Insights
  'highlight-default-background': transparentize(0.9, colors.indigo[500]),
  'highlight-default-borderColor': colors.indigo[500],
  'highlight-default-color': colors.indigo[700],

  // Audio player
  'audioBlock-primary-background': colors.blue[500],
  'audioBlock-secondary-background': colors.grey[300],
  // 'playbackCursor-default-background': colors.blue[500],
  'playbackCursor-default-background': colors.red[600],
  'playbackCursorMask-default-background': transparentize(0.9, colors.blue[500]),
  'playbackRateButton-default-background': colors.blue[500],
  'playbackRateButton-active-background': colors.blue[400],
  'playbackRateButton-default-color': 'white',

  // Badges
  'badge-default-background': colors.blue[200],
  'badge-default-borderColor': colors.blue[200],

  'badge-warn-background': 'white',
  'badge-warn-borderColor': colors.red[600],

  'agent-notes-default-color': colors.magenta[700],

  // Scrollbar
  'scrollbar-background-track': 'rgba(0, 0, 0, 0.05)',
  'scrollbar-background-thumb': 'rgba(0, 0, 0, 0.2)',
  'scrollbar-background-thumb-hover': 'rgba(0, 0, 0, 0.3)',
}
