import { Editor, Range } from 'slate'

import {
  indentSelection,
  setLink,
  toggleMark,
  unindentSelection,
} from 'happitu/src/helpers/editor/formattingHelper'

export interface Hotkeys {
  [key: string]: (editor?: Editor) => void
}

const handleModK = (editor: Editor) => {
  const { selection } = editor
  const path = selection ? Editor.path(editor, selection) : undefined
  if (selection && Range.isExpanded(selection) && path && path.length !== 0) {
    setLink(editor, '')
  }
}

export const defaultHotkeys: Hotkeys = {
  'mod+b': (editor: Editor) => toggleMark(editor, 'bold'),
  'mod+i': (editor: Editor) => toggleMark(editor, 'italic'),
  'mod+u': (editor: Editor) => toggleMark(editor, 'underline'),
  'mod+`': (editor: Editor) => toggleMark(editor, 'code'), // TODO: This isn't being captured in Chrome or Firefox, but it works in Safari. Keypress isn't capturing ` when Meta is pressed, for some reason.
  'mod+k': handleModK,
  'tab': (editor: Editor) => indentSelection(editor),
  'shift+tab': (editor: Editor) => unindentSelection(editor),
}
