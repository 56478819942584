import styled, { css } from 'styled-components'
import { ifProp, ifNotProp } from 'styled-tools'

import Icon from 'happitu/src/components/Icon'
import SubLoader from 'happitu/src/components/Loaders/SubLoader'
import { FlexRow } from 'happitu/src/components/_DEPRECATED/Flex'
import getStyle from 'happitu/src/getStyle'
import { inputFocus } from 'happitu/src/themes/mixins/form'
import theme, { anim, color } from 'theme'

export const ClearIcon = styled(Icon)`
  cursor: pointer;
`

export const Input = styled.input`
  background: transparent;
  border: none;
  box-shadow: none;
  color: ${getStyle('searchInput-default-color')};
  flex-grow: 1;
  padding: ${theme('input.padding')};

  &:focus {
    border-color: none;
    box-shadow: none;
  }

  &::placeholder {
    color: ${getStyle('searchInput-default-placeholderColor')};
  }
`

export const LeftPlaceholder = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  margin-left: 1em;
  width: 15px;
`

export const Loader = styled(SubLoader)`
  position: relative;
  height: 15px;
  width: 15px;
  transform: translateX(-50%);
`

export const ResultCount = styled.div``

export const RightPlaceholder = styled(FlexRow)`
  color: ${color('grey', 'lighter')};
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 1em;
`

export const SearchIcon = styled(Icon)``

// prettier-ignore
export const Wrapper = styled(FlexRow)<{ focus: boolean, inverse?: boolean }>`
  align-items: center;
  background: ${ifProp(
    'focus',
    getStyle('searchInput-active-background'),
    getStyle('searchInput-default-background'),
  )};
  border-radius: ${theme('input.borderRadius')};
  border: 1px solid
    ${ifProp(
      'focus',
      getStyle('searchInput-active-borderColor'),
      getStyle('searchInput-default-borderColor'),
    )};
  color: ${getStyle('searchInput-default-color')};
  flex-shrink: 0;
  min-width: 160px;
  position: relative;
  transition: border ${anim('speed', 'fast')}, box-shadow ${anim('speed', 'fast')};

  ${ifProp(
    'inverse',
    css`
      ${ifNotProp(
        'focus',
        css`
          background: ${getStyle('searchInput-inverse-default-background')};
          border: 1px solid ${getStyle('searchInput-inverse-default-borderColor')};
          color: ${getStyle('searchInput-inverse-default-placeholderColor')};

          input {
            color: ${getStyle('searchInput-inverse-default-color')};

            &::placeholder {
              color: ${getStyle('searchInput-inverse-default-placeholderColor')};
            }
          }
        `,
      )}
    `,
  )}

  ${ifProp('focus', inputFocus)}
`
