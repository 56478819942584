/* eslint-disable max-len */
export const URL = /(https?:\/\/)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/
export const URL_GLOBAL = new RegExp(URL, 'g')
export const URL_PROTOCOL = /(https?:\/\/)/
export const URL_NO_EMAIL = /(?:^|[^\n@.\w-])([a-z0-9]+:\/\/)?(\w(?!ailto:)\w+:\w+@)?([\w.-]+\.[a-z]{2,4})(:[0-9]+)?(\/.*)?(?=$|[^@.\w-])/gim
export const HOST = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)+([A-Za-z]|[A-Za-z][A-Za-z0-9-]*[A-Za-z0-9])$/m
export const FILE_EXTENTSION = /\.[0-9a-z]+$/i
export const ONE_LOWER_CASE_LETTER = /[a-z]/
export const ONE_UPPER_CASE_LETTER = /[A-Z]/
export const ONE_NUMBER = /\d/
export const ONE_SPECIAL_CHARACTER = /`|~|!|@|#|\$|%|\^|&|\*|\(|\)|\+|=|\[|\{|\]|\}|\||\\|'|<|,|\.|>|\?|\/|""|;|:/
export const MINIMUM_EIGHT_CHARACTERS = /.{8,}/
export const DATE = /^\d{2}([./-])\d{2}\1\d{4}$/
export const EMAIL = /^(?:^|mailto:)?[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/i
export const EMAIL_GLOBAL = new RegExp(EMAIL, 'g')
export const TELEPHONE = /\d{7,}/i
export const HEX_COLOR = /^#(?:[0-9a-f]{3}){1,2}$/i
export const LINE_BREAK = /(\r\n|\r|\n)/g

export const IMAGE = /image\//
export const PHONE = /tel|phone|cell|mobile/i
export const ADDRESS = /address|location/i
export const FILE_FORMATS = /\.(pdf|png|jpg|jpeg)$/
