import styled from 'styled-components'

import { color } from 'theme'

const NoMatches = styled.div`
  color: ${ color('grey', 'light') };
  padding: 1em 1.5em;
`

export default NoMatches
