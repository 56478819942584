import * as React from 'react'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'

import Icon from 'happitu/src/components/Icon'
import getStyle from 'happitu/src/getStyle'
import { IS_MAC } from 'happitu/src/helpers/domHelper'
import { font } from 'theme'

interface WrapperProps {
  ghost?: boolean
  inverted?: boolean
}

interface Props extends WrapperProps {
  hotkey: string
  className?: string
}

const Wrapper = styled.span<WrapperProps>`
  color: ${ifProp('inverted', 'white', getStyle('text-sub-color'))};
  font-size: ${font('size', 'small')};
  font-weight: ${font('weight', 'normal')};
  background: ${ifProp('ghost', 'transparent', 'rgba(0, 0, 0, .03)')};
  border-radius: 3px;
  display: inline-block;
  padding: ${ifProp('ghost', '0', '2px 4px')};

  i {
    margin-right: 0;
  }
`

function HotkeyFigure(props: Props) {
  const keys = props.hotkey
    .replace(/mod/i, IS_MAC ? '⌘' : 'ctrl')
    .replace(/return/i, '↵')
    .split('+')

  return (
    <Wrapper
      className={props.className}
      ghost={props.ghost}
      data-icon
      inverted={props.inverted}
    >
      {keys.map((key, index) => {
        return (
          <span key={index}>
            {key.charAt(0).toUpperCase() + key.slice(1)}
            {index + 1 < keys.length && <Icon type="plus" />}
          </span>
        )
      })}
    </Wrapper>
  )
}

export default React.memo(HotkeyFigure)
