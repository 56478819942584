import React, { MouseEventHandler, useRef, useState } from 'react'
import styled from 'styled-components'

import Menu from '../Menu'

import { IconButton } from 'happitu/src/components/Buttons'
import { ListChildren } from 'happitu/src/components/Lists/List.types'
import SelectableListProvider from 'happitu/src/components/Lists/SelectableListProvider'
import { ButtonProps } from 'happitu/src/components/button'

interface Props extends ButtonProps {
  children: ListChildren
  className?: string
  defaultVisibility?: boolean
  icon?: string
  menuStyle?: Omit<React.CSSProperties, 'scale'>
}

const OptionMenuBody = (props: {
  children: ListChildren
  onClick: MouseEventHandler
}) => {
  const listRef = useRef<HTMLDivElement>(null)
  return (
    <div
      role="list"
      ref={listRef}
      tabIndex={0}
      style={{ outline: 'none' }}
      onClick={props.onClick}
    >
      <SelectableListProvider ref={listRef}>{props.children}</SelectableListProvider>
    </div>
  )
}

const OptionMenu = ({
  defaultVisibility = false,
  icon = 'horizontal-dots',
  ...props
}: Props) => {
  const [isVisible, setVisibility] = useState(defaultVisibility)
  const onOpen = () => setVisibility(true)
  const onClose = () => setVisibility(false)

  return (
    <div className={props.className}>
      <IconButton
        rounded
        ghost={props.ghost}
        icon={icon}
        onClick={onOpen}
        title={props.title}
      />

      <Menu isVisible={isVisible} onClose={onClose} style={props.menuStyle}>
        <OptionMenuBody onClick={onClose}>{props.children}</OptionMenuBody>
      </Menu>
    </div>
  )
}

export default styled(OptionMenu)`
  height: fit-content;
  position: relative;

  ${Menu} {
    padding: 0.5em;
    min-width: 200px;
    width: max-content;
  }

  hr {
    margin: 0.5em 0;
  }
`
