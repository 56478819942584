import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import scrollbar, { scrollbarYOffset } from 'happitu/src/themes/scrollbar'

const MenuList = styled.ul<{ maxHeight?: number, compact?: boolean }>`
  margin: 0;

  ${ifProp(
    'compact',
    css`
      > li {
        margin: 0px;
      }
    `,
  )}
  ${(props) =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight}px;
      overflow-y: auto;
      margin-right: -4px;
      ${scrollbar}
      ${scrollbarYOffset}
    `}

  ${(props) =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight}px;
      overflow-y: auto;
      margin-right: -4px;
      ${scrollbar}
      ${scrollbarYOffset}
    `}

  *:first-child > *:first-child {
    border-top: none;
  }
`

export default MenuList
