import React from 'react'

import Option from './Option'
import { OptionProps } from './Option.types'

import Icon from 'happitu/src/components/Icon'
import { FlexRow } from 'happitu/src/components/_DEPRECATED/Flex'

const CreateOption = (props: OptionProps) => {
  return (
    <Option {...props} checkbox={false} style={{ position: 'sticky', bottom: 0 }}>
      <FlexRow>
        <Icon type="plus" />
        <div>{props.label}</div>
      </FlexRow>
    </Option>
  )
}

export default CreateOption
