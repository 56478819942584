let service: {
  init: Function
  people: {
    set: Function
  }
  identify: Function
  track: Function
}

interface QueueItem {
  func: 'init' | 'people.set' | 'identify' | 'track'
  params: any[]
}

function Tracker () {
  const preLoadQueue: QueueItem[] = []

  import(/* webpackChunkName: "mixpanel-browser" */ 'mixpanel-browser').then((module) => {
    service = module.default
    service.init(process.env.MIXPANEL_KEY)
    _flushPreLoadQueue()
  })

  function _flushPreLoadQueue () {
    const item = preLoadQueue.shift()
    if (item) {
      _push(item.func, ...item.params)
      _flushPreLoadQueue()
    }
  }

  function _push (func: QueueItem['func'], ...params: any[]) {
    if (!service) {
      preLoadQueue.push({ func, params })
    } else {
      if (func === 'people.set') {
        service.people.set(...params)
      } else {
        service[func](...params)
      }

    }
  }

  function identify (id: ID) {
    _push('identify', id)
  }

  function setProfile (user: UserRecord) {
    _push('people.set', {
      '$name': `${ user.firstName } ${ user.lastName }`,
      '$email': user.email,
      'userType': user.userType,
    })
  }

  function track (name: string, properties: Record<string, object | string | number | boolean>) {
    _push('track', name, properties)
  }

  return {
    identify,
    setProfile,
    track,
  }
}

export default Tracker()
