import { isEmpty } from 'lodash'

import { getWorkflowEmailDomain } from './localizeHelper'

export const generateSubject = (email: InteractionEmail) => `Re: ${email.subject}`

export const appendTicketNumber = (message: string, ticketNumber: string) => {
  if (isEmpty(message.match(/\[Ticket Number: \d+\]/))) {
    message = `${message}\n\n[Ticket Number: ${ticketNumber}]`
  }

  return message
}

export const generateEmailAddressLocal = (workflowName?: string) => {
  return (
    workflowName
      // remove all except alphanumeric, dashes, underscores, periods, whitespace
      ?.replace(/[^a-zA-Z0-9\-_.\s]+/gi, '')
      .trim()
      // replace continuous whitespace with one dash
      .replace(/\s+/gi, '-')
      .toLowerCase() || ''
  )
}

export const generateEmailAddress = (orgEmailDomain: string, workflowName: string) => {
  return `${generateEmailAddressLocal(
    workflowName,
  )}@${orgEmailDomain}.${getWorkflowEmailDomain()}`
}

export const assembleWorkflowEmailAddress = (
  workflowEmailAddress: string,
  orgEmailDomain: string,
) => {
  return `${workflowEmailAddress}@${orgEmailDomain}`
}

export const delimitedListRegExp = /,\s*|;\s*/

export const getRenderValue = (value: string[], allowMultiEmail?: boolean) => {
  const newValue = [...value]
  if (!allowMultiEmail) return [newValue[0]]
  if (value[value.length - 1] !== '' || value.length === 1) return [...newValue, '']
  return newValue
}
