/* eslint-disable no-console */
import { useContext } from 'react'
import { RenderElementProps, useFocused, useSelected } from 'slate-react'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import getStyle from 'happitu/src/getStyle'
import {
  formatDate,
  SHORT_HAND_DATE_WITH_TIME_FORMAT,
} from 'happitu/src/helpers/dateHelpers'
import { VariableContext } from 'happitu/src/modules/workflow-variables'
import { WorkflowVariableElement } from 'happitu/src/types/slate-types'

interface Props extends RenderElementProps {
  element: WorkflowVariableElement
  variables?: Record<string, string>
}

interface WrapperProps {
  focused?: boolean
  isPresent?: boolean
}

const Variable = styled.span<WrapperProps>`
  display: inline-block;
  background: ${ifProp(
    'focused',
    getStyle('text-variable-highlight-background'),
    'transparent',
  )};

  ${ifProp(
    'isPresent',
    css`
      color: ${getStyle('text-variable-color')};
      border-bottom: 1px solid ${getStyle('text-variable-color')};
    `,
    css`
      color: ${getStyle('text-inactive-color')};
      border-bottom: 1px dotted ${getStyle('text-inactive-color')};
    `,
  )};
`

function isIsoDate(str: string) {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false
  const d = new Date(str)
  return d.toISOString() === str
}

export const humaizeVariableValue = (
  variable: string,
  placeholder: string,
  variables: Record<string, string>,
) => {
  const variab = variables[variable]
  const variableValue = Array.isArray(variab) ? variab.join('|') : variab
  const isPresent = !!variableValue
  const value = isPresent
    ? variableValue
        .split('|')
        .filter((v) => !!v)
        .join(' ')
        .trim()
    : placeholder

  if (!value) return placeholder

  const formattedDate = isIsoDate(value)
    ? formatDate(value, SHORT_HAND_DATE_WITH_TIME_FORMAT)
    : null

  return formattedDate ? formattedDate : value
}

const EditableWorkflowVariable = ({ attributes, children, element }: Props) => {
  const selected = useSelected()
  const focused = useFocused()
  const variables = useContext(VariableContext)
  const isPresent = !!variables[element.variable]
  const value = humaizeVariableValue(element.variable, element.placeholder, variables)
  return (
    <Variable
      isPresent={isPresent}
      focused={selected && focused}
      {...attributes}
      data-variable={element.variable}
    >
      {value}
      {children}
    </Variable>
  )
}

export default EditableWorkflowVariable
