import React from 'react'

import { pluralize } from 'happitu/src/helpers/stringHelper'

const concatValue = (
  value: string[],
  concatPos = value.length,
  itemLabel?: string | { plural: string, singular: string },
) => {
  if (concatPos === 0) {
    const length = value.length - concatPos
    const label = ' ' + pluralize(itemLabel || 'item', length)
    return `${value.length}${label}`
  } else if (concatPos && concatPos < value.length) {
    const newVal = value.slice(0, concatPos)
    const length = value.length - concatPos
    const label = itemLabel ? ' ' + pluralize(itemLabel, length) : ''
    if (newVal.length === 1) return newVal[0] + ` and ${length} more${label}`
    return (
      newVal.reduce((acc, cur) => acc + cur + ', ', '') + `and ${length} more${label}`
    )
  } else {
    if (value.length === 1) return value[0]
    if (value.length === 2) return value[0] + ' and ' + value[1]
    return value.reduce((acc, cur, index) => {
      if (index === value.length - 1) return acc + 'and ' + cur
      return acc + cur + ', '
    }, '')
  }
}

// Keep concatenating values until they fit in their container
const getConcatValue = (
  value: string[],
  ghostRef: any,
  targetWidth: number,
  index: number,
): string => {
  const newVal = concatValue(value, index)
  ghostRef.current.innerText = newVal
  const currentWidth = ghostRef.current.getBoundingClientRect().width
  if (currentWidth > targetWidth)
    return getConcatValue(value, ghostRef, targetWidth, index - 1)
  return newVal
}

export default function useConcateValue<R extends HTMLElement>(value: string[]) {
  const [displayVal, setDisplayVal] = React.useState<string>(
    !!value.length ? concatValue(value) : '',
  )
  const ref = React.useRef<R>(null)
  const ghostRef = React.useRef<R>(null)

  React.useEffect(() => {
    if (ref.current && ghostRef.current) {
      const refMetrics = ref.current.getBoundingClientRect()
      ghostRef.current.innerText = ''
      for (let index = 0; index < value.length; index++) {
        ghostRef.current.innerText = concatValue(value.slice(0, index + 1))
        const ghostMetrics = ghostRef.current.getBoundingClientRect()
        if (ghostMetrics.width > refMetrics.width) {
          const newVal = getConcatValue(value, ghostRef, refMetrics.width, index)
          return setDisplayVal(newVal)
        }
      }
      if (
        ghostRef.current.getBoundingClientRect().width <
        ref.current.getBoundingClientRect().width
      ) {
        if (!!value.length) {
          setDisplayVal(concatValue(value))
        } else {
          setDisplayVal('')
        }
      }
    }
  }, [value])

  return { displayVal, ref, ghostRef }
}
