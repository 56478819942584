// eslint-disable-next-line import/default
import prettyBytes from 'pretty-bytes'
import React from 'react'

import { FileSize, Heading, Label, Wrapper } from './AttachmentItem.styles'

import { IconButton } from 'happitu/src/components/Buttons'
import Icon from 'happitu/src/components/Icon'
import { getFileUrl } from 'happitu/src/helpers/fileUploadHelpers'

interface Props {
  disabled?: boolean
  file: Partial<FileUploadRecord>
  onRemove?: (id: ID) => void
}

export default function AttachmentItem(props: Props) {
  const handleRemove = () => {
    if (typeof props.onRemove === 'function' && props.file.id) {
      props.onRemove(props.file.id)
    }
  }
  return (
    <Wrapper
      disabled={props.disabled}
      animate={{ transform: 'translate3d(0,0px,0)', opacity: 1 }}
      initial={{ transform: 'translate3d(0,1em,0)', opacity: 0 }}
      exit={{ transform: 'translate3d(0,-1em,0)', opacity: 0 }}
    >
      <Label>
        <Heading>{props.file.name}</Heading>
        <FileSize>{prettyBytes(props.file.contentLength || 0)}</FileSize>
      </Label>
      {!props.disabled ? (
        <IconButton
          as="a"
          href={props.file.id && getFileUrl(props.file.id)}
          target="_blank"
          icon="download"
        />
      ) : (
        <Icon type="download" />
      )}
      {typeof props.onRemove === 'function' && (
        <IconButton
          icon="close"
          size="small"
          title="Remove attachment"
          onClick={handleRemove}
        />
      )}
    </Wrapper>
  )
}
