import { isWhiteLabel } from '../helpers/localizeHelper'

/**
 * Gets the correct end point for a service depending on the NODE_ENV.
 * @param {String} serviceType  The service being used.
 * @returns {String}
 */
const getServiceHost = (serviceType: string) => {
  const service = process.env[`SERVICE_${serviceType}`]
  if (!service) {
    throw new Error(`getServiceHost: unknown service ${serviceType}`)
  }
  return service
}

export const selfServiceHost = () =>
  isWhiteLabel ? 'https://guide.logixx.io' : getServiceHost('SELF_SERVICE')

export const getServicePath = (
  orgId: ID,
  workflowId: ID,
  test = true,
  overwrites = {},
) => {
  const queryParams = test ? 'testTicket=true' : ''
  const params = Object.keys(overwrites).map((key: keyof typeof overwrites) => {
    return `${key}=${overwrites[key]}`
  })

  return `${selfServiceHost()}/s/org/${orgId}/workflow/${workflowId}/new?${[
    queryParams,
    ...params,
  ].join('&')}`
}

/**
 * Return the correct api endpoint url based on the process environment.
 * @return {String} API URL
 */
export const apiHost = () => {
  return getServiceHost('API_V1')
}

/**
 * Return the correct reporting endpoint url based on the process environment.
 * @return {String} Reporting API URL
 */
export const reportingHost = getServiceHost('REPORTING')

/**
 * Returns the correct web-socket endpoint url based on the process environment.
 * @returns {String} WEBSOCKET URL
 */
export const webSocketHost = () => getServiceHost('WEBSOCKET')

export const wsUrl = (path: string) => apiHost().replace(/^http/, 'ws') + path
