import { useContext, useEffect } from 'react'

import { TicketContext } from '../../ticketing/ticketing'

import { error } from 'happitu/src/helpers/loggerHelper'
import { getParams, WorkTicketRouteParams } from 'happitu/src/helpers/routeHelpers'

export const useCurrentInteraction = (): TicketInteractionRecord => {
  const { ticketInteractionId } = getParams<WorkTicketRouteParams>()
  const { state } = useContext(TicketContext)
  const ticketInteraction = state.ticketInteractions?.findById<TicketInteractionRecord>(
    ticketInteractionId,
  )
  if (!ticketInteraction)
    throw new Error(`useCurrentInteraction: missing interaction: ${ticketInteractionId}`)
  return ticketInteraction
}

export const useKConversationHook = (body: any) => {
  const ticketInteraction = useCurrentInteraction()
  useEffect(() => {
    if (!ticketInteraction.externalData.crmConversationId)
      throw new Error('useKConversationHook: missing conversation id')
    // eslint-disable-next-line no-console
    console.log('conversation hook', body)
    window.Kustomer.request(
      {
        url: '/v1/conversations/' + ticketInteraction.externalData.crmConversationId,
        method: 'put',
        body: body,
      },
      (err, conversations) => {
        if (err || !conversations) {
          error(err)
          return
        }
      },
    )
  }, [])
}

export const useKCustomerHook = (body: any) => {
  const ticketInteraction = useCurrentInteraction()

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('customer hook', body)
    if (!ticketInteraction.externalData.crmContactId)
      throw new Error('useKCustomerHook: missing contact id')

    window.Kustomer.request(
      {
        url: '/v1/customer/' + ticketInteraction.externalData.crmContactId,
        method: 'put',
        body: body,
      },
      (err, conversations) => {
        if (err || !conversations) {
          error(err)
          return
        }
      },
    )
  }, [])
}
