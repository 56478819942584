import { applyMiddleware, createStore } from 'redux'
import { router5Middleware } from 'redux-router5'
import thunk from 'redux-thunk'
import { Router } from 'router5'

import { reduxLogger } from 'happitu/src/helpers/loggerHelper'
import reducers from 'happitu/src/reducers'

export default function configureStore(router: Router, initialState = {}) {
  const createStoreWithMiddleware = applyMiddleware(
    router5Middleware(router),
    thunk,
    reduxLogger,
  )(createStore)
  const store = createStoreWithMiddleware(reducers, initialState)

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('happitu/src/reducers', () => store.replaceReducer(reducers))
  }
  // @ts-ignore
  window.store = store
  return store
}
