import React, { ComponentProps, useState, ReactNode, FunctionComponent } from 'react'
import styled from 'styled-components'

import SectionHeading from './SectionHeading'

import Autogrow from 'happitu/src/components/Animations/Autogrow'
import getStyle from 'happitu/src/getStyle'
import { getUserOption, setUserOption } from 'happitu/src/helpers/user'

interface Props extends Omit<ComponentProps<'section'>, 'title'> {
  defaultVisibility?: boolean
  children: FunctionComponent | ReactNode
  title: ReactNode
  titleIcon?: string
  settingKey?: string
}

const isReactNode = (
  children: FunctionComponent | ReactNode,
): children is FunctionComponent => typeof children === 'function'

const Section = ({
  children,
  defaultVisibility = true,
  title,
  titleIcon,
  settingKey,
  ...props
}: Props) => {
  const nodeRef = React.useRef<HTMLDivElement>(null)
  const [isVisible, setVisibility] = useState(() => {
    const settings = getUserOption('sectionVisibility')

    if (settingKey && settings) {
      return typeof settings[settingKey] === 'boolean'
        ? settings[settingKey]
        : defaultVisibility
    }
    return defaultVisibility
  })

  const handleClick = () => {
    const visibility = !isVisible
    if (settingKey) {
      setUserOption('sectionVisibility', {
        ...getUserOption('sectionVisibility', {}),
        [settingKey]: visibility,
      })
    }
    setVisibility(visibility)
  }

  return (
    <section {...props} ref={nodeRef}>
      <SectionHeading
        title={title}
        titleIcon={titleIcon}
        isVisible={isVisible}
        onClick={handleClick}
      />
      <Autogrow isVisible={isVisible}>
        <div style={{ padding: '.5em 0' }}>
          {isReactNode(children) ? React.createElement(children) : children}
        </div>
      </Autogrow>
    </section>
  )
}

export default styled(Section)`
  padding: 0.5em 0;

  &::not(:last-child) {
    border-bottom: 1px solid ${getStyle('border-color')};
  }
`
