import { MotionProps } from 'framer-motion'
import { CSSProperties } from 'react'

import { Anchor } from './Menu.types'

// Space between the menu and its parent, measured in rem.
const MENU_BUFFER_REM = 1

// eslint-disable-next-line complexity
export const getStyleFromAnchor = (anchor: Anchor) => {
  const { position, alignment } = anchor
  const style: CSSProperties = {}
  const offset = `calc(100% + ${MENU_BUFFER_REM}rem)`

  // Styles from position.
  switch (position) {
    case 'top':
      style.bottom = offset
      break
    case 'right':
      style.left = offset
      break
    case 'bottom':
      style.top = offset
      break
    case 'left':
      style.right = offset
      break
  }

  // Styles from alignment.
  switch (alignment) {
    case 'start':
      if (position === 'top' || position === 'bottom') {
        style.left = '0%'
      } else if (position === 'left' || position === 'right') {
        style.top = '0%'
      }
      break
    case 'center':
      if (position === 'top' || position === 'bottom') {
        style.left = '50%'
      } else if (position === 'left' || position === 'right') {
        style.top = '50%'
      }
      break
    case 'end':
      if (position === 'top' || position === 'bottom') {
        style.right = '0%'
      } else if (position === 'left' || position === 'right') {
        style.bottom = '0%'
      }
      break
  }

  return style
}

// TODO: Maybe slide from different directions based on the position? For the Menu component, it feels right to sort of bounce down... because physics.
export const getSlideTransition = (anchor: Anchor): MotionProps => {
  const { position, alignment } = anchor

  const config = { x: '0px', y: '0px' }

  if (alignment === 'center') {
    if (position === 'top' || position === 'bottom') {
      config.x = '-50%'
    } else if (position === 'left' || position === 'right') {
      config.y = '-50%'
    }
  }

  // NOTE: We aren't really using the initial state as part of the animation, since the component needs to mount before we can update the animation with the correct position.
  // We're instead emulating the animation with the array of values in the 'animate' state, configured using the 'times' prop.
  return {
    initial: { pointerEvents: 'none' },
    animate: {
      // Simulate a little bounce.
      transform: [
        `translate3d(${config.x}, calc(-5px + ${config.y}), 0px)`,
        `translate3d(${config.x}, calc(0px + ${config.y}), 0px)`,
      ],
      opacity: [0, 1],
      pointerEvents: 'auto',
      transition: {
        type: 'tween',
        duration: 0.05, // Default is 0.8 for keyframes.
      },
    },
    exit: {
      pointerEvents: 'none',
      transform: `translate3d(${config.x}, calc(-5px + ${config.y}), 0px)`,
      opacity: 0,
      transition: {
        type: 'spring',
        // stiffness: 100, // Default is 100.
        // damping: 10, // Default is 10.
        mass: 0.1, // Default is 1.
      },
    },
  }
}

// Maybe drop these helpers, or refactor them to compare against the window bounds so that they can be reused when determining the alignment.
const exceedsTop = (top: number, menuHeight: number) => top < menuHeight
const exceedsBottom = (bottom: number, menuHeight: number) => bottom < menuHeight
const exceedsLeft = (left: number, menuWidth: number) => left < menuWidth
const exceedsRight = (right: number, menuWidth: number) => right < menuWidth

// TODO: Maybe take a partially off-screen parent into account.

// eslint-disable-next-line complexity
export const getOptimalAnchor = (defaultAnchor: Anchor, menu: HTMLElement) => {
  // Get the default anchor properties.
  const { position: defaultPosition, alignment: defaultAlignment } = defaultAnchor
  // Initialize the new anchor using the default anchor.
  const optimalAnchor = { ...defaultAnchor }
  // Get the parent of the menu, which should always exist.
  const parentElement = menu.parentElement as HTMLElement
  // Get menu metrics.
  const { height: menuHeight, width: menuWidth } = menu.getBoundingClientRect()
  // Get parent metrics.
  const {
    height: parentHeight,
    width: parentWidth,
    left,
    right,
    top,
    bottom,
  } = parentElement.getBoundingClientRect()
  // Buffer between menu and its parent, measured in px.
  const menuBuffer = MENU_BUFFER_REM * parseFloat(getComputedStyle(menu).fontSize)

  // Check if the menu needs a new position. Fall back on the opposite position first, then either right-to-left or bottom-to-top if it would fit the viewport on the new axis. If nothing works, keep the default.
  if (defaultPosition === 'top' && exceedsTop(top, menuHeight + menuBuffer)) {
    if (!exceedsBottom(window.innerHeight - bottom, menuHeight + menuBuffer)) {
      optimalAnchor.position = 'bottom'
    } else if (window.innerHeight >= menuHeight) {
      if (!exceedsRight(window.innerWidth - right, menuWidth + menuBuffer)) {
        optimalAnchor.position = 'right'
      } else if (!exceedsLeft(left, menuWidth + menuBuffer)) {
        optimalAnchor.position = 'left'
      }
    }
  } else if (
    defaultPosition === 'bottom' &&
    exceedsBottom(window.innerHeight - bottom, menuHeight + menuBuffer)
  ) {
    if (!exceedsTop(top, menuHeight + menuBuffer)) {
      optimalAnchor.position = 'top'
    } else if (window.innerHeight >= menuHeight) {
      if (!exceedsRight(window.innerWidth - right, menuWidth + menuBuffer)) {
        optimalAnchor.position = 'right'
      } else if (!exceedsLeft(left, menuWidth + menuBuffer)) {
        optimalAnchor.position = 'left'
      }
    }
  } else if (defaultPosition === 'left' && exceedsLeft(left, menuWidth + menuBuffer)) {
    if (!exceedsRight(window.innerWidth - right, menuWidth + menuBuffer)) {
      optimalAnchor.position = 'right'
    } else if (window.innerWidth >= menuWidth) {
      if (!exceedsBottom(window.innerHeight - bottom, menuHeight + menuBuffer)) {
        optimalAnchor.position = 'bottom'
      } else if (!exceedsTop(top, menuHeight + menuBuffer)) {
        optimalAnchor.position = 'top'
      }
    }
  } else if (
    defaultPosition === 'right' &&
    exceedsRight(window.innerWidth - right, menuWidth + menuBuffer)
  ) {
    if (!exceedsLeft(left, menuWidth + menuBuffer)) {
      optimalAnchor.position = 'left'
    } else if (window.innerWidth >= menuWidth) {
      if (!exceedsBottom(window.innerHeight - bottom, menuHeight + menuBuffer)) {
        optimalAnchor.position = 'bottom'
      } else if (!exceedsTop(top, menuHeight + menuBuffer)) {
        optimalAnchor.position = 'top'
      }
    }
  }

  // Check if the menu needs a new alignment (using new position). Prioritize start > center > end.
  if (optimalAnchor.position === 'top' || optimalAnchor.position === 'bottom') {
    // Check for vertical positions.
    if (defaultAlignment === 'start') {
      if (left + menuWidth > window.innerWidth) {
        // Check if the menu overflows to the right.
        if (
          right + menuWidth / 2 - parentWidth / 2 < window.innerWidth &&
          window.innerWidth - left + menuWidth / 2 - parentWidth / 2 < window.innerWidth
        ) {
          // Check if center alignment works.
          optimalAnchor.alignment = 'center'
        } else if (window.innerWidth - right + menuWidth < window.innerWidth) {
          // Check if end alignment would work.
          optimalAnchor.alignment = 'end'
        }
      }
    } else if (defaultAlignment === 'center') {
      if (menuWidth < window.innerWidth) {
        // Check if the menu will actually fit in the viewport.
        if (right + menuWidth / 2 - parentWidth / 2 > window.innerWidth) {
          // Check if the menu exceeds the viewport to the right.
          if (window.innerWidth - right + menuWidth < window.innerWidth) {
            // Check if end alignment would work.
            optimalAnchor.alignment = 'end'
          }
        } else if (
          window.innerWidth - left + menuWidth / 2 - parentWidth / 2 >
          window.innerWidth
        ) {
          // Check if the menu exceeds the viewport to the left.
          if (left + menuWidth < window.innerWidth) {
            // Check if start alignment would work.
            optimalAnchor.alignment = 'start'
          }
        }
      }
    } else if (defaultAlignment === 'end') {
      if (window.innerWidth - right + menuWidth > window.innerWidth) {
        // Check if the menu overflows to the left.
        if (
          right + menuWidth / 2 - parentWidth / 2 < window.innerWidth &&
          window.innerWidth - left + menuWidth / 2 - parentWidth / 2 < window.innerWidth
        ) {
          // Check if center alignment works.
          optimalAnchor.alignment = 'center'
        } else if (left + menuWidth < window.innerWidth) {
          // Check if start alignment would work.
          optimalAnchor.alignment = 'start'
        }
      }
    }
  } else if (optimalAnchor.position === 'left' || optimalAnchor.position === 'right') {
    // Check for horizontal positions.
    if (defaultAlignment === 'start') {
      if (top + menuHeight > window.innerHeight) {
        // Check if the menu overflows to the bottom.
        if (
          bottom + menuHeight / 2 - parentHeight / 2 < window.innerHeight &&
          window.innerHeight - top + menuHeight / 2 - parentHeight / 2 <
            window.innerHeight
        ) {
          // Check if center alignment works.
          optimalAnchor.alignment = 'center'
        } else if (window.innerHeight - bottom + menuHeight < window.innerHeight) {
          // Check if end alignment would work.
          optimalAnchor.alignment = 'end'
        }
      }
    } else if (defaultAlignment === 'center') {
      if (menuHeight < window.innerHeight) {
        // Check if the menu will actually fit in the viewport.
        if (bottom + menuHeight / 2 - parentHeight / 2 > window.innerHeight) {
          // Check if the menu exceeds the viewport to the bottom.
          if (window.innerHeight - bottom + menuHeight < window.innerHeight) {
            // Check if end alignment would work.
            optimalAnchor.alignment = 'end'
          }
        } else if (
          window.innerHeight - top + menuHeight / 2 - parentHeight / 2 >
          window.innerHeight
        ) {
          // Check if the menu exceeds the viewport to the top.
          if (top + menuHeight < window.innerHeight) {
            // Check if start alignment would work.
            optimalAnchor.alignment = 'start'
          }
        }
      }
    } else if (defaultAlignment === 'end') {
      if (window.innerHeight - bottom + menuHeight > window.innerHeight) {
        // Check if the menu overflows to the top.
        if (
          bottom + menuHeight / 2 - parentHeight / 2 < window.innerHeight &&
          window.innerHeight - top + menuHeight / 2 - parentHeight / 2 <
            window.innerHeight
        ) {
          // Check if center alignment works.
          optimalAnchor.alignment = 'center'
        } else if (top + menuHeight < window.innerHeight) {
          // Check if start alignment would work.
          optimalAnchor.alignment = 'start'
        }
      }
    }
  }

  return optimalAnchor
}

let IS_MENU_OPEN = false
export const isMenuOpen = () => IS_MENU_OPEN
export const setMenuVisibility = (visibility: boolean) => (IS_MENU_OPEN = visibility)
