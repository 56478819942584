import { useEffect } from 'react'

import { getBrandName } from '../helpers/localizeHelper'

import { error } from 'happitu/src/helpers/loggerHelper'

export default function useDataFetch(action: () => Promise<any>) {
  useEffect(() => {
    action().catch((e) => {
      error(`There was a problem initializing ${getBrandName()}.`, e)
      throw new Error(`There was a problem initializing ${getBrandName()}.`)
    })
  }, [])
}
