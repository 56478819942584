import apiRequest from './request'
import { handleReject, handleSuccess } from './responseHandler'

export const getContactDetailTypesRequest = () =>
  new Promise((resolve, reject) =>
    apiRequest.get('/contact-details')
      .then(r => resolve(handleSuccess(r)))
      .catch(r => reject(handleReject(r)))
  )

export const createContactDetailTypesRequest = (contactDetail: Partial<ContactDetailTypeRecord>) =>
  new Promise((resolve, reject) =>
    apiRequest.post('/contact-details')
      .send(contactDetail)
      .then(r => resolve(handleSuccess(r)))
      .catch(r => reject(handleReject(r)))
  )
