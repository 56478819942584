import {
  getElementValue,
  isElementValueEmpty,
} from 'happitu/src/helpers/ticket/interactionHelper'
import { elementPresets } from 'happitu/src/reducers/workflowElementsReducer'

export function validateField(values: string[], element?: WorkflowElementRecord) {
  if (!element) return []
  const value = getElementValue(element.component, { values })
  const elementPreset = elementPresets[element.component]
  // Does the element have validators?
  if (!elementPreset.validators) return []
  // Does the field have a value but isn't required?
  // Important when a field is not required but needs to validate the format of the value.
  if (!element.attributes.required && isElementValueEmpty(value)) return []

  return Object.keys(elementPreset.validators).reduce((errs, key) => {
    if (key === 'required' && !element.attributes.required) return errs
    let message = elementPreset.validators ? elementPreset.validators[key](value) : ''
    // Newer validators will return another function. This is to take into account
    // configuration options on the workflow element.
    if (typeof message === 'function') {
      message = message(element)
    }

    return message ? [...errs, message] : errs
  }, [])
}

function getFieldValue(component: WorkflowElementTypes, field: InteractionStepField) {
  switch (component) {
    case 'AttachFileField':
      return field.fileIds || []
    default:
      return field.values
  }
}

export default function validateWorkflowStep(
  fields: InteractionStepField[],
  elements: StoreInterface<WorkflowElementRecord>,
  validationAttempts?: number,
) {
  let isValid = true

  const validatedFields = fields.map((field) => {
    const element = elements.findById<WorkflowElementRecord>(field.elementId)
    if (!element) {
      throw new Error(
        `${field.elementId} is not in the local state. Something is seriously wrong :\\`,
      )
    }

    const errors = validateField(getFieldValue(element.component, field), element)
    if (errors.length > 0) {
      isValid = false
    }

    return {
      ...field,
      _error: errors,
    }
  })

  return {
    fields: validatedFields,
    isValid,
    validationAttempts: (validationAttempts || 0) + 1,
  }
}
