import { pick } from 'lodash'
import * as React from 'react'
import shallowEqual from 'shallowequal'

import AddressLine from '../AddressLine'

import {
  FieldFigure,
  FieldWrapper,
  Label,
} from 'happitu/src/components/Fields/FieldUtils'
import PostalCodeLookup from 'happitu/src/components/Fields/PostalCodeLookup'
import {
  addressKeyToIndex,
  getAddressIndexFromKey,
  getInitialAddressFieldValues,
  isLineThreeVisible,
} from 'happitu/src/helpers/addressHelper'
import { AddressWorkflowElementDisplayProps } from 'happitu/src/types/workflowElements'

interface StreetAddress {
  line1: string
  line2: string
  line3: string
}

interface AddressFields extends StreetAddress {
  city: string
  country: string
  postalCode: string
  state: string
}

type AddressIndex = keyof typeof addressKeyToIndex

export default function AddressField(props: AddressWorkflowElementDisplayProps) {
  const { hasStreetAddress, defaultValue, required, label } = props.attributes
  const showError = props.forceErrors && props.errors.length > 0
  const firstError = props.errors[0] as string

  // Clear any default values left over from the workflow editor if "Include Street Address" is unchecked.
  const initialAddressFields = getInitialAddressFieldValues(
    props.value || defaultValue,
    hasStreetAddress,
  )

  const city = initialAddressFields[getAddressIndexFromKey('city')]
  const country = initialAddressFields[getAddressIndexFromKey('country')]
  const postalCode = initialAddressFields[getAddressIndexFromKey('postalCode')]
  const state = initialAddressFields[getAddressIndexFromKey('state')]

  const [autoFocusLine3, setAutoFocusLine3] = React.useState(false)

  const [addressFields, setAddressFields] = React.useState<ReadonlyArray<string>>(
    initialAddressFields,
  )
  const [prevFields, setPrevFields] = React.useState<ReadonlyArray<string>>(addressFields)

  // Make sure each field receives the trimmed value on blur.
  React.useEffect(() => {
    if (!shallowEqual(prevFields, initialAddressFields)) {
      setAddressFields(initialAddressFields)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevFields])

  // Update state on props change (needed to handle recursive steps).
  React.useEffect(() => {
    setAddressFields(initialAddressFields)
  }, [props.value])

  function handlePostalChange(postalChange: PostalCode) {
    // Strip out id before making updates.
    const postalUpdates = pick(postalChange, ['postalCode', 'city', 'state', 'country'])
    handleBlur(postalUpdates)
  }

  const handleLineBlur = (key: AddressIndex, value: string) => {
    const lineUpdate = { [key]: value }
    handleBlur(lineUpdate)
    // Reset autofocus for the line 3 input.
    if (key !== 'line2') {
      setAutoFocusLine3(false)
    }
  }

  const handleBlur = (updates: Partial<AddressFields>) => {
    const emptyAddress = new Array(8).fill('')
    const nextAddressFields = Object.keys(updates).reduce(
      (fields, updateKey: keyof AddressFields) => {
        const key = getAddressIndexFromKey(updateKey)
        if (typeof key === 'number') {
          fields[key] = updates[updateKey] as string
        }

        return fields
      },
      emptyAddress.map((a, index) => addressFields[index] || a),
    )

    setAddressFields(nextAddressFields)
    props.onBlur(nextAddressFields, props.id)
    setPrevFields(nextAddressFields)
  }

  const renderAddressFields = () => {
    if (!hasStreetAddress) return

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Tab' && !e.shiftKey) {
        setAutoFocusLine3(true)
      }
    }

    return (
      <div>
        <AddressLine
          attribute="line1"
          placeholder="Street Address"
          onBlur={handleLineBlur}
          defaultValue={addressFields[getAddressIndexFromKey('line1')]}
          width="400px"
        />
        <AddressLine
          attribute="line2"
          placeholder="Apt / Ste"
          onBlur={handleLineBlur}
          onKeyDown={handleKeyDown}
          defaultValue={addressFields[getAddressIndexFromKey('line2')]}
          width="400px"
        />
        {isLineThreeVisible(addressFields) && (
          <AddressLine
            attribute="line3"
            autoFocus={autoFocusLine3}
            placeholder="Line 3"
            onBlur={handleLineBlur}
            defaultValue={addressFields[getAddressIndexFromKey('line3')]}
            width="400px"
          />
        )}
      </div>
    )
  }

  return (
    <FieldWrapper invalid={showError}>
      <Label required={required} value={label} />
      <br />
      {renderAddressFields()}
      <PostalCodeLookup
        postalCode={postalCode}
        city={city}
        state={state}
        country={country}
        onChange={handlePostalChange}
      />
      {showError && <FieldFigure value={firstError} />}
    </FieldWrapper>
  )
}
