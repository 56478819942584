import PropTypes from 'prop-types'
import React from 'react'

import styles from './FloatingButton.scss'

const FloatingButton = ({ className, children, component, value, ...props }) => {
  const classes = [styles.button, className].join(' ')
  return React.createElement(component, { className: classes, value, ...props }, children)
}

FloatingButton.propTypes = {
  children: PropTypes.any,
  value: PropTypes.any,
  className: PropTypes.string,
}

FloatingButton.defaultProps = {
  className: '',
  component: 'button',
  tabIndex: -1,
}

export default FloatingButton
