import apiRequest from './request'
import { handleReject, handleSuccess } from './responseHandler'

import { HelpTopicRecord, HelpTopicPageRecord } from 'happitu/src/types/models/helpTopics'

interface HelpTopicResponse {
  helpTopics: HelpTopicRecord[]
  helpTopicPages: HelpTopicPageRecord[]
}

export const updateHelpTopicPageRequest = (
  id: ID,
  helpTopicPage: Partial<HelpTopicPageRecord>,
) => {
  return new Promise<HelpTopicResponse>((resolve, reject) =>
    apiRequest
      .put(`/help-topic-pages/${id}`)
      .send(helpTopicPage)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )
}

export const deleteHelpTopicPageRequest = (id: ID) =>
  new Promise<HelpTopicResponse>((resolve, reject) =>
    apiRequest
      .delete(`/help-topic-pages/${id}`)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )

export const newHelpTopicPageRequest = (helpTopicPage: Partial<HelpTopicPageRecord>) =>
  new Promise<HelpTopicResponse>((resolve, reject) =>
    apiRequest
      .post('/help-topic-pages')
      .send(helpTopicPage)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )
