/* eslint-disable complexity */
export const lookupMimeType = (filename: string): string => {
  const extensionIndex = filename.lastIndexOf('.')
  if (extensionIndex === -1 || extensionIndex === filename.length + 1) {
    return 'application/octet-stream'
  }
  switch (filename.substr(extensionIndex + 1).toLowerCase()) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg'
    case 'png':
      return 'image/png'
    case 'gif':
      return 'image/png'
    case 'pdf':
      return 'application/pdf'
    case 'ppt':
      return 'application/vnd.ms-powerpoint'
    case 'pptx':
      return 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    case 'doc':
      return 'application/msword'
    case 'docx':
      return 'application/application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    case 'html':
      return 'text/html'
    case 'txt':
      return 'text/plain'
    case 'zip':
      return 'application/zip'
    case '7z':
      return 'application/application/x-7z-compressed'
    case 'mp3':
      return 'audio/mp3'
    case 'mpeg':
      return 'video/mpeg'
    case 'wav':
      return 'audio/wav'
    case 'aac':
      return 'audio/aac'
    default:
      return 'application/octet-stream'
  }
}
