import {
  GeneratedFieldType,
  GeneratedCollection,
} from 'happitu/src/helpers/generatedMetadata'
import { CustomMetricStore } from 'happitu/src/models/customMetric'
import Tree, {
  RecordFieldReference,
  TreeValue,
  isRecordFieldReference,
  ConstantNumber,
  isTreeValue,
  FunctionName,
  NodeValue,
  Operation,
  ResultType,
  NullValue,
  isTree,
} from 'happitu/src/models/expressionTree'

export interface MetricOption {
  label: string
  type: GeneratedFieldType
  value: string
}

export interface MetricGroup {
  label: string
  fields: MetricOption[]
}

export type CompiledFieldList = Array<MetricOption | MetricGroup>

export const getRefValue = (recordName: string, fieldName: string) =>
  `${recordName}:${fieldName}`

type ParseValue =
  | {
      recordName: string
      fieldName: string
    }
  | { expressionTreeId: ID }
export const parseRefValue = (fieldValue: string): ParseValue => {
  const [recordName, fieldName] = fieldValue.split('.')
  if (!fieldName) return { expressionTreeId: recordName }
  return { recordName, fieldName }
}

// eslint-disable-next-line complexity
export const findField = (
  fields: Array<MetricGroup | MetricOption>,
  value?: RecordFieldReference | TreeValue | ConstantNumber | Tree,
): MetricOption | null => {
  if (value && (isRecordFieldReference(value) || isTreeValue(value) || isTree(value))) {
    for (const field of fields) {
      if (isMetricOption(field)) {
        const parsedValue = JSON.parse(field.value)
        if (
          (isRecordFieldReference(value) &&
            parsedValue.fieldName === value.fieldName &&
            parsedValue.recordName === value.recordName &&
            parsedValue.distinct === value.distinct) ||
          (isTreeValue(value) && parsedValue.treeId === value.treeId) ||
          (isTree(value) &&
            parsedValue.nodes &&
            parsedValue.nodes[0].operation === value.nodes[0].operation)
        )
          return field
      } else {
        const result = findField(field.fields, value)
        if (result) return result
      }
    }
  }

  return null
}

export const isMetricOption = (
  option: MetricGroup | MetricOption,
): option is MetricOption => 'value' in option

export const buildInteractionFields = (
  existingFields: Array<MetricGroup | MetricOption> = [],
): Array<MetricGroup | MetricOption> => [
  ...existingFields,
  {
    label: '# Interactions',
    type: GeneratedFieldType.Id,
    value: JSON.stringify(
      new RecordFieldReference(GeneratedCollection.TicketInteractions, 'ID'),
    ),
  },
  {
    label: '# Interactions Cases',
    type: GeneratedFieldType.Id,
    value: JSON.stringify(
      new RecordFieldReference(GeneratedCollection.TicketInteractions, 'ID', true),
    ),
  },
  {
    label: '# Interactions w/ Escalation',
    type: GeneratedFieldType.PartialNumber,
    value: JSON.stringify(
      new Tree()
        .createNode({
          operation: Operation.NotEquals,
          values: [
            new RecordFieldReference(GeneratedCollection.Analytics, 'PreviousWorkflow'),
            NullValue,
          ],
        })
        .createNode({
          function: {
            name: FunctionName.CountIfTrue,
            resultType: ResultType.NumberResult,
          },
          operation: Operation.FunctionCall,
          values: [
            new RecordFieldReference(GeneratedCollection.TicketInteractions, 'ID', true),
            new NodeValue(0),
          ],
        })
        .setRoot(1),
    ),
  },
  {
    label: '# Interactions w/o Escalation',
    type: GeneratedFieldType.PartialNumber,
    value: JSON.stringify(
      new Tree()
        .createNode({
          operation: Operation.Equals,
          values: [
            new RecordFieldReference(GeneratedCollection.Analytics, 'PreviousWorkflow'),
            NullValue,
          ],
        })
        .createNode({
          function: {
            name: FunctionName.CountIfTrue,
            resultType: ResultType.NumberResult,
          },
          operation: Operation.FunctionCall,
          values: [
            new RecordFieldReference(GeneratedCollection.TicketInteractions, 'ID', true),
            new NodeValue(0),
          ],
        })
        .setRoot(1),
    ),
  },
]

export const buildInteractionStatFields = (
  existingFields: Array<MetricGroup | MetricOption> = [],
): Array<MetricGroup | MetricOption> => {
  return [
    ...existingFields,
    {
      label: 'Phone Stats',
      fields: [
        {
          label: 'Abandoned Time',
          type: GeneratedFieldType.Duration,
          value: JSON.stringify(
            new RecordFieldReference(
              GeneratedCollection.InteractionStats,
              'AbandonedSeconds',
            ),
          ),
        },
        {
          label: '# Abandons',
          type: GeneratedFieldType.NullableBool,
          value: JSON.stringify(
            new RecordFieldReference(GeneratedCollection.InteractionStats, 'Abandoned'),
          ),
        },
        {
          label: 'Talk Time',
          type: GeneratedFieldType.Duration,
          value: JSON.stringify(
            new RecordFieldReference(
              GeneratedCollection.InteractionStats,
              'Duration', //TODO: use AgentSeconds
            ),
          ),
        },
        {
          label: 'Hold Count',
          type: GeneratedFieldType.Number,
          value: JSON.stringify(
            new RecordFieldReference(GeneratedCollection.InteractionStats, 'HoldCount'),
          ),
        },
        {
          label: 'Hold Time',
          type: GeneratedFieldType.Duration,
          value: JSON.stringify(
            new RecordFieldReference(GeneratedCollection.InteractionStats, 'HoldSeconds'),
          ),
        },
        {
          label: 'Total Duration',
          type: GeneratedFieldType.Duration,
          value: JSON.stringify(
            new RecordFieldReference(
              GeneratedCollection.InteractionStats,
              'TotalDuration',
            ),
          ),
        },
        {
          label: 'Speed of Answer',
          type: GeneratedFieldType.Duration,
          value: JSON.stringify(
            new RecordFieldReference(
              GeneratedCollection.InteractionStats,
              'QueuedDuration',
            ),
          ),
        },
      ],
    },
  ]
}

export const buildCustomMetricFields = (customMetrics?: CustomMetricStore) => (
  existingFields: Array<MetricGroup | MetricOption> = [],
): Array<MetricGroup | MetricOption> => {
  const index = existingFields.length
  return customMetrics
    ? customMetrics.reduce(
        (acc, customMetric) => {
          const customMetricFields = acc[index] as MetricGroup
          acc[index] = {
            ...customMetricFields,
            fields: [
              ...customMetricFields.fields,
              {
                label: customMetric.name,
                value: JSON.stringify(new TreeValue(customMetric.expressionTreeId)),
                type: GeneratedFieldType.Number,
              },
            ],
          }
          return acc
        },
        [
          ...existingFields,
          {
            label: 'Custom Metrics',
            fields: [],
          },
        ],
      )
    : existingFields
}

export const getAliasPrefix = (aggregate: FunctionName) => {
  if (aggregate === FunctionName.Average) return 'Avg'
  return ''
}
