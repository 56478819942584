import React, { Suspense } from 'react'
import { StaticRange } from 'react-date-range'
import 'react-date-range/dist/styles.css'

import { ClearButton } from '../DateSelect/DateSelect.styles'
import DateSelectWrapper from '../DateSelect/DateSelectWrapper'
import useDateSelect from '../DateSelect/useDateSelect'

import { DateRange } from './DateRangeSelect.types'

import ErrorBoundary from 'happitu/src/components/ErrorBoundary'
import { Menu } from 'happitu/src/components/menus'
import { humanizeRange } from 'happitu/src/helpers/dateHelpers'
import {
  BEGINNING_OF_TIME,
  defaultStaticRanges,
  TODAY,
} from 'happitu/src/helpers/dateSelectHelpers'

const DateRangePicker = React.lazy(
  () => import('happitu/src/components/date-select/react-date-picker'),
)

interface Props {
  autoFocus?: boolean
  disableClear?: boolean
  label?: string
  maxDate?: Date | null
  minDate?: Date | null
  months?: number
  onSelect: (value: DateRange | null) => void
  placeholder?: string
  required?: boolean
  staticRanges?: StaticRange[] | null
  style?: React.CSSProperties
  value: DateRange
}

const DateRangeSelect = ({
  maxDate = TODAY,
  minDate = BEGINNING_OF_TIME,
  months = 2,
  staticRanges = defaultStaticRanges,
  ...props
}: Props) => {
  const { ref, onOpen, onClose, isVisible } = useDateSelect()
  const isPlaceholder = !props.value.startDate && !props.value.endDate

  // If the range is null, show today's date as a preview.
  const displayRanges =
    !!props.value.startDate || !!props.value.endDate
      ? [
          {
            ...props.value,
            key: 'selection',
          },
        ]
      : [
          {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
          },
        ]

  const handleSelect = (range: { selection: DateRange }) => {
    props.onSelect(range.selection)
  }

  const handleClear = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    props.onSelect(null)
  }

  React.useEffect(() => {
    if (props.autoFocus) {
      ref.current?.focus()
    }
  }, [props.autoFocus])

  return (
    <DateSelectWrapper
      {...{ ref, onOpen, onClose }}
      label={props.label}
      required={props.required}
      hasValue={!!props.value.startDate || !!props.placeholder}
      isPlaceholder={isPlaceholder}
    >
      <span style={{ marginRight: 'auto' }}>
        {humanizeRange({ start: props.value.startDate, end: props.value.endDate }) ||
          props.placeholder}
      </span>
      {!isPlaceholder && !props.disableClear && (
        <ClearButton
          onClick={handleClear}
          size="small"
          title="Clear the selected date range"
          type="close"
        />
      )}
      <Menu isVisible={isVisible} style={{ minWidth: 'max-content' }}>
        <ErrorBoundary>
          <Suspense fallback={<div />}>
            <DateRangePicker
              direction="horizontal"
              endDatePlaceholder="Select End Date"
              maxDate={maxDate || undefined}
              minDate={minDate || undefined}
              months={months}
              onChange={handleSelect}
              rangeColors={['#248fc6']}
              // NOTE: Currently, a null range shows as a continuous range (beginning of time until the end). We'd prefer to not show anything at all. The discussion is being tracked here: https://github.com/Adphorus/react-date-range/issues/360.
              ranges={displayRanges}
              // showDateDisplay={false} // Use this to turn off inputs above the calendar. They can be editable, so we may keep them.
              startDatePlaceholder="Select Start Date"
              staticRanges={staticRanges || []} // NOTE: Currently passing empty array and letting the column collapse via css until this issue has a PR: https://github.com/Adphorus/react-date-range/issues/362.
            />
          </Suspense>
        </ErrorBoundary>
      </Menu>
    </DateSelectWrapper>
  )
}

export default DateRangeSelect
