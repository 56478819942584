import { get } from 'lodash'

import { FORM_FIELDS } from 'happitu/src/components/WorkflowElements/DisplayElements'

// eslint-disable-next-line complexity
export const getElementValue = (
  component: WorkflowElementTypes,
  field: Partial<InteractionStepField>,
) => {
  switch (component) {
    case 'AddressField':
    case 'CheckboxArray':
    case 'DateRangePicker':
    case 'RadioButtonArray':
      return get(field, 'values', '')
    case 'DatePicker':
    case 'CallbackScheduler':
    case 'LocationSelect':
    case 'NumberField':
    case 'SSNField':
    case 'MultiLineTextField':
    case 'TelephoneField':
      return get(field, 'values[0]', '')
    case 'EmailField':
    case 'AttachFileField':
    case 'TextField':
      return get(field, 'values', [])
    default:
      return ''
  }
}

export const getStepIndex = (lastInteraction?: TicketInteractionRecord) => {
  return (
    (lastInteraction && lastInteraction.workflowSteps.length
      ? lastInteraction.workflowSteps.length - 1
      : 0) + ''
  )
}

export function getFieldError(field?: InteractionStepField) {
  return field && field !== null && field._error ? field._error : []
}

export function isElementValueEmpty(value: string[] | string) {
  if (Array.isArray(value)) {
    return value.filter((v) => v !== '').length === 0
  }
  return value === ''
}

export function isEmptyField(
  values: (string | undefined)[],
  fileIds: (string | undefined)[] | null,
) {
  return (
    (!!values && values.some((value) => value !== undefined && value !== '')) ||
    (!!fileIds && fileIds.some((fileId) => fileId !== undefined && fileId !== ''))
  )
}
