import React from 'react'
import { Transforms, Editor, Element } from 'slate'
import { RenderElementProps, useSlate } from 'slate-react'

import LinkTooltip from './LinkTooltip'

import { setLink } from 'happitu/src/helpers/editor/formattingHelper'
import { ElementType, LinkElement } from 'happitu/src/types/slate-types'

interface Props extends RenderElementProps {
  element: LinkElement
}

const EditableLink = ({ attributes, ...props }: Props) => {
  const element = props.element
  const children = props.children
  const editor = useSlate()
  const [openDialog, setOpenDialog] = React.useState(element.url === '')

  const handleClose = () => setOpenDialog(false)

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    !openDialog && setOpenDialog(true)
  }

  const handleSubmit = (value?: string) => {
    if (value) {
      const [node] = Editor.nodes(editor, {
        at: [],
        match: (n) =>
          Element.isElement(n) &&
          n.type === ElementType.Link &&
          n.nodeId === element.nodeId,
      })
      if (node) {
        Transforms.select(editor, node[1])
        setLink(editor, value, element.nodeId)
      }
    } else {
      Transforms.unwrapNodes(editor, {
        at: [],
        match: (n) =>
          Element.isElement(n) &&
          n.type === ElementType.Link &&
          n.nodeId === element.nodeId,
      })
    }
  }

  return (
    <div style={{ display: 'inline', position: 'relative' }}>
      <LinkTooltip
        defaultValue={element.url}
        direction="above"
        isVisible={openDialog}
        nodeId={element.nodeId}
        onClose={handleClose}
        onSubmit={handleSubmit}
      />
      <a
        {...attributes}
        href={element.url}
        onClick={handleClick}
        rel="noreferrer nofollow noopener"
        // style={{ cursor: 'pointer' }}
      >
        {children}
      </a>
    </div>
  )
}

export default EditableLink
