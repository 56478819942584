import React, { ReactNode, MouseEventHandler, forwardRef } from 'react'

import Icon from '../Icon'
import { Button, ButtonProps } from '../button'
import Flex from '../flex'

import { useMenuButton } from './utils'

interface Props extends ButtonProps {
  children?: ReactNode
  onClick?: MouseEventHandler
  onMouseDown?: MouseEventHandler
  icon?: string
}

const MenuButton = forwardRef<HTMLButtonElement, Props>(
  ({ children, onClick, ...props }, ref) => {
    const [isVisible, click] = useMenuButton(onClick)
    return (
      <Button {...props} onClick={click} ref={ref}>
        <Flex gap=".5">
          <span>{children}</span>
          <Icon type={isVisible ? 'caret-up' : 'caret-down'} />
        </Flex>
      </Button>
    )
  },
)

export default MenuButton
