import TextareaAutosize from 'react-textarea-autosize'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'

import { Button } from 'happitu/src/components/button'
import getStyle from 'happitu/src/getStyle'
import { font } from 'theme'

export const Label = styled.label`
  border-top: 1px solid ${getStyle('border-color')};
  display: grid;
  align-items: stretch;
  grid-gap: 1em;
  grid-template-rows: min-content 1fr min-content;
`

export const Heading = styled.h3`
  color: ${getStyle('text-sub-color')};
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 1em;
  font-weight: ${font('weight', 'medium')};

  > span {
    float: right;
  }
`

export const Textarea = styled(TextareaAutosize)`
  border: 0;
  box-shadow: none;
  flex-grow: 1;
  padding: 0;
  resize: none;
  max-height: calc(50vh - 3em);

  &::placeholder {
    color: ${getStyle('text-light-color')};
  }

  &:focus {
    box-shadow: none;
  }
`

export const ClearButton = styled(Button)<{ visible: boolean }>`
  align-self: flex-start;
  margin-bottom: 1em;
  opacity: ${ifProp('visible', 1, 0)};
  transition: opacity 160ms;
`
