import * as React from 'react'

import { Wrapper, Label, DeselectButton } from './FuzzySelectOption.styles'

type FuzzySelectValue = string | number

interface Props extends FuzzySelectOption {
  onMouseEnter: (value: FuzzySelectValue) => void
  onClick: (value: FuzzySelectValue, name: string) => void
  selected?: boolean
  deselectable?: boolean
  hovering?: boolean // this.state.active === option.value
  innerRef?: React.RefObject<HTMLLIElement>
}

function areEqual (prevProps: Props, nextProps: Props) {
  return (
    prevProps.selected === nextProps.selected &&
    prevProps.hovering === nextProps.hovering
  )
}

function FuzzySelectOption (props: Props) {
  const {
    deselectable,
    name,
    selected,
    value,
    hovering,
    children,
  } = props

  const handleMouseEnter = React.useCallback(() => {
    props.onMouseEnter(value)
  }, [value])

  const handleClick = React.useCallback(() => {
    if ((deselectable && selected) || !selected) {
      props.onClick(value, name)
    }
  }, [deselectable, selected, value])

  return (
    <Wrapper
      hover={ hovering }
      active={ selected }
      ref={ props.innerRef }
      key={ value }
      onMouseEnter={ handleMouseEnter }
      onClick={ handleClick }
    >
      <Label>{ children || name }</Label>
      { selected && deselectable ?
        <DeselectButton
          type="close"
          role="deselect"
        /> : null }
    </Wrapper>
  )
}

export default React.memo(FuzzySelectOption, areEqual)
