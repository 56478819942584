import * as React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import styled from 'styled-components'

import TextField from './TextField'

import { DisplayWorkflowElementBaseProps } from 'happitu/src/types/workflowElements'

const StyledTextarea = styled(TextareaAutosize)`
  min-height: 8em;
`

export default function MultiLineTextField(props: DisplayWorkflowElementBaseProps) {
  return <TextField {...props} as={StyledTextarea} />
}
