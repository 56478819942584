import { Transforms, Editor, Element } from 'slate'
import { v4 as uuid } from 'uuid'

import uploadFile from './uploadFile'

import { error } from 'happitu/src/helpers/loggerHelper'
import { ElementType, ImageElement } from 'happitu/src/types/slate-types'

export const getImagesFromFiles = (files: File[]) =>
  files.filter((file) => /^image/.test(file.type))

// TODO: Handle offline mode, and save any files to local storage if the upload fails and point to it from the node.
export const insertImagesFromFiles = (
  editor: Editor,
  imageFiles: File[],
  collection: string,
  id: ID,
) => {
  imageFiles.forEach((file) => {
    // Get the encoded url.
    const previewUrl = URL.createObjectURL(file)
    const currentBlock = Editor.above(editor, {
      match: (n) => Editor.isBlock(editor, n),
    })
    // Get a unique ID for each new image node.
    const nodeId = uuid()

    const lastNode = Editor.last(editor, [])

    const newAt = !editor.selection ? [lastNode[1][0]] : undefined // Insert just before last node if editor isn't focused.

    // Insert an image node with the previewUrl.
    const image: ImageElement = {
      type: ElementType.Image,
      fileId: null,
      previewUrl,
      nodeId,
      children: [{ text: '' }],
    }
    Transforms.insertNodes(editor, image, { at: newAt, mode: 'highest' })
    newAt && Transforms.select(editor, newAt)

    // Only replace the node if it's an empty paragraph. Otherwise, just insert the image.
    if (
      currentBlock &&
      Element.isElement(currentBlock[0]) &&
      currentBlock[0].type === ElementType.Paragraph &&
      Editor.isEmpty(editor, currentBlock[0])
    ) {
      Transforms.removeNodes(editor, { at: currentBlock[1] })
    }

    // Update the upload progress.
    const handleUploadProgress = (p: number) => {
      Transforms.setNodes(
        editor,
        { uploadProgress: p },
        {
          at: [],
          match: (n) =>
            Element.isElement(n) && n.type === ElementType.Image && n.nodeId === nodeId,
        },
      )
    }

    // Get the uploaded file.
    uploadFile(collection, id, [file], handleUploadProgress)
      .then((fileIds) => {
        const fileId = fileIds[0]
        // Update the image node to include the fileId.
        Transforms.setNodes(
          editor,
          { fileId },
          {
            at: [],
            match: (n) =>
              Element.isElement(n) && n.type === ElementType.Image && n.nodeId === nodeId,
          },
        )
      })
      .catch(error)
      .finally(() => {
        // Clean up previewUrl on image upload.
        Transforms.unsetNodes(editor, ['previewUrl', 'uploadProgress'], {
          at: [],
          match: (n) =>
            Element.isElement(n) && n.type === ElementType.Image && n.nodeId === nodeId,
        })
      })
  })
}
