import React, { ComponentProps } from 'react'
import styled from 'styled-components'

import { getBrandName, getSupportEmail } from '../helpers/localizeHelper'

import Icon from 'happitu/src/components/Icon'
import { Link } from 'happitu/src/components/Navigation'
import Box from 'happitu/src/components/box'
import getStyle from 'happitu/src/getStyle'

const RouterErrorMessage = (props: ComponentProps<'div'>) => {
  return (
    <div {...props}>
      <Box padding="2" style={{ maxWidth: 650 }} gap="2" justify="center">
        <img src="https://assets.happitu.com/public/hiro-hiding.svg" />
        <Box>
          <p>
            <strong>Well this is embarrassing.</strong>
          </p>
          <p>
            Our developers have been notified of what went wrong and will be looking into
            it.
          </p>
          <p>
            Fortunately, refreshing the page should get you on your feet. If it doesn't,
            please contact us at{' '}
            <a
              href={`mailto:${getSupportEmail()}?subject=${getBrandName()} crashed (${
                window.location.href
              })`}
            >
              {getSupportEmail()}
            </a>
            .
          </p>
          <nav>
            <Link to="app.tickets">
              <Icon type="home" /> Return Home
            </Link>
          </nav>
        </Box>
      </Box>
    </div>
  )
}

export default styled(RouterErrorMessage)`
  background: ${getStyle('app-alt-background')};
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  line-height: 2rem;
  color: ${getStyle('error-page-color')};
  text-align: center;

  a {
    color: ${getStyle('error-page-color')};
  }
`
