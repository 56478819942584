import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import { isSelectedOption } from '../List.helpers'
import SelectableContext from '../ListContainers/SelectableList.context'

import { OptionProps, OptionValue } from './Option.types'

import Icon from 'happitu/src/components/Icon'
import { FlexCol, FlexRow } from 'happitu/src/components/_DEPRECATED/Flex'
import getStyle from 'happitu/src/getStyle'
import { icomoon } from 'happitu/src/themes/style-utils'
import { metric, font } from 'theme'

const isEqual = (prevProps: OptionProps, nextProps: OptionProps) =>
  prevProps.value === nextProps.value &&
  prevProps.label === nextProps.label &&
  prevProps.selected === nextProps.selected &&
  prevProps.focused === nextProps.focused

const OptionDisplay = React.memo(({ onSelect, onHover, ...props }: OptionProps) => {
  return (
    <label
      style={props.style}
      className={props.className}
      onMouseOver={() => onHover && onHover(props.value)}
      title={props.title}
    >
      <input
        role="listitem"
        type="checkbox"
        aria-selected={props.selected}
        aria-label={props.label || ''}
        disabled={props.disabled}
        checked={props.selected}
        value={props.value}
        onChange={() => onSelect && onSelect(props.value)}
      />
      <FlexRow>
        {!!props.icon && <OptionIcon type={props.icon} />}
        <FlexCol grow="1">
          {props.description ? (
            <>
              <strong>{props.children}</strong>
              <span>{props.description}</span>
            </>
          ) : (
            props.children
          )}
        </FlexCol>
        {!!props.highlight && (
          <Highlight>
            <Icon type="star-empty" />
          </Highlight>
        )}
      </FlexRow>
    </label>
  )
}, isEqual)

const OptionIcon = styled(Icon)`
  margin-right: 0.5em;
`

const Highlight = styled.div`
  border-radius: 4px;
  color: ${getStyle('highlight-default-color')};
  font-size: 11px;
  margin: 0;
  padding: 5px 8px;
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
`

const focusedBackground = getStyle('selectOption-focused-background')
const selectedBackground = getStyle('selectOption-selected-background')
const checkboxBackground = getStyle('button-primary-default-background')

// The BaseOption can be used as a layout component for visual consistency in cases where we don't want extra behavior.
export const BaseOption = styled(OptionDisplay)`
  align-items: center;
  border-radius: ${metric('largeBorderRadius')};
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  overflow: hidden;
  position: relative;
  text-align: left;

  input {
    height: 0;
    left: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 0;
  }

  ${ifProp(
    'checkbox',
    css`
      padding: 0.5rem;
      :before {
        ${icomoon}
        color: ${getStyle('button-primary-default-color')};
        background: ${ifProp('selected', checkboxBackground)};
        border-radius: ${metric('largeBorderRadius')};
        border: 1px solid
          ${ifProp(
            'selected',
            getStyle('input-focus-borderColor'),
            getStyle('input-default-borderColor'),
          )};
        content: '${ifProp('selected', '\\e90e', '')}';
        display: inline-block;
        height: 20px;
        line-height: 1.5;
        margin-right: 0.5em;
        text-align: center;
        width: 20px;
        flex-shrink: 0;
      }
      :focus:before,
      :hover:before {
        background: ${getStyle('app-background')};
        background: ${ifProp('selected', checkboxBackground)};
        border-color: ${ifProp('selected', checkboxBackground)};
      }

      ${ifProp(
        'focused',
        css`
          background: ${focusedBackground};
        `,
      )}
    `,
    css`
      margin-bottom: 2px;
      padding: 0.75rem;

      ${ifProp(
        'selected',
        css`
          background: ${getStyle('selectOption-selected-background')};
          color: ${getStyle('selectOption-selected-color')};

          ${Highlight} {
            color: ${getStyle('selectOption-selected-color')};
          }
        `,
        css`
          background: transparent;
          color: ${getStyle('text-default-color')};
        `,
      )}

      ${ifProp(
        'focused',
        css`
          background: ${ifProp('selected', selectedBackground, focusedBackground)};
        `,
      )}
    `,
  )};

  ${ifProp(
    'warn',
    css`
      color: ${getStyle('text-warning-color')};
      font-weight: ${font('weight', 'medium')};
    `,
  )}

  ${ifProp(
    'disabled',
    css`
      color: ${getStyle('text-inactive-color')};
      cursor: not-allowed;
    `,
  )}
`

// This Option component is to be used with the Select component et al.
const Option = (props: OptionProps) => {
  const context = useContext(SelectableContext)

  // Give the Option's onSelect handler priority.
  const onSelect = props.onSelect || context.onSelect

  // Give the Option's onHover handler priority.
  const onHover = props.onHover || context.onHover

  const handleSelect = (value: OptionValue) => {
    if (!!props.confirm) {
      window.confirm(props.confirm) && onSelect(value)
    } else {
      onSelect(value)
    }
  }

  return (
    <BaseOption
      {...props}
      value={props.value}
      checkbox={props.checkbox || context.multiple}
      focused={context.focusIndex === context.getIndexFromValue(props.value)}
      onSelect={handleSelect}
      onHover={() => onHover(props.value)}
      selected={props.selected || isSelectedOption(context.value, props.value)}
    >
      {props.children || props.label}
    </BaseOption>
  )
}

export default styled(Option)``
