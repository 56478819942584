import { isString } from './stringHelper'

import { getParams } from 'happitu/src/helpers/routeHelpers'
import { apiHost } from 'happitu/src/services'

const generatedFallbackSizeOrder = ['medium', 'small', 'large']

function getGeneratedSize(
  generatedFiles: FileUploadRecord['generatedFiles'],
  requestedSize = 'medium',
): string | void {
  if (generatedFiles[requestedSize]) return requestedSize
  for (const size of generatedFallbackSizeOrder) {
    if (generatedFiles[size]) return size
  }
}

export function getGeneratedFileUrl(
  id: ID,
  generatedFiles: FileUploadRecord['generatedFiles'],
  requestedSize?: 'small' | 'medium' | 'large',
): string {
  const generatedFileSize = getGeneratedSize(generatedFiles, requestedSize)
  return getFileUrl(id, {
    generatedFile: generatedFileSize,
  })
}

export function convertParamObjToString(params?: Record<string, string | void>): string {
  if (!params) return ''
  const stringParams = Object.keys(params)
    .filter((key) => params[key] !== '') // Filter out params with empty string value.
    .map((key) => {
      const param = params[key]
      return isString(param)
        ? encodeURIComponent(key) + '=' + encodeURIComponent(param)
        : ''
    })
    .filter((param) => param !== '')
    .join('&')
  return `&${stringParams}`
}

export function getFileUrl(id: ID, params?: Record<string, string | void>) {
  const { organizationId } = getParams()
  const paramString = convertParamObjToString({
    organizationId,
    redirect: 'true',
    ...params,
  })
  const url = `${apiHost()}/file-uploads/${id}`
  return `${url}?${paramString}`
}
