import classNames from 'classnames'
import React, { useState, useEffect, MouseEvent } from 'react'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import Button from '../button/button'

import getStyle from 'happitu/src/getStyle'

interface Props {
  className?: string
  confirmClassName?: string
  confirmValue?: string
  onClick: React.MouseEventHandler
  setConfirm?: (bool?: boolean, event?: React.MouseEvent) => void
  confirm?: boolean
  timeout?: boolean
  value?: string | React.ReactNode
  size?: string
  children?: any
}

const StyledButton = styled(Button)<{ confirm?: boolean }>`
  ${ifProp(
    'confirm',
    css`
      background: ${getStyle('button-warn-default-background')} !important;
      color: ${getStyle('button-warn-default-color')} !important;
      :active {
        background: ${getStyle('button-warn-active-background')} !important;
        color: ${getStyle('button-warn-active-color')} !important;
      }
    `,
  )}
`

const ConfirmButton = ({
  confirmClassName = '',
  confirmValue = 'Confirm',
  children = 'Remove',
  ...props
}: Props) => {
  const [confirm, setConfirm] = useState(props.confirm || false)
  const [timeoutHandler, setTimeoutHandler] = useState<number>()

  useEffect(() => {
    return () => clearTimeout(timeoutHandler)
  })

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation()

    if (confirm) {
      setConfirm(false)
      props.onClick(event)
    } else {
      setConfirm(true)
      props.setConfirm && props.setConfirm(true, event)
    }
  }

  const handleMouseOver = () => {
    clearTimeout(timeoutHandler)
  }

  const handleMouseout = (event: MouseEvent) => {
    event.persist()

    setTimeoutHandler(
      window.setTimeout(() => {
        setConfirm(false)
        props.setConfirm && props.setConfirm(false, event)
      }, 1000),
    )
  }

  const classes = classNames({ [confirmClassName]: confirm }, props.className)

  return (
    <StyledButton
      {...props}
      confirm={confirm}
      className={classes}
      onClick={handleClick}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseout}
    >
      {confirm ? confirmValue : children}
    </StyledButton>
  )
}

export default styled(ConfirmButton)``
