import apiRequest from './request'
import { handleReject, handleSuccess } from './responseHandler'

import { BaseRelayResponse } from 'happitu/src/actions/relay'

/**
 * Fetches all the tags!
 */
export const fetchTagsRequest = () =>
  new Promise((resolve, reject) =>
    apiRequest
      .get('/tags')
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )

/**
 * Fetches a tag by ID.
 */
export const fetchTagRequest = (tagId: ID) =>
  new Promise((resolve, reject) =>
    apiRequest
      .get(`/tags/${tagId}`)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )

interface CreateTagResponse extends BaseRelayResponse<''> {
  tags: TagRecord[]
}

/**
 * Handles create tag request.
 */
export const createTagRequest = (tag: Partial<TagRecord>) =>
  new Promise<CreateTagResponse>((resolve, reject) =>
    apiRequest
      .post('/tags')
      .send(tag)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )

/**
 * Handles update tag update request.
 */
export const updateTagRequest = (tag: TagRecord) =>
  new Promise((resolve, reject) =>
    apiRequest
      .put(`/tags/${tag.id}`)
      .send(tag)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )

/**
 * Handles delete tag request.
 */
export const deleteTagRequest = (tagId: ID, currentPassword: string) =>
  new Promise((resolve, reject) =>
    apiRequest
      .delete(`/tags/${tagId}`)
      .send({ currentPassword })
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )
