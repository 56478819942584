import React from 'react'

import { ButtonWrapper } from './FullscreenImagePreview.styles'

import Icon from 'happitu/src/components/Icon'

interface Props {
  isDisabled: boolean
  onClick: () => void
}

const PrevImgButton = ({ isDisabled, onClick }: Props) => {
  const title = 'View previous image'

  const handleClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation()
    if (!isDisabled) {
      onClick()
    }
  }

  return (
    <ButtonWrapper onClick={handleClick} title={title} isDisabled={isDisabled}>
      <Icon type="caret-left" size="medium" />
    </ButtonWrapper>
  )
}

export default PrevImgButton
