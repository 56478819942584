import Cookies from 'js-cookie'
import React from 'react'
import { actions } from 'redux-router5'

import router from '../../entries/app.router'

import NotFound from 'happitu/src/components/ErrorPages/NotFound'
import AppLoader from 'happitu/src/components/Loaders/AppLoader'
import { ORGANIZATION_ID_COOKIE } from 'happitu/src/constants/cookies'
import { getSession } from 'happitu/src/helpers/currentUserHelpers'
import { error } from 'happitu/src/helpers/loggerHelper'

function getPathWithOrg(organizationId: ID) {
  return '/org/' + organizationId + window.location.pathname
}

const redirectToOrganization = async (organizationId: ID) => {
  // Let's attempt to match an old route with a the new regime
  const matchedRoute = router.matchPath(getPathWithOrg(organizationId))
  if (matchedRoute) {
    window.store.dispatch(
      actions.navigateTo(
        matchedRoute.name,
        {
          ...matchedRoute.params,
        },
        {
          replace: true,
        },
      ),
    )
  } else {
    error(`RedirectToOrganization: unknown route: ${window.location.pathname}`)
    throw new Error(`RedirectToOrganization: unknown route: ${window.location.pathname}`)
  }
}

export default function RedirectToOrganization() {
  const [unknownRoute, setUnknownRoute] = React.useState(false)

  React.useEffect(() => {
    const session = getSession()
    const orgId = Cookies.get(ORGANIZATION_ID_COOKIE)
    if (session) {
      const { oui } = session
      const sortedOid = Object.keys(oui).sort()
      redirectToOrganization(sortedOid[0]).catch(() => {
        setUnknownRoute(true)
      })
    } else if (orgId && window.location.href !== `/org/${orgId}`) {
      window.location.href = `/org/${orgId}`
    } else {
      window.location.href = `/login`
    }
  }, [])

  return unknownRoute ? <NotFound /> : <AppLoader />
}
