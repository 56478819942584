import { css } from 'styled-components'
import { ifProp } from 'styled-tools'

const unit = /em/

const paddingProp = (key: keyof PaddingProps) => {
  return (props: PaddingProps) => {
    const prop = props[key]
    switch (typeof prop) {
      case 'number':
      case 'string':
        if (unit.test(`${prop}`)) return prop
        return `${prop}em`
      case 'boolean':
        return '0em'
      default:
        throw new Error('invalid padding type. must be a number, string, or boolean.')
    }
  }
}
export interface PaddingProps {
  padding?: number | string
  paddingY?: number | string
  paddingX?: number | string
  paddingTop?: number | string
  paddingRight?: number | string
  paddingBottom?: number | string
  paddingLeft?: number | string
}

export const paddingMixin = css<PaddingProps>`
  ${ifProp(
    'padding',
    css`
      padding: ${paddingProp('padding')};
    `,
  )}
  ${ifProp(
    'paddingY',
    css`
      padding-top: ${paddingProp('paddingY')};
      padding-bottom: ${paddingProp('paddingY')};
    `,
  )}
  ${ifProp(
    'paddingX',
    css`
      padding-left: ${paddingProp('paddingX')};
      padding-right: ${paddingProp('paddingX')};
    `,
  )}
  ${ifProp(
    'paddingTop',
    css`
      padding-top: ${paddingProp('paddingTop')};
    `,
  )}
  ${ifProp(
    'paddingRight',
    css`
      padding-right: ${paddingProp('paddingRight')};
    `,
  )}
  ${ifProp(
    'paddingBottom',
    css`
      padding-bottom: ${paddingProp('paddingBottom')};
    `,
  )}
  ${ifProp(
    'paddingLeft',
    css`
      padding-left: ${paddingProp('paddingLeft')};
    `,
  )}
`
