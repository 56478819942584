import { get } from 'lodash'
import memoize from 'memoize-one'

import { updateUserOptions } from 'happitu/src/actions/currentUserActions'
import { getCurrentUser } from 'happitu/src/helpers/currentUserHelpers'

const parseUserOption = memoize((hash?: string, defaultValue?: any) =>
  hash ? JSON.parse(atob(hash)) : defaultValue,
)

export const getUserOption = <O extends keyof UserOptions, D extends UserOptions[O]>(
  option: O,
  defaultValue?: D,
): D => {
  const user = getCurrentUser()
  return parseUserOption(get(user, `options.${option}`), defaultValue)
}

export const setUserOption = <O extends keyof UserOptions>(
  key: O,
  value?: UserOptions[O],
) => {
  return updateUserOptions(key, value)(window.store.dispatch, window.store.getState)
}

export const getFavoriteLookups = () => {
  const lookupOptions = getUserOption('ticketLookup')
  return (lookupOptions && lookupOptions.favoriteLookups) || []
}

export const getFavoriteReports = () => {
  const insightsOptions = getUserOption('insights')
  return insightsOptions?.favoriteReports || []
}
