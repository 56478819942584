import relay, { RelayDispatch } from './relay'

import {
  createContactDetailTypesRequest,
  getContactDetailTypesRequest,
} from 'happitu/src/services/happitu-api/contactDetailTypeService'

export function getContactDetailTypes() {
  return (dispatch: RelayDispatch<typeof getContactDetailTypesRequest>) => {
    return getContactDetailTypesRequest().then(relay(dispatch))
  }
}

export function createContactDetailType(contactDetail: Partial<ContactDetailTypeRecord>) {
  return (dispatch: RelayDispatch<typeof getContactDetailTypesRequest>) => {
    return createContactDetailTypesRequest(contactDetail).then(relay(dispatch))
  }
}
