import styled from 'styled-components'
import { ifProp, prop } from 'styled-tools'

import { media } from 'happitu/src/themes/style-utils'
import { metric } from 'theme'

export interface FlexProps {
  alignItems?: 'stretch' | 'center' | 'flex-start' | 'top'
  justifyContent?: 'flex-end'
  className?: string
  grow?: number | string
  position?: string
  shrink?: number | string
  flexWrap?: boolean
}

const alignItems = (props: FlexProps): string => props.alignItems || 'center'

export const FlexCol = styled.div<FlexProps>`
  display: flex;
  flex-direction: column;
  flex-grow: ${prop('grow') || undefined};
  flex-shrink: ${prop('shrink') || undefined};
  flex-wrap: ${ifProp('flexWrap', 'wrap', 'nowrap')};
  position: ${prop('position', undefined)};
`

export const FlexRow = styled.div<FlexProps>`
  align-items: ${alignItems};
  display: flex;
  flex-grow: ${prop('grow') || undefined};
  flex-shrink: ${prop('shrink') || undefined};
  flex-wrap: ${ifProp('flexWrap', 'wrap', 'nowrap')};
  justify-content: ${prop('justifyContent') || undefined};
  position: ${prop('position', undefined)};
`

export const ResponsiveRow = styled.div`
  align-items: ${alignItems};
  display: flex;

  ${media.handheld`
      flex-direction: row;
      align-items: stretch;

      > * {
        margin-bottom: ${metric('baseline')};
      }
    `}
`

export const Spacer = styled.div`
  flex-grow: 1;
`
