import React, { ReactNode } from 'react'

import Box from '../box'
import Text from '../text'

import { defineName, MenuComponent } from './utils'

interface Props {
  label: string
  children: ReactNode
}

const MenuListSection = defineName((props: Props) => {
  return (
    <div>
      <Box paddingY="0.5" paddingX="1">
        <Text style={{ textTransform: 'uppercase' }} bold as="sub" color="light">
          {props.label}
        </Text>
      </Box>
      {props.children}
    </div>
  )
}, MenuComponent.MenuListSection)

export default MenuListSection
