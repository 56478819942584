import React from 'react'
import styled from 'styled-components'

import BaseLoader from 'happitu/src/components/Loaders/BaseLoader'
import getStyle from 'happitu/src/getStyle'

const Wrapper = styled.div`
  border-left: 1px solid ${getStyle('border-color')};
  grid-area: helpTopics;
  position: relative;

  .loader {
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`

const HelpTopicLoader = () => {
  return (
    <Wrapper>
      <BaseLoader size="large" className="loader" />
    </Wrapper>
  )
}

export default HelpTopicLoader
