import styled from 'styled-components'
import { ifProp } from 'styled-tools'

import { ButtonProps } from 'happitu/src/components/button'
import styles from 'happitu/src/components/button/button.styles'
import { font } from 'theme'

export interface IconButtonProps extends ButtonProps {
  as?: keyof JSX.IntrinsicElements
  href?: string
  target?: string
  icon: string
}

export default styled.button.attrs((props: IconButtonProps) => ({
  className: `icon-${props.icon}`,
  type: 'button',
}))<IconButtonProps>`
  ${styles}
  font-size: 16px;
  font-weight: ${font('weight', 'normal')};
  min-height: ${ifProp({ size: 'large' }, '3rem', '2rem')};
  min-width: ${ifProp({ size: 'large' }, '3rem', '2rem')};
  padding: 0;

  &:before {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`
