import styled, { css } from 'styled-components'
import { ifProp, switchProp } from 'styled-tools'

import { SelectWrapperProps } from './Select.types'

import Icon from 'happitu/src/components/Icon'
import { SelectableList } from 'happitu/src/components/Lists'
import getStyle from 'happitu/src/getStyle'
import scrollbar, { scrollbarYOffset } from 'happitu/src/themes/scrollbar'
import { icomoon } from 'happitu/src/themes/style-utils'
import theme, { metric } from 'theme'

export const Label = styled.div`
  white-space: nowrap;
  align-items: center;
  transition: padding-left 100ms;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;

  ${Icon} {
    margin-right: 0.5em;
    color: ${getStyle('text-error-color')};
    padding: 3px;
    border-radius: 6px;
  }
`

export const SelectWrapper = styled.div<SelectWrapperProps>`
  border-radius: ${metric('borderRadius')};
  border: 1px solid;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  min-width: 100px;
  padding: 1em;
  padding-right: 2em;
  position: relative;
  width: fit-content;
  user-select: none;

  ${ifProp(
    'insistent',
    css`
      ${Label} {
        color: ${getStyle('text-sub-color')};
        font-style: italic;
        padding-left: 1.5em;
      }
    `,
  )}

  ${switchProp(
    'size',
    {
      small: css`
        padding: ${theme('button.padding')};
        min-height: 2.5em;
      `,
    },
    css`
      padding: 1em;
      min-height: 3em;
    `,
  )}

  ${switchProp(
    'type',
    {
      button: css`
        background: ${getStyle('button-base-default-background')};
        border-color: ${getStyle('button-base-borderColor')};
        font-weight: ${theme('button.fontWeight')};

        &:after {
          display: none;
        }
      `,
    },
    css`
      background: ${getStyle('select-default-background')};
      border-color: ${getStyle('select-default-borderColor')};
      padding-right: 3em;

      &:after {
        ${icomoon}
        content: '\\e910';
        position: absolute;
        right: 1em;
        top: 50%;
        transform: translateY(-50%);
      }
    `,
  )}

  ${ifProp(
    'disabled',
    css`
      background: ${getStyle('input-disabled-background')};
      border-color: ${getStyle('input-disabled-background')};
      box-shadow: none;
      color: ${getStyle('input-disabled-color')};
      cursor: not-allowed;
    `,
  )}

  &:focus-within {
    border-radius: ${metric('borderRadius')};
    background: ${getStyle('select-focus-background')};
    border-color: ${getStyle('input-focus-borderColor')};
    box-shadow: 0 0 0 1px ${getStyle('input-focus-borderColor')};
  }

  &[aria-pressed='true']:after {
    content: '\\e917';
  }

  input[role='select'] {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    height: 0;
    width: 0;
    border: 0;
    appearance: none;
  }

  ${SelectableList} {
    ${scrollbarYOffset}
    ${scrollbar}
    max-height: 50vh;
    min-width: 250px;
    overflow-y: auto;
    padding: 1em;
  }

  header {
    padding: 1em;
    border-bottom: 1px solid ${getStyle('menu-border-color')};
  }
`
