import styled from 'styled-components'
import { ifProp, prop } from 'styled-tools'

import getStyle from 'happitu/src/getStyle'
import { inputFocus } from 'happitu/src/themes/mixins/form'
import theme, { anim, metric } from 'theme'

export default styled.input`
  border-radius: ${ifProp('ghost', '0', metric('borderRadius'))};
  border: 1px solid ${getStyle('input-default-borderColor')};
  color: ${getStyle('text-default-color')};
  flex-grow: ${prop('grow')};
  min-width: 160px;
  padding: ${theme('input.padding')};
  transition: border ${anim('speed', 'fast')}, box-shadow ${anim('speed', 'fast')};

  &:focus {
    ${ifProp('ghost', '', inputFocus)}
  }
`
