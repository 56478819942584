export enum LocationStatus {
  'Active' = 'active',
  'Suspended' = 'suspended',
}
export interface LocationRecord extends StoreRecord {
  name: string
  phones: ContactMethod[]
  emails: ContactMethod[]

  address: Partial<AddressRecord>

  helpTopicImpressionIds: ID[]
  activeWorkflowIds: ID[]
  pendingWorkflowIds: ID[]

  status: LocationStatus
}

export type LocationStore = StoreInterface<LocationRecord>
