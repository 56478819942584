import { uploadFileRequest } from 'happitu/src/services/happitu-api/fileUploadService'

const uploadFile = async (
  collection: string,
  id: ID,
  files: File[],
  progressCallback?: (progress: number) => void,
) => {
  const fileIds: ID[] = []
  await Promise.all(
    files.map(async (file, index) => {
      const response = await uploadFileRequest(collection, id, file, (p) => {
        if (progressCallback) {
          progressCallback((p * (index + 1)) / files.length)
        }
      })
      response.fileUploads.forEach((f) => fileIds.push(f.id))
    }),
  )
  return fileIds
}

export default uploadFile
