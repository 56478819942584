import TextareaAutosize from 'react-textarea-autosize'
import styled, { css } from 'styled-components'
import { ifNotProp, ifProp } from 'styled-tools'

import { IconButton } from 'happitu/src/components/Buttons'
import Icon from 'happitu/src/components/Icon'
import { Select } from 'happitu/src/components/Select'
import { Label } from 'happitu/src/components/Select/Select.styles'
import { FlexRow } from 'happitu/src/components/_DEPRECATED/Flex'
import { buttonStyles } from 'happitu/src/components/button'
import getStyle from 'happitu/src/getStyle'
import { font, metric } from 'theme'

// prettier-ignore
export const PageSelect = styled(Select)<{ dark?: boolean, ghost?: boolean }>`
  ${buttonStyles};
  border-radius: ${metric('borderRadius')};
  box-shadow: none;
  font-size: inherit;
  justify-content: unset;
  margin-left: 0.75em;
  min-height: unset;
  padding-right: 2em;
  position: relative;
  width: 175px;

  ${Label} {
    display: inline-block;
    line-height: 1.3;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:after {
    right: .75em;
  }

  &:focus-within {
    background: ${getStyle('button-base-default-background')};
    border-color: transparent;
    border-radius: ${metric('borderRadius')};
    box-shadow: 0 0 0 2px ${getStyle('input-focus-borderColor')};
    outline: none;

    input {
      box-shadow: none;
    }
  }
`

export const ActionButton = styled(IconButton)`
  border-radius: ${metric('borderRadius')};
  font-size: ${font('size', 'medium')};
  margin-left: 0.5em;
  height: 38px;
  width: 38px;
`

export const InsertActionButton = styled(IconButton)`
  left: -0.5em;
  min-height: 1.4rem;
  min-width: 1.4rem;
  opacity: 0.6;
  position: absolute;
  transform: translateX(-100%);
  transition: all 100ms ease-in-out;

  :after {
    background: ${getStyle('button-base-default-background')};
    content: '';
    height: 2px;
    left: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 0.5em;
  }

  :hover {
    transform: translateX(-100%) scale(1.1);
  }
`

export const InsertAboveContainer = styled.div`
  height: 1.4rem;
  left: 0;
  position: absolute;
  top: -0.7rem;
  width: 100%;
  z-index: 1;

  :hover {
    ${InsertActionButton} {
      opacity: 1;
    }
  }

  ${InsertActionButton} {
    top: 0;
  }
`

export const InsertBelowContainer = styled.div`
  height: 1.4rem;
  left: 0;
  position: absolute;
  bottom: -0.7rem;
  width: 100%;
  z-index: 1;

  :hover {
    ${InsertActionButton} {
      opacity: 1;
    }
  }

  ${InsertActionButton} {
    bottom: 0;
  }
`

export const LabelWrapper = styled.div<{ isActive?: boolean }>`
  align-items: center;
  border: 1px solid ${getStyle('border-color')};
  border-radius: 6px;
  color: ${ifProp('isActive', getStyle('text-active-color'), getStyle('text-sub-color'))};
  display: flex;
  position: relative;

  ${Icon} {
    position: absolute;
    right: 0.5em;
    transition: opacity 150ms ease-in-out;
  }

  :focus-within {
    ${Icon} {
      opacity: 0;
      visibility: hidden;
    }
  }
`

export const StyledTextArea = styled(TextareaAutosize)`
  border: none;
  box-shadow: none;
  color: inherit;
  font-size: inherit;
  font-weight: ${font('weight', 'medium')};
  min-height: 3em;
  padding: 0.75em 1em;
  padding-right: 2em;
  resize: none;
  transition: none;

  :focus {
    box-shadow: 0 0 0 2px ${getStyle('input-focus-borderColor')};
    z-index: 1;
  }

  ::placeholder {
    color: ${getStyle('text-inactive-color')};
    font-weight: ${font('weight', 'normal')};
  }
`

interface BorderConfigProps {
  isDragging: boolean
  isFirst: boolean
  isLast: boolean
}

// prettier-ignore
const getBorderRadiusFromOrder = ({ isDragging, isFirst, isLast }: BorderConfigProps) => {
  if (isDragging || (isFirst && isLast)) {
    return css`
      border-radius: 6px;
    `
  } else if (isFirst) {
    return css`
      border-radius: 6px 6px 0 0;
    `
  } else if (isLast) {
    return css`
      border-radius: 0 0 6px 6px;
    `
  } else {
    return css`
      border-radius: 0;
    `
  }
}

const getBorderFromOrder = ({ isDragging, isFirst, isLast }: BorderConfigProps) => {
  if (isDragging || (isFirst && isLast)) {
    return
  } else if (isFirst) {
    return css`
      border-bottom: none;
    `
  } else if (isLast) {
    return css`
      border-top: none;
    `
  } else {
    return css`
      border-top: none;
      border-bottom: none;
    `
  }
}

interface ContainerProps {
  isDragging: boolean
  isFirst: boolean
  isLast: boolean
  isSelected?: boolean
}
// prettier-ignore
export const Container = styled.div<ContainerProps>`
  align-items: center;
  cursor: default;
  display: flex;
  user-select: none;
  width: fit-content;

  ${StyledTextArea} {
    ${ifProp('isSelected', css`
      :not(:focus) {
        background: ${getStyle('button-base-default-background')};
      }
    `)}
  }

  ${LabelWrapper} {
    ${props => getBorderRadiusFromOrder(props)}

    ${props => getBorderFromOrder(props)}

    ${ifNotProp('isLast', css`
      &:after {
        background: ${getStyle('border-color')};
        content: '';
        height: 1px;
        left: 50%;
        position: absolute;
        top: 100%;
        transform: translateX(-50%);
        width: calc(100% - 1.5em);
      }
    `)}

    ${ifNotProp('isFirst', css`
      padding-top: 1px;
    `)}
  }

  ${ ifProp('isFirst', css` padding-top: 1em;`)}
  ${ ifProp('isLast', css`padding-bottom: 1em;`)}
`

export const ControlsRow = styled(FlexRow)`
  position: relative;
`

export const Wrapper = styled.div``
