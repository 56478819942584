import { createContext, Dispatch, SetStateAction } from 'react'

import { VOID_FUNC } from 'happitu/src/helpers/codeHelpers'

export const MenuContext = createContext<{
  isVisible: boolean
  setVisibility: Dispatch<SetStateAction<boolean>>
}>({
  isVisible: false,
  setVisibility: VOID_FUNC,
})

export const MenuListContext = createContext<{
  hoverIndex: number
  setHover: Dispatch<SetStateAction<number>>
}>({
  hoverIndex: 0,
  setHover: VOID_FUNC,
})
