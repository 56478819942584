import { css } from 'styled-components'
import { ifProp } from 'styled-tools'

export interface ShadowProps {
  shadow?: boolean
}

export const shadowMixin = css<ShadowProps>`
  ${ifProp(
    'shadow',
    css`
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    `,
  )}
`
