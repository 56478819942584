import update from 'immutability-helper'
import { omit } from 'lodash'

import createReducer from './createReducer'

import * as types from 'happitu/src/constants/ActionTypes'

export const STEP_SHAPE = {
  id: null,
  name: null,
  stepGroupId: null,
  actionIds: [],
  resourceIds: [],
}

// NOTE: This is just for testing. Please remove me when the time is right.
export const initialState = {
  data: {},
  loaded: false,
  filteredBy: 'SHOW_ALL',
  _removedElements: {},
}

const addStep = (state, { payload }) => ({
  ...state,
  data: {
    ...state.data,
    [payload.id]: {
      ...STEP_SHAPE,
      ...payload,
      stepGroupId: payload.stepGroupId,
    },
  },
})

const timestampStep = (state, { stepId, updatedAt }) => {
  return {
    ...state,
    data: {
      ...state.data,
      [stepId]: {
        ...state.data[stepId],
        updatedAt,
      },
    },
  }
}

const deleteStep = (state, { stepId }) => {
  return {
    ...state,
    data: omit(state.data, [stepId]),
  }
}

const updateActionIds = (state, stepId, change) =>
  update(state, {
    data: {
      [stepId]: {
        actionIds: change,
      },
    },
  })

const updateFromRelay = (state, steps) => {
  if (!steps) return state
  const nextSteps = {}
  steps.forEach((step) => {
    const existingStep = state.data[step.id] || STEP_SHAPE
    nextSteps[step.id] = { ...existingStep, ...step }
  })
  return update(state, { data: { $merge: nextSteps } })
}

export default createReducer(initialState, {
  [types.RELAY_DISPATCH]: (state, { payload }) =>
    updateFromRelay(state, payload.workflowStepGroupSteps),

  // Steps
  [types.CREATE_WORKFLOW_STEP]: (state, action) => addStep(state, action),
  [types.DELETE_WORKFLOW_STEP]: (state, action) => deleteStep(state, action),
  [types.TIMESTAMP_STEP]: (state, action) => timestampStep(state, action.response),

  // Actions
  [types.UPDATE_WORKFLOW_ACTION_WITH_STEP_FAILED]: (state, { payload }) =>
    deleteStep(state, { stepId: payload.step.id }),
  [types.REMOVE_WORKFLOW_ACTION_FAILED]: (state, { payload }) =>
    updateActionIds(state, payload.stepId, {
      $splice: [[payload.actionIndex, 1, payload.id]],
    }),
})
