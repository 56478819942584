import * as React from 'react'

import { TicketingToolbar, TicketingHeading, TicketingSubHeading } from '../Scaffolding'

import { formatTicketNumber } from 'happitu/src/helpers/ticketBinHelper'

interface Props {
  workflow?: WorkflowRecord
  ticket?: TicketRecord
}

export default function TicketHeader({ ticket, workflow }: Props) {
  return (
    <TicketingToolbar style={{ gridArea: 'header' }}>
      <div>
        <TicketingHeading>
          {!!ticket && formatTicketNumber(ticket, true)}
        </TicketingHeading>
        <TicketingSubHeading>{workflow?.name}</TicketingSubHeading>
      </div>
    </TicketingToolbar>
  )
}
