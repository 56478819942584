import { ReactNode, useState } from 'react'

import getTicketErrorMessage from '../errors'

import { APIError } from 'happitu/src/createErrorHandler'

type ErrorReturnType = [ReactNode[], (e: APIError<any>[]) => void]

const useTicketErrors = (): ErrorReturnType => {
  const [errors, setErrors] = useState<ReactNode[]>([])
  const onError = (e: APIError<any>[]) => {
    setErrors(getTicketErrorMessage(e))
  }

  return [errors, onError]
}

export default useTicketErrors
