import { map } from 'lodash'

import * as types from 'happitu/src/constants/ActionTypes'

const populateCustomAttributes = (state, payload) => {
  if (!payload || !payload.contactAttributes) return state
  const { contactAttributes } = payload

  const details = {
    ...state.details,
  }

  map(contactAttributes.details, (type, key) => (details[key] = type))

  return {
    ...contactAttributes,
    details,
  }
}

export default function contactAttributesReducer(state = {}, { type, payload }) {
  switch (type) {
    case types.GET_ORGANIZATION:
      return populateCustomAttributes(state, payload)
    case types.UPDATE_ORGANIZATION:
      return populateCustomAttributes(state, payload)
    case types.UPDATE_ORGANIZATION_SUCCESS:
      return populateCustomAttributes(state, payload)
    case types.UPDATE_ORGANIZATION_FAIL:
      return populateCustomAttributes(state, payload)
    default:
      return state
  }
}
