import React, { ComponentProps, useCallback } from 'react'
import styled from 'styled-components'

import { FlexProps } from 'happitu/src/components/_DEPRECATED/Flex'
import { isSafari } from 'happitu/src/helpers/browser-helpers'
import scrollbar, { scrollbarYOffset } from 'happitu/src/themes/scrollbar'

interface Props extends FlexProps, ComponentProps<'div'> {
  children: React.ReactNode
  className?: string
  innerRef?: React.RefObject<HTMLDivElement>
  noShadow?: boolean
  onScroll?: React.UIEventHandler<HTMLDivElement>
  style?: React.CSSProperties
  preventParentScrolling?: boolean
}

const Overflow = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  ${scrollbar}
  ${scrollbarYOffset}
  flex-shrink: 1;
  overscroll-behavior: contain;
`

function Scrollable({ innerRef, children, preventParentScrolling, ...props }: Props) {
  function handleScroll(event: React.UIEvent<HTMLDivElement>) {
    if (props.onScroll) {
      props.onScroll(event)
    }
  }

  // Note: this should be resolved soon https://bugs.webkit.org/show_bug.cgi?id=220139
  const handleWheel = useCallback((event: React.WheelEvent<HTMLDivElement>) => {
    if (isSafari()) {
      const el = event.currentTarget
      if (el.clientHeight + el.scrollTop + event.deltaY >= el.scrollHeight) {
        event.preventDefault()
      } else if (el.scrollTop + event.deltaY <= 0) {
        event.preventDefault()
      }
      event.stopPropagation()
    }
  }, [])

  return (
    <Overflow
      {...props}
      onScroll={handleScroll}
      onWheel={preventParentScrolling ? handleWheel : undefined}
      ref={innerRef}
    >
      {children}
    </Overflow>
  )
}

export default styled(Scrollable)`
  outline: none;
`
