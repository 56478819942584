import { useContext } from 'react'

import { LocationRecord, LocationStatus } from 'happitu/src/models/location'
import { TicketContext } from 'happitu/src/modules/ticketing'

export const useSuspendedLocationValidation = (locationId?: ID | null) => {
  const { state } = useContext(TicketContext)
  const location =
    state.locations && locationId
      ? state.locations?.findById<LocationRecord>(locationId)
      : null
  return location?.status === LocationStatus.Suspended
}
