import * as React from 'react'
import { Response } from 'superagent'

import { getSupportEmail } from './helpers/localizeHelper'

import Notice from 'happitu/src/components/Fields/Notice'
import { error } from 'happitu/src/helpers/loggerHelper'

export interface APIError<RR = void> {
  referenceRecords?: RR
  type: string
  what: string
  message?: string
}

interface ErrorMessages {
  [what: string]: {
    [type: string]: string | React.ReactNode
  }
}

function getMessage(error: APIError, messages: ErrorMessages, response?: Response) {
  const defaultMessage = 'There was a problem with your request. Please try again.'
  try {
    return (
      messages[error.what][error.type] ||
      (response && messages[error.what][response.status]) ||
      defaultMessage
    )
  } catch (e) {
    return defaultMessage
  }
}

export default function createErrorHandler<P>(
  messages: ErrorMessages,
  Component: React.FunctionComponent | string = Notice,
  componentProps: Record<string, any> = {},
) {
  return (errors: APIError<any>[], props?: P, response?: Response) => {
    try {
      return errors.map((error) => {
        const message = getMessage(error, messages, response)
        return React.createElement(
          Component,
          {
            key: error.type,
            ...componentProps,
          },
          typeof message === 'function' ? message(props) : message,
        )
      })
    } catch (e) {
      error(e)
      return [
        React.createElement(
          Component,
          {},
          <>
            There was an issue on our end. Please try again, and if the problem persists,
            please contact us at{' '}
            <a href={`mailto:${getSupportEmail()}?subject=I experienced a system error`}>
              {getSupportEmail()}
            </a>
            .
          </>,
        ),
      ]
    }
  }
}
