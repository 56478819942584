import React from 'react'
import styled from 'styled-components'

const HelpTopicErrorBoundary = () => {
  return <div>There was a problem loading help topics.</div>
}

export default styled(HelpTopicErrorBoundary)`
  grid-area: helpTopics;
`
