import createReducer from './createReducer'
import Store from './createStore'

import { RelayAction } from 'happitu/src/actions/relay'
import * as types from 'happitu/src/constants/ActionTypes'

const initialState = new Store()

const updateActionWithDependency = (
  state: Store<WorkflowActionRecord>,
  { payload }: RelayAction<{ action: WorkflowActionRecord }>,
) => {
  return payload.action ? state.update(payload.action.id, payload.action) : state
}

export default createReducer(initialState, {
  [types.RELAY_DISPATCH]: (
    state,
    { payload }: RelayAction<{ workflowActions: WorkflowActionRecord[] }>,
  ) => state.setMany(payload.workflowActions),

  [types.UPDATE_WORKFLOW_ACTION_FAILED]: (state, { payload }: any) => {
    return state.updateWithErrors(payload.id, payload.errors)
  },

  [types.UPDATE_WORKFLOW_ACTION_WITH_STEP_FAILED]: updateActionWithDependency,

  [types.REMOVE_WORKFLOW_ACTION_FAILED]: (
    state,
    { payload }: RelayAction<Partial<WorkflowActionRecord>>,
  ) => state.rollbackRemoval(payload.id),
})
