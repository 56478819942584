import React, { MouseEventHandler } from 'react'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import HotkeyFigure from 'happitu/src/components/Layout/HotkeyFigure'
import Box from 'happitu/src/components/box'
import Text from 'happitu/src/components/text'
import getStyle from 'happitu/src/getStyle'
import useSelectableList from 'happitu/src/hooks/use-selectable-list'
import { font } from 'theme'

export interface SelectableOption {
  value: string
  label: string
  description?: string
}

export const isOption = (option: any): option is SelectableOption =>
  option && 'value' in option

const Wrapper = styled(Box)<{ active: boolean }>`
  grid-template-columns: 1fr min-content;
  font-weight: ${font('weight', 'normal')};
  cursor: pointer;

  .hotkey {
    opacity: 0;
    background: rgba(255, 255, 255, 0.1);
    padding: 0.4em;
    font-size: 11px;
  }

  ${ifProp(
    'active',
    css`
      background: ${getStyle('selectOption-selected-background')};

      p,
      .hotkey {
        color: ${getStyle('selectOption-selected-color')};
      }

      .hotkey {
        opacity: 1;
      }
    `,
  )}
`

interface OptionProps extends Omit<SelectableOption, 'value'> {
  active: boolean
  onMouseOver?: MouseEventHandler
  onMouseDown?: MouseEventHandler
  onClick?: MouseEventHandler
}

const Option = (props: OptionProps) => {
  return (
    <Wrapper
      as="a"
      align="center"
      active={props.active}
      rounded
      gap="1"
      paddingX=".75"
      paddingY=".25"
      onMouseOver={props.onMouseOver}
      onMouseDown={props.onMouseDown}
      onClick={props.onClick}
    >
      <Box gap="0">
        <Text color="default" bold>
          {props.label}
        </Text>
        {!!props.description && <Text color="sub">{props.description}</Text>}
      </Box>
      <HotkeyFigure className="hotkey" hotkey="return" />
    </Wrapper>
  )
}

interface Props {
  options: SelectableOption[]
  onSelect: (value: string) => void
}

const SelectableList = (props: Props) => {
  const [active, setActive] = useSelectableList({
    listLength: props.options.length,
    onSelect: (index) => {
      props.onSelect(props.options[index].value)
    },
  })

  return (
    <div>
      {props.options.map((option, index) => {
        return (
          <Option
            key={index}
            label={option.label}
            active={active === index}
            description={option.description}
            onMouseOver={() => setActive(index)}
            onMouseDown={(e) => {
              e.stopPropagation()
              props.onSelect(props.options[index].value)
            }}
          />
        )
      })}
    </div>
  )
}

export default SelectableList
