import update from 'immutability-helper'

export const updateContactAttribute = (contact, attribute, value) => {
  let con
  if (attribute === 'Name') {
    con = update(contact, {
      name: { $set: value },
    })
  } else if (/[a-zA-Z]\.[a-zA-Z]/.test(attribute)) {
    let a = attribute.split('.')
    let index = contact[a[0]].findIndex((item) => item.type === a[1])
    if (index > -1) {
      con = update(contact, {
        [a[0]]: {
          $splice: [[index, 1]],
        },
      })
    } else {
      con = update(contact, {
        [a[0]]: {
          $push: [{ values: value, type: a[1] }],
        },
      })
    }
  } else {
    con = update(contact, {
      details: {
        [attribute]: { $set: value },
      },
    })
  }
  return con
}

export const getContactData = (contact, contactAttribute) => {
  if (/name/i.test(contactAttribute)) {
    return [contact.name]
  }

  if (/[a-zA-Z]+\.[a-zA-Z]+/.test(contactAttribute)) {
    const ary = contactAttribute.split('.')
    const item = contact[ary[0]].find((item) => item.type === ary[1])
    const value = item
      ? ary[0] === 'addresses'
        ? addressObjectToArray(item)
        : [item.value]
      : []
    return value.length ? [...value, ary[1]] : []
  }
  return contact.details[contactAttribute] || []
}

/**
 * Turns an address record into an array of values.
 * @param {String} city
 * @param {String} country
 * @param {String} line1
 * @param {String} line2
 * @param {String} line3
 * @param {String} postalCode
 * @param {String} state
 * @param {String} type
 * @returns {String[]}
 */
export const addressObjectToArray = ({
  city,
  country,
  line1,
  line2,
  line3,
  postalCode,
  state,
  type,
}) => [line1, line2, line3, city, state, postalCode, country, type]

export const stringifyContactAddress = ({
  line1,
  line2,
  line3,
  city,
  state,
  postalCode,
  country,
}) => {
  const separator = postalCode || country ? ',' : ''
  const regionalPart = postalCode ? `${city}, ${state} ${postalCode}` : ''
  const streetPart = `${line1} ${line2} ${line3}`
  return `${streetPart}${separator}${regionalPart} ${country}`.replace(/\s{2,}/, ' ')
}

/**
 * Returns the last email address for the contact record.
 * If there is an email address, an empty string is returned instead.
 * @param {object} contact  The contact record.
 * @return {string}
 */
export const getLastContactEmailAddress = (contact) => {
  const email = contact.emails[contact.emails.length - 1]
  return (email && email.value) || ''
}
