import { Editor, Range, Element, Transforms } from 'slate'
import { v4 as uuid } from 'uuid'

import { ElementType, SectionBreakElement } from 'happitu/src/types/slate-types'

const withSectionBreaks = (editor: Editor) => {
  const { insertText, isVoid } = editor

  editor.isVoid = (element) => {
    return element.type === ElementType.SectionBreak ? true : isVoid(element)
  }

  editor.insertText = (text: string) => {
    const { selection } = editor

    // Handle '---' to insert section breaks
    if (text === '-' && selection && Range.isCollapsed(selection)) {
      const node = Editor.above(editor, { match: (n) => Editor.isBlock(editor, n) })
      if (node && Element.isElement(node[0]) && node[0].type === ElementType.Paragraph) {
        const nodeText = Editor.string(editor, node[1])
        const shortcutRegex = /^--(?![\s\S])/
        // If the text is only --, replace the node with a section break.
        if (shortcutRegex.test(nodeText)) {
          const { anchor } = selection
          const path = node ? node[1] : []
          const start = Editor.start(editor, path)
          const range = { anchor, focus: start }

          const sectionBreak: SectionBreakElement = {
            type: ElementType.SectionBreak,
            children: [{ text: '' }],
            nodeId: uuid(),
          }

          Transforms.select(editor, range)
          Transforms.insertNodes(editor, [sectionBreak], { at: path })
          Transforms.delete(editor)
          return
        }
      }
    }

    // Fallback on other insertText behavior.
    insertText(text)
  }

  return editor
}

export default withSectionBreaks
