import React from 'react'
import { RenderElementProps, useSelected } from 'slate-react'

import DisplayAttachment from './DisplayAttachment'

import { AttachmentElement } from 'happitu/src/types/slate-types'

interface Props extends RenderElementProps {
  element: AttachmentElement
}

const EditableAttachment = (props: Props) => {
  const selected = useSelected()
  return <DisplayAttachment selected={selected} readOnly={false} {...props} />
}

export default EditableAttachment
