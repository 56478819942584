import { Editor, Range, Transforms } from 'slate'
import { v4 as uuid } from 'uuid'

import { ElementType, WorkflowVariableElement } from 'happitu/src/types/slate-types'

export const createVariableNode = (variable: string): WorkflowVariableElement => {
  return {
    children: [{ text: '' }],
    nodeId: uuid(),
    placeholder: variable,
    type: ElementType.WorkflowVariable,
    variable: variable,
  }
}

export const insertVariable = (
  editor: Editor,
  variable: string,
  opts: { reverse?: boolean } = {},
) => {
  const variableElement = createVariableNode(variable)
  Transforms.insertNodes(editor, variableElement)
  Transforms.move(editor, opts)
}

const withWorkflowVariables = (editor: Editor) => {
  const { insertText, isInline, isVoid } = editor

  editor.isInline = (element) => {
    return element.type === ElementType.WorkflowVariable ? true : isInline(element)
  }

  editor.isVoid = (element) => {
    return element.type === ElementType.WorkflowVariable ? true : isVoid(element)
  }

  // eslint-disable-next-line complexity
  editor.insertText = (char: string) => {
    // Check for variable match, i.e. ":Variable Name:".
    if (char === ':') {
      const { selection } = editor

      if (selection && Range.isCollapsed(selection)) {
        const [start] = Range.edges(selection)
        const beforeCharPoint = Editor.before(editor, start)
        const beforeCharRange = Editor.range(editor, start, beforeCharPoint)

        // Get the char before the ':'.
        const beforeChar = Editor.string(editor, beforeCharRange)

        // '' - don't look back for match if we're at the beginning of a line.
        // ' ' - don't match a variable with spaces before or after i.e. ': not a variable :'
        // ':' - don't match existing variables i.e ':variable1::'
        // NOTE: Exhaustively check if we can find a variable before the current ':', else check after the selection.
        if (!['', ' ', ':'].includes(beforeChar)) {
          const beforeTextPoint = Editor.before(editor, start, { unit: 'line' })
          const beforeTextRange =
            beforeTextPoint && Editor.range(editor, beforeTextPoint, start)

          // Get the text preceding the ':' within the same line.
          const beforeText = beforeTextRange && Editor.string(editor, beforeTextRange)
          // Check for the latest match of a ':' not followed by a non-whitespace or additional ':' but followed by 1 or more non-':' characters.
          // ': var' doesn't match. ':var' will match.
          const beforeTextRegex = /(:[\w_]+)$/
          const beforeMatch = beforeText && beforeText.match(beforeTextRegex)

          insertText(char) // Insert the ':' now.

          // If we have a match, convert text into a variable.
          if (!!beforeMatch) {
            const variableBeforeText = beforeMatch[0] // This should look like ':variable name'.

            // Calculate a new start point to account for the added ':' char.
            const newStart = { ...start, offset: start.offset + 1 }

            const variablePoint = Editor.before(editor, newStart, {
              unit: 'character',
              distance: variableBeforeText.length + 1,
            })
            const variableRange =
              variablePoint && Editor.range(editor, variablePoint, newStart)
            if (variableRange) {
              const fullVariableText = Editor.string(editor, variableRange) // ':variable:'
              const trimmedVariable = fullVariableText.replaceAll(':', '') // 'variable'
              // Replace the variable text with the void node.
              Transforms.select(editor, variableRange)
              insertVariable(editor, trimmedVariable)

              // NOTE: The below is necessary if we don't use voids.

              // // Track if the selection is already within a variable.
              // const hasVariableAncestor = isVariable(editor)

              // // Finally, wrap the matched text with a variable element.
              // Transforms.wrapNodes(editor, variableElement, {
              //   at: variableRange,
              //   split: true,
              // })

              // // TODO: Maybe unwrap the node first and recalculate values as necessary.
              // // If we're creating a variable that would be nested within an existing variable, unwrap the existing variable node.
              // if (hasVariableAncestor) {
              //   Transforms.unwrapNodes(editor, {
              //     mode: 'highest',
              //     match: (n) =>
              //       Element.isElement(n) && n.type === ElementType.WorkflowVariable,
              //   })
              // }
            }
          }
          return
        } else {
          // Check if there's a matching variable after the current selection.

          const afterCharPoint = Editor.after(editor, start)
          const afterCharRange = Editor.range(editor, start, afterCharPoint)

          // Get the char after the ':'.
          const afterChar = Editor.string(editor, afterCharRange)

          if ([' ', '', ':'].includes(afterChar)) {
            insertText(char)
            return
          }

          const afterTextPoint = Editor.after(editor, start, { unit: 'line' })
          const afterTextRange =
            afterTextPoint && Editor.range(editor, afterTextPoint, start)

          // Get the text preceding the ':' within the same line.
          const afterText = afterTextRange && Editor.string(editor, afterTextRange)

          // Check for the earliest match of text that ends with a ':' without preceding whitespace.
          const afterTextRegex = /^([\w_]+:)/
          const afterMatch = afterText && afterText.match(afterTextRegex)

          insertText(char) // Insert the ':' now.

          // If we have a match, convert text into a variable.
          if (!!afterMatch) {
            const variableAfterText = afterMatch[0] // This should look like ':variable name'.

            const variablePoint = Editor.after(editor, start, {
              unit: 'character',
              distance: variableAfterText.length + 1,
            })
            const variableRange =
              variablePoint && Editor.range(editor, variablePoint, start)
            if (variableRange) {
              const fullVariableText = Editor.string(editor, variableRange) // ':variable:'
              const trimmedVariable = fullVariableText.replaceAll(':', '') // 'variable'
              // Replace the variable text with the void node.
              Transforms.select(editor, variableRange)
              insertVariable(editor, trimmedVariable, { reverse: true })
            }
          }
          return
        }
      }
    }

    insertText(char)
  }

  return editor
}

export default withWorkflowVariables
