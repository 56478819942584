import * as color from './colors'

export const divider = color.grey.lightest

export const link = {
  color: color.grey.base,
  iconColor: color.grey.lighter,

  hover: {
    color: color.grey.base,
  },

  active: {
    background: color.green.base,
    color: 'white',
    iconColor: 'rgba(255, 255, 255, .5)',
    indicator: color.secondary.base,
  },
}
