import styled from 'styled-components'

import Icon from 'happitu/src/components/Icon'
import { FlexCol } from 'happitu/src/components/_DEPRECATED/Flex'
import { Button } from 'happitu/src/components/button'
import { fontColorByBackground } from 'happitu/src/helpers/domHelper'

export const Wrapper = styled(FlexCol)`
  margin: 0 6px;
`

export const StyledButton = styled(Button)<{ color: string }>`
  background-color: ${(props) =>
    props.color.charAt(0) === '#' ? props.color : '#' + props.color};
  border: 0;
  color: ${(props) => fontColorByBackground(props.color)};
  margin: 0;
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);

  :hover,
  :active {
    background-color: ${(props) =>
      props.color.charAt(0) === '#' ? props.color : '#' + props.color};
    color: ${(props) => fontColorByBackground(props.color)};
  }
`

export const StyledIcon = styled(Icon)`
  margin-right: 0 !important;
`

export const InputWrapper = styled.div`
  position: relative;
`
