import * as Types from './relay.types'

import { APIError } from 'happitu/src/createErrorHandler'
import { processResponse } from 'happitu/src/models/searchSuggester/searchSuggester.context'

export const RELAY_DISPATCH = 'RELAY_DISPATCH'

export default function relay<R extends (...args: any) => Promise<any>>(
  dispatch: Types.RelayDispatch<R>,
) {
  return (
    response: Types.UnpackPromise<ReturnType<R>>,
    options: Types.RelayAction<Types.UnpackService<R>>['options'] = {},
  ) => {
    processResponse(response)
    return dispatch({
      payload: response,
      options,
      type: RELAY_DISPATCH,
    })
  }
}

export function relayError<R extends (...args: any) => Promise<any>>(
  dispatch: Types.RelayDispatch<R>,
) {
  return (
    errors?: APIError[] | ReadonlyArray<APIError>,
    options: Types.RelayAction<Types.UnpackService<R>>['options'] = {},
  ) => {
    return dispatch({
      payload: {
        errors: errors,
        nonRelayKeys: ['errors'],
      },
      options,
      type: RELAY_DISPATCH,
    })
  }
}
