/* eslint-disable @typescript-eslint/no-explicit-any */
import { get } from 'lodash'
import memoizeOne from 'memoize-one'
import * as React from 'react'
import { connect } from 'react-redux'

import NotFound from 'happitu/src/components/ErrorPages/NotFound'
import AppLoader from 'happitu/src/components/Loaders/AppLoader'
import { error } from 'happitu/src/helpers/loggerHelper'

export interface BaseProps {
  route?: Router5Route
}

type DecoratedComponent =
  | React.FunctionComponent<BaseProps>
  | React.ComponentClass<BaseProps>
type Component = React.FunctionComponent<any> | React.ComponentClass<any>

interface RouterOptions {
  baseRoute?: string
  views: {
    [key: string]:
      | Component
      | {
          [key: string]:
            | Component
            | {
                [key: string]:
                  | Component
                  | {
                      [key: string]: Component
                    }
              }
        }
  }
}

const DefaultWrapper = (props: BaseProps & { children: React.ReactNode }) => (
  <React.Suspense fallback={() => <AppLoader />}>{props.children}</React.Suspense>
)

function isComponent(component: any): component is Component {
  return component && (typeof component === 'function' || !!component.$$typeof)
}

function getComponent(
  routeName: string,
  views: RouterOptions['views'],
  index: number,
): Component | void {
  const component = get(views, routeName)

  if (component) {
    if (isComponent(component)) {
      return component
    } else {
      const indexComponent = get(views, routeName + '.index')
      if (isComponent(indexComponent)) return indexComponent
    }
  }

  const indexComponent = views.index
  if (isComponent(indexComponent)) return indexComponent

  const nextIndex = index - 1
  const route = routeName.split('.', nextIndex).join('.')
  if (nextIndex > 0) {
    return getComponent(route, views, nextIndex)
  }

  error('Missing route', routeName)
}

function mapStateToProps(state: ReduxState) {
  return {
    route: state.router.route,
  }
}

const getRouteSegment = memoizeOne((routeName: string, baseRoute = '') => {
  const routeParts = routeName.split('.')
  const rootPart = routeParts.indexOf(baseRoute)
  const segmentedRouteName = routeParts.slice(rootPart + 1, routeParts.length).join('.')
  return {
    segmentedRouteName: segmentedRouteName,
    routeParts: routeParts,
  }
})

export default function createRouter(
  routeOptions: RouterOptions,
  FallbackComponent: Component = NotFound,
) {
  return (DecoratedComponent: DecoratedComponent = DefaultWrapper) => {
    return connect(mapStateToProps)((props: BaseProps) => {
      if (props.route) {
        const { segmentedRouteName, routeParts } = getRouteSegment(
          props.route.name,
          routeOptions.baseRoute,
        )
        const component = getComponent(
          segmentedRouteName,
          routeOptions.views,
          routeParts.length,
        )
        const view = React.createElement(component || FallbackComponent, {
          route: props.route,
        }) as React.ReactElement
        return React.createElement(DecoratedComponent, props, view)
      }
      return React.createElement(DecoratedComponent, props, <FallbackComponent />)
    })
  }
}
