import { css } from 'styled-components'

import getStyle from 'happitu/src/getStyle'
import theme, { anim, metric } from 'theme'

export const input = css`
  background: ${getStyle('input-default-background')};
  border-radius: ${metric('borderRadius')};
  border: 1px solid ${getStyle('input-default-borderColor')};
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1);
  color: ${getStyle('text-default-color')};
  padding: ${theme('input.padding')};
  transition: border ${anim('speed', 'superFast')},
    box-shadow ${anim('speed', 'superFast')};
`

export const inputFocus = css`
  border-color: ${getStyle('input-focus-borderColor')};
  box-shadow: 0 0 0 2px ${getStyle('input-focus-borderColor')};
`
