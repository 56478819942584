import styled from 'styled-components'

import getStyle from 'happitu/src/getStyle'

export const Wrapper = styled.div`
  display: grid;
  grid-template:
    'documentation header helpTopics' 4em
    'documentation body helpTopics' calc(100vh - 9em)
    'documentation footer helpTopics' 5em
    / minmax(300px, 400px) minmax(300px, 50%) minmax(300px, 30%);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
`

export const DocumentationWrapper = styled.div`
  border-right: 1px solid ${getStyle('border-color')};
  display: grid;
  grid-area: documentation;
  grid-template-rows: min-content minmax(33%, 66%) auto;
  max-width: 400px;
  min-width: 200px;
  transition: opacity 600ms;

  & > * {
    padding: 1em;
  }
`
