import { SuperAgentRequest } from 'superagent'

import apiRequest from './request'
import { handleReject, handleSuccess } from './responseHandler'

import { HelpTopicRecord, HelpTopicPageRecord } from 'happitu/src/types/models/helpTopics'

interface HelpTopicResponse {
  helpTopics: HelpTopicRecord[]
  helpTopicPages: HelpTopicPageRecord[]
}

interface QueryParams {
  workflowId?: string
  workflowVersion?: number
  working?: boolean
  current?: boolean
  excludeExpired?: boolean
}

export interface SearchServiceConfig {
  cursor?: string
  fieldParams?: Record<string, any>
  isCurrentVersion?: boolean
  isWorkingVersion?: boolean
  limit?: number
  responseScope?: 'ids'
  workflowIds?: ID[]
  includeExpired?: boolean
}

export const getHelpTopicsRequest = (queryParams: QueryParams = { working: true }) =>
  new Promise((resolve, reject) =>
    apiRequest
      .get('/help-topics')
      .query(queryParams)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )

const defaultSearchConfig = {
  fieldParams: {
    'name': { boost: '2.0', useHighlighter: true },
    'pages.name': {},
    'pages.searchText': { useHighlighter: true },
  },
  isCurrentVersion: ['true'],
  includedExpired: false,
  limit: 50,
}

// NOTE: You may filter by workflow version by tacking on the version number
// to id (e.g., workflowId: 5d51d4bb0d64436b5d922dd8 verion: 0 => 5d51d4bb0d64436b5d922dd80)
export const searchHelpTopicsRequest = (
  query: string,
  sort: object[] = [],
  conf?: SearchServiceConfig,
) =>
  new Promise<{ helpTopics: HelpTopicRecord[] } | ID[]>((resolve, reject) => {
    const config = { ...defaultSearchConfig, ...conf } as SearchServiceConfig
    apiRequest
      .post('/help-topic-search')
      .send({
        cursor: config.cursor,
        fieldParams: config.fieldParams,
        filters: {
          isCurrentVersion: config.isCurrentVersion,
          isWorkingVersion: config.isWorkingVersion,
          workflowIds: config.workflowIds,
          includeExpired: config.includeExpired
            ? [`${config.includeExpired}`]
            : undefined,
        },
        minimumMatch: '10<100%',
        limit: config.limit,
        query,
        responseScope: config.responseScope,
        sort,
      })
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r)))
  })

export const getHelpTopicRequest = (id: ID) =>
  new Promise<HelpTopicResponse>((resolve, reject) =>
    apiRequest
      .get(`/help-topics/${id}`)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )

export const getWorkingHelpTopicRequest = (impressionId: ID) =>
  new Promise<HelpTopicResponse>((resolve, reject) =>
    apiRequest
      .get(`/help-topic-working/${impressionId}`)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )

export const newHelpTopicRequest = (helpTopic: Partial<HelpTopicPageRecord>) =>
  new Promise<HelpTopicResponse>((resolve, reject) =>
    apiRequest
      .post('/help-topics')
      .send(helpTopic)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )

let inFlightRequest: null | SuperAgentRequest = null
export const updateHelpTopicRequest = (id: ID, helpTopic: Partial<HelpTopicPageRecord>) =>
  new Promise<HelpTopicResponse>((resolve, reject) => {
    if (inFlightRequest) inFlightRequest.abort()
    inFlightRequest = apiRequest.put(`/help-topics/${id}`).send(helpTopic)

    inFlightRequest
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r)))
    return inFlightRequest
  })

export const publishHelpTopicRequest = (id: ID) =>
  new Promise<HelpTopicResponse>((resolve, reject) =>
    apiRequest
      .post(`/help-topic-rollout/${id}`)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )

export const deleteHelpTopicRequest = (id: ID) =>
  new Promise<HelpTopicResponse>((resolve, reject) =>
    apiRequest
      .delete(`/help-topics/${id}`)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )
