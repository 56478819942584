import jwtDecode from 'jwt-decode'
import { Response, SuperAgentRequest } from 'superagent'

import apiRequest from './request'
import { handleReject, handleSuccess } from './responseHandler'

import { APIErrorResponse } from 'happitu/src/actions/relay'
import { UserType } from 'happitu/src/helpers/userHelpers'

interface CurrentUserResponse {
  organizations: OrganizationRecord[]
  users: UserRecord[]
  websocketToken: string
  authToken: string
}

/**
 * Fetch the current user record.
 */
export const getCurrentUserRequest = () => {
  return new Promise<CurrentUserResponse>((resolve, reject) => {
    return apiRequest
      .get('/current_user')
      .then((r: Response) => resolve(handleSuccess(r)))
      .catch((r: APIErrorResponse) => reject(handleReject(r)))
  })
}

/**
 * Updates the user record.
 */
export const updateUserRequest = (userId: ID, userRecord: Partial<UserRecord>) => {
  return new Promise<{ users: UserRecord[] }>((resolve, reject) => {
    return apiRequest
      .put(`/users/${userId}`)
      .send(userRecord)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r)))
  })
}

export const updateUserTypeRequest = (userId: ID, userType: UserType) => {
  return new Promise<{ users: UserRecord[] }>((resolve, reject) => {
    return apiRequest
      .post(`/users/set-user-type/${userId}/${userType}`)
      .send({})
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r)))
  })
}

export interface PasswordChangeFields {
  newPassword: string
  currentPassword?: string
  token?: string
}

/**
 * Changes the current users password.
 */
export const changePasswordRequest = (passwords: PasswordChangeFields) => {
  return new Promise((resolve, reject) => {
    return apiRequest
      .post('/users/change-password')
      .send(passwords)
      .then((r: Response) => resolve(handleSuccess(r)))
      .catch((r: APIErrorResponse) => reject(handleReject(r)))
  })
}

export const getUsersRequest = () => {
  return new Promise((resolve, reject) => {
    return apiRequest
      .get('/users')
      .send()
      .then((r: Response) => resolve(handleSuccess(r)))
      .catch((r: APIErrorResponse) => reject(handleReject(r)))
  })
}

interface EmailRequestParams {
  newEmailAddress: string
  currentPassword: string
}

export const changeEmailRequest = (params: EmailRequestParams) => {
  return new Promise((resolve, reject) => {
    return apiRequest
      .post('/email-change-request')
      .send(params)
      .then((r: Response) => resolve(handleSuccess(r)))
      .catch((r: APIErrorResponse) => reject(handleReject(r)))
  })
}

interface EmailChangeToken {
  iat: number
  newEmail: string
  organizationId: ID
  userId: ID
}

interface ProcessEmailParams {
  token: string
  password?: string
}

const organizationHeader = (params: ProcessEmailParams) => {
  return (req: SuperAgentRequest) => {
    if (!params.password) {
      const { organizationId } = jwtDecode<EmailChangeToken>(params.token)
      req.set('Happitu-Organization-ID', organizationId)
    }
    return req
  }
}

export const processEmailRequest = (params: ProcessEmailParams) => {
  return new Promise((resolve, reject) => {
    return (
      apiRequest
        .post('/process-email-change-request')
        .use(organizationHeader(params))
        .send(params)
        // Set organization ID header only if the user is logged in.
        // If the user is not logged in, a password needs to be provided.

        .then((r: Response) => resolve(handleSuccess(r)))
        .catch((r: APIErrorResponse) => reject(handleReject(r)))
    )
  })
}

export const removeUserRequest = (id: string, currentPassword: string) =>
  new Promise((resolve, reject) =>
    apiRequest
      .delete(`/users/${id}`)
      .send({ currentPassword })
      .then((r: Response) => resolve(handleSuccess(r)))
      .catch((r: APIErrorResponse) => reject(handleReject(r))),
  )
