import { MouseEventHandler, MouseEvent, useCallback, useContext, useRef } from 'react'
import { ReactNode } from 'react-markdown'

import { MenuContext } from './context'

import useDocumentToggleEvent from 'happitu/src/hooks/useDocumentToggleEvent'

export enum MenuComponent {
  MenuListItem = 'MenuListItem',
  MenuListSection = 'MenuListSection',
}

const NAV_HEIGHT = 80
const NAME_ATTR = '_menu_component_name'

export const getName = (component?: any): MenuComponent =>
  component && component.type && component.type[NAME_ATTR]

export const defineName = (component: ReactNode, name: MenuComponent) =>
  Object.defineProperty(component, NAME_ATTR, { value: name, writable: false })

export const usePosition = (isVisible: boolean, enabledOverflow: boolean) => {
  const ref = useRef<HTMLDivElement>(null)
  const position = () => {
    if (!ref.current || !ref.current.parentNode) return
    const { top, bottom } = (ref.current
      .parentNode as HTMLElement).getBoundingClientRect()
    // this gives us the "true" heigh and ignores transform scaling
    const height = ref.current.scrollHeight
    const isTopAligned = ref.current.style.bottom !== ''
    const menuBottom = bottom + height

    if (isTopAligned) {
      if (top - height < NAV_HEIGHT) {
        ref.current.style.bottom = ''
        ref.current.style.top = 'calc(100% + 10px)'
      }
    } else {
      if (
        (top - height > NAV_HEIGHT ||
          (enabledOverflow && top - height > -(window.innerHeight / 2))) &&
        menuBottom - 10 > window.innerHeight
      ) {
        ref.current.style.bottom = 'calc(100% + 10px)'
        ref.current.style.top = ''
        const { top: menuTop, height: menuHeight } = ref.current.getBoundingClientRect()
        ref.current.style.maxHeight = `calc(100vh - ${
          window.innerHeight - menuTop - menuHeight
        }px)`
      } else {
        const { top: menuTop } = ref.current.getBoundingClientRect()
        ref.current.style.maxHeight = `calc(100vh - ${menuTop}px)`
      }
    }
  }

  useDocumentToggleEvent(isVisible, position, 'scroll')
  return { ref, position }
}

export const useMenuButton = (
  onClick?: MouseEventHandler,
): [boolean, MouseEventHandler] => {
  const { isVisible, setVisibility } = useContext(MenuContext)
  const click = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (typeof onClick === 'function') onClick(e)
      setVisibility(!isVisible)
    },
    [isVisible],
  )

  return [isVisible, click]
}
