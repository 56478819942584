import { isAfter } from 'date-fns'
import React from 'react'
import { connect } from 'react-redux'

import { getOrganizationId, isAuthenticated } from '../helpers/authHelpers'

import { initializeCurrentUser } from 'happitu/src/actions/currentUserActions'
import AppLoader from 'happitu/src/components/Loaders/AppLoader'

interface Props {
  currentUser: CurrentUserRecord
  organization: OrganizationRecord
  initializeCurrentUser: (
    ...params: Parameters<typeof initializeCurrentUser>
  ) => ReturnType<ReturnType<typeof initializeCurrentUser>>
  children: React.ReactNode
}

const mapStateToProps = (state: ReduxState) => ({
  currentUser: state.user,
  organization: state.organization,
})

const mapDispatchToProps = {
  initializeCurrentUser,
}

const isWithinGracePeriod = (gracePeriod?: string | null) => {
  if (!gracePeriod) return false
  return isAfter(new Date(gracePeriod), new Date())
}

class AppInitializer extends React.Component<Props> {
  componentDidMount() {
    const organizationId = getOrganizationId()

    this.props
      .initializeCurrentUser()
      .then((response) => {
        if (!isAuthenticated(response.authToken)) {
          window.location.href = organizationId
            ? `/org/${organizationId}/login`
            : '/login'
        }
      })
      .catch(() => {
        window.location.href = organizationId ? `/org/${organizationId}/login` : '/login'
      })
  }

  render() {
    // Note: This is here because of a really bad design decision that requires
    // currentUser to be initialized as an empty object. This should be refactored
    // when we redo the workflow designer.
    if (!this.props.currentUser.id) {
      return <AppLoader />
    }

    // Turn this on later:
    if (
      !this.props.organization.paymentInfo.free &&
      !this.props.organization.paymentInfo.stripeCustomerId &&
      !isWithinGracePeriod(this.props.organization.paymentInfo.gracePeriod)
    ) {
      window.location.href = `/org/${this.props.organization.id}/setup`
      return null
    }

    if (
      this.props.organization.happituVersion === 'v2' &&
      !this.props.organization.features?.guides
    ) {
      window.location.href = `${process.env.SERVICE_V2_APP}/org/${this.props.organization.id}`
      return null
    }

    return this.props.children
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppInitializer)
