import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import { boolableProp } from 'happitu/src/themes/helpers'
import { growMixin, GrowProps } from 'happitu/src/themes/mixins'

interface Props extends GrowProps {
  y?: number | string | boolean
  x?: number | string | boolean
}

const Spacer = styled.div<Props>`
  ${ifProp(
    'y',
    css`
      height: ${boolableProp('y', 'em')};
    `,
  )}

  ${ifProp(
    'x',
    css`
      width: ${boolableProp('x', 'em')};
    `,
  )}

  ${growMixin}
`

export default Spacer
