import { useState } from 'react'

import useHotkey, { Hotkey } from './useHotkey'

interface HookProps {
  listLength: number
  onSelect?: (itemIndex: number) => void
}

const useSelectableList = (props: HookProps): [number, typeof setActiveIndex] => {
  const [activeIndex, setActiveIndex] = useState<number>(0)

  useHotkey(
    Hotkey.Down,
    () => {
      setActiveIndex((activeIndex) => {
        return activeIndex === props.listLength - 1 ? 0 : activeIndex + 1
      })
    },
    true,
    [props.listLength],
  )

  useHotkey(
    Hotkey.Up,
    () => {
      setActiveIndex((activeIndex) => {
        return activeIndex === 0 ? props.listLength - 1 : activeIndex - 1
      })
    },
    true,
    [props.listLength],
  )

  useHotkey(
    Hotkey.Enter,
    () => {
      if (typeof props.onSelect === 'function') props.onSelect(activeIndex)
    },
    true,
    [activeIndex],
  )

  return [activeIndex, setActiveIndex]
}

export default useSelectableList
