import api from "!../../../../.yarn/$$virtual/style-loader-virtual-1eeb2c781f/0/cache/style-loader-npm-2.0.0-b9a5c4a2aa-ffc3054882.zip/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../.yarn/$$virtual/css-loader-virtual-ef416ba337/0/cache/css-loader-npm-5.2.4-285d2e57c2-3698399558.zip/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../.yarn/$$virtual/postcss-loader-virtual-9cd4602e66/0/cache/postcss-loader-npm-5.2.0-a85448a80f-669232a256.zip/node_modules/postcss-loader/dist/cjs.js!../../../../.yarn/$$virtual/sass-loader-virtual-122f19711e/0/cache/sass-loader-npm-11.0.1-5dad8a423c-fdd50c6c65.zip/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].use[3]!./DropdownOption.scss";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};