
export function embedRecordEvent<R> (record: R, recordEvent?: Partial<RecordEvent>) {
  if (recordEvent === undefined) {
    return record
  }
  return {
    ...record,
    _recordEvents: [{
      ...recordEvent,
      metadata: {
        ...recordEvent.metadata,
        url: window.location.href,
      },
      createdAt: new Date().toISOString(),
    }],
  }
}
