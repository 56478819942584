import React, { useEffect } from 'react'

import ErrorBoundary from 'happitu/src/components/ErrorBoundary'
import { Notice } from 'happitu/src/components/Fields'
import { DisplayElements } from 'happitu/src/components/WorkflowElements/DisplayElements'
import { error } from 'happitu/src/helpers/loggerHelper'

interface Props {
  component: WorkflowElementTypes
  [key: string]: any
}

const ElementErrorFallback = (props: Props) => {
  useEffect(() => {
    error('error while rendering element', props)
  }, [])
  return (
    <Notice type="error">
      There was an error rendering "{props.attributes.label ?? props.component}". Please
      refresh before proceeding.
    </Notice>
  )
}

const WorkflowElement = ({ component, ...props }: Props) => {
  return (
    <ErrorBoundary
      errorComponent={() => <ElementErrorFallback {...props} component={component} />}
    >
      {React.createElement(DisplayElements[component], props)}
    </ErrorBoundary>
  )
}

export default WorkflowElement
