import { createContext } from 'react'

import { OptionValue } from '../ListItems'

import { AcceptedSelectValue } from 'happitu/src/components/Select'

interface ContextProps {
  focusIndex: number
  getIndexFromValue: (value: OptionValue) => number
  multiple?: boolean
  onHover: (value: OptionValue) => void
  onSelect: (optionValue: OptionValue) => void
  selected?: AcceptedSelectValue | null
  value?: AcceptedSelectValue | null
}

const SelectableContext = createContext<ContextProps>({
  focusIndex: 0,
  onHover: () => {
    return
  },
  onSelect: () => {
    return
  },
  getIndexFromValue: () => -1,
  selected: null,
})

export default SelectableContext
