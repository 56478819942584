// tslint:disable: object-literal-sort-keys
export default [
  {
    name: 'home',
    path: '/',
  },
  {
    name: 'app',
    path: '/org/:organizationId',
    children: [
      // Insights
      {
        name: 'insights',
        path: '/insights',
        children: [
          {
            name: 'view',
            path: '/:reportId?:hash',
          },
        ],
      },

      // Ticket lookup
      {
        name: 'lookup',
        path: '/lookup',
        children: [
          {
            name: 'view',
            path: '/:savedQueryId',
          },
        ],
      },

      // Tickets
      {
        name: 'tickets',
        path: '/tickets',
        children: [
          {
            name: 'success',
            path: '/success',
          },

          // Ticket Documentation
          {
            name: 'view',
            path: '/:ticketId',
            children: [
              {
                name: 'explainHook',
                path: '/explain/:ticketInteractionId',
              },
            ],
          },

          // New Ticket Landing Page
          {
            name: 'new',
            path: '/new/:workflowId',
          },

          // Find Ticket Before Create
          {
            name: 'findOrCreate',
            path: '/find-or-create/:workflowId',
          },

          // Ticketing
          {
            name: 'work',
            path: '/:ticketId/work/:ticketInteractionId/:stepIndex',
          },
        ],
      },

      // Workflows
      {
        name: 'workflow',
        path: '/workflow',
        children: [
          {
            name: 'setup',
            path: '/setup',
            children: [
              {
                name: 'smtp',
                path: '/smtp',
              },
            ],
          },
          // TODO: Move this to be a child of workflow.designer.manage.
          {
            name: 'smtpNew',
            path: '/:workflowId/smtp',
          },
          // TODO: Move this to be a child of workflow.designer.manage.
          {
            name: 'smtpUpdate',
            path: '/:workflowId/smtp/:smtpSettingId',
          },

          // Workflow Designer
          {
            name: 'designer',
            path: '/:workflowId',
            children: [
              // Workflow Designer - Step Designer
              {
                name: 'step',
                path: '/step/:stepId',
                children: [
                  {
                    name: 'test',
                    path: '/test',
                  },
                ],
              },
            ],
          },

          // Workflow Designer - Workflow Manage
          {
            name: 'manage',
            path: '/:workflowId/manage',
            children: [
              {
                name: 'locations',
                path: '/locations',
                children: [
                  {
                    name: 'upload',
                    path: '/upload',
                  },
                  {
                    name: 'process',
                    path: '/:fileId/process',
                  },
                  {
                    name: 'index',
                    path: '/:workflowId/locations',
                  },
                ],
              },
            ],
          },

          {
            name: 'embed',
            path: '/:workflowId/embed',
          },

          {
            name: 'incontact',
            path: '/:workflowId/incontact',
          },

          {
            name: 'preview',
            path: '/:workflowId/preview',
          },
          {
            name: 'rollout',
            path: '/:workflowId/rollout',
          },
          // TODO: Move this to be a child of workflow.designer.manage.
          {
            name: 'modify',
            path: '/:workflowId/edit',
          },
          {
            name: 'testEmail',
            path: '/:workflowId/test/email',
          },
        ],
      },

      // Resources
      {
        name: 'resource',
        path: '/resource',
        children: [
          {
            name: 'view',
            path: '/:resourceId',
          },
        ],
      },

      // Manage
      {
        name: 'manage',
        path: '/manage',
        children: [
          // Manage Members
          {
            name: 'members',
            path: '/members',
          },

          {
            name: 'organizations',
            path: '/organizations',
          },

          // Invite User
          // TODO: Move this to be a child of manage.members.
          {
            name: 'invite',
            path: '/members/invite',
          },

          {
            name: 'locations',
            path: '/locations',
            children: [
              {
                name: 'edit',
                path: '/:locationId/edit',
              },
              {
                name: 'new',
                path: '/new',
              },
            ],
          },

          {
            name: 'helpTopics',
            path: '/help-topics',
            children: [
              {
                name: 'edit',
                path: '/:helpTopicImpressionId?:backTo',
                children: [
                  {
                    name: 'page',
                    path: '/page/:helpTopicPageImpressionId/edit?:backTo',
                  },
                ],
              },
              {
                name: 'new',
                path: '/new?:backTo',
              },
            ],
          },

          // Billing
          {
            name: 'billing',
            path: '/billing',
            children: [
              {
                name: 'new',
                path: '/new',
              },
            ],
          },

          // Interaction Hooks
          {
            name: 'interactionHooks',
            path: '/interaction-hooks',
            children: [
              {
                name: 'edit',
                path: '/:interactionHookId/edit',
              },
              {
                name: 'new',
                path: '/new',
              },
            ],
          },

          // Notification Hooks
          {
            name: 'notificationHooks',
            path: '/notification-hooks',
            children: [
              {
                name: 'edit',
                path: '/edit/:notificationHookId',
              },
            ],
          },

          // Manage Teams
          {
            name: 'teams',
            path: '/teams',
            children: [
              {
                name: 'new',
                path: '/new',
              },
              {
                name: 'edit',
                path: '/:teamId',
              },
            ],
          },

          // User Profile
          {
            name: 'profile',
            path: '/profile',
          },

          // Workflow Templates
          {
            name: 'workflowTemplates',
            path: '/workflow-templates',
            children: [
              {
                name: 'edit',
                path: '/:workflowTemplateId/edit',
              },
            ],
          },

          // Workflow Overview
          {
            name: 'workflows',
            path: '/workflows',
          },

          // Tag Overview
          {
            name: 'tags',
            path: '/tags',
          },

          // Invitations
          {
            name: 'invitations',
            path: '/invitations',
          },
        ],
      },
    ],
  },
] as Router5DefinedRoute[]
// tslint:enable: object-literal-sort-keys
