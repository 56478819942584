import React, { Dispatch, SetStateAction } from 'react'

import SelectableList from '../ListContainers/SelectableList'
import { Option } from '../ListItems'

import { generatedMetadata } from 'happitu/src/helpers/generatedMetadata'

interface Props {
  autoFocus?: boolean
  fieldName: string
  values: string[]
  onSelect: (values: string[]) => void | Dispatch<SetStateAction<string[]>>
}

const PresetSelector = (props: Props) => {
  const { presets } = generatedMetadata.modelInfo.ticketInteractions.fields[
    props.fieldName === 'InteractionType' ? 'Channels' : props.fieldName
  ]
  if (!presets) throw new Error('FilterSelect: missing preset')
  return (
    <SelectableList
      autoFocus={props.autoFocus}
      multiple
      onSelect={props.onSelect}
      value={props.values}
    >
      {Object.keys(presets).map((presetKey) => (
        <Option key={presetKey} value={`${presets[presetKey]}`} label={presetKey} />
      ))}
    </SelectableList>
  )
}

export default PresetSelector
