import React, { CSSProperties } from 'react'

import { FieldFigure, FieldWrapper, Label } from './FieldUtils'

interface Props {
  label: string
  footnote?: string
  children: React.ReactNode
  style?: CSSProperties
}

const CellChooser = (props: Props) => {
  return (
    <FieldWrapper>
      <Label value={props.label} />
      <div style={{ ...props.style, paddingBottom: 1 }}>{props.children}</div>
      <FieldFigure value={props.footnote} />
    </FieldWrapper>
  )
}

export default CellChooser
