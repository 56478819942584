import { pick, uniq } from 'lodash'

import { convertAddressArrayToObject } from 'happitu/src/helpers/contactHelper.new'
import { error } from 'happitu/src/helpers/loggerHelper'
import {
  createContactRequest,
  updateContactRequest,
} from 'happitu/src/services/happitu-api/contactsService'
function updateContactAttribute(attribute: string, values: string[]) {
  const [key, type] = attribute.split('.')
  switch (key) {
    case 'phones':
    case 'emails':
      return { [key]: [{ type, value: values[0] }] }
    case 'addresses':
      return { [key]: [convertAddressArrayToObject(values)] }
    case 'firstName':
    case 'lastName':
      return { [key]: values[0] }
    default:
      if (typeof key === 'undefined' || typeof type === 'undefined') {
        error(
          `updateContactAttribute: unknown contact attribute is set on field. attribute: ${attribute}`,
        )
        return {}
      }
      return { details: { [type]: values } }
  }
}

export function updateContact(
  values: string[],
  currentContact: Partial<ContactRecord> = {},
  element?: WorkflowElementRecord,
) {
  if (
    element &&
    (element.attributes.contactAttribute || element.attributes.customerAttribute)
  ) {
    const key =
      element.attributes.contactAttribute ||
      (element.attributes.customerAttribute as string)

    const nextContact = updateContactAttribute(key, values)
    return {
      ...currentContact,
      ...nextContact,
      details: {
        ...currentContact.details,
        ...(nextContact.details || {}),
      },
    }
  }

  return
}

const safeKeys: Array<keyof ContactRecord> = [
  'details',
  'emails',
  'firstName',
  'lastName',
  'phones',
]
export const sanitizeContact = (contact: Partial<ContactRecord>) =>
  pick(contact, safeKeys)
const isEmptyContact = (contact: Partial<ContactRecord>) => {
  for (const key of safeKeys) {
    if (contact[key]) {
      return false
    }
  }
  return true
}
export async function finalizeContact(
  contact: Partial<ContactRecord>,
  ticketId: ID,
): Promise<{ contacts: ContactRecord[] } | void> {
  const sanitizedContact = sanitizeContact(contact)
  if (contact.id) {
    return await updateContactRequest(contact.id, {
      ...sanitizedContact,
      ticketIds: uniq([...(contact.ticketIds || []), ticketId]),
    })
  } else if (!isEmptyContact(sanitizedContact)) {
    if (sanitizedContact.firstName)
      return await createContactRequest({
        ...sanitizedContact,
        ticketIds: [ticketId],
      })
  }
}
