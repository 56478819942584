import { css } from 'styled-components'
import { ifProp } from 'styled-tools'

const BORDER_RADIUS = '6px'

export interface RoundedProps {
  rounded?: boolean
}

export const roundedMixin = css<RoundedProps>`
  border-radius: ${ifProp('rounded', BORDER_RADIUS, 0)};
`
