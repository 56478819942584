import { detect } from 'detect-browser'

const browser = detect()

enum Browser {
  IE = 'ie',
  Safari = 'safari',
  Edge = 'edge',
}

const isBrowser = (name: Browser) => browser && browser.name === name

export const isSafari = () => isBrowser(Browser.Safari)
export const isIE = () => isBrowser(Browser.IE)
// Newer (chromium) version of edge are cool with me.
export const isNonChromiumEdge = () =>
  isBrowser(Browser.Edge) &&
  browser &&
  (!browser.version || parseInt(browser.version.split('.')[0]) < 79)
