import autobind from 'autobind-decorator'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Mask, Wrapper } from './MenuWrapper.styles'

import { getHorizontalMetrics, getVerticalMetrics } from 'happitu/src/helpers/domHelper'
import portalHelper, { createPortal } from 'happitu/src/helpers/portalHelper'

export default class MenuWrapper extends Component {
  static propTypes = {
    anchorRef: PropTypes.object.isRequired,
    closeOnClick: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    style: PropTypes.object,
    width: PropTypes.number,
  }

  static defaultProps = {
    closeOnClick: false,
    style: {},
    horizontalOffset: 5,
    verticalOffset: 5,
  }

  constructor(props) {
    super(props)
    this.domNode = portalHelper('span')
  }

  componentDidMount() {
    this.handlePosition()
    window.addEventListener('resize', this.handlePosition)
  }

  componentDidUpdate(prevProps) {
    if (this.props.width !== prevProps.width) {
      this.handlePosition()
    }
  }

  componentWillUnmount() {
    portalHelper(this.domNode)
    window.removeEventListener('resize', this.handlePosition)
  }

  getAnchorRect() {
    const { anchorRef } = this.props
    try {
      return (anchorRef.current || anchorRef).getBoundingClientRect()
    } catch (e) {
      throw new Error(
        'Unable to anchor to dom element. You most likely forgot to reference one.',
      )
    }
  }

  @autobind
  handlePosition() {
    const { innerHeight, innerWidth } = window
    const anchorMetrics = this.getAnchorRect()
    // Set the menu width to the width of the anchor as the base width.
    // This will be used in the following calculations.
    this._menu.style.width = `${this.props.width || anchorMetrics.width}px`
    const menuMetrics = this._menu.getBoundingClientRect()
    const vertical = getVerticalMetrics(anchorMetrics, menuMetrics, innerHeight)
    const horizontal = getHorizontalMetrics(anchorMetrics, menuMetrics, innerWidth)

    this._menu.style = `
        ${vertical.key}: ${vertical.value + this.props.verticalOffset}px;
        ${horizontal.key}: ${horizontal.value + this.props.horizontalOffset}px;
        width: ${this.props.width ? this.props.width : anchorMetrics.width}px;
      `
  }

  @autobind
  handleClose(event) {
    this.props.onClose(event)
  }

  @autobind
  handleClick(event) {
    if (!this.props.closeOnClick) {
      event.stopPropagation()
    }
  }

  @autobind
  handleRef(ref) {
    this._menu = ref
  }

  renderMenu() {
    const { style } = this.props
    return (
      <Mask onClick={this.handleClose} style={{ pointerEvents: style.pointerEvents }}>
        <Wrapper
          {...this.props}
          onClick={this.handleClick}
          ref={this.handleRef}
          style={style}
          role="menu"
        />
      </Mask>
    )
  }

  render() {
    return createPortal(this.renderMenu(), this.domNode)
  }
}
