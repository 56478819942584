import { Editor, Range, Transforms } from 'slate'

import { setBlockType } from 'happitu/src/helpers/editor/formattingHelper'
import { ElementType } from 'happitu/src/types/slate-types'

// '+': Maybe create an action/link to another page?
// '###': Apply a bold mark instead of creating an H3 block.
// TODO: Maybe allow users to enter up to '99.'
const SHORTCUTS: Record<string, ElementType> = {
  '*': ElementType.UnorderedList,
  '-': ElementType.UnorderedList,
  '1.': ElementType.OrderedList,
  '1)': ElementType.OrderedList,
  '>': ElementType.BlockQuote,
  '#': ElementType.HeadingOne,
  '##': ElementType.HeadingTwo,
}

const withBlockMarkdown = (editor: Editor) => {
  const { insertText } = editor

  editor.insertText = (text: string) => {
    const { selection } = editor

    // Handle block-level markdown.
    if (text === ' ' && selection && Range.isCollapsed(selection)) {
      const { anchor } = selection
      const block = Editor.above(editor, {
        match: (n) => Editor.isBlock(editor, n),
      })
      const path = block ? block[1] : []
      const start = Editor.start(editor, path)
      // Get the range from the start of the block to the cursor position.
      const range = { anchor, focus: start }
      // Whenever a space is entered, get the text before it.
      const beforeText = Editor.string(editor, range)
      // Check if that text is a shortcut.
      const shortcut = SHORTCUTS[beforeText]

      if (block && shortcut) {
        // Delete the shortcut text.
        Transforms.delete(editor, { at: range })
        // Coerce the node to the new type.
        setBlockType(editor, shortcut)
        return
      }
    }

    insertText(text)
  }

  return editor
}

export default withBlockMarkdown
