import React, { ComponentProps, forwardRef } from 'react'
import styled from 'styled-components'

import buttonStyles from './button.styles'
import { ButtonProps } from './button.types'
import ButtonLabel from './label'

import useMultipleRefs from 'happitu/src/hooks/useMultipleRefs'
import useShadowSize from 'happitu/src/hooks/useShadowSize'

interface Props extends Omit<ComponentProps<'button'>, 'value'>, ButtonProps {
  loading?: boolean
  loadingLabel?: string
  primary?: boolean
  value?: React.ReactNode
  icon?: string
}

const ALLOWED_PROPS: Array<keyof Props> = ['loading', 'loadingLabel', 'primary', 'icon']

const ANIMATION_SPEED = 200

const Button = forwardRef<HTMLButtonElement, Props>((props: Props, ref) => {
  const {
    children,
    icon,
    loading,
    loadingLabel = 'Loading...',
    primary,
    value,
    type = 'button',
    ...buttonProps
  } = props

  const [animationRef] = useShadowSize<HTMLButtonElement>(loading, ANIMATION_SPEED)
  const combinedRefs = useMultipleRefs<HTMLButtonElement>(animationRef, ref)

  const Label = (
    <ButtonLabel
      icon={icon}
      invert={primary}
      loading={loading}
      loadingValue={loadingLabel}
      value={value || children}
    />
  )

  return (
    <button
      role="button"
      type={type}
      {...buttonProps}
      disabled={buttonProps.disabled || loading}
      ref={combinedRefs}
    >
      {Label}
    </button>
  )
})

//

export default styled(Button).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    ALLOWED_PROPS.includes(prop) || defaultValidatorFn(prop),
})`
  ${buttonStyles}
  overflow: hidden;
  transition: width ${ANIMATION_SPEED}ms, padding ${ANIMATION_SPEED}ms;
  white-space: nowrap;
`
