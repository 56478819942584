import { Ref, useCallback, RefCallback } from 'react'

const isRefCallback = <T>(ref: Ref<T>): ref is RefCallback<T> => {
  return typeof ref === 'function'
}

const isRefObject = <T>(ref: Ref<T>): ref is { current: T } => {
  return !!ref && 'current' in ref
}

// Inspired from https://github.com/facebook/react/issues/13029
const useMultipleRefs = <T extends any>(...refs: Array<Ref<T>>): Ref<T> =>
  useCallback(
    (element: T) =>
      refs.forEach((ref) => {
        // Ref can have two types - a function or an object. We treat each case.
        if (isRefCallback(ref)) return ref(element)
        if (isRefObject(ref)) return (ref.current = element)
      }),
    refs,
  )

export default useMultipleRefs
