import React from 'react'
import { useSlate } from 'slate-react'
import styled from 'styled-components'

import { toolbarButtonStyles } from './Toolbar.styles'

import Icon from 'happitu/src/components/Icon'
import {
  acceptedFileTypes,
  getDocumentsFromFiles,
  insertDocumentsFromFiles,
} from 'happitu/src/helpers/editor/attachmentHelpers'
import {
  getImagesFromFiles,
  insertImagesFromFiles,
} from 'happitu/src/helpers/editor/imageHelper'
import { getDataTransferItems } from 'happitu/src/helpers/fileHelper'

interface Props extends React.ComponentProps<'label'> {
  id: ID
  collection: string
}

const InsertImageButton = ({ collection, id, ...props }: Props) => {
  const [value, setValue] = React.useState('')
  const editor = useSlate()

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = getDataTransferItems(e)
    const images = getImagesFromFiles(files)
    const documents = getDocumentsFromFiles(files)
    insertImagesFromFiles(editor, images, collection, id)
    insertDocumentsFromFiles(editor, documents, collection, id)
  }

  const handleMouseDown = (e: React.MouseEvent<HTMLLabelElement>) => {
    e.preventDefault()
    setValue('') // NOTE: This is necessary to wipe the value of the file input in case the user tries to upload the same file again.
  }

  return (
    <label className={props.className} onMouseDown={handleMouseDown}>
      <input
        type="file"
        accept={`image/*,${acceptedFileTypes.join(',')}`}
        multiple
        onChange={handleChange}
        value={value}
      />
      <Icon type="image" size="medium" />
    </label>
  )
}

export default styled(InsertImageButton)`
  ${toolbarButtonStyles}

  input[type="file"] {
    bottom: -1000px;
    left: -1000px;
    opacity: 0;
    position: fixed;
    visibility: hidden;
  }
`
