import React, { ReactNode } from 'react'

import useTicketingHelpTopicNav from './hooks/use-ticketing-help-topic-nav'
import FindOrCreateTicket from './views/find-or-create-ticket'
import NewTicket from './views/new-ticket'
import TicketSubmitted from './views/ticket-submitted'
import WorkTicket from './views/work-ticket'

import createRouter from 'happitu/src/createRouter'
import { TicketProvider } from 'happitu/src/modules/ticketing'
import TicketingHelpTopicsContext from 'happitu/src/modules/ticketing/ticketing-help-topics.context'

const routes = {
  baseRoute: 'tickets',
  views: {
    new: NewTicket,
    work: WorkTicket,
    findOrCreate: FindOrCreateTicket,
    success: TicketSubmitted,
  },
}

interface Props {
  children: ReactNode
  route: Router5Route
}

function Ticketing(props: Props) {
  const contextProps = useTicketingHelpTopicNav()
  return (
    <TicketingHelpTopicsContext.Provider value={contextProps}>
      <TicketProvider>{props.children}</TicketProvider>
    </TicketingHelpTopicsContext.Provider>
  )
}

export default createRouter(routes)(Ticketing)
