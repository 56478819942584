// Workflows
export const GET_WORKFLOWS: ActionType = 'GET_WORKFLOWS'
export const CREATE_WORKFLOW: ActionType = 'CREATE_WORKFLOW'
export const UPDATE_WORKFLOW: ActionType = 'UPDATE_WORKFLOW'
export const UPDATE_WORKFLOW_LOCATIONS: ActionType = 'UPDATE_WORKFLOW_LOCATIONS'
export const PUBLISH_WORKFLOW: ActionType = 'PUBLISH_WORKFLOW'
export const SAVE_WORKFLOW: ActionType = 'SAVE_WORKFLOW'
export const DELETE_WORKFLOW: ActionType = 'DELETE_WORKFLOW'
export const TIMESTAMP_WORKFLOW: ActionType = 'TIMESTAMP_WORKFLOW'

// SMTP Setting
export const GET_SMTP_SETTINGS: ActionType = 'GET_SMTP_SETTINGS'
export const GET_SMTP_SETTING: ActionType = 'GET_SMTP_SETTING'
export const CREATE_SMTP_SETTING: ActionType = 'CREATE_SMTP_SETTING'
export const UPDATE_SMTP_SETTING: ActionType = 'UPDATE_SMTP_SETTING'

// Workflow Steps
export const CREATE_WORKFLOW_STEP: ActionType = 'CREATE_WORKFLOW_STEP'
export const DELETE_WORKFLOW_STEP: ActionType = 'DELETE_WORKFLOW_STEP'
export const TIMESTAMP_STEP: ActionType = 'TIMESTAMP_STEP'
export const ADD_WORKFLOW_STEP_TO_STEP_GROUP: ActionType = 'ADD_WORKFLOW_STEP_GROUP_STEP'
export const REMOVE_WORKFLOW_STEP_FROM_GROUP: ActionType =
  'REMOVE_WORKFLOW_STEP_FROM_GROUP'
export const CHANGE_WORKFLOW_STEP_GROUP: ActionType = 'CHANGE_WORKFLOW_STEP_GROUP'

// Workflow Elements
export const ADD_WORKFLOW_ELEMENT: ActionType = 'ADD_WORKFLOW_ELEMENT'
export const REPLACE_WORKFLOW_ELEMENT: ActionType = 'REPLACE_WORKFLOW_ELEMENT'
export const MOVE_WORKFLOW_ELEMENT: ActionType = 'MOVE_WORKFLOW_ELEMENT'
export const CLONE_WORKFLOW_ELEMENT: ActionType = 'CLONE_WORKFLOW_ELEMENT'
export const SYMBOLIZE_WORKFLOW_ELEMENT: ActionType = 'SYMBOLIZE_WORKFLOW_ELEMENT'

export const UPDATE_WORKFLOW_ELEMENT: ActionType = 'UPDATE_WORKFLOW_ELEMENT'
export const UPDATE_WORKFLOW_ELEMENT_SUCCESS: ActionType =
  'UPDATE_WORKFLOW_ELEMENT_SUCCESS'

export const REMOVE_WORKFLOW_ELEMENT: ActionType = 'REMOVE_WORKFLOW_ELEMENT'
export const REMOVE_WORKFLOW_ELEMENT_SUCCESS: ActionType =
  'REMOVE_WORKFLOW_ELEMENT_SUCCESS'

// Workflow Actions
export const UPDATE_WORKFLOW_ACTION_FAILED: ActionType = 'UPDATE_WORKFLOW_ACTION_FAILED'
export const UPDATE_WORKFLOW_ACTION_WITH_STEP_FAILED: ActionType =
  'UPDATE_WORKFLOW_ACTION_WITH_STEP_FAILED'
export const REMOVE_WORKFLOW_ACTION_FAILED: ActionType = 'REMOVE_WORKFLOW_ACTION_FAILED'

// Workflow Designer Settings
export const SET_WORKFLOW_DESIGNER_SETTING: ActionType = 'SET_WORKFLOW_DESIGNER_SETTING'

// Linting
export const LINT_WORKFLOW: ActionType = 'LINT_WORKFLOW'
export const LINT_WORKFLOW_STEP: ActionType = 'LINT_WORKFLOW_STEP'
export const LINT_WORKFLOW_AND_STEP: ActionType = 'LINT_WORKFLOW_AND_STEP'
export const LINT_LOADING: ActionType = 'LINT_LOADING'

// Resources
export const NEW_RESOURCE: ActionType = 'NEW_RESOURCE'
export const CREATE_RESOURCE: ActionType = 'CREATE_RESOURCE'
export const DELETE_RESOURCE: ActionType = 'DELETE_RESOURCE'
export const EDIT_RESOURCE: ActionType = 'EDIT_RESOURCE'
export const UPDATE_RESOURCE: ActionType = 'UPDATE_RESOURCE'
export const ADD_RESOURCE_TO_STEP: ActionType = 'ADD_RESOURCE_TO_STEP'
export const REMOVE_RESOURCE_FROM_STEP: ActionType = 'REMOVE_RESOURCE_FROM_STEP'
export const GET_RESOURCE: ActionType = 'GET_RESOURCE'
export const GET_RESOURCES: ActionType = 'GET_RESOURCES'

export const FIND_RESOURCE: ActionType = 'FIND_RESOURCE'
export const SEARCHING_RESOURCES: ActionType = 'SEARCHING_RESOURCES'

// Organization
export const GET_ORGANIZATION: ActionType = 'GET_ORGANIZATION'
export const ADD_TAG_TO_ORGANIZATION: ActionType = 'ADD_TAG_TO_ORGANIZATION'
export const UPDATE_ORGANIZATION: ActionType = 'UPDATE_ORGANIZATION'
export const UPDATE_ORGANIZATION_SUCCESS: ActionType = 'UPDATE_ORGANIZATION_SUCCESS'
export const UPDATE_ORGANIZATION_FAIL: ActionType = 'UPDATE_ORGANIZATION_FAIL'

export const FILTER_RESOURCES: ActionType = 'FILTER_RESOURCES'

// Teams
export const UPDATE_TEAM_FAILED: ActionType = 'UPDATE_TEAM_FAILED'

export const ADD_TICKET_TO_TEAM: ActionType = 'ADD_TICKET_TO_TEAM'

export const WS_CREATE_TEAM: ActionType = 'WS_CREATE_TEAM'
export const WS_UPDATE_TEAM: ActionType = 'WS_UPDATE_TEAM'
export const WS_DELETE_TEAM: ActionType = 'WS_DELETE_TEAM'

// Users
export const GET_USERS: ActionType = 'GET_USERS'
export const UPDATE_USER_AVAILABILITY: ActionType = 'UPDATE_USER_AVAILABILITY'

export const UPDATE_USER: ActionType = 'UPDATE_USER'
export const UPDATE_USER_FAILED: ActionType = 'UPDATE_USER_FAILED'

export const WS_REMOVE_USER: ActionType = 'WS_REMOVE_USER'
export const REMOVE_USER: ActionType = 'REMOVE_USER'
export const REMOVE_USER_SUCCESS: ActionType = 'REMOVE_USER_SUCCESS'
export const REMOVE_USER_FAILED: ActionType = 'REMOVE_USER_FAILED'

// Ticket Bin
export const TICKET_BIN_MASS_ASSIGN: ActionType = 'TICKET_BIN_MASS_ASSIGN'
export const TICKET_BIN_MASS_ASSIGN_SUCCESS: ActionType = 'TICKET_BIN_MASS_ASSIGN_SUCCESS'
export const TICKET_BIN_MASS_ASSIGN_FAILED: ActionType = 'TICKET_BIN_MASS_ASSIGN_FAILED'

export const TICKET_BIN_MASS_STATUS: ActionType = 'TICKET_BIN_MASS_STATUS'
export const TICKET_BIN_MASS_STATUS_SUCCESS: ActionType = 'TICKET_BIN_MASS_STATUS_SUCCESS'
export const TICKET_BIN_MASS_STATUS_FAILED: ActionType = 'TICKET_BIN_MASS_STATUS_FAILED'

export const TICKET_BIN_MASS_PRIORITY: ActionType = 'TICKET_BIN_MASS_PRIORITY'
export const TICKET_BIN_MASS_PRIORITY_SUCCESS: ActionType =
  'TICKET_BIN_MASS_PRIORITY_SUCCESS'
export const TICKET_BIN_MASS_PRIORITY_FAILED: ActionType =
  'TICKET_BIN_MASS_PRIORITY_FAILED'

export const TICKET_BIN_MASS_TAG: ActionType = 'TICKET_BIN_MASS_TAG'
export const TICKET_BIN_MASS_TAG_SUCCESS: ActionType = 'TICKET_BIN_MASS_TAG_SUCCESS'
export const TICKET_BIN_MASS_TAG_FAILED: ActionType = 'TICKET_BIN_MASS_TAG_FAILED'

// Dashboard
export const GET_DASHBOARD: ActionType = 'GET_DASHBOARD'
export const HYDRATE_WIDGET: ActionType = 'HYDRATE_WIDGET'

// Tags
export const GET_TAGS: ActionType = 'GET_TAGS'
export const FETCH_TAG: ActionType = 'FETCH_TAG'
export const CREATE_TAG: ActionType = 'CREATE_TAG'
export const UPDATE_TAG: ActionType = 'UPDATE_TAG'
export const DELETE_TAG: ActionType = 'DELETE_TAG'
export const SET_TAG: ActionType = 'SET_TAG'

// Postal Codes
export const GET_POSTAL_CODE: ActionType = 'GET_POSTAL_CODE'

// Websockets
export const WS_CREATE_TICKET: ActionType = 'WS_CREATE_TICKET'
export const WS_UPDATE_TICKET: ActionType = 'WS_UPDATE_TICKET'
export const WS_DELETE_TICKET: ActionType = 'WS_DELETE_TICKET'

export const WS_CREATE_TICKET_EMAIL: ActionType = 'WS_CREATE_TICKET_EMAIL'

export const WS_CREATE_INTERACTION: ActionType = 'WS_CREATE_INTERACTION'
export const WS_UPDATE_INTERACTION: ActionType = 'WS_UPDATE_INTERACTION'
export const WS_DELETE_INTERACTION: ActionType = 'WS_DELETE_INTERACTION'

export const WS_CREATE_WORKFLOW_STEP_GROUP: ActionType = 'WS_CREATE_WORKFLOW_STEP_GROUP'
export const WS_UPDATE_WORKFLOW_STEP_GROUP: ActionType = 'WS_UPDATE_WORKFLOW_STEP_GROUP'
export const WS_DELETE_WORKFLOW_STEP_GROUP: ActionType = 'WS_DELETE_WORKFLOW_STEP_GROUP'

export const WS_CREATE_WORKFLOW_STEP: ActionType = 'WS_CREATE_WORKFLOW_STEP'
export const WS_UPDATE_WORKFLOW_STEP: ActionType = 'WS_UPDATE_WORKFLOW_STEP'
export const WS_DELETE_WORKFLOW_STEP: ActionType = 'WS_DELETE_WORKFLOW_STEP'

export const WS_CREATE_RESOURCE: ActionType = 'WS_CREATE_RESOURCE'
export const WS_UPDATE_RESOURCE: ActionType = 'WS_UPDATE_RESOURCE'
export const WS_DELETE_RESOURCE: ActionType = 'WS_DELETE_RESOURCE'

export const WS_CREATE_TAG: ActionType = 'WS_CREATE_TAG'
export const WS_UPDATE_TAG: ActionType = 'WS_UPDATE_TAG'
export const WS_DELETE_TAG: ActionType = 'WS_DELETE_TAG'

export const WS_ROOM_UPDATE: ActionType = 'WS_ROOM_UPDATE'

export const WS_UPDATE_ORGANIZATION: ActionType = 'WS_UPDATE_ORGANIZATION'

// Locations
export const GET_LOCATION: ActionType = 'GET_LOCATION'
export const GET_LOCATIONS: ActionType = 'GET_LOCATIONS'
export const CREATE_LOCATION: ActionType = 'CREATE_LOCATION'
export const UPDATE_LOCATION: ActionType = 'UPDATE_LOCATION'
export const DELETE_LOCATION: ActionType = 'DELETE_LOCATION'
export const STORE_LOCATION_TEMPLATE: ActionType = 'STORE_LOCATION_TEMPLATE'
export const CLEAR_LOCATION_TEMPLATE: ActionType = 'CLEAR_LOCATION_TEMPLATE'
export const LOCATION_UPLOAD: ActionType = 'LOCATION_UPLOAD'
export const LOCATION_LOOKUP: ActionType = 'LOCATION_LOOKUP'
export const SET_ACTIVE_LOCATION: ActionType = 'SET_ACTIVE_LOCATION'

// Invitations
export const GET_INVITATIONS: ActionType = 'GET_INVITATIONS'
export const DELETE_INVITATION: ActionType = 'DELETE_INVITATION'
export const DELETE_INVITATION_SUCCESS: ActionType = 'DELETE_INVITATION_SUCCESS'
export const DELETE_INVITATION_FAILED: ActionType = 'DELETE_INVITATION_FAILED'
export const RESEND_INVITATION: ActionType = 'RESEND_INVITATION'
export const UPDATE_INVITATION: ActionType = 'UPDATE_INVITATION'

export const RELAY_DISPATCH: ActionType = 'RELAY_DISPATCH'
