import { startCase, toLower } from 'lodash'
import * as React from 'react'
import shallowEqual from 'shallowequal'

import CitySelectValue from './CitySelectValue'
import FuzzySelect from './FuzzySelect'
import Input from './Input'
import {
  CityStateCountryWrapper,
  CityStateWrapper,
  Loader,
  PostalCodeInput,
  StateField,
  Wrapper,
} from './PostalCodeLookup.styles'

import { getPostalCodeRequest } from 'happitu/src/services/happitu-api/postalCodeService'

interface Props {
  postalCode: string
  city: string
  state: string
  country: string
  onChange: (postalCode: PostalCode) => void
}

interface PostalCode {
  city: string
  country: string
  id: ID
  postalCode: string
  state: string
}

export default function PostalCodeLookup(props: Props) {
  const defaultFields = {
    city: props.city ? props.city : '',
    country: props.country ? props.country : '',
    id: '',
    postalCode: props.postalCode ? props.postalCode : '',
    state: props.state ? props.state : '',
  }
  const [addressFields, setAddressFields] = React.useState<PostalCode>(defaultFields)
  const [postalCodes, setPostalCodes] = React.useState<PostalCode[]>([])
  const [postalCode, setPostalCode] = React.useState(addressFields.postalCode)
  const [prevFields, setPrevFields] = React.useState<Partial<PostalCode>>({})
  const [loading, setLoading] = React.useState(false)
  const [prevBlurVals, setPrevBlurVals] = React.useState<PostalCode>(addressFields)

  React.useEffect(() => {
    // Currently used to make sure each field gets updated with the trimmed value
    if (!shallowEqual(prevBlurVals, defaultFields)) {
      setPrevBlurVals(defaultFields)
      setAddressFields(defaultFields)
      setPostalCode(defaultFields.postalCode)
    }
    // if (postalCode !== prevBlurVals.postalCode) {
    //   setPostalCode(prevBlurVals.postalCode)
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevBlurVals, defaultFields])

  const getPostalResponse = async (value: string) => {
    const sanitizedValue = value.toUpperCase().trim().replace(/\s/g, '')
    if (value.length < 5) {
      setAddressFields({ ...addressFields, ...prevFields, postalCode: sanitizedValue })
      return setPostalCodes([])
    }
    try {
      setLoading(true)
      const response = await getPostalCodeRequest(sanitizedValue)
      if (!Array.isArray(response)) {
        setAddressFields({ ...addressFields, ...prevFields, postalCode: sanitizedValue })
        return setPostalCodes([])
      }

      // TODO: if we fix the data itself, then remove this conversion on the frontend
      // Convert to title case, we should eventually do this on the backend
      response.forEach(
        (postalCodeVal) => (postalCodeVal.city = startCase(toLower(postalCodeVal.city))),
      )

      setPostalCodes(response)

      const existingCityIndex = response.findIndex(
        (element) => element.city === addressFields.city,
      )
      const cityIndex = existingCityIndex > 0 ? existingCityIndex : 0
      setAddressFields({
        ...addressFields,
        ...response[cityIndex],
        postalCode: value,
      })
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    getPostalResponse(addressFields.postalCode)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const options = postalCodes.map((postalCodeVal) => {
    return { name: postalCodeVal.city, value: postalCodeVal.city }
  })

  const handleBlur = () => {
    props.onChange(addressFields)
    setPrevBlurVals(addressFields)
  }

  const handleCityCreate = (newCity: string) => {
    const updatedAddressFields = { ...addressFields, city: newCity }
    props.onChange(updatedAddressFields)
    options.push({ name: newCity, value: newCity })
    setAddressFields(updatedAddressFields)
    setPrevBlurVals(updatedAddressFields)
  }

  const handleCitySelect = (newCity: string) => {
    const updatedAddressFields = { ...addressFields, city: newCity }
    props.onChange(updatedAddressFields)
    setAddressFields(updatedAddressFields)
    setPrevBlurVals(updatedAddressFields)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setAddressFields({ ...addressFields, [name]: value })
    setPrevFields({ ...addressFields, [name]: value })
  }

  const handlePostalChange = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      setPostalCode(value)
      // Remove white space and hyphens from postal code
      getPostalResponse(value.replace(/[-\s]/g, ''))

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [addressFields, postalCode],
  )

  function renderCity() {
    return (
      <Input
        name="city"
        onBlur={handleBlur}
        onChange={handleInputChange}
        placeholder="City"
        type="text"
        value={addressFields.city}
      />
    )
  }

  function renderDropdown() {
    return (
      <FuzzySelect
        name="city"
        options={options}
        createLabel="use custom city"
        value={addressFields.city}
        searchPlaceholder="Find or enter a city"
        onCreate={handleCityCreate}
        onSelect={handleCitySelect}
        valueTemplate={
          <CitySelectValue
            {...{ placeholder: 'Select a city', value: addressFields.city }}
          />
        }
      />
    )
  }

  return (
    <Wrapper>
      <div>
        <PostalCodeInput
          name="postalCode"
          type="text"
          placeholder="Postal Code"
          onChange={handlePostalChange}
          onBlur={handleBlur}
          value={postalCode}
        />
        {loading && (
          <Loader
            style={{
              height: '18px',
              left: '130px',
              top: '11px',
              width: '18px',
            }}
          />
        )}
      </div>
      <CityStateCountryWrapper>
        <CityStateWrapper>
          {postalCodes.length > 1 ? renderDropdown() : renderCity()}
          <StateField
            name="state"
            onBlur={handleBlur}
            onChange={handleInputChange}
            placeholder="State"
            type="text"
            value={addressFields.state}
          />
        </CityStateWrapper>
        <Input
          name="country"
          onBlur={handleBlur}
          onChange={handleInputChange}
          placeholder="Country"
          type="text"
          value={addressFields.country}
        />
      </CityStateCountryWrapper>
    </Wrapper>
  )
}
