import ReactDom from 'react-dom'

/**
 * Portal Helper
 * A collection of helper functions for React 16 Portals.
 * The default export should be all that is needed.
 */

const isTesting = process.env.NODE_ENV === 'test'

/**
 * The root dom element that is used to render portals.
 * @type {HTMLElement | null}
 */
export const portalRoot =
  isTesting ?
    document.createElement('div') :
    document.getElementById('portal-root')

/**
 * The collection of portals that have been mounted. Used to determine if the
 * dom node should be appended or removed.
 * @type {Array}
 */
const MOUNTED_PORTALS = []

/**
 * Mounts the given dom to the portal-root.
 * @param domToMount
 * @returns {HTMLElement}
 */
const mountPortal = (domToMount) => {
  portalRoot.appendChild(domToMount)
  MOUNTED_PORTALS.push(domToMount)
  return domToMount
}

/**
 * Unmounts the given dom from the portal-root
 * @param index
 * @returns {function(*=)}
 */
const unmountPortal = (index) =>
  (domToUnmount) => {
    portalRoot.removeChild(domToUnmount)
    MOUNTED_PORTALS.splice(index)
    return null
  }

/**
 * Mounts or un mounts the given dom.
 * If domOrString is a string, it will create an element with the string.
 * If domOrString is a dom, it will use that dom.
 * @param {HTMLElement | string} domOrString
 * @returns {HTMLElement | null}
 */
const portalHelper = (domOrString) => {
  const node =
    typeof domOrString === 'string' ?
      document.createElement(domOrString) :
      domOrString
  const index = MOUNTED_PORTALS.indexOf(node)
  const fn = index === -1 ? mountPortal : unmountPortal(index)
  // No portals in test env.
  if (isTesting) { return null }
  return fn(node)
}

/**
 * Uses ReactDom.createPortal to create the portal for the component.
 * If the enviroment is test, the children are returned.
 * @param children
 * @param container
 * @returns {*}
 */
export const createPortal = (children, container) => {
  // No portals in test env.
  if (isTesting) { return children }
  return ReactDom.createPortal(children, container)
}

export default portalHelper