import * as React from 'react'
import { connect } from 'react-redux'

import * as helpTopicActions from 'happitu/src/actions/helpTopicActions'
import * as helpTopicPageActions from 'happitu/src/actions/helpTopicPageActions'
import { getWorkflows } from 'happitu/src/actions/workflowActions'
import createContext, {
  ContextProviderProps,
  mapGlobalStateToProps,
} from 'happitu/src/createRelayContext'
import createReducer, {
  BoundActionTypes,
  createContextProvider,
  initialState,
} from 'happitu/src/createRelayReducer'
import {
  HelpTopicsState,
  OptionalHelpTopicsState,
} from 'happitu/src/types/helpTopicsContext'

const actions = {
  ...helpTopicActions,
  ...helpTopicPageActions,
  getWorkflows,
}

type ContextActions = typeof actions
type ContextProps = keyof (HelpTopicsState & ContextActions)
type AvailableContextProps = HelpTopicsState & BoundActionTypes<typeof actions>
export type ComponentProps<S extends ContextProps> = Pick<AvailableContextProps, S>

const { reducer, useActions } = createReducer<HelpTopicsState, {}>(
  {},
  {
    relations: {
      helpTopicPages: [{ key: 'helpTopicId', belongsTo: 'helpTopics' }],
    },
  },
)

const boundHandlers = useActions(actions)
const HelpTopicsContext = createContext()

export const Provider = connect(mapGlobalStateToProps(['users']))(
  (props: ContextProviderProps) => {
    const [state, dispatch] = React.useReducer(reducer, initialState)
    const handlers = boundHandlers(dispatch)

    return (
      <HelpTopicsContext.Provider
        value={{ handlers, state: { ...props._reduxState, ...state } }}
      >
        {props.children}
      </HelpTopicsContext.Provider>
    )
  },
)

/**
 * Use this to safely bind to the context.
 */
export const useHelpTopicsContext = createContextProvider<
  HelpTopicsState,
  OptionalHelpTopicsState,
  ContextActions
>(HelpTopicsContext)
