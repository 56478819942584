import { css, Interpolation } from 'styled-components'

import theme, { anim } from 'theme'

export const media = {
  handheld: (
    first: TemplateStringsArray,
    ...interpolations: Array<Interpolation<any>>
  ) => css`
    @media screen and (max-width: 505px) {
      ${ css(first, interpolations) }
    }
  `,

  tablet: (
    first: TemplateStringsArray,
    ...interpolations: Array<Interpolation<any>>
  ) => css`
    @media screen and (max-width: 1024px) {
      ${ css(first, interpolations) }
    }
  `,

  small: (
    first: TemplateStringsArray,
    ...interpolations: Array<Interpolation<any>>
  ) => css`
    @media screen and (max-width: 1200px) {
      ${ css(first, interpolations) }
    }
  `,

  medium: (
    first: TemplateStringsArray,
    ...interpolations: Array<Interpolation<any>>
  ) => css`
    @media screen and (max-width: 1505px) {
      ${ css(first, interpolations) }
    }
  `,

  custom: (maxWidth: string) => (
    first: TemplateStringsArray,
    ...interpolations: Array<Interpolation<any>>
  ) => css`
    @media screen and (max-width: ${ maxWidth }) {
      ${ css(first, interpolations) }
    }
  `,
}

export const documentationBreakpoint = css`
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;

  ${ media.custom('1500px')`
    margin-left: ${ theme('grid.gutter') };
    margin-right: ${ theme('grid.gutter') };
    max-width: 100%;
  ` }
`

export const linkVertIndicator = (indColor?: string) => (props: ContextThemeProps) => {
  indColor = indColor || theme('nav.link.active.indicator')(props)
  return css`
    content: '';
    position: absolute;
    top: 50%;
    left: -2px;
    height: 0;
    transition: height ${ anim('speed', 'superFast') };
    transform: translateY(-50%);
    width: 4px;
    border-radius: 10px;
    background: ${ indColor };
    display: block;
  `
}

export const icomoon = css`
  font-family: 'icomoon' !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
`
