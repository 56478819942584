import Store from './createStore'

import * as types from 'happitu/src/constants/ActionTypes'

const randString = () =>
  Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, 14)

const setSetting = (state, payload) =>
  state.set(payload.id, { ...payload, password: randString() })

const setSettings = (state, payload) => {
  const mappedPayload = payload.map((setting) => ({ ...setting, password: randString() }))
  return state.setMany(mappedPayload)
}

export default function workflowSMTPSettingReducer(
  state = new Store(),
  { type, payload },
) {
  switch (type) {
    case types.GET_SMTP_SETTINGS:
      return setSettings(state, payload)
    case types.CREATE_SMTP_SETTING:
    case types.UPDATE_SMTP_SETTING:
    case types.GET_SMTP_SETTING:
      return setSetting(state, payload)
    default:
      return state
  }
}
