import React, { Component } from 'react'

import FieldArray from './FieldArray'

class RadioButtonArray extends Component {
  render () {
    return <FieldArray { ...this.props } type="radio" />
  }
}

export default RadioButtonArray
