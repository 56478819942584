import isHotkey from 'is-hotkey'
import React from 'react'
import styled from 'styled-components'
import useMeasure from 'use-measure'

import { DisplayValue, EditableValue, GhostValue, StyledIcon } from './InlineEdit.styles'

import getStyle from 'happitu/src/getStyle'
import { font } from 'theme'

type CommitTypes = 'Enter' | 'Tab' | 'blur'

interface ClickProps {
  onClick?: () => void
  onDoubleClick?: () => void
}

// prettier-ignore
interface Props {
  // onBlur?: () => void // We might still need this for FieldArrayOption when it gets refactored
  allowBlank?: boolean
  autoEdit?: boolean
  className?: string
  click?: 'single' | 'double'
  icon?: string
  initialValue?: string
  maxLength?: number
  onCommit: (e: { value: string, type?: CommitTypes }) => void
  placeholder?: string // AppLauncher is passing in node
}

const InlineEdit = (
  {
    // onBlur,
    allowBlank = false,
    autoEdit = false,
    className,
    click = 'double',
    icon,
    initialValue = '',
    maxLength = 50,
    onCommit,
    placeholder,
  }: Props,
  ref: React.RefObject<HTMLSpanElement>,
) => {
  const [editing, setEditing] = React.useState(autoEdit)
  const [value, setValue] = React.useState(initialValue)
  const editableValueRef = React.useRef<HTMLInputElement>(null)

  const ghostValueRef = React.useRef<HTMLSpanElement>(null)
  const { width } = useMeasure(ghostValueRef)

  const startEditing = () => {
    setEditing(true)
  }

  const finishEditing = (type: CommitTypes) => {
    if (!allowBlank && value === '') {
      cancelEditing()
    } else {
      // Trim any whitespace on commit.
      const newValue = value.trim()
      setValue(newValue)
      setEditing(false)
      onCommit({ value: newValue, type })
    }
  }

  const cancelEditing = () => {
    setEditing(false)
    setValue(initialValue)
  }

  // TODO: useHotKey
  // If the user starts typing when the DisplayValue is focused, start editing.
  const handleDisplayFieldKeyPress = (e: React.KeyboardEvent<HTMLSpanElement>) => {
    e.preventDefault()
    // NOTE: Enter has a charCode.
    if (e.key === 'Enter') {
      setEditing(true)
    } else if (e.charCode) {
      // Check for printable characters.
      setEditing(true)
      setValue(e.key)
    }
  }

  const handleEditableChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const handleEditableKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation()
    if (isHotkey('Enter', e.nativeEvent)) {
      finishEditing('Enter')
    } else if (isHotkey('Tab', e.nativeEvent)) {
      finishEditing('Tab')
    } else if (isHotkey('Escape', e.nativeEvent)) {
      cancelEditing()
    }
  }

  // Update value on prop change.
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  // NOTE: Currently using this listener to determine if the user clicks outside the active input since onBlur isn't fired in use cases like StepNavItem.
  React.useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        editableValueRef.current &&
        !editableValueRef.current.contains(e.target as HTMLElement)
      ) {
        finishEditing('blur')
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [value])

  const renderDisplayValue = () => {
    const title = `${click === 'single' ? 'Click' : 'Double click'} to edit '${value}'`
    // Dynamically pick the appropriate click handler.
    const clickType = click === 'single' ? 'onClick' : 'onDoubleClick'
    const clickProps: ClickProps = {}
    clickProps[clickType] = startEditing

    return (
      <DisplayValue
        {...clickProps}
        onKeyPress={handleDisplayFieldKeyPress}
        placeholder={placeholder}
        tabIndex={0} // Gives a span the ability to be focused.
        title={title}
      >
        <span>{value}</span>
        {icon && <StyledIcon type={icon} isActive={!!value} />}
      </DisplayValue>
    )
  }

  const renderEditableValue = () => {
    return (
      <EditableValue
        autoFocus
        contentEditable={true}
        data-slate-editor={true} // Needed to embed inputs in slate.
        maxLength={maxLength}
        onChange={handleEditableChange}
        onKeyDown={handleEditableKeyDown}
        placeholder={placeholder}
        ref={editableValueRef}
        type="text"
        value={value}
        width={Number.parseInt(width.toFixed(0))}
      />
    )
  }

  return (
    <span className={className} ref={ref}>
      {editing ? renderEditableValue() : renderDisplayValue()}
      <GhostValue ref={ghostValueRef}>{value || placeholder}</GhostValue>
    </span>
  )
}

export default styled(React.forwardRef(InlineEdit))`
  color: ${getStyle('text-default-color')};
  display: inline-block;
  font-size: ${font('size', 'base')};
  font-weight: ${font('weight', 'base')};
  min-width: 20px;
  vertical-align: middle;
`
