import { rgba } from 'polished'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import Icon from 'happitu/src/components/Icon'
import BaseLoader from 'happitu/src/components/Loaders/BaseLoader'
import { grey, orange } from 'happitu/src/themes/default/colors'
import { weight } from 'happitu/src/themes/default/font'
import { errorMessage } from 'happitu/src/themes/default/zIndexes'

const PILL_HEIGHT = 48

export const Wrapper = styled.div<{ connecting: boolean }>`
  border-radius: ${PILL_HEIGHT / 2}px;
  bottom: 1em;
  font-weight: ${weight.medium};
  opacity: 0.8;
  overflow: hidden;
  padding: 15px 20px;
  position: absolute;
  right: 1em;
  transition: height 200ms, width 200ms, background-color 200ms;
  z-index: ${errorMessage};

  ${Icon} {
    margin-left: 5px;
    margin-right: 13px;
  }

  ${ifProp(
    'connecting',
    css`
      background-color: ${grey.offWhite};
      color: ${grey.dark};
      height: ${PILL_HEIGHT};
      padding-left: 18px;
      width: 165px;
    `,
    css`
      background-color: ${rgba(orange.base, 0.2)};
      color: ${grey.darkest};
      height: 66px;
      width: 400px;
    `,
  )}
`

export const RetryLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
`

export const Loader = styled(BaseLoader)`
  height: 20px;
  width: 20px;
`
