import React, { CSSProperties } from 'react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { Transforms } from 'slate'
import { useSlate } from 'slate-react'
import styled from 'styled-components'

import { DragHandle } from 'happitu/src/components/Buttons'
import getStyle from 'happitu/src/getStyle'
import { metric } from 'theme'

interface Props {
  className?: string
  dragHandleProps?: DraggableProvidedDragHandleProps
  style?: CSSProperties
}

const ElementDragHandle = ({ dragHandleProps, ...props }: Props) => {
  const editor = useSlate()

  return (
    <div
      style={{ userSelect: 'none' }}
      contentEditable={false}
      onPointerDown={() => Transforms.deselect(editor)}
    >
      <DragHandle {...dragHandleProps} className={props.className} style={props.style} />
    </div>
  )
}

export const dragHandleOffset = 0

export default styled(ElementDragHandle)`
  border-radius: 0;
  border-top-left-radius: ${metric('borderRadius')};
  border-bottom-left-radius: ${metric('borderRadius')};
  height: 100%;
  padding: 0 1em;
  position: absolute;
  right: calc(100% + ${dragHandleOffset}em);
  top: 50%;
  transform: translateY(-50%);

  &:hover {
    background: ${getStyle('app-background')};
    background: transparent;
  }

  &:active {
    background: ${getStyle('app-background')};
    background: transparent;
  }
`
