import React from 'react'
import { useSlate } from 'slate-react'
import styled from 'styled-components'

import { ToolbarButton } from './Toolbar.styles'

import Icon from 'happitu/src/components/Icon'
import { isBlockActive, toggleBlock } from 'happitu/src/helpers/editor/formattingHelper'
import { BLOCK_TYPES } from 'happitu/src/types/models/richTextEditor'

interface Props {
  block: BLOCK_TYPES
  icon: string
}

const BlockButton = ({ block, icon }: Props) => {
  const editor = useSlate()

  // Prevent losing editor focus so we keep the selection for toggling the block.
  const handleMouseDown = (e: React.MouseEvent<HTMLSpanElement>) => e.preventDefault()
  const handleMouseUp = () => toggleBlock(editor, block)

  return (
    <ToolbarButton
      active={isBlockActive(editor, block)}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <Icon type={icon} />
    </ToolbarButton>
  )
}

export default styled(BlockButton)``
