import styled, { css } from 'styled-components'
import { ifNotProp, ifProp } from 'styled-tools'

import { Input, InputWrapper } from 'happitu/src/components/Fields/FieldUtils'
import { Button } from 'happitu/src/components/button'
import getStyle from 'happitu/src/getStyle'
import { font } from 'theme'

export const DeleteButton = styled(Button)<{ disabled?: boolean }>`
  font-size: ${font('size', 'large')};
  opacity: 0.3;
  padding: 0 0.25em;
  position: absolute;
  right: 0.5em;

  :hover,
  :active {
    ${ifNotProp(
      'disabled',
      css`
        opacity: 1;
      `,
    )};
  }
`

export const StyledInput = styled(Input)`
  padding-right: 3em;
  text-overflow: ellipsis;
`

export const StyledInputWrapper = styled(InputWrapper)<{
  lastItem?: boolean
  disabled?: boolean
}>`
  transition: all 150ms;

  ${ifProp(
    'lastItem',
    css`
      opacity: 0.5;

      ${ifNotProp(
        'disabled',
        css`
          :hover,
          :focus-within {
            opacity: 1;
          }
        `,
      )};
    `,
  )};
`

export const ProTip = styled.div`
  background: ${getStyle('info-flag-background')};
  border-radius: 2px;
  color: ${getStyle('info-flag-color')};
  font-size: ${font('size', 'small')};
  margin-bottom: 0.5em;
  padding: 0.25em 0.5em;
  width: fit-content;
`
