import { datadogLogs } from '@datadog/browser-logs'
import { differenceInSeconds } from 'date-fns'
import request from 'superagent'

import clientId from 'happitu/src/constants/clientId'
import { happituHeaders } from 'happitu/src/helpers/requestHelpers'
import { apiHost } from 'happitu/src/services'

const TIME_THROTTLE = 60 // Seconds
const AMOUNT_THROTTLE = 5
export type LoggedMessage = Array<string | object | Error | number | null | boolean>

let amountSent = 0
let lastSent: Date | null = null

if (process.env.DATADOG_CLIENT_TOKEN) {
  datadogLogs.init({
    clientToken: process.env.DATADOG_CLIENT_TOKEN,
    forwardErrorsToLogs: true,
    sampleRate: 100,
  })
}

function exceedsThrottle(): boolean {
  const currentDate = new Date()
  amountSent += 1
  if (amountSent > AMOUNT_THROTTLE) {
    if (lastSent && differenceInSeconds(currentDate, lastSent) >= TIME_THROTTLE) {
      amountSent = 0
      lastSent = null
      return true
    } else {
      if (lastSent === null) {
        lastSent = currentDate
      }
      return true
    }
  }
  return false
}

export function sendLog(severity: 'error' | 'info' | 'warn', ...errs: LoggedMessage) {
  if (exceedsThrottle()) {
    return
  }

  let error = {
    message: [],
  } as {
    message: string[]
    stack?: string
  }

  errs.forEach((err) => {
    if (typeof err === 'string') {
      error.message.push(err)
    } else if (err instanceof Error) {
      error = {
        ...error,
        ...err,
        message: [...error.message, err.message],
      }
    } else {
      error.stack = JSON.stringify(err)
    }
  })
  /* NOTE: Evaluating data dog  */
  if (process.env.DATADOG_CLIENT_TOKEN) {
    datadogLogs.logger[severity](error.message.join(' -> '), {
      userAgent: navigator.userAgent,
      stacktrace: error.stack,
    })
  }

  request
    .post(`${apiHost()}/log-error`)
    .withCredentials()
    .use(happituHeaders())
    .set('Client-Session-ID', clientId())
    .send({
      severity: severity,
      message: 'message: ' + error.message.join(' -> '),
      url: window.location.href,
      userAgent: navigator.userAgent,
      stacktrace: error.stack,
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    })
}
