import React from 'react'
import styled from 'styled-components'

import IconButton from './IconButton'

import theme from 'theme'

const EditButton = (props: Omit<React.ComponentPropsWithoutRef<'button'>, 'type'>) => {
  return <IconButton secondary icon="edit" {...props} />
}

export default styled(EditButton)`
  border-radius: ${theme('button.borderRadius')};
`
