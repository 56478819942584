import cookies from 'js-cookie'
import { get } from 'lodash'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import request from 'superagent'

import OfflinePrompt from 'happitu/src/components/OfflinePrompt'
import clientId from 'happitu/src/constants/clientId'
import { _safelyGetCurrentUser } from 'happitu/src/helpers/currentUserHelpers'
import { error } from 'happitu/src/helpers/loggerHelper'
import { happituHeaders } from 'happitu/src/helpers/requestHelpers'
import { apiHost } from 'happitu/src/services'

export interface APIResponseBody {
  team?: TeamRecord[]
}

type RequestMethodType = 'get' | 'put' | 'post' | 'delete'

interface HappituRequest extends request.SuperAgentRequest {
  _data?: object
  _header: object
  _maxRetries?: number
  _originalCallback?: Function
  _query: object
  _callback?: Function
  callback?: Function
  method: 'GET' | 'PUT' | 'POST' | 'DELETE'
}

/**
 * Mounts the password prompt. This component handles error and all.
 */
export const mountPasswordPrompt = (onSubmit: Function) => {
  if (!window.store) {
    throw new Error('Cannot load password prompt. Redux is not in the global context.')
  }

  const container = document.getElementById('password-prompt')
  if (!container) {
    throw new Error('Cannot locate container to mount prompt.')
  }

  const PasswordPrompt = React.lazy(
    () =>
      import(
        /* webpackChunkName: "PasswordPrompt" */ 'happitu/src/modules/PasswordPrompt'
      ),
  )

  ReactDOM.render(
    <Suspense fallback={<div />}>
      <PasswordPrompt onSubmit={onSubmit} email={window.store.getState().user.email} />
    </Suspense>,
    container,
  )
}

/**
 * Unmounts password prompt.
 */
export const unmountPasswordPrompt = () => {
  const node = document.getElementById('password-prompt')
  if (node) ReactDOM.unmountComponentAtNode(node)
}

/**
 * Mounts offline prompt.
 */
export const mountOfflinePrompt = (onRetry: Function) => {
  if (process.env.NODE_ENV === 'test') return
  const container = document.getElementById('reconnect-websockets')
  if (!container) {
    throw new Error('Cannot locate container to unmount prompt.')
  }
  ReactDOM.render(<OfflinePrompt onRetry={onRetry} />, container)
}

/**
 * Unmounts offline prompt.
 */
export const unmountOfflinePrompt = () => {
  const node = document.getElementById('reconnect-websockets')
  if (node) ReactDOM.unmountComponentAtNode(node)
}

let queue: HappituRequest[] = []

const authenticationMiddleware = () => {
  const reconnectHandler = () => {
    return replay()
  }

  /**
   * Replays requests in the queue.
   */
  const replay = () => {
    unmountPasswordPrompt()
    return new Promise((_, reject) => {
      try {
        queue.forEach((req: HappituRequest) => {
          // Clone all the props from the original request.
          request[req.method.toLowerCase() as RequestMethodType](req.url)
            .withCredentials()
            .set(req._header)
            .query(req._query)
            .send(req._data)
            .end((err: request.ResponseError, res: request.Response) => {
              if (err) {
                queue.push(req)
                reject(err)
                return error({
                  message:
                    "We have a problem Huston! I'm unable to re-connect to server!",
                  request: req,
                })
              }
              unmountOfflinePrompt()
              if (typeof req._callback === 'function') {
                req._callback(null, res)
              }
            })
        })
        queue = []
      } catch (e) {
        reject(e)
      }
    })
  }

  const handleSubmit = (auth: object) =>
    request
      .post(apiHost())
      .withCredentials()
      .send(auth)
      .then(() => replay())

  /**
   * Intercepts request and handles unauthorized responses.
   */
  return (req: HappituRequest) => {
    //  Store original callback for later use
    req._originalCallback = req._originalCallback || req.callback
    req.callback = (err: request.ResponseError, res: request.Response) => {
      if (err && !err.status && !res) {
        queue.push(req)
        return mountOfflinePrompt(reconnectHandler)
      }

      // Display password prompt and has original request to the queue.
      if (res.unauthorized && !/current_user$/.test(req.url)) {
        queue.push(req)
        if (window.store) {
          return mountPasswordPrompt(handleSubmit)
        }
      }

      // Fire original callback.
      if (req._originalCallback) {
        req._originalCallback(err, res)
      }
    }
  }
}

export const apiVersionRequest = cookies.get('Happitu-Request-API-Version')

const apiRequest = (
  url: string,
  method: RequestMethodType,
): request.SuperAgentRequest => {
  const req = request[method](`${apiHost()}${url}`)
    .withCredentials()
    .use(authenticationMiddleware())
    .use(happituHeaders())
    .set('Client-Session-ID', clientId())

  const requestApiVersion = get(_safelyGetCurrentUser(), 'systemConfig.apiVersion')
  if (apiVersionRequest || requestApiVersion) {
    req.set('Happitu-Request-API-Version', apiVersionRequest || requestApiVersion)
  }

  return req
}

export default {
  delete: (url: string) => apiRequest(url, 'delete'),
  get: (url: string) => apiRequest(url, 'get'),
  post: (url: string) => apiRequest(url, 'post'),
  put: (url: string) => apiRequest(url, 'put'),
}
