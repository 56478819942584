import request from './request'
import { handleReject, handleSuccess } from './responseHandler'

/**
 * Make a request to the API to fetch workflows. Parameters effect the url params.
 */
export const getWorkflowsRequest = (queryParams = {}) => {
  return new Promise((resolve, reject) => {
    return request
      .get('/workflows')
      .send(queryParams)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r)))
  })
}

interface GetWorkflowResponse {
  authHash: string
  dialpadConfigured?: boolean
  workflows: WorkflowRecord[]
}

export const getWorkflowRequest = (workflowId: ID) => {
  return new Promise<GetWorkflowResponse>((resolve, reject) => {
    return request
      .get(`/workflows/${workflowId}`)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r)))
  })
}

/**
 * Creates a new workflow with given values.
 */
export const createWorkflowRequest = (workflow: Partial<WorkflowRecord>) =>
  new Promise<{ workflows: WorkflowRecord[] }>((resolve, reject) =>
    request
      .post('/workflows')
      .send(workflow)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )

/**
 * Makes a request to the API to update the workflow.
 */
export const updateWorkflowRequest = (workflow: PartialRecord<WorkflowRecord>) => {
  return new Promise((resolve, reject) => {
    return request
      .put(`/workflows/${workflow.id}`)
      .send(workflow)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r)))
  })
}

/**
 * Makes a request to the API to delete the workflow.
 */
export const deleteWorkflowRequest = (id: ID) => {
  return new Promise((resolve, reject) => {
    return request
      .delete(`/workflows/${id}`)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r)))
  })
}

export const archiveWorkflowRequest = (id: ID) => {
  return new Promise((resolve, reject) => {
    return request
      .post(`/workflows/${id}/archive`)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r)))
  })
}

export const reactivateWorkflowRequest = (id: ID, password: string) => {
  return new Promise((resolve, reject) => {
    return request
      .post(`/workflows/${id}/reactivate`)
      .send({ password })
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r)))
  })
}

/**
 * Makes a request to the API to publish the workflow.
 */
export const publishWorkflowRequest = (
  workflowId: ID,
  data?: PartialRecord<WorkflowRecord>,
) => {
  return new Promise((resolve, reject) =>
    request
      .post(`/publish-workflow/${workflowId}`)
      .send(data)
      .then((response) => resolve(handleSuccess(response)))
      .catch((err) => reject(handleReject(err))),
  )
}

export interface FetchWorkflowResponse {
  workflowElements: WorkflowElementRecord[]
  workflows: WorkflowRecord[]
  workflowStepGroups: WorkflowStepGroupRecord[]
  workflowStepGroupSteps: WorkflowStepRecord[]
}

/**
 * Fetch a hydrated workflow version.
 */
export const fetchWorkflowRequest = (workflowId: ID, version: number) => {
  return new Promise<FetchWorkflowResponse>((resolve, reject) => {
    return request
      .get(`/workflow/${workflowId}/${version}`)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r)))
  })
}

export interface CloneParams {
  name: string
  password: string
}

export const cloneWorkflowRequest = (workflowId: ID, params: CloneParams) => {
  return new Promise<FetchWorkflowResponse>((resolve, reject) => {
    return request
      .post(`/clone-workflow/${workflowId}`)
      .send(params)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r)))
  })
}

interface FetchWorkflowPresetResponse {
  workflowPresets: WorkflowPresetRecord[]
  organizations: OrganizationRecord[]
}

export const getWorkflowPresetsRequest = () => {
  return new Promise<FetchWorkflowPresetResponse>((resolve, reject) => {
    return request
      .get(`/workflow-preset`)
      .send()
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r)))
  })
}

interface CreateWorkflowPreset {
  workflowId: ID
  name: string
  password: string
  allowedOrganizationIds: ID[]
}

export const createWorkflowPresetsRequest = (values: CreateWorkflowPreset) => {
  return new Promise<FetchWorkflowPresetResponse>((resolve, reject) => {
    return request
      .post(`/workflow-preset`)
      .send(values)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r)))
  })
}

export const updateWorkflowPresetAllowedOrganizationsRequest = (
  id: ID,
  values: {
    add?: ID[]
    remove?: ID[]
  },
) => {
  return new Promise<FetchWorkflowPresetResponse>((resolve, reject) => {
    return request
      .put(`/workflow-preset/${id}/allowed-organizations`)
      .send(values)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r)))
  })
}

export const getWorkflowPresetRequest = (id: ID) => {
  return new Promise<FetchWorkflowPresetResponse>((resolve, reject) => {
    return request
      .get(`/workflow-preset/${id}`)
      .send()
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r)))
  })
}

export const deleteWorkflowPresetRequest = (id: ID) => {
  return new Promise<FetchWorkflowPresetResponse>((resolve, reject) => {
    return request
      .delete(`/workflow-preset/${id}`)
      .send()
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r)))
  })
}
