import { memo, useContext, useRef, ReactNode, MouseEventHandler } from 'react'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import { MenuListContext } from './context'
import { defineName, MenuComponent } from './utils'

import getStyle from 'happitu/src/getStyle'
import { icomoon } from 'happitu/src/themes/style-utils'

type ItemType = 'radio' | 'checkbox'

interface Props {
  type: ItemType
  href?: string
  disabled?: boolean
  children?: ReactNode
  onSelect?: () => void
  onMouseDown?: MouseEventHandler
  active?: boolean
  index: number
}

const LI = styled.li`
  list-style: none;
  padding: 0.5em 1em;
  outline: none;
  cursor: pointer;
  display: flex;

  ${ifProp(
    'isHovering',
    css`
      background: ${getStyle('selectOption-focused-background')};
      color: ${getStyle('text-default-color')};
    `,
  )}

  ${ifProp(
    'isActive',
    css`
      color: ${getStyle('text-active-color')};
      font-weight: ${getStyle('text-weight-medium')};

      ${ifProp(
        'isHovering',
        css`
          color: ${getStyle('text-active-color')};
        `,
      )}

      &:before {
        ${icomoon}
        content: "\\e90e";
        margin-right: 0.5em;
        display: inline-block;
      }
    `,
  )}
`

const useItemState = (index: number, isDisabled?: boolean) => {
  const ref = useRef<HTMLLIElement>(null)
  const { hoverIndex, setHover } = useContext(MenuListContext)
  const isHovering = hoverIndex === index

  const onBlur = () => {
    return
  }

  const onMouseEnter = () => {
    if (!isDisabled) setHover(index)
  }

  const onMouseLeave = () => {
    return
  }

  return {
    ref,
    isHovering,
    setHover,
    onBlur,
    onMouseEnter,
    onMouseLeave,
  }
}

const getRole = (type: ItemType) => {
  switch (type) {
    case 'radio':
      return 'menuitemradio'
    case 'checkbox':
      return 'menuitemcheckbox'
    default:
      return 'menuitem'
  }
}

const MenuListItem = defineName(
  memo((props: Props) => {
    const { ref, isHovering, onMouseEnter, onMouseLeave } = useItemState(
      props.index,
      props.disabled,
    )

    //const { isActive } = useActiveState(isHovering, props.disabled, ref)

    const isRadio = props.type === 'radio'
    const isCheckBox = props.type === 'checkbox'
    const isChecked = false

    const menuItemProps = {
      onMouseEnter,
      onMouseLeave,
      ref,
      'isHovering': isHovering,
      'tabIndex': isHovering ? 0 : -1,
      'role': getRole(props.type),
      'aria-checked': isRadio || isCheckBox ? isChecked : undefined,
      'aria-disabled': props.disabled || undefined,
      'onClick': props.onSelect,
      'onMouseDown': props.onMouseDown,
      'isActive': props.active,
      // 'onKeyDown': (e: KeyboardEvent<HTMLLIElement>) => {
      //   if (isActiveKey(e.key) && typeof props.onSelect === 'function') {
      //     props.onSelect()
      //   }
      // },
    }

    return <LI {...menuItemProps}>{props.children}</LI>
  }),
  MenuComponent.MenuListItem,
)

export default MenuListItem
