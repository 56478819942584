import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import getStyle from 'happitu/src/getStyle'
import { zIndex } from 'theme'

export const ButtonWrapper = styled.div<{ isDisabled?: boolean }>`
  align-items: center;
  border-radius: 6px;
  color: ${getStyle('toolbar-dark-color')};
  cursor: pointer;
  display: flex;
  height: 2em;
  justify-content: center;
  width: 2em;

  ${ifProp(
    'isDisabled',
    css`
      cursor: default;
      opacity: 0.6;
      user-select: none;
    `,
  )}
`

export const Wrapper = styled.div`
  backdrop-filter: blur(2px);
  background: ${getStyle('appMask-dark-background')};
  cursor: default;
  display: grid;
  grid-template-rows: 0px 1fr;
  height: 100%;
  left: 0;
  overflow: auto;
  overscroll-behavior: contain;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1001;
`

export const PreviewImg = styled.img<{ isZoomed: boolean }>`
  ${ifProp(
    'isZoomed',
    css`
      height: fit-content;
      width: fit-content;
      max-height: unset;
      max-width: unset;
    `,
    css`
      max-height: 80%;
      max-width: 80%;
    `,
  )}

  position: relative;
  z-index: 1;
`

export const ImgContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 1em;
  width: 100%;
`

export const Toolbar = styled.div`
  backdrop-filter: blur(1px);
  background: ${getStyle('toolbar-dark-background')};
  border-radius: 6px;
  bottom: 2em;
  color: ${getStyle('toolbar-dark-color')};
  display: flex;
  left: 50%;
  padding: 0.75em;
  position: fixed;
  transform: translateX(-50%);
  user-select: none;
  width: fit-content;
  z-index: ${zIndex('modalControls')};

  > :not(:first-child) {
    margin-left: 1em;
  }
`

export const PaginationValues = styled.div`
  align-items: center;
  display: flex;
  height: 2em;
  white-space: nowrap;
`
