import request from './request'
import { handleReject, handleSuccess } from './responseHandler'

interface CreateInteractionResponse {
  ticketInteractions: TicketInteractionRecord[]
}

interface UpdateInteractionResponse extends CreateInteractionResponse {
  tickets: TicketRecord[]
}

// Creates a new interaction for given ticket id.
export const createInteractionRequest = (ticketId: ID, workflowId: ID) => {
  return new Promise<CreateInteractionResponse>((resolve, reject) => {
    return request.post('/ticket-interactions')
      .send({
        ticketId,
        workflowId,
      })
      .then(r => resolve(handleSuccess(r)))
      .catch(r => reject(handleReject(r)))
  })
}

// Updates a ticket interaction.
export const updateInteractionRequest = (interaction: PartialRecord<TicketInteractionRecord>) => {
  return new Promise<UpdateInteractionResponse>((resolve, reject) => {
    return request.put(`/ticket-interactions/${interaction.id}`)
      .send(interaction)
      .then(r => resolve(handleSuccess(r)))
      .catch(r => reject(handleReject(r)))
  })
}
