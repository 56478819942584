import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import Loader from 'happitu/src/components/button/loader'
import getStyle from 'happitu/src/getStyle'
import { anim } from 'theme'

const handleMetric = 24

const sharedStyle = css`
  bottom: 0;
  color: ${getStyle('text-default-color')};
  flex-shrink: 1;
  font-size: 13px;
  font-weight: normal;
  overflow: hidden;
  padding: 3px 0;
  position: absolute;
  text-align: center;
  top: 0;
  transition: transform ${anim('speed', 'normal')};
  width: ${handleMetric * 2}px;
  white-space: nowrap;
`

export const Handle = styled.div`
  background: ${getStyle('toggle-handle-background')};
  border-radius: ${handleMetric / 2}px;
  border: 1px solid ${getStyle('dark-border-color')};
  bottom: 0;
  left: ${handleMetric}px;
  position: absolute;
  top: 0;
  transform: translateX(-100%);
  transition: transform ${anim('speed', 'normal')},
    border-color ${anim('speed', 'normal')};
  width: ${handleMetric}px;
`

/* eslint-disable max-len */
export const Inner = styled.div`
  -webkit-backface-visibility: hidden;
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
  background: ${getStyle('toggle-default-background')};
  border: 1px solid ${getStyle('dark-border-color')};
  border-radius: ${handleMetric / 2}px;
  height: ${handleMetric}px;
  overflow: hidden;
  position: relative;
  width: ${handleMetric * 2}px;
`
/* eslint-enable-disable max-len */

export const Label = styled.span`
  color: ${getStyle('text-sub-color')};
  cursor: pointer;
  flex-grow: 1;

  &:last-child {
    margin-left: 0.75em;
  }

  &:first-child {
    margin-right: 0.75em;
  }
`

export const On = styled.div`
  ${sharedStyle};
  background: ${getStyle('toggle-active-background')};
  left: 0;
  transform: translateX(-100%);
`

export const Switch = styled.div<{ isLoading: boolean }>`
  border-radius: 20px;
  cursor: pointer;
  padding: 0;
  position: relative;

  ${ifProp(
    'isLoading',
    css`
      opacity: 0.5;
    `,
  )};

  &:last-child {
    margin-right: 10px;
  }
`

export const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    ${On} {
      transform: translateX(0);
    }

    ${Handle} {
      border-color: ${getStyle('toggle-active-background')};
      transform: translateX(0);
    }
  }
`

export const Wrapper = styled.label`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  user-select: none;
`

const uncheckedPos = handleMetric / 2 - 3
const checkedPos = handleMetric + (handleMetric / 2 - 3)
export const SwitchLoader = styled(Loader)<{ checked?: boolean }>`
  height: 6px;
  margin-right: 0;
  transition: left ${anim('speed', 'normal')};
  width: 6px;

  ${ifProp(
    'checked',
    css`
      left: ${checkedPos}px;
    `,
    css`
      left: ${uncheckedPos}px;
    `,
  )};

  :before {
    border: 1px solid ${getStyle('text-default-color')};
    height: 4px;
    width: 4px;
  }
`
