import { omit } from 'lodash'
import React from 'react'

import { LineInput } from './AddressField.styles'

interface Props extends OmitProps<React.InputHTMLAttributes<HTMLInputElement>, 'onBlur'> {
  attribute: string
  onBlur: (attribute: string, value: string) => void
}

function AddressLine({ onBlur, ...props }: Props) {
  const [value, setValue] = React.useState<string>(props.defaultValue as string)
  const [prevVal, setPrevVal] = React.useState<string>(value)

  React.useEffect(() => {
    if (prevVal !== props.defaultValue) {
      setValue(props.defaultValue as string)
    }
  }, [prevVal, props.defaultValue])

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    onBlur(props.attribute, e.target.value)
    setPrevVal(e.target.value)
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValue(e.target.value)

  return (
    <LineInput
      {...omit(props, 'defaultValue')}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  )
}

export default AddressLine
