import { last } from 'lodash'
import React from 'react'

import DateSelect from 'happitu/src/components/Fields/DateSelect'
import { formatDateSafely } from 'happitu/src/components/Fields/DateSelect/DateSelect'
import { FieldFigure, FieldWrapper } from 'happitu/src/components/Fields/FieldUtils'
import { FlexRow } from 'happitu/src/components/_DEPRECATED/Flex'
import { SHORT_HAND_DATE_FORMAT } from 'happitu/src/helpers/dateHelpers'
import { DatePickerDisplayProps } from 'happitu/src/types/workflowElements'

const DatePicker = (props: DatePickerDisplayProps) => {
  const { value, attributes, onBlur, onChange, id, errors } = props
  const date = !!value ? value : null
  const lastError = last(errors)

  const handleSelect = (date: Date | null | string) => {
    const newValue = !!date
      ? date instanceof Date
        ? formatDateSafely(date, SHORT_HAND_DATE_FORMAT)
        : date
      : ''
    onBlur([newValue], id)
    onChange(newValue, id)
  }

  return (
    <FieldWrapper invalid={!!lastError}>
      <FlexRow>
        <FlexRow>
          <DateSelect
            label={attributes.label}
            maxDate={null}
            minDate={null}
            months={1}
            onSelect={handleSelect}
            required={attributes.required}
            style={{ paddingTop: '0.75em', paddingBottom: '0.75em' }}
            value={date}
          />
        </FlexRow>
      </FlexRow>

      {!!lastError && <FieldFigure value={lastError} />}
    </FieldWrapper>
  )
}

export default DatePicker
