import { Editor, NodeEntry, Element, Node, Transforms, Point, Range } from 'slate'

import {
  getNextBlockNode,
  getPreviousBlockNode,
} from 'happitu/src/components/RichTextEditor/editorHelpers'
import {
  getNextOLSibling,
  getOLStartsAt,
  removeAllMarks,
  setBlockType,
  unindentLine,
  updateNextOrderedList,
} from 'happitu/src/helpers/editor/formattingHelper'
import { LIST_TYPES } from 'happitu/src/types/models/richTextEditor'
import { ElementType, OrderedListElement } from 'happitu/src/types/slate-types'

const withListItems = (editor: Editor) => {
  const { normalizeNode, deleteBackward, insertBreak } = editor

  editor.insertBreak = () => {
    const currentNode = Editor.above(editor, {
      match: (n) => Editor.isBlock(editor, n),
    })
    const block = currentNode ? currentNode[0] : undefined

    if (
      block &&
      Element.isElement(block) &&
      (block.type === ElementType.OrderedList || block.type === ElementType.UnorderedList)
    ) {
      if (Node.string(block) === '') {
        // When pressing enter on an empty list item, unindent if possible. Otherwise, convert to a paragraph.
        if (currentNode && block.indentLevel > 0) {
          unindentLine(editor, currentNode as NodeEntry<typeof block>)
        } else if (currentNode && block.indentLevel === 0) {
          setBlockType(editor, ElementType.Paragraph)
        }
      } else {
        Transforms.splitNodes(editor, { always: true })
        removeAllMarks(editor)
      }
      return
    }

    insertBreak()
  }

  // Handle backspace behavior.
  editor.deleteBackward = (...args) => {
    const { selection } = editor

    // Check that the selection is not a range.
    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        match: (n) => Editor.isBlock(editor, n),
      })

      if (match) {
        // Get the block and path of the current selection.
        const [block, path] = match
        // Get the starting point of the selection.
        const start = Editor.start(editor, path)

        // Check if the user trying to backspace from the start of block that isn't a paragraph.
        if (Element.isElement(block) && Point.equals(selection.anchor, start)) {
          if (LIST_TYPES.includes(block.type)) {
            // If the user is backspacing at the start of a list item, convert to a paragraph.
            setBlockType(editor, ElementType.Paragraph)
          } else {
            // Otherwise if the block isn't empty, handle delete as normal.
            deleteBackward(...args)
          }
          return
        }
      }

      // Fallback on default behavior.
      deleteBackward(...args)
    }
  }

  // eslint-disable-next-line complexity
  editor.normalizeNode = (entry: NodeEntry<Node>) => {
    const [node, path] = entry
    const prevBlockNode = getPreviousBlockNode(editor, path)
    const nextBlockNode = getNextBlockNode(editor, path)

    // Handle cases where a given list gets split into multiple lists.
    if (
      Element.isElement(node) &&
      node.type !== ElementType.OrderedList &&
      !!nextBlockNode &&
      Element.isElement(nextBlockNode[0]) &&
      nextBlockNode[0].type === ElementType.OrderedList
    ) {
      // TODO: Figure out method to guarantee indentLevel exists on node. We could check if node is of any of the types that have indentLevel.
      const indentLevel = (node as any)?.indentLevel ?? 0
      updateNextOrderedList(
        editor,
        nextBlockNode as NodeEntry<OrderedListElement>,
        indentLevel,
        0,
      )
      // Handle a specific edge case where you split the list between a parent and its children - make sure the siblings get updated.
      if (nextBlockNode[0].indentLevel > indentLevel) {
        const nextSibling = getNextOLSibling(
          editor,
          nextBlockNode as NodeEntry<OrderedListElement>,
          indentLevel,
        )
        if (nextSibling) {
          Transforms.setNodes(
            editor,
            { startsAt: 1 },
            {
              at: nextSibling[1],
              match: (n) => Element.isElement(n) && n.type === ElementType.OrderedList,
            },
          )
        }
      }
    }

    // Handle normalization for ordered lists. We're using the startsAt attribute to maintain the proper order.
    if (Element.isElement(node) && node.type === ElementType.OrderedList) {
      const indentLevel = node.indentLevel
      const startsAt = node.startsAt

      // Check if the current node is already in a list.
      if (
        prevBlockNode &&
        Element.isElement(prevBlockNode[0]) &&
        prevBlockNode[0].type === ElementType.OrderedList
      ) {
        // If the previous node has the same indentLevel.
        if (prevBlockNode[0].indentLevel === indentLevel) {
          const newStartsAt = prevBlockNode[0].startsAt + 1
          // Check that the current node has a startsAt of the previous startsAt + 1
          if (startsAt !== newStartsAt) {
            Transforms.setNodes(
              editor,
              { startsAt: newStartsAt },
              {
                at: path,
                match: (n) => Element.isElement(n) && n.type === ElementType.OrderedList,
              },
            )
          }
          if (nextBlockNode) {
            updateNextOrderedList(
              editor,
              nextBlockNode as NodeEntry<OrderedListElement>,
              indentLevel,
              newStartsAt,
            )
          }
        } else if (prevBlockNode[0].indentLevel < indentLevel) {
          // Reset the numbering if the previous node has a smaller indent level (therefore an ancestor).
          const newStartsAt = 1
          if (startsAt !== newStartsAt) {
            Transforms.setNodes(
              editor,
              { startsAt: newStartsAt },
              {
                at: path,
                match: (n) => Element.isElement(n) && n.type === ElementType.OrderedList,
              },
            )
          }
          if (
            nextBlockNode &&
            Element.isElement(nextBlockNode[0]) &&
            nextBlockNode[0].type === ElementType.OrderedList
          ) {
            updateNextOrderedList(
              editor,
              nextBlockNode as NodeEntry<OrderedListElement>,
              indentLevel,
              newStartsAt,
            )
          }
        } else if (prevBlockNode[0].indentLevel > indentLevel) {
          const newStartsAt = getOLStartsAt(editor, prevBlockNode, indentLevel)
          if (startsAt !== newStartsAt) {
            Transforms.setNodes(
              editor,
              { startsAt: newStartsAt },
              {
                at: path,
                match: (n) => Element.isElement(n) && n.type === ElementType.OrderedList,
              },
            )
          }
          if (
            nextBlockNode &&
            Element.isElement(nextBlockNode[0]) &&
            nextBlockNode[0].type === ElementType.OrderedList
          ) {
            updateNextOrderedList(
              editor,
              nextBlockNode as NodeEntry<OrderedListElement>,
              indentLevel,
              newStartsAt,
            )
          }
        }
      } else {
        // TODO: Maybe handle starting lists at a specific number based on user input.
        // If the node is the first item in the list, make sure it starts at 1.
        const newStartsAt = 1
        if (startsAt !== newStartsAt) {
          Transforms.setNodes<OrderedListElement>(
            editor,
            { startsAt: newStartsAt },
            {
              at: path,
              match: (n) => Element.isElement(n) && n.type === ElementType.OrderedList,
            },
          )
        }
        if (
          nextBlockNode &&
          Element.isElement(nextBlockNode[0]) &&
          nextBlockNode[0].type === ElementType.OrderedList
        ) {
          updateNextOrderedList(
            editor,
            nextBlockNode as NodeEntry<OrderedListElement>,
            indentLevel,
            newStartsAt,
          )
        }
      }
      // If the current list item has children
      if (
        nextBlockNode &&
        Element.isElement(nextBlockNode[0]) &&
        nextBlockNode[0].type === ElementType.OrderedList &&
        nextBlockNode[0].indentLevel > indentLevel
      ) {
        const nextSibling = getNextOLSibling(
          editor,
          entry as NodeEntry<OrderedListElement>,
          indentLevel,
        )
        if (nextSibling) {
          const newStartsAt = startsAt + 1
          // Make sure the next sibling gets updated, even if siblings are separated by children.
          if (nextSibling[0].startsAt !== newStartsAt) {
            Transforms.setNodes(
              editor,
              { startsAt: newStartsAt },
              {
                at: nextSibling[1],
                match: (n) => Element.isElement(n) && n.type === ElementType.OrderedList,
              },
            )
          }
        }
      }
    }
    normalizeNode(entry)
  }
  return editor
}

export default withListItems
