import { css } from 'styled-components'

import getStyle from 'happitu/src/getStyle'

export interface BorderProps {
  border?: boolean | 'bottom' | 'top' | 'left' | 'right' | 'red'
}

export const borderMixin = css<BorderProps>`
  ${(props) => {
    if (props.border === 'bottom') {
      return css`
        border-bottom: 1px solid ${getStyle('border-color')};
      `
    } else if (props.border === 'top') {
      return css`
        border-top: 1px solid ${getStyle('border-color')};
      `
    } else if (props.border === 'right') {
      return css`
        border-right: 1px solid ${getStyle('border-color')};
      `
    } else if (props.border === 'left') {
      return css`
        border-left: 1px solid ${getStyle('border-color')};
      `
    }

    if (props.border === 'red') {
      return css`
        border: 1px solid ${getStyle('button-warn-hover-background')};
      `
    }

    if (!!props.border) {
      return css`
        border: 1px solid ${getStyle('border-color')};
      `
    }

    return css``
  }}
`
