import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { ifProp, prop, switchProp } from 'styled-tools'

import getStyle from 'happitu/src/getStyle'
import { font } from 'theme'

interface Props {
  as?: 'h1' | 'h2' | 'h3' | 'p' | 'sub'
  bold?: boolean
  italic?: boolean
  align?: 'center' | 'left' | 'right'
  color?: 'default' | 'sub' | 'light'
  size?: 'medium'
  children: ReactNode
  title?: string
}

const alignMixin = css`
  text-align: ${prop('align', 'left')};
`

const colorMixin = css`
  ${switchProp('color', {
    default: css`
      color: ${getStyle('text-default-color')};
    `,
    sub: css`
      color: ${getStyle('text-sub-color')};
    `,
    light: css`
      color: ${getStyle('text-light-color')};
    `,
  })}
`

const sizeMixin = css`
  ${switchProp('size', {
    medium: css`
      font-size: ${font('size', 'medium')};
    `,
  })}
`

const h1 = css`
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 1.1em;
`

const h2 = css`
  font-size: 1.6rem;
  line-height: 1.1em;
`

const h3 = css`
  font-size: 1.3rem;
  line-height: 1.1em;
`

const sub = css`
  font-size: 0.8em;
`

const Text = styled.p<Props>`
  ${alignMixin}
  ${colorMixin}
  ${sizeMixin}
  margin-bottom: 0;

  ${ifProp(
    'bold',
    css`
      font-weight: 600;
    `,
  )}

  ${switchProp(
    'as',
    {
      h1,
      h2,
      h3,
      sub,
    },
    css`
      line-height: 1.3em;
    `,
  )}
`

export default Text
