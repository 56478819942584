import memoizeOne from 'memoize-one'

import defaultTheme from 'happitu/src/themes/defaultTheme'
import logixxTheme from 'happitu/src/themes/logixxTheme'

export const isWhiteLabel = /guide\.logixx\.io/i.test(window.location.href)

export const getBrandName = () => {
  return isWhiteLabel ? 'Logixx Guide' : 'Happitu'
}

export const getSupportEmail = () => {
  return isWhiteLabel ? 'support@logixx.atlassian.net' : 'support@happitu.com'
}

export const getWorkflowEmailDomain = () => {
  return isWhiteLabel ? 'logixx.io' : 'happitu.com'
}

export const getThemeKeyFromURL = memoizeOne(() => {
  return isWhiteLabel ? 'logixxTheme' : 'defaultTheme'
})

const themeMap = {
  defaultTheme,
  logixxTheme,
}

export const getTheme = () => {
  const themeKey = getThemeKeyFromURL()
  return themeMap[themeKey]
}
