import React from 'react'
import { connect } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import getTheme from 'happitu/src/getTheme'

interface Props {
  children?: React.ReactNode
}

const HappituThemeProvider = ({ children }: Props) => {
  // TODO: Pulling the theme from the user record on load may cause an initial flash. Use local storage.
  const theme = getTheme()
  return (
    <ThemeProvider theme={theme}>
      <>{children}</>
    </ThemeProvider>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
})

export default connect(mapStateToProps)(HappituThemeProvider)
