import relay, { RelayDispatch } from './relay'

import { getParams, WorkTicketRouteParams } from 'happitu/src/helpers/routeHelpers'
import {
  createTicketRequest,
  getTicketRequest,
  getTicketsRequest,
  updateTicketRequest,
} from 'happitu/src/services/happitu-api/ticketsService'

// Get many tickets...
export function getTickets(criteria: Partial<PaginationObject> = {}, limit = 50) {
  return async (dispatch: RelayDispatch<typeof getTicketsRequest>) => {
    const response = await getTicketsRequest({
      ...criteria,
      limit,
      // NOTE: there is a use-case (based on scroll position) that will cause
      // a negative skip to be set. Negative numbers = upset API, so we're
      // overwriting it here.
      // - rwn3 2019/04/24
      skip: criteria.skip && criteria.skip > 0 ? criteria.skip : 0,
    })

    if (!response.pagination) {
      throw new Error("Pagination wasn't provided... I will not compute.")
    }

    relay(dispatch)({
      ...response,
      pagination: {
        ...response.pagination,
        offsetIndex: criteria.offsetIndex || 0,
        skip: criteria.skip,
      },
    })
  }
}

function isInteractionInResponse(ticketInteractions: TicketInteractionRecord[]) {
  const params = getParams<WorkTicketRouteParams>()
  return (
    (params.ticketInteractionId &&
      ticketInteractions.findIndex((ti) => ti.id === params.ticketInteractionId) > -1) ||
    !params.ticketInteractionId
  )
}

// Fetch a ticket...
export function getTicket(ticketId: ID) {
  return async (dispatch: RelayDispatch<typeof getTicketRequest>) => {
    const response = await getTicketRequest(ticketId)

    if (!isInteractionInResponse(response.ticketInteractions)) {
      throw [
        {
          message: `Trying to work ticket ${ticketId} with interaction
          ${getParams<WorkTicketRouteParams>().ticketInteractionId}`,
          type: 'invalid-parameter',
          what: 'id',
        },
      ]
    }
    relay(dispatch)(response)
    return response
  }
}

// Create a ticket...
export function createTicket(ticket: Partial<TicketRecord>) {
  return async (dispatch: RelayDispatch<typeof createTicketRequest>) => {
    const response = await createTicketRequest(ticket)
    relay(dispatch)(response)
    return response
  }
}

// Push ticket updates...
export function updateTicket(updates: PartialRecord<TicketRecord>) {
  return async (dispatch: RelayDispatch<typeof updateTicketRequest>) => {
    const response = await updateTicketRequest(updates.id, updates)
    relay(dispatch)(response)
  }
}
