import { map } from 'lodash'

import { formatDate, relativeDate } from './dateHelpers'
import { arrayToList, getGreetingTime } from './stringHelper'

import { stringifyContactAddress } from 'happitu/src/helpers/contactHelper'
import { findGroup, findStep } from 'happitu/src/helpers/finderHelper'
import {
  getWorkingVersion,
  stringifyWorkflowAddress,
} from 'happitu/src/helpers/workflowHelper'

export const OVERWRITE_PREFIX = /^var/i
const VARIABLE_DELIMITER = ':'
export const DEFAULT_VALUE = '____'
const WORDS = '[\\w]+([\\s-]*[\\w]*)*'
export const VARIABLE_REGEXP = new RegExp(
  `${VARIABLE_DELIMITER}(${WORDS})${VARIABLE_DELIMITER}`,
  'gi',
)

/**
 * Substitute variables in a string
 * @param {Object} variables  The list of available variables
 * @param {String} text       The text to replace.
 * @return {String}
 */
export const substitute = (variables, text, flagUndefinedVariables) =>
  text.replace(VARIABLE_REGEXP, (match, $1) => {
    const matchRegExp = new RegExp(`^${$1}$`, 'i')
    const key = Object.keys(variables).find((v) => matchRegExp.test(v))
    return (
      '_' +
      (variables[key] && variables[key] !== ''
        ? variables[key]
        : flagUndefinedVariables
        ? `__${$1}__`
        : `${$1}`) +
      '_'
    )
  })

/**
 * Builds the variable list from various sources.
 * @param {Object}  sources       The available sources to build the variables from.
 */
export const buildVariableList = (sources = {}, state) => {
  return Object.assign(
    buildSharedVariables(sources, state),
    getStepVariables(sources.steps, state),
  )
}

export const convertLabelToVariable = (label) => {
  return label.replace(/\s/g, '_').toLowerCase()
}

export const stringifyFieldValue = (field) => {
  if (Array.isArray(field)) {
    return arrayToList(field)
  }

  switch (field.component) {
    case 'AddressField':
      return stringifyWorkflowAddress(field.values)
    case 'DatePicker':
      if (!!field.values[0]) {
        return formatDate(field.values[0])
      }
      return
    case 'DateRangePicker':
      return `${formatDate(field.values[0])} – ${formatDate(field.values[1])}`
  }

  if (field && field.values[0]) {
    return field.values[0]
  }
}

/**
 * Gets the variables from input fields in workflow steps.
 * @param {Array}   steps   The array of steps to build from.
 */
const getStepVariables = (interactionSteps = [], state) => {
  const variables = {}

  if (!state) return variables

  interactionSteps.forEach((step) => {
    step.fields?.forEach((field) => {
      if (field.name) {
        const label = convertLabelToVariable(field.name)
        variables[label] = stringifyFieldValue(field)
      }
    })
  })

  return variables
}

/**
 * Builds a list of shared variables from various sources.
 * @param {Object}  sources           The sources to build the list.
 */
export const buildSharedVariables = (sources, state) => {
  return Object.assign(
    buildAgentVariables(sources.user),
    buildGenericVariables(),
    buildWorkflowVariables(sources.workflow),
    buildTicketVariables(sources.ticket, sources.currentInteraction),
    buildContactVariables(sources.contact, state),
    buildVariableOverwrites(sources.overwrites),
  )
}

/**
 * Builds a list of variables from the user object.
 * @param {Object}  user   The user object.
 */
const buildAgentVariables = (user = {}) => {
  // if (!user) return {}
  return {
    agent_full_name: `${user.firstName} ${user.lastName}`,
    agent_first_name: user.firstName,
    agent_last_name: user.lastName,
  }
}

/**
 * Returns a list of variables from the ticket object.
 * @param {Object}  ticket The ticket object.
 */
const buildTicketVariables = (ticket = {}, currentInteraction = {}) => {
  return {
    ticket_number: ticket.ticketNumber ? `#${ticket.ticketNumber}` : null,
    ticket_url: `${process.env.APP_HOST_NAME}/org/${ticket.organizationId}/tickets/${
      ticket.id
    }/work/${currentInteraction.id}/${
      (currentInteraction.workflowSteps?.length || 0) - 1
    }`,
  }
}

/**
 * Returns a list of variables from the workflow object.
 * @param {Object}  workflow The workflow object.
 */
const buildWorkflowVariables = (workflow = {}) => {
  return {
    workflow_name: workflow.name,
    workflow_created_at: relativeDate(workflow.createdAt),
    workflow_updated_at: relativeDate(workflow.updatedAt),
  }
}

/**
 * Builds a list of variables that do not require a source.
 */
const buildGenericVariables = () => ({
  salutation: getGreetingTime(),
})

/**
 * Builds a list of variables based on the contact record.
 * @param {Object} contact The contact object to pull variables from
 * @returns {{}}
 */
export const buildContactVariables = (contact = {}, state) => {
  return Object.assign(
    buildContactDetailVariables(contact.details, state),
    buildContactPhoneVariables(contact.phones),
    buildContactEmailVariables(contact.emails),
    buildContactAddressVariables(contact.addresses),
    buildContactBaseVariables(contact),
  )
}

const buildVariableOverwrites = (overwrites) => {
  const vars = {}
  if (overwrites) {
    for (let overwrite in overwrites) {
      vars[overwrite] = overwrites[overwrite]
    }
  }
  return vars
}

const buildContactBaseVariables = ({ firstName, lastName }) => ({
  contact_first_name: firstName,
  contact_last_name: lastName,
})

/**
 * Builds contact detail variables
 * @param {Object} details The contact details
 * @returns {{}}
 */
const buildContactDetailVariables = (details = {}, state) => {
  const vars = {}

  if (state && state.contactDetailTypes) {
    map(details, (value, key) => {
      const type = state.contactDetailTypes.findById(key)
      if (type) {
        return (vars[`${convertLabelToVariable(type.label)}`] = Array.isArray(value)
          ? value[0]
          : value)
      }
    })
  }

  return vars
}

const buildContactPhoneVariables = (phones) =>
  buildContactSpecialVariables(phones, 'phone')

const buildContactEmailVariables = (emails) =>
  buildContactSpecialVariables(emails, 'email')

const buildContactAddressVariables = (addresses = []) =>
  buildContactSpecialVariables(
    addresses.map(({ type, ...a }) => ({ type, value: stringifyContactAddress(a) })),
    'address',
  )

/**
 * Builds variables for contact phones, emails, and addresses.
 * @param {Object[]} arrayOfTypedObjects Array of items to parse
 * @param {String} baseType              The base type.
 * @returns {{}}
 */
const buildContactSpecialVariables = (arrayOfTypedObjects, baseType) => {
  const vars = {}
  if (!arrayOfTypedObjects) {
    return vars
  }
  arrayOfTypedObjects.map(({ type, value }, index) => {
    vars[`contact_${type.charAt(0).toLowerCase() + type.slice(1)}_${baseType}`] = value
    if (index === arrayOfTypedObjects.length - 1) {
      vars[`contact_${baseType}`] = value
    }
  })
  return vars
}

const genRecord = (...params) => ({ fields: [], record: findStep(...params) })

const pseudoContactRecord = (attributes) => {
  const con = {}
  map(attributes, (outerValue, key) => {
    if (Array.isArray(outerValue)) {
      con[key] = outerValue.map((value) => ({ value, type: value }))
    } else {
      con.details = outerValue
    }
  })
  return con
}

export const getVariableSuggestions = (state, query, limit = 0) => {
  const { workflowId } = state.router.route.params
  const { channels, name } = state.workflows.findById(workflowId)
  const workflow = Object.assign({}, getWorkingVersion(state, workflowId), {
    channels,
    name,
  })

  const groups = workflow.groups.map((groupId) => findGroup(state, groupId))
  const steps = []

  groups.map((group) => group.steps.map((stepId) => steps.push(genRecord(state, stepId))))

  const list = buildVariableList({
    steps,
    user: state.user.profile,
    workflow,
    contact: pseudoContactRecord(state.contactAttributes),
  })

  let variables = map(list, (value, key) => ({ name: key, value: key }))
  if (query) {
    variables = variables.filter((v) => query.test(v.name))
  }

  if (limit) {
    variables = variables.slice(0, limit)
  }

  return variables
}
