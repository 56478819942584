import React from 'react'
import styled from 'styled-components'

import ListSubHeading from './ListSubHeading'

import getStyle from 'happitu/src/getStyle'

interface Props {
  label: string
  children: React.ReactNode
}

const Section = styled.section`
  margin-top: 0.5rem;
  padding-top: 0rem;

  &:not(:first-child) {
    border-top: 1px solid ${getStyle('border-color')};
  }
`

const OptionGroup = (props: Props) => {
  return (
    <Section>
      <ListSubHeading>{props.label}</ListSubHeading>
      {props.children}
    </Section>
  )
}

export default OptionGroup
