import React from 'react'

import { HelpTopicPageRecord } from 'happitu/src/types/models/helpTopics'

interface ContextProps {
  helpTopicPage?: HelpTopicPageRecord | null
  onHelpTopicPageSelect?: (id: ID) => void
}

const defaultContext: ContextProps = {
  helpTopicPage: undefined,
  onHelpTopicPageSelect: undefined,
}

const HelpTopicPageNav = React.createContext(defaultContext)

export default HelpTopicPageNav
