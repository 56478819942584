import * as request from 'superagent'

import apiRequest from './request'
import { handleSuccess } from './responseHandler'

import { getFileUrl } from 'happitu/src/helpers/fileUploadHelpers'
import { lookupMimeType } from 'happitu/src/helpers/mimeLookup'
import { happituHeaders } from 'happitu/src/helpers/requestHelpers'

export const uploadFileRequest = async (
  associatedCollection: string,
  associatedWith: ID,
  file: File,
  progressHandler: (progress: number) => void,
) => {
  const contentType = file.type !== '' ? file.type : lookupMimeType(file.name)
  const createResponse = await apiRequest.post('/file-uploads').send({
    associatedCollection,
    associatedWith,
    name: file.name,
    contentType,
    contentLength: file.size,
  })
  const fileUploadId = createResponse.body.fileUploads[0].id
  const uploadUrl = createResponse.body.url
  await request
    .put(uploadUrl)
    .set('Content-Type', contentType)
    .send(file)
    .on('progress', (e) => e.percent && progressHandler(e.percent))
  const response = await apiRequest
    .put(`/file-uploads/${fileUploadId}`)
    .send({ uploadedAt: new Date().toISOString() })
  return handleSuccess<{
    fileUploads: FileUploadRecord[]
  }>(response)
}

export const getSignedUrl = (fileId: ID) => {
  return new Promise<string>((resolve) =>
    request
      .get(getFileUrl(fileId, { redirect: 'false' }))
      .withCredentials()
      .use(happituHeaders())
      .then(({ body: { url } }) => resolve(url)),
  )
}
