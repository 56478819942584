import { Descendant, Text } from 'slate'
import { RenderElementProps } from 'slate-react'

import Element from '../Element'
import Leaf from '../Leaf'

import { SlateRecordRefs } from 'happitu/src/types/models/richTextEditor'

interface Props extends RenderElementProps {
  collections?: SlateRecordRefs
  fileIds?: ID[]
  node: Descendant
}

// Recursively render Slate nodes.
const Node = ({ node, ...props }: Props) => {
  if (Text.isText(node)) return <Leaf leaf={node}>{node.text}</Leaf>

  const children = node.children.map((n: Descendant, index: number) => (
    <Node {...props} key={index} node={n} />
  ))

  return (
    <Element {...props} isReadOnly={true} element={node}>
      {children}
    </Element>
  )
}

export default Node
