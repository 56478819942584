import update from 'immutability-helper'
import { Dispatch } from 'react'

import { relayError, RelayError } from './relay'

import { APIError } from 'happitu/src/createErrorHandler'

export function clearError(index: number) {
  return async (
    dispatch: Dispatch<RelayError>,
    getState: () => { errors?: APIError[] },
  ) => {
    const { errors } = getState()
    if (errors && index > -1 && index < errors.length) {
      relayError(dispatch)(
        update(errors, {
          $splice: [[index, 1]],
        }),
      )
    }
  }
}
