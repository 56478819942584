import React from 'react'
import { FieldRenderProps } from 'react-final-form'

import { InputWrapper, StyledButton, StyledIcon, Wrapper } from './ColorField.styles'
import { ErrorMessage, FieldFigure, Label } from './FieldUtils'

import { getRandomTagColor } from 'happitu/src/helpers/tagHelper'

interface Props<V = string> extends FieldRenderProps<V, HTMLInputElement> {
  footnote: string
  label: string
  placeholder: string
  required: boolean
  selectColor: Function
}

const ColorField = ({ input, footnote, label, meta, placeholder, required }: Props) => {
  const handleClick = () => {
    if (typeof input.value === 'string') {
      input.onChange(getRandomTagColor())
    }
  }
  return (
    <Wrapper>
      <Label value={label} required={required} />
      <InputWrapper>
        <input {...input} placeholder={placeholder} />
        <StyledButton
          color={input.value}
          onClick={handleClick}
          title="Get a random color."
        >
          <StyledIcon type="replay" />
        </StyledButton>
      </InputWrapper>
      {meta.touched && meta.error ? (
        <ErrorMessage {...meta} />
      ) : (
        <FieldFigure value={footnote} />
      )}
    </Wrapper>
  )
}

export default ColorField
