import React from 'react'
import { Transforms, Editor, Element } from 'slate'
import { useSlate } from 'slate-react'
import { v1 as uuid } from 'uuid'

import { ToolbarButton } from './Toolbar.styles'

import Icon from 'happitu/src/components/Icon'
import { ElementType, HelpTopicActionElement } from 'happitu/src/types/slate-types'

const InsertHelpTopicActionButton = () => {
  const editor = useSlate()

  const handleMouseDown = (e: React.MouseEvent<HTMLSpanElement>) => e.preventDefault()
  const handleMouseUp = () => {
    const currentBlock = Editor.above(editor, {
      match: (n) => Editor.isBlock(editor, n),
    })
    const newNodeId = uuid()
    const nodes: HelpTopicActionElement[] = [
      {
        children: [{ text: '' }],
        label: '',
        nextPageId: null,
        nodeId: newNodeId,
        type: ElementType.HelpTopicAction,
      },
    ]

    const lastNode = Editor.last(editor, [])

    // NOTE: Clicking into an action label textarea is setting the selection offset to 1, even though it's a void node. Haven't seen a documented issue for anything like this in Slack or GitHub.
    // TODO: Reliably reproduce this issue, figure out the root cause, and open a new issue/PR for Slate.
    // This is introducing weird behavior with the insert when an action is selected. The new action should ideally be added after the current action, but it's being added before.
    // My workaround is to set the offset in the selection to 0 in this case.
    // TODO: Figure out the issue and remove the unnecessary logic if we can.
    const newAt =
      editor.selection &&
      currentBlock &&
      Element.isElement(currentBlock[0]) &&
      currentBlock[0].type === ElementType.HelpTopicAction
        ? {
            anchor: { ...editor.selection.anchor, offset: 0 },
            focus: { ...editor.selection.focus, offset: 0 },
          }
        : !editor.selection // NOTE: Keep the below logic. If editor doesn't have focus, insert it just before the last node (should always be an empty paragraph node).
        ? lastNode[1]
        : undefined

    Transforms.insertNodes(editor, nodes, { at: newAt })

    // Only replace the node if it's an empty paragraph. Otherwise, just insert the action.
    if (
      currentBlock &&
      Element.isElement(currentBlock[0]) &&
      currentBlock[0].type === ElementType.Paragraph &&
      Editor.isEmpty(editor, currentBlock[0])
    ) {
      Transforms.removeNodes(editor, { at: currentBlock[1] })
    }

    // Select the new action.
    const [newAction] = Editor.nodes(editor, {
      at: [],
      match: (n) =>
        Element.isElement(n) &&
        n.type === ElementType.HelpTopicAction &&
        n.nodeId === newNodeId,
    })
    Transforms.select(editor, newAction[1])
  }

  return (
    <ToolbarButton onMouseUp={handleMouseUp} onMouseDown={handleMouseDown}>
      <Icon type="arrow-right" size="medium" />
    </ToolbarButton>
  )
}

export default InsertHelpTopicActionButton
