import autobind from 'autobind-decorator'
import classNames from 'classnames'
import { without } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import styles from '../FieldArray.scss'

import Dropdown from './FieldArray/Dropdown'

import {
  FieldFigure,
  FieldWrapper,
  Label,
} from 'happitu/src/components/Fields/FieldUtils'
import RadioButton from 'happitu/src/components/Fields/RadioButton'

export default class FieldArray extends Component {
  static propTypes = {
    arrayLimit: PropTypes.number,
    attributes: PropTypes.object.isRequired,
    defaultValue: PropTypes.array,
    errors: PropTypes.arrayOf(PropTypes.string),
    forceErrors: PropTypes.bool,
    id: PropTypes.string,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['checkbox', 'radio']),
    value: PropTypes.array,
  }

  static defaultProps = {
    type: 'checkbox',
    arrayLimit: 9,
    value: [],
  }

  state = {
    touched: false,
    value: this.props.attributes.defaultValue,
  }

  UNSAFE_componentWillReceiveProps({ value }) {
    if (value !== this.props.value) {
      this.setState({ value })
    }
  }

  @autobind
  handleChange(value) {
    const { id, onBlur, onChange } = this.props
    this.setState({ value, touched: true })
    onBlur(value, id)
    onChange(value, id)
  }

  @autobind
  handleButtonSelect({ target }) {
    const { type } = this.props
    const { value } = this.state
    let nextValue
    let data
    if (type === 'checkbox') {
      if ((value || []).includes(target.value)) {
        nextValue = without(value, target.value)
      } else {
        nextValue = [...(value || []), target.value]
      }
      data = nextValue
    } else {
      data = (value || []).indexOf(target.value) > -1 ? [] : [target.value]
    }

    this.handleChange(data)
  }

  @autobind
  handleOptionSelect(value) {
    this.handleChange(value)
  }

  @autobind
  checkedOption(item) {
    const { defaultValue, value } = this.props

    if (value) {
      if (typeof value === 'object') {
        return value.includes(item.label)
      }
      return value && value === item.label
    }
    if (typeof defaultValue === 'object') {
      return defaultValue.includes(item.label)
    }
    return defaultValue && defaultValue === item.label
  }

  renderOption(item, key, classes) {
    const { type, id } = this.props
    return (
      <li className={classNames(styles.option, classes)} key={key}>
        <label>
          <RadioButton
            onClick={this.handleButtonSelect}
            type={type}
            checked={this.checkedOption(item)}
            name={id}
            value={item.label}
          />
          <div>{item.label}</div>
        </label>
      </li>
    )
  }

  renderOptions() {
    const { options } = this.props.attributes
    const classes = classNames(styles.dropdown, {
      [styles.invalid]:
        (this.props.forceErrors || this.state.touched) && this.props.errors,
    })
    return (
      <ul>{options.map((option, index) => this.renderOption(option, index, classes))}</ul>
    )
  }

  renderDropdown() {
    const { attributes, type, value } = this.props
    // TODO: Enforce unique values for FieldArray options
    // We won't have access to the ID of any value we save, so we're setting the name and value with the label for now
    const options = attributes.options.map(({ label }) => {
      return {
        name: label,
        value: label,
      }
    })

    return (
      <Dropdown
        invalid={!!this.props.errors.length}
        onSelect={this.handleOptionSelect}
        options={options}
        type={type}
        value={value}
      />
    )
  }

  renderFieldFigure() {
    if ((this.props.forceErrors || this.state.touched) && this.props.errors.length > 0) {
      return <FieldFigure value={this.props.errors[0]} />
    }
  }

  render() {
    const { arrayLimit, attributes } = this.props
    return (
      <FieldWrapper className={styles.array} invalid={this.props.errors.length > 0}>
        <Label required={attributes.required} value={attributes.label} />
        {attributes.options.length > arrayLimit
          ? this.renderDropdown()
          : this.renderOptions()}
        {this.renderFieldFigure()}
      </FieldWrapper>
    )
  }
}
