import { error } from './loggerHelper'

import { formatTicketNumber } from 'happitu/src/helpers/ticketBinHelper'

export const CONTACT_DISPLAY_FALLBACK = 'Unknown Contact'

export const ADDRESS_VALUE_INDEX = {
  city: 3,
  country: 6,
  line1: 0,
  line2: 1,
  line3: 2,
  postalCode: 5,
  state: 4,
  type: 7,
} as { [K in keyof Address]: number }

export const convertAddressArrayToObject = (addressArray: string[]) => {
  const address = {} as Address
  Object.keys(ADDRESS_VALUE_INDEX).forEach((key: keyof Address) => {
    return (address[key] = addressArray[ADDRESS_VALUE_INDEX[key]])
  })
  return address
}

export const getContactName = (
  contact: Partial<ContactRecord> | null,
  defaultValue = CONTACT_DISPLAY_FALLBACK,
) => {
  if (contact?.firstName || contact?.lastName)
    return `${contact?.firstName || ''} ${contact?.lastName || ''}`
  if (contact?.emails?.length) return contact.emails[0].value
  if (contact?.phones?.length) return contact.phones[0].value
  return defaultValue
}

export const getContactNameFromTicket = (
  contactStore: ContactStore,
  ticket: TicketRecord,
) => {
  try {
    const contact = contactStore.findById<ContactRecord>(ticket.contactId)
    return !contact
      ? formatTicketNumber(ticket)
      : getContactName(contact, formatTicketNumber(ticket))
  } catch (e) {
    error('Unable to load contact', e)
    return formatTicketNumber(ticket)
  }
}

export const createContactMethod = (value: string, type = '') => ({
  type,
  value,
})
