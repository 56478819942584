import React from 'react'

import { ClearButton, Label, Heading, Textarea } from './AgentNotes.styles'

import HotkeyFigure from 'happitu/src/components/Layout/HotkeyFigure'
import useHotkey, { Hotkey } from 'happitu/src/hooks/useHotkey'

interface Props {
  defaultValue: string
  onBlur: Function
}

let activeElement: HTMLInputElement

export default function AgentNotes({ defaultValue = '', onBlur }: Props) {
  const [value, setValue] = React.useState(defaultValue)
  const textareaParent = React.useRef<HTMLLabelElement>(null)

  React.useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  useHotkey(
    Hotkey.FocusNotes,
    () => {
      if (textareaParent.current) {
        const textarea = textareaParent.current.querySelector('textarea')
        activeElement = document.activeElement as HTMLInputElement
        textarea?.focus()
      }
    },
    true,
  )

  const handleChange = React.useCallback(
    (e) => {
      setValue(e.target.value)
    },
    [defaultValue],
  )

  const handleBlur = React.useCallback(() => {
    onBlur(value)
  }, [value])

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === Hotkey.Close) {
        if (
          (activeElement && activeElement.tagName.toLowerCase() === 'input') ||
          activeElement.tagName.toLowerCase() === 'textarea'
        ) {
          activeElement.focus()
        } else if (textareaParent.current) {
          const textarea = textareaParent.current.querySelector('textarea')
          textarea?.blur()
        }
      }
    },
    [activeElement],
  )

  const clearNotes = React.useCallback(() => {
    setValue('')
    onBlur('')
  }, [])

  return (
    <Label ref={textareaParent}>
      <Heading>
        Notes
        <HotkeyFigure hotkey={Hotkey.FocusNotes} />
      </Heading>
      <Textarea
        placeholder="Jot down some notes here..."
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={value}
      />
      <div>
        <ClearButton onClick={clearNotes} size="small" visible={value !== ''}>
          Clear Notes
        </ClearButton>
      </div>
    </Label>
  )
}
