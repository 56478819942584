import React, { ComponentProps } from 'react'
import styled from 'styled-components'
import { ifProp, ifNotProp, switchProp } from 'styled-tools'

import Icon from 'happitu/src/components/Icon'
import getStyle from 'happitu/src/getStyle'
import { GeneratedFieldType } from 'happitu/src/helpers/generatedMetadata'
import { color, font } from 'theme'

export interface IconProps {
  color?: 'mint' | 'blue' | 'red' | 'purple' | 'default'
  icon:
    | 'alpha-symbol'
    | 'bin'
    | 'search'
    | 'number-symbol'
    | 'calendar-bold'
    | 'bool'
    | 'tag'
    | 'stopwatch'
}

export const getFieldIconPropsFromFieldName = (fieldName?: string): IconProps => {
  switch (fieldName) {
    case 'CreatedBy':
    case 'UpdatedBy':
    case 'UserID':
    case 'WorkedBy':
      return { icon: 'search', color: 'blue' }
    case 'Active':
      return { icon: 'bool', color: 'purple' }
    case 'TagsTagID':
      return { icon: 'tag', color: 'mint' }
    case 'CreatedAt':
    case 'UpdatedAt':
      return { icon: 'stopwatch', color: 'blue' }
    case 'WorkflowID':
      return { icon: 'alpha-symbol', color: 'blue' }
    default:
      return { icon: 'search', color: 'blue' }
  }
}

export function getFieldIconPropsFromType(
  type: GeneratedFieldType | 'suggester' | 'group',
): IconProps {
  if (type === '[]time' || type === 'time')
    return { color: 'purple', icon: 'calendar-bold' }
  if (type === '[]number' || type === 'number')
    return { color: 'mint', icon: 'number-symbol' }
  if (type === 'bool') return { color: 'red', icon: 'bool' }
  if (type === 'suggester') return { icon: 'search' }
  if (type === 'group') return { icon: 'bin' }

  return { color: 'blue', icon: 'alpha-symbol' }
}

const FieldIcon = ({ color, icon, ...props }: IconProps & ComponentProps<'div'>) => {
  return (
    <div {...props} color={color}>
      <Icon type={icon} />
    </div>
  )
}

export default styled(FieldIcon)`
  align-items: center;
  background: ${switchProp('color', {
    mint: getStyle('iconMint-background'),
    blue: getStyle('iconBlue-background'),
    red: getStyle('iconRed-background'),
    purple: getStyle('iconPurple-background'),
    default: getStyle('iconDefault-background'),
  })};
  border: 1px solid;
  border-color: ${ifNotProp('color', color('border'), 'transparent')};
  border-radius: 4px;
  color: ${ifProp('color', getStyle('pane-background'), color('grey', 'light'))};
  display: flex;
  flex-shrink: 0;
  font-size: ${font('size', 'small')};
  font-weight: ${font('weight', 'bold')};
  height: 22px;
  justify-content: center;
  width: 22px;
  margin-right: 1em;
`
