import React from 'react'
import { RenderElementProps } from 'slate-react'

import { StyledButton, Wrapper, StyledIcon } from './DisplayHelpTopicAction.styles'

import { error } from 'happitu/src/helpers/loggerHelper'
import HelpTopicPageNav from 'happitu/src/modules/help-topic-viewer/help-topic-page-context'
import { HelpTopicPageRecord } from 'happitu/src/types/models/helpTopics'
import { SlateRecordRefs } from 'happitu/src/types/models/richTextEditor'
import { HelpTopicActionElement } from 'happitu/src/types/slate-types'

interface Props extends RenderElementProps {
  element: HelpTopicActionElement
  collections?: SlateRecordRefs
}

const DisplayHelpTopicAction = ({ children, attributes, element, ...props }: Props) => {
  if (!element.nextPageId || !props.collections?.helpTopicPages) return null // TODO: Consider rendering an inactive state if this is confusing.
  const nextPage = props.collections.helpTopicPages.findById<HelpTopicPageRecord>(
    element.nextPageId,
  )
  if (!nextPage) {
    error(`Help topic action is linked to missing help topic page ${element.nextPageId}`)
    return null
  }
  if (!nextPage.active) {
    error(`Help topic action is linked to deleted help topic page ${element.nextPageId}`)
    return null
  }

  const [isFirst, setIsFirst] = React.useState(true)
  const [isLast, setIsLast] = React.useState(true)
  const ref = React.useRef<HTMLDivElement>(null)

  const { onHelpTopicPageSelect } = React.useContext(HelpTopicPageNav)
  const label = element.label ? element.label : nextPage.name

  const handleHelpTopicPageSelect = () =>
    element.nextPageId &&
    onHelpTopicPageSelect &&
    onHelpTopicPageSelect(element.nextPageId)

  React.useEffect(() => {
    if (ref.current) {
      const { previousElementSibling, nextElementSibling, className } = ref.current
      setIsFirst(
        !previousElementSibling || previousElementSibling.className !== className,
      )
      setIsLast(!nextElementSibling || nextElementSibling.className !== className)
    }
  }, [])

  return (
    <Wrapper
      {...attributes}
      ref={ref}
      className="help-topic-action"
      isFirst={isFirst}
      isLast={isLast}
    >
      <StyledButton ghost onClick={handleHelpTopicPageSelect}>
        {label}
        <StyledIcon type="arrow-right" />
      </StyledButton>
      {children}
    </Wrapper>
  )
}

export default DisplayHelpTopicAction
