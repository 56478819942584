// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/$$virtual/css-loader-virtual-ef416ba337/0/cache/css-loader-npm-5.2.4-285d2e57c2-3698399558.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DFMtwn7nJFKkRJmgs859i:hover .sdrdLlTGKiocVXCPHcGZs:before{opacity:1}._1xtuWsiu4EpuWp8XfA2kH1{align-items:center;display:flex;min-height:1em}._1xtuWsiu4EpuWp8XfA2kH1>label{display:flex;padding:5px 10px;cursor:pointer}._1xtuWsiu4EpuWp8XfA2kH1:not(:last-child){border-bottom:0;margin-bottom:-1px}._1xtuWsiu4EpuWp8XfA2kH1>span:not(:first-child){flex-grow:1}._1xtuWsiu4EpuWp8XfA2kH1.J6ZRJ-xSIRQADC361BQjQ{opacity:0.5;cursor:pointer}._1xtuWsiu4EpuWp8XfA2kH1.J6ZRJ-xSIRQADC361BQjQ:hover{opacity:0.75}._1p_I5M6Ih0LdW1ecBdV80Y{cursor:pointer}.lZvteaOS03guKxbtmj8s4{padding-left:0}._35OjbohSi9NjuVRKcZ5krM{background:transparent;border:0;color:#a6a1ab}._35OjbohSi9NjuVRKcZ5krM:hover{color:#807987}._35OjbohSi9NjuVRKcZ5krM:active{color:#5a555f}._3t21UZXrsmWicB_XnRGeub{font-style:italic;color:#cdcacf;margin-bottom:20px}._1V_bhd1L8sACyxsgAo-RMu{flex-grow:1}.sdrdLlTGKiocVXCPHcGZs{align-items:stretch;flex-shrink:0;margin-bottom:45px;margin-left:10px;min-width:25%;position:relative;z-index:5}.sdrdLlTGKiocVXCPHcGZs:before{content:'';background:#e9e7ea;border-radius:2px;bottom:0;left:0;opacity:0;position:absolute;top:0;width:3px}._26bu1wq6SJzi5kSypM53eB{position:absolute;top:50%;transform:translateY(-50%)}.DFMtwn7nJFKkRJmgs859i{align-items:stretch !important}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "DFMtwn7nJFKkRJmgs859i",
	"rightCol": "sdrdLlTGKiocVXCPHcGZs",
	"option": "_1xtuWsiu4EpuWp8XfA2kH1",
	"ghosted": "J6ZRJ-xSIRQADC361BQjQ",
	"label": "_1p_I5M6Ih0LdW1ecBdV80Y",
	"optionLabel": "lZvteaOS03guKxbtmj8s4",
	"removeButton": "_35OjbohSi9NjuVRKcZ5krM",
	"callout": "_3t21UZXrsmWicB_XnRGeub",
	"leftCol": "_1V_bhd1L8sACyxsgAo-RMu",
	"attributeButton": "_26bu1wq6SJzi5kSypM53eB"
};
export default ___CSS_LOADER_EXPORT___;
