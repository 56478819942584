import * as React from 'react'
import styled from 'styled-components'

import BaseLoader from './BaseLoader'

import getStyle from 'happitu/src/getStyle'
import { zIndex } from 'theme'

interface Props {
  className?: string
}

const Wrapper = styled.div`
  background: ${getStyle('app-background')};
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${zIndex('appLoader')};
`

const InnerWrapper = styled.div`
  height: 50px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
`

export default function AppLoader({ className }: Props) {
  return (
    <Wrapper className={className}>
      <InnerWrapper>
        <BaseLoader size="large" />
      </InnerWrapper>
    </Wrapper>
  )
}
