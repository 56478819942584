import styled from 'styled-components'

import createErrorHandler from 'happitu/src/createErrorHandler'

const getTicketErrorMessage = createErrorHandler(
  {
    id: {
      'invalid-parameter': `This ticket is missing from our system. Please try another one.`,
    },
  },
  styled.p`
    margin-bottom: 1em;
  `,
)

export default getTicketErrorMessage
