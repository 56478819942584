import { Response } from 'superagent'

import { APIErrorResponse } from 'happitu/src/actions/relay'
import { APIError } from 'happitu/src/createErrorHandler'
import { StatusCode, FailedRequest } from 'happitu/src/types/statusCodes'

export function handleSuccess<R>(response: Response): R {
  return response.body
}

export const handleReject = (error: APIErrorResponse): {} | APIError[] => {
  if (error.response.body) {
    if (error.response.body.errors) {
      return error.response.body.errors
    } else if (`${error.response.status}` === StatusCode.Conflict) {
      return [
        {
          message: error.response.error,
          referenceRecords: error.response.body,
          type: FailedRequest,
          what: `${error.response.status}`,
        },
      ] as APIError[]
    } else {
      // Refactor this out to pass status error shape.
      return error.response
    }
  }

  return error && error.body ? (error.body.errors as object) : error
}
