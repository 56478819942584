import React from 'react'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import Icon from 'happitu/src/components/Icon'
import HotKey from 'happitu/src/components/Icons/HotKey'
import BaseLoader from 'happitu/src/components/Loaders/BaseLoader'
import { FlexRow } from 'happitu/src/components/_DEPRECATED/Flex'
import getStyle from 'happitu/src/getStyle'
import { color, font } from 'theme'

interface WrapperProps {
  broken?: boolean
  selected?: boolean
  disabled?: boolean
}

interface Props extends WrapperProps {
  action: WorkflowActionRecord
  broken?: boolean
  onClick: (action: WorkflowActionRecord) => void
  selected: boolean
  loading?: boolean
}

const getAdditionalStyles = (props: WrapperProps) => {
  if (props.broken) {
    return css`
      color: ${getStyle('text-inactive-color')};
      cursor: not-allowed;
      opacity: 0.5;

      :hover {
        color: ${getStyle('text-inactive-color')};
      }

      ${HotKey} {
        color: ${getStyle('text-inactive-color')};
      }
    `
  }

  if (props.selected) {
    return css`
      color: ${color('secondary')};
    `
  }

  return css`
    &:hover,
    &:focus {
      z-index: 1;

      ${Icon} {
        transform: translateX(0.25em);
        opacity: 1;
      }
    }

    &:hover {
      z-index: 2;
    }
  `
}

export const LabelLink = styled.a<WrapperProps>`
  color: ${getStyle('text-active-color')};
  cursor: pointer;
  display: inline-flex;
  font-weight: ${font('weight', 'medium')};
  margin: 0;
  outline: none;
  padding: 0.75em 1em;
  margin: 0.25em 0;
  position: relative;
  background: ${getStyle('button-base-default-background')};
  border-radius: 3px;

  :hover,
  :active {
    color: ${getStyle('text-hover-color')};
  }

  ${Icon} {
    transition: transform 200ms;
    margin-left: 0.5em;
  }

  ${ifProp(
    'disabled',
    css`
      opacity: 0.5;
      cursor: not-allowed;

      &:hover,
      ${HotKey} {
        color: ${color('grey', 'base')};
      }
    `,
    css`
      ${getAdditionalStyles}
    `,
  )}
`

export const BackLink = styled.a`
  color: ${getStyle('text-sub-color')};
  padding: 1em 0;
  margin-top: 2em;
`

const Wrapper = styled.div``

const ActionItem = (props: Props) => {
  const { action, broken, onClick, selected } = props

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    if (!broken && !props.disabled) onClick(action)
  }

  return (
    <Wrapper>
      <LabelLink
        href="#"
        selected={selected}
        tabIndex={0}
        onClick={handleClick}
        broken={broken}
        disabled={props.disabled}
      >
        <FlexRow grow="1">{action.name}</FlexRow>
        {props.loading ? (
          <BaseLoader style={{ right: '1em' }} />
        ) : (
          <Icon type="arrow-right" />
        )}
      </LabelLink>
    </Wrapper>
  )
}

export default ActionItem
