import React, { createContext, Dispatch, useReducer, ReactNode } from 'react'

import { BaseRelayResponse, RelayAction } from 'happitu/src/actions/relay'
import createReducer from 'happitu/src/createRelayReducer'
import { GeneratedCollection } from 'happitu/src/helpers/generatedMetadata'

type SuggesterContextRecords = Partial<Record<GeneratedCollection, StoreInterface<any>>>

const { reducer, useActions } = createReducer()

const actions = useActions({
  processResponse: ({ nonRelayKeys = [], ...payload }: BaseRelayResponse<any>) => (
    dispatch: Dispatch<RelayAction<object>>,
  ) => {
    dispatch({
      type: 'RELAY_DISPATCH',
      payload: Object.keys(payload)
        .filter(
          (key) => ![...nonRelayKeys, 'ticketInteractions', 'tickets'].includes(key),
        )
        .reduce<Record<string, any>>((records, key: keyof typeof payload) => {
          records[key] = payload[key]
          return records
        }, {}),
      options: {},
    })
  },
})

export let processResponse: (response: BaseRelayResponse<any>) => void

export const SuggesterContext = createContext<SuggesterContextRecords>({})

export const SuggesterProvider = (props: { children?: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, {})
  processResponse = actions(dispatch).processResponse
  return (
    <SuggesterContext.Provider value={state}>{props.children}</SuggesterContext.Provider>
  )
}
