import styled from 'styled-components'

import { Label as BaseLabel } from './FieldUtils'

import { color, metric } from 'theme'

export const Checkbox = styled.div`
  background: ${ color('grey', 'offWhite') };//white;
  border-radius: ${ metric('borderRadius') };//input-border-radius;
  border: 1px solid ${ color('border') };//input-border-color;
  height: 14px;//checkbox-size;
  width: 14px;//checkbox-size;

  & input {
    opacity: 0;
    position: fixed;
    top: -1000;
    left: -1000;
  }
`

export const Label = styled(BaseLabel)`
  margin-bottom: 0;
  margin-left: 6px;
`

export const Wrapper = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
`
