import * as React from 'react'
import { FieldMetaState } from 'react-final-form'
import AutosizeTextarea from 'react-textarea-autosize'
import styled, { css } from 'styled-components'
import { ifNotProp, ifProp } from 'styled-tools'

import getStyle from 'happitu/src/getStyle'
import { color, font, metric } from 'theme'

/**
 * Helper text that typically sits below an input.
 */

interface FigureProps {
  value?: string | object | null
  children?: React.ReactNode
}

export const FieldFigure = styled.figure.attrs((props: FigureProps) => ({
  children: props.children || props.value,
}))<FigureProps>`
  color: ${getStyle('text-sub-color')};
  display: block;
  margin: 0;

  padding: 0;
`

/**
 * A form label.
 * NOTE: This component is assuming that you wrap this component AND your
 * input with a <label /> tag.
 *
 * E.g. <label><Label value="username" /><input type="text" /></label>
 *
 * This way we don't have to worry about using the "for" label arrtibute.
 */

interface LabelProps {
  required?: boolean
  value?: string
  children: React.ReactNode
}

export const Label = styled.span.attrs((props: LabelProps) => ({
  children: props.children || props.value,
}))<LabelProps>`
  color: ${getStyle('text-sub-color')};
  display: ${ifProp('children', 'inline-block', 'none')};
  font-size: ${font('size', 'small')};
  font-weight: ${font('weight', 'medium')};
  margin-bottom: 4px;
  position: relative;

  ${ifProp(
    'required',
    css`
      padding-left: 12px;

      &:before {
        content: '*';
        left: 0px;
        line-height: 0.5;
        margin-right: 4px;
        position: absolute;
        top: 50%;
        transform: translateY(-30%);
      }
    `,
  )}
`

/**
 * Wrap form fields with this component to achieve consistent field spacing.
 */

interface FieldWrapperProps {
  inline?: boolean
  invalid?: boolean
}

export const InsetLabelContent = styled(Label)`
  font-size: 1em;
  font-weight: ${font('weight', 'medium')};
  left: 10px;
  position: absolute;
  top: 1.2em;
  transform-origin: left;
  transform: translateY(-0.8em) scale(0.8);
  transition: transform 75ms;
  user-select: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  right: 0;
  overflow: hidden;
`

export const inputFontSize = '1.143em'

export const InputFocusCSS = css`
  border-color: ${getStyle('input-focus-borderColor')};
  box-shadow: 0 0 0 1px ${getStyle('input-focus-borderColor')};
`

export const InputCSS = css`
  border: 0;
  display: block;
  flex-grow: 1;
  font-size: 1.143em;
  width: 100%;
  border-radius: ${metric('borderRadius')};
  border: 1px solid ${getStyle('input-default-borderColor')};

  ${ifNotProp('noLabel', 'padding-top: 20px')};

  ::placeholder {
    opacity: 0;
    color: ${getStyle('input-default-placeholderColor')};
  }

  &:focus::placeholder,
  &.focus {
    opacity: 1;
  }

  &:focus {
    ${InputFocusCSS}
  }

  &:disabled {
    background: ${getStyle('input-disabled-background')};
    box-shadow: none;
    border-color: ${getStyle('input-disabled-background')};
    cursor: not-allowed;
  }

  &[value='']:not(:focus)
    + ${InsetLabelContent},
    &:not([type]):empty:not(:focus)
    + ${InsetLabelContent} {
    transform: translateY(0);
  }
`

const onSelectAll = (event: React.MouseEvent<HTMLInputElement>) => {
  event.currentTarget.focus()
  event.currentTarget.select()
}

interface InputProps {
  autoSelect?: boolean
  noLabel?: boolean
}

export const Input = styled.input.attrs<InputProps>((props) => ({
  onClick: props.autoSelect ? onSelectAll : undefined,
}))<InputProps>`
  ${InputCSS}
`

export const Textarea = styled.textarea`
  ${InputCSS}
  ${styled(AutosizeTextarea)``}
  border: 0;
  min-height: 100px;
  resize: vertical;
  width: 100%;
`

export const FieldRow = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
`

export const InputWrapper = styled.label`
  align-items: center;
  background: ${getStyle('app-background')};

  display: flex;
  font-size: 1em;
  margin-bottom: 4px;
  position: relative;
  transition: box-shadow 100ms;
`

export const NextFieldWrapper = styled.div<{ invalid?: boolean }>`
  position: relative;

  &:not(:last-child) {
    margin-bottom: 1em;
  }

  ${ifProp(
    'invalid',
    css`
      input,
      textarea,
      [role='button'],
      [role='dropdown'] {
        border-color: ${color('red')};
      }

      ${InsetLabelContent}, ${Label} {
        color: ${color('red')};
      }

      ${FieldFigure} {
        color: ${color('red')};
        font-weight: ${font('weight', 'medium')};
      }
    `,
  )}

  ${ifProp(
    'inline',
    css`
      align-items: center;
      display: flex;
      margin-bottom: 0;

      ${InsetLabelContent} {
        margin-bottom: 0;
        margin-right: 10px;
      }
    `,
  )}
`

export const FieldWrapper = styled.div<FieldWrapperProps>`
  margin-bottom: 1em;

  textarea {
    width: 100%;
    max-width: 600px;
  }

  &:hover {
    .contactAttributeButton {
      opacity: 1;
      margin-left: 15px;
    }
  }

  ${ifProp(
    'invalid',
    css`
      input,
      textarea,
      [role='dropdown'],
      [role='date-selector'] {
        border-color: ${color('red')};
      }

      ${Label} {
        color: ${getStyle('text-error-color')};
      }

      ${FieldFigure} {
        color: ${getStyle('text-error-color')};
        font-weight: ${font('weight', 'medium')};
      }
    `,
  )}

  ${ifProp(
    'inline',
    css`
      margin-bottom: 0;
      display: flex;
      align-items: center;

      ${Label} {
        margin-right: 10px;
        margin-bottom: 0;
      }
    `,
  )}
`

/**
 * An error message that should appear below a field.
 */
export const ErrorFigure = styled.span`
  color: ${getStyle('text-error-color')};
  font-weight: ${font('weight', 'medium')};

  a {
    font-weight: ${font('weight', 'normal')};
    cursor: pointer;
  }
`
export const ErrorMessage = (
  props: Partial<FieldMetaState<string | string[] | number | number[]>>,
) => (props.touched && props.error ? <ErrorFigure>{props.error}</ErrorFigure> : <span />)

export const Fieldset = styled.fieldset`
  margin: 2em 0;
  border: 0;
  padding: 0;
`
