import React from 'react'

import { Bold, Underline, Strikethrough, Italic, Code, Highlight } from './Leaf.styles'

export interface EditorLeaf {
  text: string
  bold?: boolean
  code?: boolean
  italic?: boolean
  underline?: boolean
  strikethrough?: boolean
  link?: boolean
  highlight?: string
}

interface Props {
  leaf: EditorLeaf
  children?: React.ReactNode
  attributes?: any
}

// Arbitrarily desired format: <code> <b> <i> <s> <u> text </u> </s> </i> </b> </code>
const Leaf = ({ leaf, children }: Props) => {
  if (leaf.underline) {
    children = <Underline>{children}</Underline>
  }
  if (leaf.strikethrough) {
    children = <Strikethrough>{children}</Strikethrough>
  }
  if (leaf.italic) {
    children = <Italic>{children}</Italic>
  }
  if (leaf.bold) {
    children = <Bold>{children}</Bold>
  }
  if (leaf.code) {
    children = <Code>{children}</Code>
  }
  if (leaf.highlight) {
    children = <Highlight highlight={leaf.highlight}>{children}</Highlight>
  }
  return <>{children}</>
}

export default Leaf
