import { css } from 'styled-components'

import getStyle from '../getStyle'

export const scrollbarWidth = 8
export const scrollbarOffsetWidth = 4

const scrollbar = css`
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    width: ${scrollbarWidth}px;
    height: ${scrollbarWidth}px;
  }

  ::-webkit-scrollbar-track {
    background: ${getStyle('scrollbar-background-track')};
    border-radius: 5px;
    display: block;
    margin: 10px;
  }

  ::-webkit-scrollbar-corner {
    background: ${getStyle('scrollbar-background-track')};
  }

  ::-webkit-scrollbar-thumb {
    background: ${getStyle('scrollbar-background-thumb')};
    border-radius: 5px;
    transition: background 2s;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background: ${getStyle('scrollbar-background-thumb-hover')};
    }
  }
`

export const scrollbarYOffset = css`
  border-right: ${scrollbarOffsetWidth}px solid transparent;
`

export const scrollbarXOffset = css`
  border-bottom: ${scrollbarOffsetWidth}px solid transparent;
`

export default scrollbar
