import styled from 'styled-components'

import getStyle from 'happitu/src/getStyle'
import { metric } from 'theme'

const SectionCellList = styled.ul`
  align-items: center;
  background: ${getStyle('app-alt-background')};
  border-radius: ${metric('largeBorderRadius')};
  border: 0;
  display: grid;
  margin-bottom: 1em;
`

export default SectionCellList
