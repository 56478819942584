export enum UserType {
  Admin = 'Admin',
  Manager = 'Manager', // <-- not currently used
  Agent = 'Agent',
  AgentLimited = 'AgentLimited',

  // This is not set on userType but determined by looking in the workflowManage permissions object.
  Editor = 'Editor',
}

export const isUserOrContactRecord = (
  record?: any,
): record is UserRecord | ContactRecord => !!record && 'firstName' in record

export const isRecordWithName = (record?: any): record is RecordWithName =>
  !!record && 'name' in record
