// expression tree
import update from 'immutability-helper'

import { GeneratedCollection } from 'happitu/src/helpers/generatedMetadata'

interface TreeProperties {
  readonly rootIndex: number
  readonly nodes: Node[]
  readonly newActions: TreeAction[]
}

export default class Tree implements TreeProperties {
  readonly rootIndex: number
  readonly nodes: Node[] = []
  readonly newActions: TreeAction[]

  constructor({ rootIndex, nodes, newActions }: Partial<TreeProperties> = {}) {
    this.rootIndex = rootIndex || 0
    this.nodes = nodes || []
    this.newActions = newActions || []
  }

  setRoot(nodeIndex: number): Tree {
    this.validateIndexes(nodeIndex)
    return new Tree(
      update(this, {
        rootIndex: { $set: nodeIndex },
        newActions: { $push: [{ action: TreeActionType.SetRootNode }] },
      }),
    )
  }

  createNode(node: Node, destinationNodeIndex?: number): Tree {
    let newTree = new Tree(
      update(this, {
        nodes: { $push: [node] },
        newActions: { $push: [{ action: TreeActionType.CreateNode, node: node }] },
      }),
    )
    const newNodeIndex = newTree.nodes.length - 1
    if (destinationNodeIndex !== undefined) {
      this.validateIndexes(destinationNodeIndex)
      newTree = newTree.addNodeValue(destinationNodeIndex, new NodeValue(newNodeIndex))
    }
    return newTree
  }

  replaceNode(nodeIndex: number, newNode: Node): Tree {
    this.validateIndexes(nodeIndex)
    return new Tree(
      update(this, {
        nodes: { $splice: [[nodeIndex, 1, newNode]] },
        newActions: {
          $push: [
            { action: TreeActionType.ReplaceNode, nodeIndex: nodeIndex, node: newNode },
          ],
        },
      }),
    )
  }

  removeNode(nodeIndex: number): Tree {
    this.validateIndexes(nodeIndex)
    if (nodeIndex === this.rootIndex) {
      throw Error('cannot remove root node, change it first')
    }

    const tree = this.nodes
      .reduce<NodeValueReference[]>(filterNodeValueReferences(nodeIndex), [])
      .reduce(removeNodeReferences, this)

    return new Tree(
      update(tree, {
        nodes: { $splice: [[nodeIndex, 1]] },
        newActions: {
          $push: [{ action: TreeActionType.RemoveNode, nodeIndex: nodeIndex }],
        },
      }),
    )
  }

  setNodeOperation(nodeIndex: number, operation: Operation): Tree {
    const updatedNode = update(this.nodes[nodeIndex], { operation: { $set: operation } })
    return this.replaceNode(nodeIndex, updatedNode)
  }

  setNodeFunctionCall(nodeIndex: number, functionCall?: Function): Tree {
    this.validateIndexes(nodeIndex)
    const updatedNode = update(this.nodes[nodeIndex], {
      function: { $set: functionCall },
    })
    return this.replaceNode(nodeIndex, updatedNode)
  }

  addNodeValue(nodeIndex: number, value: Value): Tree {
    this.validateIndexes(nodeIndex)
    const updatedNode = update(this.nodes[nodeIndex], { values: { $push: [value] } })
    return this.replaceNode(nodeIndex, updatedNode)
  }

  addNodeValueToLast(value: Value): Tree {
    return this.addNodeValue(this.lastNode(), value)
  }

  spliceNodeValue(nodeIndex: number, value: Value, index: number, remove = 0) {
    this.validateIndexes(nodeIndex)
    const updatedNode = update(this.nodes[nodeIndex], {
      values: { $splice: [[index, remove, value]] },
    })
    return this.replaceNode(nodeIndex, updatedNode)
  }

  replaceNodeValue(nodeIndex: number, valueIndex: number, value: Value): Tree {
    this.validateIndexes(nodeIndex, valueIndex)
    const updatedNode = update(this.nodes[nodeIndex], {
      values: { $splice: [[valueIndex, 1, value]] },
    })
    return this.replaceNode(nodeIndex, updatedNode)
  }

  removeNodeValue(nodeIndex: number, valueIndex: number): Tree {
    this.validateIndexes(nodeIndex, valueIndex)
    const updatedNode = update(this.nodes[nodeIndex], {
      values: { $splice: [[valueIndex, 1]] },
    })
    return this.replaceNode(nodeIndex, updatedNode)
  }

  commitActions(actions: TreeAction[]): Tree {
    let tree: Tree = new Tree(this)
    actions.forEach((action) => {
      switch (action.action) {
        case TreeActionType.CreateNode:
          if (action.node === undefined) {
            throw Error('CreateNode is missing node value')
          }
          tree = tree.createNode(action.node)
          break
        case TreeActionType.ReplaceNode:
          if (action.node === undefined) {
            throw Error('ReplaceNode is missing node value')
          }
          if (action.nodeIndex === undefined) {
            throw Error('ReplaceNode is missing node index')
          }
          tree = tree.replaceNode(action.nodeIndex, action.node)
          break
        case TreeActionType.RemoveNode:
          if (action.nodeIndex === undefined) {
            throw Error('RemoveNode is missing node index')
          }
          tree = tree.removeNode(action.nodeIndex)
          break
        case TreeActionType.SetRootNode:
          if (action.nodeIndex === undefined) {
            throw Error('SetRootNode is missing node index')
          }
          tree = tree.setRoot(action.nodeIndex)
          break
      }
    })
    return tree
  }

  getNode(nodeIndex: number): Node {
    return this.nodes[nodeIndex]
  }

  lastNode(): number {
    return this.nodes.length - 1
  }

  private validateIndexes(nodeIndex: number, valueIndex?: number) {
    if (nodeIndex < 0 || nodeIndex >= this.nodes.length) {
      throw Error(`could not set root node, node index ${nodeIndex} is not valid`)
    }
    if (
      valueIndex !== undefined &&
      (valueIndex < 0 || valueIndex >= this.nodes[nodeIndex].values.length)
    ) {
      throw Error(`could not set node value, value index ${valueIndex} is not valid`)
    }
  }
}

export const EmptyTree = new Tree({ rootIndex: 0, nodes: [], newActions: [] })

export interface TreeAction {
  readonly action: TreeActionType
  readonly nodeIndex?: number
  readonly node?: Node
}

export enum TreeActionType {
  CreateNode = 'CreateNode',
  ReplaceNode = 'ReplaceNode',
  RemoveNode = 'RemoveNode',
  Publish = 'Publish',
  SetRootNode = 'SetRootNode',
}

export interface Node {
  readonly operation: Operation
  readonly values: Value[]
  readonly function?: Function
}

export interface FunctionNode {
  readonly operation: Operation
  readonly values: Value[]
  readonly function: Function
}

export interface Function {
  readonly name: FunctionName
  readonly resultType: ResultType
}

export enum FunctionName {
  Average = 'Average',
  Count = 'Count',
  CountIfTrue = 'CountIfTrue',
  Sum = 'Sum',
  Round = 'Round',
  Minutes = 'Minutes',
  ClockDurationHours = 'ClockDurationHours',
  ClockDurationMinutes = 'ClockDurationMinutes',

  PercentOf = 'PercentOf',
  Seconds = 'Seconds',
}

export class Value {
  readonly type: ValueType
  protected conditionalOn: number | undefined

  constructor(type: ValueType) {
    this.type = type
  }

  isConditionalOn(nodeIndex: number) {
    this.conditionalOn = nodeIndex
    return this
  }
}

export class RecordReference extends Value {
  readonly collectionName: GeneratedCollection
  readonly recordId: ID

  constructor(collectionName: GeneratedCollection, recordId: ID) {
    super(ValueType.RecordReference)
    this.collectionName = collectionName
    this.recordId = recordId
  }
}

export class RecordFieldReference extends Value {
  readonly recordName: GeneratedCollection
  readonly fieldName: string
  readonly distinct: boolean

  constructor(recordName: GeneratedCollection, fieldName: string, distinct = false) {
    super(ValueType.RecordFieldReference)
    this.recordName = recordName
    this.fieldName = fieldName
    this.distinct = distinct
  }
}

export class SearchDocumentReference extends Value {
  readonly searchDocumentName: string
  readonly fieldName: string

  constructor(searchDocumentName: string, fieldName: ID) {
    super(ValueType.SearchDocumentReference)
    this.searchDocumentName = searchDocumentName
    this.fieldName = fieldName
  }
}

export class ConstantNumber extends Value {
  readonly constant: number
  readonly isInteger: boolean

  /*
    isInteger should be set to `true` when used as the second 
    value on a node that uses the round function.
    Example Node:
    {
      function: {
        name: FunctionName.Round,
        resultType: ResultType.NumberResult,
      },
      operation: Operation.FunctionCall,
      values: [new NodeValue(1), new ConstantNumber(2, true)],
    }
  */
  constructor(constant: number, isInteger = false) {
    super(ValueType.ConstantNumber)
    this.constant = constant
    this.isInteger = isInteger
  }
}

export class BooleanValue extends Value {
  readonly bool: boolean

  constructor(bool: boolean) {
    super(ValueType.BooleanValue)
    this.bool = bool
  }
}

export class NodeValue extends Value {
  readonly nodeIndex: number

  constructor(nodeIndex: number) {
    super(ValueType.NodeValue)
    this.nodeIndex = nodeIndex
  }
}

export class TreeValue extends Value {
  readonly treeId: ID

  constructor(treeId: ID) {
    super(ValueType.TreeValue)
    this.treeId = treeId
  }
}

export enum Operation {
  Add = 'add',
  Subtract = 'subtract',
  Multiply = 'multiply',
  Divide = 'divide',
  GreaterThan = 'greaterThan',
  LessThan = 'lessThan',
  GreaterThanOrEq = 'greaterThanOrEq',
  LessThanOrEq = 'lessThanOrEq',
  NotGreaterThan = 'greaterThanNot',
  NotLessThan = 'lessThanNot',
  NotGreaterThanOrEq = 'greaterThanOrEqNot',
  NotLessThanOrEq = 'lessThanOrEqNot',
  And = 'and',
  Or = 'or',
  NotAnd = 'andNot',
  NotOr = 'orNot',
  Equals = 'equals',
  NotEquals = 'equalsNot',
  OneOf = 'oneOf',
  NotOneOf = 'oneOfNot',
  FunctionCall = 'function',
  NotFunctionCall = 'functionNot',
}

export const operationDisplayLabels = {
  [Operation.Add]: '+',
  [Operation.Subtract]: '–',
  [Operation.Multiply]: '×',
  [Operation.Divide]: '÷',
  [Operation.And]: 'AND',
  [Operation.NotAnd]: 'AND NOT',
  [Operation.Or]: 'OR',
  [Operation.NotOr]: 'OR NOT',
  [Operation.Equals]: '=',
  [Operation.NotEquals]: '≠',
} as Record<Operation, string>

export enum ValueType {
  ConstantNumber = 'constantNumber',
  BooleanValue = 'bool',
  RecordReference = 'recordReference',
  SearchDocumentReference = 'searchDocumentReference',
  RecordFieldReference = 'recordFieldReference',
  NodeValue = 'node',
  TreeValue = 'tree',
  NullValue = 'null',
}

export const NullValue = new Value(ValueType.NullValue)

export enum ResultType {
  BooleanResult = 'boolean',
  NumberResult = 'number',
  StringResult = 'string',
  NoneResult = 'none',
}

export const comparatorOperations = [
  Operation.GreaterThan,
  Operation.GreaterThanOrEq,
  Operation.LessThan,
  Operation.LessThanOrEq,
  Operation.NotGreaterThan,
  Operation.NotGreaterThanOrEq,
  Operation.NotLessThan,
  Operation.NotLessThanOrEq,
  Operation.Equals,
  Operation.NotEquals,
]

export const equalityOperations = [
  Operation.Equals,
  Operation.NotEquals,
  Operation.OneOf,
  Operation.NotOneOf,
]

export const logicalOperations = [
  Operation.And,
  Operation.NotAnd,
  Operation.Or,
  Operation.NotOr,
]

export const mathOperations = [
  Operation.Add,
  Operation.Subtract,
  Operation.Divide,
  Operation.Multiply,
]

export const resultTypeOperationIndex: Record<ResultType, Operation[]> = {
  [ResultType.BooleanResult]: [...comparatorOperations, ...logicalOperations],
  [ResultType.NumberResult]: mathOperations,
  [ResultType.StringResult]: [],
  [ResultType.NoneResult]: [],
}

interface NodeValueReference {
  nodeIndex: number
  valueIndex: number
}

const filterNodeValueReferences = (nodeIndex: number) => (
  acc: NodeValueReference[],
  node: Node,
  index: number,
) => {
  const valueIndex = node.values.findIndex(
    (value) => isNodeValue(value) && value.nodeIndex === nodeIndex,
  )
  if (valueIndex > -1) {
    acc.push({ nodeIndex: index, valueIndex })
  }
  return acc
}

export const isValueType = <T extends Value>(value: Value, type: ValueType): value is T =>
  value.type === type

const removeNodeReferences = (tree: Tree, update: NodeValueReference) =>
  tree.removeNodeValue(update.nodeIndex, update.valueIndex)

export const oneToOneOperations = comparatorOperations
export const oneToManyOperations = [Operation.OneOf, Operation.NotOneOf]
export const manyToManyOperations = [...logicalOperations, ...mathOperations]
export const isNodeValue = (n: Value): n is NodeValue => 'nodeIndex' in n

export const isTree = (n?: any): n is Tree => !!n && 'rootIndex' in n
export const isRecordFieldReference = (n?: any): n is RecordFieldReference =>
  !!n && 'recordName' in n
export const isTreeValue = (n?: any): n is TreeValue => !!n && 'treeId' in n
export const isConstantValue = (n?: any): n is ConstantNumber => !!n && 'constant' in n

export const defaultTree = new Tree({
  rootIndex: 0,
  nodes: [],
  newActions: [],
}).createNode({ operation: Operation.And, values: [] })
export const isBooleanOperation = (operation: Operation) =>
  logicalOperations.includes(operation)
