import Store from './createStore'

import * as types from 'happitu/src/constants/ActionTypes.ts'

const initialState = new Store()

const populateUsers = (state, payload) => state.setMany(payload)

const updateUserAvailability = (state, payload) => {
  let nextState = state
  let record = state.findById(payload.id)
  if (record !== null) {
    nextState = nextState.update(payload.id, {
      online: payload.online,
    })
  }
  return nextState
}

export default function users(state = initialState, { type, payload }) {
  switch (type) {
    case types.RELAY_DISPATCH:
      return state.setMany(payload.users)
    case types.GET_USERS:
      return populateUsers(state, payload)
    case types.UPDATE_USER_AVAILABILITY:
      return updateUserAvailability(state, payload)
    case types.UPDATE_USER:
      return state.update(payload.id, payload)

    case types.WS_REMOVE_USER:
      return state.remove(payload.id)
    case types.REMOVE_USER:
      return state.markForRemoval(payload.id)
    case types.REMOVE_USER_SUCCESS:
      return state.remove(payload.id)
    case types.REMOVE_USER_FAILED:
      return state.rollbackRemoval(payload.id)
    default:
      return state
  }
}
