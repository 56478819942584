import React from 'react'

import { useSearchableList } from '../hooks'

import { SelectProps } from 'happitu/src/components/Select'

interface Props<SelectValue> extends Omit<SelectProps<SelectValue>, 'onSelect'> {
  searchTerm: string
  searchProps: string[]
}
function SearchableList<SelectValue>({
  children,
  searchTerm = '',
  searchProps,
}: Props<SelectValue>) {
  const connectSearch = useSearchableList(searchTerm, {
    searchProps,
  })
  return <>{connectSearch(children)}</>
}

export default SearchableList
