import { useState, useEffect, Dispatch, SetStateAction } from 'react'

import Socket from 'happitu/src/services/websocket/socketService'

interface Config {
  websocketToken: string | null
  roomId: string | null
}

const config: Config = {
  websocketToken: null,
  roomId: null,
}

const useWebSocket = (
  initialConfig = config,
): [Config, Dispatch<SetStateAction<Config>>] => {
  const [config, setConfig] = useState(initialConfig)
  useEffect(() => {
    const socket = new Socket('tickets')
    if (config.websocketToken && config.roomId) {
      socket.connect(config.websocketToken)
      socket.subscribeToRoom(config.roomId, config.websocketToken)
      socket.subscribe(() => {
        // TODO: update state with subscription events
        /*console.log(message)*/
      })
    }

    return () => {
      if (config.roomId) socket.unsubscribeToRoom(config.roomId)
    }
  }, [config.websocketToken, config.roomId])

  return [config, setConfig]
}

export default useWebSocket
