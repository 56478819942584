import request from 'superagent'

import { happituHeaders } from 'happitu/src/helpers/requestHelpers'
import { reportingHost } from 'happitu/src/services'

export const reportingRequest = (url: string, method: 'post' | 'get' = 'post') => {
  return request[method](`${reportingHost}${url}`).withCredentials().use(happituHeaders())
}

export const fetchResults = (
  fields: Record<string, any>,
  dateRange: Record<string, any>,
  filter: Record<string, any>,
  aggregate: Record<string, any>,
  timeGrouping: string | null,
  options: Record<string, any>,
) => {
  return reportingRequest(`/reporting/breakdown`).send({
    dateRange,
    fields,
    filterSet: filter,
    aggregate,
    timeGrouping,
    ...options,
  })
}

export const availableFiltersRequest = (
  filter: Record<string, any>,
  dateRange: Record<string, any>,
  options: Record<string, any>,
) => {
  return reportingRequest('/reporting/filter-list').send({
    filterSet: filter,
    ...dateRange,
    ...options,
  })
}
