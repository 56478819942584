import { ComponentProps } from 'react'
import styled from 'styled-components'

import { textColors } from './Toolbar/text-style-button'

export const Bold = styled.b``

export const Underline = styled.u``

export const Strikethrough = styled.s``

export const Italic = styled.i``

export const Code = styled.code``

export const Highlight = ({
  highlight,
  children,
  ...props
}: { highlight: string } & ComponentProps<'span'>) => (
  <span
    {...props}
    style={{
      [/background/.test(highlight) ? 'backgroundColor' : 'color']: textColors[highlight],
    }}
  >
    {children}
  </span>
)
