import React from 'react'

import Menu from '../Menu'

import { MultiStepMenuProps } from './MultiStepMenu.types'
import MultiStepMenuBody from './components/MultiStepMenuBody'

export default function MultiStepMenu(props: MultiStepMenuProps) {
  return (
    <Menu isVisible={props.isVisible} onClose={props.onClose} style={props.style}>
      <MultiStepMenuBody {...props} />
    </Menu>
  )
}
