import { ComponentClass, FunctionComponent } from 'react'

import AddressField from './AddressField'
import AppLauncher from './AppLauncher'
import AttachFileField from './AttachFileField'
import CallbackButton from './CallbackButton'
import CheckboxArray from './CheckboxArray'
import DatePicker from './DatePicker'
import DateRangePicker from './DateRangePicker'
import EmailField from './EmailField'
import HelpTopic from './HelpTopic'
import Image from './Image'
import LocationSelect from './LocationSelect'
import MultiLineTextField from './MultiLineTextField'
import TextField from './NewTextField'
import NumberField from './NumberField'
import RadioButtonArray from './RadioButtonArray'
import SSNField from './SSNField'
import TelephoneField from './TelephoneField'
import Webview from './Webview'

import * as NewDisplayElements from 'happitu/src/modules/workflow-elements/display'

export const DisplayElements = {
  AddressField,
  AppLauncher,
  AttachFileField,
  CheckboxArray,
  DatePicker,
  DateRangePicker,
  EmailField,
  HelpTopic,
  Image,
  LocationSelect,
  MultiLineTextField,
  RadioButtonArray,
  TelephoneField,
  TextField,
  CallbackButton,
  SSNField,
  NumberField,
  Webview,
  ...NewDisplayElements,
} as {
  [key: string]: FunctionComponent | ComponentClass
}

export const FORM_FIELDS = [
  'AddressField',
  'AttachFileField',
  'CheckboxArray',
  'DatePicker',
  'DateRangePicker',
  'EmailField',
  'LocationSelect',
  'MultiLineTextField',
  'RadioButtonArray',
  'TelephoneField',
  'TextField',
  'CallbackButton',
  'CallbackScheduler',
]
