import { motion, MotionProps, AnimatePresence } from 'framer-motion'
import * as React from 'react'

interface Props {
  children: React.ReactNode
  isVisible: boolean
  transition: MotionProps
  className?: string
  // TODO: Scale is currently being omitted to resolve a typing issue
  style?: Omit<React.CSSProperties, 'scale'>
}

// Use this to apply transitions to a single element when it mounts/unmounts
export default function Transition({
  children,
  isVisible,
  transition,
  style = {},
  className,
}: Props) {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div key="item" style={style} className={className} {...transition}>
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
