import { FunctionComponentElement, ReactNode, ReactPortal } from 'react'

import { OptionProps } from './ListItems'

export type ListChildrenElement =
  | FunctionComponentElement<OptionProps>
  | boolean
  | string
  | null
  | ReactPortal
  | ListChildren
  | undefined
  | number

export type ListChildren = Array<ListChildrenElement>

export const CREATE_ACTION = 'CREATE_ACTION'

export const isReactComponent = (
  node: ReactNode,
): node is FunctionComponentElement<any> =>
  !!node && typeof node === 'object' && 'props' in node

export const isOptionComponent = (
  node: ReactNode,
): node is FunctionComponentElement<OptionProps> =>
  isReactComponent(node) && node.props.value
