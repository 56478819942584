import * as types from 'happitu/src/constants/ActionTypes'

const initialState = {}

export default function organizationProfileReducer(
  state = initialState,
  {
    type,
    payload,
  }: {
    type: string
    payload: { organizationProfiles: OrganizationProfileRecord[] }
  },
) {
  switch (type) {
    case types.RELAY_DISPATCH:
      return payload.organizationProfiles ? payload.organizationProfiles[0] : state
    default:
      return state
  }
}
