import PropTypes from 'prop-types'
import React from 'react'

import Button from '../button/button'

import { refresh } from 'happitu/src/helpers/domHelper'

const RefreshLink = ({ children }) => (
  <Button onClick={refresh} primary>
    {children}
  </Button>
)

RefreshLink.defaultProps = {
  children: 'Try Again',
}

RefreshLink.propTypes = {
  children: PropTypes.string,
}

export default RefreshLink
