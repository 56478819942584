import React, { useState } from 'react'
import styled from 'styled-components'

import { SelectProps } from '../Select.types'

import SearchInput from 'happitu/src/components/Fields/SearchInput'
import { SelectableList, OptionGroup, Option } from 'happitu/src/components/Lists'
import getStyle from 'happitu/src/getStyle'
import {
  isMetricOption,
  MetricGroup,
  MetricOption,
} from 'happitu/src/modules/Insights/components/MetricInspector/MetricInspector.helpers'
import { font, metric } from 'theme'

interface Props extends SelectProps<string> {
  fields: Array<MetricOption | MetricGroup>
  searchPlaceholder: string
  value?: string
}

const AvailableFields = (props: Props) => {
  const [searchTerm, setSearchTerm] = useState('')

  const renderMetricGroup = (fields: Array<MetricOption | MetricGroup>) => {
    return fields.map((option, key) => {
      if (isMetricOption(option)) return <Option {...option} key={key} />
      if (option.fields.length)
        return (
          <OptionGroup label={option.label} key={key}>
            {renderMetricGroup(option.fields)}
          </OptionGroup>
        )
      return
    })
  }

  return (
    <>
      <header>
        <SearchInput
          autoFocus
          onChange={(e) => setSearchTerm(e.currentTarget.value)}
          placeholder={props.searchPlaceholder}
          value={searchTerm}
        />
      </header>

      <SelectableList
        multiple={props.multiple}
        onSelect={props.onSelect}
        search={props.search}
        searchTerm={searchTerm}
        value={props.value}
      >
        {renderMetricGroup(props.fields)}
      </SelectableList>
      {props.onCreate && (
        <FooterButton
          icon="plus"
          label="Create Custom Metric"
          onSelect={props.onCreate}
          value="create"
        >
          Create Custom Metric
        </FooterButton>
      )}
    </>
  )
}

const FooterButton = styled(Option)`
  background: ${getStyle('menu-background')};
  border-radius: 0;
  border-bottom-left-radius: ${metric('largeBorderRadius')};
  border-bottom-right-radius: ${metric('largeBorderRadius')};
  border-top: 1px solid ${getStyle('border-color')};
  font-weight: ${font('weight', 'medium')};
  margin: 0;
  padding: 1em;
  text-align: center;
`

export default AvailableFields
