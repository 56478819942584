import React, { ChangeEvent, ComponentProps, useState } from 'react'
import styled from 'styled-components'

import getStyle from 'happitu/src/getStyle'

interface Props extends Omit<ComponentProps<'input'>, 'onBlur'> {
  onBlur: (value: string) => void
}

const TimeInput = ({ className, onBlur }: Props) => {
  const [value, setValue] = useState<string>('')
  const [maxLength, setMaxLength] = useState(8)

  // eslint-disable-next-line complexity
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const hourValue = parseInt(e.currentTarget.value)
    let finalValue = e.currentTarget.value
    if (value.length > e.currentTarget.value.length) {
      if (e.currentTarget.value.length === 2 || e.currentTarget.value.length === 6) {
        finalValue = e.currentTarget.value.replace(/.$/, '')
      }
    } else {
      if (hourValue > 12) {
        setMaxLength(5)
      } else {
        setMaxLength(8)
      }

      if (finalValue == '1:' || finalValue == '2:') {
        finalValue = `0${finalValue}:`
      } else if (hourValue > 2 && hourValue < 10 && !/:/.test(e.currentTarget.value)) {
        finalValue = `0${hourValue}:`
      } else if (e.currentTarget.value.length === 2) {
        finalValue = `${e.currentTarget.value}:`
      }

      finalValue = finalValue.replace(/::/g, ':')

      if (finalValue.length === 6) {
        finalValue = finalValue.replace(/(.)$/, (acc) => {
          return ` ${acc}`
        })
      }

      if (finalValue.length === 7) {
        if (!/a|p/i.test(finalValue[finalValue.length - 1])) {
          finalValue = finalValue.replace(/.$/, '')
        } else {
          finalValue = `${finalValue}m`
        }
      } else if (finalValue.length === 5) {
        finalValue = `${finalValue} `
      }

      if (maxLength === 5) {
        finalValue = finalValue.replace(/\s/g, '')
      }
    }

    setValue(finalValue)
  }

  return (
    <label className={className}>
      <input
        type="text"
        placeholder="hh:mm (am|pm)"
        maxLength={maxLength}
        onChange={onChange}
        onBlur={() => onBlur(value)}
        value={value}
      />
    </label>
  )
}

export default styled(TimeInput)`
  position: relative;
  display: flex;
  align-items: center;

  &:before {
    position: absolute;
    top: 0.5em;
    bottom: 0.5em;
    border-left: 1px solid ${getStyle('border-color')};
    content: '';
    left: -1px;
  }

  span {
    flex-grow: 1;
  }

  input {
    width: 155px;
    border-color: transparent;
    box-shadow: none;
    position: relative;
    z-index: 1;
  }
`
