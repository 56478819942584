import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import FuzzySelect from 'happitu/src/components/Fields/FuzzySelect'
import Icon from 'happitu/src/components/Icon'
import { icomoon } from 'happitu/src/themes/style-utils'
import theme, { color } from 'theme'

export const Arrow = styled(Icon).attrs(() => ({
  type: 'bi-direction-arrow',
}))`
  color: ${color('grey', 'light')};
  margin-left: 10px;
  &:after {
    ${icomoon};
    background: white;
    content: '\\e913';
    position: absolute;
    right: 0.5em;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
`

export const OptionWrapper = styled.div`
  align-items: center;
  border-radius: 3px;
  border: ${theme('input.border')};
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  line-height: 22px;
  padding: 8px 10px;

  &:hover {
    border-color: ${theme('input.focus.border')};
    color: ${color('grey', 'dark')};

    ${Arrow} {
      color: ${color('grey', 'dark')};
    }
  }
`

export const GhostedOptionWrapper = styled(OptionWrapper)`
  position: fixed;
  visibility: hidden;
  white-space: nowrap;
`

export const OptionLabel = styled.div`
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  i {
    color: ${color('grey', 'light')};
  }
`

export const Wrapper = styled(FuzzySelect)<{ invalid: boolean }>`
  width: 100%;

  ${ifProp(
    'invalid',
    css`
      > div {
        border-color: ${color('red')};
      }
    `,
  )}
`
