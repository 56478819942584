import React from 'react'

import Box from 'happitu/src/components/box'

const GenericErrorMessage = () => {
  return (
    <Box padding="2" style={{ maxWidth: 600, margin: 'auto' }}>
      <p>
        <strong>Well that didn't go as planned. 😔</strong>
      </p>
      <p>
        Our developers have been notified of what went wrong and will be looking into it.
      </p>
    </Box>
  )
}

export default GenericErrorMessage
