import styled from 'styled-components'

import Icon from 'happitu/src/components/Icon'
import getStyle from 'happitu/src/getStyle'

export const ClearButton = styled(Icon)`
  color: ${getStyle('button-base-default-color')};
  cursor: pointer;
  margin-left: 0.75em;
  padding: 0.25em;
  opacity: 0.6;
  transition: all 150ms ease-in-out;

  &:hover,
  &:active {
    opacity: 1;
  }
`

export const BaldInput = styled.input`
  border: 0;
  box-shadow: none;
  padding: 0;
  width: 100%;

  &:focus {
    box-shadow: none;
  }
`
