import React, { useRef, useEffect } from 'react'
import { Editor, Range } from 'slate'
import { useSlate, ReactEditor } from 'slate-react'
import styled from 'styled-components'

import BlockButton from 'happitu/src/components/RichTextEditor/Toolbar/BlockButton'
import InsertLinkButton from 'happitu/src/components/RichTextEditor/Toolbar/InsertLinkButton'
import MarkButton from 'happitu/src/components/RichTextEditor/Toolbar/MarkButton'
import { Divider } from 'happitu/src/components/RichTextEditor/Toolbar/Toolbar.styles'
import TextStyleButton from 'happitu/src/components/RichTextEditor/Toolbar/text-style-button'
import Flex from 'happitu/src/components/flex'
import { menuStyle } from 'happitu/src/components/menu'
import Portal from 'happitu/src/components/portal'
import getStyle from 'happitu/src/getStyle'
import { ElementType } from 'happitu/src/types/slate-types'

const Menu = styled.div`
  ${menuStyle}
  pointer-events: none;
  margin-top: -10px;
  opacity: 0;
  /* background-color: ${getStyle('toolbar-dark-background')}; */
  transition: opacity 0.2s, left 0.15s;
  padding: 0.25rem;
  font-size: 15px;
  top: -10000px;
`

const HoveringToolbar = () => {
  const ref = useRef<HTMLDivElement | null>(null)
  const editor = useSlate()

  useEffect(() => {
    const el = ref.current
    const { selection } = editor

    if (!el) {
      return
    }

    if (
      !selection ||
      !ReactEditor.isFocused(editor) ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ''
    ) {
      el.removeAttribute('style')
      return
    }

    const domSelection = window.getSelection()
    if (!domSelection) return
    const domRange = domSelection.getRangeAt(0)
    const rect = domRange.getBoundingClientRect()
    el.style.opacity = '1'
    el.style.pointerEvents = 'auto'
    el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`
    el.style.left = `${
      rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2
    }px`
  })

  return (
    <Portal>
      <Menu ref={ref}>
        <Flex>
          <TextStyleButton />
          <Divider />
          <MarkButton mark="bold" icon="bold" />
          <MarkButton mark="italic" icon="italic" />
          <MarkButton mark="underline" icon="underline" />
          <MarkButton mark="strikethrough" icon="strikethrough" />

          <InsertLinkButton />
          <Divider />
          <BlockButton block={ElementType.HeadingOne} icon="heading-one" />
          <BlockButton block={ElementType.HeadingTwo} icon="heading-two" />
          <BlockButton block={ElementType.OrderedList} icon="numbered-list" />
          <BlockButton block={ElementType.UnorderedList} icon="bulleted-list" />
        </Flex>
      </Menu>
    </Portal>
  )
}

export default HoveringToolbar
