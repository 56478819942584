import React from 'react'

import { WorkflowElementProps } from '../workflow-viewer.types'

import WorkflowActionList from './workflow-actions-list'
import WorkflowElements from './workflow-elements'
import { Footer, Wrapper } from './workflow-step-viewer.styles'

interface Props extends WorkflowElementProps {
  interactionStep: InteractionStep
  onBack?: () => void
  onAdvance: (action: WorkflowActionRecord) => Promise<void>
  preventAdvancement?: boolean
  workflowStep: WorkflowStepRecord
}

const WorkflowStepViewer = (props: Props) => {
  return (
    <Wrapper>
      <WorkflowElements
        collections={props.collections}
        elementIds={props.workflowStep.elementIds}
        interactionFields={props.interactionStep.fields}
        onUpdateFileIds={props.onUpdateFileIds}
        onUpload={props.onUpload}
        onValueChange={props.onValueChange}
        validationAttempts={props.validationAttempts}
        variables={props.variables}
      />
      <Footer>
        <WorkflowActionList
          actionIds={props.workflowStep.actionIds}
          collections={props.collections}
          disabled={props.preventAdvancement}
          label={props.workflowStep.actionDocPrefix}
          onAdvance={props.onAdvance}
          onBack={props.onBack}
          selectedAction={props.interactionStep.action?.actionId}
        />
      </Footer>
    </Wrapper>
  )
}

export default WorkflowStepViewer
