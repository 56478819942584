import styled from 'styled-components'

import getStyle from 'happitu/src/getStyle'
import { font } from 'theme'

export const SectionCellListItem = styled.li`
  padding: 1em;
  &:not(:last-child) {
    border-bottom: 1px solid ${getStyle('app-background')};
  }
`

export const SectionCellListLabel = styled.h4`
  font-size: ${font('size', 'small')};
  font-weight: ${font('weight', 'medium')};
  margin-right: 1em;
  color: ${getStyle('text-sub-color')};
`

export const SectionCellListValue = styled.div``
