import { css } from 'styled-components'
import { prop } from 'styled-tools'

export interface AlignProps {
  align?: 'center' | 'start' | 'end' | 'stretch'
  justify?: 'center' | 'auto' | 'flex-end'
}

export const alignMixin = css<AlignProps>`
  align-items: ${prop('align', 'center')};
  justify-items: ${prop('justify', 'auto')};
`
