import styled from 'styled-components'

import getStyle from 'happitu/src/getStyle'

export const Wrapper = styled.div`
  margin-bottom: 3em;
`

export const List = styled.div`
  background: ${getStyle('app-background')};
`
