import { setHours, setMinutes, set, addDays } from 'date-fns'
import { last } from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import TimeInput from '../common/callback-schedule-time-select'

import DateSelect from 'happitu/src/components/Fields/DateSelect'
import DateSelectWrapper from 'happitu/src/components/Fields/DateSelect/DateSelectWrapper'
import {
  FieldWrapper,
  InputCSS,
  InsetLabelContent,
} from 'happitu/src/components/Fields/FieldUtils'
import { FlexRow } from 'happitu/src/components/_DEPRECATED/Flex'
import Box from 'happitu/src/components/box'
import Text from 'happitu/src/components/text'
import { CallbackSchedulerDisplayProps } from 'happitu/src/types/workflowElements'

const Wrapper = styled.div`
  position: relative;
  ${InputCSS}

  ${DateSelectWrapper} {
    border: 0;
    flex-shrink: 1;
  }
`

const getHour = (date: Date | null): number => {
  return date instanceof Date ? date.getHours() : 0
}

const getMinute = (date: Date | null) => {
  return date instanceof Date ? date.getMinutes() : 0
}

const CallbackScheduler = (props: CallbackSchedulerDisplayProps) => {
  const { attributes, value, onBlur, onChange, id, errors } = props
  const date = useMemo(() => (!!value ? new Date(value) : null), [value])
  const minDate = useMemo(() => addDays(new Date(), 1), [])
  const lastError = last(errors)

  const handleSelect = (newDate: Date | null | string) => {
    if (newDate instanceof Date) {
      newDate = setHours(newDate, getHour(date))
      newDate = setMinutes(newDate, getMinute(date))
    }

    const newValue = !!newDate
      ? newDate instanceof Date
        ? newDate.toISOString()
        : newDate
      : ''
    onBlur([newValue], id)
    onChange(newValue, id)
  }

  const handleTimeChange = (time: string) => {
    const [strHours, strMinutes, apm] = time.split(/:| /g)
    const hours = parseInt(strHours)
    const minutes = parseInt(strMinutes)
    const nextValue = set(date || new Date(), {
      hours:
        apm.toLowerCase() === 'pm'
          ? hours < 12
            ? hours + 12
            : hours
          : hours === 12
          ? 0
          : hours,
      minutes,
      seconds: 0,
    })

    onBlur([nextValue.toISOString()], id)
    onChange(nextValue.toISOString(), id)
  }

  return (
    <FieldWrapper invalid={!!lastError}>
      <Wrapper>
        <InsetLabelContent>{attributes.label}</InsetLabelContent>

        <Box bg="alt" padding=".5em" style={{ marginTop: '1em', marginBottom: '.5em' }}>
          <Text style={{ fontSize: '.6em' }}>
            Entering a date below will schedule a callback to{' '}
            {props.variables[attributes.variableReference || ''] || '-'}
          </Text>
        </Box>

        <FlexRow alignItems="stretch" style={{ marginTop: '.5em' }}>
          <DateSelect
            placeholder="mmm dd, yyyy"
            id={props.id}
            maxDate={null}
            minDate={minDate}
            months={1}
            onSelect={handleSelect}
            value={date}
          />
          <TimeInput onBlur={handleTimeChange} />
        </FlexRow>
      </Wrapper>
    </FieldWrapper>
  )
}

export default CallbackScheduler
