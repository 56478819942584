import React from 'react'

export default function useTicketFieldAutofocus<R extends HTMLElement> (
  autoFocus: boolean,
  validationAttempts: number,
  errors: Array<string | object>
) {
  const inputWrapper = React.useRef<R>(null)

  // Only autofocus if the field is within view
  React.useEffect(() => {
    if (autoFocus) {
      if (inputWrapper.current) {
        const { top, height } = inputWrapper.current.getBoundingClientRect()
        const windowHeight = window.innerHeight
        if (top + height < windowHeight) {
          const input = inputWrapper.current.querySelector('input')
          if (input) {
            input.focus()
          }
        }
      }
    }
  }, [])

  // Give users a second chance at forgiveness for their sins
  // (return focus to the field if the user tries to progress to the next step with errors and it has autofocus)
  React.useEffect(() => {
    if (autoFocus && errors.length > 0) {
      if (inputWrapper.current !== null) {
        const input = inputWrapper.current.querySelector('input')
        if (input) {
          input.focus()
        }
      }
    }
  }, [validationAttempts])

  return inputWrapper
}