import React from 'react'
import styled from 'styled-components'

import { ButtonWrapper } from './FullscreenImagePreview.styles'

import Icon from 'happitu/src/components/Icon'

interface CloseButtonProps {
  className?: string
  onClick: () => void
}

const CloseButton = ({ className, onClick }: CloseButtonProps) => {
  const title = 'Close image preview'
  return (
    <ButtonWrapper className={className} title={title} onClick={onClick}>
      <Icon type="close-thin" size="medium" />
    </ButtonWrapper>
  )
}

export default styled(CloseButton)`
  left: calc(100vw - 3em);
  position: sticky;
  top: 1em;
  z-index: 2;
`
