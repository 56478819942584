import styled from 'styled-components'

import getStyle from 'happitu/src/getStyle'
import { font } from 'theme'

export const TicketingHeading = styled.h2`
  font-size: ${font('size', 'medium')};
  font-weight: ${font('weight', 'bold')};
`

export const TicketingToolbar = styled.header`
  border-bottom: 1px solid ${getStyle('border-color')};
  padding: 1em;
  display: flex;
  align-items: center;
  height: 4em;
`

export const TicketingSubHeading = styled.h3`
  font-size: ${font('size', 'small')};
  color: ${getStyle('text-sub-color')};
  min-height: 1em;
`
