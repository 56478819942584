import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { Element, Range, Transforms } from 'slate'
import { useSlate, useSelected, ReactEditor } from 'slate-react'

import { StyledTextArea } from './EditableHelpTopicAction.styles'

import {
  selectNextBlockNode,
  selectPreviousBlockNode,
} from 'happitu/src/components/RichTextEditor/editorHelpers'
import { ElementType } from 'happitu/src/types/slate-types'

const isFocusable = (elem: any): elem is HTMLElement => 'focus' in elem

interface Props {
  defaultValue?: string
  placeholder?: string
  nodeId: ID
}

const LabelTextArea = ({ defaultValue = '', ...props }: Props) => {
  const editor = useSlate()
  const selected = useSelected()
  const ref = React.useRef<TextareaAutosize | null>(null)
  const [value, setValue] = React.useState(defaultValue)

  // const handleTab = (e: KeyboardEvent) => {
  //   if (selected && e.key === 'Tab') {
  //     isFocusable(ref.current) && ref.current.focus()
  //   }
  // }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value)
  }

  const handleBlur = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value.trim()
    // Update the label for the selected action.
    Transforms.setNodes(
      editor,
      {
        label: newValue,
      },
      {
        at: [],
        match: (n) =>
          Element.isElement(n) &&
          n.type === ElementType.HelpTopicAction &&
          n.nodeId === props.nodeId,
      },
    )
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      // Select the next block node on enter.
      e.preventDefault()
      selectNextBlockNode(editor)
      ReactEditor.focus(editor)
    } else if (e.key === 'Escape') {
      // Revert to default value on escape.
      setValue(defaultValue)
    } else if (e.key === 'ArrowUp') {
      const { selectionStart, selectionEnd } = e.target as HTMLTextAreaElement
      const isCollapsed = selectionStart === selectionEnd
      const isAtStart = selectionStart === 0
      if (isCollapsed && isAtStart) {
        e.preventDefault()
        selectPreviousBlockNode(editor)
        ReactEditor.focus(editor)
      }
    } else if (e.key === 'ArrowDown') {
      const { selectionStart, selectionEnd, value } = e.target as HTMLTextAreaElement
      const isCollapsed = selectionStart === selectionEnd
      const isAtEnd = selectionEnd === value.length
      if (isCollapsed && isAtEnd) {
        e.preventDefault()
        selectNextBlockNode(editor)
        ReactEditor.focus(editor)
      }
    }
  }

  // Focus the label when just a single action is selected.
  React.useEffect(() => {
    // document.addEventListener('keydown', handleTab)
    // return () => document.removeEventListener('keydown', handleTab)
    if (selected && editor.selection && Range.isCollapsed(editor.selection)) {
      // Don't focus label when we're clicking on the page select (the search input will auto-focus, causing it to be the active element).
      // TODO: It'd be preferable to use the mouse handler on the page select component to stop propagation and prevent the node selection from changing, but this doesn't seem to work with the latest release. When resolved, remove this check and the data tag on the search input.
      if (document.activeElement?.hasAttribute('data-search-input')) return
      isFocusable(ref.current) && ref.current.focus()
    }
  }, [selected])

  React.useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  return (
    <StyledTextArea
      data-slate-editor={true}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      placeholder={props.placeholder}
      ref={(r) => (ref.current = r)}
      value={value}
    />
  )
}

export default LabelTextArea
