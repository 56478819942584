import styled from 'styled-components'

import FuzzySelect from './FuzzySelect'
import Input from './Input'

import BaseLoader from 'happitu/src/components/Loaders/BaseLoader'

const POSTAL_CODE_WIDTH = '140px'

export const Wrapper = styled.div`
  align-items: center;
  display: grid;
  grid-row-gap: 0.5em;
  position: relative;
  max-width: 400px;
`

export const PostalCodeInput = styled(Input)`
  width: ${POSTAL_CODE_WIDTH};
`

export const Loader = styled(BaseLoader)`
  left: calc(${POSTAL_CODE_WIDTH} - 10px);
  top: 25%;
`

export const StateField = styled(Input)`
  min-width: 70px;
  width: 100px;
`

export const CityStateWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 0.5em;
  ${FuzzySelect} {
    width: 100%;
  }
`

export const CityStateCountryWrapper = styled.div`
  display: grid;
  grid-row-gap: 0.5em;
  ${Input} {
    transition: opacity 120ms;
    opacity: 0.6;
  }
  ${Input}:hover,
  ${Input}:focus-within,
  ${Input}:not(:placeholder-shown) {
    opacity: 1;
  }
`
