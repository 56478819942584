import { useEffect, useRef } from 'react'

type Callback = (nextBoolean: boolean) => void

export default function useDocumentToggleEvent(
  boolean: boolean,
  callback: Callback,
  event = 'click',
) {
  const savedCallback = useRef<Callback>()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const eventListener = () => savedCallback.current && savedCallback.current(!boolean)
    if (boolean) {
      document.addEventListener(event, eventListener)
    } else {
      document.removeEventListener(event, eventListener)
    }
    return () => document.removeEventListener(event, eventListener)
  }, [boolean])
}
