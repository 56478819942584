import styled, { css } from 'styled-components'
import { ifProp, prop } from 'styled-tools'

import Box from 'happitu/src/components/box'

export interface Props {
  direction?: 'column' | 'row'
  gap?: number | string
}

interface AlignProps {
  align?: 'center' | 'start' | 'end' | 'stretch'
  justify?: 'center' | 'auto' | 'flex-end'
}

const gridAlignMixin = css<AlignProps>`
  align-items: ${prop('align', 'center')};
  justify-items: ${prop('justify', 'auto')};
`

const flexAlignMixin = css<AlignProps>`
  align-items: ${prop('align', 'center')};
  justify-content: ${prop('justify', 'auto')};
`

const Flex = styled(Box)<Props & AlignProps>`
  ${ifProp(
    'gap',
    css`
      grid-auto-flow: ${ifProp({ direction: 'column' }, 'row', 'column')};
      ${gridAlignMixin}
    `,
    css`
      display: flex;
      flex-direction: ${ifProp({ direction: 'column' }, 'column', 'row')};
      ${flexAlignMixin}
    `,
  )}
`

export default Flex
