import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import getStyle from 'happitu/src/getStyle'

export const Wrapper = styled.div`
  display: block;
  padding: 0.5em 0;
  text-align: center;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
  width: fit-content;
`

interface ImgProps {
  isDragging?: boolean
  isSelected?: boolean
  isPreview?: boolean
}

export const DisplayImg = styled.img`
  border-radius: 1px;
  cursor: zoom-in;
  max-height: 2160px;
  max-width: 100%;
`

export const Img = styled(DisplayImg)<ImgProps>`
  box-shadow: ${ifProp('isSelected', '0 0 0 1px #fff, 0 0 0 3px #584BEE', 'none')};
  margin: 0 0.5em;

  ${ifProp(
    'isPreview',
    css`
      cursor: default;
      opacity: 0.6;
    `,
    css`
      cursor: ${ifProp('isSelected', 'zoom-in', 'default')};
      opacity: 1;
    `,
  )}

  ${ifProp(
    'isDragging',
    css`
      box-shadow: ${getStyle('modal-boxShadow')};
    `,
  )}
`
