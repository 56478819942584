import React, { RefObject } from 'react'

import { isSelectedOption } from './List.helpers'
import { CREATE_ACTION, isOptionComponent, ListChildren } from './List.types'
import SelectableContext from './ListContainers/SelectableList.context'
import { OptionValue } from './ListItems'
import useListHotkeys from './hooks/useListHotkeys'

import { SelectHandler, AcceptedSelectValue } from 'happitu/src/components/Select'

interface Options<SelectValue extends AcceptedSelectValue> {
  value?: SelectValue | null
  multiple?: boolean
  onSelect?: SelectHandler<SelectValue>
  onCreate?: () => void
  onHover?: (value: ID) => void
  autoFocus?: boolean
  children: ListChildren
}

// <SelectValue extends AcceptedSelectValue>
const SelectableListProvider = React.forwardRef(
  (props: Options<AcceptedSelectValue>, ref: RefObject<HTMLDivElement>) => {
    // return (children: ListChildren) => {
    const handleSelect = (optionValue: OptionValue) => {
      const focusedChild = optionIndex[focusIndex]
      // Prioritize onSelect passed to <Option />
      if (isOptionComponent(focusedChild) && focusedChild.props.onSelect) {
        focusedChild.props.onSelect(optionValue)
      } else {
        if (typeof props.onSelect !== 'function') return
        // Trigger onCreate is option value matches create action
        if (optionValue === CREATE_ACTION && typeof props.onCreate === 'function') {
          props.onCreate()
          // Handle multiple values
        } else if (props.multiple) {
          const existingValue = Array.isArray(props.value) ? props.value : []
          if (isSelectedOption(existingValue, optionValue))
            props.onSelect(
              existingValue.filter((v) => v !== optionValue) as AcceptedSelectValue,
            )
          else props.onSelect([...existingValue, optionValue] as AcceptedSelectValue)
          // Handle single value
        } else {
          props.onSelect(optionValue as AcceptedSelectValue)
        }
      }
    }

    const [focusIndex, optionIndex, handleFocusHover, getIndexFromValue] = useListHotkeys(
      ref,
      {
        children: props.children,
        value: props.value,
        onSelect: handleSelect,
        autoFocus: props.autoFocus,
        onHover: props.onHover,
      },
    )

    const context = {
      focusIndex,
      getIndexFromValue,
      onHover: handleFocusHover,
      onSelect: handleSelect,
      value: props.value,
    }

    return (
      <SelectableContext.Provider value={context}>
        {props.children}
      </SelectableContext.Provider>
    )
  },
)

export default SelectableListProvider
