import { last } from 'lodash'
import React, { Suspense } from 'react'
import styled, { css } from 'styled-components'
import { ifNotProp } from 'styled-tools'

import {
  FieldFigure,
  InsetLabelContent,
  NextFieldWrapper,
  InputCSS,
} from 'happitu/src/components/Fields/FieldUtils'
import getStyle from 'happitu/src/getStyle'
import { getParams, WorkTicketRouteParams } from 'happitu/src/helpers/routeHelpers'
import { Hotkey } from 'happitu/src/hooks/useHotkey'
import { LocationRecord } from 'happitu/src/models/location'
import {
  connectTicketContext,
  TicketComponentProps,
} from 'happitu/src/modules/ticketing/ticketing'
import { icomoon } from 'happitu/src/themes/style-utils'
import { LocationSelectDisplayProps } from 'happitu/src/types/workflowElements'
import { font } from 'theme'

const LocationLookupModal = React.lazy(
  () => import('happitu/src/pages/ticketing/components/LocationLookupModal'),
)

const LocationSelect = (
  props: LocationSelectDisplayProps & TicketComponentProps<'tickets' | 'locations'>,
) => {
  const lastError = last(props.errors)
  const [isVisible, setVisibility] = React.useState(false)
  const { ticketId } = getParams<WorkTicketRouteParams>()
  const locationId = props.tickets.findById<TicketRecord>(ticketId)?.locationId
  const location = props.locations?.findById<LocationRecord>(locationId)
  const locationName = location?.name || ''
  const ref = React.useRef<HTMLDivElement>(null)

  const handleOpen = () => setVisibility(true)

  const handleClose = () => {
    setVisibility(false)
  }

  const handleSubmit = (newLocation: LocationRecord | null) => {
    const newLocationName = newLocation?.name || ''
    props.onBlur([newLocationName], props.id)
    props.onChange(newLocationName, props.id)
  }

  const handleKeyDown = React.useCallback(
    (e: KeyboardEvent) => {
      if (
        !isVisible &&
        [Hotkey.Select, Hotkey.Enter, Hotkey.Down].includes(e.key as Hotkey)
      ) {
        e.preventDefault()
        handleOpen()
      }
    },
    [isVisible],
  )

  React.useEffect(() => {
    ref.current?.addEventListener('keydown', handleKeyDown)
    return () => ref.current?.removeEventListener('keydown', handleKeyDown)
  }, [isVisible, ref])

  // This lets us populate documentation when a LocationStep is loaded, but the location is already set.
  React.useEffect(() => {
    if (locationName !== props.value) {
      props.onBlur([locationName], props.id)
      props.onChange(locationName, props.id)
    }
  }, [locationName])

  // Handle autofocus.
  React.useEffect(() => {
    if (props.autoFocus) {
      ref.current?.focus()
    }
  }, [])

  return (
    <NextFieldWrapper invalid={!!lastError}>
      <LocationSelectWrapper
        tabIndex={0}
        onClick={handleOpen}
        hasValue={!!locationName}
        ref={ref}
        role="button"
      >
        <InsetLabelContent required={props.attributes.required}>
          {props.attributes.label}
        </InsetLabelContent>
        <div>{locationName}</div>
      </LocationSelectWrapper>
      <Suspense fallback={<div />}>
        <LocationLookupModal
          isVisible={isVisible}
          onClose={handleClose}
          onSubmit={handleSubmit}
        />
      </Suspense>

      {!!lastError && <FieldFigure value={lastError} />}
    </NextFieldWrapper>
  )
}

export default connectTicketContext<LocationSelectDisplayProps>([
  'tickets',
  'locations?',
])(LocationSelect)

const LocationSelectWrapper = styled.div<{ hasValue?: boolean }>`
  ${InputCSS}
  align-items: center;
  background: ${getStyle('select-default-background')};
  color: ${getStyle('text-default-color')};
  cursor: pointer;
  display: flex;
  min-height: 52px;
  min-width: 20em;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 3em;
  position: relative;
  transition: all 150ms ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: fit-content;

  &:hover,
  &:active {
    background: ${getStyle('select-default-background')};
  }

  &:focus-within {
    border-color: transparent;
    box-shadow: 0px 0px 0px 2px ${getStyle('border-active-color')};
    outline: none;
  }

  &:after {
    ${icomoon}
    content: '\\e910';
    position: absolute;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);
  }

  ${ifNotProp(
    'hasValue',
    css`
      ${InsetLabelContent} {
        transform: translateY(0);
      }
    `,
  )}

  ${InsetLabelContent} {
    font-size: ${font('size', 'base')};
  }
`
