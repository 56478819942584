import React from 'react'

import Option from './Option'
import { OptionProps } from './Option.types'

import { navigateTo } from 'happitu/src/helpers/routeHelpers'

interface Props extends Omit<OptionProps, 'onSelect'> {
  to: string
  params?: Record<string, string | number | undefined>
  label: string
  options?: object
}

const OptionTo = ({ to, params, options, ...props }: Props) => {
  return (
    <Option
      {...props}
      value={props.label}
      onSelect={() => navigateTo(to, params, options)}
    />
  )
}

export default OptionTo
