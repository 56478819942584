import relay, { RelayDispatch } from './relay'

import {
  createWorkflowRequest,
  getWorkflowsRequest,
  updateWorkflowRequest,
  deleteWorkflowRequest,
  publishWorkflowRequest,
  cloneWorkflowRequest,
  CloneParams,
  fetchWorkflowRequest,
  archiveWorkflowRequest,
  reactivateWorkflowRequest,
} from 'happitu/src/services/happitu-api/workflowsService'

export const getWorkflows = (queryParams?: {
  published?: boolean
  showInactive?: boolean
  includeIds?: ID[]
  excludeIds?: ID[]
}) => (dispatch: RelayDispatch<typeof getWorkflowsRequest>) => {
  return getWorkflowsRequest(queryParams).then(relay(dispatch))
}

/**
 * Gets specific workflows from the API.
 */
export const getWorkflowsById = (includeIds: ID[]) => (
  dispatch: RelayDispatch<typeof getWorkflowsRequest>,
) => {
  getWorkflowsRequest({ includeIds, showInactive: true }).then(relay(dispatch))
}

export const getWorkflowVersion = (workflowId: ID, version: number) => (
  dispatch: RelayDispatch<typeof getWorkflowsRequest>,
) => {
  return fetchWorkflowRequest(workflowId, version).then((response) => {
    relay(dispatch)(response)
  })
}

/**
 * Creates a fresh workflow. Error handling utilizes
 * @param  {Object} values Workflow attributes
 * @return {Promise}
 */
export const createWorkflow = (
  values: Partial<WorkflowRecord & { presetId: ID }>,
) => async (dispatch: RelayDispatch<typeof createWorkflowRequest>) => {
  const response = await createWorkflowRequest(values)
  relay(dispatch)(response)
  return response
}

export const updateWorkflow = (workflow: PartialRecord<WorkflowRecord>) => async (
  dispatch: RelayDispatch<typeof updateWorkflowRequest>,
  getState: GetReduxState,
) => {
  const { workflows } = getState()
  relay(dispatch)({
    workflows: [{ ...workflows.findById(workflow.id), ...workflow }],
  })
  const response = await updateWorkflowRequest(workflow)
  relay(dispatch)(response)
}

export const publishWorkflow = (workflowId: ID, data?: PartialRecord<WorkflowRecord>) => (
  dispatch: RelayDispatch<typeof publishWorkflowRequest>,
) => {
  return publishWorkflowRequest(workflowId, data).then(relay(dispatch))
}

export const deleteWorkflow = (workflowId: ID) => {
  return (dispatch: RelayDispatch<typeof deleteWorkflowRequest>) => {
    deleteWorkflowRequest(workflowId).then(relay(dispatch))
  }
}

export const archiveWorkflow = (workflowId: ID) => {
  return (dispatch: RelayDispatch<typeof deleteWorkflowRequest>) => {
    archiveWorkflowRequest(workflowId).then(relay(dispatch))
  }
}

export const reactivateWorkflow = (workflowId: ID, password: string) => {
  return (dispatch: RelayDispatch<typeof deleteWorkflowRequest>) => {
    const response = reactivateWorkflowRequest(workflowId, password)
    relay(dispatch)(response)
    return response
  }
}

export const cloneWorkflow = (workflowId: ID, params: CloneParams) => {
  return async (dispatch: RelayDispatch<typeof cloneWorkflowRequest>) => {
    const response = cloneWorkflowRequest(workflowId, params)
    relay(dispatch)(response)
    return response
  }
}
