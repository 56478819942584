// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/$$virtual/css-loader-virtual-ef416ba337/0/cache/css-loader-npm-5.2.4-285d2e57c2-3698399558.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2ILnPEmsVQEYBz75fGrJCw{position:relative;text-align:left;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}._23GJLjM5Ym7K-ihUL8eL0a{height:15px;margin:8px 10px;position:relative;width:15px}._23GJLjM5Ym7K-ihUL8eL0a:after{content:'';border-bottom:1px solid #333;border-left:1px solid #333;transform:rotate(-45deg);height:6px;width:6px;display:block;opacity:.5}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "_2ILnPEmsVQEYBz75fGrJCw",
	"arrow": "_23GJLjM5Ym7K-ihUL8eL0a"
};
export default ___CSS_LOADER_EXPORT___;
