import { combineReducers } from 'redux'
import { router5Reducer } from 'redux-router5'

import contactAttributes from './contactAttributesReducer'
import contactDetailTypes from './contactDetailTypesReducer'
import fileUploads from './fileUploadsReducer'
import helpTopicPages from './helpTopicPagesReducer'
import helpTopics from './helpTopicsReducer'
import invitations from './invitationsReducer'
import organizationProfile from './organizationProfileReducer'
import organization from './organizationReducer'
import tags from './tagsReducer'
import teams from './teamsReducer'
import user from './userReducer'
import users from './usersReducer'
import websocketRooms from './websocketRoomsReducer'
import workflowActions from './workflowActionsReducer'
import workflowElements, { workflowElementPresets } from './workflowElementsReducer'
import workflowEmailAddresses from './workflowEmailAddressReducer'
import workflowGroups from './workflowGroupsReducer'
import smtpSettings from './workflowSMTPSettingReducer'
import workflowSteps from './workflowStepsReducer'
import workflows from './workflowsReducer'

export default combineReducers({
  contactAttributes,
  contactDetailTypes,
  fileUploads,
  helpTopics,
  helpTopicPages,
  invitations,
  organization,
  organizationProfile,
  router: router5Reducer,
  smtpSettings,
  tags,
  teams,
  user,
  users,
  websocketRooms,
  workflowEmailAddresses,
  workflowElements,
  workflowElementPresets,
  workflowGroups,
  workflows,
  workflowSteps,
  workflowActions,
})
