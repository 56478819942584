import autobind from 'autobind-decorator'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { DropZone, EmptyCallout, Wrapper } from './ImageDropZone.styles'

import { Button } from 'happitu/src/components/button'

export default class ImageDropZone extends Component {
  static propTypes = {
    children: PropTypes.func,
    onDrop: PropTypes.func.isRequired,
    onUploadFinish: PropTypes.func,
    value: PropTypes.string,
  }

  state = {
    src: null,
    loading: false,
  }

  @autobind
  onDrop(files) {
    this.props
      .onDrop(files[0])
      .then((body) => {
        this.setState({
          loading: false,
          activeDropzone: false,
        })
        if (typeof this.props.onUploadFinish === 'function') {
          this.props.onUploadFinish(body)
        }
      })
      .catch(() => {
        this.setState({
          error: 'There was a problem uploading this file',
          loading: false,
        })
      })

    this.setState({
      loading: true,
    })
  }

  @autobind
  onDragEnter() {
    this.setState({
      activeDropzone: true,
    })
  }

  @autobind
  onDragLeave() {
    this.setState({
      activeDropzone: false,
    })
  }

  renderUploadCallout() {
    return (
      <EmptyCallout>
        <h4>Drop an image</h4>
        <p>or</p>
        <Button size="small">Browse Files</Button>
      </EmptyCallout>
    )
  }

  renderErrorCallout() {
    return (
      <EmptyCallout>
        <h4>Incompatible file</h4>
      </EmptyCallout>
    )
  }

  renderMessageCallout(message, type = 'notice') {
    return (
      <EmptyCallout type={type}>
        <h4>{message}</h4>
      </EmptyCallout>
    )
  }

  renderCallout() {
    const { error, loading, activeDropzone } = this.state
    const { value } = this.props

    if (error) {
      return this.renderMessageCallout(error, 'error')
    } else if (loading) {
      return this.renderMessageCallout('Uploading Image')
    } else if (activeDropzone) {
      return this.renderMessageCallout('Drop image')
    } else if (!value) {
      return this.renderUploadCallout()
    }
  }

  render() {
    const { activeDropzone } = this.state
    return (
      <Wrapper active={activeDropzone}>
        <DropZone
          accept="image/jpeg, image/png, image/gif"
          onDrop={this.onDrop}
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
        >
          {this.props.children
            ? this.props.children(activeDropzone)
            : this.renderCallout()}
        </DropZone>
      </Wrapper>
    )
  }
}
