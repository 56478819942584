import styled from 'styled-components'

import SearchInput from 'happitu/src/components/Fields/SearchInput'
import getStyle from 'happitu/src/getStyle'
import { metric } from 'theme'

export default styled(SearchInput)`
  background: ${getStyle('menu-background')};
  border: 0;
  border-bottom: 1px solid ${getStyle('border-color')};
  border-radius: 0;
  border-top-left-radius: ${metric('largeBorderRadius')};
  border-top-right-radius: ${metric('largeBorderRadius')};
  box-shadow: none;
  flex-shrink: 0;
  padding: 0.5em;
  box-shadow: none;

  input:focus {
    box-shadow: none;
  }
`
