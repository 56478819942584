import React, { useState, KeyboardEvent } from 'react'

import { ErrorFigure } from 'happitu/src/components/Fields/FieldUtils'
import { Button } from 'happitu/src/components/button'
import { MenuSection } from 'happitu/src/components/menus'
import { numberValidator } from 'happitu/src/constants/validators'
import { Hotkey } from 'happitu/src/hooks/useHotkey'
import Tree, {
  ConstantNumber,
  isConstantValue,
  RecordFieldReference,
  TreeValue,
} from 'happitu/src/models/expressionTree'

interface Props {
  onSubmit: (values: { number: string }) => void
  initialValue?: ConstantNumber | TreeValue | RecordFieldReference | Tree
}

const ConstantFieldForm = (props: Props) => {
  const [number, setNumber] = useState<string>(
    isConstantValue(props.initialValue) ? `${props.initialValue.constant}` : '',
  )
  const [error, setError] = useState<undefined | string>()

  const handleSubmit = () => {
    const error = numberValidator()(number)
    if (!error) {
      props.onSubmit({ number })
    } else {
      setError(error)
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === Hotkey.Enter) {
      e.preventDefault()
      handleSubmit()
    }
  }

  return (
    <MenuSection>
      <div style={{ display: 'grid', gridGap: '.5em' }}>
        <input
          onChange={(e) => setNumber(e.currentTarget.value)}
          onKeyDown={handleKeyDown}
          name="number"
          placeholder="Static number (e.g., 11, 42, 911)"
          type="number"
        />
        {error && <ErrorFigure>{error}</ErrorFigure>}
        <Button primary onClick={handleSubmit}>
          Apply Number
        </Button>
      </div>
    </MenuSection>
  )
}

export default ConstantFieldForm
