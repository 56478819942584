import { createTicket } from 'happitu/src/actions/ticketActions'
import { WORKFLOW_CHANNELS } from 'happitu/src/constants/workflowConstants'
import { BoundActionType } from 'happitu/src/createRelayReducer'
import { error } from 'happitu/src/helpers/loggerHelper'
import { getParams, NewTicketRouteParams } from 'happitu/src/helpers/routeHelpers'
import useDataFetch from 'happitu/src/hooks/useDataFetcher'
import { parseParamData } from 'happitu/src/modules/ticketing'

interface TicketDetails {
  ticketId: ID
  ticketInteractionId: ID
  workflowId: ID
}

interface Props {
  onSuccess: (ticketDetails: TicketDetails) => void
  onError: () => void
  onBeforeCreate?: () => Promise<Record<string, string>>
  onCreate: BoundActionType<typeof createTicket>
  initialValues?: Partial<NewTicketRouteParams>
}

const useTicketCreate = (props: Props) => {
  useDataFetch(async () => {
    const additionalParams = props.onBeforeCreate ? await props.onBeforeCreate() : {}
    const params = getParams<NewTicketRouteParams>()
    const response = await props.onCreate(
      newTicketRecord({ ...additionalParams, ...params, ...(props.initialValues || {}) }),
    )
    try {
      if (!response.tickets || !response.ticketInteractions) {
        throw new Error('Missing tickets or ticketInteracts key in response.')
      }
      const ticketId = response.tickets[0].id
      const ticketInteractionId = response.ticketInteractions[0].id
      props.onSuccess({
        ticketId,
        ticketInteractionId,
        workflowId: params.workflowId,
      })
    } catch (e) {
      error(e)
      props.onError()
    }
  })
}

const parseChannel = (channelParam?: string) => {
  if (!channelParam) return
  const channel = WORKFLOW_CHANNELS.find(
    ({ name }) => name.toLowerCase() === channelParam.toLowerCase(),
  )
  return channel ? [channel.value] : undefined
}

const newTicketRecord = (params: NewTicketRouteParams) => {
  const {
    authHash,
    autoCloseAfter,
    channel,
    nextStepImpressionId,
    testTicket,
    workflowId,
    ...otherParams
  } = params
  const paramData = parseParamData(otherParams)

  const ticketParams = {
    ...paramData,
    authHash,
    autoCloseAfter,
    channels: parseChannel(channel),
    nextWorkflowId: workflowId,
    nextStepImpressionId: nextStepImpressionId,
    // passing ?testTicket will equal null. this occurs with self-service
    reportable: `${testTicket}` !== 'true' && testTicket !== null,
  }

  return ticketParams
}

export default useTicketCreate
