import update from 'immutability-helper'
import { useState } from 'react'

import {
  HelpTopicScreenType,
  TicketingHelpTopicsContextProps,
  Screen,
} from 'happitu/src/modules/ticketing/ticketing-help-topics.context'

const useTicketingHelpTopicNav = (): TicketingHelpTopicsContextProps => {
  const [screenHistory, setScreenHistory] = useState<readonly Screen[]>([
    { screen: HelpTopicScreenType.Home, context: {} },
  ])
  const [step, setStep] = useState(0)

  // When advancing, always replace the 'future' steps.
  const advanceScreen = (helpTopicImpressionId: ID, helpTopicPageId: ID) => {
    const currentScreen = screenHistory[step]
    // Don't update history if the help topic and page are the same.
    if (
      currentScreen.context.helpTopicImpressionId === helpTopicImpressionId &&
      currentScreen.context.helpTopicPageId == helpTopicPageId
    )
      return

    setScreenHistory(
      update(screenHistory, {
        $splice: [
          [
            step + 1,
            screenHistory.length - 1 - step,
            {
              screen: 'helpTopic',
              context: {
                helpTopicImpressionId,
                helpTopicPageId,
              },
            },
          ],
        ],
      }),
    )
    setStep(step + 1)
  }

  return {
    advanceScreen,
    setStep,
    setScreenHistory,
    step,
    screenHistory,
  }
}

export default useTicketingHelpTopicNav
