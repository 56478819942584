import styled from 'styled-components'

import { color } from 'theme'

export const Header = styled.h2`
  font-size: +2em;
`

export const StackTrace = styled.div`
  border: 1px solid ${ color('border') };
`

export const SubHeader = styled.h4`
  font-size: +1.5em;
`

export const Wrapper = styled.div`
  width: 75%;
  height: 100%;
  margin: 5em auto;
  text-align: center;
`
