import { ComponentProps, ReactNode, useCallback, useState } from 'react'

import { MenuContext } from './context'

import useDocumentToggleEvent from 'happitu/src/hooks/useDocumentToggleEvent'
import useHotkey, { Hotkey } from 'happitu/src/hooks/useHotkey'

interface Props extends ComponentProps<'div'> {
  initialVisibility?: boolean
  children: ReactNode
  onClose?: () => void
}

const useCloseEffect = (
  initialVisibility = false,
  onClose?: () => void,
): [boolean, typeof setVisibility] => {
  const [isVisible, setVisibility] = useState<boolean>(initialVisibility)
  const close = useCallback(() => {
    if (typeof onClose === 'function') {
      onClose()
    }
    setVisibility(false)
  }, [onClose])

  useHotkey(Hotkey.Close, close, isVisible, [])
  useDocumentToggleEvent(isVisible, close)

  return [isVisible, setVisibility]
}

const MenuWrapper = ({
  initialVisibility = false,
  children,
  onClose,
  ...props
}: Props) => {
  const [isVisible, setVisibility] = useCloseEffect(initialVisibility, onClose)
  return (
    <MenuContext.Provider value={{ isVisible, setVisibility }}>
      <div {...props} style={{ position: 'relative' }}>
        {children}
      </div>
    </MenuContext.Provider>
  )
}

export default MenuWrapper
