import { createGlobalStyle } from 'styled-components'

import { boxShadow } from './default/menu'

import getStyle from 'happitu/src/getStyle'
import { icomoon } from 'happitu/src/themes/style-utils'

export default createGlobalStyle`

.rdrCalendarWrapper{
  background: transparent;
  font-size: 12px;
}

.rdrDateDisplay{
  padding: 0.833em;
  border-bottom: 1px solid ${getStyle('border-color')};
}

.rdrDateDisplayItem{
  background-color: ${getStyle('app-background')};
  border-radius: 4px;
  border: 1px solid transparent;
}

.rdrDateDisplayItem input{
  background: ${getStyle('app-background')};
  border: 0px;
  box-shadow: ${boxShadow};
  color: ${getStyle('text-active-color')};
  cursor: pointer;
  height: 2.5em;
  line-height: 2.5em;
  width: 100%;
}

.rdrDateDisplayItemActive{
  border-color: ${getStyle('button-primary-default-background')};
}

.rdrMonthAndYearWrapper {
  align-items: center;
  height: 40px;
  padding-top: 10px;
}

.rdrMonthAndYearPickers{
  font-weight: 600;

  select {
    appearance: none;
    background-position: right 8px center;
    background: ${getStyle('button-base-default-background')};
    border-radius: 4px;
    border: 0;
    color: ${getStyle('button-base-default-color')};
    cursor: pointer;
    outline: 0;
    padding: 10px 30px 10px 10px;
    text-align: center;

    &:hover {
      background: ${getStyle('button-base-hover-background')};
      color: ${getStyle('button-base-hover-color')};
    }
  }
}

.rdrMonthPicker, .rdrYearPicker{
  margin: 0 5px;
  position: relative;

  &:after{
    ${icomoon}
    color: ${getStyle('button-base-default-color')};
    content: '\\e913';
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
  }

  select:focus {
    box-shadow: 0px 0px 0px 2px ${getStyle('border-active-color')};
  }
}

.rdrNextPrevButton {
  background: ${getStyle('button-base-default-background')};
  border-radius: 5px;
  border: 0;
  display: block;
  height: 24px;
  margin: 0 0.833em;
  padding: 0;
  width: 24px;

  &:hover {
    background: ${getStyle('button-base-hover-background')};
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px ${getStyle('border-active-color')};
  }
}

.rdrNextPrevButton i {
  border-style: solid;
  display: block;
  height: 0;
  margin: auto;
  padding: 0;
  text-align: center;
  transform: translate(-3px, 0px);
  width: 0;
}

.rdrPprevButton i {
  border-color: transparent ${getStyle(
    'button-base-default-color',
  )} transparent transparent;
  border-width: 4px 6px 4px 4px;
  transform: translate(-3px, 0px);
}

.rdrNextButton i {
  border-color: transparent transparent transparent ${getStyle(
    'button-base-default-color',
  )} ;
  border-width: 4px 4px 4px 6px;
  margin: 0 0 0 7px;
  transform: translate(3px, 0px);
}

.rdrWeekDays {
  padding: 0 0.833em;
}

.rdrMonth{
  padding: 1em;
  padding-top: 0;
}

.rdrMonth .rdrWeekDays {
  padding: 0;
}

.rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName{
  display: none;
}

.rdrWeekDay {
  color: ${getStyle('text-light-color')};
  font-weight: 400;
  line-height: 2.667em;
}

.rdrDay {
  background: transparent;
  border: 0;
  height: 3em;
  line-height: 3em;
  padding: 0;
  text-align: center;
  user-select: none;
}

.rdrDayNumber {
  align-items: center;
  bottom: 0;
  bottom: 5px;
  display: flex !important;
  font-weight: 300;
  justify-content: center;
  left: 0;
  outline: 0;
  position: absolute !important;
  right: 0;
  top: 0;
  top: 5px;
}

.rdrDayToday .rdrDayNumber span{
  font-weight: 400
}

.rdrDayToday .rdrDayNumber span:after{
  background: ${getStyle('mainNav-background')};
  border-radius: 2px;
  bottom: 4px;
  content: '';
  height: 2px;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
  width: 18px;
}

/* Color of current day underline when selected */
.rdrDayToday .rdrInRange ~ .rdrDayNumber span:after, .rdrDayToday .rdrStartEdge ~ .rdrDayNumber span:after,
  .rdrDayToday .rdrEndEdge ~ .rdrDayNumber span:after, .rdrDayToday .rdrSelected ~ .rdrDayNumber span:after{
  background: ${getStyle('app-background')};
}

/* Color of selected date text */
.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber
  span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span{
  color: ${getStyle('button-primary-default-color')};
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
  background: ${getStyle('button-primary-default-background')};
  bottom: 5px;
  left: 0;
  position: absolute;
  right: 0;
  top: 5px;
}

.rdrSelected{
  left: 2px;
  right: 2px;
}

.rdrStartEdge{
  border-bottom-left-radius: 1em;
  border-top-left-radius: 1em;
  left: 2px;
}

.rdrEndEdge{
  border-bottom-right-radius: 1em;
  border-top-right-radius: 1em;
  right: 2px;
}

.rdrSelected{
  border-radius: 1em;
}

.rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrEndEdge{
  border-bottom-left-radius: 1em;
  border-top-left-radius: 1em;
  left: 2px;
}

.rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrStartEdge{
  border-bottom-right-radius: 1em;
  border-top-right-radius: 1em;
  right: 2px;
}

.rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview,
.rdrDayStartOfWeek .rdrDayEndPreview{
  border-bottom-left-radius: 1.333em;
  border-left-width: 1px;
  border-top-left-radius: 1.333em;
  left: 0px;
}

.rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview,
.rdrDayEndOfWeek .rdrDayStartPreview{
  border-bottom-right-radius: 1.333em;
  border-right-width: 1px;
  border-top-right-radius: 1.333em;
  right: 0px;
}

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
  background: rgba(255, 255, 255, .1);
  border: 0px solid ${getStyle('button-primary-default-background')};
  bottom: 3px;
  left: 0px;
  pointer-events: none;
  position: absolute;
  right: 0px;
  top: 3px;
  z-index: 1;

}

.rdrDayStartPreview{
  border-bottom-left-radius: 1.333em;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-left-radius: 1.333em;
  border-top-width: 1px;
  left: 0px;
}

.rdrDayInPreview{
  border-bottom-width: 1px;
  border-top-width: 1px;
}

.rdrDayEndPreview{
  border-bottom-right-radius: 1.333em;
  border-bottom-width: 1px;
  border-right-width: 1px;
  border-top-right-radius: 1.333em;
  border-top-width: 1px;
  right: 0px;
}

.rdrDefinedRangesWrapper{
  background: ${getStyle('app-background')};
  border-bottom-left-radius: 6px;
  border-right: 1px ${getStyle('border-color')};
  border-top-left-radius: 6px;
  font-size: 12px;
  max-width: 150px;
  overflow: hidden;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected{
  color: ${getStyle('button-primary-default-background')} !important;
  font-weight: 600;
}

.rdrStaticRange{
  background: ${getStyle('app-background')};
  border: none;
  cursor: pointer;
  display: block;
  height: 100%;
  outline: 0;
  padding: 0;

  &:hover {
    background: ${getStyle('button-base-hover-background')};
    color: ${getStyle('text-active-color')};
  }
}

.rdrStaticRangeLabel{
  align-items: center;
  display: block;
  display: flex;
  height: 100%;
  line-height: 18px;
  outline: 0;
  padding: 10px 20px;
  text-align: left;
}

.rdrInputRanges{
  display: none;
}

.rdrInputRange{
  align-items: center;
  padding: 5px 20px;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after{
  background: transparent;
  border-radius: 1.333em;
  border: 1px solid ${getStyle('button-primary-default-background')};
  bottom: -2px;
  content: '';
  left: 0px;
  position: absolute;
  right: 0px;
  top: -2px;
}

.rdrDayPassive{
  pointer-events: none;
}

.rdrDayPassive .rdrDayNumber span{
  color: ${getStyle('text-light-color')};
}

.rdrDayNumber span{
  color: ${getStyle('text-default-color')};
}

.rdrDayPassive .rdrInRange, .rdrDayPassive .rdrStartEdge, .rdrDayPassive .rdrEndEdge, .rdrDayPassive .rdrSelected,
.rdrDayPassive .rdrDayStartPreview, .rdrDayPassive .rdrDayInPreview, .rdrDayPassive .rdrDayEndPreview{
  display: none;
}

.rdrDayDisabled {
  background: ${getStyle('app-alt-background')};
}

.rdrDayDisabled .rdrDayNumber span{
  color: ${getStyle('text-light-color')};
}

.rdrDayDisabled .rdrInRange, .rdrDayDisabled .rdrStartEdge, .rdrDayDisabled .rdrEndEdge, .rdrDayDisabled .rdrSelected,
.rdrDayDisabled .rdrDayStartPreview, .rdrDayDisabled .rdrDayInPreview, .rdrDayDisabled .rdrDayEndPreview{
  filter: grayscale(60%) opacity(60%);
}

.rdrMonthName{
  color: ${getStyle('text-sub-color')};
  font-weight: 600;
  padding: 0.833em;
  text-align: left;
}

.rdrDateRangeWrapper {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  overflow: hidden;
}

.rdrStaticRanges {
  display: grid;
}
`
