// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/$$virtual/css-loader-virtual-ef416ba337/0/cache/css-loader-npm-5.2.4-285d2e57c2-3698399558.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1ySEoya677-fwAoZv0yKla{left:-1000px;opacity:0;position:fixed;top:-1000px}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marker": "_1ySEoya677-fwAoZv0yKla"
};
export default ___CSS_LOADER_EXPORT___;
