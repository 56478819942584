import {
  Children,
  useEffect,
  RefObject,
  useState,
  useMemo,
  FunctionComponentElement,
  ReactNode,
} from 'react'
import scrollIntoView from 'scroll-into-view-if-needed'

import { isOptionComponent, isReactComponent, ListChildren } from '../List.types'
import { OptionValue, OptionProps } from '../ListItems'

import { AcceptedSelectValue } from 'happitu/src/components/Select'
import { error } from 'happitu/src/helpers/loggerHelper'
import { Hotkey } from 'happitu/src/hooks/useHotkey'

enum Direction {
  Up = 'Up',
  Down = 'Down',
}

interface Options<SelectValue> {
  value?: SelectValue
  onHover?: (value: ID) => void
  onSelect: (newValue: OptionValue) => void
  children: ListChildren
  autoFocus?: boolean
}

export const buildOptionIndex = (
  children?: ReactNode,
): FunctionComponentElement<OptionProps>[] => {
  return Array.isArray(children)
    ? children
        .flat()
        .map((component) => {
          if (isOptionComponent(component)) return component
          // Recursively check component tree for other options. This is important when there are grouped options.
          else if (isReactComponent(component) && component.props.children)
            return buildOptionIndex(component.props.children)
          else return
        })
        .flat()
        .filter((option): option is FunctionComponentElement<OptionProps> => !!option)
    : []
}

const useListHotkeys = <SelectValue extends AcceptedSelectValue>(
  ref: RefObject<HTMLElement>,
  opts: Options<SelectValue>,
): [number, ListChildren, typeof handleHover, typeof getIndexFromValue] => {
  const [focusIndex, setFocusIndex] = useState(0)
  const options = useMemo(() => buildOptionIndex(opts.children), [opts.children])

  const getNextIndex = (index: number, direction: Direction) => {
    switch (direction) {
      case Direction.Up:
        return index === 0 ? Children.count(options) - 1 : index - 1
      case Direction.Down:
        return index === Children.count(options) - 1 ? 0 : index + 1
    }
  }

  const focusOption = (node: Element | null) => {
    if (node && ref.current) {
      ref.current.style.pointerEvents = 'none'
      scrollIntoView(node, {
        scrollMode: 'if-needed',
        block: 'nearest',
        inline: 'nearest',
      })
      ref.current.style.pointerEvents = ''
    }
  }

  const focusAdjacentOption = (list: HTMLElement, direction: Direction) => {
    setFocusIndex((index) => {
      const nextIndex = getNextIndex(index, direction)
      const node = list.children.item(nextIndex)
      focusOption(node)
      return nextIndex
    })
  }

  const focusOptionAtIndex = (list: HTMLElement, childIndex: number) => {
    setFocusIndex(() => {
      const node = list.children.item(childIndex)
      focusOption(node)
      return childIndex
    })
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (!ref.current)
      return error(`SelectableList: current target is missing a parent element`)
    const component = options[focusIndex]
    switch (e.key) {
      case Hotkey.Up:
        e.preventDefault()
        e.stopPropagation()
        e.stopImmediatePropagation()
        return focusAdjacentOption(ref.current, Direction.Up)
      case Hotkey.Down:
        e.stopPropagation()
        e.stopImmediatePropagation()
        e.preventDefault()
        return focusAdjacentOption(ref.current, Direction.Down)
      case Hotkey.Beginning:
        e.stopPropagation()
        e.stopImmediatePropagation()
        e.preventDefault()
        return focusOptionAtIndex(ref.current, 0)
      case Hotkey.End:
        e.stopPropagation()
        e.stopImmediatePropagation()
        e.preventDefault()
        return focusOptionAtIndex(ref.current, ref.current.children.length - 1)
      case Hotkey.Enter:
        if (isOptionComponent(component)) {
          e.preventDefault()
          e.stopPropagation()
          e.stopImmediatePropagation()
          return opts.onSelect(component.props.value)
        }
    }
  }

  const getIndexFromValue = (value: OptionValue) => {
    return options.findIndex((option) => option.props.value === value)
  }

  const handleHover = (value: ID) => {
    setFocusIndex(getIndexFromValue(value))
    typeof opts.onHover === 'function' && opts.onHover(value) // Handle additional hover effects.
  }

  useEffect(() => {
    //  setFocusIndex(0)
  }, [options.length])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [opts.value, focusIndex, options])

  return [focusIndex, options, handleHover, getIndexFromValue]
}

export default useListHotkeys
