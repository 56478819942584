import React from 'react'
import { Range, Editor } from 'slate'
import { useSlate } from 'slate-react'
import styled from 'styled-components'

import { ToolbarButton } from './Toolbar.styles'

import Icon from 'happitu/src/components/Icon'
import { isLinkActive, setLink } from 'happitu/src/helpers/editor/formattingHelper'

const Wrapper = styled.div`
  position: relative;
`

const InsertLinkButton = () => {
  const editor = useSlate()
  const { selection } = editor
  const path = selection ? Editor.path(editor, selection) : undefined

  const handleMouseDown = () => {
    if (selection && Range.isExpanded(selection) && path && path.length !== 0) {
      setLink(editor, '')
    }
  }

  return (
    <Wrapper>
      <ToolbarButton
        active={isLinkActive(editor)}
        disabled={
          !selection || Range.isCollapsed(selection) || !path || path.length === 0
        }
        onMouseDown={handleMouseDown}
      >
        <Icon type="link" size="medium" />
      </ToolbarButton>
    </Wrapper>
  )
}

export default InsertLinkButton
