import React from 'react'
import { useSlate } from 'slate-react'

import { ToolbarButton } from './Toolbar.styles'

import Icon from 'happitu/src/components/Icon'
import { isMarkActive, toggleMark } from 'happitu/src/helpers/editor/formattingHelper'
import { MARK_TYPES } from 'happitu/src/types/models/richTextEditor'

interface Props {
  mark: MARK_TYPES
  icon: string
}

const MarkButton = ({ mark, icon }: Props) => {
  const editor = useSlate()

  // Prevent losing editor focus so we keep the selection for toggling the mark.
  const handleMouseDown = (e: React.MouseEvent<HTMLSpanElement>) => e.preventDefault()
  const handleMouseUp = () => toggleMark(editor, mark)

  return (
    <ToolbarButton
      active={isMarkActive(editor, mark)}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <Icon type={icon} />
    </ToolbarButton>
  )
}

export default MarkButton
